/* eslint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { useQuery, useLazyQuery } from 'react-apollo';
import { useMutation } from 'components/graphql';
import { get } from 'lodash';

import  TableGrid from 'components/tableGrid/TableGrid';
import { Modal, Calendar, Button, Input } from 'components/elements';
import { LoadingWrap } from 'style/default';

import { DEBITOR, DATE_REMINDER, UPDATE_DATE_REMINDER, ENTER_PAYMENT, ENTER_PAYMENT_INFO, CALL_ADD_INVOICE_PAYMENT } from '../graphql/debitor';
import { PARAMS, PARAMS_PAYMENT } from '../CONST';
import { useTranslation } from 'react-i18next';

const DAFAULT_ENTER_PAYMENT = {
  date: new Date(),
  accountNo: '',
  receptNo: '',
  amount: '',
  description: '',
};

const ModalEnterPayment = ({ t, data, changeField, resultQueryInfo, loadPaymentInfo, refetchDebitor, refetchPayment }) => {
  const [callEnterPayment, { data: resultQuery, loading: loadPayment, refetch } ] = useLazyQuery(ENTER_PAYMENT);

  const [callAddInvoicePayment, { loading }] = useLazyQuery(CALL_ADD_INVOICE_PAYMENT, {
    onCompleted: () => ( refetch(), refetchDebitor(), refetchPayment() )
  });

  const queryEnterPayment = () => {
    callEnterPayment({
      variables: { id: data.id },
    })
  };

  useEffect(() => {
    queryEnterPayment();
  }, [])

  const callProcedure = () => {
    const formateDate = new Date(data.date).toJSON().substr(0, 10);
    const formateAmount = parseFloat(data.amount);
    const args = {
      ...data,
      date: formateDate,
      amount: formateAmount,
    }
    callAddInvoicePayment({
      variables: { args },
    });
  };

  return (
    <LoadingWrap loading={loadPayment && loadPaymentInfo || loading}>
    <Main>
      <Margin>
        <Col lg={3}>
          <Input
            readonly
            withoutFormik={true}
            value={resultQueryInfo?.fakturaAssist?.listFA_InvoiceForDebitorEnterPayment[0]?.invoiceNr}
            type='number'
            name={'invoiceNo'}
            title={t('debitor.invoiceNo')}
            small
          />
        </Col>
        <Col lg={3}>
          <Input
            readonly
            withoutFormik={true}
            value={resultQueryInfo?.fakturaAssist?.listFA_InvoiceForDebitorEnterPayment[0]?.date}
            type='number'
            name={'date'}
            title={t('common.date')}
            small
          />
        </Col>
        <Col lg={3}>
          <Input
            readonly
            dateFormat
            withoutFormik={true}
            value={resultQueryInfo?.fakturaAssist?.listFA_InvoiceForDebitorEnterPayment[0]?.total}
            type='number'
            name={'total'}
            title={t('common.total')}
            small
          />
        </Col>
        <Col lg={3}>
          <Input
            readonly
            withoutFormik={true}
            value={resultQueryInfo?.fakturaAssist?.listFA_InvoiceForDebitorEnterPayment[0]?.balance}
            type='number'
            name={'remaingAmount'}
            title={t('debitor.remaningAmount')}
            small
          />
        </Col>
      </Margin>
      <Margin>
        <Col lg={3}>
          <Input
            readonly
            withoutFormik={true}
            value={resultQueryInfo?.fakturaAssist?.listFA_InvoiceForDebitorEnterPayment[0]?.customerNr}
            type='number'
            name={'customerNo'}
            title={t('common.customerNo')}
            small
          />
        </Col>
        <Col lg={6}>
          <Input
            readonly
            withoutFormik={true}
            value={resultQueryInfo?.fakturaAssist?.listFA_InvoiceForDebitorEnterPayment[0]?.actName}
            type='number'
            name={'name'}
            title={t('common.name')}
          />
        </Col>
      </Margin>
      <TableRow>
        <TableGrid
          data={resultQuery?.fakturaAssist?.listFA_OpenDebtsPayment}
          loading={loadPayment}
          tableData={PARAMS_PAYMENT(t)}
          height={600}
          headHeight={'100%'}
        />
      </TableRow>
      <Title>{t('debitor.enterPayments')}</Title>
      <Margin>
        <Col lg={3}>
          <Calendar
            field='date'
            width={'140px'}
            title={t('common.date')}
            withoutFormik
            value={data.date}
            onChange={(value)=> {
              changeField('date', value)
            }}
          />
        </Col>
        <Col lg={3}>
          <Input
            withoutFormik={true}
            value={data.accountNo}
            onChange={(e) => changeField(e.target.name, e.target.value)}
            type='number'
            name={'accountNo'}
            title={t('common.accountNo')}
            small
          />
        </Col>
        <Col lg={3}>
          <Input
            withoutFormik={true}
            value={data.receptNo}
            type='number'
            onChange={(e) => changeField(e.target.name, e.target.value)}
            name={'receptNo'}
            title={t('common.receptNo')}
            small
          />
        </Col>
      </Margin>
      <Margin>
        <Col lg={6}>
          <Input
            withoutFormik={true}
            value={data.description}
            type='number'
            name={'description'}
            onChange={(e) => changeField(e.target.name, e.target.value)}
            title={t('common.description')}
          />
        </Col>
        <Col lg={3}>
          <Input
            withoutFormik={true}
            decimal
            type={'number'}
            value={data.amount}
            type='number'
            name={'amount'}
            onChange={(e) => changeField(e.target.name, e.target.value)}
            title={t('common.amount')}
            small
          />
        </Col>
      </Margin>
      <BtnRow>
        <Col lg={6}>
          <Button value={t('debitor.useOverpayments')} className={'no-active-btn'} width={'140px'}/>
        </Col>
        <Col lg={6}>
          <Button value={t('debitor.enterPayments')} className={'green-btn'} width={'140px'} onClick={() => callProcedure()}/>
        </Col>
      </BtnRow>
    </Main>
    </LoadingWrap>
  )
};

export const TablesDebitors = (props) => {
  const [getEnterPaymentInfo, { data: resultQueryInfo, loading: loadPaymentInfo, refetch: refetchPayment }] = useLazyQuery(ENTER_PAYMENT_INFO);

  const [t] = useTranslation();

  const [isOpen, setOpenModal] = useState(false);
  const [isOpenPayment, setOpenModalPayment] = useState(false);
  const [dataForEnterPayment, setDateForEnterPayment] = useState(DAFAULT_ENTER_PAYMENT);
  const changeFieldPayment = (name, value) => {
    setDateForEnterPayment({...dataForEnterPayment, [name]: value})
  };
  const [idPayment, setIdPayment] = useState();

  const { data, loading, refetch: refetchDebitor } = useQuery(DEBITOR, {
    variables: {
      args: {
        invoicesFrom: props.date.dateFrom,
        invoicesTill: props.date.dateTill,
        paymentDate: props.date.dateUp,
      }
    }
  });

  const ModalInfo = (props) => {
    const [dateDebts, setDateDebts] = useState({dateDebtReminder1: '', dateDebtReminder2: '', dateDebtReminder3: ''});
    const [t] = useTranslation();
    const [updateDateReminder] = useMutation(UPDATE_DATE_REMINDER);
    const { data } = useQuery(DATE_REMINDER, {
      variables: {id: idPayment}
    });

    useEffect(() => {
      const dates = get(data, 'fakturaAssist.listFA_InvoiceDate', {dateDebtReminder1: ''});
      if(dates) {
        if(dates?.dateDebtReminder1.includes(':')) {
          setDateDebts({
            dateDebtReminder1: new Date(dates?.dateDebtReminder1),
            dateDebtReminder2: new Date(dates?.dateDebtReminder2),
            dateDebtReminder3: new Date(dates?.dateDebtReminder3)
          })
        } else {
          setDateDebts({
            dateDebtReminder1: new Date(parseInt(dates?.dateDebtReminder1)),
            dateDebtReminder2: new Date(parseInt(dates?.dateDebtReminder2)),
            dateDebtReminder3: new Date(parseInt(dates?.dateDebtReminder3))
          })
        }
      }

    }, [data]);

    const saveChange = () => {
      updateDateReminder({
        variables: {
          id: idPayment,
          fields: {
            dateDebtReminder1: dateDebts.dateDebtReminder1,
            dateDebtReminder2: dateDebts.dateDebtReminder2,
            dateDebtReminder3: dateDebts.dateDebtReminder3,
          }
        }
      });
      setOpenModal(!isOpen);
    };

    return (
      <ModalBlock>
        <Row>
          <Col lg={12}>
            <Calendar
              field='dateDebtReminder1'
              width={'250px'}
              title={t('common.date') + ' 1'}
              withTime
              withoutFormik
              value={dateDebts.dateDebtReminder1}
              onChange={(value)=> {
                setDateDebts({...dateDebts, dateDebtReminder1: new Date(value)});
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Calendar
              field='date2'
              width={'250px'}
              title={t('common.date') + ' 2'}
              withTime
              withoutFormik
              value={dateDebts.dateDebtReminder2}
              onChange={(value)=> {
                setDateDebts({...dateDebts, dateDebtReminder2: new Date(value)});
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Calendar
              field='date3'
              width={'250px'}
              title={t('common.date') + ' 3'}
              withTime
              withoutFormik
              value={dateDebts.dateDebtReminder3}
              onChange={(value)=> {
                setDateDebts({...dateDebts, dateDebtReminder3: new Date(value)});
              }}
            />
          </Col>
        </Row>
        <ModalRowBtn>
          <Col lg={6}>
            <Button value={t('common.cancel')} className={'grey-btn'} width={'140px'} onClick={() => setOpenModal(!isOpen)}/>
          </Col>
          <Col lg={6}>
            <Button value={t('common.save')} className={'black'} width={'140px'} onClick={saveChange}/>
          </Col>
        </ModalRowBtn>
      </ModalBlock>
    )
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setOpenModalPayment(false);
    setDateForEnterPayment(DAFAULT_ENTER_PAYMENT);
  };

  const onClickRow = (i) => {
    const id = info.items[i].id
    setDateForEnterPayment({...dataForEnterPayment, id, amount: info.items[i].balance, description: `${t('debitor.paymentOnInvoiceNo')} ${info.items[i].fakturaNr}`})
    setOpenModalPayment(true);
    getEnterPaymentInfo({
      variables: {id},
      fetchPolicy: 'network-only',
    });

  }

  const info = get(data, 'fakturaAssist.callTFN_OpenDebtsListByInvoicesOnPeriod', {});

  const modalView = <Modal closeModal={onCloseModal} Component={<ModalInfo {...props}/>} headerText={t('debitor.reminders')} isOpen={isOpen}/>
  const modalViewPayment = <Modal
    closeModal={onCloseModal}
    Component={
      <ModalEnterPayment
        t={t}
        data={dataForEnterPayment}
        changeField={changeFieldPayment}
        resultQueryInfo={resultQueryInfo}
        loadPaymentInfo={loadPaymentInfo}
        refetchDebitor={refetchDebitor}
        refetchPayment={refetchPayment}
        closeModal={onCloseModal}
      />}
    headerText={t('debitor.enterPayments')}
    isOpen={isOpenPayment}/>
  return (
    <Block>
      {isOpen ? modalView : null}
      {isOpenPayment ? modalViewPayment : null}
      <TableGrid
        changeTableIndex={onClickRow}
        data={info.items}
        loading={loading}
        tableData={PARAMS(
          {
            t,
            total: info.total,
            balance: info.balance,
            totalInTime: info.totalInTime,
            total15: info.total15,
            total30: info.total30,
            totalMore: info.totalMore,
            openModal: (e, data) => (e.stopPropagation(), setOpenModal(!isOpen), setIdPayment(data.id)),
          }
          )}
        height={600}
        headHeight={'100%'}
        // withSearch
        // onSearch={setSearch} // not wotk
        // searchValue={search}
        withTotal

        getTableIndex={props.getTableIndex}
        getFirstValueOfMount={props.getFirstValueOfMount(info)}
        startedIndex={0}

      />
    </Block>
  );
}

const Block = styled.div`
	margin: 6px 0px 0px 0px;
	overflow-x: scroll;
	height: 100%;
`;

const ModalBlock = styled.div`
  width: 377px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f5f5f5;
  padding-left: 20px;
`;

const ModalRowBtn = styled(Row)`
  margin-top: 20px;
  padding-bottom: 20px;
`;

// Modal Payment

const Main = styled.div`
  width: 700px;
  padding: 0 10px;
`;

const TableRow = styled(Row)`
  margin: 20px 0;
`;

const BtnRow = styled(Row)`
  margin: 10px;
`;

const Margin = styled(Row)`
  margin: 0;
`;

const Title = styled.div`
  margin: 5px 0 0 15px;
  /* font-family: Roboto; */
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
`;