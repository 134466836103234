import React from 'react';
import styled from 'styled-components';
import { Row } from 'style';
import moment from 'moment';

import { tableData } from './tableData';

import DateRange from 'components/elements/dateRange/DateRange';
//import { Dropdown } from '../../../../components/elements';
import Query from 'components/graphql/Query';
import DXTableGrid from 'components/dxTableGrid';
import { Formik } from 'formik';

import { listFA_EmptyOrders } from '../../qql/query';

export default ({ t }) =>(
  <Formik initialValues={{ date: moment(), secondDate: moment(new Date()).add(1, 'days') }}>
    {({ handleSubmit, handleChange, values, setFieldValue }) =>{
      return (
        <>
          <ArgsBlock>
            <Row>
            
              <DateRange startField='date' endField='secondDate' values={values} t={t} withoutFormik onChange={setFieldValue}/>
            </Row>
          </ArgsBlock>
          
          <TableBlock>
            <Query
              query={listFA_EmptyOrders}
              variables={{ args: { date: values.date, secondDate: values.secondDate } }}
            >
              {({ data, loading }) => {
                return (
                  <DXTableGrid
                    data={data || []}
                    loading={loading}
                    columns={tableData(t)}
                    withoutQuery
                    height={508}
                    manualGroup/>
                );
              }}
            </Query>
          </TableBlock>
          
        </>);
    }}
  </Formik>
);

const ArgsBlock = styled.div`
  background: #f4f4f4;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  padding: 0 20px;
`;

const TableBlock = styled.div`
  margin: 20px 0px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;
