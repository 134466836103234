export const usersTableParams = (t) =>([
  {
    name: t('common.userName'),
    dataBase: 'test',
  },
  {
    name: t('common.userLogin'),
    dataBase: 'test',
  },
]);

export const rolesTableParams = (t) =>([
  {
    name: t('common.name'),
    dataBase: 'test',
  },
  {
    name: t('common.editing'),
    dataBase: 'test',
  },
  {
    name: t('common.printing'),
    dataBase: 'test',
  },
]);

