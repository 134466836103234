import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withFieldSubmit } from '../../formik';
import withName from '../hoc/withName';

const Textarea = (props) => {
  const { onChangeFormik, onChange, onBlur, submit, bold, editorStyles, setInitValue, initValue } = props;

  useEffect(() => {
    if (setInitValue) {
      setInitValue(initValue);
    }
  }, [initValue]);

  return (
    !bold
      ?
      <BlockEditor
        label='Memo'
        modules={bold ? null : Textarea.modules}
        formats={'code-block'}
        bounds={'.app'}
        theme='snow'
        onChange={onChange || onChangeFormik}
        onBlur={submit ? (e) => submit(e) : onBlur ? onBlur : null}
        {...props}
        value={props.value}
      />
      :
      <BlockEditor
        label='Memo'
        modules={Textarea.modules = {
          toolbar: [
            editorStyles || ['bold'],
          ],
        }}
        formats={'code-block'}
        bounds={'.app'}
        theme='snow'
        onChange={onChange || onChangeFormik}
        onBlur={submit ? (e) => submit(e) : onBlur ? onBlur : null}
        {...props}
        value={props.value}
      />
  );
};

export default compose(
  withName,
  withFieldSubmit
)(Textarea);

const BlockEditor = styled(ReactQuill)`
height: ${({ height }) => (height ? height : 300)}px;


  .ql-container{
    margin: 0px;
    margin-top: 10px;
    position: relative;
    background: #ffffff;
    height: ${(props) => (props.height ? (parseInt(props.height) - 45) + 'px' : '100px')};
    width: ${(props) => (props.width ? props.width : '88%')};
    overflow-y: auto;
    border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.12);
    & .ql-editor {
      border: 1px solid #ececec;
      background: ${({ editorBgColor }) => editorBgColor || 'inherit'};
      font-weight: ${({ editorFontWeight }) => editorFontWeight || 'inherit'};
    }
}

  .ql-formats {

    margin-right: 0px !important;

    .ql-picker {
      margin: 0 5px 0 5px;
      width: 120px !important;
    }

    .ql-color-picker {
      width: 40px !important;
    }

    .ql-icon-picker {
      width: 40px !important;
    }

    .ql-picker-label {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: 0;

      & svg {
        height: 20px !important;
      }
    }

  }

  button, span.ql-icon-picker, .ql-picker-label, .ql-font, .ql-color-picker {
    height: 36px !important;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0 5px;

    & svg {
      height: 20px !important;
    }

  }

  button, span.ql-icon-picker, .ql-picker-label {
    display: flex !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  button, .ql-picker {
    width: 36px !important;
  }

  .ql-picker.ql-size {
    height: 36px !important;
    width: 100px !important;
  }
  .ql-toolbar.ql-snow {
    padding: 0px;
  }

  .ql-font {
    width: 100% !important;
    .ql-picker-label {
      min-width: 100% !important;
    }
  }



  button.ql-active, .ql-active, .ql-font, .ql-size {
    background: #fff !important;
    border-radius: 4px;
    color: #000 !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);

  }
// .ql-picker, .ql-bold, .ql-italic, .ql-underline {
//   border-radius: 1px;
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
//   border: solid 0.3px #dbdbdb;
//       display: flex;
//     margin: 0;
// }


.ql-toolbar, .ql-snow, .ql-formats {
  border: none !important;
}

.ql-toolbar{
  padding-left: 0px !important;
  margin-left: -3px;
}

// .ql-color, .ql-bold, .ql-italic, .ql-underline {
//   margin-bottom: 10px;
//   background: #ffffff !important;
// }

`;

Textarea.modules = {
  toolbar: [
    [{ color: [] }],
    ['bold'],
  ],
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */

/*
 * PropType validation
 */

Textarea.propTypes = {
  value: PropTypes.string.isRequired, //Array format on bottomd,
};

Textarea.defaultProps = {
  value: '',
};

/*
  Example
    <Textarea value='Some'/>
*/
