import customerParams from './customer/info';
import articleParams from './product/info';
import procurementParams from './procurement/info';
import offerParams from './offer/info';
import orderParams from './order/info';
import invoiceParams from './invoice/info';
import debitorParams from './debitor/info';
import optionsParams from './options/info';
import statisticParams from './statistic/info';

import productionParams from './production/info';

export default (t) => [customerParams(t), articleParams(t)].concat(productionParams(t), orderParams(t), invoiceParams(t), offerParams(t), debitorParams(t), optionsParams(), statisticParams(t), procurementParams(t));
