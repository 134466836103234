import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { Button, Input, Textarea } from 'components/elements';
import Query from 'components/graphql/Query';
import Formik from 'components/formik';
import { LoadingWrap } from 'style/default';

import phone from 'style/order/home-telephone.png';
import tel from 'style/order/phone.svg';
import faximg from 'style/order/fax.svg';
import emailIcon from 'style/order/email.svg';
import { getFA_Customer, getFA_CustomerCreatedOrderSpecialInCustomerInfo } from '../../qql/query';
import { updateFA_CreatedOrderForCustomers } from '../../qql/mutation';
import MutationHoc from 'components/graphql/MutationHoc';
import { LoadingWrapper } from 'components/elements/Filter/Filter';
import { Dots } from 'components/Loading';

import useQuerySpecial from 'components/graphql/useQuerySpecial';

const infoParams = ({ fax, phoneG, phoneG2, phoneP, email, natel }) => ([
  {
    text: phoneG,
    icon: phone,
  },
  {
    text: natel,
    icon: tel,
  },
  {
    text: phoneG2,
    icon: phone,
  },
  {
    text: fax,
    icon: faximg,
  },
  {
    text: phoneP,
    icon: phone,
  },
  {
    text: email,
    icon: emailIcon,
  },
]);

const InfoCustomer = (props) => {
  const { linkId, closeModal, t } = props;

  const caCustomerInfo = useQuerySpecial(getFA_CustomerCreatedOrderSpecialInCustomerInfo, {
    variables: { id: linkId },
  });

  const { caCashierId, caRezeptrNr, caDeliveryTime, caDeliveryType, caUser, comment, customerLink } = caCustomerInfo.data;

  return (
    <Query skip={!customerLink} query={getFA_Customer} variables={{ id: customerLink }} fetchPolicy='cache-first'>
      {({ data, client, loading, error }) => {
        const newParams = infoParams(data || {});
        const renderContactsInfo = newParams.map(({ text, icon }, index) => {
          return <Col lg={6}><ContactsBlock index={index}> <img src={icon} alt={text} width={18} height={18} /><span> {text || '-'} </span></ContactsBlock></Col>;
        });
        // console.log({ data, customerLink, ...props })
        if (loading) return <LoadingWrapper><Dots radius='25px' /></LoadingWrapper>;
        return (
          <Formik initialValues={{ caCashierId, caRezeptrNr, caDeliveryTime, caDeliveryType, caUser, comment }} enableReinitialize={true} updateWithoutId onSubmit={() => { }}>
            {({ handleSubmit, handleChange, values, setFieldValue, setValues }) => {
              return (

                <Main>
                  <LoadingWrap loading={caCustomerInfo.loading} height={'30vh'}>
                    <CustomerInfoBlock>
                      <div><TitleText> {data.customerNr} - {data.nameId || data.nameCustomer}</TitleText>
                        {/* <CompanyAdressBlock>
                          <span>{data.deliveryNote || t('common.noData')}</span>
                        </CompanyAdressBlock> */}
                      </div>
                    </CustomerInfoBlock>

                    <Row style={{ width: '100%' }}>
                      <Col lg={12} style={{ width: '100%' }}>
                        <ContactsMain>
                          {renderContactsInfo}
                        </ContactsMain>
                      </Col>
                    </Row>

                    <Row>

                      <Col lg={2}>
                        <Input title={t('order.modals.cashRegisterID')} field='caCashierId' value={values.caCashierId} small type='number' />
                      </Col>
                      <Col lg={2}>
                        <Input title={t('order.modals.receiptNr')} field='caRezeptrNr' value={values.caRezeptrNr} small />
                      </Col>
                      <Col lg={2}>
                        <Input title={t('common.deliveryTime')} field='caDeliveryTime' value={values.caDeliveryTime} small />
                      </Col>
                      <Col lg={2}>
                        <Input title={t('order.orderType')} field='caDeliveryType' value={values.caDeliveryType} small type='number' />
                      </Col>

                    </Row>
                    <Row>
                      <Col lg={3}>
                        <Input title={t('order.modals.cashAssistUser')} field='caUser' value={values.caUser} />
                      </Col>
                    </Row>
                    {false && <Row>
                      <Col lg={3}>
                        <Input title={t('order.modals.webOrder')} />
                      </Col>
                    </Row>

                    }

                    <Row />
                    <Row>
                      <Col lg={12}>
                        <Textarea
                          width={'100%'}
                          height={150}
                          title={t('common.memo')}
                          value={values.comment}
                          field='comment'
                          editorBgColor={values.comment && values.comment !== '<p><br></p>' ? '#FFFC05' : '#FFFFFF'}
                          editorFontWeight='bold'
                        />
                      </Col>
                    </Row>
                    <BottomBlock>
                      <Col lg={5}>
                        <Button value={t('common.cancel')} className='black' upperCase onClick={closeModal} />
                      </Col>
                      <MutationHoc mutation={updateFA_CreatedOrderForCustomers} >
                        {({ mutation }) => {
                          return (
                            <Col lg={5}>
                              <Button value={t('common.save')} className='grey' upperCase onClick={() => {
                                mutation({ variables: { id: linkId, fields: values } });
                                closeModal();
                              }} />
                            </Col>
                          );
                        }}
                      </MutationHoc>

                    </BottomBlock>
                  </LoadingWrap>
                </Main>

              );
            }}
          </Formik>
        );
      }}
    </Query>
  );
};
export default InfoCustomer;

const Main = styled.div`
  background: #f5f5f5;
  padding: 15px 20px 20px 20px;
`;

// const CompanyAdressBlock = styled.div`
//   height: 110px;
//   border-radius: 3px;
//   box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
//   background: #ffffff;
//   color: #3c445a;
//   width: 250px;
//   margin-top: 16px;
// `;

const ContactsBlock = styled.div`
  height: 36px;
  box-shadow: 0 1px 0 0 rgba(76, 76, 76, 0.08);
  background: ${({ index }) => (
    index === 2 || index === 3 ? '#ffffff' : '#ffffff'
  )};
  width: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #e7e7e7 ;
  padding: 10px 12px;
  border: 1px solid #ececec;
  & span {
    margin-left: 5px;
  }
`;

const ContactsMain = styled.div`
  width: 100%;
	margin: 0px 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr)
`;

const TitleText = styled.span`
  font-size: 18px
  font-weight: 600;
  color: #3c445a;
  display: inline-block
  padding: 0 0 16px 0;
`;

const BottomBlock = styled(Row)`
    margin-top: 40px;
    justify-content: center;
`;

const CustomerInfoBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
