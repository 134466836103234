/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from 'components/graphql';
import { useTranslation } from 'react-i18next';
import { cloneDeep, isEqual, transform } from 'lodash';
import { encode } from 'single-byte';
// import saveAs from 'file-saver';
import { writeQuery } from 'helpers/queryOperations';
import reduce from 'helpers/reduceGraphqlData';

import { Modal, ErrorModal } from 'components/elements';
import Addition from 'components/List/Additional';

import Items from 'components/List/Items';
import withPDFActions from 'components/viewPDF/withPDFActions';

import {
  listFA_CreatedOrderForCustomer,
  getFA_GetOrderPositions,
  getFA_CustomerCreatedOrder
} from '../qql/query';
import { editOrder, doFA_DeleteOrder } from '../qql/mutation';
//import useDeleteCustomerFromList from '../helpers/useDeleteCustomerFromList';

import {
  OrderCreatingModal,
  WarningModal,
  InfoCustomer,
  ConfirmModal,
} from './modals';
import Query from 'components/graphql/Query';
import useQuerySpecial from 'components/graphql/useQuerySpecial';
import useDelivery from '../helpers/useDelivery';

import Filter from '../../../components/elements/Filter/Filter';

import { Main } from './pages';

import { tableData } from './params';
import { EXPORT_WA } from './gql';
import moment from 'moment';
import LoadingModal from '../../../components/Loading/LoadingModal';
import { editLeftGridColors, leftGridColors } from '../info';

//import MainList from '../../../components/mainList/MainList';

const EditOrderMain = (props) => {
  const [errorText, setErrorText] = useState(null);
  const formikRef = useRef(null);
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [chooseCustomerIndex, changeChooseCustomerIndex] = useState(0);
  const [ordersDate, setOrdersDate] = useState(null)
  const [ordersCustomerId, setOrdersCustomerId] = useState(null)
  const { linkId, contex, writeContex } = props;
  const exportWAcustomerDate = props.contex.filter[1]?.column === 'date' ? props.contex.filter[1]?.value : 1;
  const [t] = useTranslation();
  const [chooseCustomerInfo, changeChooseCustomerInfo] = useState({});
  const customerDate = chooseCustomerInfo.date || null;
  const [exportWA, { data: exportData, loading: exportLoading }] = useMutation(EXPORT_WA, {
    variables: {
      filter: [
        { column: 'folderName', value: 'date' },
        { column: 'date', value: exportWAcustomerDate || moment().add(1, 'days').format('YYYY-MM-DD') },
      ],
    }
  });
  const { filterData, filterRaw, filterQuery, restContexData } = props.subSectionInfo;

  const [editOrderMutation] = useMutation(editOrder);
  const [deleteOrderMutation] = useMutation(doFA_DeleteOrder);
  const {
    sectionInfo,
    client,
    sendPDFIntoPrinter,
    showPDFPreview,
    sendPDFIntoEmail,
  } = props;
  // console.log(showPDFPreview);
  const [isOpenCustomerInfoModal, changeOpenCustomerInfoModal] = useState(false);
  const [isLoadingModalStatus, changeLoadingModalStatus] = useState(false);
  const [isWarningModalStatus, changeWarningModalStatus] = useState(false);
  const [confirmedFilterInfo, setConfirmedFilterInfo] = useState(JSON.parse(localStorage.getItem('order/edit')) || []);
  const [isConfirmModalDeleteOrder, setConfirmModalDeleteOrder] = useState(
    false
  );
  const [isConfirmModalSaveOrder, setConfirmModalSaveOrder] = useState(false);
  const [clickedOrder, setClickedOrder] = useState({
    clickedItem: null,
    clickedIndex: null,
  });
  const [childDateInterval, setChildDateInterval] = useState(null)
  const localDataRef = useRef(null)
  const refetchRef = useRef(null)
  // const { deleteCustomerFromList } = useDeleteCustomerFromList({
  //   ...props,
  //   chooseCustomerIndex,
  //   changeChooseCustomerInfo,
  //   changeChooseCustomerIndex,
  //   query: listFA_CreatedOrderForCustomer,
  //   link: 'order/edit',
  // });
  const variables = { id: linkId };
  const queryProductsParams = {
    client,
    variables,
    query: getFA_GetOrderPositions,
  };
  const deliveryProps = useDelivery({ ...props, queryProductsParams });
  const { orderType } = chooseCustomerInfo;
  const [memoryProductData, setMemoryProductData] = useState([]);

  const orderInfo = useQuerySpecial(getFA_CustomerCreatedOrder, {
    variables: variables,
    skip: !variables.id,
  });
  const [initialOrderInfo] = useState({
    linkId: linkId,
    orderNrSAP: '',
    deliveryTime: '',
    textareaValue: ' ',
  });

  //Fix for problem with moving search settings from another subsystem
  delete contex.params.search;

  useEffect(() => {
    setOrdersDate(null)
    setOrdersCustomerId(null)
  }, [linkId])

  const setFilterInfo = (filter, restContex = []) => {
    writeContex({ ...contex, filter: [...filter], restContex: [...restContex] });
  };

  const setOrderDateContex = (d) => {
    writeContex({ ...contex, date: { ...contex.date, date: d } });
  };

  const pdfParams = {
    reportId: 'orderPreview',
    AOrderID: `${linkId}`,
    AUserFormularID: '0',
  };

  // const getBlockColor = ({ isDelete }) => {
  //   if (isDelete) {
  //     return '#d07177';
  //   } else {
  //     return null;
  //   }
  // };

  const getTableIndex = (index, item) => {
    changeChooseCustomerInfo(item);
    changeChooseCustomerIndex(index);
    props.history.replace({
      pathname: `/order/edit/${item.id}`,
    });
  };

  const editOrderOperation = async (values) => {
    //Local variables of query
    try {
      const productsFromCache = client.cache.readQuery({
        query: getFA_GetOrderPositions,
        variables: variables,
      });
      const reducedProducts = reduce(productsFromCache);
      const productsForOrder = reducedProducts
        .productTable.filter(({ quantity }) => quantity)
        .map((item) => {
          const {
            virtualPositionLink,
            productLink,
            quantity,
            price,
            discount,
            taxCode,
            indTextProduction,
            indTextDeliveryNote,
          } = item;
          return {
            virtualPositionLink: virtualPositionLink,
            productLink: productLink,
            quantity: +quantity,
            price: +price,
            discount: +discount,
            taxCode: +taxCode,
            indTextProduction: indTextProduction,
            indTextDeliveryNote: indTextDeliveryNote,
          };
        });
      if (deliveryProps.isDelivery) {
        const delivery = {
          virtualPositionLink: 2,
          quantity: 1,
          price: +deliveryProps.deliveryInfo.price,
          discount: null,
          taxCode: 1,
        };
        productsForOrder.push(delivery);
      }
      //If order havent quantity or total of all fields
      // console.log({ productsForOrder, reducedProducts })
      if (!productsForOrder.length || (productsForOrder.length !== reducedProducts.productTable.length)) {
        changeWarningModalStatus(true);
      } else {
        changeLoadingModalStatus(true);
        const customerId = chooseCustomerInfo.customerLink;
        const dataForOrder = {
          orderNr: linkId,
          userId: 774,
          orderType: orderType ? +orderType : 1,
          customerId: customerId,
          date: customerDate,
          textarea: values.memo,
          orderRef: values.orderNrSAP,
          deliveryTime: values.deliveryTime,
          productList: productsForOrder,
        };
        // console.log({ dataForOrder })
        try {
          const some = await editOrderMutation({
            variables: { args: { ...dataForOrder, date: ordersDate || dataForOrder.date, customerId: ordersCustomerId || dataForOrder.customerId } }
          })

          refetchRef.current();
          changeLoadingModalStatus(false);
          refetchRef?.current()
        } catch (err) {
          changeLoadingModalStatus(false);
          console.log(err);
        }
      }

      //console.log(data);
    } catch (err) {
      console.log(err);
      //link={'https://s3-eu-west-1.amazonaws.com/hstemp/8a87d970-1565-4f20-a29f-8b5c351eb2df/factura.pdf'}
    }
  };

  const onCompleted = (data) => {

    try {
      const queryData = data.fakturaAssist.getFA_GetOrderPositions;
      setMemoryProductData(cloneDeep(queryData.productTable));
      const deliveryItem = queryData.productTable.find(
        (item) => item.virtualPositionLink === 2
      );
      if (Object.keys(deliveryItem || {}).length) {
        const cacheData = cloneDeep(queryData);
        cacheData.productTable = queryData.productTable.filter(
          (item) => item.virtualPositionLink !== 2
        );
        writeQuery({
          ...queryProductsParams,
          changedData: cloneDeep(cacheData),
        });
        deliveryProps.setDelivery(true);
        setMemoryProductData(cloneDeep(cacheData.productTable));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onConfirmSaveOrder = async (some, s) => {
    const values = formikRef.current.formikRef.state.values;
    const { clickedIndex, clickedItem } = clickedOrder;
    setConfirmModalSaveOrder(false);
    await editOrderOperation(values);
    getTableIndex(clickedIndex, clickedItem);
  };

  const onNoConfirmSaveOrder = () => {
    const { clickedIndex, clickedItem } = clickedOrder;
    getTableIndex(clickedIndex, clickedItem);
    setConfirmModalSaveOrder(false);
  };

  const checkIsOrderSaved = ({ index, item, data }) => {
    try {
      if (data.length) {
        const isCreateTemporaryOrder = !isEqual(
          data,
          cloneDeep(memoryProductData)
        );
        if (isCreateTemporaryOrder) {
          setConfirmModalSaveOrder(true);
        } else {
          //isCreateTemporaryOrder && alert('Save u order');
          setMemoryProductData(data);
          getTableIndex(index, item);
        }
      }
    } catch (err) {
      //
    }
  };

  const onConfirmDeleteOrder = async () => {
    try {
      const dataForMutation = {
        orderId: linkId,
        userId: 1,
        deliveryId: linkId,
      };
      await deleteOrderMutation({ variables: { args: dataForMutation } });
      //deleteCustomerFromList();
      const сacheData = client.cache.readQuery({
        query: listFA_CreatedOrderForCustomer,
        variables: contex,
      });
      const clientsList = cloneDeep(reduce(сacheData));
      const activeClient = { ...cloneDeep(clientsList[chooseCustomerIndex]) };
      activeClient.isDelete = true;
      clientsList[chooseCustomerIndex] = activeClient;
      writeQuery({
        client,
        query: listFA_CreatedOrderForCustomer,
        variables: contex,
        changedData: cloneDeep(clientsList),
      });
      client.cache.readQuery({
        query: listFA_CreatedOrderForCustomer,
        variables: contex,
      });
      setConfirmModalDeleteOrder(false);
      // refetch if isDelete=false
      const { restContex } = contex
      if (restContex) {
        const withDeletedColumn = restContex.find(({ column }) => column === 'isDelete')
        if (withDeletedColumn && !withDeletedColumn.value) {
          // clientsList.splice(chooseCustomerIndex, 1)
          refetchRef?.current()
        }
      }
    } catch (err) {
      setErrorText(err.toString());
    }
  };

  const tranformContext = () => {
    const bool = contex.filter.some(i => i.column === 'secondDate');
    const newContext = {
      ...contex, filter: [...contex.filter, ...(
        bool === false && childDateInterval?.secondDate && childDateInterval?.secondDate !== '' // condition
          ? [
            { column: 'secondDate', value: childDateInterval?.secondDate }
          ] // true
          : []) // false
      ].map(obj => [{ column: 'date', value: childDateInterval?.date || contex.filter?.find((c) => c.column === 'date')?.value || moment().add(1, 'days').format('YYYY-MM-DD') }].find(o => o.column === obj.column) || obj)
    };

    return newContext
  };

  const [isLeftGridEmpty, setIsLeftGridEmpty] = useState(true);
  const onDetermineIsGridEmpty = (value) => {
    setIsLeftGridEmpty(value);
  };

  return (
    <>
      <ErrorModal errorText={errorText} />
      <Modal isOpen={exportLoading} Component={<LoadingModal />} />
      <Modal
        isOpen={isOpenCustomerInfoModal}
        closeModal={() => changeOpenCustomerInfoModal(false)}
        linkId={+linkId}
        Component={
          <InfoCustomer
            t={t}
            closeModal={() => changeOpenCustomerInfoModal(false)}
            linkId={+linkId}
          />
        }
      />
      <Modal
        isOpen={isLoadingModalStatus}
        headerText={t('common.loading')}
        width='350px'
        height='220px'
        Component={<OrderCreatingModal t={t} />}
      />
      <Modal
        isOpen={isWarningModalStatus}
        closeModal={() => changeWarningModalStatus(false)}
        headerText={t('common.warning')}
        width='350px'
        height='220px'
        Component={
          <WarningModal
            t={t}
            closeModal={() => changeWarningModalStatus(false)}
          />
        }
      />
      <Modal
        isOpen={isConfirmModalSaveOrder}
        closeModal={() => setConfirmModalSaveOrder(false)}
        headerText={t('common.confirm')}
        width='450px'
        height='220px'
        Component={
          <ConfirmModal
            t={t}
            closeModal={onNoConfirmSaveOrder}
            onConfirm={onConfirmSaveOrder}
            text={t('order.modals.saveChanges')}
          />
        }
      />
      <Modal
        isOpen={isConfirmModalDeleteOrder}
        closeModal={() => setConfirmModalDeleteOrder(false)}
        headerText={t('common.confirm')}
        width='450px'
        height='220px'
        Component={
          <ConfirmModal
            t={t}
            closeModal={() => setConfirmModalDeleteOrder(false)}
            onConfirm={onConfirmDeleteOrder.bind(null,)}
            text={t('order.modals.deleteThisOrder')}
          />
        }
      />
      <Query
        skip={!+linkId}
        query={getFA_GetOrderPositions}
        variables={variables}
        onCompleted={onCompleted}
      >
        {({ data, loading, ...prevQueryProductProps }) => {
          const productTable = data.productTable || [];
          return (
            <>
              <Items
                {...props}
                mainsection={props.mainsection}
                onDetermineIsGridEmpty={onDetermineIsGridEmpty}
                //Upper name
                initialSortValue='orderNr'
                withVirtualized
                graphqlParams={{
                  query: listFA_CreatedOrderForCustomer,
                  variables: tranformContext(),
                  fetchPolicy: 'network-only'

                }}
                queryCb={({ data, refetch }) => {
                  if (!isEqual(data, localDataRef.current)) {
                    localDataRef.current = data;
                    writeQuery({
                      client,
                      query: listFA_CreatedOrderForCustomer,
                      variables: props.contex,
                      changedData: cloneDeep(data),
                    })
                    refetchRef.current = refetch;
                  }
                }}
                // name={t('order.orderEdit')}
                data={null}
                leftGridData={localDataRef.current}
                tableData={contex.filter?.[0]?.value === 'date' ? tableData(t).filter((item) => item.dataBase !== 'date') : tableData(t)}
                // getBlockColor={getBlockColor}
                leftGridColors={editLeftGridColors}
                getTableIndex={(index, item) => {
                  changeChooseCustomerInfo(item);
                  setClickedOrder({
                    clickedIndex: index,
                    clickedItem: item,
                  });
                  //checkIsOrderSaved({ index, item, data: productTable });
                  getTableIndex(index, item);
                }}
                operations={({ isDelete }) => {
                  if (isDelete) {
                    return (
                      {
                        info: () => changeOpenCustomerInfoModal(true),
                      }
                    );
                  } else {
                    return (
                      {
                        info: () => changeOpenCustomerInfoModal(true),
                        delete: () => setConfirmModalDeleteOrder(true),
                      }
                    );
                  }
                }}
                operationAvailability={{
                  delete: { field: 'invoiceNr', check: (value) => !value }
                }}
                withSearch
                selectById
                isOpenFilter={isOpenFilter}
                setOpenFilter={setOpenFilter}
                linkId={+linkId}
                // calendarDate={props.contex.date.date}
                setCalendarDate={setOrderDateContex}
                searchColumns={['orderNr', 'actualName', 'customerNr']}
                FilterComponent={
                  <Filter
                    t={t}
                    mainsection={props.mainsection}
                    setFilterInfo={setFilterInfo}
                    filterData={filterData}
                    graphqlParams={filterQuery}
                    filterRaw={filterRaw}
                    filterInfo={contex.filter}
                    restContexInfo={contex.restContex}
                    restContexData={restContexData}
                    isOpenFilter={isOpenFilter}
                    setOpenFilter={setOpenFilter}
                    getSecondDateVal={(inteval) => setChildDateInterval(inteval)}
                    confirmedFilterInfo={confirmedFilterInfo}
                    setConfirmedFilterInfo={setConfirmedFilterInfo}
                    subsection={props.subsection}

                  />
                }
                getValueOnStartById={getTableIndex}
                cutHeight={271}
                width={814}
                specialWidth='100%'
              />
              {/* don't show right part when left grid is empty */
                (!isLeftGridEmpty) && <Addition
                  text={
                    chooseCustomerInfo ? chooseCustomerInfo.actualName : '-'
                  }
                  menuObj={[
                    { link: '/order/edit', name: t('order.orderEdit') },
                  ]}
                  pdfParams={pdfParams}
                  customActions={true}
                  reportForms={[
                    8,
                    43,
                    44,
                    55,
                    16,
                    40,
                    62,
                    29,
                    30,
                  ]}
                  actions={{
                    printReport: () => {
                      sendPDFIntoPrinter(
                        pdfParams,
                        chooseCustomerInfo.pdfLink
                      );
                    },
                    preview: (actualPDFParams) => {
                      showPDFPreview((actualPDFParams && { ...actualPDFParams, reportId: 'orderPreview' }) || { ...pdfParams, reportId: 'orderPreview' }, (actualPDFParams && +actualPDFParams.reportId) === 44 && chooseCustomerInfo.pdfLink);
                    },
                    reportForms: () => {
                      props.showFormsType(pdfParams, [
                        8,
                        43,
                        44,
                        55,
                        16,
                        40,
                        62,
                        29,
                        30,
                      ]);
                    },
                    email: () => {
                      sendPDFIntoEmail(pdfParams);
                    },
                    history: 'default',
                  }}
                  linkId={linkId}
                  t={t}
                  historyFormTypeID={sectionInfo.historyFormTypeID}
                  // loading={customerListQueryProps.loading}
                  isShow={linkId}
                  noShowText='No orders'
                >
                  <Main
                    {...props}
                    {...deliveryProps}
                    ordersDate={ordersDate}
                    setOrdersDate={setOrdersDate}
                    ordersCustomerId={ordersCustomerId}
                    setOrdersCustomerId={setOrdersCustomerId}
                    data={data}
                    loading={loading}
                    isOpenCustomerInfoModal={isOpenCustomerInfoModal}
                    changeOpenCustomerInfoModal={
                      changeOpenCustomerInfoModal
                    }
                    chooseCustomerInfo={chooseCustomerInfo}
                    editOrderOperation={editOrderOperation}
                    date={customerDate}
                    t={t}
                    id={+linkId}
                    onCompleted={onCompleted}
                    {...prevQueryProductProps}
                    productTable={productTable}
                    orderType={orderType}
                    orderInfo={orderInfo.data}
                    initialOrderInfo={initialOrderInfo}
                    formikRef={formikRef}
                  />
                </Addition>
              }
            </>
          );
        }}
      </Query>
    </>
  );
};

export default withPDFActions(EditOrderMain);
