import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { phoneInputs } from './params';
import { Dropdown, Input, Checkbox, Textarea } from '../../../components/elements';
import Formik from '../../../components/formik';

import listFA_CustomerTourplan from './qql/listFA_CustomerTourplan';

export default (props) => {
  const { t } = props;
  return (
    <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        const phoneLabel = phoneInputs(t).map((item, index)=>{
          return(<Row>
            <Col width='170px'>
              <Input small {...item[0]} value={values[item[0].field]}/>
            </Col>
            <Col width='330px'>
              <Dropdown
                {...item[1]}
                valueField = 'id'
                labelField = 'name'
                defaultValueField = {values[item[1].field]}
                graphqlParams={{ query: listFA_CustomerTourplan }}
              />
            </Col>
            <Col width='170px'>
              <Input type='number' small {...item[2]} value={values[item[2].field]}/>
            </Col>
            <Col width='170px'>
              <Input small {...item[3]} value={values[item[3].field]}/>
            </Col>
          </Row>);
        }
        );

        const specialForms = ()=>{
          return(
            <Row>
              <Col width='170px'>
                <Input type='number' title='TagSplitting1' field='toolboxDeliveryNo1' value={values.toolboxDeliveryNo1}/>
                <Input type='number' title='TagSplitting2' field='toolboxDeliveryNo2' value={values.toolboxDeliveryNo2}/>
                <Input type='number' title='TagSplitting3' field='toolboxDeliveryNo3' value={values.toolboxDeliveryNo3}/>
                <Input type='number' title='TagSplitting4' field='toolboxDeliveryNo4' value={values.toolboxDeliveryNo4}/>
                <Input type='number' title='TagSplitting5' field='toolboxDeliveryNo5' value={values.toolboxDeliveryNo5}/>
                <Input type='number' title='TagSplitting6' field='toolboxDeliveryNo6' value={values.toolboxDeliveryNo6}/>
                <Input type='number' title='TagSplitting7' field='toolboxDeliveryNo7' value={values.toolboxDeliveryNo7}/>
                <Input type='number' title='TagSplitting8' field='toolboxDeliveryNo8' value={values.toolboxDeliveryNo8}/>
                <Input type='number' title='TagSplitting9' field='toolboxDeliveryNo9' value={values.toolboxDeliveryNo9}/>
                <Input type='number' title='TagSplitting10' field='toolboxDeliveryNo10' value={values.toolboxDeliveryNo10}/>
                <Checkbox value={values.toolBoxUseOwnNrInSplitting} field='toolBoxUseOwnNrInSplitting' title={'Splitting Kunden'}/>
              </Col>
            </Row>
          );
        };

        return (
          <MainBlock>
            <Row>
              <Col width='800px'>
                {phoneLabel}
                <Row>
                  <Col lg={4}>
                    <Checkbox value={values.alwaysTourSchedule} field='alwaysTourSchedule' title={t('customer.isDeliveryTour')}/>
                  </Col>
                </Row>
                <Row>
                  <Col width='100%'>
                    <Textarea
                      title={t('customer.tourNote')}
                      value={values.tourPlanDescription}
                      onBlur={handleSubmit}
                      width='95%'
                      height='300px'
                      field='tourPlanDescription'
                    />
                  </Col>
                </Row>
              </Col>
              <Col width='800px'>
                {values.isSpecialCustomer && specialForms()}
              </Col>
            </Row>
          </MainBlock>
        );
      }}
    </Formik>
  );
};
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;

