import React from 'react';

import General from './table/General.js';
import Bank from './table/Bank.js';

import { Transition } from '../../../../../components/elements';
//import Formik from '../../../components/formik';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <React.Fragment>
      <Transition index={4} text={t('common.general')}>
        <General {...props}/>
      </Transition>
      <Transition index={5} text={t('common.bank')}>
        <Bank {...props}/>
      </Transition>
    </React.Fragment>
  );
};
