
import styled from 'styled-components';
import React from 'react';
import { Row, Col } from 'style';
import { Button } from 'components/elements';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import infoValidationDates from '../qql/infoValidationDates';

export default (props) => {
  const [t] = useTranslation();
  const { values, formikDays, setOpen, save, id, setInfo, setId, setIndex, linkId, validationValue, setTouched, setFieldValue, setFieldError } = props;
  const QUERY_infoValidationDatest = useQuery(infoValidationDates, { variables: { customerId: +linkId, fromDate: values.fromDate, toDate: values.toDate, id: id ? id : null } });
  const periodCompatibility = !QUERY_infoValidationDatest?.data?.fakturaAssist?.infoValidationDates[0];
  const dateComparing = values?.fromDate && values?.toDate && Date.parse(values?.toDate) < Date.parse(values?.fromDate);

  return (
    <Footer>
      <Col width='50%'>
        <div style={{ marginRight: '15px' }}>
          <Button
            float='right'
            className={'grey-btn'}
            width={'190px'}
            onClick={()=>{
              setOpen(false);
              setId(null); setIndex(null); setInfo(null);
            }}
            value={t('common.cancel')}/>
        </div>
      </Col>
      <Col width='50%'>
        <div style={{ marginLeft: '15px' }}>
          {QUERY_infoValidationDatest.loading ?
            <i className='fas fa-spinner fa-pulse' />
            :
            <Button
              float='left'
              className={periodCompatibility ? 'black' : 'grey-btn'}
              width={'190px'}
              disabled={values.fromDate === null}
              noActive={validationValue() || !periodCompatibility}
              noActiveFunc = {() => {
                if(dateComparing) {
                  return setFieldError('toDate', t('common.validation.endDateNotBeLessThenStartDate'));
                }
                if(!periodCompatibility) {
                  return setFieldError('period', t('common.validation.incompatiblePeriods'));
                }
                setFieldValue(null);
                setTouched({ noActiveBtnClick: true });
              }}
              onClick={() => {
                setTouched({});
                periodCompatibility && save({
                  isMonday: formikDays.values.isMonday,
                  isWednesday: formikDays.values.isWednesday,
                  isFriday: formikDays.values.isFriday,
                  isTuesday: formikDays.values.isTuesday,
                  isThursday: formikDays.values.isThursday,
                  isSaturday: formikDays.values.isSaturday,
                  isSunday: formikDays.values.isSunday,
                }, values, id);
              }}
              value={t('common.save')}/>}
        </div>
      </Col>
    </Footer>
  );
};

const Footer = styled(Row)`
  margin-top: 20px;
`;


