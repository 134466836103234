
import {
  getFA_Suppliers,
  listFA_Suppliers,
  getFA_Goods,
  listFA_Goods,

} from './qql/query';

import {
  updateFA_Suppliers,
} from './qql/mutation';

import { tableDataSuppliers, tableDataGoods } from './params';

import Address from './suppliers/table/address';
import Conditions from './suppliers/table/conditions';
import Forms from './suppliers/table/forms';
import CRM from './suppliers/table/crm';
import Goods from './suppliers/table/goods';

import General from './goods/general';

const filterData = (t) => [{
  title: t('common.filter.activeCustomers'),
  field: 'IsAktiviert',
  value: 1,
},
{
  title: t('common.filter.inactiveCustomers'),
  field: 'IsAktiviert',
  value: 0,
  
}];

const filterTypeNameSuppliers = JSON.parse(localStorage.getItem('procurement/suppliers'));
const filterTypeNameGoods = JSON.parse(localStorage.getItem('procurement/goods'));

export default (t) => ([
  {
    name: t('procurement.procurement'),
    link: 'procurement/suppliers',
    listQuery: listFA_Suppliers,
    itemQuery: getFA_Suppliers,
    tableData: tableDataSuppliers(t),
    updateItem: updateFA_Suppliers,
    defaultFilterParams: { filter: filterTypeNameSuppliers || [{ column: 'IsAktiviert', value: '1' }] },
    filterData: filterData(t),
    mainItem: 'id',
    withDefaultFunction: true,
    withDefaultParams: true,
    searchColumns: ['suppliersNumber', 'nameSupplier'],
    subSection: [
      { name: t('procurement.suppliers.address'),
        link: 'address',
        Component: Address,
      },
      { name: t('procurement.suppliers.conditions'),
        link: 'conditions',
        Component: Conditions,
  
      },
      { name: t('procurement.suppliers.forms'),
        link: 'forms',
        Component: Forms,
      },
      { name: t('procurement.suppliers.crm'),
        link: 'crm',
        Component: CRM,
      },
      { name: t('procurement.suppliers.goods'),
        link: 'goods',
        Component: Goods,
      },
    ],
  },

  {
    name: t('invoice.invoice'),
    link: 'procurement/goods',
    listQuery: listFA_Goods,
    itemQuery: getFA_Goods,
    tableData: tableDataGoods(t),
    updateItem: updateFA_Suppliers,
    defaultFilterParams: { filter: filterTypeNameGoods || [{ column: 'IsAktiviert', value: '1' }] },
    filterData: filterData(t),
    mainItem: 'id',
    searchColumns: ['articleNr', 'name'],
    subSection: [
      { name: t('General'),
        link: 'general',
        Component: General,
      },
      { name: t('Stock'),
        link: 'stock',
        Component: Conditions,
  
      },
    ],
  },

]);

//Test
// export default (t) => ([
//   {
//     link: 'procurement',
//     separateSection: [
//       // {
//       //   name: 'orderCreate',
//       //   link: 'create',
//       //   listQuery: listFA_CustomersListForCreatingOrdersOnDate,
//       //   mainItem: 'id',
//       //   filterData: orderCreateFilterData(t),
//       //   filterRaw: true,
//       //   filterQuery,
//       //   Handler: CreateOrder,
//       //   defaultFilterParams: filterCreate,
//       //   withParams: true,
//       //   withoutMutation: true,

//       // },
//       // {
//       //   name: 'orderEdit',
//       //   link: 'edit',
//       //   listQuery: listFA_CreatedOrderForCustomer,
//       //   mainItem: 'id',
//       //   filterData: orderEditFilterData(t),
//       //   filterRaw: true,
//       //   Handler: EditOrder,
//       //   defaultFilterParams: filterEdit,
//       //   withParams: true,
//       //   withoutMutation: true,
//       //   searchColumns: ['nameCustomer', 'customerNr'],

//       // },
//       {
//         link: 'procurement',
//         Handler: <div>112</div>,

//       },

//     ],
//   },
// ]);
