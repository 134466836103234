
import gql from 'graphql-tag';

export default gql`
query listFA_Production($day: String! , $fromDate: String, $toDate: String){
    fakturaAssist{
    listFA_Production(day: $day, fromDate: $fromDate, toDate: $toDate){
		id
		name
		isPrint
		reportID
		printFld
		previewFld
		addDay
    }
    
  }
}`;
