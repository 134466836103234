import React from 'react';
import { ForgotPassword } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';

import { withTranslation } from 'react-i18next';

import AuthContainer, { LinkButton } from './AuthContainer';
import LoginInput from './elements/LoginInput.js';
import { Button } from 'components/elements';

class MyRequireNewPassword extends ForgotPassword {
	state = {
	  username: '',
	  isSubmitting: false,
	  err: { errField: '', errMessage: '' },
	  isSendCode: false,
	  code: '',
	  newPassword: '',
	}
	setSubmitState = (val) => this.setState({ isSubmitting: val })

	goBack = () => {
	  this.setState({ username: '' });
	  this.changeState('signIn');
	}

	onChange = (e) => {
	  const { name, value } = e.target;
	  this.setState({ [name]: value });
	  };

	sendCode = async (e) => {
	  e.preventDefault();
	  const { username } = this.state;
	  this.setSubmitState(true);
	  try {
	    await Auth.forgotPassword(username);
	    this.setState({ isSendCode: true });
	    this.changeState('forgotPassword');
	  } catch(err) {
	    if(err.code === 'UserNotFoundException') {
	      this.setState({ err: { errField: 'username', errMessage: 'User not found' } });
	    } else if(err.code === 'LimitExceededException') {
	      this.setState({ err: { errField: 'username', errMessage: 'Try limit' } });
	    }
	  } finally {
	    this.setSubmitState(false);
	  }
	}

	resetPassword = async (e) => {
	  e.preventDefault();
	  const { username, code, newPassword } = this.state;
	  this.setSubmitState(true);
	  try {
	    await Auth.forgotPasswordSubmit(username, code, newPassword);
	    this.setState({ username: '' });
	    this.changeState('signIn');
	  } catch(err) {
	    if(err.code === 'CodeMismatchException') {
	      this.setState({ err: { errField: 'username', errMessage: 'Invalid code' } });
	    } else if(err.code === 'LimitExceededException') {
	      this.formApi.setError('username', 'Try limit');
	    }
	  } finally {
	    this.setSubmitState(false);
	  }
	}

	showComponent() {
	  const { err, isSendCode, code, newPassword } = this.state;
	  return(
	    <AuthContainer onSubmit={isSendCode ? this.resetPassword : this.sendCode}>
	      <span style={{ color: '#434343' }}>
	        {'Passwort zurücksetzen'}
	      </span>
	      {isSendCode ? <><LoginInput
	        type='text'
	        placeholder='Dein code'
	        onChange={this.onChange}
	        name='code'
	        err={err}
	        value={code}
	      />
		  <LoginInput
	        type='text'
	        placeholder='Neue Passwort'
	        onChange={this.onChange}
	        name='newPassword'
	        err={err}
	        value={newPassword}
	      /></> : <LoginInput
		  type='text'
		  placeholder='Dein login'
		  onChange={this.onChange}
		  name='username'
		  err={err}/>}
				  <Button style={{ marginTop: 5 }} value={'ZURÜCKSETZEN'} onClick={isSendCode ? this.resetPassword : this.sendCode} type='submit' width='300px' className={'red-btn'} height='46px' />
				  <LinkButton onClick={this.goBack}> Zurück </LinkButton>
	    </AuthContainer>
	  );
	}
}

export default withTranslation()(MyRequireNewPassword);
