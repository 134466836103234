import gql from 'graphql-tag';

export default gql`
query listFA_CustomerView_fkCustomerDefaultOrderKinds($languageId: Int){
    fakturaAssist{
    listFA_CustomerView_fkCustomerDefaultOrderKinds(languageId: $languageId){
		id
		name
    }
    
  }
}`;
