import gql from 'graphql-tag';

export default gql`
query getFA_ProductionEdit($id: ID! ){
    fakturaAssist{
    getFA_ProductionEdit(id: $id){
		id
		name
		reportID
		defaultFormID
		showSemiProductsDetails
		reportGroupID
		addDay
		linkGroup
		linkSorting
		isCrosstableEasyMode
		isPrint
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		filterDefSql
		filterDefCustomerSql
		orderPositionsOnlyIndividualText
		highlightArticlesFromProductionCalculation
		isSplitDoughBeforePrint

		tourenplanDay
		isNeedSignatureOnForwardingList
		isShowProductionDescription
		isShowIndividalTexts
		isShowDeliverySplitting
		linkCustomerSorting 

		isTourenplanLS
		linkTourenplan
		isIncludeOneTimeCustomers
    }
    
  }
}`;
