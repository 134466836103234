import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import { LoadingSpinner } from 'components/Loading/StyledLoading';

import AuthContainer, { LinkButton } from './AuthContainer';
import LoginInput from './elements/LoginInput.js';
import { Button } from 'components/elements';

class MySignIn extends SignIn {
	state = {
	  username: '',
    	password: '',
	  isSubmitting: false,
	  err: { errField: '', errMessage: '' },
	}

	onChange = (e) => {
	  const { name, value } = e.target;
	  this.setState({ [name]: value });
	};

	setSubmitState = (val) => this.setState({ isSubmitting: val })

	signIn = async (e) => {
	  e.preventDefault();
	  const { username, password } = this.state;
	  if(this.state.isSubmitting) {
	    return;
	  }
	  this.setSubmitState(true);
	  try {
	    const user = await Auth.signIn(username.toLowerCase(), password);
	    //const hasAccess = checkAccess(user)
	    if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
	      this.changeState('confirmSignIn', user);
	    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
	      this.changeState('requireNewPassword', user);
	    }
	    // else if(!hasAccess) {
	    // this.setState({ err: { filed: 'username', code: 'Havent access' } });
	    // Auth.signOut()
	    // console.log(3)
	    // }
	    else {
	      this.checkContact(user);
	    }
	  } catch(err) {
	    if(err.code === 'UserNotFoundException' || err.code === 'InvalidParameterException') {
	      this.setState({ err: { errField: 'username', errMessage: err.message } });
	    } else if(err.code === 'NotAuthorizedException') {
	      switch(err.message) {
	        case 'User is disabled':
	          this.setState({ err: { errField: 'username', errMessage: err.message } });
	          break;
	        case 'Incorrect username or password.':
	          this.setState({ err: { errField: 'password', errMessage: err.message } });
	          break;
	        default:
	          this.setState({ err: { errField: 'username', errMessage: err.message } });
	          break;
	      }
	    } else if(err.code === 'NetworkError') {
	      this.setState({ err: { errField: 'username', errMessage: err.message } });
	    } else {
	      this.setState({ err: { errField: 'username', errMessage: err.message } });
	    }
	  } finally {
	    this.setSubmitState(false);
	  }
	}

	showComponent() {
	  const { err, isSubmitting } = this.state;
	  return(
	    <AuthContainer onSubmit={this.signIn}>
	      {isSubmitting ? <div><LoadingSpinner transparent height='100px' width='100px'/></div>
	        :
	        <div>
	        <span style={{ color: '#434343' }}>
	          {' '}
			  Geben Sie die erforderlichen Daten ein, um sich am <br />System anzumelden
	        </span>
	        <LoginInput
	          type='text'
	          placeholder='Dein Login'
	          onChange={this.onChange}
	          name='username'
	          err={err}
	        />
	        <LoginInput
	          type='password'
	          placeholder='Dein Passwort'
	          onChange={this.onChange}
	          name='password'
	          err={err}
	        />
	        <Button style={{display: 'flex', justifyContent: 'center', marginTop: 15 }} value={'EINLOGGEN'} onClick={this.signIn} type='submit' width='300px' className={'red-btn'} height='46px' />
	        <LinkButton onClick={() => this.changeState('forgotPassword')}>
								Passwort vergessen?
	        </LinkButton>
		  </div>
	      }
	    </AuthContainer>
	  );
	}
}

export default withTranslation()(MySignIn);
