
import gql from 'graphql-tag';

export default gql`
mutation updateFA_ProductionEditCrossTable($fields: InputFA_ProductionEditCrossTable!, $id: ID!){
    fakturaAssist{
    updateFA_ProductionEditCrossTable(fields: $fields, id: $id){
		categoryName
    }
    
  }
}`;
