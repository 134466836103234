import React from 'react';
import { Summary, TotalItem } from 'devextreme-react/data-grid';

export const FooterSum = (formatCol, colProps, i) => {
  let item;
  const { customizeText, ...summary } = formatCol?.summary || {};

  if(formatCol.summary) {
    item = (
      <TotalItem
        key={i}
        column={formatCol.dataField || formatCol.caption}
        {...formatCol.format && { valueFormat: formatCol.format }}
        customizeText={customizeText || colProps.customizeText}
        {...summary}
      />);
  }
  return item;
};

export const FooterUI = (footerColsArr, customSummary) =>
  footerColsArr.length
    ? <Summary skipEmptyValues texts={{ sum: '' }} {...customSummary && { calculateCustomSummary: customSummary }}>{footerColsArr}</Summary>
    : null;
