import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import XMLParser from 'react-xml-parser';
import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash';

import XLS from 'style/img/xls.svg';
import { IMPORT } from '../graphql/debitor';
import { LoadingWrap } from 'style/default';

import TableGrid from 'components/tableGrid/TableGrid';
import { useTranslation } from 'react-i18next';

const ImportISO = () => {
  const [t] = useTranslation();
  const [fileName, setFileName] = useState(null);

  const [getImport, { data: resultQuery, loading }] = useLazyQuery(IMPORT);

  const callQuery = (data) => {
    getImport({
      variables: {
        input: data,
      },
      fetchPolicy: 'network-only',
    });
  };

  const parseXML = (text) => {
    const xml = new XMLParser().parseFromString(text);
    const main = xml.getElementsByTagName('Ntry');
    const lengthArr = main.length;
    const arr = [];
    let obj = {};
    for (let i = 0; i < lengthArr; i++) {
      // bankId: Int
      // facturaNm: Int
      // taskDate: String
      // amount: Int
      obj = {
        // participantNo: xml.getElementsByTagName('NtryRef')[i].value,
        amount: parseFloat(
          xml.getElementsByTagName('Amt')[i].value,
        ), // betrag
        // referenceNumber: xml.getElementsByTagName('Ref')[i].value,
        facturaNm: parseInt(
          xml.getElementsByTagName('Ref')[i].value.slice(7, 13)
        ), //factura Nm
        bankId: xml.getElementsByTagName('Ref')[i].value.slice(0, 6), // bakiId
        taskDate: xml.getElementsByTagName('BookgDt')[i].children[0].value, // date (creditDate)
        // processingDate: xml.getElementsByTagName('ValDt')[i].children[0].value,
      };
      arr.push(obj);
    }
    callQuery(arr);
  };

  const parseTXT = (text) => {
    const lines = text.split('\n');
    const arr = [];
    let item = {};

    for (let line = 0; line < lines.length; line++) {
      const firtsCheck = lines[line].slice(0, 3);
      if (firtsCheck !== '999') {
        item = {
          // participantNo: lines[line].slice(4, 12),
          // referenceNumber: lines[line].slice(12, 39),
          facturaNm: parseInt(lines[line].slice(18, 25)), //factura Nm
          bankId: lines[line].slice(12, 18), // bankId
          amount: parseFloat(lines[line].slice(39, 49)) / 100, // betrag
          // taskReference: lines[line].slice(49, 59),
          taskDate: lines[line].slice(59, 65), // AufgabeDatum
          // processingDate: lines[line].slice(65, 71),
          // creditDate: lines[line].slice(71, 77),
          // microfilmNo: lines[line].slice(77, 86),
          // rejectCode: lines[line].slice(86, 87),
          // reserve: lines[line].slice(87, 96),
          // priceForDeposit: parseFloat(lines[line].slice(96, 100)) / 100,
        };
      }
      if(item.facturaNm === item.facturaNm) {
        arr.push(item);
      }
    }
    callQuery(arr);
  };

  const showFile = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if(file !== undefined) {
      const fileType = file.type.slice(5); // xml or null
      setFileName(file.name);

      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        if (fileType === 'xml') {
          parseXML(text);
        } else {
          parseTXT(text);
        }
      };
      reader.readAsText(e.target.files[0]);
    }
  };

  return (
    <LoadingWrap loading={loading}>
      <StRow>
        <Row>
          <Title lg={2}>
            {t('debitor.importISO')}
          </Title>
        </Row>
        <StyledHeader>
          <StyledHeaderCol lg={3}>
            <ImportInput for='upload-photo'>
              <input type='file' id='upload-photo' onChange={(e) => showFile(e)} style={{ opacity: 0, position: 'absolute', cursor: 'pointer' }}/>
              <FileNameBlock>{fileName}</FileNameBlock>
              <ActionIcon for='upload-photo' src={XLS}/>
            </ImportInput>

          </StyledHeaderCol>
        </StyledHeader>
        <StyledRowContent>
          <Col>
            <Title>{t('common.result')}</Title>
            <TableGrid
              data={get(resultQuery, 'fakturaAssist.listFA_ImportISO', [])}
              tableData={[
                {
                  name: t('common.date'),
                  dataBase: 'date',
                },
                {
                  name: t('debitor.invoiceNo'),
                  dataBase: 'linkCustomer',
                },
                {
                  name: t('invoice.invoiceDate'),
                  dataBase: 'invoiceDate',
                },
                {
                  name: t('customer.customer'),
                  dataBase: 'customer',
                },
                {
                  name: t('debitor.remainingAmount'),
                  dataBase: 'remainingAmount',
                },
                {
                  name: t('debitor.paymentAmount'),
                  dataBase: 'amountPosted',
                },
                {
                  name: t('debitor.discountAmount'),
                  dataBase: 'discountAmount',
                },
                {
                  name: t('debitor.recognizedAmount'),
                  dataBase: 'recognizedAmount',
                },
                {
                  name: t('debitor.fibuAccount'),
                  dataBase: 'FIBUAccount',
                },
                {
                  name: t('debitor.status'),
                  dataBase: 'status',
                },
                {
                  name: t('debitor.errorMessage'),
                  dataBase: 'errorMessage',
                },
                {
                  name: t('debitor.subscriberNumber'),
                  dataBase: 'subs',
                },
                {
                  name: t('debitor.reference'),
                  dataBase: 'reference',
                },
              ]}
              height={600}
              headHeight={'100%'}
            />
          </Col>
        </StyledRowContent>
      </StRow>
    </LoadingWrap>
  );
};

export default ImportISO;

const StRow = styled(Row)`
  margin: 0 20px;
`;

const ImportInput = styled.label`
  padding: 8px;
  width: 333px;
  height: 36px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
  background-color: var(--white);
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
  cursor: pointer;
`;

const FileNameBlock = styled.div`
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  cursor: pointer;
`;

const ActionIcon = styled.img`
  float: right;
  cursor: pointer;
`;

const Title = styled(Col)`
  margin: 12px 0 2px 0;
  /* font-family: Roboto; */
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
`;

const StyledHeader = styled(Row)`
	border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  border-top: 1px solid #3e4c57;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`;

const StyledHeaderCol = styled(Col)`
	padding: 10px 0;
`;

const StyledRowContent = styled(Row)`
  margin-top: 10px;
  padding-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;
