import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Auth, Cache } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import { withRouter, Router } from 'react-router-dom';

import Main from './main/index.js';
import './style/Main.css';
import withAuth from 'components/withAuth';
import { withStatusIndicatorContext } from 'components/graphql';

export const history = createBrowserHistory();

class Login extends SignIn {
  signOut = async () => {
    const { client, onStateChange } = this.props;
    await client.clearStore(); //clear apollo-client store
    Cache.clear(); //clears aws-amplify cache
    await Auth.signOut(); // sign out user
    onStateChange('signedOut'); // goto sign in page
    history.push('/');
  }

  render() {
    return (
      <Router history={history}>
        <Main signOut={this.signOut} {...this.props}/>
      </Router>
    );
  }
}

const withRouterHOC = withRouter(Login);
const withAuthHOC = withAuth(withRouterHOC);

export default withStatusIndicatorContext(withAuthHOC);
