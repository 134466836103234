import gql from 'graphql-tag';

export default gql`
query listFA_CustomerLists($id: Int ){
    fakturaAssist{
    listFA_CustomerLists(id: $id){
	id
  	customerListsRelationID
  	name
  	isValue
  	linkCustomer
    }
    
  }
}`;
