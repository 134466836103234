
import gql from 'graphql-tag';

export default gql`
query infoValidationDates($id: Int, $customerId: Int!, $fromDate: String, $toDate: String){
    fakturaAssist{
    infoValidationDates(id: $id, customerId: $customerId, fromDate: $fromDate, toDate: $toDate){
		fromDate 
		toDate
		description

    }
    
  }
}`;
