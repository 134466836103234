import gql from 'graphql-tag';

export default gql`
query getFA_GetDefaultOrderForCustomerOnDate($args: getFA_GetDefaultOrderForCustomerOnDateInputs!){
    fakturaAssist{
    	getFA_GetDefaultOrderForCustomerOnDate(args: $args){
    		id
    		productTable{
    			id
     			productLink
     			productLinkLookNr
     			productLinkLookName
     			quantity
     			labelRecipeLink
     			virtualPositionLink
     			orderPositionID
				taxCode
				price
				total
				discount
				discountSpec
				weight
				productNr
				productName
				addition
				fax
				email
				internet
				additive
				indTextProduction
				indTextDeliveryNote
				min
				max
				canChangeInOrders
			}
			isShowPreviousOrders
			deliveryInfo{
				id
				deliveryNr
				date
				total
			}

			temporaryId
			offerId

 		}
 	}
}`;

