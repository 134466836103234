import gql from 'graphql-tag';
import { version } from '../../../package.json';

const reportCrash = gql`
mutation reportCrash(
	$message: String!
	$stacktrace: String!
) {
  reportCrash(
    message: $message,
    stacktrace: $stacktrace,
    appVersion: "${version}",
    clientType: FAKTURA_ASSIST_WEB
  )
}`;

export default reportCrash;
