import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { compose, branch } from 'recompose';
import PropTypes from 'prop-types';

import { withFieldSubmit } from '../../formik';
import True from 'style/path.svg';
import IsWhiteStyle from 'style/img/path.svg';

const Checkbox = (props) => {

  const { value, title, onChangeFormik, submit, specialFunc, handleClick, withoutFormik, isWhiteStyle, onClick, name, onChange, inActive, withFormikSubmit, ...prev } = props;
  return (
    <CheckBox
      {...prev}
      onClick={!inActive ? !onClick ? !specialFunc && !withoutFormik ? () => {
        if (handleClick) {
          return handleClick(!value);
        }
        onChangeFormik(!value, 'checkBox');
        submit(!value, 'checkBox');
      } : ()=>onChange(name, !value) : () => {
        onClick(!value);
        withFormikSubmit && submit();
      } : null}
      margin={props.margin}
      open={value}
    >
      <Img open={value} isWhiteStyle = {isWhiteStyle} src={value ? isWhiteStyle ? IsWhiteStyle : True : ''} />
      <div>
        <span>{title}</span>
      </div>

    </CheckBox>
  );
};

export default compose(
  branch(
    (props) => {
      return(!props.withoutFormik);
    },
    withFieldSubmit
  ))(Checkbox);

const CheckBox = styled(Col)`
  /* font-family: ${(props) => props.open ? 'Roboto-Medium' : 'Roboto-Light'}; */
  font-weight: ${(props) => props.open ? '500' : '300'};
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  margin: ${(props) => props.margin ? props.margin : '48px 0px 0px 0px'};
  z-index: ${(props) => props.zIndex ? props.zIndex : '0'};;
  &:hover {
    cursor: pointer
  }
`;

const Img = styled.img`
  background: ${(props) => props.isWhiteStyle ? '#4F5E69' : ''};
  padding: 2px 1px 2px 2px;
  border: ${(props) => props.open ? 'solid 1px #3e4c57' : 'solid 1px #c3c3c3'};
  border-radius: 1px;
  float: left;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 3px;
`;

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  title: PropTypes.string,
  withoutFormik: PropTypes.bool,
};

/*
  Example
    <Checkbox
      value={true}
      title='Checkbox'
    />
*/
