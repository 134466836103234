import React from 'react';
import { Row, Col } from 'style';

import { Checkbox, Transition, Dropdown } from 'components/elements';
import Formik from 'components/formik';

import listFA_KgAmountRepresentationKinds from '../../qql/query/listFA_KgAmountRepresentationKinds';

export default ({ t, onSubmit, configData }) => {
  return (
    <Formik initialValues={configData} enableReinitialize onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <>
            <Transition text={t('options.configurations.cashAssist.deliveryNoteAP')} isOpen setTransitions={null}>
              <Row>
                <Col lg = {2}>
                  <Checkbox
                    margin='24px 0 0 0'
                    width='100%'
                    value={values.isCAPrintFilial}
                    field='isCAPrintFilial'
                    title={t('options.configurations.cashAssist.storeOrders')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg = {2}>
                  <Checkbox
                    margin='24px 0 0 0'
                    width='100%'
                    value={values.isCAPrintRetour}
                    field='isCAPrintRetour'
                    title={t('options.configurations.cashAssist.returns')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg = {2}>
                  <Checkbox
                    margin='24px 0 0 0'
                    width='100%'
                    value={values.isCAPrintCustomer}
                    field='isCAPrintCustomer'
                    title={t('options.configurations.cashAssist.creditCustomer')}
                  />
                </Col>
              </Row>
            </Transition>

            <Transition text={t('options.configurations.cashAssist.settings')} isOpen setTransitions={null}>
              <Row>
                <Col lg = {2}>
                  <Dropdown
                    title={t('options.configurations.cashAssist.killogramAmountRepresentation')}
                    field='amountRepresentationKindId'
                    defaultValueField = {values.amountRepresentationKindId}
                    graphqlParams={{ query: listFA_KgAmountRepresentationKinds }}
                  />
                </Col>
              </Row>
              <Row>
                <Col width = {'100%'}>
                  <Checkbox
                    margin='24px 0 0 0'
                    width='100%'
                    value={values.isCAOrderExportActive}
                    field='isCAOrderExportActive'
                    title={t('options.configurations.cashAssist.waCaOrderExport')}
                  />
                </Col>
              </Row>
            </Transition>
          </>
        );
      }}
    </Formik>
  );
};
