import gql from 'graphql-tag';

export default gql`
mutation createFA_UserForms($fields: fieldsUserForms!){
    fakturaAssist{
    createFA_UserForms(fields: $fields){
		id
        reportGroupID
    }
    
  }
}`;
