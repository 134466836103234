import gql from 'graphql-tag';

export default gql`
query listFA_TaxList{
    fakturaAssist{
    	listFA_TaxList{
    		fromDate
	        taxRange
 		}
 	}
}`;
