
import React, { useState } from 'react';
import styled from 'styled-components';
import TableGrid from 'components/tableGrid/TableGrid.js';
import { Checkbox } from 'components/elements';
import moment from 'moment';
import IMG_preview from '../../../../../style/img/actions/preview.svg';
import IMG_form from '../../../../../style/img/reports.svg';

import { Row, Col } from 'style';
import listFA_ProductionPrintDeliveryNotes from './qql/listFA_ProductionPrintDeliveryNotes';
import { useTranslation } from 'react-i18next';
import withPDFActions from 'components/viewPDF/withPDFActions';

export default withPDFActions((props) => {
  const { showPDFPreview, showFormsType } = props;
  const [AOrderID, setAOrderId] = useState(null);
  console.log(props);
  const pdfParams = {
    reportId: '44',
    AOrderID: `${AOrderID}`,
    AUserFormularID: '0',
  };
  const [t] = useTranslation();
  // const GetError = ({ AOrderID })=>!AOrderID && <Error>Select Order </Error>;
  const tableDataProductions = [

    {
      name: t('common.deliveryNr'),
      dataBase: 'deliveryNoteNr',
    },
    {
      name: t('common.date'),
      dataBase: 'date',
      specialComponent: (index, _, value)=>{
        return(
          <div>
            {value && moment(value).format('DD.MM.YYYY')}
          </div>
        );
      },
    },
    {
      name: t('common.customerNo'),
      dataBase: 'customerNr',
    },
    {
      name: t('common.name'),
      dataBase: 'actNameId',
    },
    {
      name: t('order.orderType'),
      dataBase: 'deliveryNoteType',
    },
    {
      name: t('common.groupDeliveryNote'),
      dataBase: 'resName',
    },
    {
      name: t('common.printed'),
      dataBase: 'isPrint',
      specialComponent: (index, _, { props: { value } }) => {
        return(
          <div style={{ marginTop: '-44px' }}>
            <Checkbox withoutFormik inActive value={value}/>
          </div>
        );
      },
    },
    {
      name: t('common.date') + '/' + t('common.time'),
      dataBase: 'isPrintDateTime',
      specialComponent: (index, _, value) => {
        return(
          <div>
            {value && (moment(value).format('DD.MM.YYYY HH:mm:ss'))}
          </div>
        );
      },
    },
    // {
    //   name: t('common.print'),
    //   dataBase: 'id',
    //   specialComponent: (index, _, value, data) => {
    //     return;
    //   },
    // },
    // {
    //   name: t('common.preview'),
    //   dataBase: 'id',
    // },
  ];
  return (
    <HeadBlock>
      <MainBlock>
        <Row>
          {/* <GetError AOrderID={AOrderID}/> */}
          <Col style={{ cursor: !AOrderID && 'not-allowed' }} width='47px'>
            <IMG title='Open FORM' AOrderID = {AOrderID} onClick={()=>{
              AOrderID && showFormsType(pdfParams, [8,
                43,
                44,
                55,
                16,
                40,
                62,
                29,
                30]);
            }}>
              <img src={IMG_form}/>
            </IMG>
          </Col>

          <Col width='50px' style={ { cursor: !AOrderID && 'not-allowed' }}>
            <IMG title='Preview' AOrderID = {AOrderID} onClick={()=>{
              AOrderID && showPDFPreview({ ...pdfParams, reportId: 'orderPreview' });
            }}>
              <img src={IMG_preview}/>
            </IMG>
          </Col>
        </Row>
        

        {/* <DXTableGrid
          withSearch
          graphqlParams={{
            query: listFA_ProductionPrintDeliveryNotes,
            variables: { fromDate: props.values.fromDate, toDate: props.values.toDate } }}
            height={700}
            label={'All print lists'}
            columns={tableDataProductions}/> */}
        <TableGrid
          onClick={(_, item)=>{
            setAOrderId(item.id);
          }}
          withSearch
          withScroll
          graphqlParams={{
            query: listFA_ProductionPrintDeliveryNotes,
            variables: { fromDate: props.values.fromDate, toDate: props.values.toDate || props.values.fromDate } }}
          height={540}
          disableHeightChange
          headerText={'All print lists'}
          tableData={tableDataProductions}
        />

      </MainBlock>
    </HeadBlock>
  );
});

const HeadBlock = styled.div`
  height: 680px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
`;
const IMG = styled.div`
  margin: 0px 20px 20px 0px;
  float: left;
  background:white;
  opacity: ${(props) => props.AOrderID ? 1 : 0.3}
  height: 37px;
  width: 37px;
  padding: 9px;
  &:hover{
    cursor: pointer;
  }
`;

// const Error = styled.div`
// margin: 35px 20px 20px 0px;
// float: left;
// background:white;
// color: red;
// `;
