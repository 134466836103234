export const filterFolder = (t) => ([
  {
    title: t('common.filter.dailyCustomers'),
    value: 'dailyCustomer',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.customerWithEmptyOrders'),
    value: 'customerWithEmptyOrders',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.acceptedOffers'),
    value: 'acceptedOffers',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.activeCustomers'),
    value: 'activeCustomer',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.inactiveCustomers'),
    value: 'inactiveCustomers',
    field: 'folderName',
    subfolder: [],
  },
  // {
  //   title: 'Customer',
  //   value: 'customer',
  //   field: 'folderName',
  //   subfolder: [],
  // },

]);

export const timeTableParams = (t) =>([
  {
    name: t('options.automation.month'),
    dataBase: 'test',
  },
  {
    name: t('options.automation.weekday'),
    dataBase: 'test',
  },
  {
    name: t('options.automation.monthDay'),
    dataBase: 'test',
  },
  {
    name: t('options.automation.month'),
    dataBase: 'test',
  },

]);

export const itemsData = (t) =>([
  {
    name: t('common.description'),
    dataBase: 'id',
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },
  {
    name: t('options.automation.lastStart'),
    dataBase: 'orderNr',
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },
  {
    name: t('options.automation.nextStart'),
    dataBase: 'date',
  },

]);

export const settingTableTaskTypeData = (t) =>([
  {
    name: t('options.automation.taskType'),
    dataBase: 'id',
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },
  {
    name: t('common.description'),
    dataBase: 'orderNr',
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },

]);

export const settingTableArticleFilterData = (t) =>([
  {
    name: t('options.automation.articleFilter'),
    dataBase: 'id',
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },

]);


