import gql from 'graphql-tag';

export default gql`
query listFA_OfferCustomersList($filter: [FA_GeneralFilter], $date: FA_FilterDate!, $params: ListQueryArgs){
    fakturaAssist{
    	listFA_OfferCustomersList(filter: $filter, date: $date, params: $params){
    		id
			nameRes
			accepted
			declined
			temporaryId
			customerNr
			offerID
			fromDate
			toDate
			offerDate,
			offerNr,
			total
 		}
 	}
}`;
