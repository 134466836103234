/* eslint-disable no-plusplus */
import React, { useState, Fragment, useEffect } from 'react';
import { useMutation } from 'components/graphql';
import { cloneDeep, orderBy } from 'lodash';
import { generateNewItem } from './const';
import reduce from 'helpers/reduceGraphqlData';

import { checkFA_ProductInfoFromOrderDropdown } from '../qql/mutation';

const withFilter = (WrappedComponent) => (props) =>{
  const { query, variables, orderType } = props.queryInfo;
  const { tableIndex, client, calculateDeliveryInfo, isMutable, setOrderInfo, orderInfo, isStateChanges, deliveryInfo, localSortKey } = props;
  const [rerenderValue] = useState(0);
  const [totalParams, setTotalParams] = useState({ total: 0, quantityCount: '0/0' });
  const [getProductInfoFromDropdown] = useMutation(checkFA_ProductInfoFromOrderDropdown);
  /*
    Used in min/max modal
    //Modal show according to Object.keys.lenght
    Transform this state to {}, to close modal
  */
  const [minMaxInfo, setMinMaxInfo] = useState({});
  const readQuery = () =>{
    try{
      if(setOrderInfo && isStateChanges) {
        return orderInfo;
      }else{
        const сacheData = client.cache.readQuery({
          query: query,
          variables: variables,
        });
        const result = cloneDeep(reduce(сacheData));
        if (localSortKey && result.productTable) {
          result.productTable = orderBy(result.productTable, [localSortKey], ['asc']);
        }
        return result;
      }
    }catch(err) {
      //console.log(err);
      return [];
    }
  };

  const calculateTotal = () => {
    const query = readQuery();
    if(query) {
      if(query.productTable && query.productTable.length) {
        const total = query.productTable.reduce((acc, val)=> acc + val.total, 0);
        const filledQuantityNumber = query.productTable.reduce((acc, val)=> val.quantity ? acc + 1 : acc, 0);
        const finalQuantity = `${filledQuantityNumber}/${query.productTable.length}`;
        //&& total + deliveryInfo && deliveryInfo.total
        const calculateFinalTotal = () => {
          let totalCache = total;
          if(deliveryInfo && deliveryInfo.total) {
            totalCache += deliveryInfo.total;
          }
          return totalCache;
        };
        setTotalParams({ quantityCount: finalQuantity, total: calculateFinalTotal() });
      }else{
        setTotalParams({ quantityCount: '0/0', total: 0 });
      }
    }
  };

  useEffect(() => {
    calculateTotal();
    calculateDeliveryInfo && calculateDeliveryInfo();
  }, [props.data]);

  const concatTotal = (value) => Math.round(value * 100) / 100;

  //const productTable = readQuery();
  const writeQuery = (changedData) =>{
    try{
      if(setOrderInfo && isStateChanges) {
        setOrderInfo({ ...orderInfo, productTable: changedData.productTable });
      }else{
        client.writeQuery({
          query: query,
          variables: variables,
          data: {
            fakturaAssist: {
              [props.queryValue]: cloneDeep(changedData),
              __typename: 'FA_Query',
            },
          },
        });
      }
    }catch(err) {
      console.log(err);
    }
  };
  /*
    *
      * Table operations ( add, delete, onChange)
    *
  */
  const onDelete = (rowIndex) =>{
    const productArray = cloneDeep(readQuery());
    productArray.productTable.splice(rowIndex, 1);
    writeQuery(productArray);
  };

  const onAdd = async (callback) =>{
    try{
      const productArray = cloneDeep(readQuery());
      productArray.productTable.splice(tableIndex, 0, { ...generateNewItem(productArray.productTable.length) });
      await writeQuery(productArray);
    }catch(err) {
      //
    }
  };

  const onChangeInput = (e, setValue) =>{
    try{
      const { name, value } = e.target;
      const productArray = cloneDeep(readQuery());
      const activeProduct = { ...cloneDeep(productArray.productTable[tableIndex]) };
      const checkNegativeNumber = (number) => {
        return Math.sign(number) === 1 ? -number : number;
      };
      switch(name) {
        case 'quantity':
          if(orderType === 4) {
            activeProduct.quantity = checkNegativeNumber(value);
            activeProduct.total = concatTotal(activeProduct.quantity * activeProduct.price || 0);
          }else if(activeProduct.min || activeProduct.max) {
            if(value > activeProduct.max) {
              activeProduct.quantity = activeProduct.max;
              activeProduct.total = concatTotal(activeProduct.quantity * activeProduct.price || 0);
              setMinMaxInfo({ min: activeProduct.min, max: activeProduct.max, error: 'Maxium' });
              setValue(activeProduct.max);
            }else if(value < activeProduct.min) {
              activeProduct.quantity = activeProduct.min;
              activeProduct.total = concatTotal(activeProduct.quantity * activeProduct.price || 0);
              setMinMaxInfo({ min: activeProduct.min, max: activeProduct.max, error: 'Minium' });
              setValue(activeProduct.max);
            }else{
              activeProduct.quantity = value;
              activeProduct.total = concatTotal(activeProduct.quantity * activeProduct.price || 0);
            }
          }else{
            activeProduct.quantity = value;
            activeProduct.total = concatTotal(activeProduct.quantity * activeProduct.price || 0);
          }
          break;
        case 'price':
          activeProduct.price = value;
          activeProduct.total = concatTotal(activeProduct.quantity * activeProduct.price || 0);

          break;
        case 'discount':
          value > 99 ? activeProduct.discount = 99 : activeProduct.discount = value;
          setValue(activeProduct.discount);
          break;
        case 'indTextDeliveryNote':
          activeProduct.indTextDeliveryNote = value;
          break;
        case 'indTextProduction':
          activeProduct.indTextProduction = value;
          break;
        default:
          return activeProduct[name] = value;
      }
      productArray.productTable[tableIndex] = cloneDeep(activeProduct);
      writeQuery(productArray);
    }catch(err) {
      console.log(err);
    }
  };

  const onDropdownChange = async ({ item }, name) =>{
    const productArray = readQuery();
    const activeProduct = { ...cloneDeep(productArray.productTable[tableIndex]) };
    if(name === 'tax') {
      activeProduct.taxCode = item.id;
    }else{
      const productArgs = { customerId: props.linkId, date: props.date, productId: item.id };
      const productInfo = await getProductInfoFromDropdown({ variables: { args: productArgs } });
      const { productNr, name, discount, taxCode, price, weight } = productInfo.data.fakturaAssist.checkFA_ProductInfoFromOrderDropdown;
      activeProduct.productName = name;
      activeProduct.taxCode = taxCode;
      activeProduct.price = price;
      activeProduct.productNr = productNr;
      activeProduct.discount = discount;
      activeProduct.weight = weight;
      activeProduct.productLink = +item.id;
      activeProduct.productLinkLookName = +item.id;
      activeProduct.productLinkLookNr = +item.id;
      activeProduct.total = concatTotal(activeProduct.quantity * price || 0);
    }
    productArray.productTable[tableIndex] = activeProduct;
    writeQuery(productArray);
  };

  const onAdditionTextareaArrowClick = (field) => {
    const productArray = readQuery();
    const activeProduct = { ...cloneDeep(productArray.productTable[tableIndex]) };
    if(field === 'indTextProduction') {
      activeProduct.indTextDeliveryNote = activeProduct.indTextProduction;
    }else{
      activeProduct.indTextProduction = activeProduct.indTextDeliveryNote;
    }
    productArray.productTable[tableIndex] = activeProduct;
    writeQuery(productArray);
  };

  /*
    *
      *
    *
  */

  // const selectAndFocusMergeInput = () =>{
  //   console.log(quantityInputRef)
  //   quantityInputRef[tableIndex + 1].focus()
  //   quantityInputRef[tableIndex + 1].select()
  //   props.changeTableIndex(tableIndex + 1);
  // };

  return (
    <Fragment>
      <WrappedComponent
        {...props}
        totalParams={totalParams}
        onDropdownChange = {!isMutable ? onDropdownChange : () => {}}
        onDelete={!isMutable ? onDelete : () => {}}
        onAdd={!isMutable ? onAdd : () => {}}
        onChangeInput={!isMutable ? onChangeInput : () => {}}
        rerenderValue={rerenderValue}
        minMaxInfo={minMaxInfo}
        setMinMaxInfo={setMinMaxInfo}
        onAdditionTextareaArrowClick={onAdditionTextareaArrowClick}

      />
    </Fragment>
  );
};

export default withFilter;

// import React, { useState, useEffect, useRef, useCallback } from "react";
// import ReactDOM from "react-dom";

// import "./styles.css";

// const INIT_INPUT_COUNT = 3;

// function App() {
//   const curRef = useRef();
//   const [state, setState] = useState({
//     inputCount: INIT_INPUT_COUNT,
//     currentFocus: 0
//   });

//   useEffect(() => {
//     if (!curRef.current) return;
//     console.log(`Focusing: ${state.currentFocus}th input`);
//     curRef.current.focus();
//   }, [state.currentFocus]);

//   const handleEnter = useCallback(
//     e => {
//       if (e.key !== "Enter") return;
//       setState(s =>
//         s.currentFocus >= s.inputCount - 1
//           ? { inputCount: ++s.inputCount, currentFocus: ++s.currentFocus }
//           : { ...s, currentFocus: ++s.currentFocus }
//       );
//     },
//     [state]
//   );

//   const handleFocus = useCallback(
//     idx => e => {
//       if (idx === state.currentFocus) return;
//       setState(s => ({ ...s, currentFocus: idx }));
//     },
//     [state]
//   );

//   return (
//     <div className="App">
//       <h1>Hello CodeSandbox</h1>
//       <h2>Start editing to see some magic happen!</h2>
//       {Array(state.inputCount)
//         .fill()
//         .map((_, i) => (
//           <input
//             onKeyPress={handleEnter}
//             onFocus={handleFocus(i)}
//             ref={i === state.currentFocus ? curRef : undefined}
//             type="text"
//             key={i}
//           />
//         ))}
//     </div>
//   );
// }

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
