import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query getFA_Goods($id: ID!, $isShowOnlyAssigned: Boolean){
    fakturaAssist{
    	getFA_Goods(id: $id, isShowOnlyAssigned: $isShowOnlyAssigned){
	    	id
            name
            articleNr
            isActive
            goodsItems {
                id
                innerId
                linkSupplier
                number
                name
                externalNumber
                externalName
                price
                lastSale
                isAvailable
            }
	 	}
 	}
}`;

