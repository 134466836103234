export const tableData = (t) => [{
  dataField: 'customerNr',
  caption: 'Customer No.',
},
{
  dataField: 'amount',
  localFormat: { type: 'float' },
  caption: 'Amount',
},
{
  dataField: 'description',
  caption: 'Description',
},
{
  dataField: 'name',
  caption: 'Name',
},
{
  dataField: 'deliveryNote',
  caption: 'Delivery Note',
},
{
  dataField: 'productionList',
  caption: 'Production List',
},
{
  dataField: 'date',
  caption: 'Date',
  localFormat: { type: 'date' },
},
{
  dataField: 'deliveryNr',
  caption: 'Delivery No.',
},
{
  dataField: 'price',
  caption: 'Price',
},
{
  dataField: 'discount',
  caption: 'Discount',
},
{
  dataField: 'dough',
  caption: 'Dough',
},
];
