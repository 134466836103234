import gql from 'graphql-tag';

export default gql`
mutation changeStatusBakery2bProduction($onDate: String!, $status: Boolean!){
    fakturaAssist{
    changeStatusBakery2bProduction(onDate: $onDate, status: $status){
		RecordID
    }
    
  }
}`;
