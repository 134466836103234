import { useQuery as useQueryOriginal } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { fetchPolicy as globalFetchPolicy } from 'config/graphql';
import { transformData } from '../utils';

const useQuery = (query, options) => {
  const noQuery = !query;
  const { data, loading, ...rest } = useQueryOriginal(noQuery ? gql`query{_}` : query, {
    skip: noQuery,
    fetchPolicy: globalFetchPolicy,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    ...options,
  });
  const { data: transformedData, queryField, ...dataInfo } = transformData(data);
  return noQuery ? {} : {
    ...rest,
    ...dataInfo,
    loading: loading,
    data: loading ? [] : transformedData[queryField],
    hasData: !!Object.keys(transformedData).length,
  };
};

export default useQuery;
