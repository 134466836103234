import gql from 'graphql-tag';

export default gql`
mutation callFA_CreateOffer($args: FA_CreateOfferInputs!){
    fakturaAssist{
    	callFA_CreateOffer(args: $args){
    		errorCode
			errorText
			offerID
			offerNr
 		}
 	}
}`;
