import gql from 'graphql-tag';

export default gql`
query listFA_AvaliableProductsListOnDay(
	$args: FA_AvaliableProductsListOnDayInputs!,
	$params: ListQueryArgs){
    fakturaAssist{
    	listFA_AvaliableProductsListOnDay(args: $args, params: $params){
    		id
			price
			name
			productNr
 	    }
    }
}`;


