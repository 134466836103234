export default (linkId, data, props) => {
  const {
    mainsection,
    subsection,
    sectionInfo,
  } = props;
  if(linkId) {
    if(!props.loading) {
      if(data.length) {
        props.history.replace({
          pathname: `/${mainsection}/${subsection}/${sectionInfo ? linkId : data[0].id}`,
        });
      }else{
        props.history.replace({
          pathname: `/${mainsection}/${subsection}`,
        });
      }
    }
  }
};
