import gql from 'graphql-tag';

export default gql`
query listFA_ProductionForm($id: Int){
    fakturaAssist{
    listFA_ProductionForm(id: $id){
		id
		userFormID
		defaultFormID
		form
		fileName
		reportTypeName
    }
    
  }
}`;
