import gql from 'graphql-tag';

export default gql`
query listFA_PriceRoundingMethods{
    fakturaAssist{
    	listFA_PriceRoundingMethods{
    		id
			name
 		}
 	}
}`;
