import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import warning from 'style/img/errors/404.svg';

import { Modal, Button } from '../index';

const ErrorModal = ({ errorText, setErrorText, ...prev }) =>{
  const [isOpen, closeModal] = useState(errorText);
  useEffect(() => {
    closeModal(errorText);
  }, [errorText]);
  const close = () =>{
    if (setErrorText) {
      setErrorText(null);
    } else {
      closeModal(false);
    }
  };
  const ErrorModalComponent = () => {
    return(
      <ErrorBlock>
        <img src={warning}/>
        <p>{errorText}</p>
        <div><Button onClick={close} value='Ok' className='red-btn' upperCase/></div>
      </ErrorBlock>
    );
  };
  return (
  	<Modal
      {...prev}
      height='270px'
      width='400px'
  		Component = {<ErrorModalComponent/>}
      headerBackground='#dd5860'
  		headerText= 'Error'
      isOpen={isOpen}
      closeModal={close}
  		
  	/>
  );
};

export default ErrorModal;

const ErrorBlock = styled.div`
	width: 400px;
  height: 300px;
  padding: 20px;
  background: #fff;
  text-align: center;
  position: relative;
  img{
    height: 89px;
    width: 93px;
    display: block;
    margin: 10px auto 30px auto;
  }
  button {
    margin-top:20px
  }
  p{
    font-size: 16px;
  }
  & > div{
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: auto;
    left: 100px;
  }
`;

ErrorModal.propTypes = {
  errorText: PropTypes.string.isRequired,
};

/*
  Example
    <ErrorModal errorText='Ops'/>
*/

