import { IS_LOCALHOST, isDeployedToDevTestEndpoint, isDeployedToProductionEndpoint } from 'config/consts';

const devUserPoolConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: 'eu-west-1:d901e32d-9604-4851-9907-acd611a558c8',

  // REQUIRED - Amazon Cognito Region
  region: 'eu-west-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_HB2RoWYWY',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: 'v5ev0902vd0m0gcdb551hu37h',
};

const productionUserPoolConfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: 'eu-west-1:46ef19b7-823a-4c10-a0a6-fa23d303688e',

  // REQUIRED - Amazon Cognito Region
  region: 'eu-west-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-west-1_ZQAKufUVZ',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: '1t2j35i84uj8ekvr29np02n9k7',
};

const amplifyConfig = {
  Auth: {
    ...((IS_LOCALHOST || isDeployedToDevTestEndpoint) ? devUserPoolConfig : productionUserPoolConfig),
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: isDeployedToProductionEndpoint ? '.hssoft.com' : window.location.hostname,
      // OPTIONAL - Cookie path
      // path: '/',
      // OPTIONAL - Cookie expiration in days
      // expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: isDeployedToProductionEndpoint,
    },

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },

  Storage: {
    bucket: 'upload.wawiassist.hssoft.com', //REQUIRED -  Amazon S3 bucket
    region: 'eu-west-1', //OPTIONAL -  Amazon service region
  },

  Analytics: {
    disabled: true,
  },
};

export default amplifyConfig;
