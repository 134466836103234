import gql from 'graphql-tag';

export default gql`
query getFA_Config {
    fakturaAssist{
    	getFA_Config {
    		company
			name
			additive
			street
			postCode
			city
			phone
			fax
			email
			internet
			taxNumber
			bankName
			bin
			accountNr
			iban
			creditorId
			bic
			invoiceNumber
			productGroup
			roundingTotalPriceMethodID
			isAddSummaryToInvoiceReportPDF
			isUseAnotherCurrencies
			euroRate
			usdRate
			roundingTotalPriceMethodID
			roundingArticlePriceMethodID
			orderGroup
			isDeliveryInMonday
			isDeliveryInTuesday
			isDeliveryInWednesday
			isDeliveryInThursday
			isDeliveryInFriday
			isDeliveryInSaturday
			isDeliveryInSunday
			isAutomaticDeliveryDay
			isOrderPricePrior
			isShippingNote
			isDelivery
			importOrdersPositionSorting
			webOrderAutoPrint
			farmyApiToken
			farmyApiLinkCustomer
			SMTPServer
			SMTPPort
			SMTPUser
			SMTPPassword
			SMTPUseSSL
			SMTPSenderAddress
			SMTPReceiverAddress
			isCAPrintFilial
			isCAPrintCustomer
			isCAPrintRetour
			isCAOrderExportActive
			amountRepresentationKindId
			countryId
			orderTypesInProductionReports
			articlePriceCategory
			isSameEANForMultipleArticles
 		}
 	}
}`;

