import gql from 'graphql-tag';

export default gql`
mutation updateFA_UserForms($id: Int!, $fields: fieldsUserForms!){
    fakturaAssist{
    updateFA_UserForms(id: $id, fields: $fields){
		id
    }
    
  }
}`;
