import gql from 'graphql-tag';

export const CONFIG = gql`
query {
    fakturaAssist{
    	getFA_Config {
    		articlePriceCategory
 		}
 	}
}`;
