import React from 'react';
import { Auth } from 'aws-amplify';

import { withTranslation } from 'react-i18next';
import { ConfirmSignIn } from 'aws-amplify-react';
import { LoadingSpinner } from 'components/Loading/StyledLoading';

import AuthContainer, { LinkButton } from './AuthContainer';
import LoginInput from './elements/LoginInput.js';
import { Button } from 'components/elements';

class MyConfirmSignIn extends ConfirmSignIn {
	state = {
	  isSubmitting: false,
	  code: '',
	  err: { errField: '', errMessage: '' },
	 }

	onChange = (e) => {
	  const { name, value } = e.target;
	  this.setState({ [name]: value });
	  };

	goBack = () => {
	  this.changeState('signIn');
	}

	handleMfa = async (e) => {
	  e.preventDefault();
	  const { code } = this.state;
	  const { authData: user } = this.props;
	  const mfaType = user.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'SOFTWARE_TOKEN_MFA' : null;
	  this.setState({ isSubmitting: true });
	  try {
	    await Auth.confirmSignIn(user, code, mfaType);
	    this.checkContact(user);
	  } catch(err) {
	    this.setState({ isSubmitting: false });
	    this.setState({ err: { errField: 'code', errMessage: err.message } });
	  }
	}

	showComponent() {
	  const { err, isSubmitting } = this.state;
	  return (

	    <AuthContainer onSubmit={this.handleMfa}>
	      {isSubmitting ? <div><LoadingSpinner transparent height='100px' width='100px'/></div>
	        :
	        <div>
	      <span style={{ color: '#434343' }}>
		  	Bestätigen Sie den MFA-Code
	      </span>
	      <LoginInput
	        type='text'
	        placeholder='MFA-Code eintragen'
	        name='code'
	        field='code'
	        err={err}
	        onChange={this.onChange}
	      />
				  <Button style={{ marginTop: 5 }} value={'BESTÄTIGEN'} onClick={this.handleMfa} width='300px' className={'red-btn'} type='submit' />
				  <LinkButton onClick={this.goBack}> Zurück</LinkButton>
	        </div>
	  }
	    </AuthContainer>
	  );
	}
}

export default withTranslation()(MyConfirmSignIn);
