// Options section render with another light components. Read and update query the same in all options tabs
import React from 'react';
import { Route } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import useQuerySpecial from 'components/graphql/useQuerySpecial';

import { getFA_Config } from 'main/options/qql/query';

import { useMutation, updateQuery } from 'components/graphql';

import { LoadingWrap } from 'style/default';
import Menu from 'components/Menu/Menu';

import mutateArrayIntoObject from 'helpers/mutateArrayIntoObject';

import sectionArray from 'main/options/sections';

//Standart Config setting with 1 id;

const OptionsSection = (props) => {
  const { t, match } = props;
  const { subsection } = match.params;

  //Check
  const singleSectionFunc = sectionArray.find((item)=> {
    const section = item(t);
    if(section.link === subsection) {
      return true;
    }
  });
  const sectionInfo = singleSectionFunc(t);

  const { data, ...prevQuery } = useQuerySpecial(getFA_Config, {
    fetchPolicy: 'cache-first',
  });

  const { location: { pathname } } = props;
  let { updateMutation, fields, Component, link, subsections } = sectionInfo;
  if(subsections.length) {
    const additionLink = pathname.split('/')[3];
    const actualSubsection = subsections.find((item) => item.link === additionLink);
    updateMutation = actualSubsection.updateMutation;
    fields = actualSubsection.fields;
    Component = actualSubsection.Component;
  }

  const [updateMutationHook] = useMutation(updateMutation);
  //We pass in component only needed fields
  const dataAccordindToFields = fields ? mutateArrayIntoObject(fields, data) : data;

  const onSubmit = (values) => {
    updateMutationHook({
      variables: { fields: values },
      update: updateQuery({ query: getFA_Config }, (draft, { mutationResult, readQuery, queryField }) => {
        const newItems = values;
        draft[queryField] = newItems;
      }),
    });
  };
  if(subsections.length) {
    const generateMenuArray = subsections.map((item) => {
      return ({
        link: `/options/${link}/${item.link}`,
        name: item.name,
      });
    });
    const section = subsections.map((item) => {
      return (
        <Route
          key={item.link}
          path={`/options/${link}/${item.link}`}
          component={() => (
            <Component
              {...props}
              {...prevQuery}
              onSubmit={onSubmit}
              configData={dataAccordindToFields}
            />)}
        />
      );
    });
    return (
      <div>
        <LoadingWrap loading={props.loading}>
          <Row><Menu menuObj={generateMenuArray}/></Row>
          {section}
        </LoadingWrap>

      </div>
    );
  }

  return(
    <Route
      path={`/options/${link}`}
      component={() => (
        <Component
          {...props}
          {...prevQuery}
          onSubmit={onSubmit}
          configData={dataAccordindToFields}
        />)}
    />
  );
};

export default OptionsSection;
