import gql from 'graphql-tag';

export default gql`
query listFA_ActualTaxs($args: FA_ActualTaxsInput!){
    fakturaAssist{
    	listFA_ActualTaxs(args: $args){
    		id
            name
            tax
 	    }
    }
}`;


