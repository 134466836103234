/* eslint-disable */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import DataGrid, { Button, Column, Selection, Editing, RemoteOperations, Paging, Scrolling, SearchPanel, Grouping, GroupPanel, Sorting, LoadPanel, DataGridHeaderFilter } from 'devextreme-react/data-grid';
import { useTranslation } from 'react-i18next';
import { compose, branch } from 'recompose';
import CustomStore from 'devextreme/data/custom_store';

import { toolbarCustomizer, columnsRender, replaceByLinkId } from './utils';
import StyledWrapper, { StyledSearchPanel } from './styled';
import { Modal, Button as CustomeBtn } from 'components/elements';

import withQuery from '../tableGrid/hoc/withQuery';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

export const ADD_BTN_CLS_NAME = 'dx-add-btn';

const DxDataTable = (props) => {
  const {
    data,
    columns,
    tableData,
    height, 
    width,
    onRowClick,
    withSearch,
    groupBy, 
    manualGroup,
    linkId,
    withoutSearchPanel,
    queryName, 
    graphqlParams,
    withDefaultFunction,
    onAddRecord,
    ...restProps } = props;


    // console.table('DxDataTable', props)

  const dataGridRef = useRef();
  const [t] = useTranslation();

  const { onToolbarPreparing, templates } = toolbarCustomizer({ ...props, ref: dataGridRef });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { cols, footerCols } = useMemo(() => columnsRender({ tableData: tableData || columns, groupBy, width, withDefaultFunction, selectedRowKeys, onAddRecord }), [tableData, groupBy, onAddRecord]);
  const [focusRowKey, setFocusRowKey] = useState(-1);
  const [defSort, setDefSort] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const dataGridHeight = data?.length ? height : 'auto';

  const optionChanged = (e) => {
    if(e.fullName === 'searchPanel.text' || e.name === 'columns') {
      setFocusRowKey(-1);
      setDefSort(null);
    }
    if (e?.value?.length <= 0 && data) {
      const key = data.findIndex((x) => +x.id === +linkId);
      setFocusRowKey(key);
      setDefSort(null);
    }
    if(e.value === 0) {
      setDefSort({ field: 'id', order: 'ASC' });
    }
  };

  const store = new CustomStore({
    key: 'id',
    load: function (loadOptions) {
      // let searchValue = '';
      // searchValue = loadOptions?.filter?.[0] ? loadOptions?.filter?.[0]?.filterValue : '';

      // let sortValue = { field: 'id', order: 'ASC' };
      // sortValue = loadOptions?.sort?.[0]
      //   ? { field: loadOptions?.sort?.[0]?.selector, order: loadOptions?.sort?.[0]?.desc ? 'DESC' : 'ASC' }
      //   : { field: 'id', order: 'ASC' };

      // const variables = {
      //   params: {
      //     search: { string: searchValue, columns: ['id', 'name'] },
      //     sort: defSort !== null ? { ...defSort } : { ...sortValue },
      //   },
      // };
      if(!props.client) return props.data;

      return props.client.query({
        query: graphqlParams.query,
        // variables: graphqlParams.variables ? { ...graphqlParams.variables } : null,
        // fetchPolicy: 'cache-first',
      })
        .then((res) => {
          return {
            data: res?.data?.fakturaAssist[queryName],
            totalCount: res?.data?.fakturaAssist[queryName].length,
          };
        })
        .catch(() => {
          console.log('Data Loading Error DX');
        });
    },
  });

  useEffect(
    () => {
      if (data) {
        const key = data.findIndex((x) => +x.id === +linkId);
        setFocusRowKey(key);
      }
    },
    [props.data, props.linkId],
  );

  const onSelectionChanged = (data) => {
    setSelectedRowKeys(+data.row.key);
  };

  return(
    <StyledWrapper {...props}>
      <DataGrid

        // onContentReady={(e)=>getSortedDate(e.component.getDataSource()._storeLoadOptions.sort)}
        key={dataGridHeight}
        dataSource={graphqlParams ? store : data}
        //getDataSource={(a)=>{console.log(a)}}
        height={dataGridHeight}
        noDataText={t('common.noDataAvailable')}
        showColumnLines={false}
        loadPanel={false}
        selection={{ mode: 'single' }}
        keyExpr={'id'}
        allowColumnResizing
        allowColumnReordering

        focusedRowEnabled={true}
        focusedRowIndex={focusRowKey}
        autoNavigateToFocusedRow={true}

        onOptionChanged={optionChanged}

        ref={dataGridRef}

        {...onRowClick && {
          ...typeof onRowClick === 'function' && { onFocusedRowChanged: (rowData) => onRowClick(rowData?.row?.data, rowData) },
        }}

        {...linkId && {
          onFocusedRowChanged: (rowData) => (onSelectionChanged(rowData), replaceByLinkId(rowData?.row?.data?.id, data, props)),
        }}

        sorting={{
          mode: 'single',
          ascendingText: t('common.strings.sortASC'),
          descendingText: t('common.strings.sortDESC'),
          clearText: t('common.strings.clearSort'),
        }}

        onCellPrepared={(e) => {
          if (onAddRecord && e.rowType === 'header') {
            setTimeout(() => {
              const old = e.cellElement;
              const newEl = old.cloneNode(true);
              old.parentNode.replaceChild(newEl, old);
              newEl.addEventListener('click', (ev) => {
                const path = ev?.composedPath?.() || [];
                const isAddClicked = path.some((item) => item?.getAttribute?.('class') === ADD_BTN_CLS_NAME);
                if(isAddClicked) {
                  ev.stopPropagation();
                  onAddRecord();
                }
              });
            });
          }
        }}

        // onToolbarPreparing={onToolbarPreparing} // верхняя строка для доп функций (templates) шаблон для этого
        {...restProps}
      >
        <Scrolling mode='virtual' />

        {!withoutSearchPanel && <SearchPanel
          visible={withSearch}
          highlightSearchText={false}
          placeholder={t('common.search')}
          style={{ paddingRight: 20 }}
        />}

        {cols}
        {manualGroup && <GroupPanel visible={true} emptyPanelText={t('common.strings.dragColToGroup')} />}
        <Sorting mode='multiple' />
        {/* {templates} */}
        {data?.length ? footerCols : null}
      </DataGrid>

      <Modal
        headerText={'Modal'}
        width={'480px'}
        height={'170px'}
        isOpen={isDelete}
        Component={
          <div>
            <div>You cannot delete products</div>
            <CustomeBtn
              float='right'
              className={'grey-btn'}
              width={'190px'}
              onClick={()=>setIsDelete(false)}
              value={'CANCEL'}/>
          </div>

        }
      />
    </StyledWrapper>
  );
};

DxDataTable.defaultProps = {
  data: [],
  exportFileName: 'Data',
  withSearch: true,
  excelExport: false,
};

DxDataTable.propTypes = {
  data: PropTypes.array, // или data=[]
  graphqlParams: PropTypes.object, // graphqlParams={{ query: listQuery, variables: contex }} !PRIORITY
  tableData: PropTypes.array, // fields for table
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]), // height table
  withSearch: PropTypes.bool, // with search input
  onRowClick: PropTypes.func, // при нажатии на строку - отдает значение строки (need)
  label: PropTypes.string,
  manualGroup: PropTypes.bool,
  withoutSearchPanel: PropTypes.bool,
  excelExport: PropTypes.bool,
  exportFileName: PropTypes.string,
};

export default compose(
  branch(
    ({ withoutQuery }) => !withoutQuery,
    withQuery
  ),
)(DxDataTable);
