
import gql from 'graphql-tag';

export default gql`
query listFA_CustomerResellerPrices($id: ID! ){
    fakturaAssist{
    listFA_CustomerResellerPrices(id: $id){
		id
      note
      productNm
      description
      price
      productId
      customerId
    }
    
  }
}`;
