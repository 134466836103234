import gql from 'graphql-tag';

export default gql`
query listFA_Discount{
    fakturaAssist{
    listFA_Discount{
		id
		name
    }
    
  }
}`;
