import moment from 'moment';

import { Checkbox } from 'components/elements';
import { listFA_InvoiceListNew, listFA_Invoice, FILTER_QUERY } from './qql/query';
import { NewInvoice } from './newInvoice';
import { CreatedInvoice } from './createdInvoice';
import { newInvoicefilterData, editInvoiceFilterData } from './filters';

const filterTypeNameNew = JSON.parse(localStorage.getItem('invoice/new'));
const filterTypeNameCreated = JSON.parse(localStorage.getItem('invoice/created'));
const filterTypeNameDeleted = JSON.parse(localStorage.getItem('invoice/created/isDelete'));

const filterNew = { filter: filterTypeNameNew || [{ column: 'IsAktiviert', value: '1' }], date: {
  date: (moment().date() <= 7) ? moment().startOf('month').add(-1, 'months') : moment().startOf('month'),
  secondDate: moment().endOf('month'),
} };

const filterCreated = {
  filter: filterTypeNameCreated || [{ column: 'folderName', value: 'currentMonth' }],
  restContex: filterTypeNameDeleted || [{ column: 'isDelete', value: false }] };

const filterQuery = {
  query: FILTER_QUERY,
  variables: () => ({ languageId: +localStorage.getItem('lngId') || 1 }),
};

const restContexData = (t) => [
  {
    title: t('common.show_deleted'),
    Component: Checkbox,
    field: 'isDelete',
  },
];

export default (t) => ([
  {
    name: t('invoice.invoice'),
    link: 'invoice',
    searchColumns: ['Name'],
    separateSection: [
      {
        link: 'new',
        name: 'invoiceNew',
        listQuery: listFA_InvoiceListNew,
        Handler: NewInvoice,
        defaultFilterParams: filterNew,
        filterData: newInvoicefilterData(t),
        filterQuery,
        withParams: true,
        withoutMutation: true,
        reportForms: [3, 4, 5, 45],
      },
      {
        name: 'invoiceCreated',
        link: 'created',
        listQuery: listFA_Invoice,
        filterData: editInvoiceFilterData(t),
        restContexData: restContexData(t),
        filterRaw: true,
        withParams: true,
        disableSearch: false,
        Handler: CreatedInvoice,
        leftGridColors: {
          isDelete: '#F90303',
          paidByAutoBankDebit: '#3FFFFF',
          invoiceId: '#23BBD9',
        },
        defaultFilterParams: filterCreated,
        historyFormTypeID: 6,
      },

    ],
  },
]);

