import React from 'react';
import { Col, Row } from 'style';
import Formik from 'components/formik';

import { Input, Textarea } from 'components/elements';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik initialValues={props.data} onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <div>
            <Row>
              <Col lg={2}>
                <Input small value={values.payment} type='number' field='payment' title={t('customer.payment.termOfPaymentInDays')}/>
              </Col>
            </Row>
            <Row>
              <Col lg={2}>
                <Textarea
                  value={values.customPaymentText}
                  field='customPaymentText'
                  title={t('customer.payment.IndividualPaymentCollection')}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: -120 }}>
              <Col width='150px'>
                <Input value={values.discountInPercent} type='number' small field='discountInPercent' title={t('customer.payment.cashDiscount') + ' %'}/>
              </Col>
              <Col width='200px'>
                <Input value={values.discountDays} small field='discountDays' type='number' title={t('common.days.days')}/>
              </Col>
              <Col width='150px'>
                <Input value={values.debitorAccount} type='number' small field='debitorAccount' title={t('customer.payment.debtorAccount')}/>
              </Col>
              <Col width='200px'>
                <Input value={values.sAPOperationNr} small field='sAPOperationNr' title={t('customer.payment.slipReference')}/>
              </Col>

              <Col width='180px'>
                <Input value={values.returnLimit} type='number' width='100%' field='returnLimit' title={t('customer.payment.returnLimitIn') + ' %'}/>
              </Col>
            </Row>

          </div>
        );
      }}
    </Formik>
  );
};


