import gql from 'graphql-tag';

export default gql`
query listFA_CustomerSpecialPricesAndDiscounts($id: ID! ){
    fakturaAssist{
    listFA_CustomerSpecialPricesAndDiscounts(id: $id){
		id
		customerId
		productId
		specialPriceTypeId
		fromDate
		toDate
		description
		price
		fromAmount
		discount
		currentPrice
		isDiscountsDisabled
		productNr
		productName
    }
    
  }
}`;
