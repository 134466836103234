import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from 'components/graphql';
import { withFieldSubmit } from '../../formik';
import { compose, branch } from 'recompose';
import withQuery from '../hoc/withQuery';
import withPDFActions from 'components/viewPDF/withPDFActions';
import { Dots } from 'components/Loading/index';
// import Add from '../../../style/img/add_green.svg';
import Reports from '../../../style/img/reports.svg';
import icon_edit from '../../../style/edit.svg';
import { Row, Col } from 'style';

import listFA_Customer from 'main/customer/qql/listFA_Customer.js';
import listFA_preiskategorie from './qql/listFA_preiskategorie.js';

import { Dropdown, Calendar } from 'components/elements';

import Modal from '../../elements/Modal/Modal';

import ModalReportSystem from './components/ModalReportSystem';
import useQuerySpecial from 'components/graphql/useQuerySpecial';
import listFA_Forms from './qql/listFA_Forms';
import listFA_DefaultForms from './qql/listFA_DefaultForms';
import listFA_UserForms from './qql/listFA_UserForms';
import sendPDFToPrint from './qql/mutation/sendPDFToPrint';
import callFA_ReportSystem from './qql/mutation/callFA_ReportSystem';
import checkFA_ReportStatus from './qql/mutation/checkFA_ReportStatus';
//import getListPrinters from './qql/getListPrinters';
import { useTranslation } from 'react-i18next';

const customStyles = (row) => ({

  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    border: 'none',
    boxShadow: '1px 1px 2px 1px rgba(90,90,90,0.12)',
    height: '36px',
    width: '100%',
    background: '#fff',
  }),
  menu: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    zIndex: 10,
  }),
  option: (provided, state) => {
    return({
      ...provided,
      background: state.isFocused ? '#f5f5f5' : '#ffffff',
      color: '#3c445a',
      fontWeight: state.isFocused ? 600 : state.isSelected ? '500' : '300',
      borderRadius: '3px',
      boxShadow: '0 1px 2px 0 rgba(90, 90, 90, 0.12)',
      borderBottom: '1px solid #f4f4f4',
      // fontFamily: state.isSelected ? 'Roboto-Medium' : 'Roboto-Light',
    });
  },
  menuList: (provided, state) => ({
    ...provided,
    width: '100%',
    zIndex: 10,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    width: '300px',
    zIndex: 10,
    background: '#f5f5f5',
    height: '200px',
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    background: '#eeeeee',
    position: 'fixed',
    height: '40px',
    width: '300px',
  }),
});

const Index = (props) => {
  const {
    setActualPdfParams,
    onChangeFormik,
    submit,
    defaultValueField,
    loading,
    data,
    avaliableReportForms,
    withoutInput,
    field,
    width,
    title,
    pdfParams,
    withoutMutation,
    withPrinting,
    showPDFPreview,
    selectFirstItem,
    avaliableReportGroup,
    printingServiceAvailable,
  } = props;
  const [callReportSystem] = useMutation(callFA_ReportSystem);
  const [callCheckFA_ReportStatus] = useMutation(checkFA_ReportStatus);
  const [t] = useTranslation();
  //const [callCheckFA_ReportStatus] = useMutation(checkFA_ReportStatus);
  //useQuerySpecial(getListPrinters, { fetchPolicy: 'cache-first' });
  const [statusLoading, setStatusLoading] = useState(null);
  const [priceFormularId, setPriceFormularId] = useState(null);
  const [priceCategory, setPriceCategory] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [date, setDate] = useState(null);
  const [isOpenLoadingModal, setIsOpenLoadingModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const allForms = useQuerySpecial(listFA_Forms, { fetchPolicy: 'cache-first',
    onCompleted: (data)=>{
      if(selectFirstItem) {
        let actualData = data.fakturaAssist.listFA_Forms
          && avaliableReportForms
          ?
          data.fakturaAssist.listFA_Forms.filter(({ formIdDefault })=>avaliableReportForms.includes(formIdDefault)) : (data.fakturaAssist.listFA_Forms || []);
        actualData = actualData[0] && avaliableReportGroup ? actualData.filter(({ reportGroupID })=>avaliableReportGroup.includes(reportGroupID)) : actualData;

        setId(actualData[0].id);
      }
    }, variables: {
      languageId: +localStorage.getItem('lngId') || 1,
    } });
  const allFormsDefault = useQuerySpecial(listFA_DefaultForms, { fetchPolicy: 'cache-first' });
  const allFormsUser = useQuerySpecial(listFA_UserForms, { fetchPolicy: 'cache-first' });
  const [Mutation_sendPDFToPrint] = useMutation(sendPDFToPrint);

  let actualData = allForms.data && avaliableReportForms ? allForms.data.filter(({ formIdDefault })=>avaliableReportForms.includes(formIdDefault)) : (allForms.data || []);
  actualData = actualData[0] && avaliableReportGroup ? actualData.filter(({ reportGroupID })=>avaliableReportGroup.includes(reportGroupID)) : actualData;

  const checkReportStatus = async (id) => {
    const reportInfo = await callCheckFA_ReportStatus({ variables: { taskId: +id } });
    const reportInfoData = reportInfo.data.fakturaAssist.checkFA_ReportStatus;
    return reportInfoData;
  };

  const delay = async (ms) => {
    return await new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  };
  const getSelectPrinterInfo = () =>{
    const selectForm = allForms.data.filter((elem)=>{
      if(id) {
        return elem.id === id;
      } else{
        return elem.id === defaultValueField;
      }
    });

    if(selectForm[0]) {
      if(selectForm[0].formId) {
        return(allFormsUser.data.filter((elem)=>
          elem.id === selectForm[0].formId
        )[0]);
      } else{
        return(allFormsDefault.data.filter((elem)=>
          elem.id === selectForm[0].formIdDefault
        )[0]);
      }
    }
  };

  useEffect(()=>{
    if (props.setPrinterInfo) props.setPrinterInfo(getSelectPrinterInfo());
  }, [id, allFormsUser.data]);

  const onSendPDFPrint = async () =>{
    setIsOpenLoadingModal(true);
    setStatusLoading('loading');
    const printerInfo = getSelectPrinterInfo();
    const actualPdfParams = pdfParams;
    actualPdfParams.reportId = printerInfo.defaultFormID ? `${printerInfo.defaultFormID}` : `${printerInfo.id}`;
    const params = {
      pdfParams: actualPdfParams,
    };
    setStatusLoading('Upload PDF');
    await callReportSystem({ variables: { params: params } }).then((db)=> {
      const reduceData = db.data.fakturaAssist.callFA_ReportSystem;
      //
      setStatusLoading('Check PDF status');

      const taskId = reduceData._taskids[0];
      const checkStatus = async (taskId) => {
        const reportInfoData = await checkReportStatus(taskId);
        if(reportInfoData.state.id === 2) {
        // ready
          setStatusLoading(`Send to print ${printerInfo.printer}`);
          Mutation_sendPDFToPrint({
            variables: {
              PrinterName: printerInfo.printer,
              TrayID: `${printerInfo.printerTray}`,
              Downloadlink: `${db.data.fakturaAssist.callFA_ReportSystem.link}`,
              Copies: `${printerInfo.copies}`,
            },
          }).then((db)=> {
            if(db.data.fakturaAssist.sendPDFToPrint && db.data.fakturaAssist.sendPDFToPrint.State) {
              if(db.data.fakturaAssist.sendPDFToPrint.State === 'Ready') {
                setStatusLoading('Print completed successfully');
                setTimeout(() => setIsOpenLoadingModal(false), 3000);
              } else{
                setStatusLoading('Print error 1');
                setTimeout(() => setIsOpenLoadingModal(false), 3000);
              }
            } else{
              setStatusLoading('Print error 2');
              setTimeout(() => setIsOpenLoadingModal(false), 3000);
            }
          }).catch((err)=>{
            setStatusLoading('Print error 3');
            setTimeout(() => setIsOpenLoadingModal(false), 3000);
          });
        } else if(reportInfoData.state.id === 5) {
        // n progress
          setStatusLoading(reportInfoData.state.name);
          checkStatus(taskId);
        } else {
        // error
          setStatusLoading(reportInfoData.state.name);
          await delay(2000);
          setIsOpenLoadingModal(false);
        }
      };
      checkStatus(taskId);
      ///
    }).catch((err)=>{
      setStatusLoading('Error upload PDF');
      setTimeout(() => setIsOpenLoadingModal(false), 3000);
    });
  };



  const onShowPDFPrint = () =>{
    const printerInfo = getSelectPrinterInfo();
    const actualPdfParams = pdfParams;
    actualPdfParams.reportId = printerInfo.defaultFormID ? `${printerInfo.defaultFormID}` : `${printerInfo.id}`;
    showPDFPreview(actualPdfParams);
  };

  const onShowPDFPrintServerless = () =>{
    const printerInfo = getSelectPrinterInfo();
    const actualPdfParams = pdfParams;
    actualPdfParams.reportId = printerInfo.defaultFormID ? `${printerInfo.defaultFormID}` : `${printerInfo.id}`;
    actualPdfParams.isServerlessReport = true;
    showPDFPreview(actualPdfParams);
  };

  return(<Main {...props}>
    {withoutInput ?
      <div>
        {
          ///for products reports
          (avaliableReportForms.includes(6) || avaliableReportForms.includes(36) || avaliableReportForms.includes(50)) &&
          <AdditionBlock>
            {(Math.round(pdfParams.reportId) === 6 || Math.round(pdfParams.reportId) === 50) &&
              <Row>
                <Col width='100%'>
                  <Dropdown
                    data={
                      [
                        { name: 'Kunde', id: 1 },
                        { name: 'Preiskategorie', id: 2 },
                      ]
                    }
                    marginTitle='4px 0px 2px 0px'
                    customStyles={customStyles}
                    withoutFormik={true}
                    title={t('common.group')}
                    width='100%'
                    setFieldValue={(_, id)=>{
                      setPriceFormularId(id);
                      setActualPdfParams && setActualPdfParams({
                        ...props.pdfParams,
                        ACustomerID: id === 2 ? 0 : +customerId,
                        APriceCategory: id === 2 ? `${priceCategory}` : '0',
                      });
                    }}
                    valueField = 'id'
                    labelField = 'name'
                    name = 'priceFormularId'
                    defaultValueField = {priceFormularId || ((+pdfParams.APriceCategory || pdfParams.ACustomerID) ? pdfParams.ACustomerID ? 1 : 2 : null)}

                  />
                </Col>
              </Row>
            }

            <Row>
              <Col width='100%'>
                <Calendar paddingTop='0' marginTitle='8px 0px 2px 0px' title='Datum' withoutFormik={true} setFieldValue={(_, date)=>{
                  setDate(date); setActualPdfParams && setActualPdfParams({ ...props.pdfParams, APriceDate: date });
                }} name='fromDate' value={date || pdfParams.APriceDate}/>
              </Col>
            </Row>
            {(Math.round(pdfParams.reportId) === 6 || Math.round(pdfParams.reportId) === 50) &&
              <Row>
                {(priceFormularId || ((+pdfParams.APriceCategory || pdfParams.ACustomerID) ? pdfParams.ACustomerID ? 1 : 2 : null)) === 2
                  ?
                  <Col width='100%'>
                    <Dropdown
                      marginTitle='11px 0px 2px 0px'
                      customStyles={customStyles}
                      withoutFormik={true}
                      title={t('customer.customer')}
                      width='100%'
                      setFieldValue={(_, id)=>{
                        setPriceCategory(id);
                        setActualPdfParams && setActualPdfParams({
                          ...props.pdfParams,
                          APriceCategory: `${id}`,
                        });
                      }}
                      valueField = 'ResID'
                      labelField = 'ResName'
                      name = 'priceCategory'
                      defaultValueField = {priceCategory || +pdfParams.APriceCategory}
                      graphqlParams={{ query: listFA_preiskategorie }}
                    />
                  </Col>

                  :

                  <Col width='100%'>
                    <Dropdown
                      marginTitle='11px 0px 2px 0px'
                      customStyles={customStyles}
                      withoutFormik={true}
                      title={t('customer.customer')}
                      width='100%'
                      setFieldValue={(_, id)=>{
                        setActualPdfParams && setActualPdfParams({
                          ...props.pdfParams,
                          ACustomerID: +id,
                        });
                        setCustomerId(id);
                      }}
                      valueField = 'id'
                      labelField = 'nameCustomerDisp'
                      name = 'customerId'
                      defaultValueField = {customerId || pdfParams.ACustomerID}
                      graphqlParams={{ query: listFA_Customer, variables: { params: { limit: 100 } } }}
                    />

                  </Col>
                }

              </Row>
            }
          </AdditionBlock>
        }

        <Row>
          <Col width='10%'>
            <img src={Reports}/>
          </Col>
          <Col width='90%'>
            <Row>
              <Text margin='-3px 0px 0px 0px' fontSize='14px'>
              Open Report form
              </Text>
            </Row>
            <Row>
              <Col width='100%'>

                { (!id && loading) ?
                  <Loading>
                    <Dots radius='10px'/>
                  </Loading> :
                  <Text color='#3cb950' margin='0px 0px 0px 0px' >
                    {(!loading && data && data.find((i) => +i.id === defaultValueField) && data.find((i) => +i.id === defaultValueField).form)
                      || (actualData && actualData.find((i) => +i.id === defaultValueField) && actualData.find((i) => +i.id === defaultValueField).form)
                      || (actualData && actualData.find((i) => +i.id === id) && actualData.find((i) => +i.id === id).form)
                    }

                  </Text>
                }
              </Col>
            </Row>
            <Row>
              {(printingServiceAvailable) &&
                <Col lg='40%' onClick = {()=>onSendPDFPrint()}>
                  <Text decoration='underline' margin='2px 0px 0px 0px' >
                    PRINT
                  </Text>
                </Col>
              }
            </Row>
          </Col>
          <Col width='10%'>
            <img style={{ marginTop: '13px' }} onClick = {()=>setOpen(true)} src={icon_edit}/>
          </Col>
        </Row>

      </div> :
      <div>
        {title ? <Text>{title}</Text> : <div/>}
        <InputWrapper>
          <Input width={width} disabled value={loading ? null : (!loading && data && data.find((i) => +i.id === defaultValueField)
            && data.find((i) => +i.id === defaultValueField).form)
            || (actualData && actualData.find((i) => i.id === defaultValueField) && actualData.find((i) => i.id === defaultValueField).form)
            || (!allForms.loading && defaultValueField > -1 && `${t('common.incorrect_id_warning')}${defaultValueField}`)
            || ''
          }/>
          {loading && <LoadingWrapper>
            <Dots radius='5px'/>
          </LoadingWrapper>}
          <Img className='fas fa-ellipsis-h fa-2x' onClick = {()=>setOpen(true)} />
          {/* <Img src={Add} onClick = {()=>setOpen(true)}/> */}
        </InputWrapper>
      </div>}

    <Modal
      headerText={t('common.changeReportSystem')}
      width={'670px'}
      height={'430px'}
      isOpen={open}
      zIndex={100}
      closeModal={()=>setOpen(false)}
      Component={
        <ModalReportSystem
          onSelect={(value)=>{
            if(!withoutMutation) {
              onChangeFormik(value, field, true);
              submit(null, 'reportSystem');
            }
            setOpen(false);
          }}
          onSendPDFPrint={onSendPDFPrint}
          setOpenMain={setOpen}
          onShowPDFPrint={onShowPDFPrint}
          onShowPDFPrintServerless={onShowPDFPrintServerless}
          {...props}
          withPrinting={withPrinting}
          data={data || allForms.data}
          allForms={allForms}
          setId={setId}
          id={id}
          printingServiceAvailable={printingServiceAvailable}
        />
      }/>

    <Modal
      headerText={'Loading window'}
      width={'300px'}
      height={'150px'}
      isOpen={isOpenLoadingModal}
      zIndex={100}
      onRequestClose={()=>setIsOpenLoadingModal(false)}
      Component={
        <div>
          {statusLoading}
        </div>
      }/>

  </Main>);
};

export default compose(
  branch(
    ({ withoutFormik }) => !withoutFormik,
    withFieldSubmit
  ),
  withQuery,
  withPDFActions)(Index);

const Main = styled.div`
  width: 100%;
`;

const Loading = styled.div`
  margin: 10px 0 0 10px;
`;

const LoadingWrapper = styled(Loading)`
    position: absolute;
    top: 5px;
    right: 70px;
`;

const InputWrapper = styled.div`
    position: relative;
`;

const Input = styled.input`
  background: white;
  /* font-family: Roboto-Regular; */
  font-weight: 400;
  font-size: 14px;
  width: ${(props) => props.width ? props.width : '300px'};
  padding-left: 10px;
  height: 36px;
  border-radius: 3px;
  border: ${(props) => (props.noBorder ? 'none' : 'solid 1px #d2cfcf')} ;
  box-shadow: ${(props) => (props.noBorder ? 'none' : '0 1px 2px 0 rgba(90, 90, 90, 0.12)')}  ;
  color: ${(props) => (props.color ? props.color : props.notEdit ? '#ffffff' : 'inherit')};


`;

const Img = styled.i`
  width: 20px;
  height: 20px;
  position: absolute;
  margin: 5px 0 0 -40px;
  color: #434343;
`;

const Text = styled.div`
  cursor: pointer;
  font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.color ? props.color : '#3c445a'};
  margin: ${(props) => props.margin ? props.margin : '15px 0 6px 0'};
  text-decoration: ${(props) => props.decoration ? props.decoration : 'none'};
  padding-left: ${(props) => props.left ? props.left : '0px'};
`;

const AdditionBlock = styled.div`
  cursor: pointer;
  font-size: ${(props) => props.fontSize ? props.fontSize : '14px'};
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.color ? props.color : '#3c445a'};
  margin: ${(props) => props.margin ? props.margin : '0px 0 0px 0'};
  text-decoration: ${(props) => props.decoration ? props.decoration : 'none'};
  padding-left: ${(props) => props.left ? props.left : '10px'};
  right: 10px;
  top: 0px;
  position: absolute;
`;

// <Text text="value" color="string" fontSize ="300px" fontWeight="500" marginBottom='20px'/>
