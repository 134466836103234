export const phoneInputs = (t) => [
  {
    0: {
      title: t('customer.delivery.setupStation'),
      field: 'setupStation',
    },
    1: {
      title: t('customer.delivery.standartTour'),
      field: 'linkTourPlan',
    },
    2: {
      title: t('customer.delivery.drivingPos'),
      field: 'tourPlanOrder',
    },
    3: {
      title: t('common.deliveryTime'),
      field: 'timePlan',
    },
  },
  {
    0: {
      title: t('customer.delivery.setupStationSat'),
      field: 'setupStationSa',
    },
    1: {
      title: t('customer.delivery.satTour'),
      field: 'linkTourPlanSa',
    },
    2: {
      title: t('customer.delivery.drivingPos'),
      field: 'tourPlanOrderSa',
    },
    3: {
      title: t('common.deliveryTime'),
      field: 'timePlanSa',
    },
  },

  {
    0: {
      title: t('customer.delivery.setupStationSun'),
      field: 'setupStationSu',
    },
    1: {
      title: t('customer.delivery.sunTour'),
      field: 'linkTourPlanSu',
    },
    2: {
      title: t('customer.delivery.drivingPos'),
      field: 'tourPlanOrderSu',
    },
    3: {
      title: t('common.deliveryTime'),
      field: 'timePlanSu',
    },
  },

];
