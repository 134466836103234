import gql from 'graphql-tag';

export const CONFIG = gql`
query getFA_Config {
    fakturaAssist {
    	getFA_Config {
            isShopOnline
            isForbidCA
        }
    }
}`;
