import React from 'react';
import { Col } from 'react-bootstrap';
import { Text } from 'components/elements';

import './TableForFreezer.css';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const scroll = () =>{
    const newscrollLeft = document.getElementById('mainScroll').scrollLeft;
    document.getElementById('childrenScroll').scrollLeft = newscrollLeft;
  };

  return (
    <div>
      <Col lg={12}>
        <Col lg ={5} className='mainTableFreezHeader'>
          <Col lg ={12}> <Text text={t('production.preparationForFermentation')}/> </Col>
          <Col lg={12} className='mainHead'>
            <Col lg={12} className='borderRight'>
              <Col lg={12} className='borderTop' style={{ height: '36px', background: '#3e4c57', paddingTop: '8px', paddingBottom: '8px' }}>
                <div style={{ paddingLeft: '8px' }}>
                  <Col lg={2} className='borderRight'>
                    {t('common.articleNo')}
                  </Col>
                  <Col lg={4} className='borderRight'>
                    {t('common.description')}
                  </Col>
                  <Col lg={2} className='borderRight'>
                    {t('production.inStock')}
                  </Col>
                  <Col lg={2} className='borderRight'>
                    {t('production.ordered')}
                  </Col>
                  <Col lg={2}>
                    {t('production.newInStock')}
                  </Col>
                </div>
              </Col>
            </Col>
          </Col>
        </Col>
        {props.index !== null ?
          <Col lg ={7} className='mainTableFreezHeader padding_L20 '>
            <Col lg ={12}>
              <Col lg={5}>
                <Text text={t('common.orders')}/>
              </Col>
              <Col lg={7}>
                <Col lg={1} style={{ background: '#FEF3C5', width: '24px', borderRadius: '2px', height: '14px', border: 'solid 0.3px #bebebe' }} />
                <Col lg={2}> <Text text={t('common.deliveryNr')} fontSize='14px' padding='0 0 0 10px'/> </Col>
                <Col lg={1} style={{ background: '#FADED9', width: '24px', borderRadius: '2px', height: '14px', border: 'solid 0.3px #bebebe' }} />
                <Col lg={2}> <Text text={t('common.quantity')} fontSize='14px' padding='0 0 0 10px'/> </Col>
              </Col></Col>
            <Col lg={6}>
              <Col lg={12} className='borderTop' style={{ height: '36px', background: '#3e4c57', paddingTop: '8px', paddingBottom: '8px' }}>
                <div style={{ paddingLeft: '8px' }}>
                  <Col lg={4} className='borderRight' >
                    {t('common.articleNo')}
                  </Col>
                  <Col lg={8} className='borderRight' >
                    {t('common.description')}
                  </Col>
                </div>
              </Col>
            </Col>

            <Col lg ={6}>
              <Col lg ={12} className='overflowXHeader borderTop' id='childrenScroll' >
                <div style={{ width: `${props.nameCustomer.length * 200 + 'px'}` }}>
                  {props.mainCustomerproductinfo.map((item, index)=>{
                    if(props.ProductChildIndexForCustomer.indexOf(index) >= 0) {
                      return(
                        <div className='itemOverflowX'>
                          <Col lg = {12} style={{ height: '34px', background: '#3e4c57', paddingTop: '8px', paddingBottom: '8px' }}>
                            <Col lg = {12} className='borderRight'>
                              {props.nameCustomer[index].substring(0, 29)}
                            </Col>
                          </Col>
                        </div>
                      );
                    }
                  })}

                </div>
              </Col>
            </Col>
          </Col>
          :
          <Col lg ={7} />
        }
      </Col>

      <Col lg ={12}>
        <Col lg ={5} className='mainTableFreez'>

          <Col lg ={12}>
            {props.loading ? <i className='fas fa-spinner fa-pulse' /> :
              <Col lg ={12} className='itemTableFreez'>
                {props.mainProductInfo.map((item, index)=>{
                  return(
                    <div className='itemNoneOverflowX' onClick={()=>props.onClickMainTable(index)} style={{ height: '36px', borderLeft: props.index === index ? '8px solid #3e4c57' : '8px solid #cdcdcd', paddingTop: '8px', paddingBottom: '8px' }}>
                      <Col lg={2} className='borderRightlight'>
                        {item.SourceArticleNo}
                      </Col>
                      <Col lg={4} className='borderRightlight'>
                        {item.SourceArticleName}
                      </Col>
                      <Col lg={2} className='borderRightlight'>
                        {item.AvailableQuantity}
                      </Col>
                      <Col lg={2} className='borderRightlight'>
                        {+item.RequiredByNormalCustomers + +item.RequiredByFlexibleCustomers}
                      </Col>
                      <Col lg={2}>
                        {'-' + item.RequiredByFlexibleCustomers}
                      </Col>
                    </div>
                  );
                })}
              </Col>}

          </Col>
        </Col>
        {props.index !== null ?
          <Col lg ={7} className='padding_L20'>
            <Col lg ={12} className='mainTableFreez'>
              <Col lg ={6}>
                {props.mainProductChild[props.index].map((itemChild, indexChild)=>{
                  return(
                    <Col onClick={()=>props.onClickIngridientTable(indexChild)} lg ={12}>
                      <div className='itemNoneOverflowX' style={{ background: (indexChild + 1) % 2 === 0 && '#eeeeee', height: '36px', borderLeft: props.indexIngridient === indexChild ? '8px solid #3e4c57' : '8px solid #cdcdcd', paddingTop: '8px', paddingBottom: '8px' }}>
                        <Col lg={4} className='borderRightlight'>
                          {itemChild.OrderedArticleNo}
                        </Col>
                        <Col lg={8}>
                          {itemChild.OrderedArticleName}
                        </Col>
                      </div>
                    </Col>
                  );
                })}
              </Col>
              <Col lg ={6} className='overflowX' id='mainScroll' onScroll={scroll}>
                <div style={{ width: `${props.nameCustomer.length * 200 + 'px'}` }}>
                  {props.mainProductChildForCustomer.map((item, index)=>{
                    return(
                      <div className='itemOverflowX'>
                        {item.map((itemProductInfo, indexProductInfo)=>{
                          return(
                            <Col lg = {12} className='itemForOverflowX'>
                              <Col lg = {6} style={{ background: itemProductInfo.Menge || itemProductInfo.Menge === 0 ? '#FEF3C5' : '#E6E6E6', padding: '8px 0 8px 0' }}>
                                {itemProductInfo.LieferscheinNr ? itemProductInfo.LieferscheinNr : '-'}
                              </Col>
                              <Col lg = {6} style={{ background: itemProductInfo.Menge || itemProductInfo.Menge === 0 ? '#FADED9' : '#E6E6E6' }}>
                                <Col lg = {10} style={{ color: itemProductInfo.additionallyMenge === itemProductInfo.Menge
                                  ? 'black' :
                                  itemProductInfo.Menge - itemProductInfo.additionallyMenge > 0 ? 'green' : 'red', padding: '8px 0 8px 0' }}>
                                  {
                                    itemProductInfo.additionallyMenge === itemProductInfo.Menge
                                      ?
                                      itemProductInfo.Menge || itemProductInfo.Menge === 0 ? itemProductInfo.Menge : '-'
                                      : itemProductInfo.Menge - itemProductInfo.additionallyMenge > 0
                                        ?
                                        itemProductInfo.Menge + '(+' + (itemProductInfo.Menge - itemProductInfo.additionallyMenge) + ')'
                                        : itemProductInfo.Menge + '(' + (itemProductInfo.Menge - itemProductInfo.additionallyMenge) + ')'
                                  }
                                </Col>
                                <Col lg = {2}>
                                  {itemProductInfo.Menge || itemProductInfo.Menge === 0 ?
                                    <div>
                                      <Col lg ={12} style={{ marginTop: '5px', height: '10px' }} onClick={()=>props.mengeAdd(index, indexProductInfo)}>
                                        <div style= {{ border: ' 5px solid transparent', borderBottom: '4px solid #3e4c57', width: '5px' }} />
                                      </Col>

                                      <Col lg ={12} style={{ marginTop: '1px', height: '3px' }} />
                                      <Col lg ={12} style={{ marginTop: '1px', height: '10px' }} onClick={()=>props.mengeAway(index, indexProductInfo)} >
                                        <div style= {{ border: ' 5px solid transparent', borderTop: '4px solid #3e4c57', width: '5px' }} />
                                      </Col>
                                    </div>
                                    :
                                    ''}
                                </Col>
                              </Col>
                            </Col>
                          );
                        })}
                      </div>
                    );
                  })}

                </div>
              </Col>
            </Col>

          </Col>
          :
          <Col lg ={7} >
            <Col lg={2} />
            <Col lg={8} className='test12'>
              <Col lg={12}>
                <i className='far fa-hand-pointer' />
                {t('production.selectArticle')}
              </Col>
              <Col lg={12} />
            </Col>
            <Col lg={2} />
          </Col>}
      </Col>

    </div>
  );
};
