import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Modal from '../../elements/Modal/Modal';
import Button from '../../elements/Button/Button';

export default (props) =>{
  const {
    saveButton,
    headerText,
    modalWidth,
    modalHeight,
    isOpen,
    handleClose,
    Component,
    isUpdate,
    data,
    dataRow,
    rowIndex,
    defaultChangedValues,
    tableData,
    modalDisableBtnOnEmpty,
  } = props;
  const [t] = useTranslation();
  let allTouched = {};
  let objectSchema = {};
  for(const item of tableData) {
    if(item.isRequired) {
      allTouched = { ...allTouched, [item.dataBase]: true };
      objectSchema = {
        ...objectSchema,
        [item.dataBase]: (item.dataType === 'float' || item.dataType === 'percent')
          ?
          Yup.number(t('common.validation.isRequiredInput')).positive(t('common.validation.isRequiredInput')).required(t('common.validation.isRequiredInput')).nullable(t('common.validation.isRequiredInput'))
          :
          (item.dataType === 'date'
            ?
            Yup.string().required(t('common.validation.isRequiredInput')).nullable(t('common.validation.isRequiredInput')).matches(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/, t('common.validation.isRequiredInput'))
            :
            Yup.string().required(t('common.validation.isRequiredInput')).nullable(t('common.validation.isRequiredInput'))
          ),
      };
    }
  }

  return(
    <Modal
      headerText={headerText}
      width={modalWidth}
      height={modalHeight}
      isOpen={isOpen}
      onAfterClose={handleClose}
      closeModal={handleClose}
      Component={
        <div>
          <Formik
            validationSchema={Yup.object().shape(objectSchema)}
            initialValues={isUpdate ? data : data}
          >{
              (props) => {
                const onTouched = () => {
                  allTouched.noActiveBtnClick = true;
                  props.setTouched(allTouched);
                };
                const validationValue = () => {
                  if(props.values?.fromDate && props.values?.toDate && Date.parse(props.values?.toDate) < Date.parse(props.values?.fromDate)) {
                    return false;
                  }

                  for(const item of tableData) {
                    if(item.isRequired && !props.values[item.dataBase]) {
                      return false;
                    }
                  }
                  return true;
                };

                return(
                  <Main>
                    <Component {...props} dataRow={dataRow} tableData={tableData} />
                    <Footer>
                      <Col lg={6}>
                        <div style={{ marginRight: '10px' }}>
                          <Button
                            className={'grey-btn'}
                            width={'100%'}
                            onClick={handleClose}
                            value={t('common.cancel')}/>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div style={{ marginLeft: '10px' }}>
                          {saveButton(
                            props.values,
                            isUpdate,
                            rowIndex,
                            defaultChangedValues,
                            validationValue(),
                            props.errors,
                            props.setFieldValue,
                            onTouched,
                            props.setFieldError,
                            props.setTouched,
                            modalDisableBtnOnEmpty
                          )}
                        </div>
                      </Col>
                    </Footer>
                  </Main>
                );
              }}
          </Formik>
        </div>
      }/>
  	);
};

const Main = styled.div`
  padding: 10px 20px 20px 20px;
`;
const Footer = styled.div`
  margin-top: 20px;
`;

