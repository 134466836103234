import React from 'react';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import moment from 'moment';

import DateRange from 'components/elements/dateRange/DateRange';
import DXTableGrid from 'components/dxTableGrid';
import { Formik } from 'formik';

import { listFA_OverviewDough } from '../../qql/query';
import { tableData } from './tableData';

export default ({ t }) =>(
  <Formik initialValues={{ date: moment(), secondDate: moment(new Date()).add(1, 'days') }}>
    {({ handleSubmit, handleChange, values, setFieldValue }) =>{
      return (
        <div>
          <ArgsBlock>
            <FlexRow>
              <DateRange startField='date' endField='secondDate' values={values} t={t} onChange={setFieldValue} withoutFormik/>
            </FlexRow>
          </ArgsBlock>
          <Row>
            <TableBlock>
              <DXTableGrid
                columns={tableData(t)}
                graphqlParams={{
                  query: listFA_OverviewDough,
                  variables: { args: { date: values.date, secondDate: values.secondDate } },
                }} groupBy={['dough']} height={508}/>

            </TableBlock>
          </Row>
        </div>);
    }}
  </Formik>
);

const ArgsBlock = styled.div`
  background: #f4f4f4;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  padding: 0 20px;
`;

const FlexRow = styled(Row)`
    display: flex;
`;

const TableBlock = styled.div`
  margin: 20px 0px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  width: 100%;
`;

