import gql from 'graphql-tag';
//Used in information block on top
export default gql`
mutation ($args: CreateOrderInputs!){
    fakturaAssist{
    	callFA_CreateOrder(args: $args){
	    	errorCode
			errorText
			orderId
			orderNo
	 	}
 	}
}`;
