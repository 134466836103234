import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';

import { Input, Transition, Dropdown, Checkbox, Button } from 'components/elements';
import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid';

import { timeTableParams, settingTableTaskTypeData, settingTableArticleFilterData } from '../params';

const Main = ({ t, onSubmit, configData, setSpecialDateModal }) => {
  return (
    <Formik initialValues={configData} enableReinitialize={true}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (<Section> <HeadBlock>
          <Row>
            <Col lg={4}>
              <Input title={t('common.description')} field='orderNrSAP' value={values.orderNrSAP} />
            </Col>
            <Col lg={4}>
              <Dropdown data={[]} title={t('options.automation.groupType')}/>
            </Col>
            <Col lg={4}>
              <ButtonBlock value='Future Modal' upperCase onClick={()=> setSpecialDateModal(true)}/>
            </Col>
          </Row>
          <Row>
            {false && <Checkbox title ='some'/>}
          </Row>
        </HeadBlock>
        <Transition text={t('options.automation.executionTimeTable')} minHeight='200px' isOpen>
          <TableGrid
            tableData={timeTableParams(t)}

            height={200}
            disableHeightChange
          />
        </Transition>
        <TransitionBlock>
          <Transition text={t('common.settings')} minHeight='200px' isOpen>
            <Row>
              <Col lg={4}>
                <TableGrid
                  tableData={settingTableTaskTypeData(t)}

                  height={390}
                  disableHeightChange
                />
              </Col>
              <Col lg={4}>
                <SettingMiddleBlock>

                  <Dropdown data={[]} title={t('options.automation.taskType')}/>
                  <Input title={t('options.automation.taskDescription')}/>

                </SettingMiddleBlock>
              </Col>
              <Col lg={4}>
                <TableGrid
                  tableData={settingTableArticleFilterData(t)}
                  checkBoxList={[]}
                  data={[]}
                  changeCheckboxList={()=>{}}
                  height={390}
                  disableHeightChange
                  withCheckbox
                />
              </Col>
            </Row>
          </Transition>
        </TransitionBlock>

        </Section>
        );
      }}
    </Formik>
  );
};

export default Main;

const HeadBlock = styled.div`
  padding: 40px 10px 18px 15px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;

const SettingMiddleBlock = styled.div`
  padding: 0px 20px;
`;

const Section = styled.div`
  border-radius: 4px;

`;

const TransitionBlock = styled.div`
  margin-top: 10px;
`;

const ButtonBlock = styled(Button)`
  margin-top: 36px
`;
