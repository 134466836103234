
import styled from 'styled-components';
import React, { useState } from 'react';
import { Row, Col } from 'style';
import { Button } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid.js';
import { useTranslation } from 'react-i18next';

export default (props) =>{
  const [t] = useTranslation();
  const { data, setOpenCreateOfModal, createItem, mutationLoading, dataLoading } = props;
  const [id, setId] = useState(null);
  const [productNr, setProductNr] = useState(null);
  const [description, setDescription] = useState(null);

  const setFieldValue = (item) => {
    setId(item.id);
    setProductNr(item.productNumber);
    setDescription(item.name);
  };
  return (
    <HeadBlock>
      <TableGrid
        withSearch
        data={data}
        dataPolicy='local'
        searchColumns={['productNumber', 'name']}
        //searchValue={this.state.searchValue}
        //onSearch={this.onSearch}
        //getSortField={this.getSortField}
        height={670}
        name={t('customer.quantityDiscount')}
        tableData={[{
          name: t('common.number'),
          dataBase: 'productNumber',
          relativeWidth: 0.2,
        },
        {
          name: t('common.description'),
          dataBase: 'name',
        }]}
        changeSelectId={setFieldValue}
        linkId={id}
        loading={dataLoading}
        selectById
      />

      <Footer>
        <Col width='50%'>
          <div style={{ marginRight: '10px' }}>
            <Button
              float='right'
              className={'grey-btn'}
              width={'190px'}
              onClick={()=>setOpenCreateOfModal(false)}
              value={t('common.cancel')}/>
          </div>
        </Col>
        <Col width='50%'>
                        
          <div style={{ marginLeft: '10px' }}>
            {mutationLoading ?
              <i className='fas fa-spinner fa-pulse' />
              :
              <Button
                float='left'
                className={id ? 'black' : 'grey-btn'}
                width={'190px'}
                onClick={()=>{
                  id && createItem(id, productNr, description);
                }}
                value={t('common.save')}/>}
          </div>
        </Col>
      </Footer>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  height: 800px;
  padding: 20px 20px 20px 20px;
  background: #f4f4f4;
`;
const Footer = styled(Row)`
  margin-top: 20px;
`;


