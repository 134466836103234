export const historyTableParams = (t) =>([
  {
    name: t('common.date'),
    dataBase: 'test',
  },
  {
    name: t('common.time'),
    dataBase: 'test',
  },
  {
    name: t('history.operation'),
    dataBase: 'test',
  },
  {
    name: t('common.userName'),
    dataBase: 'test',
  },
  {
    name: t('history.field'),
    dataBase: 'test',
  },
  {
    name: t('history.oldValue'),
    dataBase: 'test',
  },
  {
    name: t('history.newValue'),
    dataBase: 'test',
  },
  {
    name: t('common.content'),
    dataBase: 'test',
  },
  {
    name: t('common.type'),
    dataBase: 'test',
  },
  {
    name: t('history.fieldNameDB'),
    dataBase: 'test',
  },
  {
    name: t('history.dbFieldType'),
    dataBase: 'test',
  },
  {
    name: t('history.newValueID'),
    dataBase: 'test',
  },

]);
