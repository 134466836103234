import gql from 'graphql-tag';

export default gql`
query listFA_OverviewProductGetProductByNumber ($args: FA_OverviewProductGetProductByNumberInputs!, $params: ListQueryArgs){
    fakturaAssist{
    	listFA_OverviewProductGetProductByNumber(args: $args, params: $params){
    		id
			productNr
			name
			customerNr
			nameRes
			price
			indTextDeliveryNote
			indTextProduction
			date
			orderNr
			total
			totalNetto
			discount
			quantity
 		}
 	}
}`;

