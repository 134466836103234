/*eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withTransitions } from 'components/section/hoc/withCache';

import Text from '../Text/Text';
import Line from '../Line/Line';
import triangle from 'style/img/triangle_copy_4.svg';
import { BtnWrapper } from '../FilterButton/FilterButton';

const Transitions = ({ onClick, children, pieces, text, transitions, index, setTransitions, minHeight, isWhite, ...prev }) => {
  const [isOpen, setOpen] = useState(prev.isOpen ? prev.isOpen : transitions.length ? transitions[index] : prev.isOpen);
  return (
    <TransitionBlock>
      <Block
        onClick={() => {
          setOpen(!isOpen);
          if (setTransitions) {
            setTransitions(index)
          } else {
            if (onClick) {
              onClick()
            }
          }
        }}
        isOpen={isOpen}
        isWhite={isWhite}
        withoutMargin={prev.withoutMargin}
      >
        <Header>
          <BtnWrapper>
            <div className='icon'>
              <i className={`icon-arrow ${isOpen ? 'opened' : ''}`}></i>
            </div>
            <Text padding='0 0 0 10px' text={text} color='#3c445a' size='16px' fontWeight={'500'} />
            {pieces && isOpen === false
              ?
              <Number>{pieces}</Number>
              : null
            }
          </BtnWrapper>
        </Header>
      </Block>
      {isOpen
        ?
        <Content minHeight={minHeight} isWhite={isWhite}>
          <Line marginTop={'0px'} marginBottom={'20px'} marginRight={'-10px'} marginLeft={'-10px'} backColor={isWhite && 'rgba(195, 195, 195, 0.2)'} />
          <div>
            {children}
          </div>
        </Content>
        : null}
    </TransitionBlock>
  );
};

export default withTransitions(Transitions);

const TransitionBlock = styled.div`
  cursor: pointer;
`;

const Block = styled.div`
    border-radius: ${(props) => props.isOpen ? '0' : '4px'};
    border-top: ${(props) => props.isOpen && !props.isWhite ? '1px solid #3e4c57' : null};
    height: 55px;
    background-color: ${(props) => props.isWhite ? '#fff' : '#f4f4f4'};
    margin-top: ${(props) => props.withoutMargin ? '0' : '20px'};
    margin-bottom: ${(props) => props.isOpen ? '0' : '10px'};
    padding: 0 20px 20px 20px;
    border: 1px solid #CDCDCD;
    cursor: pointer;
`;
const Header = styled.div`
    padding: 17px 0 17px 0;
    cursor: pointer;
`;
const Img = styled.img`
    float: right;
    margin: 10px 20px 0 0;
    transform: ${(props) => props.open ? 'rotateX(180deg)' : null} ;
`;
const Number = styled.div`
    float: left;
    width: 26px;
    height: 26px;
    margin: -3px 0 0 10px;
    padding: 3px 0 0 0;
    color: white;
    text-align: center;
    background-color: #3e4c57;
    border-radius: 20px;
`;
const Content = styled.div`
    min-height:${(props) => props.minHeight};
    background-color: ${(props) => props.isWhite ? '#fff' : '#f4f4f4'};
    border-radius: 0 0 4px 4px;
    box-shadow: 0 0 2px 0 rgba(90, 90, 90, 0.2);
    border: 1px solid #cdcdcd;
    border-top-width: 0;
    padding: 0 20px 20px 20px;
    height: auto;
    display: flow-root;
`;

Transitions.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  withoutMargin: PropTypes.bool,
  pieces: PropTypes.number,
};
Transitions.defaultProps = {
  isOpen: false,
  text: '',
};
