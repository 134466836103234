export const tableData = (t) => [
  {
    name: `${t('common.number')}`,
    dataBase: 'customerNr',
    relativeWidth: 0.3,
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },

  {
    name: `${t('common.name')}`,
    dataBase: 'nameCustomerDisp',
  },
];

export const menuObj = [
  {
    name: 'Adresse',
    link: '/customers/adresse',
    col: 2,
  },
  {
    name: 'Konditionen',
    link: '/customers/konditionen',
    col: 1,
  },
  {
    name: 'Vorgabebestellung',
    link: '/customers/vorgadedestellung',
    col: 2,
  },
  {
    name: 'Tour',
    link: '/customers/tour',
    col: 1,
  },
  {
    name: 'Formulare',
    link: '/customers/formulare',
    col: 2,
  },
  {
    name: 'CRM',
    link: '/customers/cRM',
    col: 1,
  },
  {
    name: 'List',
    link: '/customers/list',
    col: 2,
  },
  {
    name: 'CashAssist',
    link: '/customers/cashAssist',
    col: 1,
  },
];

export const additionalMenuObj = [
  {
    name: 'Order',
    link: '/customers/adresse',
  },
  {
    name: 'Default',
    link: '/customers/adresse',
  },
];

export const folderNames = (t) => [
  t('common.filter.dailyCustomers'),
  t('common.filter.customerWithEmptyOrders'),
  t('common.filter.acceptedOffers'),
  t('common.filter.activeCustomers'),
  t('common.filter.inactiveCustomers'),
  t('customer.customer'),
];

export const databaseNames = [
  'dailyCustomer',
  'customerWithEmptyOrders',
  'acceptedOffers',
  'activeCustomer',
  'inactiveCustomers',
  'customer',
];

export const typeOrder = (t) => [
  {
    type: t('order.order'),
    id: 1,
  },
  {
    type: t('order.additionalOrder'),
    id: 2,
  },
  {
    type: t('order.return'),
    id: 4,
  },
  {
    type: t('order.creditOrder'),
    id: 5,
  },
  {
    type: t('order.specialOrder'),
    id: 6,
  },
  {
    type: t('order.catering'),
    id: 9,
  },
];

export const tableDataList = [
  {
    name: 'Name:',
    dataBase: 'CustomerListName',
  },
];

export const tableDataCRM = [
  {
    name: 'User name:',
    dataBase: 'AddedOn',
  },
  {
    name: 'Date/time:',
    dataBase: 'VonDatum',
  },
  {
    name: 'Note:',
    dataBase: 'Note',
  },
];

