import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FilterButton, ReportSystemInput } from '../elements';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { CONFIG } from './gql/getFA_Config';
import { allActions } from './params';
import HistoryModal from '../HistoryModal';
import { get } from 'lodash';
import { Dots } from '../Loading/index';

const ActionsList = (props) => {
  const { notAllowed, reportForms, historyFormTypeID, linkId } = props;
  const [isOpen, changeOpen] = useState(false);
  const [isOpenHistory, setOpenHistory] = useState(false);
  const [actualPdfParams, setActualPdfParams] = useState(null);
  const [printerInfo, setPrinterInfo] = useState({});
  const [printingServiceAvailable, setPrintingServiceAvailable] = useState(false);
  const actionRef = useRef(null);
  const { data, loading } = useQuery(CONFIG, { fetchPolicy: 'cach-and-network' });

  useEffect(
    () => {
      const params = actualPdfParams;
      if (params) {
        for (const item in props.pdfParams) {
          if (+props.pdfParams[item] && (item !== 'reportId')) {
            params[item] = props.pdfParams[item];
          }
        }
      }

      setActualPdfParams(actualPdfParams || props.pdfParams);
    }, [JSON.stringify(props.pdfParams)]
  );

  useEffect(
    () => {
      const printingServiceLastActivity = new Date(get(data, 'fakturaAssist.getFA_Config.printingServiceLastActivity', null));

      if (printingServiceLastActivity) {
        const now = new Date();
        setPrintingServiceAvailable(Math.abs((now - printingServiceLastActivity) / 1000) < 600);
      }
    }, [data]
  );

  if (loading) return <Dots radius='8px' />;

  const renderActionsList = () => {
    const { actions, t, reportForms, customActions } = props;
    // Get all keys from  props.actions
    const allKeys = Object.keys(actions);

    // Compare actions from upper component with ALL LIST actions from './params' ;
    const arrayComparison = () => {
      const array = [];
      for (const key of allKeys) {
        for (const item of allActions({ t, actualPdfParams: actualPdfParams, printerInfo: printerInfo, ...props })) {
          if (key === item.action) {
            if (item.action !== 'printReport') {
              array.push({ ...item, func: !customActions ? item.defaultFunc : actions[key] });
            } else if (printingServiceAvailable === true) {
              array.push({ ...item, func: !customActions ? item.defaultFunc : actions[key] });
            }
            break;
          }
        }
      }
      return array;
    };
    //Map final array
    const renderActions = arrayComparison().map(({ func, text, icon, action }, index) => {
      return (
        action === 'reportForms' ?
          <Action width={((reportForms && reportForms.includes(6)) || (reportForms && reportForms.includes(36))) ? '600px' : '300px'}>
            <ReportSystemInput
              setActualPdfParams={setActualPdfParams}
              setPrinterInfo={setPrinterInfo}
              pdfParams={actualPdfParams}
              title={t('common.form')}
              avaliableReportForms={reportForms}
              defaultValueField={(+actualPdfParams.reportId * 1000000) + +actualPdfParams.AUserFormularID}
              withoutInput
              withoutMutation
              withPrinting
              printingServiceAvailable={printingServiceAvailable}
            />
          </Action> :
          <Action width={((reportForms && reportForms.includes(6)) || (reportForms && reportForms.includes(36))) ? '600px' : '300px'}
            onClick={() => {
              changeOpen(false);
              if (action === 'history') {
                setOpenHistory(true);
              } else {
                func(actualPdfParams, printerInfo);
              }
            }}
            key={index}
          >
            <ActionIcon src={icon} alt={text} />
            <span> {text}</span>
          </Action>

      );
    });
    return renderActions;
  };

  return (
    <>
      <HistoryModal t={props.t} isOpen={isOpenHistory} closeModal={() => setOpenHistory(false)} historyFormTypeID={historyFormTypeID} recordId={linkId ? linkId.toString() : '0'} />
      <FilterButton value={props.t('common.actions')} onClick={() => notAllowed ? null : changeOpen(!isOpen)} />
      {isOpen && <ItemsActions innerRef={actionRef} id='action' width={((reportForms && reportForms.includes(6)) || (reportForms && reportForms.includes(36))) ? '600px' : '300px'} notAllowed={notAllowed}>{renderActionsList()} </ItemsActions>}
    </>
  );
};
export default ActionsList;

const ItemsActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 2px 4px 0 rgba(90, 90, 90, 0.2);
  cursor: pointer;
  right: 0;
  width: ${({ width }) => width};
  position: absolute;
  z-index: 3;
  background: #ffffff;
  cursor: ${({ notAllowed }) => notAllowed ? 'not-allowed' : 'pointer'};
`;

const Action = styled.div`
  /* font-family: Roboto-Regular; */
  font-weight: 400;
  font-size: '16px';
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 63px;
  float: left;
  display: flex;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  width: ${({ width }) => width};
  padding: 0 20px;
  &:first-child {
    border: none;
  }
  &:hover {
    box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.14);
    background-color: #f4f4f4;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
`;

const ActionIcon = styled.img`
	float: left;
	padding: 0 13px 0 0;
`;

export const ActionsListBlock = styled.div`
  position: absolute;
  margin-top: 0;
  margin-right: 20px;
  top: 0;
  right: 0;
`;

ActionsList.propTypes = {
  actions: PropTypes.object.isRequired, //Object with keys from params. key: function
  t: PropTypes.func.isRequired, // Translate function
  text: PropTypes.string, // Text in buttom. By default - Actions
};

/*  Example. t(i18) is a required PARAMS!!!!!!
  <ActionsList
      text={t('common.actions')}
      t={t}
      actions={{
        printReport: createNewOrder,
        preview: createNewOrder,
        debitorPrint: createNewOrder,
        email: createNewOrder,
        history: createNewOrder,
        importOrder: createNewOrder,
      }}
     />
*/
