//Id on new Input - link from FA_Customer
import gql from 'graphql-tag';

export default gql`
query ($id: ID!) {
    fakturaAssist{
    	getFA_Customer(id: $id){
    		id
 		}
 	}
}`;
