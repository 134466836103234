import React from 'react';
// import styled from 'styled-components';

import IconWrapper from './IconWrapper';
import { ReactComponent as Download } from 'style/img/download_image.svg';

const ImageDownload = ({ img }) => {
  return (
    <IconWrapper width={'50%'}>
      <a download='image.png' href={`data:image/png;base64,${img}`}>
        <Download/>
      </a>
    </IconWrapper>
  );
};

export default ImageDownload;
