import gql from 'graphql-tag';

export const Recept = gql`
query getFA_RezeptAssist($id: ID!) {
  fakturaAssist {
    getFA_RezeptAssistRecipes(id:$id){
      id
      CA_WeightSale
      concatField
    }
  }
}
`;

export const LIST_RECIPES = gql`
query listFA_RezeptAssistRecipes($params: ListQueryArgs) {
  fakturaAssist {
    listFA_RezeptAssistRecipes(params: $params) {
      id
      concatField
      name
      number
      CA_WeightSale
    }
  }
}`;
