import React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

import { withFieldSubmit } from '../../formik';
import checkedIcon from '../../elements/style/path.svg';

const Radio = (props) =>{
  const { onChangeFormik, title, field } = props;
  return (
    <Label >
      	<RadioStyle
      		type ='radio'
      		onClick={(e) => {
          const { value } = e.target;
          if(+value) {
            onChangeFormik(parseInt(value));
          }else{
            onChangeFormik(e.target.value);
          }
        }
        }
  	      	field={field}
  	      	{...props}
  	      />
      <RadioCircle/>
      	<Title> {title}</Title>
    </Label>
  );
};

export default compose(withFieldSubmit)(Radio);

const Label = styled.label`
    font-weight: 400;
    float: left;
`;

const RadioCircle = styled.span`
  background-size: 60%;
  content: '';
  display: inline-block;
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  background-color: #fff;
  margin-right: 8px;
  border: 0.5px solid #cdcdcd;
  /* -webkit-transition: background-color 0.3s, border 0.3s; */
  transition: background-color 0.3s, border 0.3s;
`;

const Title = styled.span`
`;

const RadioStyle = styled.input`
  display: none;
  &:checked + ${RadioCircle} {
    background-image: url(${checkedIcon});
    background-repeat: no-repeat;
    background-position: center;
    border-color: #3cb950;
    
  }
`;
