
import gql from 'graphql-tag';

export default gql`
mutation createFA_CustomerListsDefault($fields: CustomerListsDefaultFields!){
    fakturaAssist{
    createFA_CustomerListsDefault(fields: $fields){
		id
		name
		isValue
		customerListsRelationID
		linkCustomer
    }
    
  }
}`;
