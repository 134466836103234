import gql from 'graphql-tag';

export const TotalCategories = gql`
query listFA_ProductSpecialPriceConnection($linkArticle: Int){
  fakturaAssist {
    listFA_ProductSpecialPriceConnection(linkArticle: $linkArticle) {
        total
    }
  }
}`;

export const TotalCustomerPrice = gql`
query listFA_CustomerSpecialPricesAndDiscountsProductConnection($id: Int){
  fakturaAssist {
    listFA_CustomerSpecialPricesAndDiscountsProductConnection(id: $id) {
        total
    }
  }
}`;