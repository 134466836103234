import loadingImg from 'components/Loading/loading.svg';
import styled from 'styled-components';

export const LoadingWrap = styled.div`
  min-height: ${({ height }) => height || 'auto'};
  position: relative;
  ${(props) => props.loading ? `
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: url(${loadingImg}) no-repeat center, rgba(255, 255, 255, 0.86);
			background-size: 60px auto;
		}` : ''}
`;
