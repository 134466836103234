import React from 'react';
import { remove, concat } from 'lodash';
import styled from 'styled-components';

import { useQuery } from 'components/graphql';
import { Checkbox } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';
import Formik from 'components/formik';

import { listFA_OrderTypesView } from '../../qql/query';

export default ({ t, configData, client, onSubmit }) => {
  const tableList = useQuery(listFA_OrderTypesView, { fetchPolicy: 'cache-first' });

  const onChange = ({ values, id, setFieldValue }) =>{
    let arr = values.orderTypesInProductionReports.split(',');
    if(arr.includes(id)) {
      remove(arr, (i)=>i === id);
    } else{
      arr = concat(arr, id);
    }
    const str = arr.join();
    setFieldValue('orderTypesInProductionReports', str);
  };

  return(
    <Formik initialValues={configData} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        const tableData = [
          {
            name: '',
            dataBase: 'id',
            relativeWidth: 0.15,
            specialComponent: (index, _, id)=>{
              return (
                <Checkbox
                  margin={'0px'}
                  zIndex={100}
                  onClick={()=>onChange({ values, id, setFieldValue })}
                  value={values.orderTypesInProductionReports.split(',').includes(id)}
                  withFormikSubmit
                />
      
              );
            },
      
          },
          {
            relativeWidth: 0.85,
            name: t('common.name'),
            dataBase: 'orderTypeName',
            isMutation: true,
          },
        ];
        return (
          <Content>
            <div>
              <TableGrid
                withSearch
                data={tableList.data}
                loading={tableList.loading}
                //searchValue={this.state.searchValue}
                //onSearch={this.onSearch}
                //getSortField={this.getSortField}
                //Component={Component}
                height={700}
                headerText={t('common.edit')}
                modalWidth={440}
                modalHeight={205}
                //editItem={this.openModal}
                tableData={tableData}
                //withModalMutations
                //getTableIndex={this.indexOnClickTable}

              />
            </div>
          </Content>
        );
      }}
    </Formik>
  );
};

const Content = styled.div`
  padding: 10px 20px 20px 20px; 
  background: #f4f4f4;
  margin: 20px;
  min-height: 755px;
  & > div:nth-child(1){
    width: 500px;
  }
`;
