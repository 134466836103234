import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Button } from 'components/elements';
const MinMaxModal = ({ closeModal, minMaxInfo }) => (
  <ModalBody>
    <p>
      Input no allowed. {minMaxInfo.error} = {minMaxInfo.error === 'Maxium' ? minMaxInfo.max : minMaxInfo.min}
      <br/>
      Range from {minMaxInfo.min} to {minMaxInfo.max}
    </p>
    <ButtonBlock lg={4} lgOffset={4}>
      <Button value='OK' onClick={closeModal} />
    </ButtonBlock>
  </ModalBody>
);

const ModalBody = styled.div`
  padding-top: 20px;
  text-align: center;
  font-size: 15px;
`;

const ButtonBlock = styled(Col)`
  margin-top: 15px;
`;

export default MinMaxModal;
