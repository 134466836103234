import React, { useRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import gql from 'graphql-tag';

import { useMutation } from 'components/graphql';

const withMutate = (WrappedComponent) => (props) => {
  const cacheSection = useRef(null);
  const updateItem = props.sectionInfo?.updateItem;
  const [updateItemMutation] = useMutation(updateItem || gql`mutation{_}`);

  useEffect(()=> {
    //Add init value
    if(!cacheSection.current) {
      cacheSection.current = props.sectionInfo;
    }
  }, []);

  const updateItemForm = (params, sect, specialId) =>{
    const sectionInfo = props.sectionInfo;
    //cacheSection.current = sect;
    const finalMutation = async (params) => {
      const { client } = props;
      try{
        await updateItemMutation(params);
        const dataForCache = props.client.readQuery({
          query: sectionInfo.itemQuery,
          variables: {
            id: +params.variables.id,
          },
        }).fakturaAssist[sectionInfo.itemQuery.definitions[0].name.value];
        const copyCacheData = cloneDeep(dataForCache);
        for(const key in params.variables.fields) {
          if(params.variables.fields) {
            copyCacheData[key] = params.variables.fields[key];
          }
        }
        const copyCacheData1 = cloneDeep(copyCacheData);
        if (copyCacheData1.__typename === 'FA_ProductionEditDefault') {
          copyCacheData1.__typename = 'FA_ProductionEdit';
        }
        client.writeQuery({
          query: sectionInfo.itemQuery,
          variables: {
            id: +params.variables.id,
          },
          data: {
            fakturaAssist:
              {
                [sectionInfo.itemQuery.definitions[0].name.value]: copyCacheData1,
                __typename: 'FA_Query',
              },
          },
        });
      }catch(err) {
        //
      }

    };
    const { linkId } = props;
    const cloneParams = cloneDeep(params);
    delete cloneParams.__typename;
    if (cloneParams.productImage) delete cloneParams.productImage; // remove productImage from payload as it resized on server
    
    finalMutation({ variables: { id: specialId ? specialId : +linkId, fields: { ...cloneParams } } });
    cacheSection.current = props.sectionInfo;
  };
  return <WrappedComponent {...props} mutations={updateItemForm}/>;
};
export default withMutate;

