import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'components/elements';
import { Col } from 'style';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';

import updateFA_ProductionEdit from '../qql/updateFA_ProductionEdit.js';

import getFA_ProductionEdit from '../qql/getFA_ProductionEdit.js';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const { values = {}, setFieldValue, data } = props;
  const [mutation_updateFA_ProductionEdit] = useMutation(updateFA_ProductionEdit);
  let dataCache = null;
  // console.log({ 'fromProps': data.reportGroupID, 'fromValues': values.reportGroupID })
  useEffect(() => {
    setFieldValue('reportGroupID', data.reportGroupID);
  }, [data.id, data.reportGroupID]); // we should update formik value if reportGroupID was changed , not only id of production

  const { client, loading } = useQuery(getFA_ProductionEdit, { variables: { id: data && data.id }, skip: !data || !data.id });

  if(!props.loading && !values.reportGroupID && !data.reportGroupID) {
    if(!loading && data && data.id) {
      dataCache = client.cache.readQuery({
        query: getFA_ProductionEdit,
        variables: { id: data.id },
      }).fakturaAssist.getFA_ProductionEdit.reportGroupID;
    }
  }
  const changeValue = (name, value) =>{
    setFieldValue(name, value);
    switch(value) {
      case 3:
        mutation_updateFA_ProductionEdit(
          {
            variables:
                  {
                    id: data.id,
                    fields: { id: data.id, reportID: 12000000 },
                  },
          }
        );
        break;
      case 11:
        mutation_updateFA_ProductionEdit(
          {
            variables:
                  {
                    id: data.id,
                    fields: { id: data.id, reportID: 49000000 },
                  },
          }
        );
        break;
      default:
        console.log(value);
    }
  };
  const params = [
    { name: t('production.production'), id: 1 },
    { name: t('production.forwardingListReportGroup'), id: 8 },
    { name: t('production.crossTableReportGroup'), id: 9 },
    { name: `${t('common.labels')} (LabelAssist)`, id: 3 },
    { name: t('production.doughList'), id: 11 },
  ];
  // console.log(values);
  // console.log(values.reportGroupID);
  return(
    <HeadBlock>
      <MainBlock>
        <Col lg={3}>
          {
            <Dropdown
              key={Math.random()}
              data={params}
              withoutFormik={true}
              title={t('common.group')}
              width='100%'
              setFieldValue={changeValue}
              valueField = 'id'
              labelField = 'name'
              name = 'reportGroupID'
              defaultValueField = {data && (values.reportGroupID || data.reportGroupID || dataCache)}
            />
          }
        </Col>
      </MainBlock>
    </HeadBlock>);
};

const HeadBlock = styled.div`
  height: 100px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
