import gql from 'graphql-tag';

export const Sale = gql`
query listFA_ViewSaleUnits {
    fakturaAssist {
      listFA_ViewSaleUnits{
        id
        name
      }
    }
}`;
  
export const Tax = gql`
query listFA_ViewTaxKinds {
    fakturaAssist {
      listFA_ViewTaxKinds{
        id
        name
      }
    }
}`;
