import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Line = (props) => (
  <LineStyle {...props}/>
);

export default Line;

const LineStyle = styled.hr`
  width: ${(props) => props.width ? props.width : '100%'};
  height: ${(props) => props.height ? props.height : '2px'};
  border: 0;
  margin: 0;
  padding: ${(props) => props.padding ? props.padding : '0'};
  background-color: ${(props) => props.backColor ? props.backColor : '#ffffff'};
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0'};
  margin-left: ${(props) => props.marginLeft ? props.marginLeft : '0'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0'};
  margin-right: ${(props) => props.marginRight ? props.marginRight : '0'};
`;

Line.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backColor: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};

/*
  Example
    <Line/>
*/
