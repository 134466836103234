/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import icon_add from '../../style/img/addWhite.svg';
import Radio from './components/TableCheckbox.js';

const TableHeader = ({
  getColumnWidth,
  addNewItem,
  tableData,
  headHeight,
  width,
  withCheckbox,
  changeCheckboxList,
  checkboxList,
  setTableUpdate,
  getSortField = () => {},
  changeSortParams,
  sortParams,
  localTableFilter,
  getCheckboxList,
  sortArray,
  onSort,
  sortObject,
  loading,
  initialSort,
  actionsToLeft,
  disableRows
}) => {

  const [mainCheckBox, changeMainCheckBox] = useState(checkboxList && checkboxList.every((i) => i));
  let allWidth = 0;
  const table = tableData.map(
    ({ headerTextAlign, name, relativeWidth, dataBase, disableSort, dataType, textAlign }, index) => {
      allWidth += getColumnWidth(width, { index: index });

      const MatchText = () => {
        if(typeof name !== 'object'){
          const addText = name?.match(/\((.*?)\)/g);
          const mainText = name?.replace(name?.match(/\((.*?)\)/g), '');
          return (
            <>
              <HeaderText>{mainText}</HeaderText>
              <br/>
              <HeaderTextSmall>{addText}</HeaderTextSmall>
            </>
          )
        }
        return <HeaderText>{name}</HeaderText>
      };
      return (
        <NameBlock
          key={index}
          onClick={ () => {
            // On press on header item. ChangeSortParams from HOC withParams
            !loading && !disableSort? onSort && onSort(dataBase, changeSortParams) : null
          }}
          width={getColumnWidth(width, { index: index }) + 'px'}
          textAlign={textAlign ? textAlign : dataType === 'float' || dataType === 'number' || dataType === 'percent' ? 'right' : 'left'}
          headHeight={headHeight}
          sort={sortObject ? sortObject.column===dataBase ? sortObject.order : null : null}
        >
          {withCheckbox && index === 0 && <RadioPosition> <Radio checked={mainCheckBox} onClick={(()=>{
            changeMainCheckBox(!mainCheckBox);
            const changedCheckBoxList = checkboxList.map(()=> !mainCheckBox)
            changeCheckboxList(changedCheckBoxList);
            getCheckboxList(changedCheckBoxList);
          })} submit={(()=>{})}/> </RadioPosition>}
          <MatchText/>
        </NameBlock>
      );
    }
  );
  return (
    <>
    <Main allWidth={allWidth} width={width}>
      {addNewItem && actionsToLeft && (
        <AddBlockLeft onClick={()=>{
          addNewItem();
          setTableUpdate && setTableUpdate(false);
        }}>
          <img src={icon_add} alt='' />
        </AddBlockLeft>
      )}
      {table}
      {addNewItem && !actionsToLeft && !disableRows && (
        <AddBlock onClick={()=>{
          addNewItem();
          setTableUpdate && setTableUpdate(false);
        }}>
          <img src={icon_add} alt='' />
        </AddBlock>
      )}
    </Main>
    </>
  );
};

TableHeader.propTypes = {
  headerTextAlign: PropTypes.string,
  name: PropTypes.string,
  relativeWidth: PropTypes.number,
};

const NameBlock = styled.div`
  width: ${(props) => props.width};
  /* height: ${(props) => props.headHeight ? props.headHeight : '36px'}; */
  float: left;
  position: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: ${({ sort, textAlign }) => textAlign ? '10px 10px 10px 10px' : sort==='ASC' ? "6px 0 0 10px" : "10px 0 0 10px"};
  background-color: #434343;
  border-radius: 3px 3px 0 0;
  color: #fff;
  cursor: pointer;
  border-top:  ${({sort}) => sort==='ASC' ? "2px solid #e23530" : "initial"};
  border-bottom:  ${({sort}) => sort==='DESC' ? "2px solid #e23530" : "initial"};
  line-height: 1;
  margin: ${({sort}) => sort? "0 auto" : "auto"};
  /* margin: auto; */
`;

const AddBlock = styled.div`
  width: 36px;
  /* height: 36px; */
  right: 0px;
  /* position: absolute; */
  /* background-color: #434343; */
  /* padding-top: 8px; */
  cursor: pointer;
  border-radius: 3px;
  /* margin-top: auto; */
  margin-left: -36px;
  align-items: center;
  display: flex;
  justify-content: center;
`;


const AddBlockLeft = styled.div`
  width: 36px;
  /* height: 36px; */
  left: 0px;
  /* position: absolute; */
  /* background-color: #434343; */
  /* padding-top: 8px; */
  cursor: pointer;
  border-radius: 3px;
  /* margin-top: auto; */
  margin-left: 6px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const RadioPosition = styled.div`
    margin: 2px 10px 10px 10px;
    float: left;
`;

const Main = styled.div`
  border-radius: 3px 3px 0 0;
  width: ${(props) => props.allWidth ? (props.allWidth) + 'px' : (props.width) + 'px'};
  min-width: ${(props) => props.width ? (props.width) + 'px' : '100%'};
  background-color: #434343;
  display: flex;
`;

const HeaderText = styled.span`
  width: 126px;
  /* font-family: Roboto-Regular; */
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
`;

const HeaderTextSmall = styled.span`
  /* font-family: Roboto-Regular; */
  font-weight: 400;
  font-size: 11px;
  line-height: 1;
  text-align: right;
`;

export default TableHeader;

//textAlign ? textAlign : dataType === 'float' || dataType === 'number' || dataType === 'percent' ? 'right' : 'left'