import gql from 'graphql-tag';

export default gql`
query listProducts ($params: ListQueryArgs, $filter: [FA_GeneralFilter]){
  fakturaAssist {
    listProducts(params: $params, filter: $filter){
      id
      name
      productNumber
      isTitle
      isBruchTitel
      isDough
      isFermentationInterrupt
    }
  }
}`;
