import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Textarea, Button } from 'components/elements';
import Query from 'components/graphql/Query';

import phone from 'style/order/home-telephone.png';
import tel from 'style/order/phone.svg';
import faximg from 'style/order/fax.svg';
import emailIcon from 'style/order/email.svg';
import { getFA_Customer } from '../../qql/query';
import updateFA_Customer from '../../../customer/qql/updateFA_Customer';
import MutationHoc from 'components/graphql/MutationHoc';
import { LoadingWrapper } from 'components/elements/Filter/Filter';
import { Dots } from 'components/Loading';

const infoParams = ({ fax, phoneG, phoneG2, phoneP, email, natel }) => ([
  {
    text: phoneG,
    icon: phone,
  },
  {
    text: natel,
    icon: tel,
  },
  {
    text: phoneG2,
    icon: phone,
  },
  {
    text: fax,
    icon: faximg,
  },
  {
    text: phoneP,
    icon: phone,
  },
  {
    text: email,
    icon: emailIcon,
  },
]);

const InfoCustomer = (props) => {
  const { id, closeModal, initialOrderInfo, setOrderInfo, t } = props;
  const [textareaValue, setTextareaValue] = useState('');
  const memoRef = useRef(null);
  const onAccept = (mutation) => {
    setOrderInfo({ ...initialOrderInfo, textareaValue: textareaValue });
    mutation({ variables: { id, fields: { deliveryNote: memoRef.current === '<p><br></p>' ? '' : memoRef.current } } });
    closeModal();
  };

  return (
    <Query query={getFA_Customer} variables={{ id: id }} fetchPolicy='cache-and-network'>
      {({ data, client, loading, error }) => {
        const newParams = infoParams(data || {});
        const renderContactsInfo = newParams.map(({ text, icon }, index) => {
          return <Col lg={6}><ContactsBlock index={index}> <img src={icon} alt={text} width={18} height={18} /><span> {text || '-'} </span></ContactsBlock></Col>;
        });
        if (loading) return <LoadingWrapper><Dots radius='25px' /></LoadingWrapper>;
        return (
          <Main>
            <Row>
              <TitleText> {data.customerNr || t('common.loading')} - {data.nameId || data.nameCustomer}</TitleText>
            </Row>
            <Row>
              {/* <Col lg={4}>
    			<CompanyAdressBlock>
    				<span>{data.deliveryNote || t('common.noData')}
                  </span>
    			</CompanyAdressBlock>
    		</Col> */}
              <Col lg={12}>
                <ContactsMain>
                  {renderContactsInfo}
                </ContactsMain>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Textarea
                  width={'100%'}
                  height={150}
                  title={t('common.memo')}
                  value={textareaValue}
                  initValue={data.deliveryNote}
                  setInitValue={setTextareaValue}
                  editorBgColor={textareaValue && textareaValue !== '<p><br></p>' ? '#FFFC05' : '#FFFFFF'}
                  editorFontWeight='bold'
                  onChange={(text) => {
                    memoRef.current = text;
                    setTextareaValue(text);
                  }}
                />
              </Col>
            </Row>
            <BottomBlock>
              <Col lg={3} lgOffset={2}>
                <Button value={t('common.cancel')} className='grey-btn' upperCase onClick={closeModal} />
              </Col>
              <MutationHoc mutation={updateFA_Customer} >
                {({ mutation }) => {
                  return (
                    <Col lg={3} lgOffset={1}>
                      <Button value={t('common.save')} className='black' upperCase onClick={closeModal} onClick={onAccept.bind(null, mutation)} />
                    </Col>
                  );
                }}
              </MutationHoc>
            </BottomBlock>
          </Main>
        );
      }}
    </Query>
  );
};
export default InfoCustomer;

const Main = styled.div`
  background: #f5f5f5;
  padding: 15px 20px 20px 20px;
`;

// const CompanyAdressBlock = styled.div`
//   height: 110px;
//   border-radius: 3px;
//   box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
//   background: #ffffff;
//   color: #3c445a;
// `;

const ContactsBlock = styled.div`
  height: 36px;
  box-shadow: 0 1px 0 0 rgba(76, 76, 76, 0.08);
  background: ${({ index }) => (
    index === 2 || index === 3 ? '#ffffff' : '#ffffff'
  )};
  width: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #e7e7e7 ;
  padding: 10px 12px;
  border: 1px solid #ececec;
  & span {
    margin-left: 5px;
  }
`;

const ContactsMain = styled.div`
	margin: 0px 0px;
`;

const TitleText = styled.span`
  font-size: 18px
  font-weight: 600;
  color: #3c445a;
  display: inline-block
  padding: 0 0 16px 0;
`;

const BottomBlock = styled(Row)`
    margin-top: 40px;
`;

