import gql from 'graphql-tag';

export default gql`
mutation createFA_Assortment($fields: FA_AssortmentInput){
    fakturaAssist{
		createFA_Assortment(fields: $fields){
		    id
            name
    }
  }
}`;