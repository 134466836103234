import gql from 'graphql-tag';

export default gql`
mutation ($id: ID!, $fields: FA_CreatedOrderForCustomersFields!){
    fakturaAssist{
      updateFA_CreatedOrderForCustomers(id: $id, fields: $fields){
		    date
        
    }  
  }
}`;
