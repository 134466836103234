import React from 'react';
import { components } from 'react-select';

function NoOptionsMessage(props) {
  return (
    <components.NoOptionsMessage {...props} />
  );
}

export default NoOptionsMessage;
