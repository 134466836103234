/*eslint-disable */
import React, { useState, useRef, useMemo } from 'react';
import StatusIndicator from '../components/StatusIndicator';
//import { AlertModal } from '../../Modal';


export const StatusIndicatorContext = React.createContext();

export const withStatusIndicatorContext = (Component) => (props) => {
  const [status, setStatus] = useState(null);
  const [alert, setAlert] = useState({ show: false, text: '' });
  const promiseMap = useRef({});
  const errorArr = useRef([]);
  const isNetworkError = useRef(false);
  const value = useMemo(() => {
    // remove resolved promises and set status if it was the last promise
    const handleComplete = (index) => () => {
      const { [index]: promise, ...newPromises } = promiseMap.current;
      promiseMap.current = newPromises;

      if(!Object.keys(newPromises).length) {
        if(!errorArr.current.length) {
          setStatus(null);
        } else {
          setStatus(isNetworkError.current ? 'networkError' : 'error');
        }
      }
    };

    // save any error which occured during promises resolution
    const handleError = (index) => (err) => {
      errorArr.current = [...errorArr.current, err];
      if(err && err.networkError) {
        isNetworkError.current = true;
      }
      handleComplete(index)();
    };
    return {
      // add new promise to the queue and reset errors and status if it was the first promise
      addPromise(promise) {
        if(!Object.keys(promiseMap.current).length) {
          setStatus('loading');
          errorArr.current = [];
        }

        const index = Object.keys(promiseMap.current).length;
        promise
          .then(handleComplete(index))
          .catch(handleError(index));
        promiseMap.current = {
          ...promiseMap.current,
          [index]: promise,
        };
      },
      setAlert,
    };
  }, []);
  return(
    <StatusIndicatorContext.Provider value={value}>
      {/* {
        alert.show && <AlertModal
          onClose={()=>{
            setAlert({ show: false, text: '' });
          }}
          label={'Fehler'}
          text={alert.text}
        />
      } */}
      {/* {alert.show && <ErrorModal
        errorText = {'Fehler'}
        isOpen={true}/> 
        } */}
      <Component {...props}/>
      <StatusIndicator status={status} />
    </StatusIndicatorContext.Provider>
  );
};
