import gql from 'graphql-tag';

export default gql`
mutation deleteFA_AusnahmeTag($id: Int){
    fakturaAssist{
		deleteFA_AusnahmeTag(id: $id){
		id
    }
    
  }
}`;