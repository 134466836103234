export const phoneInputs = (t) =>([
  {
    title: t('customer.telefonG'),
    field: 'phoneG',
  },
  {
    title: t('customer.fax'),
    field: 'fax',
  },
  {
    title: t('customer.additionalEmail'),
    field: 'emailForInvoice',
  },
  {
    title: t('customer.phoneG2'),
    field: 'phoneG2',
  },
  {
    title: t('customer.mobile'),
    field: 'natel',
  },
  {
    title: t('customer.internet'),
    field: 'internet',
  },
  {
    title: t('customer.phoneP'),
    field: 'phoneP',
  },
  {
    title: t('common.email'),
    field: 'email',
  },
  {
    title: t('customer.contactPerson'),
    field: 'contactPerson',
  },

]);
export const addressInputs = (t) => ([
  
  {
    title: t('customer.salutation'),
    field: 'salutation',
  },
  {
    title: t('common.street'),
    field: 'street',
  },
  {
    title: t('customer.internalName'),
    field: 'nameId',
  },
  {
    title: t('common.name'),
    field: 'nameCustomer',
  },
  {
    title: t('customer.postBox'),
    field: 'postOfficeBox',
  },

]);

export const lands = [{
  name: 'SWITZERLAND',
  id: 'SWITZERLAND',
},
{
  name: 'GERMANY',
  id: 'GERMANY',
},
{
  name: 'AUSTRIA',
  id: 'AUSTRIA',
},
{
  name: 'GREAT BRITAIN',
  id: 'GREAT BRITAIN',
},
{
  name: 'MALDIVES',
  id: 'MALDIVES',
},
{
  name: 'RUSSIAN FEDERATION',
  id: 'RUSSIAN FEDERATION',
},
{
  name: 'UNITED ARAB EMIRATES',
  id: 'UNITED ARAB EMIRATES',
},
{
  name: 'AMERICAN SAMOA',
  id: 'AMERICAN SAMOA',
},
{
  name: 'ANDORRA',
  id: 'ANDORRA',
},
{
  name: 'ANLA',
  id: 'ANLA',
},
{
  name: 'ANGUILLA',
  id: 'ANGUILLA',
},
{
  name: 'ANTARCTICA',
  id: 'ANTARCTICA',
},
{
  name: 'ANTIGUA AND BARBUDA',
  id: 'ANTIGUA AND BARBUDA',
},
{
  name: 'ARGENTINA',
  id: 'ARGENTINA',
},
{
  name: 'ARMENIA',
  id: 'ARMENIA',
},
{
  name: 'ARUBA',
  id: 'ARUBA',
},
{
  name: 'AUSTRALIA',
  id: 'AUSTRALIA',
},
{
  name: 'AZERBAIJAN',
  id: 'AZERBAIJAN',
},
{
  name: 'BAHAMAS',
  id: 'BAHAMAS',
},
{
  name: 'BAHRAIN',
  id: 'BAHRAIN',
},
{
  name: 'BANGLADESH',
  id: 'BANGLADESH',
},
{
  name: 'BARBADOS',
  id: 'BARBADOS',
},
{
  name: 'BELARUS',
  id: 'BELARUS',
},
{
  name: 'BELGIUM',
  id: 'BELGIUM',
},
{
  name: 'BELIZE',
  id: 'BELIZE',
},
{
  name: 'BENIN',
  id: 'BENIN',
},
{
  name: 'BERMUDA',
  id: 'BERMUDA',
},
{
  name: 'BHUTAN',
  id: 'BHUTAN',
},
{
  name: 'BOLIVIA, PLURINATIONAL STATE OF',
  id: 'BOLIVIA, PLURINATIONAL STATE OF',
},
{
  name: 'BONAIRE, SAINT EUSTATIUS AND SABA',
  id: 'BONAIRE, SAINT EUSTATIUS AND SABA',
},
{
  name: 'BOSNIA AND HERZEVINA',
  id: 'BOSNIA AND HERZEVINA',
},
{
  name: 'BOTSWANA',
  id: 'BOTSWANA',
},
{
  name: 'BOUVET ISLAND',
  id: 'BOUVET ISLAND',
},
{
  name: 'BRAZIL',
  id: 'BRAZIL',
},
{
  name: 'BRITISH INDIAN OCEAN TERRITORY',
  id: 'BRITISH INDIAN OCEAN TERRITORY',
},
{
  name: 'BRUNEI DARUSSALAM',
  id: 'BRUNEI DARUSSALAM',
},
{
  name: 'BULGARIA',
  id: 'BULGARIA',
},
{
  name: 'BURKINA FASO',
  id: 'BURKINA FASO',
},
{
  name: 'BURUNDI',
  id: 'BURUNDI',
},
{
  name: 'CAMBODIA',
  id: 'CAMBODIA',
},
{
  name: 'CAMEROON',
  id: 'CAMEROON',
},
{
  name: 'CANADA',
  id: 'CANADA',
},
{
  name: 'CAPE VERDE',
  id: 'CAPE VERDE',
},
{
  name: 'CAYMAN ISLANDS',
  id: 'CAYMAN ISLANDS',
},
{
  name: 'CENTRAL AFRICAN REPUBLIC',
  id: 'CENTRAL AFRICAN REPUBLIC',
},
{
  name: 'CHAD',
  id: 'CHAD',
},
{
  name: 'CHILE',
  id: 'CHILE',
},
{
  name: 'CHINA',
  id: 'CHINA',
},
{
  name: 'CHRISTMAS ISLAND',
  id: 'CHRISTMAS ISLAND',
},
{
  name: 'COCOS (KEELING) ISLANDS',
  id: 'COCOS (KEELING) ISLANDS',
},
{
  name: 'COLOMBIA',
  id: 'COLOMBIA',
},
{
  name: 'COMOROS',
  id: 'COMOROS',
},
{
  name: 'CON',
  id: 'CON',
},
{
  name: 'CON, THE DEMOCRATIC REPUBLIC OF THE',
  id: 'CON, THE DEMOCRATIC REPUBLIC OF THE',
},
{
  name: 'COOK ISLANDS',
  id: 'COOK ISLANDS',
},
{
  name: 'COSTA RICA',
  id: 'COSTA RICA',
},
{
  name: 'COTE D\'IVOIRE',
  id: 'COTE D\'IVOIRE',
},
{
  name: 'CROATIA',
  id: 'CROATIA',
},
{
  name: 'CUBA',
  id: 'CUBA',
},
{
  name: 'CURACAO',
  id: 'CURACAO',
},
{
  name: 'CYPRUS',
  id: 'CYPRUS',
},
{
  name: 'CZECH REPUBLIC',
  id: 'CZECH REPUBLIC',
},
{
  name: 'DENMARK',
  id: 'DENMARK',
},
{
  name: 'DJIBOUTI',
  id: 'DJIBOUTI',
},
{
  name: 'DOMINICA',
  id: 'DOMINICA',
},
{
  name: 'DOMINICAN REPUBLIC',
  id: 'DOMINICAN REPUBLIC',
},
{
  name: 'ECUADOR',
  id: 'ECUADOR',
},
{
  name: 'EGYPT',
  id: 'EGYPT',
},
{
  name: 'EL SALVADOR',
  id: 'EL SALVADOR',
},
{
  name: 'EQUATORIAL GUINEA',
  id: 'EQUATORIAL GUINEA',
},
{
  name: 'ERITREA',
  id: 'ERITREA',
},
{
  name: 'ESTONIA',
  id: 'ESTONIA',
},
{
  name: 'ETHIOPIA',
  id: 'ETHIOPIA',
},
{
  name: 'FALKLAND ISLANDS (MALVINAS)',
  id: 'FALKLAND ISLANDS (MALVINAS)',
},
{
  name: 'FAROE ISLANDS',
  id: 'FAROE ISLANDS',
},
{
  name: 'FIJI',
  id: 'FIJI',
},
{
  name: 'FINLAND',
  id: 'FINLAND',
},
{
  name: 'FRANCE',
  id: 'FRANCE',
},
{
  name: 'FRENCH GUIANA',
  id: 'FRENCH GUIANA',
},
{
  name: 'FRENCH POLYNESIA',
  id: 'FRENCH POLYNESIA',
},
{
  name: 'FRENCH SOUTHERN TERRITORIES',
  id: 'FRENCH SOUTHERN TERRITORIES',
},
{
  name: 'GABON',
  id: 'GABON',
},
{
  name: 'GAMBIA',
  id: 'GAMBIA',
},
{
  name: 'GEORGIA',
  id: 'GEORGIA',
},
{
  name: 'GHANA',
  id: 'GHANA',
},
{
  name: 'GIBRALTAR',
  id: 'GIBRALTAR',
},
{
  name: 'GREECE',
  id: 'GREECE',
},
{
  name: 'GREENLAND',
  id: 'GREENLAND',
},
{
  name: 'GRENADA',
  id: 'GRENADA',
},
{
  name: 'GUADELOUPE',
  id: 'GUADELOUPE',
},
{
  name: 'GUAM',
  id: 'GUAM',
},
{
  name: 'GUATEMALA',
  id: 'GUATEMALA',
},
{
  name: 'GUERNSEY',
  id: 'GUERNSEY',
},
{
  name: 'GUINEA',
  id: 'GUINEA',
},
{
  name: 'GUINEA-BISSAU',
  id: 'GUINEA-BISSAU',
},
{
  name: 'GUYANA',
  id: 'GUYANA',
},
{
  name: 'HAITI',
  id: 'HAITI',
},
{
  name: 'HEARD ISLAND AND MCDONALD ISLANDS',
  id: 'HEARD ISLAND AND MCDONALD ISLANDS',
},
{
  name: 'HOLY SEE (VATICAN CITY STATE)',
  id: 'HOLY SEE (VATICAN CITY STATE)',
},
{
  name: 'HONDURAS',
  id: 'HONDURAS',
},
{
  name: 'HONG KONG',
  id: 'HONG KONG',
},
{
  name: 'HUNGARY',
  id: 'HUNGARY',
},
{
  name: 'ICELAND',
  id: 'ICELAND',
},
{
  name: 'INDIA',
  id: 'INDIA',
},
{
  name: 'INDONESIA',
  id: 'INDONESIA',
},
{
  name: 'IRAN (ISLAMIC REPUBLIC OF)',
  id: 'IRAN (ISLAMIC REPUBLIC OF)',
},
{
  name: 'IRAQ',
  id: 'IRAQ',
},
{
  name: 'IRELAND',
  id: 'IRELAND',
},
{
  name: 'ISLE OF MAN',
  id: 'ISLE OF MAN',
},
{
  name: 'ISRAEL',
  id: 'ISRAEL',
},
{
  name: 'ITALY',
  id: 'ITALY',
},
{
  name: 'JAMAICA',
  id: 'JAMAICA',
},
{
  name: 'JAPAN',
  id: 'JAPAN',
},
{
  name: 'JERSEY',
  id: 'JERSEY',
},
{
  name: 'JORDAN',
  id: 'JORDAN',
},
{
  name: 'KAZAKHSTAN',
  id: 'KAZAKHSTAN',
},
{
  name: 'KENYA',
  id: 'KENYA',
},
{
  name: 'KIRIBATI',
  id: 'KIRIBATI',
},
{
  name: 'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF',
  id: 'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF',
},
{
  name: 'KOREA, REPUBLIC OF',
  id: 'KOREA, REPUBLIC OF',
},
{
  name: 'KUWAIT',
  id: 'KUWAIT',
},
{
  name: 'KYRGYZSTAN',
  id: 'KYRGYZSTAN',
},
{
  name: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC',
  id: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC',
},
{
  name: 'LATVIA',
  id: 'LATVIA',
},
{
  name: 'LEBANON',
  id: 'LEBANON',
},
{
  name: 'LESOTHO',
  id: 'LESOTHO',
},
{
  name: 'LIBERIA',
  id: 'LIBERIA',
},
{
  name: 'LIBYAN ARAB JAMAHIRIYA',
  id: 'LIBYAN ARAB JAMAHIRIYA',
},
{
  name: 'LIECHTENSTEIN',
  id: 'LIECHTENSTEIN',
},
{
  name: 'LITHUANIA',
  id: 'LITHUANIA',
},
{
  name: 'LUXEMBOURG',
  id: 'LUXEMBOURG',
},
{
  name: 'MACAO',
  id: 'MACAO',
},
{
  name: 'MACEDONIA, THE FORMER YUSLAV REPUBLIC OF',
  id: 'MACEDONIA, THE FORMER YUSLAV REPUBLIC OF',
},
{
  name: 'MADAGASCAR',
  id: 'MADAGASCAR',
},
{
  name: 'MALAWI',
  id: 'MALAWI',
},
{
  name: 'MALAYSIA',
  id: 'MALAYSIA',
},
{
  name: 'ALAND ISLANDS',
  id: 'ALAND ISLANDS',
},
{
  name: 'MALI',
  id: 'MALI',
},
{
  name: 'MALTA',
  id: 'MALTA',
},
{
  name: 'MARSHALL ISLANDS',
  id: 'MARSHALL ISLANDS',
},
{
  name: 'MARTINIQUE',
  id: 'MARTINIQUE',
},
{
  name: 'MAURITANIA',
  id: 'MAURITANIA',
},
{
  name: 'MAURITIUS',
  id: 'MAURITIUS',
},
{
  name: 'MAYOTTE',
  id: 'MAYOTTE',
},
{
  name: 'MEXICO',
  id: 'MEXICO',
},
{
  name: 'MICRONESIA, FEDERATED STATES OF',
  id: 'MICRONESIA, FEDERATED STATES OF',
},
{
  name: 'MOLDOVA, REPUBLIC OF',
  id: 'MOLDOVA, REPUBLIC OF',
},
{
  name: 'MONACO',
  id: 'MONACO',
},
{
  name: 'MONLIA',
  id: 'MONLIA',
},
{
  name: 'MONTENEGRO',
  id: 'MONTENEGRO',
},
{
  name: 'MONTSERRAT',
  id: 'MONTSERRAT',
},
{
  name: 'MOROCCO',
  id: 'MOROCCO',
},
{
  name: 'MOZAMBIQUE',
  id: 'MOZAMBIQUE',
},
{
  name: 'MYANMAR',
  id: 'MYANMAR',
},
{
  name: 'NAMIBIA',
  id: 'NAMIBIA',
},
{
  name: 'NAURU',
  id: 'NAURU',
},
{
  name: 'NEPAL',
  id: 'NEPAL',
},
{
  name: 'NETHERLANDS',
  id: 'NETHERLANDS',
},
{
  name: 'NEW CALEDONIA',
  id: 'NEW CALEDONIA',
},
{
  name: 'NEW ZEALAND',
  id: 'NEW ZEALAND',
},
{
  name: 'NICARAGUA',
  id: 'NICARAGUA',
},
{
  name: 'NIGER',
  id: 'NIGER',
},
{
  name: 'NIGERIA',
  id: 'NIGERIA',
},
{
  name: 'NIUE',
  id: 'NIUE',
},
{
  name: 'NORFOLK ISLAND',
  id: 'NORFOLK ISLAND',
},
{
  name: 'NORTHERN MARIANA ISLANDS',
  id: 'NORTHERN MARIANA ISLANDS',
},
{
  name: 'NORWAY',
  id: 'NORWAY',
},
{
  name: 'OMAN',
  id: 'OMAN',
},
{
  name: 'PAKISTAN',
  id: 'PAKISTAN',
},
{
  name: 'PALAU',
  id: 'PALAU',
},
{
  name: 'PALESTINIAN TERRITORY, OCCUPIED',
  id: 'PALESTINIAN TERRITORY, OCCUPIED',
},
{
  name: 'PANAMA',
  id: 'PANAMA',
},
{
  name: 'PAPUA NEW GUINEA',
  id: 'PAPUA NEW GUINEA',
},
{
  name: 'PARAGUAY',
  id: 'PARAGUAY',
},
{
  name: 'PERU',
  id: 'PERU',
},
{
  name: 'PHILIPPINES',
  id: 'PHILIPPINES',
},
{
  name: 'PITCAIRN',
  id: 'PITCAIRN',
},
{
  name: 'POLAND',
  id: 'POLAND',
},
{
  name: 'PORTUGAL',
  id: 'PORTUGAL',
},
{
  name: 'PUERTO RICO',
  id: 'PUERTO RICO',
},
{
  name: 'QATAR',
  id: 'QATAR',
},
{
  name: 'REUNION',
  id: 'REUNION',
},
{
  name: 'ROMANIA',
  id: 'ROMANIA',
},
{
  name: 'ALBANIA',
  id: 'ALBANIA',
},
{
  name: 'RWANDA',
  id: 'RWANDA',
},
{
  name: 'SAINT BARTHELEMY',
  id: 'SAINT BARTHELEMY',
},
{
  name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
  id: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
},
{
  name: 'SAINT KITTS AND NEVIS',
  id: 'SAINT KITTS AND NEVIS',
},
{
  name: 'SAINT LUCIA',
  id: 'SAINT LUCIA',
},
{
  name: 'SAINT MARTIN (FRENCH PART)',
  id: 'SAINT MARTIN (FRENCH PART)',
},
{
  name: 'SAINT PIERRE AND MIQUELON',
  id: 'SAINT PIERRE AND MIQUELON',
},
{
  name: 'SAINT VINCENT AND THE GRENADINES',
  id: 'SAINT VINCENT AND THE GRENADINES',
},
{
  name: 'SAMOA',
  id: 'SAMOA',
},
{
  name: 'SAN MARINO',
  id: 'SAN MARINO',
},
{
  name: 'SAO TOME AND PRINCIPE',
  id: 'SAO TOME AND PRINCIPE',
},
{
  name: 'SAUDI ARABIA',
  id: 'SAUDI ARABIA',
},
{
  name: 'SENEGAL',
  id: 'SENEGAL',
},
{
  name: 'SERBIA',
  id: 'SERBIA',
},
{
  name: 'SEYCHELLES',
  id: 'SEYCHELLES',
},
{
  name: 'SIERRA LEONE',
  id: 'SIERRA LEONE',
},
{
  name: 'SINGAPORE',
  id: 'SINGAPORE',
},
{
  name: 'SINT MAARTEN (DUTCH PART)',
  id: 'SINT MAARTEN (DUTCH PART)',
},
{
  name: 'SLOVAKIA',
  id: 'SLOVAKIA',
},
{
  name: 'SLOVENIA',
  id: 'SLOVENIA',
},
{
  name: 'SOLOMON ISLANDS',
  id: 'SOLOMON ISLANDS',
},
{
  name: 'SOMALIA',
  id: 'SOMALIA',
},
{
  name: 'SOUTH AFRICA',
  id: 'SOUTH AFRICA',
},
{
  name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
  id: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
},
{
  name: 'SPAIN',
  id: 'SPAIN',
},
{
  name: 'SRI LANKA',
  id: 'SRI LANKA',
},
{
  name: 'SUDAN',
  id: 'SUDAN',
},
{
  name: 'SURINAME',
  id: 'SURINAME',
},
{
  name: 'SVALBARD AND JAN MAYEN',
  id: 'SVALBARD AND JAN MAYEN',
},
{
  name: 'SWAZILAND',
  id: 'SWAZILAND',
},
{
  name: 'SWEDEN',
  id: 'SWEDEN',
},
{
  name: 'SYRIAN ARAB REPUBLIC',
  id: 'SYRIAN ARAB REPUBLIC',
},
{
  name: 'TAIWAN, PROVINCE OF CHINA',
  id: 'TAIWAN, PROVINCE OF CHINA',
},
{
  name: 'TAJIKISTAN',
  id: 'TAJIKISTAN',
},
{
  name: 'TANZANIA, UNITED REPUBLIC OF',
  id: 'TANZANIA, UNITED REPUBLIC OF',
},
{
  name: 'THAILAND',
  id: 'THAILAND',
},
{
  name: 'TIMOR-LESTE',
  id: 'TIMOR-LESTE',
},
{
  name: 'TO',
  id: 'TO',
},
{
  name: 'TOKELAU',
  id: 'TOKELAU',
},
{
  name: 'TONGA',
  id: 'TONGA',
},
{
  name: 'TRINIDAD AND TOBA',
  id: 'TRINIDAD AND TOBA',
},
{
  name: 'TUNISIA',
  id: 'TUNISIA',
},
{
  name: 'TURKEY',
  id: 'TURKEY',
},
{
  name: 'TURKMENISTAN',
  id: 'TURKMENISTAN',
},
{
  name: 'TURKS AND CAICOS ISLANDS',
  id: 'TURKS AND CAICOS ISLANDS',
},
{
  name: 'TUVALU',
  id: 'TUVALU',
},
{
  name: 'UGANDA',
  id: 'UGANDA',
},
{
  name: 'UKRAINE',
  id: 'UKRAINE',
},
{
  name: 'ALGERIA',
  id: 'ALGERIA',
},
{
  name: 'AFGHANISTAN',
  id: 'AFGHANISTAN',
},
{
  name: 'UNITED STATES',
  id: 'UNITED STATES',
},
{
  name: 'UNITED STATES MINOR OUTLYING ISLANDS',
  id: 'UNITED STATES MINOR OUTLYING ISLANDS',
},
{
  name: 'URUGUAY',
  id: 'URUGUAY',
},
{
  name: 'UZBEKISTAN',
  id: 'UZBEKISTAN',
},
{
  name: 'VANUATU',
  id: 'VANUATU',
},
{
  name: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
  id: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
},
{
  name: 'VIET NAM',
  id: 'VIET NAM',
},
{
  name: 'VIRGIN ISLANDS (BRITISH)',
  id: 'VIRGIN ISLANDS (BRITISH)',
},
{
  name: 'VIRGIN ISLANDS (U.S.)',
  id: 'VIRGIN ISLANDS (U.S.)',
},
{
  name: 'WALLIS AND FUTUNA',
  id: 'WALLIS AND FUTUNA',
},
{
  name: 'WESTERN SAHARA',
  id: 'WESTERN SAHARA',
},
{
  name: 'YEMEN',
  id: 'YEMEN',
},
{
  name: 'YUSLAVIA',
  id: 'YUSLAVIA',
},
{
  name: 'ZAMBIA',
  id: 'ZAMBIA',
}, {

  id: 'ZIMBABWE',
  name: 'ZIMBABWE' },
];
