import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import TableGrid from '../tableGrid/TableGrid';
import ActionsList from 'components/actionsList/ActionsList';
import { LoadingWrap } from 'style/default';

import { FilterButton, Text } from '../elements';
import CalendarButton from './CalendarButton';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../helpers/useWindowDimensions';

const List = (props) => {
  const [t] = useTranslation();
  const {
    leftGridData: data,
    name,
    FilterComponent,
    //changeFilter,
    isOpenFilter,
    setOpenFilter,
    calendarDate,
    setCalendarDate,
    mainsection,
    height,
    contex,
    position,
    linkId,
    rightGridLoading,
    itemWidth,
    actions,
    subSectionInfo,
  } = props;
  const [check, setCheck] = useState(0);
  const [width, setWidth] = useState(0);
  const [recordCounter, setRecordCounter] = useState(0);
  useWindowDimensions();

  const transformFilterText = (str, val = false) => {
    if(str === 'IsAktiviert' && val === '1') return t('offer.filter.activeCustomer');
    if(str === 'IsAktiviert' && val === '0') return t('common.filter.inactiveCustomers');
    switch (str) {
      case 'dailyCustomer':
        return t('common.filter.dailyCustomers');
      case 'customerWithEmptyOrders':
        return t('common.filter.customerWithEmptyOrders');
      case 'acceptedOffers':
        return t('common.filter.acceptedOffers');
      case 'inactiveCustomer':
        return t('common.filter.inactiveCustomers');
      case 'activeCustomer':
        return t('offer.filter.activeCustomer');
      case 'customer':
        return t('customer.customer');
      case 'date':
        return t('common.date');
      case 'dateRange':
        return t('common.filter.dateRange');
      case 'currentMonth':
        return t('common.filter.currentMonth');
      case 'lastMonth':
        return t('common.filter.lastMonth');
      case 'invoiceDateRange':
        return t('common.filter.invoiceDateRange');
      case 'invoiceNumbersRange':
        return t('common.filter.invoiceNumberRange');
      case 'journalNumbersRange':
        return t('common.filter.journalNumbersRange');
      case 'deliveryNotesRange':
        return t('common.filter.deliveryNotesRange');
      case 'customerGroup':
        return t('order.customerGroup');
      case 'customerList':
        return t('common.filter.customerList');
      case 'oneTimeCustomers':
        return t('common.filter.oneTimeCustomers');
      case 'additionalOffer':
        return t('offer.filter.additionalOffer');
      case 'declinedOffers':
        return t('offer.filter.declinedOffers');
      case 'openOffers':
        return t('offer.filter.openOffers');
      case 'openInvoices':
        return t('common.filter.openInvoicesTillDate');
      default:
        return '';
    }
  };

  const checkOnMainSection = (section, subInf) => {
    if(section === 'invoice' && (subInf.name === 'invoiceCreated')) return false;
    switch (section) {
      case 'offer':
        return true;
        // Invoice bug with filter typename (temporary)
      case 'invoice':
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    setWidth(document.getElementById('main_list') && document.getElementById('main_list').offsetWidth);
  }, [document.getElementById('main_list')]);

  useEffect(() => {
    if(!contex.params?.search && data) {
      setRecordCounter(data.length);
    }
  }, [data]);

  useEffect(() => {
    setCheck(0);
  }, [linkId]);

  const cutHeight = props.cutHeight || 340;
  const clientHeight = height || document.documentElement.clientHeight - cutHeight;

  const offerOpenFilter = contex?.filter?.some((f) => ['openOffers', 'acceptedOffers', 'declinedOffers'].includes(f.column));

  return (
    <ClientList itemWidth={itemWidth}>
      <div id='main_list'>
        <div style={{ position: position || 'fixed', width: width + 'px' }}>
          <MainOptionBlock>
            <div>
              {calendarDate && !isOpenFilter && !offerOpenFilter ?
                <CalendarButton
                  setCheck={() => setCheck(Math.random())}
                  calendarDate={calendarDate}
                  setCalendarDate={setCalendarDate}
                />
                :
                <Text
                  text={name}
                  marginBottom='4px'
                  fontSize='20px'
                  color='#3c445a'
                />
              }
            </div>
            <AdditionOptionBlock>{actions && !isOpenFilter ?
              <ActionsListBlock><ActionsList
                t={t}
                actions={actions}
              />
              </ActionsListBlock>
              : null}
            </AdditionOptionBlock>
          </MainOptionBlock>
          <FilterInfoBlock>
            {FilterComponent ? (
              <FilterButtonBlock>
                <FilterButton text={t('common.filter.filter')} onClick={() => setOpenFilter && setOpenFilter(!isOpenFilter)} />
              </FilterButtonBlock>
            ) : null}
            <StyledSpan>
              {checkOnMainSection(mainsection, subSectionInfo) ?
                transformFilterText(contex?.filter?.[0]?.column, contex?.filter?.[0]?.value)
                : transformFilterText(contex?.filter?.[0]?.value)

              } ({recordCounter})
            </StyledSpan>
          </FilterInfoBlock>
          {!isOpenFilter ? (
            <div>
              <ShadowBlock>
                <LoadingWrap loading={check > 0 ? rightGridLoading : false}>
                  <TableGrid
                    {...props}
                    // rowActiveColor={'#99d5d7'}
                    minHeight={props.minHeight ? props.minHeight : clientHeight}
                    height={clientHeight}
                    role='mainList'
                    disableHeightChange
                    zechem={'da'}
                  />
                </LoadingWrap>
              </ShadowBlock>
            </div>
          ) : (
            <FilterBlock isOpenFilter={isOpenFilter} height={clientHeight - 10}>{FilterComponent} </FilterBlock>
          )}
        </div>
      </div>
    </ClientList>
  );
};

export default List;

const ShadowBlock = styled.div`
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  margin-top: 6px;
`;

const FilterInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSpan = styled.span`
  font-weight: 300;
  font-style: italic;
`;

const FilterBlock = styled.div`
  height: ${(props) => props.height + 90}px;
  box-shadow: 0 1px 0 0 rgba(76, 76, 76, 0.08);
  background-color: #eeeeee;
  padding: 15px;
  margin-top: 10px;
  display: ${(props) => props.isOpenFilter ? 'block' : 'none'};
  z-index: 2;
`;

const FilterButtonBlock = styled.div`
  width: 60px;
`;

const MainOptionBlock = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
`;

const ClientList = styled.div`
width: ${({ itemWidth }) => itemWidth || '20.1388889%'};
min-height: 1px;
display: block;
float: left;
`;

const AdditionOptionBlock = styled.div`
  display: flex;
`;

const ActionsListBlock = styled.div`
  margin-right: 10px;
`;
