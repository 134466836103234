import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';

import { Dropdown, Button, Input, Transition, Text } from 'components/elements'; // eslint-disable-line
import TableWithProductOnDate from 'components/tableWithProductOnDate/';
import Formik from 'components/formik/Formik';
//import TableDefOrder from 'main/customer/defaultOrder/tabs/tableDefOrder';

import addShippingCost from 'style/order/addShippingCost.svg';
//import chooseDefault from 'style/order/chooseDefault.svg';
import noOrder from 'style/order/noOrder.svg';
import { typeOrder } from '../params';
// import { readQuery } from 'helpers/queryOperations';
// import { listFA_CustomersListForCreatingOrdersOnDate } from 'main/order/qql/query';

const Main = (props) => {
  const {
    data,
    client,
    query,
    variables } = props.productData;
  const {
    t,
    onDeliveryClick,
    linkId,
    contex,
    localData,
    refetchLoading,
    initialOrderInfo,
    setOrderInfo,
    chooseCustomerInfo } = props;

  const isEmptyOrderSection = useMemo(() => {
    const currentCustomer = localData?.find(({ id }) => linkId === id);
    return contex?.filter[0]?.value === 'customerWithEmptyOrders' || (currentCustomer && currentCustomer.isEmptyOrder);
  }, [linkId, JSON.stringify(contex), localData]); // dailyCustomer / customerWithEmptyOrders

  const sortedTypeOrder = useMemo(() => typeOrder(t).filter((a) => a.sortId > -1).sort((a, b) => a.sortId - b.sortId), []) // eslint-disable-line

  return (
    <Formik initialValues={initialOrderInfo} enableReinitialize={true} customHandleChange={setOrderInfo} customState={initialOrderInfo}>
      {() => {
        return (
          <MainBlock>
            <TopElements>
              <Row>
                <Col lg={4}>
                  <Dropdown
                    data={sortedTypeOrder}
                    title={t('order.orderType')}
                    defaultValueField={props.orderType}
                    sortOrder={'none'}
                    onChange={({ value }) => {
                      props.setOrderType(value);
                    }} />
                </Col>
                <Col lg={4}>
                  <Input title={t('order.orderRef')} field='orderRef' value={initialOrderInfo.orderRef} />
                </Col>
                <Col lg={2}>
                  <Input title={t('common.deliveryTime')} field='deliveryTime' value={initialOrderInfo.deliveryTime} small />
                </Col>
                <Col lg={5}>
                  <ButtonBlock>
                    <Button value={t('order.orderCreate')} upperCase className='green-btn' onClick={() => props.createNewOrder(initialOrderInfo)} />
                  </ButtonBlock>
                </Col>
              </Row>
            </TopElements>

            <TableActions>
              <div> </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ActionBlock
                  background='#c13c44'
                  onClick={props.noOrderOperation}
                  style={{
                    order: 2,
                    display: 'flex',
                    pointerEvents: !localData.length || refetchLoading ? 'none' : 'auto',
                    opacity: !localData.length || refetchLoading ? '.3' : '1',
                  }}>
                  {isEmptyOrderSection ? <i className='fas fa-undo-alt fa-2x' style={{ color: '#ffffff' }} /> : <Image alt='' src={noOrder} />}
                </ActionBlock>
                <ActionBlock background={props.isDelivery && '#fff3c3'} onClick={onDeliveryClick}>
                  <Image alt='' src={addShippingCost} />
                </ActionBlock>
              </div>
            </TableActions>

            <PositionsBlock>
              <TableWithProductOnDate
                {...props}
                data={data.productTable || []}
                client={client}
                t={t}
                queryInfo={{
                  query: query,
                  variables: variables,
                }}
                mutable={chooseCustomerInfo?.offerId}
                queryValue='getFA_GetDefaultOrderForCustomerOnDate'
                localSortKey='orderPositionID'
              />
            </PositionsBlock>
            {/* <DefaultOrderBlock><Transition text='Default order' isWhite> <TableDefOrder {...props} values={{ deliverySplittingParts: 1 }} orderGraphQLParams={props.productData} orderDate={date} /> </Transition></DefaultOrderBlock> */}
            {/* <Transition text={t('common.note')} />*/}
          </MainBlock>
        );
      }}
    </Formik>
  );
};

export default Main;

const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 90px;
`;

const TopElements = styled.div`

`;

const ButtonBlock = styled.div`
  margin: 39px 0 0 20px;
`;

const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -22px;
`;
const ActionBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: ${({ background }) => background || '#ffffff'};
  display: inline-block;
  margin: 0 0 5px 10px;
  cursor: pointer;
`;
const PositionsBlock = styled.div`

`;

const Image = styled.img`
  /* margin: 1px 0px 1px ${({ marginRight }) => marginRight}px; */
`;

// const DefaultOrderBlock = styled.div`
//   padding-top: 10px;
//   background: #ffffff;
// `;
