import React from 'react';
import { RequireNewPassword } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';

import AuthContainer, { LinkButton } from './AuthContainer';
import LoginInput from './elements/LoginInput.js';
import { Button } from 'components/elements';

class MyRequireNewPassword extends RequireNewPassword {
  state = {
    username: '',
    isSubmitting: false,
    err: { errField: '', errMessage: '' },
    isSendCode: false,
    code: '',
    password: '',
  }

  goBack = () => {
    this.changeState('signIn');
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  change = async (e) => {
    e.preventDefault();
    const user = this.props.authData;
    const { password } = this.state;
    this.setState({ isSubmitting: true });
    try {
      const completedUser = await Auth.completeNewPassword(user, password);
      if (completedUser.challengeName === 'SMS_MFA' || completedUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
        this.changeState('confirmSignIn', completedUser);
      } else if (user.challengeName === 'MFA_SETUP') {
        this.changeState('TOTPSetup', completedUser);
      } else {
        this.checkContact(completedUser);
      }
    } catch(err) {
      if(err.message === 'callback.totpRequired is not a function') {
        this.goBack(); // workaround https://github.com/aws-amplify/amplify-js/issues/4321
      }
      this.setState({ err: { errField: 'password', errMessage: 'Unknown' } });
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  showComponent() {
    const { err } = this.state;
    return(
      <AuthContainer onSubmit={this.change}>
        <span style={{ color: '#434343' }}>
          New pass required
        </span>
        <LoginInput
          type='password'
          placeholder='Your New Password'
          onChange={this.onChange}
          name='password'
          err={err}
        />
        <Button style={{ marginTop: 5 }} value={'Set new password'} onClick={this.change} type='submit' width='300px' className={'red-btn'} height='46px' />
        <LinkButton onClick={this.goBack}> Go back</LinkButton>
        
      </AuthContainer>
    );
  }
}

export default withTranslation()(MyRequireNewPassword);
