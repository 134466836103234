import gql from 'graphql-tag';

export default gql`
query listFA_InvoiceIntervals{
    fakturaAssist{
    	listFA_InvoiceIntervals{
    		id
    		name
 		}
 	}
}`;
