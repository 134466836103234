import gql from 'graphql-tag';

export const CONFIG = gql`
query getFA_Config {
    fakturaAssist {
    	getFA_Config{
            id
            languageID
        }
    }
}`;

export const UPDATE_CONFIG = gql`
mutation updateFA_Config($fields: FA_ConfigFields!) {
    fakturaAssist {
        updateFA_Config(fields: $fields) {
            id
            languageID
        }
    }
}
`;
