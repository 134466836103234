import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NewDebts from './debts/Debts.js';
import Payment from './table/Payment.js';
import ImportISO from './table/ImportISO.js';

export default (childProps) => {
  return (
    <Switch>
      <Route path='/debitor/open' render={(props) =>
        <NewDebts {...childProps} {...props} />
      }/>
      <Route path='/debitor/payments' render={(props) =>
        <Payment {...childProps} {...props} />
      }/>
      <Route path='/debitor/import' render={(props) =>
        <ImportISO {...childProps} {...props} />
      }/>
    </Switch>);
};
