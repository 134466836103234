import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';
import { useMutation } from 'components/graphql';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';

import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid.js';

import { tableData } from './params';

import Modal from './Modal.js';

import listFA_DiscountDropdown from './qql/listFA_DiscountDropdown';
import listFA_Discount from './qql/listFA_Discount';
import listFA_ProductGroup1 from './qql/listFA_ProductGroup1';

import createFA_Discount from './qql/mutation/createFA_Discount';
import deleteFA_Discount from './qql/mutation/deleteFA_Discount';
import updateFA_Customer from '../../../qql/updateFA_Customer.js';

import { Input, Dropdown } from 'components/elements';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const [MUTATION_update] = useMutation(updateFA_Customer);
  const [id, setId] = useState(0);
  const [index, setIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [nameDiscount, setNameDiscount] = useState([]);
  const { data: discountGroupsData } = useQuery(listFA_ProductGroup1, {
    fetchPolicy: 'network-only',
  });

  const discountGroupsNames = get(discountGroupsData, 'fakturaAssist.listFA_ProductGroup1[0].productGroup', []);

  const Component = (props)=>{
    const { handleChange, values } = props;
    return(
      <Col width='380px'>
        <Input
          width='100%'
          withoutFormik={true}
          type='text'
          onChange={handleChange}
          value={values.name}
          name={'name'}
          title={'Name'}
        />
      </Col>
    );
  };

  const editItem = (i, data) =>{
    setId(+data[i].id);
    setIndex(i);
    setNameDiscount(data[i].name);
    setOpen(true);
  };
  return (
    <Formik initialValues={props.data} discountGroupsNames onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        const discountGroupsRenderer = discountGroupsNames && discountGroupsNames.map((item, index) => {
          if (item) {
            return (
            //  <TitleInput>
              <Input small tabIndex={index + 1}
                type='number'
                floatFormat={'ffFixed'}
                title={item + ', %'}
                field={`discountInPercent${index + 1}`}
                value={values[`discountInPercent${index + 1}`]}
                max='100'
              />
            //  </TitleInput>
            );
          } else {
            return (
              <NoTitleInput>
                <Input small tabIndex={index + 1}
                  type='number'
                  floatFormat={'ffFixed'}
                  field={`discountInPercent${index + 1}`}
                  value={values[`discountInPercent${index + 1}`]}
                  max='100'
                />
              </NoTitleInput>
            );
          }
        });

        const changeSelectId = (item) => {
          setFieldValue('linkQuantitiesDiscount', item.id);
          MUTATION_update({
            variables: {
              id: values.linkId,
              fields: {
                id: +values.linkId,
                linkQuantitiesDiscount: +item.id,
              },
            },
          });
        };

        return (
          <div>
            {index !== null && <Modal open = {open} setOpen = {setOpen} setNameDiscount = {setNameDiscount} nameDiscount = {nameDiscount} index = {index} id={id}/>}
            <HeadBlock>
              <Col lg={4}>
                <Dropdown
                  graphqlParams={{
                    query: listFA_DiscountDropdown,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                  title={t('customer.quantityDiscountOnOrderingAmount')}
                  valueField = 'index'
                  labelField = 'name'
                  withIndex
                  field = 'discountCode'
                  defaultValueField = {values.discountCode}
                />
              </Col>
            </HeadBlock>
            <Row>
              {values.discountCode === 1 &&
                <Row />
              }
              {values.discountCode === 2 &&
                <Row>
                  <Col lg={2}>
                    <Input small type='number' value={values.discountInPercent1} field='discountInPercent1' title={t('common.discount') + ', %'}/>
                  </Col>
                </Row>
              }
              {(values.discountCode === 3 || values.discountCode === 4) &&
                <div>
                  <Row>
                    <Col width='200px'>
                      {discountGroupsRenderer[0]}
                      {discountGroupsRenderer[1]}
                      {discountGroupsRenderer[2]}
                      {discountGroupsRenderer[3]}
                      {discountGroupsRenderer[4]}
                    </Col>
                    <Col width='200px'>
                      {discountGroupsRenderer[5]}
                      {discountGroupsRenderer[6]}
                      {discountGroupsRenderer[7]}
                      {discountGroupsRenderer[8]}
                      {discountGroupsRenderer[9]}
                    </Col>
                    <Col width='200px'>
                      {discountGroupsRenderer[10]}
                      {discountGroupsRenderer[11]}
                      {discountGroupsRenderer[12]}
                      {discountGroupsRenderer[13]}
                      {discountGroupsRenderer[14]}
                    </Col>
                    <Col width='200px'>
                      {discountGroupsRenderer[15]}
                      {discountGroupsRenderer[16]}
                      {discountGroupsRenderer[17]}
                      {discountGroupsRenderer[18]}
                      {discountGroupsRenderer[19]}
                    </Col>
                  </Row>
                </div>

              }
              {values.discountCode === 5 &&
                 <Col width='600px'>
                   <TableGrid
                     graphqlParams={{ query: listFA_Discount }}
                     //searchValue={this.state.searchValue}
                     //onSearch={this.onSearch}
                     //getSortField={this.getSortField}
                     Component={Component}
                     height={700}
                     name={t('customer.quantityDiscount')}
                     headerText={t('customer.editQuantityDiscount')}
                     modalWidth={440}
                     modalHeight={640}
                     editItem={editItem}
                     tableData={tableData(t)}
                     //getTableIndex={this.indexOnClickTable}
                     changeSelectId={changeSelectId}
                     itemDeleted={deleteFA_Discount}
                     linkId={values.linkQuantitiesDiscount}
                     selectById
                     withModalMutations
                     typeName={'FA_Discount'}
                     qqlMutationCreate={createFA_Discount}
                     dataPolicy='local'
                     initialSortValue='name'
                   />
                 </Col>

              }
              {values.discountCode === 6 &&
                <Col width='600px'>
                  <TableGrid
                    graphqlParams={{ query: listFA_Discount }}
                    //searchValue={this.state.searchValue}
                    //onSearch={this.onSearch}
                    //getSortField={this.getSortField}
                    Component={Component}
                    height={500}
                    name={t('customer.quantityDiscount')}
                    headerText={t('customer.editQuantityDiscount')}
                    modalWidth={440}
                    modalHeight={205}
                    editItem={editItem}
                    tableData={tableData(t)}
                    //getTableIndex={this.indexOnClickTable}
                    changeSelectId={changeSelectId}
                    itemDeleted={deleteFA_Discount}
                    linkId={values.linkQuantitiesDiscount}
                    selectById
                    withModalMutations
                    typeName={'FA_Discount'}
                    qqlMutationCreate={createFA_Discount}
                    dataPolicy='local'
                    initialSortValue='name'
                  />
                </Col>
              }
            </Row>
          </div>);
      }}
    </Formik>
  );
};

const HeadBlock = styled(Row)`
  padding: 0px 0px 20px 0px;
`;
const NoTitleInput = styled.div`
    padding-top: 39px;
`;
