
import { Dropdown } from 'components/elements';
import React, { useState } from 'react';
import { cloneDeep, debounce, find, includes } from 'lodash';

export default (props) => {
  // const { values, index, columnIndex, id, setId, debouncedInput, data, setFieldValue } = props;
  const { values, index, columnIndex, data, setFieldValue, concatFields, selectedLabel, sortKey } = props;
  const [open, setOpen] = useState(false);
  const customStyles = (row, width) => ({

    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      margin: '-9px 0 0 0px',
      border: 'none',
      boxShadow: '0 1px 2px 0 rgba(90,90,90,0.12)',
      height: '36px',
      background: row % 2 ? '#eeeeee' : '#fff',

    }),
    option: (provided, state) => ({
      ...provided,
      position: 'fixed',
      zIndex: 4,
      // background: state.isFocused ? '#f5f5f5' : '#ffffff',
      background: state.isSelected ? '#f5f5f5' : '#ffffff',
      color: '#3c445a',
      fontWeight: state.isSelected ? 600 : 'initial',
      borderRadius: '3px',
      cursor: 'pointer',
      boxShadow: '0 1px 2px 0 rgba(90, 90, 90, 0.12)',
      borderBottom: '1px solid #f4f4f4',
      width: '100%',
    }),
    menuList: (provided, state) => ({
      ...provided,
      position: 'fixed',
      zIndex: 3,
      height: '200px',
      width: ' 16%',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      zIndex: 10,
      background: '#eeeeee',
      position: 'fixed',
      height: '40px',
      width: '100%',
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      background: '#eeeeee',
      position: 'fixed',
      height: '40px',
      width: ' 16%',
    }),
  });
  const onFocusDropDown = (id) => {
    document.getElementById(id) && document.getElementById(id).className && document.getElementById(id).firstChild.firstChild.childNodes[1].firstChild && document.getElementById(id).firstChild.firstChild.childNodes[1].firstChild.firstChild.focus();
  };
  const debouncedDropDown = debounce(onFocusDropDown, 3);

  const onKeyDownDrop = (e) => {
    if (e.shiftKey && e.keyCode === 9) {
      document.getElementById(`${index - 1}-${9}-${values[index].id}`) && document.getElementById(`${index - 1}-${9}-${values[index].id}`).focus();
    } else if (e.keyCode === 9) {
      document.getElementById(`${index}-${columnIndex + 1}-${values[index].id}`) && document.getElementById(`${index}-${columnIndex + 1}-${values[index].id}`).focus();
    } else if (e.keyCode === 13) {
      document.getElementById(`${index + 1}-${columnIndex}-${values[index + 1].id}`) && document.getElementById(`${index + 1}-${columnIndex}-${values[index + 1].id}`).focus();
    }
  };

  const filteredListProducs = () => {
    const arrID = [];
    const arrProductions = [];
    values.forEach((item, index) => {
      if (values[index]?.productId !== item.productId) {
        arrID.push(item.productId);
      }
    });

    cloneDeep(data).forEach((item, index) => {
      if (concatFields) {
        item.concatField = `${item[concatFields[0]]} - ${item[concatFields[1]]}`;
      }
      if (!includes(arrID, item.id) || props.index === index) {
        arrProductions.push(item);
      }
    });
    return arrProductions;
  };

  const onChange = (name, value) => {
    const item = find(filteredListProducs(), ['id', value]);
    setFieldValue(name, value);
    setFieldValue(`[${index}]description`, item.name);
  };

  return (
    <div
      style={{ height: '36px' }}
      onClick={() => open && setOpen(false)}
    >
      {open ?
        <Dropdown
          customStyles={customStyles}
          key={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          data={filteredListProducs()}
          id={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          valueField='id'
          sortKey={sortKey}
          labelField={concatFields && 'concatField'}
          menuIsOpen={true}
          onBlur={(a) => {
            setOpen(false);
          }}
          width={'480px'}
          withoutFormik={true}
          onKeyDown={(event) => {
            onKeyDownDrop(event);
          }}
          defaultValueField={values[index] && values[index].productId}
          additionalFunc={(_, b) => {
            props.updateCacheAfterChangeProduct(values, b, index);
            setOpen(false);
            debouncedDropDown(`${index}-${columnIndex}-${values[index].id}`);
          }}
          setFieldValue={onChange}
          withVirtualized
          selectedLabel={selectedLabel}
          name={`[${index}]productId`} /> :
        <div

          style={{ height: '36px' }}
          tabIndex='0'
          onFocus={(e) => {
            debouncedDropDown.cancel();
            setOpen(true);
            debouncedDropDown(`${index}-${columnIndex}-${values[index].id}`);
            // values[index].id !== id && setId(values[index].id);
          }}
          id={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          key={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          type={'text'}
        >{values[index] && (selectedLabel !== 'productNumber' ? values[index].description : values[index]?.productNr)}</div>
        // <Input
        //   onFocus={(e)=>{
        //     debouncedInput.cancel();
        //     debouncedDropDown.cancel();
        //     values[index].id !== id && setId(values[index].id);
        //     debouncedDropDown(`${index}-${columnIndex}-${values[index].id}`);
        //   }}
        //   id={values[index] && `${index}-${columnIndex}-${values[index].id}`}
        //   key={values[index] && `${index}-${columnIndex}-${values[index].id}`}
        //   type={'text'}
        //   style={{ marginTop: '-5px' }}
        //   noBorder
        //   withOpacity
        //   width='100%'
        //   withoutFormik={true}
        //   value={values[index] && values[index].description}
        //   name={`[${index}]productId`}/>
      }
    </div>
  );
};


