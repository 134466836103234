import gql from 'graphql-tag';

export default gql`
query listFA_CustomerSammelfakturaAufKunde($params: ListQueryArgs){
    fakturaAssist{
    listFA_CustomerSammelfakturaAufKunde(params: $params){
		id
		concatenatedName
		name
    }

  }
}`;
