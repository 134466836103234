import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = (props) => {
  const { noActive, className, onClick, value, upperCase, errText, style, noActiveFunc, children } = props;
  return (
    <div style={style}>
      <ReactButton
        {...props}
        className={noActive ? 'no-active-btn' : className}
        onClick={noActive ? noActiveFunc : onClick}
      >
        {children ? children : upperCase ? value.toUpperCase() : value }
      </ReactButton>

      <ErrText>
        {errText ? errText : null}
      </ErrText>
    </div>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
  withoutMutation: PropTypes.bool,
};

Button.defaultProps = {
  value: 'button',
};

export default Button;

const ErrText = styled.div`
  color: #c13c44;
  float: left;
  /* font-family: Roboto-Medium; */
  font-weight: 400;
  font-size: 14px;
  /* font-weight: normal; */
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const ReactButton = styled.button`
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : '190px')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: ${(props) => (props.float ? props.float : 'none')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  background-color: #99d5d7;
  border-radius: 4px;
  height: ${({ height }) => height || '36px'};
  color: #fff;
  font-size: 13px;
  border: ${(props) => (props.errText ? '1px solid #c13c44' : 'none')};
    &:not([disabled]):hover {
      font-size: 13px;
      font-weight: 400;
      color: #fff;
      box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.14);
      background-color: #69bcbe;
      -webkit-transition: all 0.3s ease;;
      -moz-transition: all 0.3s ease;;
      -o-transition: all 0.3s ease;;
      transition: all 0.3s ease;
    }

  &.black {
    background-color: #2e3941;
    color: #ffffff;
      &:not([disabled]):hover {
      box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.35);
      background-color: #575d6d;
    }
  }

  &.transparent {
    background-color : transparent;
    color: #3c445a;
    border: 1px solid #2e3941;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.35);
        background-color: #2e3941;
        color: #ffffff;
    }
  }

  &.red-btn {
    background-color : #C60008;
    color: #ffffff;
    border: 1px solid #dd5860;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.35);
        background-color : transparent;
        color: #C60008;
    }
  }
  &.green-btn {
    background-color: #62B55D;
    color: #ffffff;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.35);
        background-color : #74DA6E;
        color: #ffffff;
    }
  }

  &.grey-btn-no-active {
    background-color: #cdcdcd;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.14);
        background-color : #cdcdcd;
        cursor: not-allowed;
        color: #ffffff;
    }
  }
  &.no-active-btn {
    background-color : #575d6d;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.14);
        background-color : #2e3941;
        color: #ffffff;
        cursor: not-allowed;
    }
  }
  &.grey-btn {
    background-color : #cdcdcd;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.14);
        background-color : #2e3941;
        color: #ffffff;
    }
  }
  &.white {
    background-color : #ffffff;
    color: #24313b;
      &:hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.14);
        background-color : #2e3941;
        color: #ffffff;
    }
  }
`;
