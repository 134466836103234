import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query getFA_CustomerDeliveryPrices($args: FA_CustomerDeliveryPricesInputs!){
    fakturaAssist{
    	getFA_CustomerDeliveryPrices(args: $args){
	    	weigthID
			weight
			weightPrice
	 	}
 	}
}`;
