import gql from 'graphql-tag';

export default gql`
mutation deleteFA_TemporaryOrder($id: ID!){
    fakturaAssist{
    	deleteFA_TemporaryOrder(id: $id){
    		id
 		}
 	}
}`;
