import History from './History';
import { getFA_Config } from '../qql/query';
import { updateFA_Config } from '../qql/mutation';

export default () => ({
  link: 'history',
  subsections: [],
  itemQuery: getFA_Config,
  updateMutation: updateFA_Config,
  Component: History,
});

