
import React, { Component } from 'react';
import styled from 'styled-components';
import reportCrash from './reportCrash';
import { IS_LOCALHOST } from 'config/consts';

import { default as Face } from './assets/face.svg';
import { Button } from '../elements';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromProps({ location }, { hasError, locationKey }) {
    if(hasError && location.key !== locationKey) {
      return { hasError: false };
    }
    return null;
  }

  sendReport(variables) {
    const { client } = this.props;
    return client.mutate({
      mutation: reportCrash,
      variables,
    });
  }

  componentDidCatch(error, info) {
    this.location = this.props.location;
    this.setState({ hasError: true, locationKey: this.props.location.key });
    if(!IS_LOCALHOST) {
      const message = error.message + ', Pathname:' + this.props.location.pathname;
      this.sendReport({
        message,
        stacktrace: error.stack,
      });
    }
  }

  render({ children, t } = this.props) {
    return this.state.hasError ?
      <ErrorHandlerWrapper>
        <div>
          <img src={Face} />
        </div>
        <InfoHolder>
          <ErrorCode>404</ErrorCode>
          <ErrorDescription>
            {t('common.pageNotFound') + ' :('}
          </ErrorDescription>
          <StyledButton value='Back' upperCase onClick={() => window.history.back()}/>
        </InfoHolder>
      </ErrorHandlerWrapper> :
      children;
  }
}

const ErrorHandlerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
`;

const InfoHolder = styled.div`
  margin-left: 55px;
`;

const ErrorCode = styled.div`
  font-size: 150px;
  color: #cdcdcd;
`;

const ErrorDescription = styled.div`
  margin-top: -25px;
  font-size: 24px;
  color: #3c445a;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: solid 1px #3c4e5a;
  margin-top: 40px;
  color: #3c4e5a;

  &:hover {
    background-color: #3c4e5a;
    color: #fff;
  }
`;

export default ErrorHandler;
