import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import { readQuery, writeQuery } from 'helpers/queryOperations';
import { get, cloneDeep } from 'lodash';

import { Row, Col, Table } from 'style';
import Formik from 'components/formik';
import getProduct from '../gql/getArticles';
import { Input, Checkbox, Transition, Modal, Dropdown, Button } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';

import {
  listFA_ProductProduction,
  getFA_ProductProductionCalc,
  createFA_ProductProductionCalc,
  updateFA_ProductProductionCalc,
  deleteFA_ProductProductionCalc,
} from './gql/calculation';

import {
  listFA_ProductProductionGeneralRecipe,
  listFA_ProductProductionDough,
} from './gql/general';

import { listFA_ProductBreak } from './gql/break';
import { useTranslation } from 'react-i18next';
import { ModalBody } from 'react-bootstrap';

const params = (t) => [
  {
    name: t('common.articleNo'),
    dataBase: 'articleNm',
    relativeWidth: 0.25,
  },
  {
    name: t('common.description'),
    dataBase: 'description',
    relativeWidth: 0.25,
  },
  {
    name: t('product.multiplierOnAmount'),
    dataBase: 'multiplier',
    relativeWidth: 0.25,
  },
  {
    name: t('production.fermentation'),
    dataBase: 'isFermentationInterrupt',
    specialComponent: (index, _, __, data) => {
      return (
        <Checkbox margin={'0px'} value={data[index].isFermentationInterrupt} handleClick={() => { }} />
      );
    },
    relativeWidth: 0.25,
  },
];

export default (props) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState();
  const [dataRow, changeDataRow] = useState();
  const [createCalc, { loading: creationLoading }] = useMutation(createFA_ProductProductionCalc);
  const [updateCalc, { loading: updateLoading }] = useMutation(updateFA_ProductProductionCalc);
  const [deleteCalc, { loading: deletionLoading }] = useMutation(deleteFA_ProductProductionCalc);
  const [activeBreak, setActiveBreak] = useState();
  const { data, loading } = useQuery(listFA_ProductProduction, {
    variables: {
      id: +props.data.id,
    },
    skip: !props.data.id,
    fetchPolicy: 'cache-and-network',
  });
  const { data: generalData } = useQuery(listFA_ProductBreak);
  const dataTable = get(data, 'fakturaAssist.listFA_ProductProduction', []);

  const handleDelete = (id, cb = () => { }) => {
    deleteCalc({
      variables: { id }, update: () => {
        try {
          const calcData = readQuery({
            client: props.client, query: listFA_ProductProduction, variables: {
              id: +props.data.id,
            },
          }, true).fakturaAssist.listFA_ProductProduction;
          let cacheData = cloneDeep(calcData);
          cacheData = cacheData.filter((item) => item.id !== id);
          writeQuery({
            client: props.client, query: listFA_ProductProduction, variables: {
              id: +props.data.id,
            }, changedData: cacheData,
          });
        } catch (e) {
          console.log(e);
        } finally {
          cb(false);
        }
      },
    });
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
      sectionInfo={props.sectionInfo}
      linkId={props.linkId}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        const {
          willReplacedWithProductionCalc,
          isBruchTitel,
          bruchTitelAnzahlTeile,
          isBruchRound,
          bruchSummierenAuf,
          bruchVerwendeteTeile,
          isDough,
          isFermentationInterrupt,
          isBakingSheet,
          numberPerSheet,
          numberOfSheetsPerCat,
          doughInsert,
          factorLtKg,
          roundUp,
          isBatterSplitting,
          batterSplitting1,
          batterSplitting2,
          linkRecipe,
        } = values;

        const ConfirmModal = ({ closeModal, onConfirm }) => (
          <ModalBody>
            <p>
              {t('common.confirmDelete')}
            </p>
            <BottonBlock>
              <Col lg={5}>
                <Button value={t('common.no')} style={{ marginRight: 10 }} className='black' upperCase onClick={closeModal} />
              </Col>
              <Col lg={5}>
                <Button value={t('common.ok')} className='grey' upperCase onClick={onConfirm} />
              </Col>
            </BottonBlock>
          </ModalBody>
        );

        const Calc = (id, data) => {
          const [multiplier, isMultiplier] = useState(dataRow !== null ? dataRow.multiplier : null);
          const [article, setArticle] = useState(dataRow ? dataRow.addedArticle : null);
          const [errors, setErrors] = useState(null);

          const handleMutation = () => {
            if (!multiplier || !article) {
              setErrors({ multiplier: !multiplier ? t('common.validation.isRequiredInput') : null, articleToAdd: !article ? t('common.validation.isRequiredInput') : null });
              return;
            }
            setErrors(null);

            if (dataRow) {
              setIsOpen(false);
              const params = { variables: { idCalc: dataRow.id, multiplier: parseFloat(multiplier), addedArticle: article } };
              updateCalc(params);
            } else {
              setIsOpen(false);
              createCalc({
                variables: { id: parseInt(props.data.id), multiplier: parseFloat(multiplier), addedArticle: article },
                update: (cache, result) => {
                  if (!result || !result.data || !result.data.fakturaAssist || !result.data.fakturaAssist.createFA_ProductProductionCalc) return;
                  const data = result.data.fakturaAssist.createFA_ProductProductionCalc;
                  try {
                    const calcData = readQuery({
                      client: props.client, query: listFA_ProductProduction, variables: {
                        id: +props.data.id,
                      },
                    }, true).fakturaAssist.listFA_ProductProduction;
                    let cacheData = cloneDeep(calcData);
                    cacheData = [...cacheData, data];
                    writeQuery({
                      client: props.client, query: listFA_ProductProduction, variables: {
                        id: +props.data.id,
                      }, changedData: cacheData,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                },
              });
            }
          };
          // console.log(multiplier); // value input
          return (
            <Block>
              <Row>
                <Col lg={4}>
                  <Dropdown
                    title={t('product.articleToAdd')}
                    labelField='name'
                    valueField='id'
                    field='articleToAdd'
                    defaultValueField={null}
                    withoutFormik
                    setFieldValue={(_, val) => {
                      if (val) setErrors((prevState) => ({ ...prevState, articleToAdd: null }));
                      setArticle(val);
                    }}
                    getQuery={article && getProduct}
                    singleQueryVariables={{ id: article }}
                    graphqlParams={{ query: getFA_ProductProductionCalc, variables: { id: parseInt(props.data.id) } }}
                    manualErrors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={1}>
                  <Input
                    value={multiplier} name='multiplier'
                    manualErrors={errors}
                    type='number'
                    floatFormat={'ffFloat'}
                    alignRight
                    onChange={({ target: { value } }) => {
                      if (value) setErrors((prevState) => ({ ...prevState, multiplier: null }));
                      return isMultiplier(value !== '' ? value : null);
                    }}
                    field='multiplier'
                    title={t('product.multiplierOnAmount')} />
                </Col>
              </Row>
              <Buttons>
                <Col lg={5}>
                  <Button value={t('common.cancel')} onClick={() => setIsOpen(false)} className={'grey-btn'} />
                </Col>
                <Col lg={5}>
                  <Button value={t('common.save')} onClick={handleMutation} className={'black'} />
                </Col>
              </Buttons>
            </Block>
          );
        };
        //const usedBreakArticle = get(generalData, 'fakturaAssist.listFA_ProductGeneral', []);

        const getStatus = (array, search, set) => {
          const activeData = get(array, 'fakturaAssist.listFA_ProductBreak', []);
          const result = activeData.filter(function (item) {
            return Object.keys(item).some(function (key) {
              if (item[key] === search) return item;
            });
          });
          return set(get(result, '[0].id', ''));
        };
        getStatus(generalData, bruchSummierenAuf, setActiveBreak);

        const [isConfirmModalDeleteProdCalc, setConfirmModalDeleteProdCalc] = useState(false);
        const [deletedCalcId, setDeletedCalcId] = useState(null);

        const handleFractionChange = (e) => {
          e.persist();
          const { value, name } = e.target;
          setFieldValue(name, value !== '' ? parseFloat(value) : null);
        };

        return (
          <div>
            <Transition index={0} text={t('common.general')} isWhite>
              <Row>
                <Col width={'320px'}>
                  <Dropdown
                    title={t('product.recipe')}
                    labelField='concatenatedField'
                    valueField='id'
                    field='linkRecipe'
                    defaultValueField={linkRecipe}
                    graphqlParams={{ query: listFA_ProductProductionGeneralRecipe }}
                  />
                </Col>

                {isDough
                  ?
                  <>
                    <Col lg={2}>
                      <Checkbox value={isBatterSplitting} field='isBatterSplitting' title={t('product.chargeSplitting')} zIndex={'0'} />
                    </Col>
                    <Col width={'160px'}>
                      <Input small value={batterSplitting1} type={'number'} floatFormat={'ffFloat'} field='batterSplitting1' title={t('product.kgProCharge')} />
                    </Col>
                    <Col width={'160px'}>
                      <Input small value={batterSplitting2} type={'number'} floatFormat={'ffFloat'} field='batterSplitting2' title={t('product.kgProCharge') + ' 2'} />
                    </Col>
                  </>
                  : null
                }
              </Row>
              <Row>
                <Col lg={2}>
                  <Checkbox value={isDough} field='isDough' title={t('product.doughArticle')} zIndex={'0'} />
                </Col>
                <Col lg={5}>
                  <Checkbox value={isFermentationInterrupt} field='isFermentationInterrupt' title={t('production.fermentationInterruption')} zIndex={'0'} />
                </Col>
              </Row>
              {isDough
                ?
                <Transition index={5} text={t('product.doughLiter')}>
                  <Row>
                    <Col lg={4}>
                      <Input value={factorLtKg} decimal field='factorLtKg' title={`${t('product.weightOfLiter')} (g)`} />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Input value={roundUp} decimal field='roundUp' title={`${t('product.roundTo')} (lt)`} />
                    </Col>
                    <Col width={'400px'}>
                      <Checkbox value={isBakingSheet} field='isBakingSheet' title={t('product.showLiters')} zIndex={'0'} />
                    </Col>
                  </Row>
                </Transition>
                :
                <>
                  <Transition index={1} text={t('product.doughConnection')}>
                    <Row>
                      <Col lg={4}>
                        <Dropdown
                          title={t('common.dough')}
                          labelField='concatenatedField'
                          valueField='id'
                          field='linkDough'
                          defaultValueField={values.linkDough}
                          graphqlParams={{ query: listFA_ProductProductionDough }}
                        />
                      </Col>
                      <Col lg={4}>
                        <Input value={doughInsert} type='number' floatFormat={'ffFloat'} field='doughInsert' title={`${t('product.doughWeightBeforeBaking')} (g)`} />
                      </Col>
                    </Row>
                  </Transition>

                  <Transition index={2} text={t('product.bakingSheet')}>
                    <Row>
                      <Col lg={4}>
                        <Input value={numberPerSheet} type='number' floatFormat={'ffNumber'} field='numberPerSheet' title={t('product.numberPerBakingSheet')} />
                      </Col>
                      <Col lg={4}>
                        <Input value={numberOfSheetsPerCat} type='number' floatFormat={'ffNumber'} field='numberOfSheetsPerCat' title={t('product.numberOfSheetsPerMachine')} />
                      </Col>
                    </Row>
                  </Transition>
                </>
              }
              <Row>
                <Col lg={12} width={'500px'}>
                  <Checkbox value={isBakingSheet} field='isBakingSheet' title={t('product.showArticleOnProductionPrintLists')} zIndex={'0'} />
                </Col>
              </Row>
            </Transition>

            <Transition index={3} text={t('product.calculationForProduction')} isWhite>
              {/* <Row>
                <Col lg={4}>
                  <Input value={'300.488 TK Totenblein Klein'} field='recipe' title='Article'/>
                </Col>
              </Row> */}
              <Row>
                <Col lg={4}>
                  <Checkbox field='willReplacedWithProductionCalc' value={willReplacedWithProductionCalc} title={t('product.articleWillBeReplacedWithalculation')} margin={'10px 0'} zIndex={'0'} />
                </Col>
              </Row>
              <Modal
                headerText={t('product.calculationForProduction')}
                width={'450px'}
                height={'295px'}
                padding={'-10px 0 0 20px'}
                isOpen={isOpen}
                Component={<Calc id={props.data.id} data={dataTable} />}
              />
              <Modal
                isOpen={isConfirmModalDeleteProdCalc}
                closeModal={() => setConfirmModalDeleteProdCalc(false)}
                headerText={t('common.confirm')}
                width='450px'
                height='220px'
                Component={
                  <Block>
                    <ConfirmModal
                      closeModal={() => setConfirmModalDeleteProdCalc(false)}
                      onConfirm={() => deletedCalcId && handleDelete(deletedCalcId, setConfirmModalDeleteProdCalc)}
                    />
                  </Block>
                }
              />
              <Table>
                <TableGrid
                  height={100}
                  tableData={params(t)}
                  data={dataTable}
                  loading={loading || updateLoading || creationLoading || deletionLoading}
                  getTableIndex={((first, second) => {
                    console.log(first);
                    console.log(second);
                  })}
                  addNewItem={() => {
                    changeDataRow(null);
                    setIsOpen(true);
                  }}
                  onDelete={(_, v) => {
                    setConfirmModalDeleteProdCalc(true);
                    setDeletedCalcId(v.id);
                  }}
                  editItem={(i) => (setIsOpen(true), changeDataRow(dataTable[i]))}
                />
              </Table>
              {/* <Row>
                <StyledH3>
                  Dependencies
                </StyledH3>
                <Col lg={6}>
                  <StyledP>
                    Which of articles are used by this article
                  </StyledP>
                  <StyledTextarea>
                    --LEVEL 1--
                    500.305.1 Mini Confiture Brombeer-Honig-Rum * 12   =>   300.069 Schwarzwäldertorte 30cm (36 Stk.)
                  </StyledTextarea>
                </Col>
                <Col lg={6}>
                  <StyledP>
                    Which of articles use this article
                  </StyledP>
                  <StyledTextarea>
                    --LEVEL 1--
                    500.305.1 Mini Confiture Brombeer-Honig-Rum * 12   =>   300.069 Schwarzwäldertorte 30cm (36 Stk.)
                  </StyledTextarea>
                </Col>
              </Row> */}
            </Transition>

            <Transition index={4} text={t('product.fraction')} isWhite>
              <Row>
                <Col lg={5}>
                  <Checkbox value={isBruchTitel} field='isBruchTitel' title={t('product.fractionArticle')} margin={'10px 0'} zIndex={'0'} />
                </Col>
              </Row>
              {!isBruchTitel
                ?
                <Row>
                  <Col lg={4}>
                    <Dropdown
                      title={t('product.usedFractionArticle')}
                      labelField='concatenatedField'
                      valueField='id'
                      field='bruchSummierenAuf'
                      defaultValueField={activeBreak}
                      graphqlParams={{ query: listFA_ProductBreak }}
                    />
                  </Col>
                  <Col lg={4}>
                    <Input value={bruchVerwendeteTeile} name='bruchVerwendeteTeile' type={'number'} floatFormat={'ffFloat'} onChange={handleFractionChange} field='bruchVerwendeteTeile' title={t('product.usedFractionArticleParts')} />
                  </Col>
                </Row>
                :
                <>
                  <Row>
                    <Col lg={4}>
                      <Input value={bruchTitelAnzahlTeile} name='bruchTitelAnzahlTeile' type={'number'} floatFormat={'ffFloat'} onChange={handleFractionChange} field='bruchTitelAnzahlTeile' title={t('product.fractionsNumber')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Checkbox value={isBruchRound} field='isBruchRound' title={t('product.roundUp')} margin={'30px 0'} zIndex={'0'} />
                    </Col>
                  </Row>
                </>
              }
            </Transition>
          </div>
        );
      }}
    </Formik>
  );
};

const Block = styled.div`
  margin: 0 0 0 20px;
`;

const Buttons = styled(Row)`
  margin-top: 20px;
`;

const BottonBlock = styled(Row)`
    margin-top: 40px;
    justify-content: center;
`;

