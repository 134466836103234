import gql from 'graphql-tag';

export default gql`
query getFA_CustomerDiscountChange($id: ID!){
    fakturaAssist{
    getFA_CustomerDiscountChange(id: $id){
		data{
	        discounts
	        id
	        amount
	        discountTotal
      }
      discountsNames
      names
    }
    
  }
}`;
