import React from 'react';
import Amplify from '@aws-amplify/core';
import config from 'config/awsConfig';
import { withAuthenticator,
  VerifyContact,
  TOTPSetup,
  Loading,
} from 'aws-amplify-react';

import SignIn from './SignIn';
import ConfirmSignIn from './ConfirmSignIn';
import RequireNewPassword from './RequireNewPassword';
import ForgotPassword from './ForgotPassword';

Amplify.configure(config);

export default (Component) => withAuthenticator(Component, false,
  [
    <SignIn/>,
    <RequireNewPassword/>,
    <ForgotPassword/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <TOTPSetup/>,
    <Loading/>,
  ]
);
{/* <RequireNewPassword/>,
		<ForgotPassword/>,
		<ConfirmSignIn/>, */}
