import gql from 'graphql-tag';

export const SpecialPrice = gql`
query listFA_ProductSpecialPrice($linkArticle: Int) {
  fakturaAssist {
    listFA_ProductSpecialPrice(linkArticle: $linkArticle) {
        id
        linkArticle
        fromDate
        toDate
        price1
        price2
        price3
        price4
        price5
        price6
        price7
        price8
        price9
        price10
        description
    }
  }
}`;

export const updateSpecialPrice = gql`
mutation updateSpecialPrice($id: ID!, $fields: ProductSpecialPrice!) {
  fakturaAssist {
    updateProductSpecialPrice(id: $id, fields: $fields){
      id
      linkArticle
      fromDate
      toDate
      price1
      price2
      price3
      price4
      price5
      price6
      price7
      price8
      price9
      price10
      description
    }
  }
}`;

export const deleteProductSpecialPrice = gql `
mutation deleteProductSpecialPrice($id: ID!) {
  fakturaAssist {
    deleteProductSpecialPrice(id: $id){
      id
    }
  }
}`;

export const createProductSpecialPrice = gql `
mutation createProductSpecialPrice($fields: ProductSpecialPrice!) {
  fakturaAssist {
    createProductSpecialPrice(fields: $fields){
      id
      linkArticle
      fromDate
      toDate
      price1
      price2
      price3
      price4
      price5
      price6
      price7
      price8
      price9
      price10
      description
    }
  }
}`;
