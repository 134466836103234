import gql from 'graphql-tag';

export default gql`
mutation deleteFA_CustomerCRMNotes($id: ID!){
    fakturaAssist{
    deleteFA_CustomerCRMNotes(id: $id){
		customerLink
    }
    
  }
}`;
