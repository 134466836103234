/*eslint-disable*/
import React, { useState, useEffect, useMemo, useCallback, memo, useRef } from 'react';
import Select, { components, createFilter } from 'react-select';
import { compose, branch } from 'recompose';
import { Dots } from 'components/Loading/StyledLoading';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get, orderBy, isEqual, cloneDeep } from 'lodash';
import WarningValid from 'style/img/warningValid.svg';
import { withFieldSubmit } from '../../formik';
import withName from '../hoc/withName';
import withQuery from '../hoc/withQuery';
import withSearch from './hoc/withSearch';
import withSingleQuery from './hoc/withSingleQuery';
import MenuList from './components/MenuList';
import Option from './components/Option';
import NoOptionsMessage from './components/NoOptionsMessage';
import DropdownIndicator from './components/DropdownIndicator';
import { useTranslation } from 'react-i18next';
// import TriangleIcon from './Triangle.svg'

// const DropdownMainBlock = styled(Select)`
//     width: ${(props) => props.width || '300px'};
//     border: ${(props) => (props.err && props.err[props.name]) && (props.touched && props.touched[props.name]) ? '1px solid #c13c44' : 'none'};
//     font-weight: ${(props) => props.bold ? 'bold' : 'normal'};
//     &.Select.is-open > .Select-control .Select-arrow {
//       border-color: transparent transparent red;
//     }
//     .css-1gwetav-control {
//       cursor: pointer;
//     }
// `;

export const StyledVirtualized = styled(Select)`
  & .flat-virtualized-item {
    color: #3c445a;
    z-index: 99;
    position: fixed !Important ;
    border-bottom: 1px solid #f4f4f4;
    user-select: none;
    &  .fast-option {
      line-height: 36px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      user-select: none;
      &:not([disabled]):hover {
        box-shadow: 0 2px 2px 0 rgba(151, 151, 151, 0.35);
        background-color: #f5f5f5;
      }
      &-selected {
        background: #E6E6E6 !important;
        font-weight: 600;
      }
    }
  }
  .fast-option-focused {
    background-color: #f5f5f5;
    box-sizing: border-box;
  }
  .fa-caret-down {
    margin-right: 8px;
  }

  .css-1jknvbe-menu{
    & div{
      position: fixed !important;
      width: 300px;
    }
  }
  div[class*=indicatorContainer]:first-of-type {
    padding: 0;
  }
`

const customStyles = (row) => ({

  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    height: '36px',
    minHeight: '36px',
    width: '100%',
    background: '#fff',
    cursor: 'pointer',
    border: '1px solid #cdcdcd'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: (provided, state) => {
    // none of react-select's styles are passed to <Control />
    return {
      ...provided,
      //height: '100%',
      zIndex: 9999,
    }
  },
  option: (provided, state) => {
    return ({
      ...provided,
      background: state.isSelected ? '#f5f5f5' : '#ffffff',
      color: '#3c445a',
      fontWeight: state.isSelected ? 600 : 'initial',
      borderRadius: '3px',
      boxShadow: '0 1px 2px 0 rgba(90, 90, 90, 0.12)',
      borderBottom: '1px solid #f4f4f4',
      // fontFamily: state.isSelected ? 'Roboto-Medium' : 'Roboto-Light',
      fontWidth: state.isSelected ? '500' : '300',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      cursor: 'pointer',
      borderLeft: '1px solid #ececec',
      borderRight: '1px solid #ececec'
    });
  },
  menuList: (provided, state) => ({
    ...provided,
    width: '100%',
    height: '100%',
    zIndex: 10,
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    width: '300px',
    zIndex: 10,
    background: '#f5f5f5',
    height: '37px',
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    background: '#eeeeee',
    position: 'fixed',
    height: '36px',
    width: '300px',
    display: 'none' // tmp
  }),
});


const Dropdown = (props) => {
  const {
    labelField,
    valueField,
    defaultValueField,
    getQueryLoading,
    withoutFormik,
    width,
    withIndex,
    fetchMore,
    loading,
    additionalFunc,
    isFirstValue,
    onChangeFormik,
    submit,
    onChange,
    getQueryData,
    err = {},
    name,
    setFieldTouched,
    touched,
    customErrText,
    withoutName,
    isClearable,
    sortOrder = 'none',
    sortKey,
    additionField,
    nameForValid,
    withVirtualized = true,
    selectedLabel,
    manualErrors,
    field
  } = props;
  const convertOptions = (options) => {
    return options.map((item, index) => {
      return { value: valueField ? withIndex ? index + 1 : item[valueField] : item.id, label: labelField ? item[labelField] : item.name };
    }) || [];
  };

  const [t] = useTranslation()

  // console.log('DropDown', props)

  const [tmpValue, setTmpValue] = useState(null);
  const [isFirst, setIsFirst] = useState(true);
  const [localOffset, setLocalOffset] = useState(0);
  const [isLastFetch, setLastFetch] = useState(false);
  const [offsetLoading, setOffsetLoading] = useState(false);
  const dropdDownSyntax = useMemo(() => (props.data || []).map((item, index) => {
    return { value: valueField ? withIndex ? index + 1 : valueField === 'relatedArticles' ? `${item.id}-${item[valueField]}` : item[valueField] : item.id, label: labelField ? item[labelField] || '-' : item.name || '-', data: item };
  }) || [], [props.data]);
  const valueSyntax = dropdDownSyntax.find((i) => i.value === defaultValueField);
  const changeValue = isFirst ? get(dropdDownSyntax, '[0]', '') : valueSyntax;
  let defaultItem = isFirstValue ? changeValue : valueSyntax;
  if (getQueryData) {
    let data = getQueryData;
    // console.log('getQueryData', data)
    if (data.length) data = getQueryData[0];
    defaultItem = { value: data[valueField], label: data[labelField] };
  }

  //console.log('changeValue', defaultItem)

  // Sort by alphabetic order
  const sortedData = useMemo(() => {
    return sortOrder !== 'none'
      ? orderBy(dropdDownSyntax, [sortKey ? sortKey : item => {
        const matched = item.label.match(/[a-z]+/gi)
        return matched ? matched.join('') : item.label;
      }], [sortOrder])
      : dropdDownSyntax;
  }, [sortOrder, props.data, sortKey]) // eslint-disable-line

  const [searchText, setSearchText] = useState('');

  const onChangePag = (value) => {
    if (searchText !== value && !value) {
      // console.log(searchRef.current)
      setSearchText('')
      setLocalOffset(searchRef.current[value] || 100)
    }
  }

  const searchRef = useRef({})

  const loadOptions = async (search, prevOptions) => {
    let filteredOptions;

    if (!search) {
      filteredOptions = sortedData;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = sortedData.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 100;

    let nextOffset = 100
    const currentItem = tmpValue || defaultItem
    // console.log({ searchRef, search, currentItem })
    if (search !== searchRef.current && currentItem && filteredOptions.length > 100) {
      const itemIdx = filteredOptions.findIndex(({ value }) => value === currentItem.value)
      nextOffset = itemIdx + 1
      // console.table({ nextOffset, itemIdx })
    }

    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + nextOffset
    );

    searchRef.current = search

    return {
      options: slicedOptions,
      hasMore
    };
  };

  /**
   * Reset tmp value if local input value equals to query value
   */
  useEffect(() => {
    if (isEqual(tmpValue, defaultItem) && tmpValue) {
      setTmpValue(null);
    };
  }, [JSON.stringify(defaultItem)]); // eslint-disable-line

  /**
   * Reset tmp value after articleId is changed
   */
  useEffect(() => {
    if (tmpValue) {
      setTmpValue(null);
    };
  }, [JSON.stringify(props.singleQueryVariables?.articleId)]); // eslint-disable-line

  useEffect(
    () => {
      if (isFirstValue) {
        if (!loading) {
          isFirst && onChange(defaultItem);
          setIsFirst(false);
        }
      }
    }, [props.data, isFirstValue]
  );
  const [loadingState, setLoadingState] = useState(true);
  useEffect(() => {
    if (Boolean(!offsetLoading) === true && (Boolean(getQueryLoading) === true || Boolean(loading) === true)) {
      setLoadingState(true);
    } else {
      setLoadingState(false);
    }

  }, [offsetLoading, getQueryLoading, loading])

  const onChangeFunc = (item) => {
    // console.log(item, onChange)
    // props onChange in first priority
    if (onChange) {
      //console.log('onChange')
      onChange(item)
    } else if (!withoutFormik) {
      const mainFormikChange = (value) => {
        onChangeFormik(value, 'dropdown');
        submit(value, 'dropdown');
        additionalFunc && additionalFunc(value, item);
      }
      if (item) {
        //console.log('mainFormikChange 1')
        setTmpValue(item)
        mainFormikChange(item.value);
      } else {
        //console.log('mainFormikChange 2')
        mainFormikChange(null);
      }
    } else {
      props.setFieldValue(props.name, item.value, submit);
      additionalFunc && additionalFunc(item.value, item);
    }
  }

  let defItem = cloneDeep(defaultItem);

  if (selectedLabel && defItem) {
    defItem.label = defaultItem?.data?.[selectedLabel];
  }


  return (
    <DropdownWrapper width={width} nameForValid={nameForValid} nameForValid={name}>
      <StyledVirtualized
        isLoading={loading}
        onBlur={() => { setFieldTouched && setFieldTouched(props.name); }}
        {...props}
        options={sortedData}
        width={width}
        onInputChange={(value) => {
          onChangePag(value)
          props.onSearch(value);
        }}
        onChange={onChangeFunc}
        styles={(props.customStyles && props.customStyles(1)) || customStyles(1)}
        placeholder={'Choose...'}
        cacheUniqs={props.graphqlParams && dropdDownSyntax.length > 0 ? [props.clearCache] : [dropdDownSyntax]}
        components={{ DropdownIndicator, MenuList, NoOptionsMessage }}
        //menuShouldScrollIntoView={true}
        value={defItem ? defItem : {}}
        tabIndex={0}
        isClearable={isClearable}
        optionHeight={37}
        grouped={false}
        filterOption={createFilter({ ignoreAccents: false })}
        noOptionsMessage={() => t('common.noOptionsFound')}
      />
      {manualErrors && field && manualErrors[field] && <small style={{ color: 'red', display: 'block', maxWidth: 140 }}>{manualErrors[field]}</small>}
      {((err && err[nameForValid || name]) && (touched && touched[nameForValid || name])) &&
        <Error>
          <Img src={WarningValid} /> {customErrText ? customErrText : err[nameForValid || name]}
        </Error>
      }
      {loadingState && !localOffset && <StyledLoader><Dots /></StyledLoader>}
    </DropdownWrapper>
  );
};

const StyledLoader = styled.div`
  position: absolute;
  left: 75%;
  top: 16px;
  width: 54px;

  span {
    margin-right: 5px;
  }
`;

const DropdownWrapper = styled.div`
  border: ${(props) => (props.err && props.err[props.nameForValid || props.name]) && (props.touched && props.touched[props.nameForValid || props.name]) ? '1px solid #c13c44' : 'none'};
  position: relative;
  display: inline-block;
  width: ${(props) => props.width || '300px'};
  cursor: pointer;
`;
const Error = styled.div`
  color: #c13c44;
  /* font-family: Roboto-Light; */
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  margin-top: 0px;
`;

const Img = styled.img`
  margin-top: 2px;
`;

const _withVirtualized = Component => props => <Component {...props} withVirtualized />

export default compose(
  withSearch,
  _withVirtualized,
  withQuery,
  withName,
  withFieldSubmit,
  branch(
    ({ getQuery }) => !!getQuery,
    withSingleQuery
  ),
)(memo(Dropdown));

Dropdown.propTypes = {
  data: PropTypes.array, //Data array . In prior that graphqlParams!
  graphqlParams: PropTypes.object, // Standart graphqlParams
  sortOrder: PropTypes.oneOf(PropTypes.string),
  defaultValueField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]), // Choosen id of dropdown(according to value in DATA)
  labelField: PropTypes.string, //LABEL from data
  valueField: PropTypes.string, //VALUE from data
  field: PropTypes.string, //Formik value (default id of dropdown)
  isFirstValue: PropTypes.bool, //if true - value = first value in data
  isClearable: PropTypes.bool,
  withVirtualized: PropTypes.bool
};

/*
  Example
    <Dropdown
      title={t('product.discountGroups')}
      labelField='name'
      valueField='id'
      field='linkGroup'
      defaultValueField={values.linkGroup}
      data={arr} || graphqlParams={{query: query}}
    />
*/
