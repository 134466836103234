export const postTableNamesParams = (t) =>([
  {
    name: t('common.name'),
    dataBase: 'taxRange',
  },
]);

export const postTableAttributesParams = (t) =>([
  {
    name: t('options.configurations.post.postWeight'),
    dataBase: 'taxRange',
  },
  {
    name: t('common.price'),
    dataBase: 'taxRange',
  },
]);
  
