import React, { useState } from 'react';

const withFilter = (WrappedComponent) => (props) =>{
  const [isfilterButton, changeFilterButton] = useState(false);
  const changeFilter = () =>{
    changeFilterButton(!isfilterButton);
  };
  return <WrappedComponent {...props} isfilterButton ={isfilterButton} changeFilter={changeFilter}/>;
};

export default withFilter;
