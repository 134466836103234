import gql from 'graphql-tag';

export default gql`
mutation getPathM($path: String){
  fakturaAssist{
    getPathM(path: $path){
		body
		path
    }
  }
}`;
