import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import { FixedSizeList as List } from 'react-window';
function MenuList(props) {
  let { options, children, maxHeight, getValue, itemSize, isLoading } = props;
  const [value] = getValue();

  const initialOffset = findIndex(options, (item) => item.value === value.value) * itemSize;
  const currentHeight = children.length ? children.length * itemSize : itemSize;

  children = children.props ? [children] : children;

  if (isLoading) {
    return (
      <div>
        {children}
      </div>
    );
  }


  return (
    <List
      height={currentHeight > maxHeight ? maxHeight : currentHeight}
      itemCount={children.length}
      itemSize={itemSize}
      initialScrollOffset={initialOffset > maxHeight ? initialOffset : null}
    >
      {({ index, style }) => {
        return <div style={style}>{children[index]}</div>;
      }}
    </List>
  );
}

MenuList.propTypes = {
  maxHeight: PropTypes.number,
  getValue: PropTypes.func,
  itemSize: PropTypes.number,
};

MenuList.defaultProps = {
  itemSize: 37,
};

export default memo(MenuList, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
