export const menuObj = [
  {
    name: 'Neu',
    link: '/order/create/order',
  },
  {
    name: 'Bearbeiten',
    link: '/order/edit/order',
  },
  {
    name: 'Übersicht',
    link: '/order/overview/order',
  },
];

// OrderType sort: 1, 6, 2, 4, 5, 9
export const typeOrder = (t) => [
  {
    name: t('order.order'),
    id: 1,
    sortId: 0,
  },
  {
    name: t('order.additionalOrder'),
    id: 2,
    sortId: 2,
  },
  {
    name: t('order.return'),
    id: 4,
    sortId: 3,
  },
  {
    name: t('order.creditOrder'),
    id: 5,
    sortId: 4,
  },
  {
    name: t('order.specialOrder'),
    id: 6,
    sortId: 1,
  },
  {
    name: t('customer.internet'),
    id: 7,
  },
  {
    name: t('order.catering'),
    id: 9,
    sortId: 5,
  },
];

export const searchFields = 'KundenNr';

export const tableData = (t) =>([
  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
    relativeWidth: 0.3,
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },

  {
    name: t('common.name'),
    dataBase: 'name',
  },
]);

export const additionalMenuObj = [
  {
    name: 'Order',
    link: '/order/create/order',
  },
  {
    name: 'Default',
    link: '/order/create/default ',
  },
];

export const searchColumns = ['name', 'customerNr'];
export const sortFiled = 'name';

export const filterFolder = (t) => ([
  {
    title: t('common.filter.dailyCustomers'),
    value: 'dailyCustomer',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.customerWithEmptyOrders'),
    value: 'customerWithEmptyOrders',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.acceptedOffers'),
    value: 'acceptedOffers',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.activeCustomers'),
    value: 'activeCustomer',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.inactiveCustomers'),
    value: 'inactiveCustomers',
    field: 'folderName',
    subfolder: [],
  },
  // {
  //   title: 'Customer',
  //   value: 'customer',
  //   field: 'folderName',
  //   subfolder: [],
  // },

]);
