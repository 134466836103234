import gql from 'graphql-tag';

export default gql`
mutation updateFA_Assortment($id: Int!, $fields: FA_AssortmentInput!){
    fakturaAssist{
		updateFA_Assortment(id: $id, fields: $fields){
		    id
            name
        }
  }
}`;
