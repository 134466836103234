import React from 'react';
import { Formik } from 'formik';

const app = ({ children, values, data }) => {
  return(
    <Formik
      initialValues={values}
      render={
        (props) => {
          return(
            children({ data: data, ...props })
          );
        }}
    />);
};

export default app;
