import gql from 'graphql-tag';

export default gql`
mutation updateFA_Customer($id: ID!, $fields: CustomerFields!){
    fakturaAssist{
    updateFA_Customer(id: $id, fields: $fields){
		nameId
		
    }
    
  }
}`;
