import Formik, { withFieldSubmit } from './Formik';
export { withFieldSubmit };
export default Formik;

/* Example
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) => { }}
      </Formik>

*/
