import React, { useState, useEffect } from 'react';

const withQuery = (WrappedComponent) => (props) => {
  const [searchValue, changeSearchValue] = useState('');
  useEffect(() => {
    changeSearchValue(searchValue);
  }, [searchValue]);
  useEffect(() => {
    changeSearchValue('');
  }, [props.name]);
  return <WrappedComponent {...props} searchValue={searchValue} changeSearchValue={changeSearchValue || props.changeSearchValue}/>;
};
export default withQuery;
