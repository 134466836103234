import React, { useState, useEffect, createContext } from 'react';
import { cloneDeep } from 'lodash';

export const ContexTransitions = createContext();
export const ContexHeader = createContext();

const withLink = (WrappedComponent) => (props) => {
  const { match, sectionInfo, params } = props;
  const { id, subsection, mainsection } = match.params;
  //Link cache
  const [sectionsPaths, setSectionsPaths] = useState({});
  //Filter cache
  //const [isData, setIsData] = useState(false);

  let transitions = sectionInfo.transitions || [];
  const [pageCache, setPageCache] = useState([]);

  //When filter component accept new data, data add into actualFilter array according to name;
  const actualPageCache = pageCache.find((i) => i.name === sectionInfo.name);
  let contex = sectionInfo.defaultFilterParams || {};
  if (actualPageCache) {
    if (sectionInfo.name === actualPageCache.name) {
      contex = actualPageCache.filter || sectionInfo.defaultFilterParams; // prevent mainList quering after transition change
      if (actualPageCache.subSection) {
        const actualSubSection = actualPageCache.subSection.find((i) => i.name);
        if (subsection === actualSubSection.name) {
          transitions = actualSubSection.transitions;
        } else {
          transitions = sectionInfo.transitions;
        }
      }
    }
  }

  contex = {
    ...contex,
    params: params,
  };

  // console.log('withCache', contex.filter[1])

  const cleanFilter = () => {
    const cache = cloneDeep(pageCache);
    const cacheIndexPage = pageCache.findIndex((item) => item.name === sectionInfo.name);
    cache.splice(cacheIndexPage, 1);
    setPageCache(cache);
  };
  const writeContex = (value) => {
    const clonedValue = cloneDeep(value);
    let newCachePageObject = { name: sectionInfo.name, filter: { ...clonedValue } };
    const cacheIndexPage = pageCache.findIndex((item) => item.name === sectionInfo.name);
    const cacheCopy = cloneDeep(pageCache);

    if (cacheIndexPage === -1) {
      if (sectionInfo.name === 'offer') {
        /**
         * Set localStorage filter dates to date property in offer section
         * for initial request
         */
        const storageFilter = localStorage.getItem(mainsection);
        const parsedFilter = JSON.parse(storageFilter) || [];
        const startDateIdx = parsedFilter?.findIndex((item) => item.column === 'date');
        const endDateIdx = parsedFilter?.findIndex((item) => item.column === 'secondDate');
        if (startDateIdx > -1 && endDateIdx > -1) {
          newCachePageObject = {
            ...newCachePageObject,
            filter: {
              ...newCachePageObject.filter,
              date: { date: parsedFilter[startDateIdx].value, secondDate: parsedFilter[endDateIdx].value },
            },
          };
        }
      }
      cacheCopy.push(newCachePageObject);
      setPageCache(cacheCopy);
    } else {
      cacheCopy.splice(cacheIndexPage, 1, newCachePageObject);
      setPageCache(cacheCopy);
    }
  };

  const setTransitions = (index) => {
    const transCopy = transitions;
    transCopy[index] = !transCopy[index];
    if (actualPageCache ? actualPageCache.subSection : false) {
      if (actualPageCache.subSection.name === subsection) {
        const subSectionCache = cloneDeep(actualPageCache.subSection);
        const index = subSectionCache.findIndex((i) => i.name === subsection);
        if (index !== -1) {
          subSectionCache[index] = {
            name: subsection,
            transitions: transCopy,
          };
        }
        setPageCache([{
          name: sectionInfo.name,
          subSection: [...subSectionCache],
        }]);
      }
      setPageCache([{
        name: sectionInfo.name,
        subSection: [{
          name: subsection,
          transitions: transCopy,
        }],
      }]);
    } else {
      setPageCache([{
        name: sectionInfo.name,
        subSection: [{
          name: subsection,
          transitions: transCopy,
        }],
      }]);
    }
  };

  const makeRedirect = () => {
    const { client, sectionInfo, history } = props;
    try {
      const cachedData = client.readQuery({
        query: sectionInfo.listQuery,
        variables: { ...contex },
      });
      const getDeeplyArray = (inputData) => {
        const queryName = inputData.fakturaAssist;
        return queryName[Object.keys(queryName)[0]];
      };
      const concatData = cachedData ? getDeeplyArray(cachedData) : [];
      history.replace({
        pathname: `/${mainsection}/${subsection}/${sectionInfo.specialSelectField ? concatData[0][sectionInfo.specialSelectField] : concatData[0].id}`,
      });
    } catch (err) {
      // console.log(err);
      return false;
    }
  };

  useEffect(() => {
    if (id) {
      setSectionsPaths({ ...sectionsPaths, [mainsection]: `/${mainsection}/${subsection}/${id}` });
    }
  }, [match.params]);

  const child = (<ContexTransitions.Provider value={{ transitions: transitions, setTransitions: setTransitions }}>
    <WrappedComponent
      {...props}
      pageCache={pageCache}
      makeRedirect={makeRedirect}
      setPageCache={setPageCache}
      contex={contex}
      writeContex={writeContex}
      isClear={!!actualPageCache}
      cleanFilter={cleanFilter}
      mainsection={mainsection}
      sectionsPaths={sectionsPaths}
      transitions={transitions}
      setTransitions={setTransitions}
      defaultFilterParams={sectionInfo.defaultFilterParams} />
  </ContexTransitions.Provider>);

  const renderOut = (context) => {
    if (id) {
      return child;
    }
    const redirectRes = makeRedirect(context);
    return redirectRes || child;
  };
  return renderOut();
};

export const withTransitions = (WrapedComponent) => ({ ...props }) => (
  <ContexTransitions.Consumer>
    {(context) => {
      const newProps = {
        ...props,
        transitions: context.transitions || [],
        setTransitions: context.transitions.length ? context.setTransitions : null,

      };
      return <WrapedComponent {...newProps} />;
    }
    }
  </ContexTransitions.Consumer>
);

export default withLink;
