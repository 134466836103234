export const generateNewItem = (index) => ({
  addition: '',
  additive: null,
  discount: null,
  discountSpec: 1,
  email: null,
  fax: null,
  id: index + 1,
  indTextDeliveryNote: null,
  indTextProduction: null,
  internet: null,
  labelRecipeLink: null,
  weight: 0,
  orderPositionID: 0,
  price: 0,
  productLink: 0,
  productLinkLookName: 0,
  productLinkLookNr: 0,
  productName: null,
  productNr: null,
  quantity: null,
  taxCode: 1,
  total: 0,
  virtualPositionLink: null,
  canChangeInOrders: true,
  __typename: 'FA_OrderProducts',
  min: null,
  max: null,
});
