
import gql from 'graphql-tag';

export default gql`
mutation createFA_CustomerCRMNotes($fields: CRMFields!){
    fakturaAssist{
    createFA_CustomerCRMNotes(fields: $fields){
		id
		addedOn
		
    }
    
  }
}`;
