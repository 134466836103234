import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import { cloneDeep, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'style';
import TableGrid from 'components/tableGrid/TableGrid';
import { Checkbox, Input } from 'components/elements';

import { listFA_fkArticleLists, updateList, createFA_fkArticleLists, deleteItem, createRealations, deleteRealations } from './gql/list';

export default (props) => {
  const [t] = useTranslation();
  const { loading } = useQuery(listFA_fkArticleLists, {
    skip: props.data.id ? false : true,
    variables: { linkArticle: +props.linkId },
  });

  const [relationsCreate, { client }] = useMutation(createRealations);
  const [relationsDelete] = useMutation(deleteRealations);

  const updateCheckbox = (info, i, { column, order }) => {
    const id = info.id;

    const cache = client.readQuery({
      query: listFA_fkArticleLists,
      variables: { linkArticle: +props.linkId },
    });

    const newValue = cloneDeep(cache);

    const clonedList = orderBy(newValue.fakturaAssist.listFA_fkArticleLists, [column], [order?.toLowerCase()]);

    clonedList[i].checked = !info.checked;

    const variables = {
      fields: {
        linkArticle: +props.linkId,
        linkArticleList: +id,
      },
    };

    !info.checked ? relationsCreate({ variables }) : relationsDelete({ variables });

    client.writeQuery({
      query: listFA_fkArticleLists,
      variables: { linkArticle: +props.linkId },
      data: newValue,
    });
  };

  const params = [
    {
      name: '',
      dataBase: 'checked',
      specialComponent: (i, ...rest) => {
        const l = rest[2];
        const list = l ? orderBy(l, [rest[4].column], [rest[4].order?.toLowerCase()]) : [];
        return (
          <Checkbox
            onClick={updateCheckbox.bind(this, list[i], i, rest[4])}
            margin={'0px'}
            zIndex={100}
            value={loading ? false : list[i] === undefined ? false : list[i].checked === undefined ? false : list[i].checked}
            field='checked'
          />
        );
      },
      relativeWidth: 0.1,
    },
    {
      name: t('common.name'),
      dataBase: 'name',
      isMutation: true,
    },
  ];

  const ModalEdit = (props) => {
    return (
      <Row>
        <Col lg={6}>
          <Input
            width={'300px'}
            withoutFormik={true}
            onChange={props.handleChange}
            value={props.values.name}
            name={'name'}
            title={t('common.name')}
          />
        </Col>
      </Row>
    );
  };

  return (
    <MainBlock>
      <Row>
        <Col width={'450px'}>
          <TableGrid
            graphqlParams={{
              query: listFA_fkArticleLists,
              variables: { linkArticle: +props.linkId },
            }}
            specialWidth={450}
            tableData={params}
            Component={ModalEdit}
            modalWidth={440}
            modalHeight={230}
            headerText={t('common.edit')}
            typeName={'FA_fkArticleLists'}
            qqlMutationUpdate={updateList}
            qqlMutationCreate={createFA_fkArticleLists}
            itemDeleted={deleteItem}
            defaultValuesForCache={['name', 'id', 'checked']}
            withModalMutations
            defaultFilterParams={{ filter: [] }}
            confirmDelete
            // searchColumns={['name']}
            dataPolicy={'local'}
            withDefaultFunction={true}
            withDefaultParams={true}
            initialSortValue='name'
            modalDisableBtnOnEmpty
          />
        </Col>
      </Row>
    </MainBlock>
  );
};

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
`;