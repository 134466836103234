
import styled from 'styled-components';
import { Input } from 'components/elements';
import { Row, Col } from 'style';
import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export default (props) =>{
  const { t, values, index, columnIndex, id, setId, debouncedInput, type, setFieldValue, name, value, checkAdditionalInfo } = props;

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(
    ()=>{
      props.values.length && setActive(checkAdditionalInfo);
    }, [props.id, props.open]
  );
  const onFocusInput = (id) =>{
    document.getElementById(id) && document.getElementById(id).focus();
  };
  const debounced = debounce(onFocusInput, 1);

  const onKeyDown = (e) =>{
    if(e.shiftKey && e.keyCode === 13) {
      if(index !== 0) {
        document.getElementById(`${index - 1}-${columnIndex}-${values[index - 1].id}`) && document.getElementById(`${index - 1}-${columnIndex}-${values[index - 1].id}`).focus();
      }
    } else if(e.keyCode === 13) {
      if(index !== values.length - 1) {
        document.getElementById(`${index + 1}-${columnIndex}-${values[index + 1].id}`) && document.getElementById(`${index + 1}-${columnIndex}-${values[index + 1].id}`).focus();
      }
    }
  };
  return (
    <div>
      {open ?
        <Input
          key={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          type={type}
          textAlignRight='right'
          style={{ marginTop: '-5px' }}
          noBorder
          withOpacity
          onKeyDown = {(event)=>{
            onKeyDown(event);
          }}
          width='100%'
          withoutFormik={true}
          id = {values[index] && `${index}-${columnIndex}-${values[index].id}`}
          onFocus={(e)=>{
            values[index].id !== id && setId(values[index].id);
          }}
          onBlur={(e)=>{
            debouncedInput(values);

            setOpen(false);
          }}
          onChange={(e)=>{
            setFieldValue(e.target.name, +e.target.value || (e.target.value === '0' ? 0 : null));
            debouncedInput.cancel();
          }}
          value={value}
          name={name}/>
        :
        <div
          style={{ width: '100%', height: '40px' }}
          tabIndex='0'
          onFocus={(e)=>{
            debounced.cancel();
            values[index].id !== id && setId(values[index].id);
            setOpen(true);
            debounced(`${index}-${columnIndex}-${values[index].id}`);
          }}
          id={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          key={values[index] && `${index}-${columnIndex}-${values[index].id}`}
          type={'text'}
        ><Row><Col width='83%'>{value}</Col>  <Col width='17%' title={t('customer.defaultOrderAdditionalInfo')}>{active && <CircleActive />}</Col> </Row></div>
      }
    </div>
  );
};

const CircleActive = styled.div`
  margin-top: 9px;
  height: 3px;
  width: 4px;
  background: #3cb950;
  clip-path: circle(50% at center center);
`;
