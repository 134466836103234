import gql from 'graphql-tag';

export default gql`
query listFA_Customer($params: ListQueryArgs, $filter: [FA_GeneralFilter]){
  fakturaAssist{
    listFA_Customer(params: $params, filter: $filter){
      id
      nameCustomerDisp
      customerNr
    }
  }
}`;
