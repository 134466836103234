import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Dropdown, Checkbox, Input } from 'components/elements';
import Formik from 'components/formik';
import { Row, Col } from 'style';

import listFA_ReductionsAndPricesTakeFromKinds from './qql/listFA_ReductionsAndPricesTakeFromKinds';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  useEffect(() => {
    const section = document.getElementById('addition_section');
    if (section) {
      section.style.height = document.body.clientHeight - 280 + 'px';
    }
    return () => section.style.height = '100%';
  }, []);
  return (
  	<Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        return (
          <HeadBlock>
            <MainBlock>
              <Col lg={4}>
                <Checkbox
                  margin={'19px 0 0 0'}
                  value={values.isCashAssistKredit}
                  field='isCashAssistKredit'
                  title={t('customer.cashAssist.creditCustomerCashAssist')}
                />
                <Dropdown
                  title={t('customer.cashAssist.takeReductionsAndPricesFrom') + '...'}
                  valueField='id'
                  labelField='name'
                  field='cashAssistPrice'
                  defaultValueField={values.cashAssistPrice}
                  graphqlParams={{
                    query: listFA_ReductionsAndPricesTakeFromKinds,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                />
                {values.cashAssistPrice === 6 &&
                  <Input value={values.specialCADiscount} field='specialCADiscount' type='number' title={t('customer.cashAssist.specialDiscountCashAssist') + ' (%)'}/>
                }
              </Col>
            </MainBlock>
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled(Row)`
  padding: 0px 0px 0px 0px;
  position: relative;
  z-index:0;
`;

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
