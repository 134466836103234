import gql from 'graphql-tag';

export default gql`
query listFA_InvoiceListNew($filter: [FA_GeneralFilter]!, $date: FA_FilterDate!){
    fakturaAssist{
    	listFA_InvoiceListNew(filter: $filter, date: $date){
    		id
    		nameRes
    		productNr
			sammelfakturaAufKunde
 		}
 	}
}`;
