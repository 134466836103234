import React, { useState } from 'react';
import { omit, cloneDeep } from 'lodash';
import styled from 'styled-components';
import { readQuery } from 'helpers/queryOperations';
import { useMutation, updateQuery } from 'components/graphql';

import MutationHoc from 'components/graphql/MutationHoc';
import { Row, Col } from 'style';
import Formik from 'components/formik';
import { Calendar, Transition, TextAreaStandart } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';
import mutateArrayIntoObject from 'helpers/mutateArrayIntoObject';


import { listFA_AusnahmeTag } from '../../qql/query';
import { updateFA_AusnahmeTag, createFA_AusnahmeTag, deleteFA_AusnahmeTag } from '../../qql/mutation';

//deleteFA_AusnahmeTag, createFA_AusnahmeTag,

export default (props) => {
  // const { t, client, debouncedOnMutation } = props;
  const [selectItem, setSelectItem] = useState(null);
  const { t, client } = props;
  const [deleteAusnahmeTag] = useMutation(deleteFA_AusnahmeTag);
  const [updateAusnahmeTagHook] = useMutation(updateFA_AusnahmeTag);

  const week = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const tableData = [
    {
      name: 'Date',
      dataBase: 'date',
      relativeWidth: 0.25,
    },
    {
      relativeWidth: 0.25,
      name: 'Replacing date',
      dataBase: 'replacingDate',
    },
    {
      relativeWidth: 0.20,
      name: 'Day',
      dataBase: 'date',
      specialComponent: (index, _, date)=>{
        return (
          <div>{date && week[new Date(date).getDay()]}</div>

        );
      },
    },
    {

      name: 'Note',
      dataBase: 'note',
    },
  ];

  return(
    <Formik
      initialValues={ omit(selectItem, ['__typename']) }
      enableReinitialize={true}
      onSubmit={(values) => {
        console.log(values);
        updateAusnahmeTagHook({
          variables: { id: values.id, fields: mutateArrayIntoObject(['date', 'replacingDate', 'note'], values) },
          update: updateQuery({
            query: listFA_AusnahmeTag,
            variables: {} }, (draft, { mutationResult, readQuery, queryField }) => {
            const newItems = values;
            draft[queryField] = newItems;
          }),
        });
      }
      }
      linkId={selectItem && selectItem.id}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        return (
          <Main>
            <ClientList>
     
              <MutationHoc mutation={createFA_AusnahmeTag}>
                {({ mutation }) => (
                  <TableGrid
                    height={650}
                    disableHeightChange
                    addNewItem = {async() => {
                      const mutationData = await mutation({ variables: { fields: { } } });
                      const createdId = mutationData.data.fakturaAssist.createFA_AusnahmeTag.id;
                      const listFromCache = readQuery({ client, query: listFA_AusnahmeTag, variables: {} });
                      const copyListFromCache = cloneDeep(listFromCache);
                      copyListFromCache.splice(copyListFromCache.length - 1, 0, { id: createdId, __typename: 'FA_AusnahmeTag', date: '', note: '', replacingDate: '' });
                      client.writeQuery({
                        query: listFA_AusnahmeTag,
                        variables: {},
                        data: {
                          fakturaAssist: {
                            listFA_AusnahmeTag: cloneDeep(copyListFromCache),
                            __typename: 'FA_Query',
                          },
                        },
                      });
                    }}
                    onDelete={ async (index) => {
                      
                      const listFromCache = readQuery({ client, query: listFA_AusnahmeTag, variables: {} });
                      const deleteID = listFromCache[index].id;
                      await deleteAusnahmeTag({
                        variables: { id: deleteID },
                      });
                      const copyListFromCache = cloneDeep(listFromCache);
                      copyListFromCache.splice(index, 1);
                      client.writeQuery({
                        query: listFA_AusnahmeTag,
                        variables: {},
                        data: {
                          fakturaAssist: {
                            listFA_AusnahmeTag: cloneDeep(copyListFromCache),
                            __typename: 'FA_Query',
                          },
                        },
                      });
                      
                    }}
                    graphqlParams={{ query: listFA_AusnahmeTag, variables: {} }}
                    linkId={selectItem && selectItem.id}
                    selectById
                    getFirstValueOfMount={(item) => setSelectItem(item)}
                    changeSelectId={(item) => {
                      setSelectItem(item);
                    }
                    }
                    withSearch
                    tableData={tableData}
                    withoutIdForUpdate='all'
                  />
                )
                }
              </MutationHoc>
            </ClientList>
            <Content>
            
              <Transition text={t('options.configurations.defaultSettings')} isOpen withoutMargin>
                <Row>
                  <Col lg={2}>
                    <Calendar
                      field='date'
                      title={'Date'}
                      value={values.date}
                    />
                  </Col>
                  <Col lg={2}>
                    <Calendar
                      field='replacingDate'
                      title={'Replacing date'}
                      value={values.replacingDate}
                    />
                  </Col>
                </Row>

                <TextAreaStandart
                  field='note' value={values.note} width='240px' title='Note'
                />

              </Transition>
         
            </Content>
      
          </Main>
        );
      }}
    </Formik>
  );
};

const Content = styled.div`
  overflow-x: hidden;
  width: ${({ withoutItems }) => withoutItems ? '99.388889%' : '70%'};
  display: block;
  float: left;
  padding: 0px 20px 0 20px;
`;

const ClientList = styled.div`
  width: 30%;
  min-height: 1px;
  display: block;
  float: left;
  padding-left: 20px;
`;

export const Main = styled.div`
  width: 100%;
  height: 95%;
  position: relative;
  background: #f8fdff;
  margin: auto;
  padding: 20px 0 0 0;
  overflow-y: auto;
`;
