import React, { useState } from 'react';
import styled from 'styled-components';

import blackArrowUp from 'style/blackArrowUp.svg';

import Dropdown from './Dropdown';

const SmartDropdown = (props) => {
  const { valueOfDropdown, changeTableIndex, rowIndex, data, columnIndex, name, noEdit, background } = props;
  const l = data.length;
  const onKeyDownDrop = (e) =>{
    // if(e.shiftKey && e.keyCode === 9) {
    //   document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`) && document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`).focus();
    // } else if(e.keyCode === 9) {
    //   document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`) && document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`).focus();
    // }else if(e.keyCode === 13) {
    //   document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`) && document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`).focus();
    // }
  };

  const [open, setOpen] = useState(false);
  return open ? <div onClick={() => open && setOpen(false)}><Dropdown
    menuIsOpen
    autoFocus
    sortBy={props.sortBy}
    paddingLeft='19px'
    onBlur={() => setOpen(false)}
    paddingLeft={props.paddingLeft}
    name={props.name}
    onChange = {props.onDropdownChange}
    placeholder={props.placeholder}
    changeSearchValue={props.changeSearchValue}
    onDropdownClick={props.onDropdownClick}
    date={props.date}
    topDrop={props.topDrop}
    width={props.width}
    scrollToRow={props.scrollToRow}
    graphqlParams={props.graphqlParams}
    noEdit={noEdit}
    onChange = {(item)=>{
      props.onDropdownChange(item, name);
      setOpen(false);
    }}
    onKeyDown = {(event)=>{
      onKeyDownDrop(event);
    }}
    defaultValueField={props.defaultValueField}
    labelField={props.labelField}
    id = {columnIndex && `${columnIndex}-${l - rowIndex}-${name}`}
    key={columnIndex && `${columnIndex}-${l - rowIndex}-${name}`}
    data={props.dropdownData}
    withVirtualized
  /></div> : <HidenDiv
    tabIndex='0'
    id = { `${columnIndex}-${l - rowIndex}-${name}`}
    key={`${columnIndex}-${l - rowIndex}-${name}`}
    onFocus={(e)=>{
      if(!noEdit) {
        setOpen(true);
        changeTableIndex(rowIndex);
      }
    }}
    background={background}
    noEdit={noEdit}
    loading={props.loading}
  > {valueOfDropdown}</HidenDiv>;
};

export default SmartDropdown;

export const HidenDiv = styled.div`
font-weight: 500;
width: 100%;
padding: 10px 0px 0px 10px;
height: 38px;
border: none;
width: 100%;
background: ${({ background }) => (background ? background : 'inherit')};
${({ noEdit }) => noEdit && `
  color: #afafaf;
  cursor: not-allowed
`}
&:before {
  content: url("${blackArrowUp}");
    position: absolute;
    right: 10px
}
`;
