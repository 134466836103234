import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { Input, Transition } from 'components/elements';
import Formik from 'components/formik';
import { LoadingWrap } from 'style/default';

export default ({ t, configData, loading, getFA_Config, variables, onSubmit }) => {

  return (
    <Formik
      initialValues={configData}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <LoadingWrap loading={loading}><HeadBlock>
            <Transition index={0} text={t('options.company.company')} isOpen>
              <Row>
                <Col lg={4}>
                  <Input value={values.company} field='company' title={t('options.company.company')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.street} field='street' title={t('common.street')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.fax} field='fax' title={t('options.company.fax')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.taxNumber} field='taxNumber' title={t('options.company.uidNumber')}/>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Input value={values.name} field='name' title={t('options.company.name')}/>
                </Col>
                <Col lg={2}>
                  <Input value={values.postCode} field='postCode' title={t('common.zip')} small/>
                </Col>
                <Col width='200px'>
                  <Input value={values.city} field='city' title={t('common.place')} small/>
                </Col>
                <Col lg={4}>
                  <Input value={values.email} field='email' title={t('common.email')}/>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Input value={values.additive} field='additive' title={t('options.company.addition')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.phone} field='phone' title={t('common.phone')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.internet} field='internet' title={t('options.company.internet')}/>
                </Col>
              </Row>
            </Transition>
            
            <Transition index={1} text={t('options.company.bankAccount')} isOpen>
              <>
                <Row>
                  <Col lg={4}>
                    <Input value={values.bankName} field='bankName' title={t('common.bankName')}/>
                  </Col>
                  <Col lg={4}>
                    <Input value={values.creditorId} field='creditorId' title={t('options.company.creditorIdentificationNumber')}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2}>
                    <Input value={values.bin} field='bin' title={t('common.bin')} small/>
                  </Col>
                  <Col width='200px'>
                    <Input value={values.accountNr} field='accountNr' title={t('common.accountNo')} small/>
                  </Col>

                  <Col lg={4}>
                    <Input value={values.iban} field='iban' title={t('common.iban')}/>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Input value={values.bic} field='bic' title={t('common.bic')}/>
                  </Col>
                </Row>
              </>
            </Transition>
          </HeadBlock>
          </LoadingWrap>);
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;
