/*import React from 'react';
import styled from 'styled-components';
import {Loader as LoaderUI} from 'semantic-ui-react';
import ReactDOM from 'react-dom';

const Loader = (marginTop) => (
    ReactDOM.createPortal(
        <LoaderBlock marginTop={marginTop}>
            <LoaderCircle active inline='centered' size='massive' />
        </LoaderBlock>,
        document.body
    )
);

export default Loader;

const LoaderCircle = styled(LoaderUI)`
  margin-top: 20% !important;
  z-index: 120;
`;

const LoaderBlock = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 100;
  margin-left: -20px;
`; */

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 50px }
  100% { margin-bottom: 0 }
`;
const DotWrapper = styled.div`
  
  
`;

const LoaderBackground = styled.div`
  min-height: ${(props) => props.height}px;
  background-color: white;
  display: inline-block;
  width: 100%;
`;
const Dot = styled.div`
  background-color: #3c445a;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: 0 5px;
  animation: ${BounceAnimation} 1.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;

class LoadingDots extends Component {
  render() {
    const { height } = this.props;
    return (
      <LoaderBackground height={height ? height : 600}>
        <DotWrapper>
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='0s' />
          <Dot delay='.1s' />
          <Dot delay='.2s' />
        </DotWrapper>
      </LoaderBackground>
    );
  }
}
export default LoadingDots;
