import gql from 'graphql-tag';

export default gql`
mutation updateQuantityFreezer($arrIdForUpdate: String!, $arrIdLieferscheinForUpdate: String!, $arrValueOfIdForUpdate: String!){
    fakturaAssist{
    updateQuantityFreezer(arrIdForUpdate: $arrIdForUpdate, arrIdLieferscheinForUpdate: $arrIdLieferscheinForUpdate, arrValueOfIdForUpdate: $arrValueOfIdForUpdate){
		Multiplier
    }
    
  }
}`;
