import gql from 'graphql-tag';

export default gql`
mutation deleteDefOrder($id: Int!){
    fakturaAssist{
    deleteDefOrder(id: $id){
		id
    }
    
  }
}`;
