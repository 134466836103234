import styled from 'styled-components';

const CELL_VERITCAL_PADDING = '7px';
const CELL_HORIZONTAL_PADDING = '10px';

export default styled.div.withConfig({ shouldForwardProp: (prop) => prop === 'children' })(({ theme, onRowClick, label, labelColor }) => `
	.dx-widget, .dx-toolbar {
		color: inherit;
		input {
			line-height: inherit;
		}
	}
	.dx-toolbar {
		background: transparent;
	}
	.dx-datagrid {
		background: transparent;
		&-headers {
			border-bottom: none;
			& + .dx-datagrid-rowsview {
				border-top: none;
			}
			.dx-datagrid-content {
				margin-bottom: 0;
			}
		}

		&-content {
			border: 1px solid #CDCDCD;
			font-family: 'Montserrat', 'Roboto', sans-serif;
			background: rgb(238, 238, 238);
			.dx-datagrid-table .dx-row {
				cursor: ${onRowClick ? 'pointer' : 'auto'};
				border-bottom: 2px solid #ececec;
				& > td {
					height: 40px;
					vertical-align: middle;
					padding: ${CELL_VERITCAL_PADDING} ${CELL_HORIZONTAL_PADDING};
					position: relative;
					font-size: 14px;
					font-weight: 400;
					background: transparent;
					color: ${theme?.primaryColor};
					border: none;
					&:not(:first-of-type):after {
						content: '';
						position: absolute;
						top: 8px;
						bottom: 8px;
						left: 0;
						width: 1px;
						background: rgba(195, 195, 195, 0.42);
					}
					&.dx-datagrid-group-space {
						&:after, & + td:after {
							display: none;
						}
					}
				}
				&.dx-header-row {
					& > td {
						color: #fff;
						font-size: 14px;
						&:after {
							background: rgba(47, 54, 72, 0.8);
						}
						&[aria-sort="ascending"] {
							box-shadow: inset 0 2px 0 0 ${theme?.activeColor};
						}
						&[aria-sort="descending"] {
							box-shadow: inset 0 -2px 0 0 ${theme?.activeColor};
						}
					}
				}
				&.dx-freespace-row, &.dx-group-row {
					& > td:after {
						display: none;
					}
				}
			}
		}

		&-rowsview {
			box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
		}

		&-rowsview .dx-datagrid-content {
			box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
			.dx-row {
				background: #FAFAFA;
				&:nth-child(even) {
					background: #fff !important;
				}
				&:not(.dx-freespace-row) {
					& > td:first-child {
						${onRowClick ? 'padding-left: 18px;' : ''}
						&:before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							height: calc(100% - 1px);
							width: 8px;
							background: #cdcdcd;
							z-index: 10;
							display: ${onRowClick ? 'block' : 'none'};
						}
					}
					&.dx-row-focused > td:first-child:before {
						background: #3e4c57;
					}
				}
			}
		}

		.dx-datagrid-headers .dx-header-row > td {
			background: #565656;
		}

		.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
		.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
		.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
			background-color: #fff;
			color: black;
			font-weight: 400;
		}
		.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > td,
		.dx-datagrid-rowsview .dx-row-focused.dx-data-row:not(.dx-row-lines) > tr:first-child > td {
			border: none;
		}

		&-total-footer {
			border-top: none;
			box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
			& > .dx-datagrid-content {
				padding-top: 0;
				padding-bottom: 0;
				.dx-row > td {
					color: #fff;
					background: #3cb950;
				}
			}
			.dx-datagrid-summary-item {
				color: #fff;
				font-weight: 400;
			}
		}

		&-group-panel {
			border: 1px dashed #000;
			padding: 4px;
			.dx-group-panel-item, .dx-group-panel-message, .dx-block-separator {
				padding: 3px;
			}
		}

		.dx-column-indicators {
			display: none;
		}

	}


	.dx-checkbox {
		&-checked .dx-checkbox-icon {
			border-color: #3e4c57;
		}
		&-icon {
			color: #3e4c57;
		}
	}

	.dx-datagrid-search-panel {
		height: 39px;
		border: 1px solid #CDCDCD;
		border-radius: 20px;
	}

	.dx-texteditor {
		width: 100% !important;
		float: right;
	}

	.dx-texteditor.dx-editor-outlined {
		min-width: 337px;
		height: 39px;
		border: 1px solid #CDCDCD;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #afafaf !important;
		border-radius: 20px;
	}

	.dx-widget input, .dx-widget textarea {
		font-family: 'Montserrat', sans-serif;
	}

	.dx-placeholder {
		font-family: 'Montserrat', sans-serif;
	}

	.dx-searchbox .dx-icon-search {
		right: 5px;
		top: 53%;
	}

	.dx-searchbox .dx-placeholder:before, .dx-searchbox .dx-texteditor-input {
		padding-left: 20px;
	}

	div.dx-datagrid-search-panel:not(.dx-texteditor-empty) {
		& input {
			padding-right: 40px;
		}
	}

	.dx-show-clear-button .dx-icon-clear {
		padding-left: 0;
	}

	.dx-toolbar-items-container .dx-toolbar-before {
		padding-top: 6px !important;
	}

	.dx-toolbar-items-container .dx-toolbar-before:after {
		content: "${label}";
		color: ${labelColor || 'inherit'};
	}

	.dx-freespace-row {
		background-color: rgb(238,238,238) !important;
	}
`);

export const CellWarning = styled.div`
	background: ${({ theme }) => theme.errorColor};
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: ${CELL_VERITCAL_PADDING} ${CELL_HORIZONTAL_PADDING};
	overflow: hidden;
	text-overflow: ellipsis;
`;