import styled from 'styled-components';

export const Col = styled.div`
  width: ${({ lg, width }) => {
    switch(lg) {
      case 1:
        return '90px';
      case 2:
        return '160px';
      case 3:
        return '210px';
      case 4:
        return '360px';
      case 5:
        return '210px';
      default:
        return width;
    }
  }};
  display: flex;
  flex-direction: column;

`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
`;

// for TableGrid
export const Table = styled.div`
  margin-right: 20px;
`;

export const OptionsContent = styled.div`
padding: 20px 20px 20px 20px; 
background: #f4f4f4;
min-height: 805px;
`;

