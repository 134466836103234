import gql from 'graphql-tag';

export default gql`
mutation createFA_AusnahmeTag($fields: inputFA_AusnahmeTag!){
    fakturaAssist{
		createFA_AusnahmeTag(fields: $fields){
		id
    }
    
  }
}`;
