import React from 'react';
import styled from 'styled-components';
import { Input } from 'components/elements';
import Formik from 'components/formik';

import { Row, Col } from 'style';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
      linkId={props.linkId}
      sectionInfo={props.sectionInfo}
    >
      {({ values }) => {
        return (
          <MainBlock>
            <Row>
              <Col lg={4}>
                <Input value={values.weight} type='number' field='weight' title={`${t('common.weight')} (g)`}/>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Input value={values.orderPeriodInDays} type='number' field='orderPeriodInDays' title={t('product.orderDeadlineInDays')}/>
              </Col>
            </Row>
          </MainBlock>
        );
      }}
    </Formik>
  );
};

const MainBlock = styled(Col)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
