import React from 'react';
import { OptionsContent } from 'style';

import { Checkbox, Input } from 'components/elements';
import Formik from 'components/formik';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() =>{}}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <OptionsContent>
            <>
              <Checkbox title={t('options.connections.paynet.activateService')} margin = '10px 0 0 0' />
              <Input title = {t('options.connections.paynet.connection')}/>
              <Input title = {t('options.connections.paynet.paynetPID')}/>
              <Input title = {t('options.connections.paynet.login')}/>
              <Input title = {t('options.connections.password')} />
            </>
          </OptionsContent >
        );
      }}
    </Formik>
  );
};
