import React from 'react';
import styled from 'styled-components';

import { Textarea } from 'components/elements';

import triangle from '../../../style/img/triangle_copy_4.svg';

const AdditionalTextarea = (props) => {
  const { arrowUp, name, onAdditionTextareaArrowClick, onChangeInput, localStateRef, productName, handleChange } = props;

  const makePayload = (getHTML, getText) => {
    const e = {
      target: {
        value: getText().trim() === productName ? null : getHTML?.(),
        name: name,
      },
    };
    return e;
  };

  return (
    <SpecialTextarea>
      <ArrowBlock onClick={() => onAdditionTextareaArrowClick(name)}> <Img src={triangle} arrowUp={arrowUp} /></ArrowBlock>
      <Textarea
        {...props}
        onChange={(...p) => {
          const { getHTML, getText } = p[p.length - 1];
          if (localStateRef?.current) {
            localStateRef.current[name] = makePayload(getHTML, getText);
          }
          if (handleChange) {
            handleChange(makePayload(getHTML, getText));
          }
        }}
        onBlur={(_, s, quill) => {
          if (onChangeInput) {
            onChangeInput(makePayload(quill.getHTML, quill.getText));
          }
        }}
        width='410px'
        height={190}
        withoutFormik
        bold
        editorStyles={['bold']}
      />
    </SpecialTextarea>
  );
};

export default AdditionalTextarea;

const SpecialTextarea = styled.div`
  position: relative;
`;

export const ArrowBlock = styled.div`
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(90,90,90,0.12);
  background: #fff;
  float: right;
  padding: 4px 10px;
  margin: 20px 32px 0 0;
  cursor: pointer;
`;

const Img = styled.img`
    transform: ${(props) => props.arrowUp ? 'rotateX(180deg)' : null} ;
`;
