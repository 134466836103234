import gql from 'graphql-tag';

export const GROUP = gql`
query Group {
  fakturaAssist {
    listFA_ProductGroup1 {
      productGroup
    }
  }
}`;

export const GROUP2 = gql`
query Group2 {
  fakturaAssist {
    listFA_ProductGroup2 {
      id
      name
      number
    }
  }
}`;

export const GROUP3 = gql`
query Group3 {
  fakturaAssist {
    listFA_ProductGroup3 {
      id
      name
      number
    }
  }
}`;

