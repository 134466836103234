/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const Image = (props) => {
  const { srcImage, value, width, height } = props;
  return (
    <Row>
      <Col>
        {/* <StyledTextTitle>Picture</StyledTextTitle>
        <StyledOptions>
          <StyledSpan>delete</StyledSpan>
          <StyledSpan>save</StyledSpan>
        </StyledOptions> */}
        <StyledImg >
          <img src={srcImage} alt='Img not found' style={{ width: width || '100%', height: '180px' }}/>
        </StyledImg>
        {/* {value ? <StyledTextBtm>Set image from rezeptassist</StyledTextBtm> : null} */}
      </Col>
    </Row>
  );
};

export default Image;

const StyledTextTitle = styled.span`
    text-align: left;
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #686868;
`;

const StyledOptions = styled.span`
    margin: 30px 0 0 53px;
    position: absolute;
`;

const StyledSpan = styled.span`
    margin: 0 25px 0 0;
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark-rose);
`;

const StyledImg = styled.div`
    /* margin: 5px 0 10px 0; */
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 180px;
    opacity: 0.55;
    border-radius: 5px;
    /* box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12); */
    /* background-color: #000000; */
    overflow: hidden;
    border: 1px solid #AFAFAF;
`;

const StyledTextBtm = styled.p`
    cursor: pointer;
    margin: auto;
    text-decoration: underline;
    text-transform: uppercase;
    /* font-family: Roboto; */
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #40485d;
`;
