import React from 'react';
import { Transition } from 'components/elements';

import Days from './days';
import Period from './period/index';
import BreakOff from './breakOff/index';
import Assortment from './assortment/index';
import { useTranslation } from 'react-i18next';

export default ({ id, props }) => {
  const [t] = useTranslation();
  return (
    <>
      <Transition index={0} text={t('common.deliveryPeriods')} isWhite>
        <Transition index={1} text={t('common.chooseDays')}>
          <Days {...props}/>
        </Transition>
        <Transition index={2} text={t('common.period')}>
          <Period {...props} id = {id}/>
        </Transition>
        <Transition index={3} text={t('customer.breakOff')}>
          <BreakOff {...props} id = {id}/>
        </Transition>
        {/* <Transition index={2} text={t('common.period')}>
          <Period {...props}/>
        </Transition>
        <Transition index={4} text={t('customer.breakOff')}>
          <BreakOff {...props}/>
        </Transition>
      </Transition>
      <Transition index={5} text={t('common.assortmentGroups')} isWhite>
        <AssortmentGroups {...props}/>
      </Transition> */}
      </Transition>

      <Transition index={4} text={t('common.assortmentGroups')} isWhite>
        <Assortment {...props} id = {id}/>
      </Transition>

      {/* <MainText>
        {t('common.deliveryPeriods')}
      </MainText>
      <Line marginTop={'16px'} marginBottom={'20px'}/>
      <Transition index={0} text={t('common.chooseDays')} whiteTheme={true} backColorLine={'rgba(195, 195, 195, 0.2)'}>
        <CustomerPrice id={id}/>
      </Transition>
      <Transition index={1} text={t('common.period')} whiteTheme={true} backColorLine={'rgba(195, 195, 195, 0.2)'}>
        <Period id={id}/>
         <Period id={id} props={props}/>
      </Transition> */}
    </>
  );
};
