import gql from 'graphql-tag';

export default gql`
mutation updateFA_Suppliers($id: ID!, $fields: FA_SuppliersFields!){
    fakturaAssist{
        updateFA_Suppliers(id: $id, fields: $fields){
		    id
        }
  }
}`;
