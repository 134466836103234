import gql from 'graphql-tag';

export default gql`
mutation createFA_DeliveryPeriods($fields:fieldsFA_DeliveryPeriods){
    fakturaAssist{
    createFA_DeliveryPeriods(fields: $fields){
		id
    }
    
  }
}`;
