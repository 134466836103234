import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query listFA_DefaultForms{
    fakturaAssist{
    	listFA_DefaultForms{
			id
			reportGroupID
			copies
			printerTray
			printer
			special
			name
	 	}
 	}
}`;

