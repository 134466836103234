import gql from 'graphql-tag';

const FILTER_QUERY = gql`
    query  filterQuery($languageId: Int){
        fakturaAssist {
            listFA_CustomerFormulareFacturaAdchnung(languageId: $languageId) {
                id
                name
            }
            getFA_Config {
                orderGroup
            }
        }
    }
`;

export default FILTER_QUERY;
