import gql from 'graphql-tag';

export default gql`
query getFA_ProductionEditCrossTable($id: ID!){
    fakturaAssist{
    getFA_ProductionEditCrossTable(id: $id){
		id
		categoryName
		categoryLinkCustomer
		categoryLinkCustomerList
		categoryFilterDeliveryType
		categoryFilterSplitNo
		categoryFilterCustomer
		categoryCustomFilterDelivery
		
    }
    
  }
}`;
