import gql from 'graphql-tag';

export const listFA_ProductProduction = gql`
query listFA_ProductProduction($id: Int) {
  fakturaAssist {
    listFA_ProductProduction(id: $id){
        id
        articleNm
        description
        multiplier
        isFermentationInterrupt
        linkArticle
        addedArticle
    }
  }
}`;

export const getFA_ProductProductionCalc = gql`
query getFA_ProductProductionCalc($id: Int, $params: ListQueryArgs) {
    fakturaAssist {
      getFA_ProductProductionCalc(id: $id, params: $params){
        id
        productNumber
        name
        doughInsert
        concatenatedField
      }
    }
  }`;

export const deleteFA_ProductProductionCalc = gql`
    mutation deleteFA_ProductProductionCalc($id: Int!) {
      fakturaAssist {
        deleteFA_ProductProductionCalc(id: $id) {
          id
          linkArticle
        }
      }
    }
  `;

export const updateFA_ProductProductionCalc = gql`
mutation updateFA_ProductProductionCalc($idCalc: Int!, $multiplier: Float!, $addedArticle: Int!) {
    fakturaAssist {
        updateFA_ProductProductionCalc(idCalc: $idCalc, multiplier: $multiplier, addedArticle: $addedArticle){
          id
          articleNm
          description
          multiplier
          isFermentationInterrupt
          linkArticle
          addedArticle
        }
    }
}
`;

export const createFA_ProductProductionCalc = gql`
mutation createFA_ProductProductionCalc($id: Int!, $multiplier: Float!, $addedArticle: Int!) {
    fakturaAssist {
      createFA_ProductProductionCalc(id: $id, multiplier: $multiplier, addedArticle: $addedArticle){
          id
          articleNm
          description
          multiplier
          isFermentationInterrupt
          linkArticle
          addedArticle
        }
    }
}
`;
