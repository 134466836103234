import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';
import { compose, branch } from 'recompose';
import PropTypes from 'prop-types';
import { Text } from 'components/elements';

import { withFieldSubmit } from '../../formik';

const Index = (props) => {
  const { value, onChangeFormik, submit, specialFunc, withoutFormik, onClick, name, onChange, title, textOn, textOff } = props;

  return (
    <MainBlock
      {...props}

      margin={props.margin}
      marginBottom={props.marginBottom}>
      {title &&
          <Row>
            <Col>
              <Text marginBottom='7px' fontSize='14px' text={title}/>
            </Col>
          </Row>
      }
      <Row>
        <Col>
          <Text marginTop='2px' color={value ? '#3cb950' : '#3c445a'} opacity={value ? '1' : '0.6'} fontSize='14px' text={textOn ? textOn : 'On'}/>
        </Col>
        <Col style={{ margin: '0 10px 0 10px' }}>
          <Toggle
            open={value}
            onClick={!onClick ? !specialFunc && !withoutFormik ? () => {
              onChangeFormik(!value);
              submit(!value);
            } : () => onChange(name, !value) : () => {
              onClick(!value);
            }}>
            <Circle open={value}/>
          </Toggle>
        </Col>
        <Col>
          <Text marginTop='2px' color={value ? '#3c445a' : '#c13c44'} opacity={value ? '0.6' : '1'} fontSize='14px' text={textOff ? textOff : 'Off'}/>
        </Col>
      </Row>
    </MainBlock>
  );
};

export default compose(
  branch(
    (props) => {
      return(!props.withoutFormik);
    },
    withFieldSubmit
  ))(Index);

const MainBlock = styled.div`
  /* font-family: Roboto-Regular}; */
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4a4a4a;
  margin: ${(props) => props.margin ? props.margin : '10px 0px 0px 0px'};
  z-index: ${(props) => props.zIndex ? props.zIndex : '1'};;
  &:hover {
    cursor: pointer
  }
`;

const Toggle = styled.div`
  padding: 1px;
  width: 45px;
  height: 21px;
  border-radius: 10.5px;
  border: solid 1px #3e4c57;
  background-color: white;
`;

const Circle = styled.div`
  float: ${(props) => props.open ? 'left' : 'right'};
  width: 17px;
  height: 17px;
  border-radius: 10px;
  background-color: #3c445a;
`;

Index.propTypes = {
  value: PropTypes.bool.isRequired,
  title: PropTypes.string,
  withoutFormik: PropTypes.bool,
};

/*
  Example
    <Checkbox
      value={true}
      title='Checkbox'
    />
*/
