
export const tableData = ({ totalSum, t }) => ([
  {
    name: t('common.productNr'),
    dataBase: 'productNr',
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
    additionTable: true,
    prevText: 'Kunden Nr: ',
  },

  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
  },

  {
    name: t('common.quantity'),
    dataBase: 'quantity',
    total: totalSum,
    additionTable: true,

  },

  {
    name: t('common.description'),
    dataBase: 'nameRes',
  },

  {
    name: t('common.name'),
    dataBase: 'name',
  },

  {
    name: t('common.deliveryNote'),
    dataBase: 'indTextDeliveryNote',
  },

  {
    name: t('common.productList'),
    dataBase: 'indTextProduction',
    
  },

  {
    name: t('common.date'),
    dataBase: 'date',
    dataType: 'date',
  },
  {
    name: t('common.deliveryNr'),
    dataBase: 'orderNr',
  },
  {
    name: t('common.price'),
    dataBase: 'price',
    dataType: 'float',
  },
  {
    name: t('common.discount'),
    dataBase: 'discount',
    dataType: 'percent',
  },
]);
