import React, { useEffect, useState, useRef, useMemo } from 'react';
import moment from 'moment';
import { useMutation } from 'components/graphql';
import { useResettableMutation } from 'apollo-hooks-extended';
import { compose } from 'recompose';
import { cloneDeep, isEqual } from 'lodash';

import { readQuery, writeQuery } from 'helpers/queryOperations';

import Items from 'components/List/Items';
import Addition from 'components/List/Additional';
import { Modal } from 'components/elements';
import Query from 'components/graphql/Query';
//import withParams from 'components/withParams/withParams';
import withPDFActions from 'components/viewPDF/withPDFActions';
import Filter from 'components/elements/Filter/Filter';

import reduce from 'helpers/reduceGraphqlData';
import { Main } from './pages';
import { WarningModal } from './modals';

import {
  listFA_OfferCustomersList,
  listFA_GetOfferProducts,
} from './qql/query';
import { callFA_CreateOffer, callFA_OfferActions, deleteFA_TemporaryOffer } from './qql/mutation';
import { InfoCustomer } from './modals';

import { tableData, tableDataExtended } from './params';
import useFilterInfo from 'main/order/createOrder/hooks/useFilterInfo';
import useTemporaryOffer from './hooks/useTemporaryOffer';
// import useOrderTypes from '../order/createOrder/hooks/useOrderTypes';

import callFA_EditOffer from './qql/mutation/callFA_EditOffer';

const OfferCreate = (props) => {
  const {
    client,
    showPDFPreview,
    showFormsType,
    linkId,
    contex,
    writeContex,
    mainsection,
    location,
    t,
    data,
  } = props;
  //Use in offer date
  // console.log({ props })
  //Modals state
  const [isWarningModalStatus, changeWarningModalStatus] = useState(false);
  const [isOpenCustomerInfoModal, setOpenCustomerInfoModal] = useState(false);
  //const [isCustomerInfoModalStatus, changeCustomerInfoModalStatus] = useState(false);

  //Filter state
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [confirmedFilterInfo, setConfirmedFilterInfo] = useState(() => {
    const storageFilter = localStorage.getItem(mainsection);
    const parsedFilter = JSON.parse(storageFilter) || [];
    return parsedFilter;
  });

  //Customer info. UseRef in guture
  const [chooseCustomerIndex, changeChooseCustomerIndex] = useState(0);
  const [chooseCustomerInfo, changeChooseCustomerInfo] = useState({});
  const [initialOfferInfo, setOfferInfo] = useState({
    linkId: linkId,
    textareaValue: '',
  });

  const localDataRef = useRef(null);
  const refetchRef = useRef(null);

  //Fix for problem with moving search settings from another subsystem
  delete contex.params.search;

  //Mutations
  const [createOffer, { reset, called }] = useResettableMutation(callFA_CreateOffer, { client });
  const [editOffer, { loading: editOfferLoading }] = useMutation(callFA_EditOffer);
  const [offerActions] = useMutation(callFA_OfferActions);

  // Customer info from left list according to id.
  const [dateOn, setDateOn] = useState(
    (contex.date?.date)
  ); //This is the date we selected for the left grid
  const [offerForDate, setOfferForDate] = useState(
    (chooseCustomerInfo?.offerID ? chooseCustomerInfo?.fromDate : contex.date?.date)
  );
  const [lastValidDate, setLastValidDate] = useState(
    (chooseCustomerInfo?.offerID ? chooseCustomerInfo?.toDate : moment(contex.date?.date).add(-1, 'days').format('YYYY-MM-DD'))
  );

  useEffect(() => {
    setOfferForDate(chooseCustomerInfo?.offerID ? chooseCustomerInfo?.fromDate : contex.date?.date);
    setLastValidDate(chooseCustomerInfo?.offerID ? chooseCustomerInfo?.toDate : moment(contex.date?.date).add(-1, 'days').format('YYYY-MM-DD'));
  }, [chooseCustomerInfo]);

  useEffect(() => {
    setDateOn(contex.date?.date);
  }, [contex.date?.date]);

  const { id, offerID, accepted, declined, temporaryId } = chooseCustomerInfo;
  const variables = {
    args: {
      date: dateOn,
      id: linkId,
      temporaryId: chooseCustomerInfo ? +temporaryId : 0,
      offerId: chooseCustomerInfo ? +offerID : 0,
      // params: { offset: 0, limit: null, sort: { field: 'productName', order: 'ASC' } },
    },

  };

  // Temp offer
  const readQueryProductsParams = { client, variables, query: listFA_GetOfferProducts };
  // const writeQueryProductsParams = { client, query: listFA_GetOfferProducts, variables, queryValue: 'listFA_GetOfferProducts' };
  const [networkStatus, setNetworkStatus] = useState(null);
  const [prevVariables, setPrevVariables] = useState(null);
  const [prevCustomerInfo, setPrevCustomerInfo] = useState({ index: null, info: null });
  const leftGridData = prevCustomerInfo.info?.id ? data?.some(async (item) => item.id === prevCustomerInfo.info?.id) : true;
  const [deleteTempOfferMutation] = useMutation(deleteFA_TemporaryOffer, {
    variables: { id: variables.args?.temporaryId },
  });

  const { memoryProductData, checkTemporaryOffer, setMemoryProductData } = useTemporaryOffer({
    ...props,
    chooseCustomerInfo,
    chooseCustomerIndex,
    listFA_OfferCustomersList,
    readQueryProductsParams,
    client,
    contex,
    variables,
    prevVariables,
    prevCustomerInfo,
  });

  //Filter
  // console.log({ contex })
  const setFilterInfo = (filter) => {
    const startDateIdx = filter.findIndex((item) => item.column === 'date');
    const endDateIdx = filter.findIndex((item) => item.column === 'secondDate');
    let payload = { ...contex, filter: [...filter] };
    if (startDateIdx > -1 && endDateIdx > -1) {
      payload = {
        ...payload,
        date: { date: filter[startDateIdx].value, secondDate: filter[endDateIdx].value },
      };
    } else {
      payload = { ...payload, date: { ...contex.date, secondDate: contex?.date?.date } };
    }
    const currentFolder = filter?.find((item) => !(['date', 'secondDate'].includes(item.column)));
    if (!(['acceptedOffers', 'declinedOffers'].includes(currentFolder.column))) {
      payload = { ...payload, filter: filter.filter((_, idx) => !([startDateIdx, endDateIdx].includes(idx))) };
    }
    payload = {
      ...payload,
      filter: [currentFolder, ...payload.filter.filter((item) => item.column !== currentFolder.column)],
    };
    writeContex(payload);
  };

  const pdfParams = {
    reportId: '38',
    AOfferID: +offerID,
    AUserFormularID: '0',
  };

  const getTableIndex = (index, item) => {
    changeChooseCustomerInfo(item);
    changeChooseCustomerIndex(index);
    props.history.replace({
      pathname: `/offer/create/${item.id}`,
    });
  };

  // const getBlockColor = ({ temporaryId, offerID, declined, accepted }) => {
  //   if (declined) {
  //     return 'red';
  //   } else if (accepted) {
  //     return 'rgb(80, 232, 30)';
  //   } else if (temporaryId) {
  //     return '#c1c1e6';
  //   } else if (offerID) {
  //     return '#fffed3';
  //   } else {
  //     return null;
  //   }
  // };

  const getProductsFromCache = () => {
    try {
      const productsFromCache = client.cache.readQuery({
        query: listFA_GetOfferProducts,
        variables: variables,
      });
      return reduce(productsFromCache);
    } catch (err) {
      console.log(err);
    }
  };

  const createNewOffer = async (values) => {
    //Local variables of query
    try {
      const productsFromCache = getProductsFromCache();
      const productsForOrder = productsFromCache.productTable
        .filter(({ quantity }) => quantity)
        .map((item) => {
          const {
            virtualPositionLink,
            productLink,
            quantity,
            price,
            discount,
            taxCode,
            indTextProduction,
            indTextDeliveryNote,
          } = item;
          return {
            virtualPositionLink: virtualPositionLink,
            productLink: productLink,
            quantity: +quantity,
            price: +price,
            discount: +discount,
            taxCode: +taxCode,
            indTextProduction: indTextProduction,
            indTextDeliveryNote: indTextDeliveryNote,
          };
        });
      //If order havent quantity or total of all fields
      if (!productsForOrder.length) {
        changeWarningModalStatus(true);
      } else {
        //changeLoadingModalStatus(true);
        const dataForOrder = {
          userId: 774,
          fromDate: offerForDate,
          toDate: lastValidDate,
          customerId: id,
          productList: productsForOrder,
        };
        try {
          // const createdOfferInfo = await createOffer({
          //   variables: { args: dataForOrder },
          // });

          // const {
          //   offerID,
          // } = createdOfferInfo.data.fakturaAssist.callFA_CreateOffer;
          // const offerListFromCache = readQuery(
          //   { client, query: listFA_OfferCustomersList, variables: contex },
          //   true
          // ).fakturaAssist.listFA_OfferCustomersList;
          // const copyOfferListFromCache = cloneDeep(offerListFromCache);
          // copyOfferListFromCache[chooseCustomerIndex] = {
          //   ...chooseCustomerInfo,
          //   offerID: offerID,
          // };
          // writeQuery({
          //   client,
          //   query: listFA_OfferCustomersList,
          //   variables: contex,
          //   changedData: copyOfferListFromCache,
          // });
          if(variables.args?.temporaryId) {
            deleteTempOfferMutation();
          }

          await createOffer({
            variables: { args: dataForOrder },
          });

          refetchRef.current();
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editFA_Offer = async () => {
    try {
      if (editOfferLoading) return;
      const productsFromCache = getProductsFromCache();
      const productsForOrder = productsFromCache.productTable
        .filter(({ quantity }) => quantity)
        .map((item) => {
          const {
            virtualPositionLink,
            productLink,
            quantity,
            price,
            discount,
            taxCode,
            indTextProduction,
            indTextDeliveryNote,
          } = item;
          return {
            virtualPositionLink: virtualPositionLink,
            productLink: productLink,
            quantity: +quantity,
            price: +price,
            discount: +discount,
            taxCode: +taxCode,
            indTextProduction: indTextProduction,
            indTextDeliveryNote: indTextDeliveryNote,
          };
        });
      if (!productsForOrder.length) {
        changeWarningModalStatus(true);
      } else {
        const editOfferData = {
          userId: 774,
          fromDate: offerForDate,
          toDate: lastValidDate,
          offerId: chooseCustomerInfo.offerID,
          productList: productsForOrder,
        };
        try {
          // console.log({ editOfferData })
          const editOfferInfo = await editOffer({
            variables: { args: editOfferData },
          });

          const {
            errorCode,
            errorText,
          } = editOfferInfo.data.fakturaAssist.callFA_EditOffer;

          if (!errorCode) {
            // const offerListFromCache = readQuery(
            //   { client, query: listFA_OfferCustomersList, variables: contex },
            //   true
            // ).fakturaAssist.listFA_OfferCustomersList;
            // const copyOfferListFromCache = cloneDeep(offerListFromCache);

            // if (contex.date?.date !== offerForDate) {
            //   copyOfferListFromCache.splice(chooseCustomerIndex, 1);
            // } else {
            //   copyOfferListFromCache[chooseCustomerIndex] = { ...chooseCustomerInfo, fromDate: offerForDate, toDate: lastValidDate, declined: declined, accepted: accepted };
            // }
            // writeQuery({
            //   client,
            //   query: listFA_OfferCustomersList,
            //   variables: contex,
            //   changedData: copyOfferListFromCache,
            // });
            refetchRef.current();
          } else {
            throw new Error(`${errorCode} - ${errorText}`);
          }
        } catch (err) {
          console.log(err);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const callOfferActions = async (action) => {
    try {
      const sendData = {
        args: {
          offerID: offerID,
          action: action,
        },
      };
      const cacheData = readQuery(
        { client, query: listFA_OfferCustomersList, variables: contex },
        true
      ).fakturaAssist.listFA_OfferCustomersList;
      const copyCacheData = cloneDeep(cacheData);
      const actualCustomer = { ...chooseCustomerInfo };
      if (action === 'accept') {
        actualCustomer.accepted = true;
      } else if (action === 'decline') {
        actualCustomer.declined = true;
      }

      copyCacheData[chooseCustomerIndex] = actualCustomer;

      changeChooseCustomerInfo(actualCustomer);
      await offerActions({ variables: sendData }).then(() => {
        writeQuery({
          client,
          query: listFA_OfferCustomersList,
          variables: contex,
          changedData: copyCacheData,
        });
      }).catch((err) => { });
    } catch (err) {
      console.log(err);
    }
  };

  const { setOrderDateContex } = useFilterInfo({ ...props, contex, writeContex, linkId });

  const onCompleted = (data) => {
    if (data) {
      try {
        const queryData = cloneDeep(data.fakturaAssist.listFA_GetOfferProducts);
        setMemoryProductData(cloneDeep(queryData.productTable || []));
        try {
          client.cache.writeQuery({
            query: listFA_GetOfferProducts,
            variables: variables,
            data: {
              fakturaAssist: {
                listFA_GetOfferProducts: queryData,
                __typename: 'FA_Query',
              },
            },
          });
          //Change customer position
        } catch (err) {
          //console.log(err)
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const initialDatesForTemp = useOrderTypes({ ...props, readQueryProductsParams, contex, writeQueryProductsParams, withoutCache: true, productTable: memoryProductData });

  // Temp offer useEffect
  useEffect(() => {
    // console.log('Offer: ', { leftGridData,  memoryProductData, networkStatus, prevCustomerInfo, data });
    if(leftGridData && memoryProductData && (networkStatus === 7) && (prevCustomerInfo.info?.offerID ? prevCustomerInfo.info?.offerID === 0 : true)) {
      checkTemporaryOffer(prevVariables, called, reset);
    } else {
      reset();
    }
    setPrevVariables(variables);
    setPrevCustomerInfo({ index: chooseCustomerIndex, info: chooseCustomerInfo });
  }, [linkId, location.pathname]);

  // for openOffers, acceptedOffers, declinedOffers filter column we show extended table grid
  const tableDataInfo = useMemo(() => {
    const { filter = [] } = contex;
    const activateExtended = filter.some(({ column }) => ['openOffers', 'acceptedOffers', 'declinedOffers'].includes(column));
    return activateExtended ? { data: tableDataExtended(t), type: 'extended' } : { data: tableData(t), type: 'base' };
  }, [JSON.stringify(contex)]);

  const [isLeftGridEmpty, setIsLeftGridEmpty] = useState(true);
  const onDetermineIsGridEmpty = (value) => {
    setIsLeftGridEmpty(value);
  };

  return (
    <>
      <Modal
        isOpen={isOpenCustomerInfoModal}
        onAfterClose={() => setOpenCustomerInfoModal(false)}
        closeModal={() => setOpenCustomerInfoModal(false)}
        chooseCustomerInfo={{ ...chooseCustomerInfo }}
        headerText={t('order.modals.information')}
        width='700px'
        height='500px'
        Component={
          <InfoCustomer
            t={t}
            closeModal={() => setOpenCustomerInfoModal(false)}
            id={linkId}
            setOfferInfo={setOfferInfo}
            initialOfferInfo={initialOfferInfo}
          />
        }
      />
      <Modal
        isOpen={isWarningModalStatus}
        closeModal={() => changeWarningModalStatus(false)}
        headerText={t('common.warning')}
        width='350px'
        height='220px'
        Component={
          <WarningModal
            t={t}
            closeModal={() => changeWarningModalStatus(false)}
          />
        }
      />
      <Query
        skip={!linkId}
        query={listFA_GetOfferProducts}
        variables={variables}
        fetchPolicy={'network-only'}
        onCompleted={onCompleted}
      >
        {({ data, client, loading, networkStatus: status, ...prev }) => {
          const productTable = data ? data.productTable : [];
          setNetworkStatus(status);
          return (
            <>
              <Items
                {...props}
                {...props.sectionInfo}
                client={client}
                withSearch={true}
                contex={contex}
                mainsection={mainsection}
                onDetermineIsGridEmpty={onDetermineIsGridEmpty}
                rightGridLoading={loading}
                specialSortKey={tableDataInfo.type === 'extended' ? 'offerNr' : 'customerNr'}
                name={t('offer.offer')}
                graphqlParams={{
                  query: listFA_OfferCustomersList,
                  variables: contex,
                  onCompleted: (data) => {
                    changeChooseCustomerInfo(data.fakturaAssist.listFA_OfferCustomersList[chooseCustomerIndex] || {});
                  },
                }}
                queryCb={({ data, refetch }) => {
                  if (!isEqual(data, localDataRef.current)) {
                    localDataRef.current = data;
                    writeQuery({
                      client,
                      query: listFA_OfferCustomersList,
                      variables: props.contex,
                      changedData: cloneDeep(data),
                    });
                    refetchRef.current = refetch;
                  }
                }}
                tableData={tableDataInfo.data}
                data={null}
                leftGridData={props.data}
                // getBlockColor={getBlockColor}
                getTableIndex={getTableIndex}
                linkId={+linkId}
                getValueOnStartById={getTableIndex}
                operations={() => ({ info: () => setOpenCustomerInfoModal(true) })}
                FilterComponent={
                  <Filter
                    t={t}
                    mainsection={mainsection}
                    setFilterInfo={setFilterInfo}
                    filterData={props.subSectionInfo.filterData}
                    filterInfo={contex.filter}
                    isOpenFilter={isOpenFilter}
                    setOpenFilter={setOpenFilter}
                    confirmedFilterInfo={confirmedFilterInfo}
                    setConfirmedFilterInfo={setConfirmedFilterInfo}
                  />
                }
                isOpenFilter={isOpenFilter}
                setOpenFilter={setOpenFilter}
                selectById
                infiniteLoader
                withVirtualized
                calendarDate={contex?.date?.date}
                setCalendarDate={setOrderDateContex}
                cutHeight={303}
                {...(tableDataInfo.type === 'extended' && { width: 500 })}
                specialWidth='100%'
              />
              {/* don't show right part when left grid is empty */
                (!isLeftGridEmpty) && <Addition
                  pdfParams={pdfParams}
                  customActions={true}
                  reportForms={[
                    37,
                    38,
                    48,
                  ]}
                  menuObj={[
                    { link: '/offer/create', name: chooseCustomerInfo.offerID ? t('offer.offerEdit') : t('offer.offerСreate') },
                  ]}
                  t={t}
                  value={t('common.actions')}
                  actions={typeof (chooseCustomerInfo.accepted) === 'boolean' ? {
                    preview: (actualPDFParams) => {
                      offerID &&
                        showPDFPreview((actualPDFParams && { ...actualPDFParams, reportId: 'orderPreview' }) || { ...pdfParams, reportId: 'orderPreview' });
                    },

                    reportForms: () => {
                      showFormsType(pdfParams, [
                        37,
                        38,
                        48,
                      ]);
                    },
                    history: 'default',
                  } :
                    { history: 'default' }}
                  linkId={offerID}
                  loading={loading}
                  text={chooseCustomerInfo.nameRes || '_'}
                  isShow={linkId && !loading}
                  historyFormTypeID={props.sectionInfo.historyFormTypeID}
                  noShowText='No offer'
                >
                  <Main
                    {...props}
                    dataPolicy= {'local'}
                    data={data}
                    isOpenCustomerInfoModal={isOpenCustomerInfoModal}
                    chooseCustomerInfo={chooseCustomerInfo}
                    offerForDate={offerForDate}
                    lastValidDate={lastValidDate}
                    date={dateOn}
                    linkId={id}
                    setOfferForDate={setOfferForDate}
                    setLastValidDate={setLastValidDate}
                    t={t}
                    productTable={productTable}
                    productData={{
                      data: data,
                      client: client,
                      query: listFA_GetOfferProducts,
                      variables: variables,
                    }}
                    localSortKey={'id'}
                    // specialSortKey={'productName'}
                    specialTableSearch
                    createNewOffer={createNewOffer}
                    editFA_Offer={editFA_Offer}
                    edit
                    offerID={offerID}
                    callOfferActions={callOfferActions}
                    accepted={accepted}
                    declined={declined}
                    //currentDay={currentDay}
                    editOfferLoading={editOfferLoading}
                    {...chooseCustomerInfo}
                    {...prev}
                  />
                </Addition>
              }
            </>
          );
        }}
      </Query>
    </>
  );
};

export default compose(withPDFActions)(OfferCreate);

