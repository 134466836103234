import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';
import moment from 'moment';

import { Calendar, Button, Toggle } from 'components/elements';

// "invoice": "Invoice",
//         "newInvoice": "New invoice",
//         "createdInvoice": "Created invoice",
//         "ordersFrom": "Orders from",
//         "ordersTill": "Orders till",
//         "invoiceDate": "Invoice date",
//         "createInvoice": "Faktura erstellen"

const CalendarSection = (props) => {
  const {
    contex,
    writeContex,
    orderId,
    date,
    secondDate,
    values,
    createInvoice,
    orderSelectingMode,
    setOrderSelectiongMode,
    t,
    checkBoxList,
  } = props;
  const [invoiceDate, setInvoiceDate] = useState(moment(new Date()).add(1, 'days').format('YYYY-MM-DD'));

  return (
    <>
      <Row>
        <Col width='230px'>
          <ToggleBlock>
            <Toggle
              withoutFormik
              title={t('invoice.ordersSelectingMode')}
              onClick={() => setOrderSelectiongMode(!orderSelectingMode)}
              value={orderSelectingMode}
              textOn={t('invoice.selectingModeDate')}
              textOff={t('invoice.selectingModeManually')}
            />
          </ToggleBlock>
        </Col>
        <Col lg={2}>
          <Calendar
            field='date'
            title={t('invoice.ordersFrom')}
            value={date}
            onChange={(value)=>{
              writeContex({ ...contex, date: { ...contex.date, date: value } });
            }}
            withoutFormik
          />
        </Col>
        <Col width='200px'>
          <Calendar
            field='secondDate'
            title={t('invoice.ordersTill')}
            value={secondDate}
            onChange={(value)=>{
              writeContex({ ...contex, date: { ...contex.date, secondDate: value } });
            }}
            withoutFormik
          />
        </Col>
        <Col lg={2}>
          <Calendar
            onChange={(value) => {
              setInvoiceDate(value);
            }}
            noActive ={!orderId}
            withoutFormik
            field='invoiceDate'
            title={t('invoice.invoiceDate')}
            value={invoiceDate}
          />
        </Col>
        <Col lg={2}>
          <ButtonBlock>
            <Button
              className='green-btn'
              upperCase
              noActive={!orderId || (checkBoxList.length && checkBoxList.every((checkBox) => checkBox === false))}
              value={t('invoice.createInvoice')}
              onClick={() => createInvoice({ ...values, invoiceDate })}
            />
          </ButtonBlock>
        </Col>
      </Row>
    </>
  );
};

export default CalendarSection;

const ButtonBlock = styled.div`
  margin: 40px 0 0 20px;
`;

const ToggleBlock = styled.div`
  padding-top: 5px;
`;
