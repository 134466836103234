import gql from 'graphql-tag';

export default gql`
mutation createDefOrder($productId: Int!, $customerId: Int!, $itemSortPos: Int!){
    fakturaAssist{
    createDefOrder(productId: $productId, customerId: $customerId, itemSortPos: $itemSortPos){
		id
    }
    
  }
}`;
