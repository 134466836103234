import React, { useState } from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';
import moment from 'moment';

import { Button } from 'components/elements';

import TimeList from './TimeList';
import MonthList from './MonthList';
import DayList from './DayList';
import WeekList from './WeekList';

const OrderCreatingModal = ({ closeModal, onConfirm, text, t, setSpecialDateModal }) => {
  const [time, setTime] = useState('00:00');
  const [monthNumber, setMonthNumber] = useState('01');
  const monthDays = moment('2020-' + monthNumber, 'YYYY-MM').daysInMonth();
  const [daysArray, setDaysArray] = useState([]);
  const [monthsArray, setMonthsArray] = useState([]);
  const [weekArray, setWeekArray] = useState([]);
  const [modalPosition, setModalPosition] = useState(0);
  const renderBody = () => {
    switch(modalPosition) {
      case 0:
        return <TimeList time = {time} setTime={setTime} setModalPosition = {setModalPosition} t={t}/>;
      case 1:
        return <DayList daysArray={daysArray} setDaysArray={setDaysArray} monthDays = {monthDays} t={t} setMonthNumber={setMonthNumber}/>;
      case 2:
        return <MonthList monthsArray={monthsArray} setMonthsArray={setMonthsArray}/>;
      case 3:
        return <WeekList weekArray={weekArray} setWeekArray={setWeekArray} t={t}/>;
      default:
        return <TimeList t={t}/>;
    }
  };

  const generateFinalText = () => {
    const daysForm = daysArray.length === monthDays ? 'All days' : daysArray.map((i) => i + 1 + ', ');
    const monthForm = monthsArray.length === 12 ? 'All mounths' : monthsArray.map((i) => i + ', ');
    const weekForm = weekArray.length === 7 ? 'All week' : weekArray.map((i) => i + ', ');
    return`  Daily at ${time} ${daysForm} ${monthForm} ${weekForm}`;
  };
  const buttonTextRender = () => {
    switch(modalPosition) {
      case 0:
        return 'Days';
      case 1:
        return 'Mounth';
      case 2:
        return 'Weekday';
      case 3:
        return t('common.save');
      default:
        return t('common.error');
    }
  };
  return(
    <ModalBody>
      <Row>
        <Col width={'260px'}>
            Start time
        </Col>
      </Row>
      <Row>
        {renderBody()}
      </Row>
      <Row>
        <FinalText>
          {generateFinalText()}
        </FinalText>
      </Row>
      <Row>
        <Col lg={4}>
          <Button value={modalPosition === 0 ? t('common.close') : t('common.cancel')} upperCase className='grey-btn' onClick={() => {
            if(modalPosition === 0) {
              setSpecialDateModal(false);
            }else{
              setModalPosition(modalPosition - 1);
            }
          }
            
          }/>
        </Col>
        <Col lg={4}>
          <Button value={buttonTextRender()} upperCase className='black' onClick={() => {
            if(modalPosition === 3) {
              setSpecialDateModal(false);
            }else{
              setModalPosition(modalPosition + 1);
            }
          }
          }/>
        </Col>
      </Row>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  padding: 20px 20px 0 20px;
  text-align: center;
  font-size: 15px;
`;

const FinalText = styled.div`
color: #3cb950;
font-size: 12px;
`;

export default OrderCreatingModal;
