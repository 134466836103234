import gql from 'graphql-tag';

export default gql`
mutation callFA_OfferActions($args: FA_OfferActionsInputs!){
    fakturaAssist{
    	callFA_OfferActions(args: $args){
    		errorCode
			errorText
			offerID
			offerNr
 		}
 	}
}`;
