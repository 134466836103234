import gql from 'graphql-tag';
//Used in information block on top
export default gql`
mutation ($params: PDFParamsInput){
    reports{
    	getReport(params: $params){
	    	statusCode
      		body
	 	}
 	}
}`;
