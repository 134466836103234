import React from 'react';
import { OptionsSection } from 'components/section';
//import { updateFA_Config } from '../qql/mutation';

const Options = (props) => {
  return <OptionsSection {...props} />;
  // return(
  //   <div>
  //     <Switch>
  //       <Route path='/options/company' component={() => <Company {...props} config={data} loading={loading} />} />
  //       <Route path='/options/configurations' component={() => <Configurations {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/automation' component={() => <Automation {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/import' component={() => <Import {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/history' component={() => <History {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/settings' component={() => <Settings {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/forms' component={() => <Forms {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/export' component={() => <Export {...props} config={data} loading={loading}/>} />
  //       <Route path='/options/connections' component={() => <Connections {...props} config={data} loading={loading} getConfigQuery={getFA_Config}/>} />
  //       <Route path='/options/securityManager' component={() => <SecurityManager {...props} config={data} loading={loading}/>} />
  //     </Switch>
  //   </div>
  // );
};
export default Options;

