import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Success from 'style/check.svg';
import Loading from 'style/loading.svg';
import ErrorIndicator from 'style/attention.svg';
import NoNetworkIndicator from 'style/nonetwork.svg';

const modalRoot = document.getElementById('status-root');

const MutationStatusPortal = ({ children }) => {
  const el = document.createElement('div');

  useEffect(
    () => {
      modalRoot.appendChild(el);
      return () => {
        modalRoot.removeChild(el);
      };
    },
    [el]
  );
  return ReactDOM.createPortal(children, el);
};

export const MutationStatus = ({ status }) =>{
  switch(status) {
    case 'loading':
      return <MutationStatusPortal><MutationStatusBox src={Loading || Success} background='#ffffff' /></MutationStatusPortal>;
    case 'networkError':
      return <MutationStatusPortal><MutationStatusBox src={NoNetworkIndicator} background='#c13c44' /></MutationStatusPortal>;
    case 'error':
      return <MutationStatusPortal><MutationStatusBox src={ErrorIndicator} background='#c13c44' /></MutationStatusPortal>;
    default:
      return <MutationStatusPortal><MutationStatusBox src={Success} background='#ffffff' /></MutationStatusPortal>;
  }
};

const MutationStatusBox = styled.div`
	  width: 70px;
    height: 70px;
    background: #ffffff;
    position: absolute;
    z-index: 99;
    opacity: 0.6;
    right: 5%;
    bottom: 5%;
    border-radius: 50%;
    background: url(${(props) => props.src}) no-repeat;
    background-size: 30px;
    background-color: ${(props) => props.background};
    background-position: center;
`;