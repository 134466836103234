import React from 'react';
import styled from 'styled-components';

import { Dots } from 'components/Loading/index';

const EmailModal = ({ status }) => (
  <ModalBody>
    <StatusText>{status}</StatusText>
    <Dots radius='25px'/>
  </ModalBody>
);

const ModalBody = styled.div`
  padding-top: 20px;
  text-align: center;
  font-size: 15px;
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
`;

const StatusText = styled.span`
display: block;
margin-bottom: 10px;
font-size: 16px;
`;

export default EmailModal;
