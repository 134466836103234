import gql from 'graphql-tag';

export const listFA_CustomerSpecialPricesAndDiscountsProduct = gql`
query listFA_CustomerSpecialPricesAndDiscountsProduct($id: ID!) {
  fakturaAssist {
    listFA_CustomerSpecialPricesAndDiscountsProduct(id: $id) {
      id
      customerId
      productId
      fromDate
      toDate
      customerNm
      actualName
      price
      fromAmount
      discount
      isDiscountsDisabled
      description
      specialPriceTypeId
    }
  }
}`;

export const updateFA_CustomerSpecialPricesAndDiscountsProduct = gql`
mutation updateFA_CustomerSpecialPricesAndDiscountsProduct($id: ID!, $fields: SpecialPricesFields!) {
  fakturaAssist {
    updateFA_CustomerSpecialPricesAndDiscountsProduct(id: $id, fields: $fields){
      id
      customerId
      productId
      specialPriceTypeId
      fromDate
      toDate
      description
      price
      fromAmount
      discount
      isDiscountsDisabled
      customerNm
      actualName
    }
  }
}`;

export const createFA_SpecialPrices = gql`
mutation createFA_SpecialPrices($fields: SpecialPricesFields!) {
  fakturaAssist {
    createFA_SpecialPrices(fields: $fields){
      id
      customerId
      productId
      specialPriceTypeId
      fromDate
      toDate
      description
      price
      fromAmount
      discount
      isDiscountsDisabled
      customerNm
    }
  }
}`;

export const createFA_SpecialPricesProduct = gql`
mutation createFA_SpecialPricesProduct($fields: SpecialPricesFields!) {
  fakturaAssist {
    createFA_SpecialPricesProduct(fields: $fields){
      id
      customerId
      productId
      specialPriceTypeId
      fromDate
      toDate
      description
      price
      fromAmount
      discount
      isDiscountsDisabled
      customerNm
    }
  }
}`;

export const deleteFA_SpecialPrices = gql `
mutation deleteFA_SpecialPrices($id: ID!) {
  fakturaAssist {
    deleteFA_SpecialPrices(id: $id){
      id
    }
  }
}`;

export const createProductSpecialPrice = gql `
mutation createProductSpecialPrice($fields: ProductSpecialPrice!) {
  fakturaAssist {
    createProductSpecialPrice(fields: $fields){
      id
      linkArticle
      fromDate
      toDate
      price1
      price2
      price3
      price4
      price5
      price6
      price7
      price8
      price9
      price10
      description
    }
  }
}`;

export const listFA_CustomerSpecialPricesTypes = gql`
query listFA_CustomerSpecialPricesTypes($languageId: Int) {
  fakturaAssist {
    listFA_CustomerSpecialPricesTypes(languageId: $languageId) {
      id
      name
    }
  }
}
`;

export const FA_ProductCustomerPriceCustomers = gql`
query FA_ProductCustomerPriceCustomers {
  fakturaAssist {
    listFA_ProductCustomerPriceCustomers {
      id
      actualName
      customerNr
      concatenatedName
    }
  }
}
`;

export const FA_ProductCustomerPriceCustomersActive = gql`
query FA_ProductCustomerPriceCustomersActive($id: Int) {
  fakturaAssist {
    listFA_ProductCustomerPriceCustomersActive(id: $id) {
     actualName
      customerNr
      concatenatedName
    }
  }
}
`;
