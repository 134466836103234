import gql from 'graphql-tag';

export default gql`
query listFA_CustomerForSpecialPrice($params: ListQueryArgs){
    fakturaAssist{
    listFA_CustomerForSpecialPrice(params: $params){
		id
		customerNr
		aktNameId
    }
    
  }
}`;
