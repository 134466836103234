import Main from './pages/Main';

export default (t) => ([
  {
    name: t('statistic.statistic'),
    link: 'statistic',
    Handler: Main,

  },
]);

