import gql from 'graphql-tag';

export default gql`
query listFA_CustomerDefaultProfiles {
        fakturaAssist {
            listFA_CustomerDefaultProfiles {
                id
                name
            }
        }
    }`;
