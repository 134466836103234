
import gql from 'graphql-tag';

export default gql`
query listFA_ProductionPrintDeliveryNotes($toDate: String, $fromDate: String, $params: ListQueryArgs){
    fakturaAssist{
    listFA_ProductionPrintDeliveryNotes(toDate: $toDate, fromDate: $fromDate, params: $params){
		id
		customerNr
		deliveryNoteNr
		resName
		deliveryNoteType
		actNameId
		isPrint
		isPrintDateTime
		date
    }
    
  }
}`;
