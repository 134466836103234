import React from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/elements';
import Formik from 'components/formik';
import { Row, Col } from 'style';

export default ({ t, data, mutations, linkId, sectionInfo }) => {
  return (
  	<Formik initialValues={data} enableReinitialize onSubmit={mutations} linkId={linkId} sectionInfo={sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <HeadBlock>
            <MainBlock>
              <Col lg={4}>
                <Checkbox
                  margin={'19px 0 0 0'}
                  value={values.useAnotherLngDescriptions}
                  field='useAnotherLngDescriptions'
                  title={t('customer.useAnotherLanguage')}
                />
              </Col>
            </MainBlock>
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled(Row)`
  height: 100px;
  padding: 0px 0px 0px 0px;
`;

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
