import { useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';

import reduce from 'helpers/reduceGraphqlData';
import { useMutation } from 'components/graphql';
import { temporaryOffer } from '../qql/mutation';
import { listFA_GetOfferProducts } from '../qql/query';

const useTemporaryOffer = (props) => {
  const { listFA_OfferCustomersList, contex, client, prevVariables, prevCustomerInfo } = props;
  const [temporaryOfferMutation] = useMutation(temporaryOffer);
  const [memoryProductData, setMemoryProductData] = useState(null);
  const orderDate = contex?.date?.date;

  const changeTemporaryIdForCustomer = (temporaryId) =>{
    try{
      const customersFromCache = { ...client.cache.readQuery({
        query: listFA_OfferCustomersList,
        variables: contex,
      }) };
      const deepArray = cloneDeep(customersFromCache);
      const actualCustomer = { ...prevCustomerInfo.info };
      actualCustomer.temporaryId = temporaryId;
      deepArray.fakturaAssist.listFA_OfferCustomersList[prevCustomerInfo.index] = actualCustomer;
      const changedData = deepArray.fakturaAssist.listFA_OfferCustomersList;
      try{
        client.cache.writeQuery({
          query: listFA_OfferCustomersList,
          variables: contex,
          data: {
            fakturaAssist: {
              listFA_OfferCustomersList: changedData,
              __typename: 'FA_Query',
            },
          },
        });

        //Change customer position
      }catch(err) {
      //console.log(err)
      }
    }catch(err) {
      //console.log(err);
    }
  };

  const transformData = (data) => {
    return data.map((offer) => ({ ...offer,
      quantity: offer.quantity === null ? '' : `${offer.quantity}`,
      discount: offer.discount === null ? '' : `${offer.discount}`,
      price: (offer.price === 0 || offer.price === '0.00') ? '' : `${offer.price}`,
    }))
      .sort((a, b) => +a.id - +b.id);
  };

  const checkTemporaryOffer = async (variables, isCreateOfferCalled) => {
    //Check old and new data
    try{
      const cache = client.readQuery({
        query: listFA_GetOfferProducts,
        variables: variables,
      }).fakturaAssist.listFA_GetOfferProducts;
      const data = cache.productTable;
      
      if(data.length) {
        const isCreateTemporaryOffer = !isEqual(transformData(data), cloneDeep(transformData(memoryProductData)));
        if(isCreateTemporaryOffer && isCreateOfferCalled) return;
        // console.log({ isCreateOfferCalled, data: transformData(data), memoryProductData: transformData(memoryProductData), isCreateTemporaryOffer, isEqual: isEqual(transformData(data), cloneDeep(transformData(memoryProductData))) }, );
        if(isCreateTemporaryOffer) {
          const productsForOffer = cache.productTable.map((item)=>{
            const {
              virtualPositionLink,
              productLink,
              quantity,
              price,
              discount,
              taxCode,
              indTextProduction,
              indTextDeliveryNote,
              total,
            } = item;
            return {
              virtualPositionLink: +virtualPositionLink || 0,
              productLink: productLink,
              quantity: +quantity || 0,
              price: price ? Number(price) : 0,
              discount: +discount || 0,
              taxCode: +taxCode || 0,
              total: total ? Number(total) : 0,
              indTextProduction: indTextProduction || '',
              indTextDeliveryNote: indTextDeliveryNote || '',
            };
          });
          const dataForTemporaryOffer = {
            args: {
              date: orderDate,
              customerId: prevVariables.args.id,
              temporaryId: prevVariables.args.temporaryId,
              productsList: productsForOffer,
            },
          };
          try{
            const data = await temporaryOfferMutation({ variables: dataForTemporaryOffer });
            const newTemporaryId = await reduce(data.data).id;
            await changeTemporaryIdForCustomer(newTemporaryId);
          }catch(err) {
          //console.log(err);
          }
        }
      }
    } catch(err) {
    // console.log(err);
    }
    return null;
  };

  return {
    checkTemporaryOffer,
    setMemoryProductData,
    memoryProductData,
  };
};

export default useTemporaryOffer;
