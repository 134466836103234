import print from 'style/img/actions/print.svg';
import history from 'style/img/actions/history.svg';
import email from 'style/img/actions/email.svg';
import reports from 'style/img/actions/reports.svg';
import importImg from 'style/img/actions/import.svg';
import importV2 from 'style/img/actions/importV2.svg';
import preview from 'style/img/actions/preview.svg';
import s3 from 'style/img/actions/s3.svg';

export const allActions = ({ t, showPDFPreview, actualPdfParams, printerInfo, sendPDFIntoPrinter, showFormsType, reportForms, sendPDFIntoEmail }) =>([
  {
    action: 'printReport',
    text: t('actions.printReport'),
    icon: print,
    defaultFunc: () => {
      return sendPDFIntoPrinter(actualPdfParams, printerInfo);
    },
  },
  {
    action: 'preview',
    text: t('actions.generatePDF'),
    icon: preview,
    defaultFunc: () => showPDFPreview(actualPdfParams),
  },
  {
    action: 'debitorPrint',
    text: t('actions.debitorPrint'),
    icon: reports,
  },
  {
    action: 'reportForms',
    text: t('actions.reportForms'),
    icon: reports,
    defaultFunc: () => showFormsType(actualPdfParams, reportForms),
  },
  {
    action: 'email',
    text: t('common.email'),
    icon: email,
    defaultFunc: () => sendPDFIntoEmail(actualPdfParams),
  },
  {
    action: 'importOrder',
    text: t('actions.importOrder'),
    icon: importImg,
  },
  {
    action: 'importISO',
    text: t('actions.importISO'),
    icon: importV2,
  },
  {
    action: 'history',
    text: t('history.history'),
    icon: history,
  },
  {
    action: 'exportWA',
    text: t('common.exportWA'),
    icon: importImg,
  },
  {
    action: 'exportS3',
    text: t('common.exportS3'),
    icon: s3,
  },
]);

