/*eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { floor } from 'lodash';
import WarningValid from 'style/img/warningValid.svg';

import { withFieldSubmit } from '../../formik';
import withName from '../hoc/withName';

export const FLOAT_FORMATS = {
  ffNumber: 'ffNumber',   //Simple number without separated thousands
  ffFloat: 'ffFloat',    //unformatted floating point
  ffFixed: 'ffFixed',    //fixed count of decimal places
  ffCurrency: 'ffCurrency', //currency symbol included, fixed decimal places
 };

const Input = (props) =>{
  const { submit, onChangeFormik, withoutFormik, width, small, type, defaultType, onChange, field, onChangeSpecial, onBlur, withOpacity, onBlurSpecial, additionalFunc, err={}, name, handleBlur, touched, value, manualErrors, decimalPlaces, allowNegative} = props;
  const floatFormat = props.floatFormat || FLOAT_FORMATS.ffFloat;
  const widthInput = width ? width : small ? '140px' : '300px';
  const [isEditing, setIsEditing] = useState(false)
  const inpRef = useRef(null);

  let lastValue = null;

  let locale;
  let formatOptions = {};

  switch (floatFormat) {
    case FLOAT_FORMATS.ffNumber:
      formatOptions = {
        useGrouping: false,
        maximumFractionDigits: 0,
      }
      break;
    case FLOAT_FORMATS.ffFloat:
      formatOptions = {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalPlaces || 2,
        useGrouping: true,
      }
      break;
    case FLOAT_FORMATS.ffFixed:
      formatOptions = {
        style: "decimal",
        minimumFractionDigits: decimalPlaces || 2,
        maximumFractionDigits: decimalPlaces || 2,
        useGrouping: true,
      }
      break;
    case FLOAT_FORMATS.ffCurrency:
      formatOptions = {
        style: "currency",
        currency : '', //TODO: implemet getting system's currency symbol
        minimumFractionDigits: decimalPlaces || 2,
        maximumFractionDigits: decimalPlaces || 2,
        useGrouping: true,
      } 
      break;
    default:
      formatOptions = {
        useGrouping: false,
        maximumFractionDigits: 0,
      }
  }

  //Creating numberFormatter withour any locale included. In this case we'll get "best fit" locale
  let numberFormatter = new Intl.NumberFormat(locale, formatOptions); 

  function getValue(value){
    //We need to return formatted value if Input is not in "edit" mode.
    //Also, we need to avoid "NaN" and "undefined" values during firt formik's render
    if (!withoutFormik && !isEditing && type === 'number'){
      if (value) {
        return numberFormatter.format(value)
      } else {
        return ''
      }
    } else {
      return value || ''
    }
  }

  const onChangeInput = (e) => {
    if(props.type === 'number'){
      //Check if newly got value is allowed
      if (!isValidValue(e.target.value)) {
        e.target.value = lastValue
      }
      
      const floatValue = parseFloat(e.target.value || 0);

      if (!withoutFormik) {
        onChangeFormik(floatValue) 
        additionalFunc && additionalFunc(e.target.value)
      } else {
        onChangeSpecial && onChangeSpecial(floatValue)
      }
    } else {
      if (!withoutFormik) {
        onChangeFormik(e.target.value)
        additionalFunc && additionalFunc(e.target.value)
      } else {
        onChangeSpecial && onChangeSpecial(e.target.value)
      }
    }
  }

  function isValidValue(exp) {
    let decimalSeparator = '';
    
    if (type === 'number') {
      if (!allowNegative && (exp.indexOf('-') !== -1)) return false;

      //here we need to check if we have entered decimal separator
      //for now, type='number' input allows to enter ',' as a decimal separator
      //but it may be not so in other locales, so we need to check for both '.' and ','
      //input won't allow us to enter 2 separators, so we'll search until first appearence
      if (exp.indexOf(',') !== -1) {
        decimalSeparator = ',';
      } else if (exp.indexOf('.') !== -1) {
        decimalSeparator = '.';
      } else {
        decimalSeparator = '';
      }

      if (formatOptions.maximumFractionDigits || 0 > 0) {
        if (decimalSeparator !== '') {
          const fractionPart = exp.split(decimalSeparator)[1];  
          return (fractionPart.length <= formatOptions.maximumFractionDigits)
        } else {
          return true
        }
      } else {
        if ((decimalSeparator !== '') && (floatFormat !== FLOAT_FORMATS.ffNumber)){
          return false 
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  return (
    <>
    <Inputs
      withOpacity={withOpacity}
      onBlur={
      (e) => {
        setIsEditing(false);
        onBlurSpecial&&onBlurSpecial(e.target.value);
        submit&&submit(e);
        onBlur&&onBlur(e.target.value);
        handleBlur&&handleBlur(e)
      }
      }
      onFocus={(e) => {setIsEditing(true)}}
      width={width}
      onChange={onChangeInput}
      onKeyPress={(e)=>{
        if (((e.key === '.') || (e.key === ',')) &&
            type === 'number' &&
            floatFormat === FLOAT_FORMATS.ffNumber) {
          e.preventDefault()
        } else {
          //just for to store previous value
          lastValue = e.target.value || '' + e.key
        }
      }}
      widthInput={widthInput}
      innerRef={inpRef}
      ref={props.inputRef}
      {...props}
      defaultType={type||'text'}
      type={isEditing?type||'text':'text'}
      value={getValue(props.value)}
    />
    {manualErrors && manualErrors[name || field] 
      ? <small style={{ color: 'red', display: 'block', maxWidth: 140 }}>{manualErrors[name]}</small>
    : ((err&&err[name])&&(touched&&touched[name])) &&
      <Error>
        <Img src={WarningValid}/> {err[name]}
      </Error>}
    </>
    );
};

export default compose(
    withName,
    withFieldSubmit,
    )(Input);

export const Inputs = styled.input`
  /* font-family: Roboto-Medium; */
  font-size: 14px;
  font-weight: 500;
  width: ${(props) => props.widthInput};
  padding-left: 10px;
  padding-right: 10px;
  text-align: ${(props) => (props.textAlignRight || (props.defaultType==='number' ? 'right' : ''))};
  padding-right: ${(props) => (props.textAlignRight ? '10px': props.paddingRight ? props.paddingRight : '')};
  height: 36px;
  border-radius: 5px;
  border: ${(props) => (props.readonly ? '#d6d6d6' : (props.err&&props.err[props.name]) && (props.touched&&props.touched[props.name]) ? '1px solid #c13c44' : props.noBorder ? 'none' : 'solid 1px #cdcdcd')} ;
  pointer-events: ${(props) => props.readonly ? 'none' : 'auto'};
  box-shadow: ${(props) => (props.noBorder ? 'none' : '0 1px 2px 0 rgba(90, 90, 90, 0.12)')}  ;
  background: ${(props) => (props.withOpacity ? 'transparent' : props.readonly ? '#d6d6d6' : props.notEdit ? '#c3c3c3' : '#ffffff')};
  color: ${(props) => (props.color? props.color: props.notEdit ? '#ffffff' : 'inherit')};
  &.error__input {
    border: solid 1px #c13c44;

  }
`;
const Error = styled.div`
  color: #c13c44;
  /* font-family: Roboto-Light; */
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  margin-top: 0px;
`;

const Img = styled.img`
  margin-top: 2px;
`;

Input.propTypes = {
  value: PropTypes.any, //Value of input
  field: PropTypes.string, // Formik field
  title: PropTypes.string,
  //Another standard input props
};

/*
  Example
    <Input
      placheholder={'Enter...'}
      type='float'
      value={+values.amount[0]}
      field={'amount[0]'}
      title={category[0]}
    />
*/
