import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';
import { useQuery } from '@apollo/react-hooks';
import { cloneDeep } from 'lodash';
import { Checkbox } from 'components/elements';

// import { zip, findIndex, isEqual } from 'lodash';
import listFA_CustomerSammelfakturaAufKunde from '../../../../customer/forms/qql/query/listFA_CustomerSammelfakturaAufKunde';
import listFA_CustomerListsDefault from './qql/listFA_CustomerListsDefault';

import { Formik } from 'formik';

import SpecialInput from './components/SpecialInput';
import SpecialDropdown from './components/SpecialDropdown';

export default (props) => {
  const { values, setFieldValue, arrSplit, arrDelivery, arrCustomer, linkId } = props;
  const [count, setCount] = useState(0);
  const [isExpertMode, setIsExpertMode] = useState(false);
  const QUERY_listFA_CustomerSammelfakturaAufKunde = useQuery(listFA_CustomerSammelfakturaAufKunde);
  const QUERY_listFA_CustomerListsDefault = useQuery(listFA_CustomerListsDefault);

  const getCount = () =>{
    let maxLine = 0;

    values.categoryName.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    values.categoryLinkCustomer.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    values.categoryLinkCustomerList.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    values.categoryFilterDeliveryType.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    values.categoryFilterSplitNo.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    values.categoryFilterCustomer.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    values.categoryCustomFilterDelivery.forEach((item, index)=>{
      if(item) {
        if(index > maxLine) {
          maxLine = index;
        }
      }
    });
    return maxLine + 2;
  };
  useEffect(()=>{
    setCount(getCount());
  }, [linkId]);

  const changeCount = (index) => {
    // Maximum 34 categories, counted after onChange and pass on one less
    if(count <= 33) {
      if(
        (values.categoryCustomFilterDelivery[index] ||
              values.categoryFilterCustomer[index] ||
              values.categoryFilterSplitNo[index] ||
              values.categoryFilterDeliveryType[index] ||
              values.categoryLinkCustomerList[index] ||
              values.categoryLinkCustomer[index] ||
              values.categoryName[index]) && index === count - 1) {
        setCount(count + 1);
      }
    }
    
    if((!values.categoryCustomFilterDelivery[index] &&
          !values.categoryFilterCustomer[index] &&
          !values.categoryFilterSplitNo[index] &&
          !values.categoryFilterDeliveryType[index] &&
          !values.categoryLinkCustomerList[index] &&
          !values.categoryLinkCustomer[index] &&
          !values.categoryName[index]) && index === count - 2) {
      setCount(count - 1);
    }
  };

  const addFirstElemDef = (data)=>{
    const newData = cloneDeep(data);
    newData.unshift({ id: null, name: 'nothing' });
    return newData;
  };

  return(
    <HeadBlock>
      <MainBlock>
        <Row>
          <Col lg={2}>
            <Checkbox margin={'0 0 20px 0'} onClick = {()=>setIsExpertMode(!isExpertMode)} title='Expert mode' withoutFormik value={isExpertMode}/>
          </Col>
        </Row>
        {!QUERY_listFA_CustomerSammelfakturaAufKunde.loading && !QUERY_listFA_CustomerListsDefault.loading && [...Array(count)].map((item, index)=>{
          return(
            <Row style = {{ marginTop: '10px' }}>
              <Col lg={2}>
                <SpecialInput
                  isExpertMode={isExpertMode}
                  id={`${index}-1`}
                  value={values.categoryName[index]}
                  additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                  field={`categoryName[${index}]`}
                  count={count}
                  index={index}
                  columnIndex={1}
                />
              </Col>
              <Col width='309px'>
                <Formik
                  initialValues={{}}
                  render={
                    (props) => {
                      return(
                        <Row>
                          <Col style={{ marginRight: '15px' }}>
                            <SpecialDropdown
                              isExpertMode={isExpertMode}
                              id={`${index}-2`}
                              width='140px'
                              data={arrCustomer}
                              valueField = 'id'
                              labelField = 'name'
                              withoutFormik
                              count={count}
                              index={index}
                              columnIndex={2}
                              name = {(props.values[`categoryLinkCustomerList${index}`] ? props.values[`categoryLinkCustomerList${index}`] : values.categoryLinkCustomerList[index] === null ? 1 : 2) === 1 ? `categoryLinkCustomerList[${index}]` : `categoryLinkCustomer[${index}]`}
                              setFieldValue = {(name, value, submit)=>{
                                props.setFieldValue(`categoryLinkCustomerList${index}`, value);
                                setFieldValue(value === 2 ? `categoryLinkCustomer[${index}]` : `categoryLinkCustomerList[${index}]`, null);
                                submit(null);
                              }}
                              additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                              defaultValueField = {props.values[`categoryLinkCustomerList${index}`] ? props.values[`categoryLinkCustomerList${index}`] : values.categoryLinkCustomerList[index] === null ? 1 : 2}
                            />
                          </Col>
                          <Col>
                            { (props.values[`categoryLinkCustomerList${index}`] ? props.values[`categoryLinkCustomerList${index}`] : values.categoryLinkCustomerList[index] === null ? 1 : 2) === 1 ?
                              <SpecialDropdown
                                isExpertMode={isExpertMode}
                                count={count}
                                width='140px'
                                index={index}
                                columnIndex={3}
                                id={`${index}-3`}
                                valueField = 'id'
                                labelField = 'name'
                                field = {`categoryLinkCustomer[${index}]`}
                                additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                                defaultValueField = {values.categoryLinkCustomer[index] }
                                data={addFirstElemDef(QUERY_listFA_CustomerSammelfakturaAufKunde.data.fakturaAssist.listFA_CustomerSammelfakturaAufKunde)}
                              />
                              :
                              <SpecialDropdown
                                isExpertMode={isExpertMode}
                                count={count}
                                width='140px'
                                index={index}
                                columnIndex={3}
                                id={`${index}-3`}
                                valueField = 'id'
                                labelField = 'name'
                                additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                                field = {`categoryLinkCustomerList[${index}]`}
                                defaultValueField = {values.categoryLinkCustomerList[index]}
                                data={
                                  addFirstElemDef(QUERY_listFA_CustomerListsDefault.data.fakturaAssist.listFA_CustomerListsDefault)
                                }
                              />
                            }
                          </Col>
                        </Row>
                      );
                    }}
                />
              </Col>
              <Col width='155px'>
                <SpecialDropdown
                  isExpertMode={isExpertMode}
                  count={count}
                  index={index}
                  width='140px'
                  columnIndex={4}
                  id={`${index}-4`}
                  data={arrDelivery}
                  valueField = 'id'
                  labelField = 'name'
                  additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                  field = {`categoryFilterDeliveryType[${index}]`}
                  defaultValueField = {values.categoryFilterDeliveryType[index]}
                />
              </Col>
              <Col width='155px'>
                <SpecialDropdown
                  isExpertMode={isExpertMode}
                  count={count}
                  width='140px'
                  index={index}
                  columnIndex={5}
                  id={`${index}-5`}
                  data={arrSplit}
                  valueField = 'id'
                  labelField = 'name'
                  additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                  field = {`categoryFilterSplitNo[${index}]`}
                  defaultValueField = {values.categoryFilterSplitNo[index]}
                />
              </Col>
              {isExpertMode &&
              <>
                <Col lg={2}>
                  <SpecialInput
                    isExpertMode={isExpertMode}
                    id={`${index}-6`}
                    value={values.categoryFilterCustomer[index]}
                    additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                    field={`categoryFilterCustomer[${index}]`}
                    count={count}
                    index={index}
                    columnIndex={6}
                  />
                </Col>
                <Col lg ={2}>
                  <SpecialInput
                    isExpertMode={isExpertMode}
                    id={`${index}-7`}
                    value={values.categoryCustomFilterDelivery[index]}
                    additionalFunc={(index === (count - 2) || index === (count - 1)) && changeCount(index)}
                    field={`categoryCustomFilterDelivery[${index}]`}
                    count={count}
                    index={index}
                    columnIndex={7}
                  />
                </Col>
              </>}
            </Row>);
        })}
      </MainBlock>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  height: 100%;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 0px 20px 20px;
  margin-top: 20px;
  min-height: 400px;
`;
