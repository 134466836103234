import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { isEqual } from 'lodash';

import ActionsList from 'components/actionsList/ActionsList';
import { Transition, Text } from 'components/elements';
import Formik from 'components/formik';

import DXTableGrid from 'components/dxTableGrid';

import Setting from './Settings';
//import Test from '../../Test';

import { listFA_Statistic } from '../qql/query';

const MainStatistic = (props) => {
  const { t } = props;

  const [data, setData] = useState([]);
  const getRealDate = (realDate) =>{
    if(!isEqual(data, realDate)) {
      setData(realDate);
    }
  };
  return (
    <Formik initialValues={{ textareaValue: '', invoiceDate: moment(new Date()).add(1, 'days'), textID: 2 }}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <div>
            { true && <><TitleBlock>
              <div> <Text text='Statistik' fontSize='16px' fontWeight='500'/></div>
              {/* <ActionsList
                t={t}
                actions={{}}
                notAllowed
              /> */}
            </TitleBlock>
            <Transition text={t('common.settings')} minHeight='200px' isOpen>
              <Setting {...props}/>
            </Transition>
            <InfoTableBlock>
              {/* <TableGrid
                tableData={infoTableParams(t)}
                data={[{ test: 'test' }, { test: 'test' }, { test: 'test' }, { test: 'test' }, { test: 'test' }]}
              /> */}
              <DXTableGrid
                columns={[
                  {
                    dataField: 'productNr',
                    width: 200,
                    Footer: 'Total:',
                    caption: 'Product Number',
                  },
                  {
                    dataField: 'name',
                    caption: 'Name',
                  },
                  {
                    dataField: 'quantity',
                    localFormat: { type: 'float' },
                    summary: {
                      summaryType: 'sum',
                    },
                  },
                  {
                    dataField: 'weight',
                    localFormat: { type: 'float' },
                    summary: {
                      summaryType: 'sum',
                    },
                  },
                  {
                    dataField: 'discountGroup',
                  },
                  {
                    dataField: 'marketingGroup',
                  },
                  {
                    dataField: 'productionGroup',
                  },
                ]}
                graphqlParams={{
                  query: listFA_Statistic,
                  variables: { args: { date: '2020-10-10' } },
                }}
                groupBy={[]}
                height={508}

                withSearch
                label='ABC Statistic'
                renderActions={false}
                manualGroup
              />
            </InfoTableBlock> </>
            }
            <Transition text={t('diagrams.diagram')} minHeight='200px' isOpen>
              {//data[0]&&<Test data={data}/>
              }
            </Transition>
          </div>
        );
      }}
    </Formik>
  );
};

export default MainStatistic;

const InfoTableBlock = styled.div`
  margin-top: 10px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;

`;

const TitleBlock = styled.div`
    display: flex;
    justify-content: space-between;
`;
