import React, { useState } from 'react';
import { get } from 'lodash';
import { Dropdown, Button, Modal, Input } from 'components/elements';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import listFA_CustomerDefaultProfiles from './qql/listFA_CustomerDefaultProfiles';
import createCustomer from './qql/createCustomer';
import listFA_Customer from './qql/listFA_Customer';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Col } from 'style';
import { useTranslation } from 'react-i18next';

const ComponentForCreating = ({ linkId, isOpen, setIsOpen, contex, sortObject }) => {
  const [t] = useTranslation();
  const [customerData, setCustomerData] = useState({ number: '', name: '', error: false, validationError: false });
  const { data, loading } = useQuery(listFA_CustomerDefaultProfiles);
  const [Mutation_createCustomer, { loading: mutationLoading }] = useMutation(createCustomer);
  const [id, setId] = useState(linkId);
  const [defaultCustId, setDefaultCustId] = useState(null);
  const updateHistory = () => (
    <Redirect to={{ pathname: `/customers/adresse/${id}` }} />
  );

  // const updateCache = (creatingCustomer) => {
  //   const defaultListCustomers = client.readQuery({
  //     query: listFA_Customer,
  //     variables: contex,
  //   }).fakturaAssist.listFA_Customer;
  //   const updatedCustomerList = orderBy([...defaultListCustomers, creatingCustomer], [sortObject.column], [sortObject.order?.toLowerCase()]);
  //   client.writeQuery({
  //     query: listFA_Customer,
  //     variables: contex,
  //     data: {
  //       fakturaAssist: {
  //         listFA_Customer: updatedCustomerList,
  //         __typename: 'FA_Query',
  //       },
  //     },
  //   });
  // };

  const onCreate = (id) => {
    Mutation_createCustomer({
      variables: {
        args: {
          defaultCustomerId: id,
          customerName: customerData.name,
          customerNr: customerData.number,
        },
      },
      refetchQueries: [{ query: listFA_Customer, variables: contex }],
    }).then((data) => {
      setId(data.data.fakturaAssist.createCustomer.id);
      setIsOpen(false);
      setDefaultCustId(null);
      setCustomerData({ number: '', name: '', error: false, validationError: false });
    }).catch((err) => {
      if(err?.graphQLErrors) {
        if (err.graphQLErrors[0]?.message.includes('Customer number already exists')) {
          setCustomerData({ ...customerData, error: true });
        }
      }
    });
  };
  const renderModal = () => {
    return (
      <HeadBlock>
        {
          loading ? <i className='fas fa-spinner fa-pulse' /> :
            <Dropdown
              title='Name default customer'
              data={get(data, 'fakturaAssist.listFA_CustomerDefaultProfiles', [])}
              valueField='id'
              labelField='name'
              width={'100%'}
              withoutFormik={true}
              defaultValueField={defaultCustId && defaultCustId}
              setFieldValue={(_, id) => {
                setDefaultCustId(id);
              }}
            />
        }
        {(customerData.validationError && !!!defaultCustId) && <small style={{ color: '#C60008' }}>{t('common.validation.isRequiredInput')}</small>}
        {/* Kunden Nr */}
        <Input
          value={customerData.number}
          field='number'
          title={t('common.customerNo')}
          width={'100%'}
          onChange={(e) => {
            setCustomerData({ ...customerData, number: e.target.value, error: false });
          }}
        />
        {(customerData.validationError && !!!customerData.number) && <small style={{ color: '#C60008' }}>{t('common.validation.isRequiredInput')}</small>}
        {(customerData.error) && <small style={{ color: '#C60008' }}>{t('common.customer_already_exist', { customerNumber: customerData.number })}</small>}
        {/* Kunden Name */}
        <Input
          value={customerData.name}
          field='name'
          title={t('common.name')}
          width={'100%'}
          onChange={(e) => {
            setCustomerData({ ...customerData, name: e.target.value, error: false });
          }}
        />
        {(customerData.validationError && !!!customerData.name) && <small style={{ color: '#C60008' }}>{t('common.validation.isRequiredInput')}</small>}
        <Footer>
          <Col width='50%'>
            <div style={{ marginRight: '10px' }}>
              <Button
                float='right'
                className={'grey-btn'}
                width={'100%'}
                upperCase
                height={'42px'}
                style={{ fontSize: 16 }}
                onClick={ () => {
                  setDefaultCustId(null);
                  setCustomerData({ number: '', name: '', error: false, validationError: false });
                  setIsOpen(false);
                }}
                value={t('common.cancel')} />
            </div>
          </Col>
          <Col width='50%'>
            <div style={{ marginLeft: '10px' }}>
              {mutationLoading ? <i className='fas fa-spinner fa-pulse' /> :
                <Button
                  float='left'
                  className={'green-btn'}
                  width={'100%'}
                  upperCase
                  noActive={!customerData.number || !customerData.name || !defaultCustId}
                  noActiveFunc={(val) => {
                    setCustomerData({ ...customerData, validationError: true });
                  }}
                  height={'42px'}
                  style={{ fontSize: 16 }}
                  onClick={() => {
                    defaultCustId && onCreate(defaultCustId);
                  }}
                  value={t('common.save')}
                />
              }
            </div>
          </Col>
        </Footer>
      </HeadBlock>

    );
  };

  return (
    <div>
      {
        ((linkId !== id) && defaultCustId) && updateHistory()
      }
      <Modal
        headerText={t('customer.createCustomer')}
        width={'480px'}
        isOpen={isOpen}
        closeModal={() => {
          setDefaultCustId(null);
          setCustomerData({ number: '', name: '', error: false, validationError: false });
          setIsOpen(false);
        }}
        Component={renderModal()}
      />
    </div>
  );
};

export default ComponentForCreating;

const HeadBlock = styled.div`
  padding: 0px 20px 20px 20px;
  background: #f4f4f4;
`;
const Footer = styled(Row)`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
`;
