import React from 'react';
import moment from 'moment';

import FormikComponent from 'components/formik';
import TransitionsMenu from './TransitionsMenu';

const DefaultOrder = (props) => {
  const { data, mutations } = props;
  return (
    <React.Fragment>
      <FormikComponent initialValues={data} enableReinitialize onSubmit={mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
        {({ values, setFieldValue }) =>{
          const today = moment(new Date());
          const lastMonday = today.add((1 - today.isoWeekday()), 'day').format('YYYY-MM-DD');
          return (
            <div>
              <TransitionsMenu {...props} setFieldValue={setFieldValue} b = {lastMonday} values={values}/>
            </div>
          );
        }}
      </FormikComponent>
    </React.Fragment>
  );
};

export default DefaultOrder;

