
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { allOperations } from './operationParams';

const ActionsList = ({ operations, t, data, rowIndex, colorBlock, operationAvailability }) => {
  const dataItem = data[rowIndex];
  //Get all keys from  props.actions
  const allKeys = Object.keys(operations(dataItem));
  //Compare actions from upper component with ALL LIST actions from './params' ;
  const arrayComparison = () => {
    const array = [];
    for (const key of allKeys) {
      for (const item of allOperations(t)) {
        if (key === item.action) {
          if (
            operationAvailability
            && operationAvailability[key]
            && !operationAvailability[key].check(dataItem[operationAvailability[key].field])) {
            // console.log(dataItem, dataItem[operationAvailability[key].field], operationAvailability[key].field)
            break;
          }
          array.push({ ...item, func: operations(item)[key] });
          break;
        }
      }
    }
    return array;
  };

  //Max 3 icons in one line!
  const iconPositionInPx = (index) => {
    switch (index) {
      case 0:
        return 10;
      case 1:
        return 40;
      case 2:
        return 70;
      default:
        return 0;
    }
  };
  //Map final array
  const arrayLength = arrayComparison().length;
  const renderActions = arrayComparison().map(({ func, icon }, index) => {
    return (
      <Action onClick={() => {
        func();
      }} key={index} right={iconPositionInPx(index)}>
        <ActionIcon src={icon} />
      </Action>);
  });
  const background = colorBlock ? colorBlock : rowIndex % 2 ? '#eeeeee' : '#fff';
  return <MainDiv arrayLength={arrayLength} right={iconPositionInPx(arrayLength - 1)} background={background}>{renderActions} </MainDiv>;
};
export default ActionsList;

const Action = styled.div`
margin-top: 8px;
    text-align: center;
    z-index: 1000;
    /* position: fixed; */
    position: absolute;
    right: ${({ right }) => right || 10}px;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background: inherit;
    z-index:33
`;

const ActionIcon = styled.img`
	float:left
	padding: 0 13px 0 0;
  height: 20px;
`;

const MainDiv = styled.div`
position: absolute;
height: 40px;
width: ${({ right }) => right + 30}px;
right: 0px;
background: ${({ background }) => background};
z-index: 23;
`;

ActionsList.propTypes = {
  actions: PropTypes.object.isRequired, //Object with keys from params. key: function
  t: PropTypes.func.isRequired, // Translate function
  text: PropTypes.string, // Text in buttom. By default - Actions
};

/*  Example. t(i18) is a required PARAMS!!!!!!
  <ActionsList
      text={t('common.actions')}
      t={t}
      actions={{
        printReport: createNewOrder,
        preview: createNewOrder,
        debitorPrint: createNewOrder,
        email: createNewOrder,
        history: createNewOrder,
        importOrder: createNewOrder,
      }}
     />
*/
