import React from 'react';
import PropTypes from 'prop-types';
import SadFace from './img/404.svg';
import NoNetwork from './img/NoNetwork.svg';
import styled from 'styled-components';

const PageNotFound = ({ type, height }) => {
  switch(type) {
    case '404':
      return(
        <Page404>
          <div>
            <h1>404</h1>
            <h2>Diese Seite fehlt</h2>
          </div>
        </Page404>
      );
    case 'NO_ITEM':
      return(
        <NoItem height={height}>
          <img src={SadFace} alt=''/>
          <p>Diese Seite fehlt</p>
        </NoItem>
      );
    case 'ERROR':
      return(
        <Page404>
          <div>
            <h2>Ein unerwarteter</h2>
            <h2>Fehler ist aufgetreten</h2>
          </div>
        </Page404>
      );
    case 'NETWORK_ERROR':
      return(
        <NoNetworkBlock>
        	<img src={NoNetwork} alt=''/>
          <h2>Netzwerk Fehler ist aufgetreten</h2>
        </NoNetworkBlock>
      );
    default: return <div> 123</div>;
  }
};

PageNotFound.propTypes = {
  type: PropTypes.oneOf(['404', 'NO_ITEM', 'ERROR', 'NETWORK_ERROR']),
};

PageNotFound.defaultProps = {
  type: '404',
};

export default PageNotFound;

const Page404 = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	>div {
		margin-left: 55px;
	}
	h1 {
		font-size: 150px;
	  font-weight: 400;
	  color: #cdcdcd;
	  margin: 0;
	  line-height: 1;
	}
	h2 {
	  font-size: 24px;
	}
`;

const NoItem = styled.div`
	width: 100%;
	height: ${(props)=>(props.height || '100%')};
	display: flex;
	align-items: center;
	flex-direction: column;
	
	p {
	  font-size: 24px;
	  font-weight: 400;
	  margin-bottom: 26px;
	}
`;

const NoNetworkBlock = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: calc(50vh - 200px);
	h2 {
		color: #cdcdcd;
	  margin-top: 20px;
	}
`;

