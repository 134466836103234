import React, { useEffect } from 'react';
import styled from 'styled-components';
import { CONFIG } from './gql';
import Loading from 'components/Loading/LoadingModal';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';

import Routes from './Routes';
import { useTranslation } from 'react-i18next';

const Main = (props) => {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery(CONFIG);

  useEffect(() => {
    const lngId = get(data, 'fakturaAssist.getFA_Config.languageID', null);
    let lng = 'en';
    switch (lngId) {
      case 1:
        lng = 'en';
        break;
      case 2:
        lng = 'de';
        break;
      default:
        lng = 'en';
    }
    if (lngId) {
      localStorage.setItem('lngId', lngId);
      localStorage.setItem('lng', lng);
      i18n.changeLanguage(lng);
    }
  }, [data]);

  if (loading) return <LoadingWrapper><Loading /></LoadingWrapper>;

  return (
  	<Body>
      <MainContent>
	  	<Routes {...props} />
      </MainContent>
  	</Body>
  );
};
export default Main;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const Body = styled.div`
  background-color: #434343;
  min-height: 100vh;
  width: 100%;
  padding: 0!important;
  margin: auto;
`;

const MainContent = styled.div`
 max-width: 1920px;
 min-width: 1440px;
margin: auto;
overflow-y: auto;
background: #f8fdff;
min-height: 100%;
`;

