export const tableDataCRM = (t) => [
  {
    name: t('common.dateTime'),
    dataBase: 'addedOn',
    dataType: 'dateTime',
  },
  {
    name: t('common.user'),
    dataBase: 'userName',
  },
  {
    name: t('common.note'),
    dataBase: 'note',
    isMutation: true,
    isRequired: true,
  },
];
