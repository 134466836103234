import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import IMG_printer from '../../../../../style/img/print.svg';
import IMG_preview from '../../../../../style/img/preview.svg';

import withPDFActions from 'components/viewPDF/withPDFActions';
import TableGrid from 'components/tableGrid/TableGrid.js';
import listFA_Production from './qql/listFA_Production';
import { useTranslation } from 'react-i18next';

export default withPDFActions((props) => {
  const { values } = props;
  const [t] = useTranslation();
  const week = ['IsMo', 'IsDi', 'IsMi', 'IsDo', 'IsFr', 'IsSa', 'IsSo'];
  const tableData = [
    {
      name: `${t('production.activated')}`,
      dataBase: 'isPrint',
      relativeWidth: 0.07,
    },

    {
      name: t('common.description'),
      relativeWidth: 0.3,
      dataBase: 'name',
    },
    {
      name: t('common.print'),
      dataBase: 'id',
      specialComponent: (index, _, value, data)=>{
        const isValid = ((data[index].addDay === 0 && values.order > 0) || (data[index].addDay > 0 && values.preOrder > 0));
        const isLabel = Math.round(data[index].reportID / 1000000) === 12000000;
        const isScale = Math.round(data[index].reportID / 1000000) === 49000000;
        return (
          <>
            <IMG onClick={()=> (isValid && !isLabel && !isScale) && props.sendPDFIntoPrinter({
              reportId: `${Math.round(data[index].reportID / 1000000)}`,
              AProductionPrintListID: +value,
              APeriodStart: props.values.fromDate,
              APeriodEnd: props.values.toDate || props.values.fromDate,
              AUserFormularID: '0',
              APrintRevision: '0',
            })}>
              <img style={{ opacity: (isValid && !isLabel && !isScale) ? '1' : '0.1' }} src={IMG_printer}/>
            </IMG>
          </>
          
        );
      },
    },
    {
      name: t('common.preview'),
      dataBase: 'id',
      specialComponent: (index, _, value, data)=>{
        const isValid = ((data[index].addDay === 0 && values.order > 0) || (data[index].addDay > 0 && values.preOrder > 0));
        const isLabel = Math.round(data[index].reportID / 1000000) === 12000000;
        const isScale = Math.round(data[index].reportID / 1000000) === 49000000;
        return (

          <>
            <IMG style={{float: 'left'}} onClick={()=>(isValid && !isLabel && !isScale) && props.showPDFPreview({
              reportId: `${Math.round(data[index].reportID / 1000000)}`,
              AProductionPrintListID: +value,
              APeriodStart: props.values.fromDate,
              APeriodEnd: props.values.toDate || props.values.fromDate,
              AUserFormularID: '0',
              APrintRevision: '0',
            })}>
              <img style={{ opacity: (isValid && !isLabel && !isScale) ? '1' : '0.1' }} src={IMG_preview}/>
            windows
            </IMG>

            <IMG style={{float: 'left'}} onClick={()=>(isValid && !isLabel && !isScale) && props.showPDFPreview({
              reportId: `${Math.round(data[index].reportID / 1000000)}`,
              AProductionPrintListID: +value,
              // APeriodStart: moment(props.values.fromDate, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
              // APeriodEnd: moment(props.values.toDate || props.values.fromDate, 'DD.MM.YYYY', true).format('YYYY-MM-DD'),
              APeriodStart: props.values.fromDate,
              APeriodEnd: props.values.toDate || props.values.fromDate,
              AUserFormularID: '0',
              APrintRevision: '0',
              isServerlessReport: true,
            })}>
              <img style={{ opacity: (isValid && !isLabel && !isScale) ? '1' : '0.1' }} src={IMG_preview}/>
            serverless
            </IMG>

          </>
        );
      },
    },
  ];
  return (
    <HeadBlock>
      <MainBlock>
        <TableGrid
          heightRow={60}
          withSearch
          graphqlParams={{
            query: listFA_Production,
            variables: { day: week[moment(props.values.fromDate).isoWeekday() - 1], fromDate: props.values.fromDate, toDate: props.values.toDate } }}
          height={600}
          headerText={t('common.allPrintList')}
          tableData={tableData}
        />
      </MainBlock>
    </HeadBlock>
  );
});

const HeadBlock = styled.div`
  height: 680px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
`;
const IMG = styled.div`
margin-left: 10px
`;

