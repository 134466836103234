import React from 'react';
// import { Row, Col } from 'react-bootstrap';
// import { useMutation } from 'components/graphql';

// import Formik from 'components/formik';
import { Dropdown, Input, Button } from 'components/elements';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import listFA_DefaultForms from '../qql/listFA_DefaultForms';
import listFA_UserForms from '../qql/listFA_UserForms';
import getListPrinters from '../qql/getListPrinters';
import useQuerySpecial from 'components/graphql/useQuerySpecial';
import { useTranslation } from 'react-i18next';

export default (props) =>{
  const [t] = useTranslation();
  const { data, setOpen, open, avaliableReportForms, onSave, avaliableReportDefault, withoutIdAvaliableReportDefault, avaliableReportGroup } = props;
  const arrTabs = ['Production Production',
    'Customer',
    'Article',
    'Default Order',
    'Order',
    'Invoice',
    'Debitor',
    'Production ForwardingLists',
    'Production CrossTable',
    'Offer',
    'Production SmartScale',
    'Procurement'];

  const allFormsDefault = useQuerySpecial(listFA_DefaultForms, { fetchPolicy: 'cache-first' });
  const allFormsUser = useQuerySpecial(listFA_UserForms, { fetchPolicy: 'cache-first' });
  return(
  	<div>
      {!allFormsDefault.loading && !allFormsUser.loading && <Formik
        initialValues={(data.formId ? allFormsUser.data.find((i) => i.id === data.formId) : (data.formIdDefault && allFormsDefault.data.find((i) => i.id === data.formIdDefault))) || {}}
        render={
          ({ values, setFieldValue, handleChange }) => {
          		let actualData = allFormsDefault.data || [];
          			if(allFormsDefault.data) {
          				if(avaliableReportForms) {
          					actualData = actualData.filter(({ id })=>avaliableReportForms.includes(id));
          				} else{
          					if(avaliableReportDefault) {
          						actualData = actualData.filter((item)=>avaliableReportDefault.includes(item.id));
          					}
          					if(withoutIdAvaliableReportDefault) {
          						actualData = actualData.filter((item)=>!withoutIdAvaliableReportDefault.includes(item.id));
          					}
          					if(avaliableReportGroup) {
          						actualData = actualData.filter((item)=>avaliableReportGroup.includes(item.reportGroupID));
          					}
          				}
          			} else{
          				actualData = [];
          			}

            return(
                	
              <div>
                {console.log(values)}
                <Main>
                	<Row>
                	<Col lg={6}>
              	{data.formId > 0 ? <Dropdown
              			          withoutFormik={true}
              			          setFieldValue={(name, value)=>{
              	                      setFieldValue('formIdDefault', value); setFieldValue('reportGroupID', actualData.filter(({ id })=>value === id)[0].reportGroupID);
              	                    }}
              	                    title={t('common.form')}
              	                    valueField = 'id'
              	                    labelField = 'name'
              	                    name = 'formIdDefault'
              	                    width = '90%'
              	                    defaultValueField = {values.formIdDefault || data && data.formIdDefault}
              	                    data={actualData}/> : <Input withoutFormik={true} width = '90%' value={actualData.filter((item)=>data && data.formIdDefault === item.id)[0].name} notEdit readonly field='formIdDefault' title='DateiName'/>}
                      <Input withoutFormik={true} width = '90%' value={ (arrTabs[values.reportGroupID - 1])} notEdit readonly field='reportGroupID' title={t('common.group')}/>
                      <Input
    		          withoutFormik={true}
    		          notEdit={data.formId > 0 ? false : true}
    		          width = '90%'
    		          onChange={handleChange}
    		          readonly={data.formId > 0 ? false : true}
    		           value={data.formId > 0 ? values.form || data.form : data.form} type='text' name='form' title={t('common.form')}/>
                      <Input
                        width = '90%'
    		          withoutFormik={true}
    		          onChange={handleChange} value={values.special} type='text' name='special' title={t('common.special')}/>
    
                    </Col>
                    <Col lg={6}>
                      {open && <Dropdown
                        width = '90%'
                        withoutFormik={true}
                        setFieldValue={setFieldValue}
                        title={t('common.printer')}
                        fieldQuery = 'printers'
                        valueField = 'PrinterName'
                        labelField = 'PrinterName'
                        name = 'printer'
                        defaultValueField = {values.printer}
                        graphqlParams={{ query: getListPrinters }}
                      />}
                      <Input
                        width = '90%'
          		          withoutFormik={true}
          		          onChange={handleChange} value={values.printerTray} name='printerTray' type='number' title={t('common.printerTray')}/>
                      <Input
                        width = '90%'
    		          withoutFormik={true}
    		          onChange={handleChange} value={values.copies} type='number' name='copies' title={t('common.copies')}/>
                  
                    </Col>
                  </Row>
                 
                </Main>
    
                <Footer>
            	
            	<Row>
                    <Col lg={6}>
                      <div style={{ marginRight: '15px', float: 'right' }}>
                        <Button
                          className={'grey-btn'}
                          width={'190px'}
                          onClick={()=> setOpen(false)}
                          value={t('common.cancel')}/>
                      </div>
                    </Col>
                    <Col lg={6}>
                        
                      <div style={{ marginLeft: '15px', float: 'left' }}>
                        <Button
                          className={'grey-btn'}
                          width={'190px'}
                          onClick={()=> onSave(values)}
                          value={t('common.save')}/>
                      </div>
                    </Col>
                  </Row>
                </Footer>
              </div>
            );
          }}
      />}
    </div>
  	);
};

const Main = styled.div`
  padding: 10px 20px 20px 20px;
`;
const Footer = styled.div`
  margin-top: 20px;
`;
