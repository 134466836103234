import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { Col } from 'style';
import { useQuery } from '@apollo/react-hooks';
import { get, find } from 'lodash';

import { useMutation } from 'components/graphql';

import { Dropdown } from 'components/elements';
import { changeLanguage, avaliableLanguages } from 'translation/i18next';
import { CONFIG, UPDATE_CONFIG } from './graphql';

const Language = ({ t }) => {
  const { data } = useQuery(CONFIG);
  const lngId = get(data, 'fakturaAssist.getFA_Config.languageID', null);
  const lng = find(avaliableLanguages, ({ languageId }) => languageId === lngId)?.id || 'en';

  if (lngId) {
    localStorage.setItem('lngId', lngId);
    localStorage.setItem('lng', lng);
  }

  const [updateConfig] = useMutation(UPDATE_CONFIG);

  const changeLng = (newLng) => {
    changeLanguage(newLng);
    const newLngId = find(avaliableLanguages, ({ id }) => id === newLng)?.languageId || 1;
    updateConfig({ variables: { fields: { languageID: newLngId } } });
  };

  return (
    <Fragment>
      <>
        <Col lg={4}>
          <Dropdown
            title={t('common.language')}
            defaultValueField = {localStorage.getItem('lng') ? localStorage.getItem('lng') : lng}
            data={avaliableLanguages}
            onChange = {({ value }) => changeLng(value)}
            withoutFormik
          />
        </Col>
      </>
    </Fragment>
  );
};

export default withTranslation()(Language);

