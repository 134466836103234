import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Button } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';
import Formik from 'components/formik';

import { usersTableParams, rolesTableParams } from './params';

const Tax = (props) => {
  const { t } = props;
  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() =>{}}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <>
            <Row>
              <Col lg={3}>
                <TableContent>
                  <TableGrid
                    tableData={usersTableParams(t)}
                    height={720}
                    disableHeightChange
                    data={[{ test: 'test' }, { test: 'test' }, { test: 'test' }]}

                  />
                </TableContent>
              </Col>
              <Col lg={9}>
                <Content>
                  <TableContent>
                    <TableGrid
                      tableData={rolesTableParams(t)}
                      height={333}
                      disableHeightChange
                      data={[{ test: 'test' }, { test: 'test' }, { test: 'test' }]}

                    />
                    <Row>
                      <Col>
                        <Button value={t('options.securityManager.changeAdminPassword')} width='250px' className ='green-btn' upperCase/>
                      </Col>
                    </Row>
                  </TableContent>
                </Content>
              </Col>
        
            </Row>
          </>
        );
      }}
    </Formik>
  );
};

export default Tax;

const Content = styled.div`
  padding: 10px 20px 20px 20px; 
  background: #f4f4f4;
  margin: 20px;
  min-height: 755px;
`;

const TableContent = styled.div`
  margin-top: 20px
`;
