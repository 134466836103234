import gql from 'graphql-tag';

export default gql`
	mutation deleteFA_TemporaryOffer($id: ID!){
    fakturaAssist{
    	deleteFA_TemporaryOffer(id: $id){
    		id
 		}
 	}
}`;
