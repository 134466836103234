import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Row } from 'style';
import { OptionsContent } from 'style';
import XLSX from 'xlsx';
import DXTableGrid from 'components/dxTableGrid';
import LoadingModal from 'components/Loading/LoadingModal';

import { Radio, Input, Button, Modal, Dropdown } from 'components/elements';

import Addition from 'components/List/Additional';

import { LoadingWrap } from 'style/default';

import { default as FileIcon } from 'style/img/options/file.svg';
import { IMPORT_TABLE, CHECK_DUBLICATES } from './gql';
import { useMutation } from 'components/graphql';

const fields = {
  articles: {
    queryType: 'PRODUCT',
    field: 'ArtikelNr',
  },
  customers: {
    queryType: 'CUSTOMER',
    field: 'KundenNr',
  },
  articleGroup: {
    queryType: 'PRODUCT_GROUP',
    field: 'Nummer',
  },
};

const Import = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [radio, setRadio] = useState('articles');
  const [importStarted, setImportStarted] = useState(false);
  const [file, setFile] = useState(null);
  const [workbook, setWorkbook] = useState(null);
  const [importData, { data: importResult, loading: importLoading, error: importError }] = useMutation(IMPORT_TABLE);
  const [checkDublicates, { loading, error }] = useMutation(CHECK_DUBLICATES);
  const fileInputRef = useRef(null);
  const [sheets, setSheets] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalContent, setInfoModalContent] = useState(null);
  const [infoModalHeader, setInfoModalHeader] = useState('Warning');
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [sheetData, setSheetData] = useState(null);
  const { t } = props;

  useEffect(() => {
    setSelectedSheet(sheets[0]);
  }, [sheets]);

  const open = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const clearSelection = () => {
    const input = fileInputRef.current;
    input.value = '';

    if(!/safari/i.test(navigator.userAgent)) {
      input.type = '';
      input.type = 'file';
    }
    setSheetData(null);
    setWorkbook(null);
    setSelectedSheet(null);
    setImportStarted(false);
    setSheets([]);
  };

  const handleRadioChange = (value) => {
    setRadio(value);
    setFile(null);
    clearSelection();
  };

  const parseSheets = (workbook, sheetName) => {
    const sheet = sheetName ? workbook.Sheets[sheetName] : workbook.Sheets[workbook.SheetNames[0]];
    const data = XLSX.utils.sheet_to_json(sheet);
    setSheetData(data);
  };

  useEffect(() => {
    selectedSheet && parseSheets(workbook, selectedSheet.name);
  }, [selectedSheet]);

  const handleSheetChange = (name, id) => {
    setSelectedSheet(sheets[id]);
  };

  const parseFile = (file, sheet) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, {
        type: 'binary',
      });
      setWorkbook(workbook);
      setSheets(workbook.SheetNames.map((name, index) => ({ name, id: index })));
    };
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      clearSelection();
      setFile(file);
      parseFile(file);
    }
  };

  const closeInfoModal = () => {
    setInfoModalOpen(false);
  };

  const showInfo = (string) => (
    <ModalWrapper>
      <Text>{string}</Text>
      <Button value={'close'} float='left' onClick={closeInfoModal} upperCase width='100%'/>
    </ModalWrapper>
  );

  const startImport = (mode) => {
    const items = sheetData.map((item) => JSON.stringify(item));
    importData({
      variables: {
        data: {
          type: fields[radio].queryType,
          mode,
          dataToImport: items,
        },
      },
    });
  };

  const ImportContent = (hasDublicates) => {
    return (
      <ModalWrapper>
        <Text>Table has items with ids that are already in database. Do you want to update or skip it?</Text>
        <Button value={'Update'} onClick={() => startImport('UPDATE')} upperCase width='48%' className='black' float='left' margin='0 4% 0 0'/>
        <Button value={'Skip'} onClick={() => startImport('SKIP')} upperCase width='48%' float='left'/>
      </ModalWrapper>
    );
  };

  const FinalModalContent = (success) => {
    return(
      <ModalWrapper>
        <Text>Import {success ? 'succeed' : 'failed'}.</Text>
        <Button value={'close'} onClick={closeInfoModal} upperCase width='100%' className='black' float='left' margin='0 4% 0 0'/>
      </ModalWrapper>
    );
  };

  const ErrorModalContent = () => {
    return (
      <ModalWrapper>
        <Text>Error occured.</Text>
        <Button value={'close'} onClick={closeInfoModal} upperCase width='100%' className='black' float='left' margin='0 4% 0 0'/>
      </ModalWrapper>
    );
  };

  useEffect(() => {
    if (error || importError) setInfoModalContent(ErrorModalContent);
  }, [error, importError]);

  useEffect(() => {
    if (loading) setInfoModalHeader('Checking for dublicates');
    else if (importLoading) setInfoModalHeader('Please wait this might take a while...');
    if (!loading && !importLoading && !importStarted && importResult && importResult.fakturaAssist) setInfoModalContent(FinalModalContent(importResult.fakturaAssist.importTable));
  }, [importLoading, importResult, loading, importStarted]);

  const handleImport = async () => {
    if (!sheetData) {
      setInfoModalOpen(true);
      setInfoModalContent(showInfo('No data to import'));
      return;
    }
    const neededField = fields[radio].field;
    if (!sheetData[0][neededField]) {
      setInfoModalOpen(true);
      setInfoModalContent(showInfo(`There is no required field ${neededField} in the table`));
      return;
    }
    setImportStarted(true);
    const hasDublicates = await checkDublicates({
      variables: {
        data: {
          type: fields[radio].queryType,
          dataToImport: sheetData.map((item) => JSON.stringify(item)),
        },
      },
    });
    if (hasDublicates.data.fakturaAssist.checkDublicates) setInfoModalContent(ImportContent);
    else startImport('SKIP');
    setInfoModalOpen(true);
    // setImportStarted(false);
  };

  useEffect(() => {
    if (importResult) setImportStarted(false);
  }, [importResult]);

  const modalContent = () => {
    return(
      <Content>
        <FlexBox>
          <Radio width='120px' title={t('common.articles')} checked={radio === 'articles'} withoutFormik onClick={() => handleRadioChange('articles')}/>
          <Radio width='120px' title={t('common.customers')} checked={radio === 'customers'} withoutFormik onClick={() => handleRadioChange('customers')}/>
          <Radio width='120px' title={t('product.productGroup')} checked={radio === 'articleGroup'} withoutFormik onClick={() => handleRadioChange('articleGroup')}/>
        </FlexBox>
        <FlexBox>
          <FileInputWrapper>
            <Input title={t('options.import.fileName')} type='text' width='450px' disabled value={file ? file.name : ''}/>
          </FileInputWrapper>
          <div style={{ position: 'relative' }}>
            <FileInput type='file' onChange={handleFileUpload} innerRef={fileInputRef} accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv'/>
            <Button value={t('options.import.openFile')} className='black' onClick={() => selectedSheet && parseSheets(workbook, selectedSheet.name)} upperCase margin='0 30px 0 0'/>
          </div>
          <div>
            <Dropdown
              data={sheets}
              withoutFormik={true}
              title={t('options.import.sheets')}
              valueField = 'id'
              labelField = 'name'
              name = 'productId'
              setFieldValue={handleSheetChange}
              defaultValueField = {selectedSheet ? selectedSheet.id : 0}
            />
          </div>
        </FlexBox>
        {sheetData && <DXTableGrid withoutQuery groupBy={[]} height={508} columns={[]} data={sheetData} manualGroup/>}
        <Actions>
          <CloseButton upperCase value={t('common.close')} onClick={close}/>
          <ImportButton upperCase value={t('options.import.import')} onClick={handleImport} />
        </Actions>
      </Content>
    );
  };

  return (
    <Addition
      {...props}
      text = {t('options.import.import')}
      menuObj={[{ name: t('options.import.import'), link: '/options/import' }]}
      paddingTop={10}
    >
      <LoadingWrap loading={props.loading}>
        <Main>
          <Modal isOpen={isOpen} headerText={`${t('options.import.import')} Excel + CSV`} width='95%' closeModal={close} Component={modalContent()}/>
          <OptionsContent>
            <Row>
              <Button value={`${t('options.import.import')} Excel + CSV`} onClick={open}/>
            </Row>
          </OptionsContent>
          <Modal isOpen={isInfoModalOpen && !loading && !importLoading} headerText={'Warning'} Component={infoModalContent}/>
          <Modal isOpen={loading || importLoading} background='none' width='350px' height='220px' Component = {<LoadingModal status={infoModalHeader}/>} />
        </Main>
      </LoadingWrap>
      
    </Addition>
  );
};

export default Import;

const Main = styled.div`
  padding-top: 20px;
  min-height: 80vh;
`;

const ModalWrapper = styled.div`
  margin-bottom: 20px;
  padding: 0px 20px 28px 20px;
`;

const Text = styled.h4`
  text-align: center;
  margin: 20px 10px;
`;

const FileInputWrapper = styled.div`
  margin-right: 20px;
  position: relative;
  cursor: pointer; 

  &::after {
    content: '';
    position: absolute;
    top: 45px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-image: url('${FileIcon}');
  }
`;

const FileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  width: 191px;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer; 
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const CloseButton = styled(Button)`
  background-color: #cdcdcd;
  margin-right: 30px;
`;

const ImportButton = styled(Button)`
  background-color: #3cb950;
`;

const Content = styled.div`
  padding: 20px;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: flex-end;
`;
