import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import { cloneDeep, orderBy } from 'lodash';

import { Checkbox, Input } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid.js';

import deleteFA_CustomerListsRelations from './qql/mutation/deleteFA_CustomerListsRelations';
import deleteFA_CustomerListsDefault from './qql/mutation/deleteFA_CustomerListsDefault';
import createFA_CustomerListsRelations from './qql/mutation/createFA_CustomerListsRelations';

import createFA_CustomerListsDefault from './qql/mutation/createFA_CustomerListsDefault';
import updateFA_CustomerListsDefault from './qql/mutation/updateFA_CustomerListsDefault';

import listFA_CustomerLists from './qql/listFA_CustomerLists';

import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const { loading } = useQuery(listFA_CustomerLists, {
    fetchPolicy: 'cache-first',
    variables: { id: +props.linkId },
  });
  const [mutation_create, { client }] = useMutation(createFA_CustomerListsRelations);
  const [mutation_delete] = useMutation(deleteFA_CustomerListsRelations);

  const handleCheckbox = (info, i, { column, order }) => {
    const cache = client.readQuery({
      query: listFA_CustomerLists,
      variables: { id: +props.linkId },
    });
    const copyData = cloneDeep(cache);
    const clonedList = orderBy(copyData.fakturaAssist.listFA_CustomerLists, [column], [order?.toLowerCase()]);
    
    clonedList[i].isValue = !info.isValue;

    info.isValue
      ?
      mutation_delete(
        {
          variables:
            {
              id: +info.customerListsRelationID,
            },
        }
      )
      :
      mutation_create({
        variables:
          {
            fields: {
              customerListLink: +info.id,
              linkCustomer: +props.linkId,
            },
          },
      });

    client.writeQuery({
      query: listFA_CustomerLists,
      variables: { id: +props.linkId },
      data: copyData,
    });
  };

  const tableData = [
    {
      name: '',
      dataBase: 'isValue',
      relativeWidth: 0.1,
      specialComponent: (i, ...rest)=>{
        const l = rest[2];
        const list = l ? orderBy(l, [rest[4].column], [rest[4].order?.toLowerCase()]) : [];
        return (
          <Checkbox
            margin={'0px'}
            zIndex={100}
            onClick={handleCheckbox.bind(this, list[i], i, rest[4])}
            value={loading ? false : list[i] === undefined ? false : list[i].isValue === undefined ? false : list[i].isValue}
            field='isActivated'
          />

        );
      },

    },
    {
      name: t('common.name'),
      dataBase: 'name',
      isMutation: true,
      isRequired: true,
    },
  ];

  const Component = (props)=>{
    const { handleChange, values } = props;
    return(
      <div>
        <Input
          width='100%'
          withoutFormik={true}
          type='text'
          onChange={handleChange}
          value={values.name}
          name={'name'}
          title={t('common.name')}
          setFieldTouched={props.setFieldTouched}
          touched={props.touched}
          err={props.errors}
        />
      </div>

    );
  };

  return (
    <HeadBlock>
      <MainBlock>
        <Row>
          <Col width='450px'>
            <TableGrid
              withSearch
              graphqlParams={{
                query: listFA_CustomerLists,
                variables: { id: +props.linkId } }}
              //searchValue={this.state.searchValue}
              //onSearch={this.onSearch}
              //getSortField={this.getSortField}
              Component={Component}
              height={700}
              headerText={t('common.list')}
              modalWidth={440}
              modalHeight={235}
              isRefetchAfterMutation={false}
              //editItem={this.openModal}
              tableData={tableData}
              withModalMutations
              //getTableIndex={this.indexOnClickTable}
              itemDeleted={deleteFA_CustomerListsDefault}
              defaultValuesForCache={['isValue', 'customerListsRelationID', 'linkCustomer']}
              defaultValuesForMutation = {{ linkCustomer: +props.linkId }}
              typeName={'FA_CustomerLists'}
              qqlMutationCreate={createFA_CustomerListsDefault}
              qqlMutationUpdate={updateFA_CustomerListsDefault}
              dataPolicy='local'
              initialSortValue='name'
            />
          </Col>
        </Row>
      </MainBlock>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;

const MainBlock = styled(Col)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
`;
