import React, { useState } from 'react';
import styled from 'styled-components';

import IconWrapper from './IconWrapper';
// import { ReactComponent as Edit } from 'style/img/product/edit.svg';
import { ReactComponent as Add } from 'style/img/add_image.svg';
import { ReactComponent as Replace } from 'style/img/replace_image.svg';
import imageActions from '../helpers/imageActions';
import { ErrorModal } from 'components/elements';

const ImageEdit = ({ mutation, result, id, client, t, newImage, updateImage }) => {
  const [errorText, setErrorText] = useState(null);
  const { uploadImage } = imageActions(mutation, id, client);

  const validateImage = async (e) => {
    const image = e.target.files[0];
    
    if (!image) return;

    if (image.size > 1678095){
      setErrorText(t('common.validation.selectFileLessThan1_7Mb'));
      e.target.value = null;
      return;
    }

    uploadImage(e);
  };

  return (
    <>
      <ErrorModal errorText={errorText} setErrorText={setErrorText}/>
      <IconWrapper width={newImage ? '100%' : '50%'}>
        {!updateImage && newImage && <HiddenInput type='file' stretched onChange={ (e) => validateImage(e) } accept='image/jpeg,image/png,image/jpg'/>}
        {newImage && <Add />}
        {updateImage && !newImage && <FileWrapper>
          <HiddenInput type='file' id='file' onChange={ (e) => validateImage(e) } accept='image/jpeg,image/png,image/jpg' />
          <ForLabel for="file">
            <Replace />
          </ForLabel>          
        </FileWrapper>}
      </IconWrapper>
    </>
  );
};

const HiddenInput = styled.input`
    position: absolute;
    cursor: pointer;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: ${({ stretched }) => stretched ? '100%' : '0px'};
    height: ${({ stretched }) => stretched ? '100%' : '0px'};
    :hover {
      cursor: pointer;
    }
`;

const FileWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

const ForLabel = styled.label`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default ImageEdit;
