import gql from 'graphql-tag';

export default gql`
mutation deleteFA_Discount($id: ID!){
    fakturaAssist{
    deleteFA_Discount(id: $id){
		id
    }
    
  }
}`;
