import gql from 'graphql-tag';

export default gql`
mutation changeStatusCAdirectProduction($onDate: String!, $status: Boolean!){
    fakturaAssist{
    changeStatusCAdirectProduction(onDate: $onDate, status: $status){
		RecordID
    }
    
  }
}`;
