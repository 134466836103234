import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row } from 'style';
import { useTranslation } from 'react-i18next';

import { Modal, Button } from '../';

const ConfirmModal = ({ closeModal, setIsOutsideClick, isOutsideClick }) => {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(isOutsideClick);

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsOutsideClick(false);
  };

  const ConfirmModalComponent = () => {
    return(
      <ConfirmBlock>
        <BtnContainer>
          <Button
            className={'grey-btn'}
            width='150px'
            upperCase
            height={'40px'}
            style={{ fontSize: 14 }}
            onClick={handleCloseModal}
            value={t('common.cancel')}
          />
          <Button
            width='150px'
            upperCase
            height={'40px'}
            style={{ fontSize: 14 }}
            onClick={() => {
              closeModal();
              handleCloseModal();
            }}
            value={t('common.ok')}
          />
        </BtnContainer>
      </ConfirmBlock>
    );
  };

  return (
  	<Modal
      onRequestClose={handleCloseModal}
      width='450px'
  		Component={ConfirmModalComponent()}
  		headerText= {t('common.unsavedChanges')}
      isOpen={isOpen}
      closeModal={handleCloseModal}
  	/>
  );
};

export default ConfirmModal;

const ConfirmBlock = styled.div`
	width: 450px;
  height: 85px;
  padding: 20px;
  background: #fff;
  text-align: center;
  position: relative;
  p {
    font-size: 16px;
  }
`;

const BtnContainer = styled(Row)`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
	justify-content: space-between;
`;

ConfirmModal.propTypes = {
  isOutsideClick: PropTypes.bool,
  setIsOutsideClick: PropTypes.func,
};
