import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import Formik from '../../../../../components/formik';
//import TableGrid from '../../../../../components/container/tableGrid/TableGrid.js';

// import { tableData } from './params';

import listFA_CustomerView_fkCurrencies from '../../qql/query/listFA_CustomerView_fkCurrencies';
import listFA_CustomerInvoiceSummaryReportID from '../../qql/query/listFA_CustomerInvoiceSummaryReportID';
import listFA_CustomerFormulareFacturaAdchnung from '../../qql/query/listFA_CustomerFormulareFacturaAdchnung';
import listFA_CustomerView_fkDebitorStatuses from '../../qql/query/listFA_CustomerView_fkDebitorStatuses';
//import listFA_CustomerView_fkAllFormular from '../../qql/query/listFA_CustomerView_fkAllFormular';
import listFA_CustomerSammelfakturaAufKunde from '../../qql/query/listFA_CustomerSammelfakturaAufKunde';
import listFA_CustomerPaymentSlipReportID from '../../qql/query/listFA_CustomerPaymentSlipReportID';

//import createFA_Discount from './qql/mutation/createFA_Discount';

import { Input, Dropdown, Textarea, ReportSystemInput } from '../../../../../components/elements';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const pdfParams = props.sectionInfo.defaultPDFParams(props.linkId);
  // const Component = (props)=>{
  //   const { handleChange, values } = props;
  //   return(
  //     <div>
  //       <Input
  //         width='100%'
  //         withoutFormik={true}
  //         type='text'
  //         onChange={handleChange}
  //         value={values.name}
  //         name={'name'}
  //         title={'Name'}
  //       />
  //     </div>

  //   );
  // };
  return (
    <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        // console.log('Invoice', values.collectingInvoice)
        return (
          <HeadBlock>
            <Row>
              <Col width='330px'>
                <ReportSystemInput
                  pdfParams={pdfParams}
                  title={t('common.form')}
                  avaliableReportForms={[9, 19, 31, 39]}
                  defaultValueField={values.invoiceReportID}
                  avaliableReportGroup={[6]}
                  field='invoiceReportID'
                />
              </Col>
              <Col width='170px'>
                <Input small value={values.invoiceCopies} field='invoiceCopies' type='number' title={t('common.copies')} />
              </Col>
              <Col lg={4}>
                <Dropdown
                  graphqlParams={{
                    query: listFA_CustomerView_fkCurrencies,
                  }}
                  title={t('customer.invoice.invoiceCurrency')}
                  valueField='id'
                  labelField='name'
                  withIndex
                  field='currencyID'
                  defaultValueField={values.currencyID}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <ReportSystemInput
                  pdfParams={pdfParams}
                  title={t('customer.invoice.invoiceSummary')}
                  avaliableReportForms={[-1000000, 17, 52]}
                  defaultValueField={values.invoiceSummaryReportID}
                  graphqlParams={{
                    query: listFA_CustomerInvoiceSummaryReportID,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                  avaliableReportGroup={[6]}
                  field='invoiceSummaryReportID'
                  typeName={'FA_CustomerInvoiceSummaryReportID'}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <ReportSystemInput
                  title={t('customer.invoice.paymentSlip')}
                  avaliableReportForms={[18, 59, -1000000]}
                  defaultValueField={values.invoicePaymentSlipReportID}
                  graphqlParams={{
                    query: listFA_CustomerPaymentSlipReportID,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                  avaliableReportGroup={[6]}
                  field='invoicePaymentSlipReportID'
                  typeName={'FA_CustomerInvoiceSummaryReportID'}
                />
              </Col>
            </Row>
            <Row>
              <Col width='330px'>
                <Dropdown
                  graphqlParams={{
                    query: listFA_CustomerFormulareFacturaAdchnung,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                  title={t('customer.invoice.accounting')}
                  valueField='id'
                  labelField='name'
                  withIndex
                  field='fakturaAccounting'
                  defaultValueField={values.fakturaAccounting}
                />
              </Col>
              <Col lg={4}>
                <Dropdown
                  graphqlParams={{
                    query: listFA_CustomerView_fkDebitorStatuses,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                  title={t('debitor.debitor')}
                  valueField='id'
                  labelField='name'
                  withIndex
                  field='debitorStatus'
                  defaultValueField={values.debitorStatus}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Dropdown
                  graphqlParams={{
                    query: listFA_CustomerSammelfakturaAufKunde,
                  }}
                  title={t('customer.invoice.customerInvoicedWith')}
                  valueField='id'
                  field='collectingInvoice'
                  defaultValueField={values.collectingInvoice}
                  withVirtualized
                  isClearable
                />
              </Col>
            </Row>
            <Row>
              <Col width='100%'>
                <Textarea
                  title={t('customer.invoice.individualInvoiceComments')}
                  value={values.descriptionFaktura}
                  onBlur={handleSubmit}
                  label='Note'
                  width='95%'
                  height='300px'
                  field='descriptionFaktura'
                />
              </Col>
            </Row>
            <Row>
              <Col width='100%'>
                <Textarea
                  title={t('customer.invoice.additionalInvoiceComments')}
                  value={values.descriptionFaktura2}
                  onBlur={handleSubmit}
                  label='Note'
                  width='95%'
                  height='300px'
                  field='descriptionFaktura2'
                />
              </Col>
            </Row>

          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  height: 1200px;
  padding: 0px 20px 20px 20px;
`;
