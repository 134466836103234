
import gql from 'graphql-tag';

export default gql`
mutation createFA_SpecialPrices($fields: SpecialPricesFields!){
    fakturaAssist{
    createFA_SpecialPrices(fields: $fields){
		id
		specialPriceTypeId
		currentPrice
		productNr
    productName
		
    }
    
  }
}`;
