import gql from 'graphql-tag';

export default gql`
query listFA_OrderPositionsSortingKinds{
    fakturaAssist{
    	listFA_OrderPositionsSortingKinds{
    		id
			name
 		}
 	}
}`;
