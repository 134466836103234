/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { useMutation } from 'components/graphql';
import QueryComponent from 'components/graphql/Query';

import TableForFreezer from './TableForFreezer';
import updateQuantityFreezer from './qql/updateQuantityFreezer.js';
import listFA_ProductionFreezer from './qql/listFA_ProductionFreezer.js';

export default (props) => {
  const [customer, setCustomer] = useState([]);
  const [nameCustomer, setNameCustomer] = useState([]);
  const [mainProduct, setMainProduct] = useState([]);
  const [mainProductChild, setMainProductChild] = useState([]);
  const [mainProductInfo, setMainProductInfo] = useState([]);
  const [mainProductChildForCustomer, setMainProductChildForCustomer] = useState([]);
  const [ProductChildIndexForCustomer, setProductChildIndexForCustomer] = useState([]);
  const [arrIdForUpdate, setArrIdForUpdate] = useState([]);
  const [arrValueOfIdForUpdate, setArrValueOfIdForUpdate] = useState([]);
  const [arrIdLieferscheinForUpdate, setArrIdLieferscheinForUpdate] = useState([]);
  const [mainCustomerproductinfo, setMainCustomerproductinfo] = useState([]);
  const [index, setIndex] = useState(null);
  const [indexIngridient, setIndexIngridient] = useState(null);

  const [mutation] = useMutation(updateQuantityFreezer);

  const saveChange = () => {
    if (arrIdForUpdate.length > 0 && arrIdLieferscheinForUpdate.length > 0 && arrValueOfIdForUpdate.length > 0) {
      mutation({
        variables: { arrIdForUpdate: arrIdForUpdate.join(), arrIdLieferscheinForUpdate: arrIdLieferscheinForUpdate.join(), arrValueOfIdForUpdate: arrValueOfIdForUpdate.join() },
      });
    }
  };

  const onClickIngridientTable = (index)=>{
    setIndexIngridient(index);
  };

  const onClickMainTable = (id) =>{
    const ProductChild = [];
    const mainProductChildForCustomerCopy = [];
    const ProductChildIndexForCustomerCopy = [];
    let lengthMain = 0;
    const arrLength = [];
    const length = mainProductChild[id].length;
    for(let i = 0; i < id; i++) {
      lengthMain = lengthMain + mainProductChild[i].length;
    }
    for(let i = lengthMain; i < lengthMain + length; i++) {
      arrLength.push(i);
    }

    mainCustomerproductinfo.forEach((item, index)=>{
      ProductChild.push([],);
      item.forEach((itemProductinfo, indexProductinfo)=>{
        if(arrLength.indexOf(indexProductinfo) >= 0) {
          ProductChild[index].push(itemProductinfo);
        }
      });
    });
    ProductChild.forEach((item, index)=>{
      item.forEach((itemProductinfo, indexProductinfo)=>{
        if(itemProductinfo.Menge && ProductChildIndexForCustomerCopy.indexOf(index) < 0) {
          ProductChildIndexForCustomerCopy.push(index);
        }
      });
    });
    ProductChildIndexForCustomerCopy.forEach((item, index)=>{
      ProductChild.forEach((itemChild, indexChild)=>{
        if(item === indexChild) {
          mainProductChildForCustomerCopy.push(itemChild);
        }
      });
    });

    setMainProductChildForCustomer(mainProductChildForCustomerCopy);
    setProductChildIndexForCustomer(ProductChildIndexForCustomerCopy);
    setIndex(id);
  };

  const mengeAdd = (index, id) =>{
    const arrIdForUpdateCopy = arrIdForUpdate;
    const arrValueOfIdForUpdateCopy = arrValueOfIdForUpdate;
    const arrIdLieferscheinForUpdateCopy = arrIdLieferscheinForUpdate;
    const mainProductChildForCustomerCopy = cloneDeep(mainProductChildForCustomer);
    mainProductChildForCustomerCopy[index][id].Menge = mainProductChildForCustomerCopy[index][id].Menge + (1 * mainProductChildForCustomerCopy[index][id].Multiplier);

    setMainProductChildForCustomer(mainProductChildForCustomerCopy);

    {/** next code for update in server**/}

    if(arrIdForUpdateCopy.indexOf(mainProductChildForCustomerCopy[index][id].id) < 0) {
      arrIdForUpdateCopy.push(mainProductChildForCustomerCopy[index][id].id);
      arrValueOfIdForUpdateCopy.push(mainProductChildForCustomerCopy[index][id].Menge);
    } else{
      arrValueOfIdForUpdateCopy[arrIdForUpdateCopy.indexOf(mainProductChildForCustomerCopy[index][id].id)] = mainProductChildForCustomerCopy[index][id].Menge;
    }
    if(arrIdLieferscheinForUpdateCopy.indexOf(mainProductChildForCustomerCopy[index][id].idLieferschein) < 0) {
      arrIdLieferscheinForUpdateCopy.push(mainProductChildForCustomerCopy[index][id].idLieferschein);
    }
    setArrValueOfIdForUpdate(arrValueOfIdForUpdateCopy);
    setArrIdForUpdate(arrIdForUpdateCopy);
    setArrIdLieferscheinForUpdate(arrIdLieferscheinForUpdateCopy);
  };

  const mengeAway = (index, id) =>{
    const arrIdForUpdateCopy = arrIdForUpdate;
    const arrValueOfIdForUpdateCopy = arrValueOfIdForUpdate;
    const arrIdLieferscheinForUpdateCopy = arrIdLieferscheinForUpdate;
    const mainProductChildForCustomerCopy = cloneDeep(mainProductChildForCustomer);
    mainProductChildForCustomerCopy[index][id].Menge = mainProductChildForCustomerCopy[index][id].Menge - (1 * mainProductChildForCustomerCopy[index][id].Multiplier);
    setMainProductChildForCustomer(mainProductChildForCustomerCopy);

    {/** next code for update in server**/}

    if(arrIdForUpdateCopy.indexOf(mainProductChildForCustomerCopy[index][id].id) < 0) {
      arrIdForUpdateCopy.push(mainProductChildForCustomerCopy[index][id].id);
      arrValueOfIdForUpdateCopy.push(mainProductChildForCustomerCopy[index][id].Menge);
    } else{
      arrValueOfIdForUpdateCopy[arrIdForUpdateCopy.indexOf(mainProductChildForCustomerCopy[index][id].id)] = mainProductChildForCustomerCopy[index][id].Menge;
    }
    if(arrIdLieferscheinForUpdateCopy.indexOf(mainProductChildForCustomerCopy[index][id].idLieferschein) < 0) {
      arrIdLieferscheinForUpdateCopy.push(mainProductChildForCustomerCopy[index][id].idLieferschein);
    }
    setArrValueOfIdForUpdate(arrValueOfIdForUpdateCopy);
    setArrIdForUpdate(arrIdForUpdateCopy);
    setArrIdLieferscheinForUpdate(arrIdLieferscheinForUpdateCopy);
  };

  const onCompleted = (data) => {
    const customerCopy = [];
    const nameCustomerCopy = [];
    const CustomerProductInfoCopy = [];
    const mainProductCopy = [];
    const mainProductChildCopy = [];
    const mainProductInfoCopy = [];
    const productChildIdCopy = [];
    const customerChildIdCopy = [];
    const mainCustomerproductinfoCopy = [];

    data.fakturaAssist.listFA_ProductionFreezer && data.fakturaAssist.listFA_ProductionFreezer.forEach((item, index)=>{
      if(customerCopy.indexOf(item.KundenNr) < 0) {
        customerCopy.push(item.KundenNr);
        nameCustomerCopy.push(item.KundenNr + ' ' + item.Name);
      }

      if(mainProductCopy.indexOf(item.SourceArticleNo) < 0) {
        mainProductCopy.push(item.SourceArticleNo);
        mainProductInfoCopy.push(
          {
            SourceArticleNo: item.SourceArticleNo,
            SourceArticleName: item.SourceArticleName,
            AvailableQuantity: item.AvailableQuantity,
            RequiredByNormalCustomers: item.RequiredByNormalCustomers,
            RequiredByFlexibleCustomers: item.RequiredByFlexibleCustomers });
      }
    });

    customerCopy.forEach((itemCustomer, indexCustomer)=>{
      mainCustomerproductinfoCopy.push([],);
      CustomerProductInfoCopy.push([],);
      data.fakturaAssist.listFA_ProductionFreezer && data.fakturaAssist.listFA_ProductionFreezer.forEach((itemData, indexData)=>{
        if(itemCustomer === itemData.KundenNr) {
          CustomerProductInfoCopy[indexCustomer].push({ OrderedArticleNo: itemData.OrderedArticleNo, LieferscheinNr: itemData.LieferscheinNr, Menge: itemData.Menge, id: itemData.LieferscheinPositionIntern, idLieferschein: itemData.LieferscheinIntern, additionallyMenge: itemData.Menge, Multiplier: itemData.Multiplier });
        } else{
          CustomerProductInfoCopy[indexCustomer].push({ OrderedArticleNo: itemData.OrderedArticleNo, LieferscheinNr: null, Menge: null, id: itemData.LieferscheinPositionIntern, idLieferschein: itemData.LieferscheinIntern, additionallyMenge: null, Multiplier: itemData.Multiplier });
        }
      });
    });

    CustomerProductInfoCopy.forEach((itemCustomerInfo, indexCustomerInfo)=>{
      itemCustomerInfo.forEach((item, index)=>{
        if(customerChildIdCopy.indexOf(item.OrderedArticleNo) < 0) {
          customerChildIdCopy.push(item.OrderedArticleNo);
        }
      });
    });

    customerChildIdCopy.forEach((item, index)=>{
      CustomerProductInfoCopy.forEach((itemCustomerInfo, indexCustomerInfo)=>{
        itemCustomerInfo.forEach((itemItemCustomerInfo, indexIndexCustomerInfo)=>{
          if(item === itemItemCustomerInfo.OrderedArticleNo) {
            if(mainCustomerproductinfoCopy[indexCustomerInfo][index]) {
              mainCustomerproductinfoCopy[indexCustomerInfo][index].LieferscheinNr = +mainCustomerproductinfoCopy[indexCustomerInfo][index].LieferscheinNr + itemItemCustomerInfo.LieferscheinNr;
              mainCustomerproductinfoCopy[indexCustomerInfo][index].Menge = +mainCustomerproductinfoCopy[indexCustomerInfo][index].Menge + itemItemCustomerInfo.Menge;
              mainCustomerproductinfoCopy[indexCustomerInfo][index].id = itemItemCustomerInfo.Menge ? itemItemCustomerInfo.id : mainCustomerproductinfoCopy[indexCustomerInfo][index].id;
              mainCustomerproductinfoCopy[indexCustomerInfo][index].idLieferschein = itemItemCustomerInfo.Menge ? itemItemCustomerInfo.idLieferschein : mainCustomerproductinfoCopy[indexCustomerInfo][index].idLieferschein;
              mainCustomerproductinfoCopy[indexCustomerInfo][index].additionallyMenge = itemItemCustomerInfo.Menge ? itemItemCustomerInfo.additionallyMenge : mainCustomerproductinfoCopy[indexCustomerInfo][index].additionallyMenge;
              mainCustomerproductinfoCopy[indexCustomerInfo][index].Multiplier = itemItemCustomerInfo.Menge ? itemItemCustomerInfo.Multiplier : mainCustomerproductinfoCopy[indexCustomerInfo][index].Multiplier;
            } else{
              mainCustomerproductinfoCopy[indexCustomerInfo].push({ LieferscheinNr: itemItemCustomerInfo.LieferscheinNr, Menge: itemItemCustomerInfo.Menge, id: itemItemCustomerInfo.id, idLieferschein: itemItemCustomerInfo.idLieferschein, additionallyMenge: itemItemCustomerInfo.additionallyMenge, Multiplier: itemItemCustomerInfo.Multiplier });
            }
          }
        });
      });
    });
      
    mainProductCopy.forEach((itemProduct, indexProduct)=>{
      mainProductChildCopy.push([],);
      data.fakturaAssist.listFA_ProductionFreezer && data.fakturaAssist.listFA_ProductionFreezer.forEach((itemData, indexData)=>{
        if(itemProduct === itemData.SourceArticleNo) {
          if(productChildIdCopy.indexOf(itemData.OrderedArticleNo) < 0) {
            productChildIdCopy.push(itemData.OrderedArticleNo);
            mainProductChildCopy[indexProduct].push({ OrderedArticleNo: itemData.OrderedArticleNo, OrderedArticleName: itemData.OrderedArticleName });
          }
        }
      });
    });

    setCustomer(customerCopy);
    setNameCustomer(nameCustomerCopy);
    setMainCustomerproductinfo(mainCustomerproductinfoCopy);
    setMainProduct(mainProductCopy);
    setMainProductChild(mainProductChildCopy);
    setMainProductInfo(mainProductInfoCopy);
    setArrIdLieferscheinForUpdate([]);
    setArrIdForUpdate([]);
    setArrValueOfIdForUpdate([]);
    setIndex(null);
  };

  return (
    <Row >
      <QueryComponent
        fetchPolicy={'network-only'}
        query={listFA_ProductionFreezer}
        variables={{ date: props.date }}
        onCompleted = {onCompleted}>
        {({ loading, error, data }) => {
          return(
            <div tabIndex='99' onBlur={saveChange}>
              <TableForFreezer
                customer={customer}
                nameCustomer={nameCustomer}
                mainProduct={mainProduct}
                mainProductChild={mainProductChild}
                mainProductInfo={mainProductInfo}
                mainCustomerproductinfo={mainCustomerproductinfo}
                mainProductChildForCustomer={mainProductChildForCustomer}
                ProductChildIndexForCustomer={ProductChildIndexForCustomer}
                index={index}
                indexIngridient={indexIngridient}
                arrIdForUpdate={arrIdForUpdate}
                arrValueOfIdForUpdate={arrValueOfIdForUpdate}
                arrIdLieferscheinForUpdate={arrIdLieferscheinForUpdate}
                loading={loading}
                onClickIngridientTable={onClickIngridientTable}
                mengeAdd={mengeAdd}
                onClickMainTable={onClickMainTable}
                mengeAway={mengeAway}
              />
            </div>
          );
        }}
      </QueryComponent>
    </Row>
  );
};
