import gql from 'graphql-tag';

export default gql`
query ($args: FA_InvoiceOrdersInputs!){
    fakturaAssist{
    	listFA_InvoiceOrders(args: $args){
    		id
			date
			customerId
			customerNr
			name
			orderId
			total
			orderType
			pdfLink
 		}
 	}
}`;
