import React from 'react';
import { Row, Col } from 'style';
import Formik from 'components/formik';

import { Dropdown, Input, Calendar, Text } from 'components/elements';

import listFA_CustomerPayment from '../qql/listFA_CustomerPayment';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <div>

            <Row>
              <Col lg={4}>
                <Dropdown
                  title={t('customer.bank.debit')}
                  valueField = 'id'
                  labelField = 'name'
                  field = 'debit'
                  defaultValueField = {values.debit}
                  graphqlParams={{ query: listFA_CustomerPayment,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Text marginTop='25px' text={t('common.bank') + ' 1'}/>

              </Col>
            </Row>
            <Row>
              <Col width='330px'>
                <Input value={values.bankName1} field='bankName1' title={t('common.bankName')}/>
              </Col>
              <Col width='330px'>
                <Input value={values.bankIBAN1} field='bankIBAN1' title={t('common.iban')}/>
              </Col>
              <Col width='330px'>
                <Input value={values.mandatNumber1} field='mandatNumber1' title={t('customer.bank.mandatNumber')}/>
              </Col>
            </Row>
            <Row>
              <Col lg={2}>
                <Input value={values.bankBLZ1} small field='bankBLZ1' title={t('common.bin')}/>
              </Col>
              <Col width='170px'>
                <Input value={values.bankKonto1} small field='bankKonto1' title={t('common.accountNo')}/>
              </Col>
              <Col width='330px'>
                <Input value={values.bankBIZ1} field='bankBIZ1' title={t('common.bic')}/>
              </Col>
              <Col lg={2}>
                <Calendar
                  field='mandatSignatureDate1'
                  title={t('customer.bank.dateOfMandatSignature')}
                  value={values.mandatSignatureDate1}
                />
              </Col>
            </Row>
            <Row>
              
              <Col lg={4}>
                <Text marginTop='25px' text={t('common.bank') + ' 2'}/>
              </Col>
            </Row>

            <Row>
              <Col width='330px'>
                <Input value={values.bankName2} field='bankName2' title={t('common.bankName')}/>
              </Col>
              <Col width='330px'>
                <Input value={values.bankIBAN2} field='bankIBAN2' title={t('common.iban')}/>
              </Col>
              <Col width='330px'>
                <Input value={values.mandatNumber2} field='mandatNumber2' title={t('customer.bank.mandatNumber')}/>
              </Col>
            </Row>

            <Row>
              <Col lg={2}>
                <Input value={values.bankBLZ2} small field='bankBLZ2' title={t('common.bin')}/>
              </Col>
              <Col width='170px'>
                <Input value={values.bankKonto2} small field='bankKonto2' title={t('common.accountNo')}/>
              </Col>
              <Col width='330px'>
                <Input value={values.bankBIZ2} field='bankBIZ2' title={t('common.bic')}/>
              </Col>
              <Col lg={2}>
                <Calendar
                  field='mandatSignatureDate2'
                  title={t('customer.bank.dateOfMandatSignature')}
                  value={values.mandatSignatureDate2}
                />
              </Col>
            </Row>
          </div>
        );
      }}
    </Formik>
  );
};


