import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Row, Col } from 'style';

import { Text } from 'components/elements/hoc/withName';
import Formik from 'components/formik/Formik';
import { Button, Input, TextAreaStandart } from 'components/elements';

import PDFIcon from 'style/img/actions/pdf.svg';

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const EmailModal = ({ closeModal, email, onChange, acceptSendPDFIntoEmail, t }) => (
  <Formik initialValues={{ email: email, subject: t('common.title'), message: t('common.message') }} enableReinitialize={true} validationSchema={EmailSchema} >
    {({ handleSubmit, handleChange, values, setFieldValue, errors }) =>{
      console.log(errors);
      return (
        <ModalBody>
          <Row>
            <Col>
              <Row>
                <Col width='330px'>
                  <Input value={values.email} field='email' title={t('common.recipient')} type='email'/>
                </Col>
              </Row>
              <Row>
                <Col width='330px' >
                  <SubjectInputBlock>
                    <Input value={values.subject} field='subject' title={t('common.subject')} />
                  </SubjectInputBlock>
                </Col>
              </Row>
            </Col>
            <Col>
              <Text> Attachments</Text>
        
              <PDFNameBlock>
                <img src={PDFIcon} alt=''/>
                <span>Reminder 1234312113.pdf </span>
              </PDFNameBlock>
            </Col>
          </Row>
          <Row>
            <Col>
              <TextAreaStandart title={t('common.message')} value={values.message} field='message' width='630px' height='200px'/>
            </Col>
          </Row>
          <BottomBlock>
    		<Col lg={5}>
    			<Button value ={t('common.no')} className='grey-btn' upperCase onClick={closeModal}/>
    		</Col>
    		<Col lg={3}>
    			<Button value ={t('common.ok')} className='black' upperCase onClick={() => acceptSendPDFIntoEmail(values)}/>
    		</Col>
    	</BottomBlock>
        </ModalBody>
      );
    }}
  </Formik>
);

const ModalBody = styled.div`
  padding: 20px;

  font-size: 15px;
`;

const PDFNameBlock = styled.div`
width: 300px;
height: 116px;
border-radius: 3px;
box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
background-color: #ffffff;
img{
  padding: 10px;
}
span{
  dispay: block;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a
}
`;

const BottomBlock = styled(Row)`
  margin-top: 40px;
  justify-content: center;
`;

const SubjectInputBlock = styled.div`
  margin-top: 9px;
`;

export default EmailModal;
