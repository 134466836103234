//

import gql from 'graphql-tag';

export default gql`
mutation sendPDFToPrint($PrinterName: String, $TrayID: String, $Downloadlink: String, $Copies: String, ){
    fakturaAssist{
    sendPDFToPrint(PrinterName: $PrinterName, TrayID: $TrayID, Downloadlink: $Downloadlink, Copies: $Copies){
		State
    }
    
  }
}`;
