import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';

import { useTranslation } from 'react-i18next';
import { cloneDeep, isEqual } from 'lodash';
import { compose } from 'recompose';
import TableDefOrder from '../../customer/defaultOrder/tabs/tableDefOrder';

import { Modal } from 'components/elements';
import Items from 'components/List/Items';

import Addition from 'components/List/Additional';

import Query from 'components/graphql/Query';
import withParams from 'components/withParams/withParams';

import useFilterInfo from './hooks/useFilterInfo';
import useOrderTypes from './hooks/useOrderTypes';
import useDelivery from '../helpers/useDelivery';
import useNewOrder from './hooks/useNewOrder';
import useTemporaryOrder from './hooks/useTemporaryOrder';
import useDeleteCustomerFromList from '../helpers/useDeleteCustomerFromList';

import {
  listFA_CustomersListForCreatingOrdersOnDate,
  getFA_GetDefaultOrderForCustomerOnDate,
  getFA_CustomerDeliveryPrices,
  getFA_TemporaryOrder,
} from '../qql/query';
import { noOrder } from '../qql/mutation';

import { OrderCreatingModal, WarningModal, InfoCustomer, PreviousOrdersModal, MultiOrdersModal } from './modals';

import Filter from 'components/elements/Filter';

import { Main } from './pages';
import { tableData } from './params';
import { writeQuery } from 'helpers/queryOperations';
import { leftGridColors } from '../info';

const CreateOrder = (props) => {
  const { client, linkId, contex, mainsection, location, data } = props;
  const { orderType } = props.contex;
  const { filterData, filterRaw, filterQuery } = props.subSectionInfo;
  const orderDate = props.contex?.date?.date;
  const [deliveryNr, setDeliveryNr] = useState(null);
  const [localData, setLocalData] = useState([]);
  const [confirmedFilterInfo, setConfirmedFilterInfo] = useState(JSON.parse(localStorage.getItem('order/create')) || []);
  const refetchRef = useRef(null);
  const loadingRef = useRef(null);
  const [chooseCustomerInfo, changeChooseCustomerInfo] = useState({ temporaryId: 0, offerId: 0 });
  const [chooseCustomerIndex, changeChooseCustomerIndex] = useState(0);

  const [networkStatus, setNetworkStatus] = useState(null);
  const [prevVariables, setPrevVariables] = useState(null);
  const [prevCustomerInfo, setPrevCustomerInfo] = useState({ index: null, info: null });
  const [tempDataCopy, setTempDataCopy] = useState({
    orderRef: '',
    deliveryTime: '',
  });

  // //Fix for problem with moving search settings from another subsystem
  // delete contex.params.sort;
  // delete contex.params.search;

  const variables = {
    args: {
      date: contex?.date?.date,
      id: linkId,
      temporaryId: chooseCustomerInfo ? +chooseCustomerInfo.temporaryId : 0,
      offerId: chooseCustomerInfo ? +chooseCustomerInfo.offerId : 0,
      deliveryNr: deliveryNr,
    },
  };
  const [initialOrderInfo, setOrderInfo] = useState({
    linkId,
    orderRef: '',
    deliveryTime: '',
    textareaValue: '',
  });

  const queryProductsParams = { client, variables, query: getFA_GetDefaultOrderForCustomerOnDate };
  const readQueryProductsParams = { client, variables, query: getFA_GetDefaultOrderForCustomerOnDate };
  const writeQueryProductsParams = { client, query: getFA_GetDefaultOrderForCustomerOnDate, variables, queryValue: 'getFA_GetDefaultOrderForCustomerOnDate' };

  //Information about client delivery price
  const deliveryPricesQuery = useQuery(getFA_CustomerDeliveryPrices, {
    variables: { args: { customerID: linkId } },
    skip: !linkId,
  });

  // Get current temm order if it exist
  useQuery(getFA_TemporaryOrder, {
    variables: { args: { customerId: linkId, date: contex?.date?.date } },
    skip: !linkId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const orderRef = data?.fakturaAssist?.getFA_TemporaryOrder?.orderRef;
      const deliveryTime = data?.fakturaAssist?.getFA_TemporaryOrder?.deliveryTime;
      setOrderInfo({
        ...initialOrderInfo,
        linkId,
        orderRef: orderRef || '',
        deliveryTime: deliveryTime || '',
      });
      setTempDataCopy({
        ...tempDataCopy,
        orderRef: orderRef || '',
        deliveryTime: deliveryTime || '',
      });
    },
  });

  //Hooks
  const { setFilterInfo, setOrderDateContex, setOrderType } = useFilterInfo(props);
  const { returnOrderType } = useOrderTypes({ ...props, readQueryProductsParams, writeQueryProductsParams });
  const {
    isDelivery,
    setDelivery,
    deliveryInfo,
    calculateDeliveryInfo,
    onDeliveryClick } = useDelivery({ ...props, queryProductsParams });
  const {
    deleteCustomerFromList,
  } = useDeleteCustomerFromList(
    { ...props,
      chooseCustomerIndex,
      changeChooseCustomerInfo,
      changeChooseCustomerIndex,
      query: listFA_CustomersListForCreatingOrdersOnDate,
      link: 'order/create' }
  );

  const {
    called,
    reset: resetCreateOrderMutationState,
    createNewOrder,
    multiOrderModalOnDisable,
    multiOrderModalOnAccept,
    isLoadingModalStatus,
    isWarningModalStatus,
    changeWarningModalStatus,
    ordersArray } = useNewOrder(
    { ...props,
      variables,
      readQueryProductsParams,
      writeQueryProductsParams,
      isDelivery,
      deliveryInfo,
      orderType,
      initialOrderInfo,
      deleteCustomerFromList,
      refetch: refetchRef.current,
    });
  const {
    setMemoryProductData,
    checkTemporaryOrder,
    memoryProductData,
  } = useTemporaryOrder({
    ...props,
    linkId,
    chooseCustomerInfo,
    chooseCustomerIndex,
    listFA_CustomersListForCreatingOrdersOnDate,
    readQueryProductsParams,
    isDelivery,
    deliveryInfo,
    variables,
    prevVariables,
    prevCustomerInfo,
  });

  //Modals
  const [isOpenCustomerInfoModal, changeOpenCustomerInfoModal] = useState(false);
  //Mutation
  const [noOrderMutation] = useMutation(noOrder);
  //When !id call func firstTime and change boolean
  //Use in temporary orders
  //Use in multi order modal. If lengh >= 1 show modal

  //Delivery components

  const [t] = useTranslation();

  //Top dropdown. Main logic
  const [isOpenFilter, setOpenFilter] = useState(false);

  const checkOrderType = () => {
    try {
      setDelivery(false);
      returnOrderType();
    } catch (err) {
      // console.log(err);
    }
  };
  useEffect(() => {
    checkOrderType();
  }, [linkId, orderType]);

  /*
    Subscription on order type
      *Order - all quantity positive
      *Additionalorder - all quantity positive
      *Return - all quantity negative
      *Credit order - all quantity positive
      *Special - Dont show data from db
      *Catering - recalculation in db
  */

  const getTableIndex = (index, item) => {
    changeChooseCustomerIndex(index);
    changeChooseCustomerInfo(item);
    //setDeliveryNr(null);
    props.history.replace({
      pathname: `/order/create/${item.id}`,
    });
  };

  //Main logic on this tab.

  //All quantity must be minus (-)

  //End dropdwn func
  // const getBlockColor = ({ temporaryId, offerId }) => {
  //   if (temporaryId) {
  //     return '#c1c1e6';
  //   } else if (offerId) {
  //     return '#fffed3';
  //   } else {
  //     return null;
  //   }
  // };

  const noOrderOperation = async () => {
    const customer = localData.find(({ id }) => linkId === id);
    const dataForNoOrder = {
      id: linkId,
      date: orderDate,
      isEmptyOrder: customer?.isEmptyOrder,
    };
    try {
      loadingRef.current = true;
      await noOrderMutation({ variables: { args: dataForNoOrder } });
      deleteCustomerFromList(refetchRef.current);
    } catch (err) {
      console.log(err);
    }
  };

  const onCompleted = (data) => {
    if (data) {
      try {
        const queryData = cloneDeep(data.fakturaAssist.getFA_GetDefaultOrderForCustomerOnDate);
        setMemoryProductData(cloneDeep(queryData.productTable || []));
        const deliveryIndex = queryData.productTable.findIndex(({ virtualPositionLink }, index) => virtualPositionLink === 2) || false;
        checkOrderType();

        //console.log(deliveryIndex);
        if (deliveryIndex === -1) {
          //
        } else {
          setDelivery(true);
          calculateDeliveryInfo();

          queryData.productTable.splice(deliveryIndex, 1);
          try {
            client.cache.writeQuery({
              query: getFA_GetDefaultOrderForCustomerOnDate,
              variables: variables,
              data: {
                fakturaAssist: {
                  getFA_GetDefaultOrderForCustomerOnDate: queryData,
                  __typename: 'FA_Query',
                },
              },
            });

            //Change customer position
          } catch (err) {
            //console.log(err)
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const leftGridData = data.some(async (item) => item.id === prevCustomerInfo.info?.id);
  const initialDatesForTemp = useOrderTypes(
    { ...props,
      readQueryProductsParams,
      writeQueryProductsParams,
      withoutCache: true,
      productTable: memoryProductData }
  );

  useEffect(() => {
    setPrevVariables(variables);
  }, [chooseCustomerInfo]);

  useEffect(() => {
    if((leftGridData && memoryProductData && (networkStatus === 7) && !called)) {
      const formatedData = initialDatesForTemp.returnOrderType()
        .map((order) => ({ ...order,
          quantity: order.quantity === -0 ? '' : `${order.quantity}`,
          discount: order.discount === null ? '' : `${order.discount}`,
          price: (order.price === 0 || order.price === '0.00') ? '' : `${order.price}`,
        }))
        .sort((a, b) => +a.id - +b.id);
      checkTemporaryOrder(prevVariables, formatedData, initialOrderInfo, tempDataCopy);
    }
    resetCreateOrderMutationState();
    setPrevCustomerInfo({ index: chooseCustomerIndex, info: chooseCustomerInfo });
  }, [linkId, location.pathname]);

  const [isLeftGridEmpty, setIsLeftGridEmpty] = useState(true);
  const onDetermineIsGridEmpty = (value) => {
    setIsLeftGridEmpty(value);
  };

  return (
    <>
      <Modal isOpen={ordersArray.length} headerText={t('order.modals.createNewOrder')} width='670px' height='372px' onRequestClose={multiOrderModalOnDisable} Component={<MultiOrdersModal t={t} data={ordersArray} closeModal={multiOrderModalOnDisable} multiOrderModalOnAccept={multiOrderModalOnAccept} />} />
      <Modal isOpen={isOpenCustomerInfoModal} onRequestClose={() => changeOpenCustomerInfoModal(false)} chooseCustomerInfo={{ ...chooseCustomerInfo }} headerText={t('order.modals.information')} width='700px' height='300px' Component={<InfoCustomer t={t} closeModal={() => changeOpenCustomerInfoModal(false)} id={linkId} setOrderInfo={setOrderInfo} initialOrderInfo={initialOrderInfo} />} />
      <Modal isOpen={isLoadingModalStatus} headerText={t('order.modals.creating')} width='350px' height='220px' Component={<OrderCreatingModal t={t} />} />
      <Modal isOpen={isWarningModalStatus} closeModal={() => changeWarningModalStatus(false)} headerText={t('common.warning')} width='350px' height='220px' Component={<WarningModal t={t} closeModal={() => changeWarningModalStatus(false)} />} />

      <Query
        skip={!linkId || variables.args.customerID}
        query={getFA_GetDefaultOrderForCustomerOnDate}
        variables={variables}
        fetchPolicy='network-only'
        onCompleted={onCompleted}
      >
        {({ data, client, loading, error, refetch, networkStatus: status }) => {
          setNetworkStatus(status);
          return (
            <>
              <Modal isOpen={data.isShowPreviousOrders} headerText={t('order.modals.chooseOrder')} width='670px' height='300px' Component={<PreviousOrdersModal t={t} data={data.deliveryInfo} setDeliveryNr={setDeliveryNr} />} />
              <Items
                {...props}
                dataPolicy= {'local'}
                data={null}
                leftGridData={props.data}
                withSearch={true}
                mainsection={mainsection}
                onDetermineIsGridEmpty={onDetermineIsGridEmpty}
                name={t('order.order')}
                rightGridLoading={loading}
                graphqlParams={{
                  query: listFA_CustomersListForCreatingOrdersOnDate,
                  variables: contex,
                  onCompleted: (data) => {
                    changeChooseCustomerInfo(data?.fakturaAssist?.listFA_CustomersListForCreatingOrdersOnDate[chooseCustomerIndex] || {});
                  },
                }}
                tableData={tableData(t)}
                // getBlockColor={getBlockColor}
                getTableIndex={async (item, index) => {
                  // await orderType === 1 && checkTemporaryOrder(data);
                  getTableIndex(item, index);
                }}
                getValueOnStartById={getTableIndex}
                specialTableIndex={chooseCustomerIndex}
                onAdditionalClick={() => changeOpenCustomerInfoModal(true)}
                operations={() => ({ info: () => changeOpenCustomerInfoModal(true) })}
                queryCb={({ data, refetch, loading }) => {
                  refetchRef.current = refetch.bind(null, { variables: contex });
                  loadingRef.current = loading;
                  if (!isEqual(data, localData)) {
                    writeQuery({
                      client,
                      query: listFA_CustomersListForCreatingOrdersOnDate,
                      variables: contex,
                      changedData: data,
                    });
                    setLocalData(data);
                  }
                }}
                FilterComponent={
                  <Filter
                    t={t}
                    mainsection={mainsection}
                    setFilterInfo={setFilterInfo}
                    filterData={filterData}
                    graphqlParams={filterQuery}
                    filterRaw={filterRaw}
                    filterInfo={contex.filter}
                    isOpenFilter={isOpenFilter}
                    setOpenFilter={setOpenFilter}
                    confirmedFilterInfo={confirmedFilterInfo}
                    setConfirmedFilterInfo={setConfirmedFilterInfo}
                    subsection={props.subsection}
                  />
                }
                selectById
                linkId={+linkId}
                //NEw
                infiniteLoader
                isOpenFilter={isOpenFilter}
                setOpenFilter={setOpenFilter}
                calendarDate={props.contex?.date?.date}
                setCalendarDate={setOrderDateContex}
                withVirtualized
                leftGridColors={leftGridColors}
                cutHeight={293}
              />

              {/* don't show right part when left grid is empty */
                (!isLeftGridEmpty) && <Addition
                  {...props}
                  loading={deliveryPricesQuery.loading || loading || !linkId}
                  text={chooseCustomerInfo ? chooseCustomerInfo.name : '-'}
                  menuObj={[{ link: '/order/create', name: t('order.orderCreate') }]}
                  isShow={linkId}
                  noShowText='No orders'
                >
                  <Main
                    {...props}
                    setOrderInfo={setOrderInfo}
                    initialOrderInfo={initialOrderInfo}
                    isOpenCustomerInfoModal={isOpenCustomerInfoModal}
                    changeOpenCustomerInfoModal={changeOpenCustomerInfoModal}
                    chooseCustomerInfo={chooseCustomerInfo}
                    createNewOrder={createNewOrder}
                    noOrderOperation={noOrderOperation}
                    date={orderDate}
                    data={data}
                    t={t}
                    productData={{
                      data: data,
                      client: client,
                      query: getFA_GetDefaultOrderForCustomerOnDate,
                      variables: variables,
                    }}
                    loading={loading}
                    error={error}
                    refetchProducts={refetch}
                    orderType={orderType}
                    setOrderType={setOrderType}
                    specialTableSearch
                    isDelivery={isDelivery}
                    onDeliveryClick={onDeliveryClick}
                    deliveryInfo={deliveryInfo}
                    calculateDeliveryInfo={calculateDeliveryInfo}
                    TableDefOrder={TableDefOrder}
                    localData={localData}
                    refetchLoading={loadingRef.current}
                  />
                </Addition>
              }
            </>);
        }}
      </Query>
    </>
  );
};

export default compose(
  withParams,
)(CreateOrder);
