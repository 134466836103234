import gql from 'graphql-tag';

export default gql`
query listProducts($params: ListQueryArgs){
  fakturaAssist{
    listProducts(params: $params){
      id
      name
      productNumber
    }
  }
}`;
