import gql from 'graphql-tag';

export default gql`
query listFA_AusnahmeTag{
    fakturaAssist{
    	listFA_AusnahmeTag{
    		id
		    date
		    note
		    replacingDate
 		}
 	}
}`;
