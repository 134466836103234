
import gql from 'graphql-tag';

export default gql`
mutation deleteFA_DeliveryPeriods($id: Int!){
    fakturaAssist{
    deleteFA_DeliveryPeriods(id: $id){
		id
    }
  }
}`;
