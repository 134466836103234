import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query getFA_Suppliers($id: ID!){
    fakturaAssist{
    	getFA_Suppliers(id: $id){
	    	id
            suppliersNumber
            salutation
            nameSupplier
            additive
            street
            postCode
            city
            phoneG
            phoneP
            phoneG2
            fax
            email
            internet
            natel
            contactPerson
            land
            isActivated

            ourCustomerNo
            accountDiscountDays
            discountInPercent
            bonusInPercent
            accountDiscountInPercent
            deliveryTimeFrom
            deliveryTimeTo
            isMonday
            isTuesday
            isWednesday
            isThursday
            isFriday
            isSaturday
            isSunday

            orderReportID
            orderCopies
	 	}
 	}
}`;

