import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
// import { Col } from 'style';

// import { Dropdown } from 'components/elements';
// import Addition from 'components/List/Additional';

const Language = (props) => {
  // const { t } = props;
  return (
    <TableBlock>
        printService
    </TableBlock>
  );
};

export default withTranslation()(Language);

const TableBlock = styled.div`
  margin: 20px 0px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  width: 100%;
  height: 800px;
`;
