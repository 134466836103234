import React, { useState, Fragment } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import MutationHoc from '../../graphql/MutationHoc'; //qqlMutationUpdate
import Button from '../../elements/Button/Button';
import TableModal from '../components/TableModal';

import { cloneDeep, isEqual, orderBy } from 'lodash';

export default (WrappedComponent) => (props) =>{
  const { sortObject, modalFormValidator } = props;
  const [t] = useTranslation();
  const [errors, setErrors] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState(null);
  const { client } = useQuery(props.graphqlParams.query, { variables: { ...props.graphqlParams.variables } });
  const [isUpdate, setTableUpdate] = useState(false);
  const { tableData, data, defaultValuesForFormik, Component, headerText, modalWidth, modalHeight, addFuncCreate, modalDisableBtnOnEmpty } = props;
  const tableDataForMutation = [];
  const tableDatadefaultChangedValues = [];
  tableData.forEach((item)=>{
    if(item.isMutation) {
      tableDataForMutation.push(item);
    }
    if(item.isDefaultChanged) {
      tableDatadefaultChangedValues.push(item);
    }
  });

  let dataForMutation = {}; //+
  let defaultChangedValues = {};//+
  if(isUpdate) {
    for(const prop in data[rowIndex]) {
      if(data) {
        tableDataForMutation.forEach((item)=>{
          if(prop === item.dataBase) {
            dataForMutation = { ...dataForMutation, [prop]: data[rowIndex][prop] };
          }
        });
      }
    }
    for(const prop in data[rowIndex]) {
      if(data && defaultValuesForFormik) {
        defaultValuesForFormik.forEach((item)=>{
          if(prop === item) {
            dataForMutation = { ...dataForMutation, [prop]: data[rowIndex][prop] };
          }
        });
      }
    }
  } else{
    tableDataForMutation.forEach((item)=>{
      dataForMutation = { ...dataForMutation, [item.dataBase]: null };
      if(item.type === 'boolean') {
        dataForMutation = { ...dataForMutation, [item.dataBase]: false };
      } else if(item.dataType === 'float' && (data[rowIndex]?.[item.dataBase] === 0)) {
        dataForMutation = { ...dataForMutation, [item.dataBase]: 0.00 };
      }else if(item.dataType === 'drop') {
        dataForMutation = { ...dataForMutation, [item.dataBase]: '' };
      } else if(item.dataType === 'percent') {
        dataForMutation = { ...dataForMutation, [item.dataBase]: 0.00 };
      } else{
        dataForMutation = { ...dataForMutation, [item.dataBase]: null };
      }
    });
  }

  tableDatadefaultChangedValues.forEach((item)=>{
    defaultChangedValues = { ...defaultChangedValues, [item.dataBase]: item.isDefaultChanged };
  });

  const handleClose = () => {
  	setIsOpen(false);
  };
  const handleOpen = (rowIndex) => {
    setRowIndex(rowIndex);
    setIsOpen(true);
  };

  //  console.log(rowIndex, data[rowIndex])

  const saveButton = (values, isUpdate, _, defaultChangedValues, isValid, errValue, setFieldValue, onTouched, setFieldError, setTouched, modalDisableBtnOnEmpty) =>{
  	let valueForMutation = null;
  	let dataForMutation = null;
    let id = null;

    return(<React.Fragment>
      <MutationHoc mutation={isUpdate ? props.qqlMutationUpdate : props.qqlMutationCreate}>
        {({ mutation, result, status, loading }) => {
          const cacheCahnge = () => {
            const dataResult = client.readQuery({
              query: props.graphqlParams.query,
              variables: props.graphqlParams.variables,
            });

            const dataFakturaAssist = dataResult.fakturaAssist[props.graphqlParams.query.definitions[0].name.value];
            let cloneDataFakturaAssist = cloneDeep(dataFakturaAssist);

            if (sortObject && sortObject.column && sortObject.order) {
              cloneDataFakturaAssist = orderBy(cloneDataFakturaAssist, [sortObject.column], [sortObject.order.toLowerCase()]);
            } else {
              cloneDataFakturaAssist = cloneDeep(props.data);
            }

            let newObj = values;
            if(isUpdate) {
              id = cloneDataFakturaAssist[rowIndex].id;
              // console.log('cloned', cloneDataFakturaAssist, cloneDataFakturaAssist[rowIndex], rowIndex)
            }
            newObj = { ...newObj, id: isUpdate ? id : null, __typename: props.typeName };
            valueForMutation = { ...newObj };

            for(const prop in defaultChangedValues) {
              if(defaultChangedValues) {
                newObj = { ...newObj, [prop]: defaultChangedValues[prop] };
                valueForMutation = { ...newObj, [prop]: defaultChangedValues[prop] };
              }
            }
            for(const prop in props.defaultValuesForMutation) {
              if(props.defaultValuesForMutation) {
                newObj = { ...newObj, [prop]: props.defaultValuesForMutation[prop] };
                valueForMutation = { ...newObj, [prop]: props.defaultValuesForMutation[prop] };
              }
            }

            isUpdate ? cloneDataFakturaAssist.splice(rowIndex, 1, newObj) : cloneDataFakturaAssist = [newObj, ...cloneDataFakturaAssist];

            for(const prop in valueForMutation) {
              if(prop !== 'id' && prop !== '__typename') {
                dataForMutation = { ...dataForMutation, [prop]: valueForMutation[prop] };
              }
            }
            // console.log({ id, dataForMutation, sortObject: props.sortObject })
            if(isUpdate) {
              mutation(
                {
                  variables: { id: id, fields: dataForMutation },
                }).then((db)=>{
                if(props.isRefetchAfterMutation) {
                  props.refetch();
                  handleClose();
                } else{
                  handleClose();
                  props.defaultValuesForCache && props.defaultValuesForCache.forEach((item)=>{
                    cloneDataFakturaAssist[rowIndex][item] = db.data.fakturaAssist[props.qqlMutationUpdate.definitions[0].name.value][item];
                  });
                  // console.log({ cloneDataFakturaAssist, sortObject })
                  client.writeQuery({
                    query: props.graphqlParams.query,
                    variables: props.graphqlParams.variables,
                    data: {
                      fakturaAssist: {
                        [props.graphqlParams.query.definitions[0].name.value]: cloneDataFakturaAssist,
                        __typename: 'FA_Query',
                      },
                    },
                  });
                }
              }).catch((err)=>{
                handleClose(); console.log(err);
              });
            } else{
              addFuncCreate && addFuncCreate();
              //Create mutation
              mutation(
                {
                  variables: { fields: dataForMutation },
                }).then((db)=>{
                if(props.isRefetchAfterMutation) {
                  props.refetch();
                  handleClose();
                } else{
                  props.defaultValuesForCache && props.defaultValuesForCache.forEach((item)=>{
                    cloneDataFakturaAssist[0][item] = db.data.fakturaAssist[props.qqlMutationCreate.definitions[0].name.value][item];
                  });
                  const promise = new Promise((resolve, reject) => {
                    resolve(cloneDataFakturaAssist[0].id = db.data.fakturaAssist[props.qqlMutationCreate.definitions[0].name.value].id);
                  });

                  promise.then(
                    (result) => {
                      client.writeQuery({
                        query: props.graphqlParams.query,
                        variables: props.graphqlParams.variables,
                        data: {
                          fakturaAssist: {
                            [props.graphqlParams.query.definitions[0].name.value]: cloneDataFakturaAssist,
                            __typename: 'FA_Query',
                          },
                        },
                      });
                      handleClose();
                    },
                    (error) => {
                      console.log('ddd');
                    }
                  );
                }
              }).catch((err)=>{
                handleClose(); console.log(err);
              });
            }
          };
     
          return (
            <React.Fragment>
              {loading ? <i className='fas fa-spinner fa-pulse' /> : <Button
                noActive = {(!isValid || !isEqual(errValue, {}) || (modalDisableBtnOnEmpty ? !!!Object.keys(values).every((key) => values[key]) : false))}
                noActiveFunc = {() => {
                  if(values?.fromDate && values?.toDate && Date.parse(values?.toDate) < Date.parse(values?.fromDate)) {
                    return setFieldError('toDate', t('common.validation.endDateNotBeLessThenStartDate'));
                  }
                  setFieldValue(null);
                  onTouched();
                }}
                className={'black'}
                width={'100%'}
                onClick={()=>{
                  setTouched({});
                  if (modalFormValidator) {
                    const { ok, messages } = modalFormValidator(values);
                    if (!ok && messages) {
                      setErrors(messages);
                    } else {
                      setErrors(null);
                      cacheCahnge();
                    }
                  } else {
                    cacheCahnge();
                  }
                }}
                value={t('common.save')}/>}
            </React.Fragment>);
        }}

      </MutationHoc>
    </React.Fragment>
    );
  };

  return (
    <Fragment>
      <TableModal
        tableData={tableData}
        saveButton={saveButton}
        defaultChangedValues={defaultChangedValues}
        errors={errors}
        Component={Component}
        isUpdate={isUpdate}
        rowIndex={rowIndex}
        data={dataForMutation}
        dataRow={props?.data[rowIndex]}
        isOpen={isOpen}
        handleClose={handleClose}
        headerText={headerText}
        modalWidth={modalWidth + 'px'}
        modalDisableBtnOnEmpty={modalDisableBtnOnEmpty}
        modalHeight={modalHeight + 'px'}/>
      <WrappedComponent setTableUpdate = {setTableUpdate} saveButton={saveButton} isOpen={isOpen} handleOpen={handleOpen} handleClose={handleClose} {...props}/>

    </Fragment>
  );
};
