import React, { useEffect, useState, useRef } from 'react';
import Filter from 'components/elements/Filter';
import Addition from 'components/List/Additional';
import Items from 'components/List/Items';
import withPDFActions from 'components/viewPDF/withPDFActions';
import { cloneDeep, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import ArrowWhiteDown from 'style/arrow-white-down.svg';
import ArrowWhite from 'style/arrow-white.svg';
import { LoadingWrap } from 'style/default';
import styled from 'styled-components';
// import ActionsList from 'components/actionsList/ActionsList';
import { listFA_Invoice } from '../qql/query';
import { Main } from './pages';
import { tableDeliveryDate } from './params';

const NewInvoice = (props) => {
  const { contex, writeContex, linkId, sendPDFIntoEmail, data } = props;
  const [t] = useTranslation();
  const localDataRef = useRef(null);
  const { filterData, filterRaw, restContexData } = props.subSectionInfo;
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [confirmedFilterInfo, setConfirmedFilterInfo] = useState(JSON.parse(localStorage.getItem('invoice/created')) || []);
  const [isOpenTable, changeOpenTable] = useState(false);
  const [chooseCustomerInfo, changeChooseCustomerInfo] = useState({});
  const some = (id) => {
    props.history.replace({
      pathname: `/invoice/created/${id}`,
    });
  };

  const setFilterInfo = (filter, restContex = []) => {
    writeContex({ ...contex, filter: [...filter], restContex: [...restContex] });
  };

  const [width, setWidth] = useState(0); // eslint-disable-line
  //ID from Items component!
  useEffect(() => {
    setWidth(
      document.getElementById('main_list') &&
        document.getElementById('main_list').offsetWidth
    );
  }, [document.getElementById('main_list')]);

  const [chooseOrder, changeChooseOrder] = useState({});
  //Change link on order/edit where customer can change order.
  //Date of order write in main section as defaultFilterParams and changes after history replace

  const getOrderEditPage = () => {
    if (chooseOrder.id) {
      const { pageCache, setPageCache } = props;
      const cacheCopy = cloneDeep(pageCache);
      const cacheIndexPage = cacheCopy.findIndex(
        (item) => item.name === 'orderEdit'
      );
      const orderCacheFilterObject = {
        name: 'orderEdit',
        filter: {
          filter: [
            { column: 'folderName', value: 'date' },
            { column: 'date', value: chooseOrder.date },
          ],
        },
      };
      if (cacheIndexPage === -1) {
        cacheCopy.push(orderCacheFilterObject);
        setPageCache(cacheCopy);
      } else {
        cacheCopy.splice(cacheIndexPage, 1, orderCacheFilterObject);
        setPageCache(cacheCopy);
      }
      props.history.replace({
        pathname: `/order/edit/${chooseOrder.id}`,
      });
    }
  };

  const pdfParams = {
    reportId: '52',
    // languageId: 2,
    AInvoiceID: chooseCustomerInfo.id,
    AUserFormularID: '0',
  };

  // const orderPdfParams = {
  //   reportId: '44',
  //   AOrderID: `${chooseOrder.id}`,
  //   AUserFormularID: '0',
  // };

  // const deliveryTable = !isOpenFilter && (
  //   <InvoiceListBlock>
  //     <div>
  //       <ActionsListBlock>
  //         <ActionsList
  //           t={t}
  //           actions={{
  //             printReport: () => {
  //               props.sendPDFIntoPrinter(pdfParams);
  //             },
  //             preview: (actualPDFparams) => {
  //               // props.showPDFPreview(pdfParams, chooseOrder.pdfLink);

  //               props.showPDFPreview((actualPDFparams && { ...actualPDFparams, reportId: 'invoicePreview' }) || { ...pdfParams, reportId: 'invoicePreview' });
  //             },
  //             reportForms: () => {
  //               props.showFormsType(pdfParams, [9, 17, 18, 19, 21, 31, 39, 52]);
  //             },
  //           }}
  //           pdfParams={pdfParams}
  //           t={t}
  //           customActions={true}
  //           reportForms = {[9, 17, 18, 19, 21, 31, 39, 52]}

  //         />
  //       </ActionsListBlock>
  //     </div>
  //   </InvoiceListBlock>
  // );

  // console.log('Created Invoice', props)

  const [localContex, setLocalContex] = useState(null);

  useEffect(() => {
    setLocalContex(contex);
  }, [JSON.stringify(contex)]);

  const [isLeftGridEmpty, setIsLeftGridEmpty] = useState(true);
  const onDetermineIsGridEmpty = (value) => {
    setIsLeftGridEmpty(value);
  };

  return (
    <>
      <Items
        {...props}
        withSearch={true}
        searchColumns={['invoiceNr', 'customerNr', 'nameRes']}
        onDetermineIsGridEmpty={onDetermineIsGridEmpty}
        graphqlParams={{
          query: listFA_Invoice,
          variables: contex,
          fetchPolicy: isEqual(contex, localContex) ? 'cache-first' : 'cache-and-network',
        }}
        leftGridData={localDataRef.current}
        tableData={tableDeliveryDate(t, data)}
        data={props.customersData}
        getBlockColor={() => {}}
        getTableIndex={(id, data) => {
          changeChooseCustomerInfo(data);
          some(data.id);
        }}
        queryCb={({ data }) => {
          if (!isEqual(data, localDataRef.current)) {
            localDataRef.current = data;
          }
        }}
        getValueOnStartById={(index, item) =>changeChooseCustomerInfo(item)}
        withTopSearch={false}
        disableSearch={false}
        leftGridColors={props.subSectionInfo?.leftGridColors}
        FilterComponent={
          <Filter
            t={t}
            mainsection={props.mainsection}
            filterRaw={filterRaw}
            setFilterInfo={setFilterInfo}
            filterData={filterData}
            filterInfo={contex.filter}
            isOpenFilter={isOpenFilter}
            restContexInfo={contex.restContex}
            restContexData={restContexData}
            setOpenFilter={setOpenFilter}
            confirmedFilterInfo={confirmedFilterInfo}
            setConfirmedFilterInfo={setConfirmedFilterInfo}
            subsection={props.subsection}
          />
        }
        isOpenFilter={isOpenFilter}
        setOpenFilter={setOpenFilter}
        withTotal
        cutHeight={309}
        itemWidth={'500px'}
        // actions={{
        //   printReport: () => {
        //     props.sendPDFIntoPrinter(pdfParams, chooseOrder.pdfLink);
        //   },
        //   preview: () => {
        //     props.showPDFPreview(pdfParams, chooseOrder.pdfLink);
        //   },
        //   reportForms: () => {
        //     props.showFormsType(pdfParams, [9, 19, 31, 39, 17, 18]);
        //   },
        // }}
        // pdfParams={pdfParams}
        // reportForms = {[9, 19, 31, 39, 17, 18]}
      />
      {false && (
        <BlackCircle
          onClick={() => {
            changeOpenTable(!isOpenTable);
          }}
        >
          <Arrow src={isOpenTable ? ArrowWhiteDown : ArrowWhite} alt='' />
        </BlackCircle>
      )}

      {/* don't show right part when left grid is empty */
        (!isLeftGridEmpty) && <Addition
          menuObj={[
            { link: '/invoice/created', name: t('invoice.createdInvoices') },
          ]}
          actions={{
            printReport: (reportDocument) => {
              props.sendPDFIntoPrinter(reportDocument, chooseOrder.pdfLink);
            },
            preview: (reportDocument) => {
              props.showPDFPreview(reportDocument, chooseOrder.pdfLink);
            },
            reportForms: (reportDocument) => {
              props.showFormsType(reportDocument, [9, 19, 31, 39, 17, 18]);
            },
            email: (reportDocument) => {
              sendPDFIntoEmail(reportDocument);
            },
            history: 'default',
          }}
          customActions={true}
          t={t}
          linkId={linkId}
          text={chooseCustomerInfo.nameRes || '-'}
          pdfParams={pdfParams}
          reportForms = {[9, 17, 18, 19, 21, 31, 39, 52, 59]}
          historyFormTypeID={props.sectionInfo.historyFormTypeID}
          isShow={linkId}
        >
          <LoadingWrap loading={false}>
            {/* {deliveryTable} */}
            <Main
              {...props}
              changeChooseOrder={changeChooseOrder}
              getOrderEditPage={getOrderEditPage}
              isDelete={chooseCustomerInfo?.isDelete}
              linkId={+chooseCustomerInfo.invoiceNr}
              sammelfakturaAufKunde={chooseCustomerInfo?.sammelfakturaAufKunde}
              orderId={chooseOrder.id}
            />
          </LoadingWrap>
        </Addition>
      }
    </>
  );
};

export default withPDFActions(NewInvoice);

const BlackCircle = styled.div`
  height: 32px;
  width: 32px;
  box-shadow: 0 4px 1px 0 rgba(207, 207, 207, 0.32);
  background-color: #3e4c57;
  border-radius: 16px;
  position: absolute;
  bottom: -15px;
  left: 96%;
`;

const Arrow = styled.img`
  display: block;
  margin: auto;
  top: 50%;
  margin-top: 45%;
`;

// const InvoiceListBlock = styled.div`
//   background-color: #fff;
//   box-sizing: border-box;
//   filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15));
// `;

// const ActionsListBlock = styled.div`
//   position: absolute;
//   margin-top: 0;
//   top: -35px;
//   right: 0;
// `;
