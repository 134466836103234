/*eslint-disable*/
import { tableData as params } from './params';

import listFA_Customer from './qql/listFA_Customer.js';
import updateFA_Customer from './qql/updateFA_Customer.js';
import getFA_Customer from './qql/getFA_Customer.js';

//Example
import Adresse from './adresse';
import DefaultOrder from './defaultOrder';
import Conditions from './conditions';
import CRM from './crm';
import Tour from './tour';
import List from './list';
import Forms from './forms';
import Post from './post';
import Language from './language';
import Cash from './cash';
// import { FILTER_QUERY } from '../invoice/qql/query';
import GET_FA_CUSTOMER_FILTER from './qql/getFA_CustomerFilter';
import ComponentForCreating from './ComponentForCreating';

const filterTypeName = JSON.parse(localStorage.getItem('customers'))

const defaultPDFParams = (item) => {
  return ({reportId: '3',
  ACustomersList: Array.isArray(item) ? item.join(', ') : `${item}`,
  AUserFormularID: '0',
  AOrderID: '0',
})}

const filterQuery = {
  query: GET_FA_CUSTOMER_FILTER,
};

const filterData = (t) => [{
  title: t('common.filter.activeCustomers'),
  field: 'IsAktiviert',
  value: 1,
  subItems: [
    {
      title: t('common.list'),
      field: 'Intern',
      queryField: 'listFA_CustomerListsDefault',
      titleField: 'name',
      valueField: 'relatedCustomers',
    },
    {
      title: t('order.customerGroup'),
      field: 'LinkGruppe',
      queryField: 'listFA_CustomerGroup',
      titleField: 'group',
      valueField: 'id',
    },
    {
      title: t('common.filter.tourPlan'),
      field: 'LinkTourenplan',
      queryField: 'listFA_CustomerTourplan',
      titleField: 'name',
      valueField: 'id',
    },
  ].sort((a, b) => a.title.localeCompare(b.title)),
}, {
  title: t('common.filter.inactiveCustomers'),
  field: 'IsAktiviert',
  value: 0,
  subItems: [
    {
      title: t('common.list'),
      field: 'Intern',
      queryField: 'listFA_CustomerListsDefault',
      titleField: 'name',
      valueField: 'relatedCustomers',
    },
    {
      title: t('order.customerGroup'),
      field: 'LinkGruppe',
      queryField: 'listFA_CustomerGroup',
      titleField: 'group',
      valueField: 'id',
    },
    {
      title: t('common.filter.tourPlan'),
      field: 'LinkTourenplan',
      queryField: 'listFA_CustomerTourplan',
      titleField: 'name',
      valueField: 'id',
    },
  ].sort((a, b) => a.title.localeCompare(b.title)),
}];

export default (t) => ({
  name: t('customer.customer'),
  link: 'customers',
  listQuery: listFA_Customer,
  multipleSelect: true,
  itemQuery: getFA_Customer,
  tableData: params(t),
  updateItem: updateFA_Customer,
  mainItem: 'id', //Items in top of menu
  textHead: 'nameCustomerDisp',
  defaultPDFParams: defaultPDFParams,
  reportForms: [3,4,5,45],
  filterQuery,
  defaultFilterParams: { filter: filterTypeName ||  [{ column: 'IsAktiviert', value: '1'}]},
  filterData: filterData(t),
  actions: ['printReport', 'preview', 'reportForms', 'history'],
  historyFormTypeID: 2,
  disableSearch: false,
  searchColumns: ['Name', 'KundenNr', 'NameIntern'],
  ComponentForCreating: ComponentForCreating,
  withDefaultFunction: true,
  withDefaultParams: true,
  subSection: [
  	{ name: t('common.address'),
    	link: 'adresse',
    	Component: Adresse,
      col: 1,
      transitions: [true, false, false]

  	},
    { name: t('customer.conditions.conditions'),
      link: 'conditions',
      Component: Conditions,
      col: 2,
      transitions: [false, false, false, false, false, false, false]
    },
    { name: t('customer.defaultOrder'),
      link: 'default-order',
      Component: DefaultOrder,
      col: 2,
      transitions: [false, false, false, false, false, false, false]
    },
    { name: t('common.tour'),
      link: 'tour',
      Component: Tour,
      col: 1,
    },
    { name: t('common.forms'),
      link: 'forms',
      Component: Forms,
      col: 1,
      transitions: [false, false]
    },
    { name: t('customer.crm'),
      link: 'crm',
      Component: CRM,
      col: 1,
    },
    { name: t('common.list'),
      link: 'list',
      Component: List,
      col: 1,
    },
    { name: 'CashAssist',
      link: 'cashAssist',
      Component: Cash,
      col: 1,
    },
    { name: t('customer.post.post'),
      link: 'post',
      Component: Post,
      col: 1,
    },
    { name: t('common.language'),
      link: 'language',
      Component: Language,
      col: 1,
    },
  ],
});
