//

import gql from 'graphql-tag';

export default gql`
mutation deleteFA_UserForms($id: Int!){
    fakturaAssist{
    deleteFA_UserForms(id: $id){
		id
    }
    
  }
}`;
