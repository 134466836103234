import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import MutationHook from 'components/graphql/MutationHoc';

import { Row, Col } from 'style';
import { DELETE_PRODUCT_IMG, UPLOAD_PRODUCT_IMG } from './gql/Img';
import { GROUP, GROUP2, GROUP3, TotalCategories, TotalCustomerPrice, listFA_CustomerResellerPricesConnection, CONFIG } from './gql/index';

import Formik from 'components/formik';
import { Input, Checkbox, Transition, Image, Dropdown } from 'components/elements';
import { createArray } from '../helpers/functions';
import ImageDelete from './components/ImageDelete';
import ImageEdit from './components/ImageEdit';
import ImageDownload from './components/ImageDownload';

//import { default as Alert } from 'style/img/product/noConnection.svg';
import { default as NonImage } from 'style/img/non_image.svg';

import ActualPrice from './components/ActualPrice';
import PriceCategories from './components/PriceCategories';
import CustomerPrice from './components/CustomerPrice';
import ResellerPrice from './components/ResellerPrice';

export default (props) => {
  const { data } = props;
  const [t] = useTranslation();
  const [imageError, setImageError] = useState(false);
  const { data: dataGroupQuery } = useQuery(GROUP, {
    fetchPolicy: 'cache-and-network',
  });
  const { data: tCategories, refetch: refetchCount, client } = useQuery(TotalCategories, {
    variables: {
      linkArticle: +get(data, 'id'),
    },
    skip: !+get(data, 'id'),
    fetchPolicy: 'cache-and-network',
  });
  const { data: tCustomerPrice, refetch: refetchCountCustomerPrice, client: clientCustomer } = useQuery(TotalCustomerPrice, {
    variables: {
      id: +get(data, 'id'),
    },
    skip: !+get(data, 'id'),
    fetchPolicy: 'cache-and-network',
  });
  const { data: tResselerPrice, refetch: refetchCountResselerPrice, client: clientResseler } = useQuery(listFA_CustomerResellerPricesConnection, {
    variables: {
      id: +get(data, 'id'),
    },
    skip: !+get(data, 'id'),
    fetchPolicy: 'cache-and-network',
  });
  const { data: dataConfig } = useQuery(CONFIG, {
    fetchPolicy: 'cache-and-network',
  });
  const arr = createArray(dataGroupQuery, 'fakturaAssist.listFA_ProductGroup1[0].productGroup');
  const totalCategories = get(tCategories, 'fakturaAssist.listFA_ProductSpecialPriceConnection.total', 0);
  const totalCustomerPrice = get(tCustomerPrice, 'fakturaAssist.listFA_CustomerSpecialPricesAndDiscountsProductConnection.total', 0);
  const totalResselerPrice = get(tResselerPrice, 'fakturaAssist.listFA_CustomerResellerPricesConnection.total', 0);
  const category = get(dataConfig, 'fakturaAssist.getFA_Config.articlePriceCategory', []);

  const testImage = (src) => {
    if (!src || src.length <= 0) setImageError(true);
    const image = document.createElement('img');
    image.src = `data:image/png;base64, ${src}`;
    image.onload = function () {
      console.log(1);
      setImageError(false);
    };
    image.onerror = (err) => {
      console.log(2);
      console.log(err);
      setImageError(true);
    };
  };

  useEffect(() => {
    testImage(get(data, 'productImage'));
  }, [get(data, 'productImage')]);

  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
      sectionInfo={props.sectionInfo}
      linkId={props.linkId}
      updateWithoutId
    // validate={(values) => {
    //   const errors = {};
    //   if(!values.productNumber) {
    //       errors.productNumber = 'Email Address Required';
    //       console.log(errors);
    //       handleReset();
    //   }
    //   return errors;
    // }}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        // console.log(!imageError);
        // console.log(values.productImage);
        // console.log(!imageError && values.productImage);
        // console.log('formik data', values.productImage)
        return (
          <>
            <MainBlock>
              <Col lg={4}>
                <Row>
                  <Col lg={2}>
                    <Input small value={values.productNumber} field='productNumber' title={t('product.articleNumber')} />
                  </Col>
                  <Col lg={2}>
                    <Checkbox value={values.isActivated} field='isActivated' title={t('product.articleIsActive')} margin={'45px 0 0 10px'} zIndex={'0'} />
                  </Col>
                </Row>
                <Input value={values.name} field='name' title={t('common.description')} />
                <Checkbox value={values.isTitle} field='isTitle' title={t('product.showArticleAsTitle')} margin={'20px 0 0'} zIndex={'0'} />
                <Checkbox value={values.isNetto} field='isNetto' title={t('common.noDiscountPosible')} margin={'10px 0 0'} zIndex={'0'} />
              </Col>
              <Col lg={4}>
                <Dropdown
                  title={t('product.discountGroups')}
                  labelField='name'
                  valueField='id'
                  field='linkGroup'
                  defaultValueField={values.linkGroup}
                  data={arr?.filter((val) => val.name)}
                />
                <Dropdown
                  title={t('product.productionGroups')}
                  labelField='name'
                  valueField='id'
                  field='linkGroup2'
                  defaultValueField={values.linkGroup2}
                  graphqlParams={{ query: GROUP2 }}
                />
                <Dropdown
                  title={t('product.marketingGroups')}
                  labelField='name'
                  valueField='id'
                  field='linkGroup3'
                  defaultValueField={values.linkGroup3}
                  graphqlParams={{ query: GROUP3 }}
                />
              </Col>
              <Col lg={4}>
                <TitleImage style={{ marginTop: '10px', marginBottom: '7px' }}>{t('common.image')}</TitleImage>
                <ImageWrapper>
                  <ImageActions>
                    {!imageError && values.productImage ?
                      <>
                        <MutationHook mutation={UPLOAD_PRODUCT_IMG}>
                          {(props) => <ImageEdit {...props} id={values.id} t={t} updateImage />}
                        </MutationHook>
                        <ImageDownload img={values.productImage} />
                        <MutationHook mutation={DELETE_PRODUCT_IMG}>
                          {(props) => <ImageDelete {...props} id={values.id} t={t} />}
                        </MutationHook>
                      </>
                      : <MutationHook mutation={UPLOAD_PRODUCT_IMG}>
                        {(props) => <ImageEdit {...props} id={values.id} t={t} newImage />}
                      </MutationHook>
                    }
                  </ImageActions>
                  {(!imageError && values.productImage) ?
                    <Image srcImage={`data:image/png;base64, ${values.productImage}`} />
                    :
                    <Image srcImage={NonImage} />
                  }

                </ImageWrapper>
              </Col>
            </MainBlock>

            <Transition index={0} text={t('product.actualPrice')} isWhite>
              <ActualPrice
                data={values} mutations={props.mutations} category={category} />
            </Transition>

            <Transition index={1} text={t('product.priceCategories')} pieces={totalCategories} isWhite>
              <PriceCategories id={values.id} props={values} refetchCount={refetchCount} client={client} categoryList={category} />
            </Transition>

            <Transition index={2} text={t('product.customerPrices')} pieces={totalCustomerPrice} isWhite>
              <CustomerPrice id={values.id} props={values} refetchCount={refetchCountCustomerPrice} client={clientCustomer} setFieldValue={setFieldValue} />
            </Transition>

            <Transition index={3} text={t('customer.conditions.resellerPrices')} pieces={totalResselerPrice} isWhite>
              <ResellerPrice id={values.id} props={values} refetchCount={refetchCountResselerPrice} client={clientResseler} setFieldValue={setFieldValue} />
            </Transition>
          </>
        );
      }}
    </Formik>
  );
};

const TitleImage = styled.p`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #3c445a;
  padding-top: 4px;
`;

const ImageActions = styled.div`
  border-radius: 5px;
  display: flex;
  transition: .3s ease-in-out;
  opacity: 0;
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.8); */
  /* border: 1px solid #AFAFAF; */
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
`;

// const NotFoundText = styled.div`
//   top: 120px;
//   text-transform: uppercase;
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   text-align: center;
// `;

const ImageWrapper = styled.div`
  width: 300px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &:hover ${ImageActions} {
    opacity: 1;
  }
`;

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
