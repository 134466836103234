import React from 'react';
import { useState, useEffect } from 'react';

import QueryComponent from '../../graphql/Query';

const sections = ['order', 'offer'];

const withQuery = (WrappedComponent) => (props) => {
  const { withVirtualized, dataPolicy } = props;
  // console.log(props.graphqlParams)
  // console.log(writeContex);
  const [graphqlParams, setGraphQlParams] = useState(props.graphqlParams);
  useEffect(() => {
    if (props.graphqlParams) {
      if (props.graphqlParams.variables) {
        if(dataPolicy === 'databaseOnly') {
          setGraphQlParams({
            ...props.graphqlParams,
            variables: {
              ...props.graphqlParams.variables,
              params: {
                ...(sections.includes(props.mainsection)
                  ? props.graphqlParams.variables.params
                  : graphqlParams.variables.params || props.params),
                search: props.graphqlParams.variables.params?.search || graphqlParams.variables.params?.search || { columns: ['id'], string: '' },

              },
            },
          });
        } else{
          setGraphQlParams({
            ...props.graphqlParams,
            variables: {
              ...props.graphqlParams.variables,
              params: {
                ...(sections.includes(props.mainsection)
                  ? props.graphqlParams.variables.params
                  : graphqlParams.variables.params || props.params),
                // search: props.graphqlParams.variables.params?.search || graphqlParams.variables.params?.search || { columns: ['id'], string: '' },

              },
            },
          });
        }
        // props.writeContex(graphqlParams.variables)
      }
    }
  }, [props.graphqlParams]);

  if (!graphqlParams) {
    return <WrappedComponent {...props} />;
  } else {
    const queryName =
      graphqlParams.query.definitions[0].selectionSet.selections[0].selectionSet
        .selections[0].name.value;

    if (typeof graphqlParams.variables === 'object') {
      const { restContex, ...restVars } = graphqlParams.variables;
      if (restContex) {
        graphqlParams.variables = {
          ...restVars,
          ...restContex.reduce((acc, { column, value }) => ({ ...acc, [column]: value }), {}),
        };
      }
    }

    return (
      <QueryComponent
        variables={{
          ...graphqlParams.variables || {},
          params: {
            ...graphqlParams.variables?.params || {},
            limit: withVirtualized ? Infinity : graphqlParams.variables?.params?.limit || 100,
          },
        }}
        skip={graphqlParams.skip}
        fetchPolicy={graphqlParams.fetchPolicy || 'cache-and-network'}
        query={graphqlParams.query}
        onCompleted={graphqlParams.onCompleted}
        typename={graphqlParams.typename}
      >
        {({
          data,
          loading,
          error,
          networkStatus,
          queryField,
          refetch,
          fetchMore,
          ...restQuery
        }) => {
          // console.log('dataQuery',data);
          useEffect(() => {
            if (props.queryCb) {
              props.queryCb({ data, refetch, loading });
            }
          }, [data, loading]);
          // console.log({ data })
          return (
            <WrappedComponent
              {...props}
              graphqlParams={graphqlParams}
              setGraphQlParams={setGraphQlParams}
              queryName={queryName}
              refetch={refetch}
              data={props.data || data}
              loading={loading}
              error={error}
              restQuery={restQuery}
              fetchMore={!withVirtualized ? fetchMore : null}
            />
          );
        }}
      </QueryComponent>
    );
  }
};
export default withQuery;
