import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ConfirmModal } from '../';

Modal.setAppElement('#root');

const ModalReact = (props) => {
  const { isOpen, withoutConfirmModal } = props;
  const [isOutsideClick, setIsOutsideClick] = useState(false);
  const customStyles = {
    content: {
      top: `${props.top ? props.top : '50%'}`,
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      overflow: 'none',
      transform: 'translate(-50%, -50%)',
      width: `${props.width}`,
      minHeight: `${props.height}`,
      padding: `${props.padding ? props.padding : '-10px 0 0 0'}`,
      background: `${props.background || '#ffffff'}`,
      border: '1px solid #ececec',
      margin: `${props.margin ? props.margin : '-1px 0 0 0'}`,
      zIndex: props.zIndex || 107,
    },
  };
  if (!isOpen) return null;
  return (
    <>
      <Modal
        onRequestClose={() => {
          if(withoutConfirmModal) return props.closeModal();
          setIsOutsideClick(true);
        }}
        {...props}
        style={customStyles}
      >
        <ModalWrapper>
          {props.headerText &&
          <HeaderBlock headerBackground={props.headerBackground}><div> {props.headerText}</div>{props.closeModal &&
            <CloseBtn onClick={props.closeModal}>
              <div className='close-first'>
                <div className='close-second' />
              </div>
            </CloseBtn>}
          </HeaderBlock>}
          {props.Component}
        </ModalWrapper>
      </Modal>
      {(isOutsideClick && !withoutConfirmModal) && <ConfirmModal closeModal={props.closeModal} setIsOutsideClick={setIsOutsideClick} isOutsideClick={isOutsideClick}/>}
    </>
  );
};

export default ModalReact;

const ModalWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  & > div:nth-child(2) {
    background-color: #ffffff;
    padding: 10px 20px;
  }
`;

const CloseBtn = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .close-first {
    height: 20px;
    width: 2px;
    background-color: #fff;
    transform: rotate(45deg);
    Z-index: 1;
  }
  .close-second {
    height: 20px;
    width: 2px;
    background-color: #fff;
    transform: rotate(90deg);
    Z-index: 2;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 58px;
  border-radius: 3px;
  background-color: ${({ headerBackground }) => headerBackground || '#434343'};
  height: 55px;
  border-radius: 3px;
  color: #ffffff;
  padding: 16px 20px;
  font-size: 16px;
  div:nth-child(2) {
    cursor: pointer;
   }
`;

ModalReact.propTypes = {
  Component: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  headerText: PropTypes.string,
};

/*
  Example
    <ModalReact Component={<Some/>} headerText='Text'/>
*/
