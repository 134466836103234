import React from 'react';
import { Row, Col } from 'style';
import { Calendar, TextAreaStandart } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid.js';

import listFA_DeliveryPeriods from '../qql/listFA_DeliveryPeriods';

import updateFA_DeliveryPeriods from '../qql/updateFA_DeliveryPeriods';
import deleteFA_DeliveryPeriods from '../qql/deleteFA_DeliveryPeriods';
import createFA_DeliveryPeriods from '../qql/createFA_DeliveryPeriods';
import { useTranslation } from 'react-i18next';

export default (props) =>{
  const [t] = useTranslation();
  // const { data, loading, client } = useQuery(listCompareFa_Assortment, { variables: { id: +props.linkId } });
  // const [MUTATION_changeCompareFa_Assortment] = useMutation(changeCompareFa_Assortment);
    
  const tableData = [
    {
      name: t('common.fromDate'),
      dataBase: 'fromDate',
      isMutation: true,
      dataType: 'date',
      isRequired: true,
    },
    {
      name: t('common.toDate'),
      dataBase: 'toDate',
      isMutation: true,
      dataType: 'date',
      isRequired: true,
    },
    {
      name: t('common.note'),
      dataBase: 'description',
      isMutation: true,
    },
  ];

  const Component = (props) => {
    const { values, setFieldValue, handleChange, errors, touched } = props;
    return(
      <div>
        <Row>
          <Col lg={3}>
            <Calendar withoutFormik={true} title={t('common.fromDate')} setFieldValue={setFieldValue} name='fromDate' value={values.fromDate}/>
            {(errors?.fromDate && touched?.noActiveBtnClick) && <p style={{ color: 'red' }}>{errors?.fromDate}</p>}
          </Col>
          <Col lg={3}>
            <Calendar withoutFormik={true} title={t('common.toDate')} setFieldValue={setFieldValue} name='toDate' value={values.toDate}/>
            {((errors?.toDate && touched?.noActiveBtnClick) || errors?.toDate && Date.parse(props.values?.toDate) < Date.parse(props.values?.fromDate)) &&
              <p style={{ color: 'red' }}>{errors?.toDate}</p>
            }
          </Col>
        </Row>
        <TextAreaStandart
          withoutFormik={true}
          type='text'
          onChange={handleChange}
          value={values.description}
          name={'description'}
          title={t('common.note')}
          width='100%'
        />
      </div>
    );
  };

  return (
    <TableGrid
      withSearch
      itemDeleted={deleteFA_DeliveryPeriods}
      graphqlParams={{
        query: listFA_DeliveryPeriods,
        variables: { id: +props.linkId,
          isVacation: true,
        },
      }}
      // addNewItem = {addNewItem}
      // editItem={editItem}
      //onDelete = {onDelete}
      //searchValue={this.state.searchValue}
      //onSearch={this.onSearch}
      //getSortField={this.getSortField}
      tableData={tableData}
      //getTableIndex={this.indexOnClickTable}
      Component={Component}
      height={300}
      headerText={t('customer.breakOff')}
      modalWidth={550}
      modalHeight={370}
      withModalMutations
      defaultValuesForMutation = {{ isVacation: true,
        linkCustomer: +props.linkId,
        isCustomWeekDaySetting: false,
        isMonday: false,
        isTuesday: false,
        isWednesday: false,
        isThursday: false,
        isFriday: false,
        isSaturday: false,
        isSunday: false,
        isRepeatYearly: false }}
      typeName={'FA_DeliveryPeriods'}
      qqlMutationCreate={createFA_DeliveryPeriods}
      qqlMutationUpdate={updateFA_DeliveryPeriods}
    />
  );
};


