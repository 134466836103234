// import { typeOrder as to } from '../createOrder/params';
import { listFA_OrderTypesView } from '../qql/query';
import { readQuery } from 'helpers/queryOperations';

export const typeOrder = [
  {
    name: 'Order',
    id: 1,
  },
  {
    name: 'Additional order ',
    id: 2,
  },
  {
    name: 'Return ',
    id: 4,
  },
  {
    name: 'Credit Order ',
    id: 5,
  },
  {
    name: 'Special Order ',
    id: 6,
  },
  {
    name: 'Catering ',
    id: 9,
  },
];

export const searchFields = 'KundenNr';

export const tableData = (t) => ([
  {
    name: t('common.date'),
    dataBase: 'date',
    relativeWidth: 0.17,
    dataType: 'date',
  },
  {
    name: t('common.number'),
    dataBase: 'orderNr',
    relativeWidth: 0.14,
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },
  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
    relativeWidth: 0.2,
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },
  {
    name: t('common.name'),
    dataBase: 'actualName',
    relativeWidth: 0.25,
  },
  {
    name: t('common.total'),
    dataBase: 'total',
    relativeWidth: 0.15,
    dataType: 'money',
  },
  {
    name: t('order.orderType'),
    dataBase: 'orderType',
    relativeWidth: 0.28,
    specialComponent: function (...args) {
      const item = args[4];
      const client = args[6];
      const orderTypeList = readQuery({
        client,
        query: listFA_OrderTypesView,
        variables: {
          languageId: +localStorage.getItem('lngId') || 1,
        },
      });
      return orderTypeList?.find(({ id }) => +item.orderType === +id)?.orderTypeName;
    },
  },
]);

export const additionalMenuObj = [
  {
    name: 'Order',
    link: '/order/create/order',
  },
  {
    name: 'Default',
    link: '/order/create/default ',
  },
];

export const filterFolder = (t) => ([
  {
    title: t('common.date'),
    value: 'date',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.dateRange'),
    value: 'dateRange',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.show_deleted'),
    value: 'showDeletedOrders',
    field: 'folderName',
  },
  // {
  //   title: t('common.filter.deliveryNotesRange'),
  //   value: 'deliveryNotesRange',
  //   field: 'folderName',
  //   subfolder: [],
  // },
  // {
  //   title: 'Customer',
  //   value: 'customer',
  //   field: 'folderName',
  //   subfolder: [],
  // },
  // {
  //   title: 'Customer group',
  //   value: 'customerGroup',
  //   field: 'folderName',
  //   subfolder: [],
  // },
  // {
  //   title: 'Customer list',
  //   value: 'customerList',
  //   field: 'folderName',
  //   subfolder: [],
  // },
  // {
  //   title: 'One-time customers',
  //   value: 'oneTimeCustomers',
  //   field: 'folderName',
  //   subfolder: [],
  // },

]);

