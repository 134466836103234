import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Formik from 'components/formik';
import TableWithProductOnDate from 'components/tableWithProductOnDate/';

import Selects from './Selects';

const MainOffer = (props) => {
  const { t, accepted, declined, data } = props;
  const { client, query, variables } = props.productData;
  console.log(props);
  return (
    <Formik initialValues={{ textareaValue: '', invoiceDate: moment(new Date()).add(1, 'days'), textID: 2 }}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <MainBlock>
            <TopElements>
              <Selects {...props}/>
            </TopElements>

            <PositionsBlock>
              <TableWithProductOnDate
                {...props}
                data={data?.productTable || []}
                client={client}
                t={t}
                queryInfo = {{
                  query: query,
                  variables: variables,
                }}
                queryValue='listFA_GetOfferProducts'
                notMutable={accepted || declined}
                localSortKey='id'
              />
            </PositionsBlock>
          </MainBlock>
        );
      }}
    </Formik>
  );
};

export default MainOffer;

const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 90px;
`;
const TopElements = styled.div`
`;
const PositionsBlock = styled.div`
  margin-top: 15px;
`;
