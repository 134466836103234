import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, branch } from 'recompose';
import { findIndex, orderBy } from 'lodash';
import {
  Grid,
  AutoSizer,
  InfiniteLoader,
} from 'react-virtualized';
import { Col, Row } from 'react-bootstrap';
//import orderBy from 'lodash/orderBy'
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableTotal from './TableTotal';
import InputWithSearch from '../elements/InputWithSearch';
import withQuery from './hoc/withQuery';
import withCheckBoxList from './hoc/withCheckBoxList';
import withSearchHOC from './hoc/withSearchHOC';
import withDefaultState from './hoc/withDefaultState';
import withDataFilterPolicy from './hoc/withDataFilterPolicy';
import withCustomHeaderName from './hoc/withCustomHeaderName';
import { LoadingBar } from 'components/Loading';

import withModalMutations from './hoc/withModalMutations';
import { useTranslation } from 'react-i18next';

const TableGrid = (props) => {
  const [t] = useTranslation();
  const [offsetLoading, setOffsetLoading] = useState(false);
  const [isLastFetch, setLastFetch] = useState(false);
  const [tablePosition, setTablePosition] = useState(null);
  const [isSpacePressed, setSpacePressed] = useState(false);
  const [isInitScroll, setInitScroll] = useState(false);
  const [top, setTop] = useState('0px');
  const [localOffset, setLocalOffset] = useState(0);
  const [scrollIndex, setScrollIndex] = useState(0);
  const gridRef = useRef(null);

  const {
    tableData,
    height,
    rerender,
    additionalOptionOnDelete,
    setTableIndex,
    addNewItem,
    disableHeader,
    qqlMutationCreate,
    handleOpen,
    searchValue,
    error,
    multiSelect,
    loading,
    withTotal,
    changeSelectId,
    infiniteLoader,
    withUnderLine,
    rowCount,
    scrollToRow,
    onSectionRendered,
    overscanColumnCount,
    getFirstValueOfMount,
    getAllId,
    disableHeightChange,
    getValueOnStartById,
    linkId,
    title,
    localSortKey,
    minHeightTable,
    initScrollIndex,
    specialWidth,
    isDefaultOrder,
    setIdAfterCreation,
    onDetermineIsGridEmpty,
    startedIndex,
  } = props;

  let data = props.data || [];
  const localData = useRef(data || []);
  const localScroll = useRef({ index: null });

  data = useMemo(() => {
    if (localSortKey) {
      if (data && data.length) {
        return orderBy(data, [localSortKey], ['asc']);
      }
    }
    return data;
  }, [localSortKey, data]);

  const changeSelectedState = (addedItem) => {
    const indexInCurrentSort = findIndex(data, (item) => {
      return item.id === addedItem.id;
    });

    const { index: scrollIdx } = localScroll.current;
    const updatedIdx = scrollIdx > indexInCurrentSort
      ? indexInCurrentSort < 0 ? 0 : indexInCurrentSort
      : indexInCurrentSort;

    if (setIdAfterCreation && isDefaultOrder & !addedItem.productId) {
      // console.log('changeSelectedState', { addedItem, indexInCurrentSort, data })
      setIdAfterCreation(addedItem.id);
    }
    setTableIndex(indexInCurrentSort);
    setScrollIndex(isDefaultOrder ? updatedIdx : updatedIdx);
  };

  useEffect(() => {
    const {
      linkId,
      mainsection,
      subsection,
      sectionInfo,
    } = props;
    if (props.mainsection) {
      if (localData.current.length !== data.length || !isInitScroll) {
        const scrollIdx = findIndex(data, function (o) {
          return +o.id === linkId || +o.invoiceNr === linkId;
        });
        if (scrollIdx < 0 && mainsection === 'invoice') {
          setTableIndex(0);
          setScrollIndex(0);
          if (mainsection && subsection && sectionInfo && data && data[0]) {
            props.history.replace({
              pathname: `/${mainsection}/${subsection}/${sectionInfo.specialSelectField ? data[0][sectionInfo.specialSelectField] : data[0].id}`,
            });
          }
        } else {
          setScrollIndex(initScrollIndex > -1 ? initScrollIndex : scrollIdx);
          if (localScroll.current.index !== scrollIdx) {
            setTableIndex(scrollIdx);
          }
        }
        localScroll.current.index = scrollIdx;
        if (!isInitScroll) {
          setInitScroll(true);
        }
      }
    } else if (data.length > localData.current.length && data.length && localData.current.length && (initScrollIndex > 0 || initScrollIndex === undefined)) {
      const sortedData = !isDefaultOrder ? orderBy(data, ['id'], ['asc']) : data;
      const lastAddedItem = sortedData[sortedData.length - 1];

      if (isDefaultOrder) {
        localScroll.current.index = 0;
      }

      changeSelectedState(lastAddedItem);
    }
    localData.current = data;
  }, [props.mainsection, props.data]);

  useEffect(() => {
    if (!props.fetchMore) {
      setLastFetch(true);
    }
  }, [props.fetchMore]);

  useEffect(()=>{
    if(typeof startedIndex === 'number') {
      setTableIndex(startedIndex);
    }
  }, [data]);

  /**
   * Reset local offset after user change sort order (if graphqlParams exist)
   */
  useEffect(() => {
    if (props.graphqlParams) {
      setLastFetch(false);
      setLocalOffset(0);
    }
  }, [JSON.stringify(props.graphqlParams?.variables)]);

  /**
   * Reset local offset after user change sort order (without graphqlParams)
   */
  useEffect(() => {
    if (props.sortObject && !props.graphqlParams) {
      setLastFetch(false);
      setLocalOffset(0);
    }
  }, [JSON.stringify(props.sortObject)]);

  useEffect((e) => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 32 && e?.target?.className === 'ReactVirtualized__Grid') {
        e.preventDefault();
        setSpacePressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.keyCode === 32 && e?.target?.className === 'ReactVirtualized__Grid') {
        e.preventDefault();
        setSpacePressed(false);
      }
    };
    if (gridRef && gridRef.current) {
      gridRef && window.addEventListener('keydown', handleKeyDown);
      gridRef && window.addEventListener('keyup', handleKeyUp);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [gridRef]);

  useEffect(
    () => {
      if (!props.loading && onDetermineIsGridEmpty) {
        onDetermineIsGridEmpty(data.length === 0);
      }

      if (data.length > 0 && getValueOnStartById) {
        const findIndex = data.findIndex(({ id }) => id === linkId);
        setTableIndex(findIndex);
        setScrollIndex(findIndex);
        if (findIndex === -1) {
          getValueOnStartById(0, data[0]);
        } else {
          getValueOnStartById(findIndex, findIndex === -1 ? {} : data[findIndex]);
        }
      } else if (data.length > 0 && getFirstValueOfMount !== undefined) {
        getFirstValueOfMount(data[0]);
      } else if (data.length > 0 && getAllId !== undefined) {
        const arr = [];
        data.map((i) => {
          arr.push(i.id.toString());
        });
        getAllId(arr.toString());
      }
    },
    [props.data, props.loading],
  );
  /*
    *
      * Use in plagination
    *
  */
  // console.log('fetchMore', localOffset)
  const fetchMore = async () => {
    const { fetchMore, queryName } = props;
    try {
      await fetchMore({
        variables: {
          params: { ...props.params, offset: localOffset + 100 },
        },

        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult.fakturaAssist[queryName].length) {
            //this.setState({ isLastFetch: true });
            setLastFetch(true);
            return previousResult;
          }

          const result = Object.assign({}, previousResult, {
            fakturaAssist: {
              [queryName]: [
                ...previousResult.fakturaAssist[queryName],
                ...fetchMoreResult.fakturaAssist[queryName],
              ],
              __typename: 'FA_Query',
            },
          });
          return result;
        },
      });
      if (props.setOffset && props.offset) {
        props.setOffset(props.offset + 100);
      } else {
        setLocalOffset(localOffset + 100);
      }
      setOffsetLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const loadMoreRows = async ({ startIndex, stopIndex }) => {
    const { data } = props;
    if (stopIndex > data.length - 50 && !offsetLoading) {
      setOffsetLoading(true);
      props.fetchMore && await fetchMore();
      setOffsetLoading(false);
      //props.changeOffset(100);
      //console.log('load more');
    }
  };

  const isRowLoaded = ({ index }) => {
    if (!infiniteLoader) return true;
    if (isLastFetch) return true;
    return index > props.data.length - 20;
  };

  const selectByLinkId = () => {
    const {
      specialSelectField,
      linkId,
      mainsection,
      subsection,
      replaceHistory,
      sectionInfo,
    } = props;
    const newTablePosition = ((props.tableIndex || props.tableIndex === 0) && (props.tableIndex !== -1))
      ? props.dataPolicy === 'local' && isDefaultOrder ? data.findIndex((x) => +x.id === +linkId) : props.tableIndex
      : data.findIndex((x) => specialSelectField ? +x[specialSelectField] === +linkId : +x.id === +linkId);
    // console.log(props.tableIndex);
    // const newTablePosition = data.findIndex((x) => specialSelectField ? +x[specialSelectField] === +linkId : +x.id === +linkId);
    if (newTablePosition === -1 && replaceHistory) {
      if (!props.loading && props.graphqlParams.variables.params) {
        if (!props.graphqlParams.variables.params.search.string) {
          if (data.length) {
            props.history.replace({
              pathname: `/${mainsection}/${subsection}/${sectionInfo.specialSelectField ? data[0][sectionInfo.specialSelectField] : data[0].id}`,
            });
          } else {
            props.history.replace({
              pathname: `/${mainsection}/${subsection}`,
            });
          }
        }
      }
    }
    // console.log('before set', data.findIndex((x) => +x.id === +linkId), newTablePosition)
    setTablePosition(newTablePosition);
  };

  // console.log('after set',tablePosition)

  useEffect(
    () => {
      if (props.selectById) {
        selectByLinkId();
      }
    },
    [props.data, props.linkId, props.tableIndex],
  );

  /*
    *
      *
    *
  */

  /*
    *
      * MAIN
    *
  */

  //General func. Change index in table
  const changeTableIndex = (index, item) => {
    setTableIndex(index);// setTableIndex
    props.getTableIndex(index, item);
    changeSelectId && changeSelectId(item, index);
  };
  // Render  row in table
  const cellRenderer = ({ columnIndex, key, rowIndex, style, ...prev }) => {
    if (tableData[columnIndex] && tableData[columnIndex].isHidden) return null;
    if (props.cellRenderer) {
      return props.cellRenderer({ columnIndex, key, rowIndex, style, ...prev }, data);
    }
    // if (data[rowIndex] && !data[rowIndex].nameId && data[rowIndex].nameCustomer) {
    //   console.log('d', rowIndex, data[rowIndex])
    // }
    return (
      <TableRow
        multiSelect={multiSelect}
        isSpacePressed={isSpacePressed}
        itemDeleted={props.deleted}
        columnIndex={columnIndex}
        isSelected={props.selectedItems && props.selectedItems.indexOf(data[rowIndex].id) >= 0}
        addItemToSelected={props.addItemToSelected}
        key={key}
        setTop={setTop}
        top={top}
        rowIndex={rowIndex}
        style={style}
        editItem={props.qqlMutationUpdate ? props.handleOpen : null}
        changeIsUpdate={props.changeIsUpdate}
        changeTableIndex={changeTableIndex}
        setTableUpdate={props.setTableUpdate}
        tablePosition={tablePosition}
        data={data}
        {...props}
        tableData={tableData.filter((item) => !item.isHidden)}
      />
    );
  };
  /*
    *
      *
    *
  */

  /*
    *
      * Style
    *
  */

  let sumRelativeWidth = 0;
  let relativeWidthCount = 0;
  const maxWidth = addNewItem ? 0.99 : 1;
  const dataLength = tableData.length;
  tableData.forEach(({ relativeWidth }) => {
    if (relativeWidth) {
      sumRelativeWidth += relativeWidth;
      relativeWidthCount += maxWidth;
    }
  });
  const getColumnWidth = (width, { index }) => {
    if (tableData[index].isHidden) return 0;
    if (tableData[index]) {
      const relativeWidth = tableData[index].relativeWidth;
      if (relativeWidth) {
        return relativeWidth * width;
      } else {
        return (
          ((maxWidth - sumRelativeWidth) / (dataLength - relativeWidthCount)) * width - 1);
      }
    } else {
      return 10;
    }
  };
  const rowsLength = props.data ? props.data.length : 0;
  const calculateHeight = useMemo(() => {
    const maxItemsCount = height ? Math.round(height / 40) : 8;
    //Get height according to elements count in table
    if (rowsLength) {
      if (rowsLength > maxItemsCount) {
        return height || 600;
      } else {
        return rowsLength * 40;
      }
    } else {
      return 40;
    }
  }, [height, data]);

  return (
    <>
      {props.searchColumns && props.withTopSearch && <Row>
        <Col lg={12}>
          <InputWithSearch
            data={data}
            onSearch={props.onSearch}
            searchValue={props.searchValue}
            role={props.role}
            linkId={props.linkId}
            marginBottom='21px'
            mainsection={props.mainsection}
          />
        </Col>
      </Row>}
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={data && data.length}
      >
        {({ onRowsRendered, registerChild }) => {
          //onRowsRenderedContex = onRowsRendered;
          return (
            <GridBackground>
              <AutoSizer disableHeight style={{ width: '100%', overflow: specialWidth ? 'visible auto' : 'hidden' }}>
                {({ width, headHeight }) => {
                  const tableRender = () => {
                    if (error) {
                      return (<NoDataBlock height={disableHeightChange ? height : calculateHeight}>
                        <NotItems> {t ? t('common.error') : 'Error'} </NotItems>
                      </NoDataBlock>);
                    } else if (loading) {
                      return (
                        <div ref={gridRef}>
                          <Grid
                            cellRenderer={cellRenderer}
                            columnCount={tableData.length}
                            columnWidth={(index) => getColumnWidth(props.width || width, index)}
                            height={disableHeightChange ? height : calculateHeight}
                            rowCount={rowCount || data.length}
                            overscanColumnCount={overscanColumnCount || 0}
                            style={{ overflow: 'visible auto', width: 'max-content' }}
                            rowHeight={props.heightRow || 40}
                            width={props.width || width}
                            additionalOptionOnDelete={additionalOptionOnDelete}
                            rerender={rerender}
                            onSectionRendered={onSectionRendered}
                            ref={registerChild}
                            scrollToRow={scrollToRow}
                            // isScrolling ={true}
                            // isScrollingOptOut={true}
                            // scrollingResetTimeInterval={10}
                            rowClassName='row_name'
                          />
                          <LoadingBar />
                        </div>
                      );
                    } else if (!data.length) {
                      return (<NoDataBlock height={disableHeightChange ? height : calculateHeight}>
                        <NotItems> {t ? t('common.noDataAvailable') : 'No items'} </NotItems>
                      </NoDataBlock>);
                    } else {
                      return (
                        <div className={props.className}>
                          <Grid
                            cellRenderer={cellRenderer}
                            onRowsRendered={onRowsRendered}
                            columnCount={tableData.filter((item) => !item.isHidden).length}
                            columnWidth={(index) => getColumnWidth(props.width || width, index)}
                            height={disableHeightChange ? height : calculateHeight}
                            rowCount={rowCount || data.length}
                            rowHeight={props.heightRow || 40}
                            width={props.width || width}
                            overscanColumnCount={overscanColumnCount || 0}
                            style={{ overflow: 'visible auto', overflowX: specialWidth ? 'hidden' : 'auto', width: 'max-content', minHeight: minHeightTable || 'auto' }}
                            additionalOptionOnDelete={additionalOptionOnDelete}
                            rerender={rerender}
                            ref={registerChild}
                            onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
                              onRowsRendered({
                                startIndex: rowStartIndex,
                                stopIndex: rowStopIndex,
                              });
                            }
                            }
                            scrollToRow={scrollToRow ? scrollToRow : (scrollIndex > 10
                              ? scrollIndex
                              : scrollIndex === 'none' ? -1 : scrollIndex)
                            }
                            onScroll={() => {
                              if (scrollIndex !== 'none') {
                                setScrollIndex('none');
                              }
                            }}
                          // isScrolling ={true}
                          // isScrollingOptOut={true}
                          // scrollingResetTimeInterval={10}
                          /> {(loading || offsetLoading) && <LoadingBar />}
                        </div>
                      );
                    }
                  };
                  return (
                    <div style={{ width: '100%' }}>
                      {title && <TableTitle>{t('options.forms.elementsAdjustment')}</TableTitle>}
                      {!disableHeader && (
                        <TableHeader
                          getColumnWidth={getColumnWidth}
                          width={width}
                          addNewItem={qqlMutationCreate ? handleOpen : addNewItem || null}
                          {...props}
                          tableData={tableData.filter((item) => !item.isHidden)}
                        />
                      )}
                      {props.searchColumns && !props.disableSearch ? (
                        <InputWithSearch

                          data={data}
                          onSearch={props.onSearch}
                          searchValue={searchValue}
                          role={props.role}
                          linkId={props.linkId}
                          disableRadius
                          mainsection={props.mainsection}
                        />
                      ) : null}
                      {tableRender()}
                      {withUnderLine && <TableTotal
                        getColumnWidth={getColumnWidth}
                        tableData={tableData}
                        width={width}
                        color='#3c445a'
                        background='#fff3c3'
                        tableMainColumn='underLine'

                        {...props}
                      />}
                      {withTotal && rowsLength ? <TableTotal
                        getColumnWidth={getColumnWidth}
                        tableData={tableData}
                        width={width}
                        tableMainColumn='total'

                        {...props}
                      /> : <div />}

                    </div>);
                }
                }
              </AutoSizer>
            </GridBackground>
          );
        }}
      </InfiniteLoader>
    </>
  );
};
export default compose(
  withQuery,
  withDataFilterPolicy,
  withDefaultState,
  branch(
    ({ withSearch }) => !!withSearch,
    withSearchHOC
  ),
  branch(
    ({ withCheckbox }) => !!withCheckbox,
    withCheckBoxList,
  ),
  branch(
    ({ customHeaderOptions }) => !!customHeaderOptions,
    withCustomHeaderName,
  ),
  branch(
    ({ withModalMutations }) => !!withModalMutations,
    withModalMutations,
  ),
)(TableGrid);

TableGrid.propTypes = {
  tableData: PropTypes.array.isRequired, // all information of table. See more info down.
  dbGettingName: PropTypes.string, // name of u query. Need to fetch array.
  data: PropTypes.array.isRequired, // array from dataBase like [{}, {}].
  getTableIndex: PropTypes.func, // (index): Int, get table index
  searchValue: PropTypes.string, //Search value
  onSearch: PropTypes.func, // (value):String, used to change searchValue
  height: PropTypes.number, // height of table
  preventDeletionForId: PropTypes.number, // Removes delete option for item with provided id
  fetchMore: PropTypes.func, // function on Query component without params or callback
  cellRenderer: PropTypes.func, // You own rerender func  ({ columnIndex: Int, key: Int, rowIndex: Int, style: Object})
  getSortField: PropTypes.func, // (sortField) : String . When user press on header takes actual name from DB. Need to sort
  addNewItem: PropTypes.func, // Func called on header icon 'add'
  getBlockColor: PropTypes.func, // (item): Obj, get color of left block. return only string !!!
  link: PropTypes.string, // items link. To pass params from db  use next format 'customer/adresse/:Intern', where value after : are correct! name of db,
  total: PropTypes.any, //Green down row with total sum,]
  rowActiveColor: PropTypes.string, // Left Block color when row is active
  specialTableIndex: PropTypes.number, // Active table row according to this number
  rowColor: PropTypes.string, //Color of table rows
  infiniteLoader: PropTypes.bool, // Infinity loader in graphqlparams fetchMore params in require when infiniteLoader on
  getFirstValueOfMount: PropTypes.func,
  disableHeightChange: PropTypes.bool,
  title: PropTypes.string, //Title under table header
  initialSortValue: PropTypes.string,
};

TableGrid.defaultProps = {
  tableData: [
    {
      name: 'Some name', // Required! Name of header, only string
      dataBase: 'Intern', //Required! Name of database key, only string
      relativeWidth: 0.2, //Not required! Width of some coluns in relative value (0.2 = 20%). Another colons calculate automatic
      headerTextAlign: 'right', //Not required! position of header, only left, right, center
      contentTextAlign: 'right', //Not required! position of contet elements, only left, right, center
      substring: 10, //Not required! Only INT! Cut data from 0 to substring value
      specialComponent: <div />, //Not required! You special components on this colons(Chechbox, img and other)
      total: true || 1 || 'string', //Boolean || Number || String. If a boolean is passed, the total counts locally across the field
      dataType: 'float', // date || float || percent
    },
  ],
  data: [],
  getTableIndex: () => {
    //
  },
};

export const GridBackground = styled.div`
  background-color: #f4f4f4;
  position: relative;
`;

const NoDataBlock = styled.div`
  text-align: center;
  background: #f4f4f4;
  box-shadow: 0 2px 2px 0 rgba(90,90,90,.12);
  color: grey;
  font-size: 16px;
  min-height: ${(props) => props.height}px;
  span{
    display: inline-block;
    padding-top: ${(props) => props.height / 2}px;
    padding-bottom: ${(props) => props.height / 2}px;
  }
  width: 100%;
`;

export const NameBlock = styled.div`
  width: ${(props) => props.width};
  float: left;
  position: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding: 8px 0px 0px 8px;
  height: 36px;
  background-color: #2e3941;
  color: #fff;
`;

const TableTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
  padding: 0 0 6px 0;
`;

const NotItems = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 10px 0;
`;

