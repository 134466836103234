import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';

import { Button, Input, Transition, Textarea, Dropdown, Calendar } from 'components/elements'; // eslint-disable-line
import Formik from 'components/formik';
import TableWithProductOnDate from 'components/tableWithProductOnDate/';
import useQuerySpecial from 'components/graphql/useQuerySpecial';

import { listFA_OrderTypesView, listFA_CreatedOrderForCustomer, listFA_Customer, getFA_GetOrderPositions } from '../../qql/query';

import addShippingCost from 'style/order/addShippingCost.svg';

import { readQuery } from 'helpers/queryOperations';

const Main = (props) => {
  const { t, client, contex, ordersDate, setOrdersDate, ordersCustomerId, setOrdersCustomerId, chooseCustomerInfo } = props;

  const orderTypes = useQuerySpecial(listFA_OrderTypesView, {
    fetchPolicy: 'cache-first',
    variables: {
      languageId: +localStorage.getItem('lngId') || 1,
    },
  });

  const disableRows = useMemo(() => {
    const orderList = readQuery({
      query: listFA_CreatedOrderForCustomer,
      client,
      variables: contex,
    });
    const currentOrder = orderList.find(({ id }) => id === props.id);
    return !!currentOrder?.isDelete;
  }, [props.id]);

  return (
    <Formik initialValues={props.orderInfo} enableReinitialize={true} ref={props.formikRef}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        const actualOrderType = orderTypes?.data?.find(({ id }) => +props.orderInfo?.orderType === +id);
        return (
          <MainBlock>
            <TopElements style={{ pointerEvents: disableRows ? 'none' : 'auto', opacity: disableRows ? '.3' : '1' }}>
              <Row>
                <Col lg={4}>
                  <div><TitleName> {t('order.orderType')}</TitleName><OrderTypeBlock> {actualOrderType ? actualOrderType.orderTypeName : ''}</OrderTypeBlock></div>
                </Col>
                <Col lg={2}>
                  <Calendar
                    withoutFormik={true}
                    title={t('common.date')}
                    setFieldValue={(_, date) => {
                      setOrdersDate(date);
                    }}
                    name='toDate'
                    value={ordersDate || chooseCustomerInfo.date} />
                </Col>
                <Col lg={4}>
                  <Dropdown
                    width='300px'
                    graphqlParams={{ query: listFA_Customer }}
                    valueField='id'
                    labelField='nameCustomerDisp'
                    withoutFormik={true}
                    setFieldValue={(_, value) => {
                      setOrdersCustomerId(value);
                    }}
                    title={t('customer.customer')}
                    defaultValueField={ordersCustomerId || chooseCustomerInfo.customerLink}
                    withVirtualized
                  />
                </Col>
                <Col lg={4}>
                  <ButtonBlock>
                    <Button value={t('order.orderEdit')} upperCase className='green-btn' onClick={() => props.editOrderOperation(values)} />
                  </ButtonBlock>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Input title={t('order.orderRef')} field='orderNrSAP' value={values.orderNrSAP} />
                </Col>
                <Col lg={2}>
                  <Input title={t('common.deliveryTime')} field='deliveryTime' value={values.deliveryTime} small />
                </Col>
              </Row>
            </TopElements>

            <TableActions disableRows={disableRows}>
              <div> </div>
              <ActionBlock background={props.isDelivery && '#fff3c3'} onClick={props.onDeliveryClick} disabled={disableRows}>
                <Image alt='' src={addShippingCost} />
              </ActionBlock>
            </TableActions>

            <PositionsBlock>
              <TableWithProductOnDate
                {...props}
                data={props.productTable || []}
                t={props.t}
                queryInfo={{
                  skip: !props.id,
                  query: getFA_GetOrderPositions,
                  variables: { id: props.id },
                  client: props.client,
                }}
                disableRows={disableRows}
                queryValue='getFA_GetOrderPositions'
                localSortKey='orderPositionID'
              />
            </PositionsBlock>

            {/* <TransitionBlock> <Transition text={t('common.note')} minHeight='200px' isOpen><Textarea value={values.memo} field='memo' /></Transition></TransitionBlock> */}
          </MainBlock>
        );
      }}
    </Formik>
  );
};

export default Main;

const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 90px;
`;
const TopElements = styled.div`
`;
const TitleName = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
  margin: 15px 0 6px 0;
`;
const OrderTypeBlock = styled.div`
  height: 36px;
  background-color : #b2b2b2;
  max-width: 300px;
  cursor: not-allowed;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
  padding: 10px;
  color: #000000;
`;
const ButtonBlock = styled.div`
  margin: 39px 0 0 20px;
`;
const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
  pointer-events: ${({ disableRows }) => disableRows ? 'none' : 'auto'};
  opacity: ${({ disableRows }) => disableRows ? '.3' : '1'};
  margin-top: -22px;
`;
const ActionBlock = styled.div`
  height: 32px;
  width: 32px;
  background-color: ${({ background }) => background || '#ffffff'};
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
  opacity: ${({ disabled }) => disabled ? '.3' : '1'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 5px 10px;
  float: right;
  cursor: pointer;
`;
const PositionsBlock = styled.div`

`;

const Image = styled.img`
  /*margin: 1px 0px 1px ${({ marginRight }) => marginRight}px; */
`;

// const TransitionBlock = styled.div`
//   margin-top: 10px;
// `;
