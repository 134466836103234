import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Col, Row, Table } from 'style/index';
import { Calendar, Dropdown } from 'components/elements';
import withPDFActions from 'components/viewPDF/withPDFActions';
import ActionsList, { ActionsListBlock } from 'components/actionsList/ActionsList';

import { TablesDebitors } from './TablesDebitors.js';
import { VIEW_FROM, REMINDER_TEXT } from '../graphql/debitor';

import checkedIcon from 'style/path.svg';

const Radio = (props) => {
  const { title, field, onClick, checked } = props;
  return (
    <Main>
      <Label >
      	<RadioStyle
          type='radio'
          onClick={()=>onClick(checked, field)}
          checked={checked}
          field={field}
        />
        <RadioCircle/>
      	<span>{title}</span>
      </Label>
    </Main>
  );
};

const Debts = (props) => {
  const { sendPDFIntoPrinter, showPDFPreview, sendPDFIntoEmail } = props;
  const [t] = useTranslation();
  const nowDate = new Date();
  const [defaultValue, setDefaultValue] = useState({ form: 1, reminderText: 2 });
  const [dateForDebts, setDateForDebts] = useState({ dateFrom: nowDate, dateTill: nowDate, dateUp: nowDate });
  const [printReport, setPrintReport] = useState({ allCustomer: true, selectedInvoiceAllCustomer: false, onlyInvoice: false });
  const [pdfParams, setPDFParams] = useState({});
  const [id, setId] = useState();
  const [firstValue, setFirstValue] = useState({});

  const setFalse = (obj, field) => {
    const clone = obj;
    for (const el in clone) {
      if ({}.hasOwnProperty.call(clone, el)) {
        clone[el] = false;
        clone[field] = true;
      }
    }
    return (clone);
  };

  const setPdfParams = (id, customerId, invoicesList) => {
    const { allCustomer, selectedInvoiceAllCustomer, onlyInvoice } = printReport;
    const defaultParamsForAllCustomers = {
      reportId: `${defaultValue.form / 1000000}`,
      ACustomerID: 0,
      AInvoicesList: invoicesList,
      APeriodStart: moment(dateForDebts.dateFrom).format('DD.MM.YYYY'),
      APeriodEnd: moment(dateForDebts.dateTill).format('DD.MM.YYYY'),
      APaymentsTill: moment(dateForDebts.dateUp).format('DD.MM.YYYY'),
      AReminderTextID: '2',
      AUserFormularID: '0',
    };

    const pdfParams = defaultParamsForAllCustomers;

    if (allCustomer) {
      setPDFParams(pdfParams);
    } else if (selectedInvoiceAllCustomer) {
      for (const el in defaultParamsForAllCustomers) {
        if ({}.hasOwnProperty.call(defaultParamsForAllCustomers, el)) {
          defaultParamsForAllCustomers.ACustomerID = customerId;
        }
      }
      setPDFParams(pdfParams);
    } else if (onlyInvoice) {
      for (const el in defaultParamsForAllCustomers) {
        if ({}.hasOwnProperty.call(defaultParamsForAllCustomers, el)) {
          defaultParamsForAllCustomers.ACustomerID = 0;
          defaultParamsForAllCustomers.AInvoicesList = `${id}`;
        }
      }
      setPDFParams(pdfParams);
    } else {
      setPDFParams(pdfParams);
    }
  };

  const getFirstValueOfMount = (info) => (values) => {
    // get first value with Grid in moment mounting
    const { id, customerId } = values;
    const { invoicesList } = info;
    setFirstValue({ id, customerId, invoicesList });
    setPdfParams(id, customerId, invoicesList);
  };

  const changeValuesRowInGrid = (_, info) => {
    // change values wor in grid (table)
    console.log(info);
    const { id, customerId } = info;
    console.log(id);
    setId(id);
    setPdfParams(id, customerId);
  };

  const changePrintReport = (_, field) => {
    // change action for printing (all customes, selected cust, only invoice)
    const clone = setFalse(printReport, field);
    const { allCustomer, selectedInvoiceAllCustomer, onlyInvoice } = clone;
    setPrintReport({ allCustomer, selectedInvoiceAllCustomer, onlyInvoice });
    const { id, customerId, invoicesList } = firstValue;
    console.log(invoicesList);
    setPdfParams(id, customerId, invoicesList);
  };

  return (
    <Body>
      <Row>
        <Title lg={2} marginLeft={'20px'}>
	 			  {t('debitor.openDebts')}
        </Title>
        <StyledActions>
          <ActionsList
            t={t}
            text={t('common.actions')}
            pdfParams={pdfParams}
            customActions={true}
            actions={{
              printReport: () => {
                sendPDFIntoPrinter(pdfParams);
              },
              preview: (actualPdfParams) => {
                showPDFPreview(actualPdfParams || pdfParams);
              },
              email: () => {
                sendPDFIntoEmail(pdfParams);
              },
              history: () => {
              },
            }}
          />
        </StyledActions>
      </Row>
      <StyledHeader>
        <Col width={'380px'}>
          <Row>
            <Col lg={2}>
              <Calendar
                field='dateFrom'
                title={t('debitor.invoicesFrom')}
                value={dateForDebts.dateFrom}
                withoutFormik
                dateFormat
                onChange={(value)=>{
                  setDateForDebts({ ...dateForDebts, dateFrom: value });
                }}
              />
            </Col>
            <Col lg={2}>
              <Calendar
                field='dateTill'
                title={t('debitor.invoicesTill')}
                value={dateForDebts.dateTill}
                withoutFormik
                dateFormat
                onChange={(value)=>{
                  setDateForDebts({ ...dateForDebts, dateTill: value });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={1}>
              <Calendar
                field='dateUp'
                title={t('debitor.considerPaymentUpTo')}
                value={dateForDebts.dateUp}
                withoutFormik
                dateFormat
                onChange={(value)=>{
                  setDateForDebts({ ...dateForDebts, dateUp: value });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col width={'380px'}>
          <Dropdown
            title={t('common.form')}
            field='reportId'
            labelField='form'
            valueField='reportId'
            defaultValueField={defaultValue.form}
            isFirstValue={true}
            graphqlParams={{ query: VIEW_FROM, variables: {
              languageId: +localStorage.getItem('lngId') || 1,
            } }}
            onChange={(value) => {
              setDefaultValue({ ...defaultValue, form: value.value });
              setPDFParams({ ...pdfParams, reportId: `${value.value / 1000000}` });
            }}
          />
          <Dropdown
            title={t('debitor.reminderText')}
            field='reminderText'
            defaultValueField={defaultValue.reminderText}
            graphqlParams={{ query: REMINDER_TEXT }}
            onChange={(value)=>{
              setDefaultValue({ ...defaultValue, reminderText: value.value });
            }}
          />
        </Col>
        <Col width={'380px'}>
          <PositionRadio>
            <Radio
              field='allCustomer'
              title={t('debitor.allCustomer')}
              onClick={changePrintReport}
              checked={printReport.allCustomer}
            />
            <Radio
              field='selectedInvoiceAllCustomer'
              title={t('debitor.selectedInvoiceAllCustomer')}
              onClick={changePrintReport}
              checked={printReport.selectedInvoiceAllCustomer}
            />
            <Radio
              field='onlyInvoice'
              title={t('debitor.onlyInvoice')}
              onClick={changePrintReport}
              checked={printReport.onlyInvoice}
            />
          </PositionRadio>
        </Col>
      </StyledHeader>
      <StyledContent>
	 	    <Row>
          <Title>{t('common.result')}</Title>
        </Row>
        <Table>
          <TablesDebitors
            date={dateForDebts}
            getTableIndex={changeValuesRowInGrid}
            getFirstValueOfMount={getFirstValueOfMount}
            idElement={id}/>
        </Table>
	  </StyledContent>
    </Body>
  );
};

export default withPDFActions(Debts);

const Body = styled.div`
  /* min-width: 1400px; */
  margin: -20px 20px 0 20px;
`;

const StyledActions = styled(ActionsListBlock)`
  z-index: 1;
  margin: 92px 40px 0 0;
`;

const StyledHeader = styled(Row)`
	border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  border-top: 1px solid #3e4c57;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 0 0 20px 20px;
`;

const Title = styled(Col)`
  margin: 12px 0 2px 0;
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
  /* font-family: Roboto; */
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
`;

const StyledContent = styled.div`
margin-top: 10px;
padding: 0 0 20px 20px;
border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;

const PositionRadio = styled.div`
  margin: 35px 0 0 0;
`;

const Label = styled.label`
    font-weight: 400;
    float: left;
`;

const RadioCircle = styled.span`
  border-radius: 50%;
  background-size: 60%;
  content: '';
  display: inline-block;
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  background-color: #fff;
  margin-right: 8px;
  border: 0.5px solid #cdcdcd;
  /* -webkit-transition: background-color 0.3s, border 0.3s; */
  transition: background-color 0.3s, border 0.3s;
`;

const RadioStyle = styled.input`
  display: none;
  &:checked + ${RadioCircle} {
    background-image: url(${checkedIcon});
    background-repeat: no-repeat;
    background-position: center;
    border-color: #3cb950;
    
  }
`;

const Main = styled.div`
  float: left;
  width: 100%;
`;
