import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query getFA_Customer($id: ID!){
    fakturaAssist{
    	getFA_Customer(id: $id){
	    	id
		    customerNr
		    deliveryNote
		    phoneG
		    phoneP
		    phoneG2
		    fax
		    natel
		    email
			nameId
				nameCustomer
		    customerAddress{
		      address
		      deliveryAddress
		    }
	 	}
 	}
}`;

