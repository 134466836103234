import React, { useState } from 'react';
import { Col, Row } from 'style';

import { Checkbox, Button, DateRangeInModal } from 'components/elements';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';

import callFA_RecalculateOrders from '../../../qql/getPR_ReCalculateOrders.js';

export default (props) => {
  const [t] = useTranslation();
  const [fromDate, setFromDate] = useState(Date.now());
  const [toDate, setToDate] = useState(Date.now());
  const [isAdaptPrices, setIsAdaptPrices] = useState(false);
  const [isAdaptConditions, setIsAdaptConditions] = useState(true);
  const [isAdaptCashAssist, setIsAdaptCashAssist] = useState(false);
  const [callRefresh, { loading }] = useLazyQuery(callFA_RecalculateOrders);

  const changeDates = (date, secondDate) =>{
    setFromDate(date);
    setToDate(secondDate);
    console.log(date, secondDate);
  };

  const clickRefresh = () =>{
    const variables = {
      args: {
        fromDate: fromDate,
        toDate: toDate,
        id: props.linkId,
        onlyCAOrders: isAdaptCashAssist,
        refreshPrices: isAdaptPrices,
        refreshTaxAndDiscounts: isAdaptConditions,
      },
    };

    callRefresh({ variables });
  };

  return (
    <div>
      <Row>
        <Col width={'640px'}>
          <span>{t('customer.refresh.mainText')}</span>
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <DateRangeInModal
            width='300px'
            onAccept={changeDates}
            margin='0 0 20px 0'
          />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Checkbox
            title={t('customer.refresh.adaptPrices')}
            margin='0 0 9px 0'
            specialFunc
            withoutFormik
            value={isAdaptPrices}
            onChange={(e, v)=>setIsAdaptPrices(v)} />
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Checkbox
            title={t('customer.refresh.adaptConditions')}
            margin='0 0 9px 0'
            specialFunc
            withoutFormik
            value={isAdaptConditions}
            onChange={(e, v)=>setIsAdaptConditions(v)}/>
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Checkbox
            title={t('customer.refresh.adaptCashAssist')}
            margin='0 0 9px 0'
            specialFunc
            withoutFormik
            value={isAdaptCashAssist}
            onChange={(e, v)=>setIsAdaptCashAssist(v)}/>
        </Col>
      </Row>

      <Row>
        <Col lg={2} style={{ margin: '10px 0 0 0' }}>
          <Button
            value={t('customer.refresh.updateCustomer')}
            onClick={clickRefresh}
            disabled={(!isAdaptCashAssist && !isAdaptConditions && !isAdaptPrices) || loading}
            upperCase
            className='black'
          />
        </Col>
      </Row>
    </div>
  );
};


