export default ()=>{
  return (
    {
      comment: null,
      isTitle: null,
      isActivated: null,
      IsMonAvailable: null,
      IsTueAvailable: null,
      IsWedAvailable: null,
      IsThuAvailable: null,
      IsFriAvailable: null,
      IsSatAvailable: null,
      IsSunAvailable: null,
      IsGroupAvailable: null,
      DateMon: null,
      DateTue: null,
      DateWed: null,
      DateThu: null,
      DateFri: null,
      DateSat: null,
      DateSun: null,
      ReplacingDateMon: null,
      ReplacingDateTue: null,
      ReplacingDateWed: null,
      ReplacingDateThu: null,
      ReplacingDateFri: null,
      ReplacingDateSat: null,
      ReplacingDateSun: null,
      amount: null,
      discountP: null,
      ArticleInformation: null,
      IsDough: null,
      ItemSortPos: null,
      Mon1: null,
      Mon2: null,
      Mon3: null,
      Mon4: null,
      Mon5: null,
      Mon6: null,
      Mon7: null,
      Mon8: null,
      Mon9: null,
      Mon10: null,
      Tue1: null,
      Tue2: null,
      Tue3: null,
      Tue4: null,
      Tue5: null,
      Tue6: null,
      Tue7: null,
      Tue8: null,
      Tue9: null,
      Tue10: null,
      Wed1: null,
      Wed2: null,
      Wed3: null,
      Wed4: null,
      Wed5: null,
      Wed6: null,
      Wed7: null,
      Wed8: null,
      Wed9: null,
      Wed10: null,
      Thu1: null,
      Thu2: null,
      Thu3: null,
      Thu4: null,
      Thu5: null,
      Thu6: null,
      Thu7: null,
      Thu8: null,
      Thu9: null,
      Thu10: null,
      Fri1: null,
      Fri2: null,
      Fri3: null,
      Fri4: null,
      Fri5: null,
      Fri6: null,
      Fri7: null,
      Fri8: null,
      Fri9: null,
      Fri10: null,
      Sat1: null,
      Sat2: null,
      Sat3: null,
      Sat4: null,
      Sat5: null,
      Sat6: null,
      Sat7: null,
      Sat8: null,
      Sat9: null,
      Sat10: null,
      Sun1: null,
      Sun2: null,
      Sun3: null,
      Sun4: null,
      Sun5: null,
      Sun6: null,
      Sun7: null,
      Sun8: null,
      Sun9: null,
      Sun10: null,
      quantityMon: null,
      MonMax: null,
      MonMin: null,
      quantityTue: null,
      TueMax: null,
      TueMin: null,
      quantityWed: null,
      WedMax: null,
      WedMin: null,
      quantityThu: null,
      ThuMax: null,
      ThuMin: null,
      quantityFri: null,
      FriMax: null,
      FriMin: null,
      quantitySat: null,
      SatMax: null,
      SatMin: null,
      quantitySun: null,
      SunMax: null,
      SunMin: null,
      canChangeInOrders: null,
    }
  );
};
