import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

const withLink = (WrappedComponent) => (props) => {
  const { match, history, sectionInfo } = props;
  const { id, subsection, mainsection } = match.params;
  const [mainListInfo, changeMainListInfo] = useState({});
  const [specialTableIndex, changeSpecialTableIndex] = useState(0);
  const [t] = useTranslation();
  //Get actual section from global array. By first parts of link
  const cacheSection = cloneDeep(sectionInfo);
  let actualSection = cacheSection.filter(({ link }) => link === mainsection)[0] || { subSection: [] };
  //Get actual SUBsection from global array. By second parts of link
  let actualSubSection = {};
  //All path cache in order
  if(actualSection.separateSection) {
    actualSection.subSection = actualSection.separateSection;
    actualSection.separateSection.forEach((i)=>{
      if(subsection === i.link) {
        actualSubSection = { ...i };
        actualSection.name = i.name;
        actualSection.specialMenuObj = i.specialMenuObj;
        actualSection.listQuery = i.listQuery;
        actualSection.itemQuery = i.itemQuery;
        actualSection.defaultFilterParams = i.defaultFilterParams;
        actualSection.FilterComponent = i.FilterComponent;
        actualSection.tableData = i.tableData;
        actualSection.Handler = i.Handler;
        actualSection.withParams = i.withParams;
        actualSection.specialSelectField = i.specialSelectField;
        actualSection.searchColumns = i.searchColumns;
        actualSection.dataPolicy = i.dataPolicy;
        actualSection.reportForms = i.reportForms;
        actualSection.withDefaultParams = i.withDefaultParams;
        actualSection.sortParams = i.sortParams;
        actualSection.historyFormTypeID = i.historyFormTypeID;

      }
    });
  }
  if(actualSection.subSection) {
    actualSection.subSection.forEach((i)=>{
      if(i.itemQuery && subsection === i.link) {
        actualSubSection = i;
        actualSection.itemQuery = i.itemQuery;
        actualSection.updateItem = i.updateItem;
        actualSection.withDefaultParams = i.withDefaultParams;
        actualSection.sortParams = i.sortParams;
        actualSection.historyFormTypeID = i.historyFormTypeID;
      } else if(subsection === i.link) {
        const cacheSection = sectionInfo.filter(({ link }) => link === `${mainsection}`)[0] || { subSection: [] };
        actualSection = { ...actualSection, itemQuery: cacheSection.itemQuery, updateItem: cacheSection.updateItem, transitions: i.transitions || [] };
      }
    });
  }
  if(actualSection.withParams) {
    actualSection.defaultFilterParams = { ...actualSection.defaultFilterParams, params: { ...props.params } };
  }
  //1 Get actual se
  const changeLink = (ids, item) => {
    const actualPath = `/${mainsection}/${subsection}/${actualSection.specialSelectField ? item[actualSection.specialSelectField] : item.id}`;
    !actualSubSection.withoutID && history.replace({
      pathname: actualPath,
    });
    changeMainListInfo(item);
    changeSpecialTableIndex(ids);
  };
  return (
    <WrappedComponent {...props}
      specialTableIndex = {actualSubSection.withoutID ? specialTableIndex : null}
      mainListInfo = {mainListInfo}
      sectionInfo={actualSection}
      subSectionInfo={actualSubSection}
      linkId={id} subsection={subsection}
      changeLink={changeLink}
      mainsection={mainsection}
      defaultFilterParams = {actualSection.defaultFilterParams}
      t={t}/>
  );
};

export default withLink;
