import React, { useState } from 'react';

//When query params need in main componentns

const withParams = (WrappedComponent) => (props) => {
  // console.log('withParams', props)
  const [searchString, changeSearchString] = useState('');
  const [sortParams, changeSortParams] = useState(
    {
      field: props.sectionInfo?.sortField || props.sortField || props.sortFiled || 'Intern',
      order: props.sectionInfo?.sortOrder || props.sortOrder || 'DESC',
    });
  const [offset, changeOffset] = useState(0);
  const params = {
    search: {
      string: searchString,
      columns: props.searchColumns || ['name'],
    },
    sort: {
      field: sortParams.field,
      order: sortParams.order,
    },
    offset: offset,
    limit: props.limit || 100,
  };
  return(
    <WrappedComponent
      {...props}
      sortParams={sortParams}
      changeSearchString={changeSearchString}
      changeSortParams={changeSortParams}
      changeOffset={changeOffset}
      params={params}
    />
  );
};
export default withParams;

// input ListQueryArgs {
// search: SearchParams
// sort: [SortParams]
// mod_ver: Int
// offset: Int
// limit: Int
// }

// input SearchParams {
// string: String
// columns: [String]
// }

// input SortParams {
// field: String!
// order: SortOrder
// }

// enum SortOrder {
// ASC
// DESC
// }

