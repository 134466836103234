import React from 'react';
import { useMutation } from 'components/graphql';
import editFA_History from './gql/editFA_History';

const withHistoryMutation = (WrapperComponent) => (props) => {
  const [mutateHistory, historyMutationResult] = useMutation(editFA_History);
  return (
    <WrapperComponent
      {...props}
      mutateHistory={mutateHistory}
      historyMutationResult={historyMutationResult}
    />
  );
};

export default withHistoryMutation;

