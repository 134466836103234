import React from 'react';
import moment from 'moment';

export const tableData = (t) => ([
  {
    dataBase: 'dOperation',
    name: t('common.dateTime'),
    relativeWidth: 0.13,
    specialComponent: (index, _, value) => {
      return(
        <div>
          {value && (moment(+value).format('DD.MM.YYYY HH:mm:ss'))}
        </div>
      );
    },
  },
  {
    dataBase: 'operationName',
    name: t('history.operation'),
  },
  {
    dataBase: 'userFullName',
    name: t('common.userName'),
    relativeWidth: 0.12,
  },
  {
    dataBase: 'fieldName',
    name: t('history.field'),
    relativeWidth: 0.1,
  },
  {
    dataBase: 'oldValue',
    name: t('history.oldValue'),
    relativeWidth: 0.15,
  },
  {
    dataBase: 'newValue',
    name: t('history.newValue'),
    relativeWidth: 0.15,
  },
  {
    dataBase: 'content',
    name: t('common.content'),
  },
]);
