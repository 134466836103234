import React from 'react';

export const tableDeliveryDate = (t) => ([
  {
    name: t('common.customerNo'),
    dataBase: 'productNr',
    relativeWidth: 0.3,
  },

  {
    name: t('common.name'),
    dataBase: 'nameRes',
  },
]);

export const tableOrdersParams = (t) =>([
  {
    name: t('common.date'),
    dataBase: 'date',
    dataType: 'date',
    withoutTotal: true,
  },

  {
    name: t('common.deliveryNr'),
    dataBase: 'orderId',
    textAlign: 'right',
    withoutTotal: true,
  },

  {
    name: t('common.total'),
    dataBase: 'total',
    dataType: 'float',
  },
]);

export const tableOrdersParamsWithCustomerInfo = (t) =>([
  {
    name: t('common.date'),
    dataBase: 'date',
    dataType: 'date',
    relativeWidth: 0.25,
    withoutTotal: true,
  },

  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
  },

  {
    name: t('common.name'),
    dataBase: 'name',
    relativeWidth: 0.22,
  },

  {
    name: t('common.deliveryNr'),
    dataBase: 'orderId',
    textAlign: 'right',
    withoutTotal: true,
  },

  {
    name: t('common.total'),
    dataBase: 'total',
    dataType: 'float',
  },
]);

export const tableProductsParams = (t) => ([
  {
    name: t('common.articleNo'),
    dataBase: 'productNr',
    withoutTotal: true,
  },

  {
    name: t('common.quantity'),
    dataBase: 'quantity',
    textAlign: 'right',
    withoutTotal: true,
    type: 'number',
    specialComponent: (index, _, value)=>{
      return(
        <div>
          {value && value !== 0 ? value : ''}
        </div>
      );
    },
  },

  {
    name: t('common.description'),
    dataBase: 'productName',
    relativeWidth: 0.30,
    specialComponent: (index, _, __, data) => {
      const productName = data[index].productName;
      const indTextDeliveryNote = data[index].indTextDeliveryNote?.replace(/<[^>]+>/g, '');
      return(
        <div>
          {indTextDeliveryNote || productName}
        </div>
      );
    },
    colorBlockFunc: (data, index) => {
      const productName = data[index].productName;
      const indTextDeliveryNote = data[index].indTextDeliveryNote?.replace(/<[^>]+>/g, '');
      const indTextProduction = data[index].indTextProduction?.replace(/<[^>]+>/g, '');
      return (
        (indTextDeliveryNote && indTextDeliveryNote !== productName) ||
        (indTextProduction && indTextProduction !== productName) ?
          '#FDBE9E' : undefined
      );
    },
  },

  {
    name: t('common.price'),
    dataBase: 'price',
    dataType: 'float',
    type: 'number',
    textAlign: 'right',
    withoutTotal: true,
    specialComponent: (index, _, value)=>{
      return(
        <div>
          {Number(value).toFixed(2)}
        </div>
      );
    },
  },

  {
    name: t('common.total'),
    dataBase: 'total',
    textAlign: 'right',
    specialComponent: (index, _, value)=>{
      return(
        <div>
          {typeof value === 'number' ? value.toFixed(2) : 0.00}
        </div>
      );
    },
  },

  {
    name: t('common.discount'),
    dataBase: 'discount',
    withoutTotal: true,
    type: 'number',
    textAlign: 'right',
    specialComponent: (index, _, value)=>{
      return(
        <div>
          {value && value + '%'}
        </div>
      );
    },
  },

  {
    name: t('common.vat'),
    dataBase: 'taxName',
    withoutTotal: true,
  },
]);

export const filterFolder = (t) => ([
  {
    title: t('common.filter.activeCustomers'),
    value: 'activeCustomers',
    field: 'folderName',
    subfolder: [],
  },
  {
    title: t('common.filter.inactiveCustomers'),
    value: 'inactiveCustomers',
    field: 'folderName',
    subfolder: [],
  },
  // {
  //   title: 'Customer',
  //   value: 'customer',
  //   field: 'folderName',
  //   subfolder: [],
  // },

]);
