import React, { useState, useRef, useEffect } from 'react';

import usePrevious from 'helpers/usePrevious';
import { MutationStatus } from './StatusCircle';


const StatusHandler = ({ status }) => {
  const [showIndicator, setShowIndicator] = useState(false);
  const indicatorTimer = useRef();
  const prevStatus = usePrevious(status);
  
  useEffect(() => {
    if(!status && prevStatus === status) {
      return;
    }
    const clearIndicatorTimeout = () => {
      if(indicatorTimer.current) {
        clearTimeout(indicatorTimer.current);
      }
    };
    clearIndicatorTimeout();
    if(!showIndicator && (status === 'loading' || prevStatus === 'loading')) {
      setShowIndicator(true);
    }
  }, [status, showIndicator, prevStatus]);
  
  useEffect(() => {
    if(showIndicator) {
      const removeIndicator = (timeout) => {
        indicatorTimer.current = setTimeout(() => setShowIndicator(false), timeout);
      };
      if(prevStatus === 'loading' && !status) {
        removeIndicator(2000);
      }
      if(status === 'error' || status === 'networkError') {
        removeIndicator(5000);
      }
    }
  }, [status, showIndicator, prevStatus]);
  
  return showIndicator ? <MutationStatus status={status}/> : null;
};


export default StatusHandler;