export const timePeriodTableParams = (t) =>([
  {
    name: t('statistic.timePeriod'),
    dataBase: 'time',
  },
    
]);

export const customersTableParams = (t) =>([
  {
    name: t('common.customers'),
    dataBase: 'time',
  },
      
]);

export const articlesTableParams = (t) =>([
  {
    name: t('common.articles'),
    dataBase: 'time',
  },
      
]);

export const orderTypeTableParams = (t) =>([
  {
    name: t('order.orderType'),
    dataBase: 'time',
  },
      
]);

export const infoTableParams = (t) =>([
  {
    name: t('common.number'),
    dataBase: 'test',
  },
  {
    name: t('common.description'),
    dataBase: 'test',
  },
  {
    name: t('common.quantity'),
    dataBase: 'test',
  },
  {
    name: t('order.order'),
    dataBase: 'test',
  },
  {
    name: t('order.return'),
    dataBase: 'test',
  },
  {
    name: t('order.catering'),
    dataBase: 'test',
  },
  {
    name: t('statistic.cashAssistCreditCustomerOrder'),
    dataBase: 'test',
  },
  {
    name: t('common.total'),
    dataBase: 'test',
  },
  {
    name: t('product.discountGroups'),
    dataBase: 'test',
  },
  {
    name: t('product.marketingGroups'),
    dataBase: 'test',
  },
  {
    name: t('product.productionGroups'),
    dataBase: 'test',
  },
]);


