import React, { useState, Fragment } from 'react';
import styled from 'styled-components';

import TableGrid from '../tableGrid/TableGrid';

import BlackArrowUp from '../../style/blackArrowUp.svg';
import BlackArrowDown from '../../style/blackArrowDown.svg';

import Query from '../graphql/Query';

export default (props) =>{
  const { rowItem, rowIndex, tableData, elementQuery, values } = props;
  const [productNr, changeProductNr] = useState('');
  const [open, changeOpen] = useState(false);
  const arrowRender = () =>{
    if(open) {
      return <img src={BlackArrowUp} alt ='' style={{ marginRight: '20px' }}/>;
    }else{
      return <img src={BlackArrowDown} alt='' style={{ marginRight: '20px' }} />;
    }
  };
  return (
    <Fragment>
      <ArticleBlock id='main_block_in_section_table' onClick={() => {
        changeProductNr(rowItem.productNr);
        changeOpen(!open);
      }} rowIndex={rowIndex} open={open}> {arrowRender() }
        <span> {rowItem.productNr + ' ' + rowItem.name}</span>
        <QuantityBlock> {rowItem.total}</QuantityBlock>
      </ArticleBlock>
      {open &&
        <Query
          query={elementQuery}
          variables={{ args: { date: values.date, secondDate: values.secondDate, productNr: productNr } }}
        >
          {({ data, loading, error }) => {
            return <TableGrid disableHeader disableHeightChange width={1840} height={240 } data={data} tableData={tableData} borderStyle='circle' loading={loading} error={error}/>;
          }}
        </Query>
      }
    </Fragment>
  );
};
const ArticleBlock = styled.div`
  height: 36px;
  box-shadow: 0 1px 0 0 rgba(76, 76, 76, 0.08);
  font-size: 14px;
  padding: 12px;
  color: #3c445a
  font-weight: ${(props) => props.open ? 600 : 300};
  background-color: ${(props) => (props.rowIndex % 2 ? '#eeeeee' : '#fff')};`;

const QuantityBlock = styled.span`
     
`;
