import React, { useState } from 'react';
import styled from 'styled-components';

import DefaultFilter from 'components/filter/DefaultFilter';

import { RadioGroup, Calendar, Dropdown } from 'components/elements';
import { filterFolder } from '../params';

const FilterOrder = (props) => {
  const { t } = props;
  const [localFilterInfo, setLocalFilterInfo] = useState(props.filterInfo);
  const onConfirm = () => {
    props.setFilterInfo({ ...localFilterInfo, folderName: localFilterInfo.folderName });
    //Close filter after accept
    props.setOpenFilter(false);
  };

  const changeFolderName = (e) => {
    setLocalFilterInfo({ ...localFilterInfo, folderName: e.target.value });
  };

  const changeDate = (date) => {
    setLocalFilterInfo({ ...localFilterInfo, date: date });
  };

  const renderComponentUnderRadio = (value) =>{
    switch(value) {
      case 'dailyCustomer':
      case 'customerWithEmptyOrders':
      case 'acceptedOffers':
      case 'activeCustomer':
      case 'inactiveCustomers':
        return <RadioBlock><Calendar withoutFormik onChange = {changeDate} value={localFilterInfo && localFilterInfo.date} title={t('common.date')}/></RadioBlock>;
      case 'customer':
        return <RadioBlock><Dropdown title={t('customer.customer')} data={[]}/></RadioBlock>;
      default:
        return null;
    }
  };

  return (
    <DefaultFilter
      onClose={() => props.setOpenFilter(false)}
      onConfirm={onConfirm}
      t={t}
    >
      <RadioGroup
        field='folderName'
        listItems={filterFolder(t)}
        values={{ folderName: localFilterInfo.folderName }}
        onClick={changeFolderName}
        renderComponentUnderRadio = {renderComponentUnderRadio}
      />
    </DefaultFilter>
  );
};

export default FilterOrder;

const RadioBlock = styled.div`
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #fff;
    height: 100%;
    
`;
