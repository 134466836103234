import React, { memo, useMemo } from 'react';
import gql from 'graphql-tag';

export const getQuery = (key) => gql`
query {
    fakturaAssist{
      getFA_Config{
        ${key}
     }
   }
}`;

const withCustomHeaderName = (WrappedComponent) => memo(({ customHeaderOptions = {}, tableData, ...props }) => {
  const { key, offset } = customHeaderOptions;

  const customData = useMemo(() => {
    const { restQuery } = props;
    if (key && offset && restQuery.client) {
      const { fakturaAssist: { getFA_Config } = {} } = restQuery.client.cache.readQuery({ query: getQuery(key) });
      const filtered = getFA_Config?.[key].filter((val) => val) || [];
      return [...tableData.slice(0, offset), ...tableData.slice(offset, filtered.length + offset + 1).map((data, idx) => ({ ...data, name: filtered[idx] || data.name }))];
    }
    return tableData;
  }, [customHeaderOptions]) // eslint-disable-line

  return <WrappedComponent {...props} tableData={customData} />;
});

export default withCustomHeaderName;
