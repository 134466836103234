import React, { useState } from 'react';
import { Modal } from 'components/elements';
import { ConfirmModal } from '../modals';
import IconWrapper from './IconWrapper';

import { ReactComponent as Delete } from 'style/img/delete_image.svg';
import imageActions from '../helpers/imageActions';

const ImageDelete = ({ mutation, id, client, t }) => {
  const [isConfirmModalDeleteImage, setConfirmModalDeleteImage] = useState(false);
  const { deleteImage } = imageActions(mutation, id, client);
  const onConfirmDeleteImage = () => {
    deleteImage();
  }

  return (
    <>
      <Modal
        isOpen={isConfirmModalDeleteImage}
        closeModal={() => setConfirmModalDeleteImage(false)}
        headerText={t('common.confirm')}
        width='450px'
        height='220px'
        Component={
          <ConfirmModal
            t={t}
            closeModal={() => setConfirmModalDeleteImage(false)}
            onConfirm={onConfirmDeleteImage}
            text={t('Delete picture')}
          />
        }
      />
      <IconWrapper width={'50%'}>
        <Delete onClick={() => setConfirmModalDeleteImage(true)} />
      </IconWrapper>
    </>
  );
};

export default ImageDelete;
