import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Checkbox, Transition } from 'components/elements';
import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid';

import { postTableNamesParams, postTableAttributesParams } from './params';



export default ({ t, configData, onSubmit }) => {
  return (
    <Formik
      initialValues={{ configData }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <Row>
            <Transition text={t('options.configurations.post.postSettings')} isOpen setTransitions={null}>
              <Row>
                <Checkbox
                  margin='24px 0 0 0'
                  value={values.isAutomaticDeliveryDay}
                  field='isAutomaticDeliveryDay'
                  title={t('options.configurations.post.activetePostFunction')}
                />
              </Row>
            </Transition>
             
            <Transition text={t('options.configurations.post.postSectors')} isOpen minHeight='500px'>
              <Col lg={3}>
                <TableContent>
                  <TableGrid
                    tableData={postTableNamesParams(t)}
                    //graphqlParams={{ query: listFA_TaxList }}
                    height={720}
                    disableHeightChange
                    data={[{ test: 'test' }, { test: 'test' }, { test: 'test' }]}
                    // itemDeleted={deleteFA_AusnahmeTag}
                    // defaultValuesForCache={['addedOn']}
                    // defaultValuesForMutation = {{ customerLink: 1 }}
                    // typeName={'listFA_AusnahmeTag'}
                    // qqlMutationCreate={createFA_AusnahmeTag}
                    // qqlMutationUpdate={updateFA_AusnahmeTag}
                    // withModalMutations
                    //getTableIndex = {(_, item) => setFromDate(item.fromDate)}
                  />
                </TableContent>
              </Col>
              <Col lg={9}>
                <AttributesTable>
                  <TableGrid
                    tableData={postTableAttributesParams(t)}
                    width={400}
                    //graphqlParams={{ query: listFA_TaxList }}
                    height={720}
                    disableHeightChange
                    data={[{ test: 'test' }, { test: 'test' }, { test: 'test' }]}
                    //getTableIndex = {(_, item) => setFromDate(item.fromDate)}
                  />
                </AttributesTable>
              </Col>
            </Transition>
          </Row>
        );
      }}
    </Formik>
  );
};

const TableContent = styled.div`
  margin-top: 20px
`;

const AttributesTable = styled.div`
  padding: 18px 60px;
`;
