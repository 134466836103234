import gql from 'graphql-tag';

export default gql`
query listFA_CustomerCRMNotes($id: ID! ){
    fakturaAssist{
    listFA_CustomerCRMNotes(id: $id){
		id
		customerLink
		note
		addedOn
    }
    
  }
}`;
