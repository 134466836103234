import gql from 'graphql-tag';

export default gql`
query listDefaultOrder($id: Int, $date: String, $GUID: String){
    fakturaAssist{
    listDefaultOrder(id: $id, date: $date, GUID: $GUID){
		productId
		customerId
		id
		description
		productNr
		comment
		isTitle
		isActivated
		IsMonAvailable
		IsTueAvailable
		IsWedAvailable
		IsThuAvailable
		IsFriAvailable
		IsSatAvailable
		IsSunAvailable
		IsGroupAvailable
		DateMon
		DateTue
		DateWed
		DateThu
		DateFri
		DateSat
		DateSun
		ReplacingDateMon
		ReplacingDateTue
		ReplacingDateWed
		ReplacingDateThu
		ReplacingDateFri
		ReplacingDateSat
		ReplacingDateSun
		amount
		discountP
		ArticleInformation
		IsDough
		ItemSortPos
		Mon1
		Mon2
		Mon3
		Mon4
		Mon5
		Mon6
		Mon7
		Mon8
		Mon9
		Mon10
		Tue1
		Tue2
		Tue3
		Tue4
		Tue5
		Tue6
		Tue7
		Tue8
		Tue9
		Tue10
		Wed1
		Wed2
		Wed3
		Wed4
		Wed5
		Wed6
		Wed7
		Wed8
		Wed9
		Wed10
		Thu1
		Thu2
		Thu3
		Thu4
		Thu5
		Thu6
		Thu7
		Thu8
		Thu9
		Thu10
		Fri1
		Fri2
		Fri3
		Fri4
		Fri5
		Fri6
		Fri7
		Fri8
		Fri9
		Fri10
		Sat1
		Sat2
		Sat3
		Sat4
		Sat5
		Sat6
		Sat7
		Sat8
		Sat9
		Sat10
		Sun1
		Sun2
		Sun3
		Sun4
		Sun5
		Sun6
		Sun7
		Sun8
		Sun9
		Sun10
		quantityMon
		MonMax
		MonMin
		quantityTue
		TueMax
		TueMin
		quantityWed
		WedMax
		WedMin
		quantityThu
		ThuMax
		ThuMin
		quantityFri
		FriMax
		FriMin
		quantitySat
		SatMax
		SatMin
		quantitySun
		SunMax
		SunMin
		canChangeInOrders
    }
    
  }
}`;
