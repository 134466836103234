import gql from 'graphql-tag';

export default gql`
query listFA_TextModule($params: ListQueryArgs!) {
    fakturaAssist{
    	listFA_TextModule(params: $params){
    		id
    		name
 		}
 	}
}`;
