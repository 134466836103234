import { useContext } from 'react';
import { useMutation as useMutationOriginal } from '@apollo/react-hooks';
import { transformData } from '../utils';
import { StatusIndicatorContext } from '../context/StatusIndicatorContext';

const useMutation = (mutation, options = {}) => {
  const { onCompleted, withIndicator = true, ...restOptions } = options;
  const { addPromise } = useContext(StatusIndicatorContext);

  const handleOnCompleted = (data) => {
    if(typeof onCompleted === 'function') {
      const { data: transformedData, ...dataInfo } = transformData(data);
      onCompleted(transformedData, dataInfo);
    }
  };

  const [mutate, { data, ...restMutation }] = useMutationOriginal(mutation, {
    ...restOptions,
    onCompleted: handleOnCompleted,
  });

  const handleMutate = (mutateOptions = {}) => {
    const { update, ...rest } = mutateOptions;
    const parameters = rest;
    if(typeof update === 'function') {
      parameters.update = (cache, { data: updateData, ...restUpdateCache }) => {
        const { data: transformedData } = transformData(updateData);
        return update(cache, {
          ...restUpdateCache,
          data: transformedData,
        });
      };
    }
    const promise = mutate(parameters);
    if(withIndicator) {
      addPromise(promise);
    }
    return promise;
  };

  const { data: transformedData, ...dataInfo } = transformData(data);

  return [handleMutate, {
    ...restMutation,
    ...dataInfo,
    data: transformedData,
  }];
};

export default useMutation;
