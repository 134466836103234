import gql from 'graphql-tag';

export default gql`
mutation updateFA_SpecialPrices($id: ID!, $fields: SpecialPricesFields!){
    fakturaAssist{
    updateFA_SpecialPrices(id: $id, fields: $fields){
		id
		specialPriceTypeId
		currentPrice
    productName

		
    }
    
  }
}`;
