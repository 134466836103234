import React from 'react';
import styled from 'styled-components';

import { Dropdown, Checkbox, Input } from 'components/elements';
import { Row, Col } from 'style';
import Formik from 'components/formik';

import listFA_ProductionGroup from './qql/listFA_ProductionGroup';
import listFA_ProductionSorting from './qql/listFA_ProductionSorting';
import listFA_ProductionForm from './qql/listFA_ProductionForm';
import listFA_ProductionCustomerSortingKinds from './qql/listFA_ProductionCustomerSortingKinds';
import listFA_CustomerTourplan from './qql/listFA_CustomerTourplan';

import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const { linkId } = props;
  const week = ['isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday', 'isSunday'];
  const valueGroup = props.data && (props.values?.reportGroupID || props.data.reportGroupID);
  return (
    <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        const isAllDays = () =>{
          let isDays = true;
          for (const day of week) {
            if(!values[day]) {
              isDays = false;
              break;
            }
          }
          return isDays;
        };
        const onChangeAllDays = () =>{
          for (const day of week) {
            handleChange([day], !isAllDays());
            handleSubmit([day], !isAllDays());
            setFieldValue([day], !isAllDays());
          }
        };

        return (
          values && <HeadBlock>
            <MainBlock>
              <Row>
                <Col lg={4}>
                  <Input value={values.name} field='name' title={t('common.description')}/>
                  <Dropdown
                    key={`linkGroup${linkId}`}
                    title={t('common.group')}
                    valueField = 'id'
                    labelField = 'name'
                    field = 'linkGroup'
                    defaultValueField = {values.linkGroup}
                    graphqlParams={{ query: listFA_ProductionGroup, variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    } }}
                  />
                  {([11, 65, 1, 34, 41, 14, 25, 27, 26, 15, 42, 20, 66].includes(Math.round(values.reportID / 1000000)) || (valueGroup === 3)) && <Dropdown
                    title={t('production.articleSorting')}
                    key={`linkSorting${linkId}`}
                    valueField = 'id'
                    labelField = 'name'
                    field = 'linkSorting'
                    defaultValueField = {values.linkSorting || 2}
                    graphqlParams={{ query: listFA_ProductionSorting, variables: { valueGroup: valueGroup, languageId: +localStorage.getItem('lngId') || 1 } }}
                  />}
                  {[11, 65].includes(Math.round(values.reportID / 1000000)) && values.isCrosstableEasyMode &&
                  <Dropdown
                    key={`linkCustomerSorting${linkId}`}
                    title={t('production.customerSortingDrop')}
                    valueField = 'id'
                    labelField = 'name'
                    field = 'linkCustomerSorting'
                    defaultValueField = {values.linkCustomerSorting}
                    graphqlParams={{ query: listFA_ProductionCustomerSortingKinds }}
                  />}

                  {[32].includes(Math.round(values.reportID / 1000000)) &&
                  <Checkbox
                    key={`isTourenplanLS${linkId}`}
                    margin='48px 0 20px 0'
                    value={values.isTourenplanLS}
                    field='isTourenplanLS'
                    title={t('common.isTourenplanLS')}/>}

                </Col>
                <Col lg={4}>
                  <Checkbox margin='48px 0 20px 0' value={values.isPrint} field='isPrint' title={t('production.activated')}/>
                  {valueGroup !== 3 && valueGroup !== 11 &&
                            <Dropdown
                              key={`reportID${linkId}`}
                              title={t('common.form')}
                              valueField = 'id'
                              labelField = 'form'
                              field = 'reportID'
                              defaultValueField = {values.reportID}
                              graphqlParams={{ query: listFA_ProductionForm, variables: { id: valueGroup } }}
                            />}
                  {[15, 42, 32].includes(Math.round(values.reportID / 1000000)) &&
                  <Dropdown
                    key={`tourenplanDay${linkId}`}
                    title={t('production.tourenplanDay')}
                    data={[
                      { id: '0', name: t('customer.delivery.standartTour') },
                      { id: '1', name: t('customer.delivery.satTour') },
                      { id: '2', name: t('customer.delivery.sunTour') },
                    ]}
                    valueField = 'id'
                    labelField = 'name'
                    field = 'tourenplanDay'
                    defaultValueField = {values.tourenplanDay}
                  />}
                  {[32].includes(Math.round(values.reportID / 1000000)) &&
                            <Dropdown
                              key={`linkTourenplan${linkId}`}
                              title={t('common.tour')}
                              valueField = 'id'
                              labelField = 'name'
                              field = 'linkTourenplan'
                              defaultValueField = {values.linkTourenplan}
                              graphqlParams={{ query: listFA_CustomerTourplan }}
                            />}
                  {[20, 15, 42, 32].includes(Math.round(values.reportID / 1000000)) &&
                  <Checkbox
                    key={`isNeedSignatureOnForwardingList${linkId}`}
                    margin='48px 0 20px 0'
                    value={values.isNeedSignatureOnForwardingList}
                    field='isNeedSignatureOnForwardingList'
                    title={t('production.isNeedSignatureOnForwardingList')}/>}
                  {(([14, 25, 27, 26, 57].includes(Math.round(values.reportID / 1000000)) && [1, 8, 9].includes(valueGroup)) || [11].includes(valueGroup)) &&
                    <Checkbox
                      key={`showSemiProductsDetails${linkId}`}
                      margin='48px 0 20px 0'
                      value={values.showSemiProductsDetails}
                      field='showSemiProductsDetails'
                      title={t('production.showSemiProductsDetails')}/>}
                  {[26].includes(Math.round(values.reportID / 1000000)) &&
                      <Checkbox
                        key={`isShowProductionDescription${linkId}`}
                        margin='48px 0 20px 0'
                        value={values.isShowProductionDescription}
                        field='isShowProductionDescription'
                        title={t('production.isShowProductionDescription')}/>}
                  {[11, 65].includes(Math.round(values.reportID / 1000000)) &&
                        <Checkbox
                          key={`isCrosstableEasyMode${linkId}`}
                          margin='48px 0 20px 0'
                          value={values.isCrosstableEasyMode}
                          field='isCrosstableEasyMode'
                          title={t('production.isCrosstableEasyMode')}/>}

                  {[11, 65].includes(Math.round(values.reportID / 1000000)) && values.isCrosstableEasyMode &&
                                  <Dropdown
                                    key={`CrosstableEasyModeSplitNo${linkId}`}
                                    title={t('production.CrosstableEasyModeSplitNo')}
                                    data={[
                                      { id: '0', name: 'Gesamt' },
                                      { id: '1', name: '1' },
                                      { id: '2', name: '2' },
                                      { id: '3', name: '3' },
                                      { id: '4', name: '4' },
                                      { id: '5', name: '5' },
                                      { id: '6', name: '6' },
                                      { id: '7', name: '7' },
                                      { id: '8', name: '8' },
                                      { id: '9', name: '9' },
                                      { id: '10', name: '10' },
                                    ]}
                                    valueField = 'id'
                                    labelField = 'name'
                                    field = 'CrosstableEasyModeSplitNo'
                                    defaultValueField = {values.CrosstableEasyModeSplitNo}
                                  />}

                  {/* {[14, 26, 27, 25, 49].includes(values.defaultFormID) && <Checkbox margin='48px 0 20px 0' value={values.isPrint} field='isPrint' title={t('production.showSemiProductDetails')}/>} */}
                </Col>
              </Row>
              <Row>
                <Col lg = {2}>
                  <Checkbox key={`isAllDays${linkId}`} margin='30px 0 0px 0' specialFunc={true} value={isAllDays()} onClick={onChangeAllDays} title={t('common.days.allDays')}/>
                </Col>
              </Row>
              <Row>
                <Col lg = {2}>
                  <Checkbox key={`monday${linkId}`} margin='20px 0 0px 0' value={values[week[0]]} field={week[0]} title={t('common.days.monday')}/>
                </Col>
                <Col lg = {2}>
                  <Checkbox key={`wednesday${linkId}`} margin='20px 0 0px 0' value={values[week[2]]} field={week[2]} title={t('common.days.wednesday')}/>
                </Col>
                <Col lg = {2}>
                  <Checkbox key={`friday${linkId}`} margin='20px 0 0px 0' value={values[week[4]]} field={week[4]} title={t('common.days.friday')}/>
                </Col>
                <Col lg = {2}>
                  <Checkbox key={`sunday${linkId}`} margin='20px 0 0px 0' value={values[week[6]]} field={week[6]} title={t('common.days.sunday')}/>
                </Col>
              </Row>
              <Row>
                <Col lg = {2}>
                  <Checkbox key={`tuesday${linkId}`} margin='20px 0 0px 0' value={values[week[1]]} field={week[1]} title={t('common.days.tuesday')}/>
                </Col>
                <Col lg = {2}>
                  <Checkbox key={`thursday${linkId}`} margin='20px 0 0px 0' value={values[week[3]]} field={week[3]} title={t('common.days.thursday')}/>
                </Col>
                <Col lg = {2}>
                  <Checkbox key={`saturday${linkId}`} margin='20px 0 20px 0' value={values[week[5]]}field={week[5]} title={t('common.days.saturday')}/>
                </Col>
              </Row>
              {(([1, 14, 25, 27, 26, 34, 41, 57, 66].includes(Math.round(values.reportID / 1000000)) && [1].includes(valueGroup)) || [11].includes(valueGroup)) && <Row>
                <Col lg={2}>
                  <Input key={`addDay${linkId}`} value={values.addDay} field='addDay' type = 'number' title={t('production.preProductionInDays')}/>
                </Col>
              </Row>}
            </MainBlock>
          </HeadBlock>
        );
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  height: 680px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
