import gql from 'graphql-tag';

export const EXPORT_WA = gql`
    mutation ($filter: [FA_GeneralFilter], $s3export: Boolean){
        fakturaAssist {
            exportWA(filter: $filter, s3export: $s3export) {
                debitorAccount
                date
                data
            }
        }
    }
`;
