import gql from 'graphql-tag';

const RUN_EXPORT = gql`
  mutation exportSpecialOrder($exportAll: Boolean, $type: SpecialOrderTypeEnum, $filter: [FA_GeneralFilter], $s3export: Boolean) {
    fakturaAssist {
        exportSpecialOrder(exportAll: $exportAll, type: $type, filter: $filter, s3export: $s3export) {
            data
            name
            type
        }
	}
  }
`;

export default RUN_EXPORT;
