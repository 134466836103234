import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Input, Checkbox, Dropdown, Transition } from 'components/elements';
import Formik from 'components/formik';

import { listFA_PriceRoundingMethods, listFA_OrderPositionsSortingKinds } from '../../qql/query';

 
export default ({ t, configData, onSubmit }) => {
  const orderGroup = configData.orderGroup || new Array(10).fill('');
  return (
    <Formik initialValues={{ ...configData, orderGroup: orderGroup }} enableReinitialize onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        const inputRender = values.orderGroup && values.orderGroup.map((item, index) => {
          if(index === 0) {
            return (
              <Input title={t('common.groupDeliveryNote')} field={`orderGroup[${index}]`} value={item} />
            );
          }else{
            return(
              <OrderGroupSingleInput> <Input field={`orderGroup[${index}]`} value={item} /></OrderGroupSingleInput>
            );
          }
        });
        return (
         
          <Row>
            <Transition text={t('options.configurations.order.deliveryNote')} isOpen setTransitions={null}>
              <Row>
                <Col lg={4}>
                  <Row>
                    {inputRender}
                  </Row>
                 
                </Col>
                <Col lg={8}>
                  <Input title={t('common.groupDeliveryNote')} field='invoiceNumber' value={values.invoiceNumber}/>
                  <Row>
                    <Col lg={6}>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInMonday}
                          field='isDeliveryInMonday'
                          title={t('common.days.monday')}
                        />
                      </Col>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInWednesday}
                          field='isDeliveryInWednesday'
                          title={t('common.days.wednesday')}
                        />
                      </Col>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInFriday}
                          field='isDeliveryInFriday'
                          title={t('common.days.friday')}
                        />
                      </Col>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInSunday}
                          field='isDeliveryInSunday'
                          title={t('common.days.sunday')}
                        />
                      </Col>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInTuesday}
                          field='isDeliveryInTuesday'
                          title={t('common.days.tuesday')}
                        />
                      </Col>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInThursday}
                          field='isDeliveryInThursday'
                          title={t('common.days.thursday')}
                        />
                      </Col>
                      <Col lg = {3}>
                        <Checkbox
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isDeliveryInSaturday}
                          field='isDeliveryInSaturday'
                          title={t('common.days.saturday')}
                        />
                      </Col>
              
                    </Col>
                  </Row>
                  <Row>
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isAutomaticDeliveryDay}
                      field='isAutomaticDeliveryDay'
                      title={t('options.configurations.order.whenCapturingTheOrderPosition')}
                    />
                  </Row>
                </Col>
                
              </Row>
            </Transition>
             
            <Transition text={t('common.orders')} isOpen minHeight='500px'>
              <Row>
                <Col lg={4}>
                  <Row>
                    <Checkbox
                      margin='24px 0 12px 0'
                      value={values.isOrderPricePrior}
                      field='isOrderPricePrior'
                      title={t('options.configurations.order.whenCapturingTheOrderPosition')}
                    />
                  </Row>
                  
                  <Row>
                   
                    <Dropdown
                      title={t('options.configurations.order.articlesPriceRounding')}
                      field='roundingArticlePriceMethodID'
                      defaultValueField = {values.roundingArticlePriceMethodID}
                      graphqlParams={{ query: listFA_PriceRoundingMethods }}
                    />
                  </Row>
                  <Row>
                    <Dropdown
                      title={t('options.configurations.order.orderPositionsSorting')}
                      field='importOrdersPositionSorting'
                      defaultValueField = {values.importOrdersPositionSorting}
                      graphqlParams={{ query: listFA_OrderPositionsSortingKinds }}
                    />
                  </Row>
                  <Row>
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isShippingNote}
                      field='isShippingNote'
                      title={t('options.configurations.order.activateShippingDocumentsCreation')}
                    />
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isDelivery}
                      field='isDelivery'
                      title={t('options.configurations.order.createShippingDocumentsforCardboard')}
                    />
                  </Row>

                </Col>
                
              </Row>
            </Transition>
          </Row>
        );
      }}
    </Formik>
  );
};

const OrderGroupSingleInput = styled(Col)`
    padding-top: 10px;
`;
