import gql from 'graphql-tag';

export default gql`
query listFA_TaxInclusionKinds{
    fakturaAssist{
    	listFA_TaxInclusionKinds{
    		id
			name
 		}
 	}
}`;
