import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox } from '../';
import Button from './Button';
import { changeIsAutomaticPrintingBoolean } from 'config/localStorage';
import { useTranslation } from 'react-i18next';

const OptionsButton = (props) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);
  const { errText } = props;
  const [autoPrintBoolean, setAutoPrintBoolean] = useState(localStorage.getItem('autoPrint') === 'true' ? true : false);
  const changeAutoPrintBoolean = (value) => {
    setAutoPrintBoolean(!autoPrintBoolean);
    changeIsAutomaticPrintingBoolean(value);
  };
  return (
    <ButtonBody>
      <ButtonMain>
        <Button width='160px' {...props}/>
        
      </ButtonMain>
      <OptionIcon onClick={() =>setOpen(!open)}/>
      {open && <OptionBlock>
        <Checkbox value={autoPrintBoolean} title={t('actions.automaticPrinting')} margin='20px 0px 0px 20px' onClick={changeAutoPrintBoolean}/>
      </OptionBlock>
      }

      <ErrText>
        {errText ? errText : null}
      </ErrText>
    </ButtonBody>
  );
};

OptionsButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func,
  width: PropTypes.string,
};

OptionsButton.defaultProps = {
  value: 'button',
};

export default OptionsButton;

const ErrText = styled.div`
  color: #c13c44;
  float: left;
  /* font-family: Roboto-Medium; */
  font-size: 14px;
  /* font-weight: normal; */
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const OptionIcon = styled.div`
  border-radius: 4px;
  background-color: #3cb950;
  margin: 0 0 0 5px;
  cursor: pointer;
  :after {
    content: "";
    position: absolute;
    top: 55%;
    right: 10px;
    transform: translate(0px, -50%);
    border-style: solid;
    border-width: 8px 6px 0px;
    border-color: #ffffff transparent transparent;
  }
  :hover {
    :after {

    top: 58%;
    zoom:1.6;
    right: 5px;}
  }

`;

const ButtonMain = styled.div`

  display: inline-block;
  border-radius: 4px;
  float: left;
  background: #62B55D;
  width: 100%;
`;

const OptionBlock = styled.div`
width: 267px;
height: 60px;
box-shadow: 0 2px 4px 0 rgba(90, 90, 90, 0.2);
background-color: #ffffff;
position: absolute;
margin-top: 36px;
margin-right: 0px;
top: 0;
right: 0;
z-index: 22;

`;

const ButtonBody = styled.div`
  position: relative;
`;
