import gql from 'graphql-tag';

export default gql`
mutation updateFA_CustomerResellerPrices($id: ID!, $fields: CustomerResellerPricesFields!){
    fakturaAssist{
    updateFA_CustomerResellerPrices(id: $id, fields: $fields){
		customerId
		productId
		price
		note
		id
		productNm
		description
    }
    
  }
}`;
