import React, { useMemo, useCallback } from 'react';
import { Input } from 'components/elements';
import { Template } from 'devextreme-react/core/template';
import { SearchPanel } from 'devextreme-react/data-grid';

import { useTranslation } from 'react-i18next';

export default ({ withSearch, excelExport, ref }) => {
  const [t] = useTranslation();

  const templates = [];
  const renderActions = useMemo(() => !!excelExport, [excelExport]);

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: 'before', // left side
      template: 'search', // равняется Template name='search'
    });
    e.toolbarOptions.items.push({
      location: 'after', // right side
      template: 'actions',
    });
  };

  const searchRender = useCallback(() => (
    <SearchPanel
      visible={true}
      highlightSearchText={false}
    />
  ), [ref]);

  templates.push(<Template key='search' name='search' render={searchRender} />);

  return {
    onToolbarPreparing,
    templates,
  };
};
