import gql from 'graphql-tag';

export default gql`
mutation updateFA_DefaultForms($id: Int!, $fields: fieldsDefaultForms!){
    fakturaAssist{
    updateFA_DefaultForms(id: $id, fields: $fields){
		id
    }
    
  }
}`;
