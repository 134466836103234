import React from 'react';
import { Column } from 'devextreme-react/data-grid';
import styled from 'styled-components';

import { CustomizeText } from './customizeText';
import { Gruping } from './gruping';
import { FooterSum, FooterUI } from './footerSum';
import { ADD_BTN_CLS_NAME } from '../DxDataTable';

// import { ReactComponent as InfoSVG } from 'style/img/list/info.svg';
// import { ReactComponent as DeleteImgSVG } from 'style/img/actions/delete.svg';
import { ReactComponent as IconAdd } from 'style/img/addWhite.svg';

export default ({ tableData: columns, groupBy, withDefaultFunction, selectedRowKeys, onAddRecord, width, locales }) => {
  const cols = [];
  const footerColsArr = [];
  let customSummary;

  columns && columns.forEach((item, i) => {
    const { localFormat, ...col } = { ...item };
    // localFormat - для форматирование в нужный тип

    const formatCol = CustomizeText(col, localFormat);

    // группировка по значению groupBy=['dataField']
    const groupIndex = Gruping(groupBy, formatCol);

    const headerCellRender = (typeof onAddRecord === 'function' && i === columns.length - 1 && onAddRecord)
      ? ({ column }) => (
        <ColAdd>
          {column.caption}
          <IconAdd className={ADD_BTN_CLS_NAME} /> {/* Click handler is defined in DataGrid prop 'onCellPrepared' */}
        </ColAdd>)
      : null;

    const colProps = {
      ...(groupIndex === 0 || groupIndex > 0) && { groupIndex },
      ...headerCellRender && { headerCellRender },
      ...formatCol,
    };

    const renderFunction = (data) => {
      return (
        <span>
          {data.data[formatCol.dataBase]}{+data.key === selectedRowKeys && withDefaultFunction
            // ? <StyledDot onClick={() => console.log('dot')}>...</StyledDot>
            ? null
            : null}

        </span>
      );
    };
    // построение колонок headerCellComponent={(data, ref) => <CustomFilter data={data} ref={ref} />}  headerCellRender={renderTitleHeader}
    if(columns.length - 1 === i) {
      cols.push(<StyledColumn width={`${100 * formatCol.relativeWidth}%`} key={`${formatCol.dataBase}-${i}`} dataField={formatCol.dataBase}
        caption={formatCol.name} {...colProps} cellRender={renderFunction}/>);
    } else {
      cols.push(<Column key={`${formatCol.dataBase}-${i}`} width={`${100 * formatCol.relativeWidth}%`} dataField={formatCol.dataBase}
        caption={formatCol.name} {...colProps}/>);
    }

    const { calculateCustomSummary } = formatCol?.summary || {};

    // кастомная калькуляция суммы
    if(calculateCustomSummary) {
      customSummary = calculateCustomSummary;
    }
    // вывод суммы
    footerColsArr.push(FooterSum(formatCol, colProps, i));
  });

  // UI для футера с суммой
  const footerCols = FooterUI(footerColsArr, customSummary);

  return {
    cols,
    footerCols,
  };
};

// const StyledDot = styled.div`
//   position: absolute;
//   color: black;
//   font-weight: 900;
//   top: 5px;
//   right: 6px;
//   cursor: pointer;
// `;

const StyledColumn = styled(Column)`
  max-width: 100% - 10px!important;
  color: red!important;
`;

export const ColAdd = styled.div`
    display: flex;
    align-items: right;
    svg {
        right: 6px;
        position: absolute;
        width: 20px;
        min-width: 20px;
        max-width: 20px;
        margin-left: 60px;
    }
`;
