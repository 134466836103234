import React from 'react';
import { Calendar } from '..';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const DateRange = (props) =>{
  const { values, startField, endField, t, onChange, withoutFormik, withoutMargin, title } = props;
  return (
    <>
      <Wrapper>
        <Calendar
          field={startField}
          selectsStart
          value={values[startField] ? new Date(values[startField]) : null}
          startDate = {new Date(values[startField])}
          endDate={new Date(values[endField])}
          title={title?.from || t('common.start')}
          withoutFormik={withoutFormik}
          onChange={(date) => onChange(startField, date)}
        />
      </Wrapper>
      <Wrapper withoutMargin={withoutMargin}>
        <Calendar
          field={endField}
          selectsEnd
          value={values[endField] ? new Date(values[endField]) : null}
          withoutFormik={withoutFormik}
          startDate = {new Date(values[startField])}
          endDate={new Date(values[endField])}
          title={title?.to || t('common.end')}
          onChange={(date) => onChange(endField, date)}
        />
      </Wrapper>
    </>);
};

export default DateRange;

//WORK ONLY WITH FORMIK NOW!

DateRange.propTypes = {
  values: PropTypes.object.isRequired, //values from Formik
  startField: PropTypes.string.isRequired, // start field name (same as Formik)
  endField: PropTypes.string.isRequired, // start field name (same as Formik)
  t: PropTypes.func.isRequired, //i18 func
  withoutMargin: PropTypes.bool,
};

const Wrapper = styled.div`
  width: 140px;
  margin-right: ${({ withoutMargin }) => withoutMargin ? '0' : '20px'}; 
`;

/*
  Example
    <DateRange s
      startField='date'
      endField='secondDate'
      values={values}
    />
*/
