import { StyledVirtualized } from 'components/elements/Dropdown/Dropdown';
// import Option from 'components/elements/Dropdown/components/Option';
import MenuList from 'components/elements/Dropdown/components/MenuList';
import React, { Fragment, useMemo } from 'react';
import { compose } from 'recompose';
import withQuery from '../../elements/hoc/withQuery';
import { useTranslation } from 'react-i18next';

const customStyles = (row, width, topDrop) => ({
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    border: 'none',
    boxShadow: '0 0px 11px rgba(90,90,90,0.12)',
    height: '36px',
    background: row % 2 ? '#eeeeee' : '#fff',

  }),
  option: (provided, state) => ({
    ...provided,
    // background: state.isFocused ? '#EEEEEE' : '#ffffff',
    background: state.isSelected ? '#f5f5f5' : '#ffffff',
    color: '#3c445a',
    fontWeight: state.isSelected ? 600 : 'initial',
    borderRadius: '3px',
    boxShadow: '0 1px 2px 0 rgba(90, 90, 90, 0.12)',
    borderBottom: '1px solid #f4f4f4',
    margin: '2px 0px 2px 2px',
    width: '98%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    borderLeft: '1px solid #ececec',
    borderRight: '1px solid #ececec',
  }),
  menuList: (provided, state) => ({
    ...provided,
    position: 'fixed',
    height: '160px',
    width: width * 2 + 'px',
    background: '#FAFAFA',
    boxShadow: '0px 0px 11px rgba(0, 0, 54, 0.25)',
    borderRadius: '3px',
    overflowX: 'hidden',
  }),
  menu: (provided, state) => ({
    ...provided,
    top: `${topDrop ? '-160px' : 'initial'}`,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    zIndex: 10,
    background: '#eeeeee',
    position: 'fixed',
    height: '36px',
    width: width - 2 + 'px',
    overflow: 'hidden',
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    background: '#eeeeee',
    position: 'fixed',
    height: '36px',
    width: width + 'px',
    border: '1px solid #434343',
  }),
});

const Dropdown = (props) => {

  const [t] = useTranslation();
  const { labelField, valueField, onChange, row, defaultValueField, loading, data, topDrop, width, sortBy } = props;
  const sortedData = sortBy ? data.sort((a, b) => {
    const isB = b[sortBy], isA = a[sortBy];
    if (isB && isA && a[sortBy][0] === '*' && b[sortBy][0] === '*') return 0;
    if (isB && b[sortBy][0] === '*') return -1;
    if (isA && a[sortBy][0] === '*') return 1;
    if (isA && isB && a[sortBy] > b[sortBy]) return 1;
    if (isA && isB && a[sortBy] < b[sortBy]) return -1;
    else return 0;
  }) : data;
  const dropdDownSyntax = sortedData.map((item) => {
    return { value: valueField ? item[valueField] : item.id, label: labelField ? Array.isArray(labelField) ? labelField.map((e) => item[e] || '-').join(' ') : item[labelField] || '-' : item.name || '-', item: item };
  }) || [];
  // console.log(sortedData)
  // console.log(defaultValueField, dropdDownSyntax)
  const defaultItem = useMemo(() => dropdDownSyntax.find((i) => i.value === defaultValueField), [defaultValueField, dropdDownSyntax]);

  // THIS IS MAY BE USEFUL IN FUTURE, BUT I AM NOT SURE...
  // const loadOptions = async (searchStr) => {
  //   const isSearchNotEquals = searchStr !== searchText;
  //   if (isSearchNotEquals) {
  //     setLocalOffset(0);
  //     setSearchText(searchStr);
  //   }
  //   if (props.fetchMore && props.graphqlParams) {
  //     let { variables, query } = props.graphqlParams; // eslint-disable-line
  //     const queryName = query.definitions[0].name.value;
  //     if (variables.args) {
  //       variables.args.date = variables.args.date || moment().format('YYYY-MM-DD');
  //     }
  //     const fetchMoreResult = await props.fetchMore({
  //       variables: {
  //         ...variables,
  //         params: {
  //           ...variables.params,
  //           offset: isSearchNotEquals ? 0 : localOffset,
  //           search: {
  //             ...variables.params.search,
  //             string: searchStr || '',
  //           },
  //         },
  //       },
  //       updateQuery: (previousResult, { fetchMoreResult }) => {
  //         if (!fetchMoreResult.fakturaAssist[queryName].length) {
  //           return previousResult;
  //         }
  //         const result = Object.assign({}, previousResult, {
  //           fakturaAssist: {
  //             [queryName]: [
  //               ...previousResult.fakturaAssist[queryName],
  //               ...fetchMoreResult.fakturaAssist[queryName],
  //             ],
  //             __typename: 'FA_Query',
  //           },
  //         });
  //         return result;
  //       },
  //     });
  //     const fetchMoreData = fetchMoreResult.data.fakturaAssist[queryName];
  //     const newOptions = fetchMoreData.length ? fetchMoreData.map((item) => {
  //       return { value: valueField ? item[valueField] : item.id, label: labelField ? Array.isArray(labelField) ? labelField.map((e) => item[e] || '-').join(' ') : item[labelField] || '-' : item.name || '-', item: item };
  //     }) : [];
  //     // console.log('newOptions', newOptions)
  //     if (!fetchMoreResult.data.fakturaAssist[queryName].length) {
  //       return {
  //         options: newOptions,
  //         hasMore: false,
  //       };
  //     }
  //     setLocalOffset((isSearchNotEquals ? 0 : localOffset) + 100);
  //     return {
  //       options: newOptions,
  //       hasMore: true,
  //     };
  //   }
  //   return {
  //     options: dropdDownSyntax,
  //     hasMore: false,
  //   };
  // };

  return (
    <Fragment>
      <StyledVirtualized
        options={dropdDownSyntax}
        styles={customStyles(row, width, topDrop)}
        onChange={({ item }) => {
          onChange(item);
        }}
        // arrowRenderer={<div> OK</div>}
        isLoading={loading}
        value={defaultItem ? defaultItem : {}}
        {...props}
        components={{ MenuList }}
        menuIsOpen
        // debounceTimeout={debounceTimeout}
        // menuShouldScrollIntoView={true}
        // filterOption={null}
        optionHeight={37}
        isClearable={false}
        noOptionsMessage={() => t('common.noOptionsFound')}
      />
    </Fragment>
  );
};

export default compose(withQuery)(Dropdown);
