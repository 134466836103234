import get from 'lodash/get';
import produce from 'immer';

export const transformData = (data = {}, options = {}) => {
  const { queryFieldExtract } = options;
  const appFieldList = ['cashAssist', 'fakturaAssist', 'rezeptAssist', 'reports'];
  const queryFields = Object.keys(data);
  let applicationField = null;
  const hasAppField = queryFields.length && Object.keys(data).every((field) => {
    const isAppField = appFieldList.includes(field);
    if(isAppField) {
      applicationField = field;
    }
    return field === '__typename' || isAppField;
  });
  const queryField = hasAppField ? Object.keys(data?.[applicationField] || {})[0] : queryFields[0];

  let outData = (hasAppField ? get(data, applicationField) : data) || {};
  outData = queryFieldExtract ? get(outData, queryField) : outData;
  return {
    data: outData,
    appField: hasAppField ? applicationField : null,
    queryField: queryField,
    appTypename: hasAppField ? data?.[applicationField]?.__typename : null,
  };
};

export const updateQuery = ({ query, variables }, updater) => (cache, { data }) => {
  const oldData = cache.readQuery({
    query,
    variables,
  });
  const { data: transformedOldData, queryField, ...dataInfo } = transformData(oldData);
  const cachedQuery = get(transformedOldData, queryField, {});
  const { data: mutationData, queryField: mutationField } = transformData(data);
  const mutationResult = get(mutationData, mutationField, {});
  const newData = produce(oldData, (draft) => {
    const draftWithNoAppField = dataInfo.appField ? get(draft, dataInfo.appField) : draft;
    updater(draftWithNoAppField, {
      mutationResult,
      mutationField,
      readQuery: cachedQuery,
      queryField,
      cache,
      ...dataInfo,
    });
  });
  cache.writeQuery({
    query,
    variables,
    data: newData,
  });
};

export const updateQueryHandle = ({ query, variables }, updater) => (cache, { data }) => {
  const oldData = cache.readQuery({
    query,
    variables,
  });
  const { data: transformedOldData, queryField, ...dataInfo } = transformData(oldData);
  const cachedQuery = get(transformedOldData, queryField, {});
  const { data: mutationData, queryField: mutationField } = transformData(data);
  const mutationResult = get(mutationData, mutationField, {});
  const newData = produce(oldData, (draft) => {
    const draftWithNoAppField = dataInfo.appField ? get(draft, dataInfo.appField) : draft;
    updater(draftWithNoAppField, {
      mutationResult,
      mutationField,
      readQuery: cachedQuery,
      queryField,
      cache,
      ...dataInfo,
    });
  });
  cache.writeQuery({
    query,
    variables,
    data: newData,
  });
};

