import gql from 'graphql-tag';

export default gql`
mutation changeDefaultOrderModal($fields: [DefaultOrderModalFields]){
    fakturaAssist{
    changeDefaultOrderModal(fields: $fields){
		note
    }
    
  }
}`;
