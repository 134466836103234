import gql from 'graphql-tag';

export default gql`
query listFA_OverviewProductAllProductNumbers($args: FA_OverviewProductAllProductNumbersInputs!, $params: ListQueryArgs){
    fakturaAssist{
    	listFA_OverviewProductAllProductNumbers(args: $args, params: $params){
    		items{
		        id
		        productNr
		        total
		        name
		      }
	        totalSum
 		}
 	}
}`;
