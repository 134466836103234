/*eslint-disable */
import React, { useState } from 'react';

import moment from 'moment';
import { Modal } from 'components/elements';
import { useMutation } from 'components/graphql';
import { cloneDeep } from 'lodash';
import TableGrid from 'components/tableGrid/TableGrid.js';

import { getFA_ProductDeliveryPeriod, updateFA_ProductDeliveryPeriod, deleteFA_ProductDeliveryPeriod, createFA_ProductDeliveryPeriod } from '../../gql/index';


import ModalOfPeriod from './ModalOfPeriod';
import { useTranslation } from 'react-i18next';

export default (props) =>{
console.log(props);
  const [t] = useTranslation();
  // const { data, loading, client } = useQuery(listCompareFa_Assortment, { variables: { id: +props.linkId } });
  // const [MUTATION_changeCompareFa_Assortment] = useMutation(changeCompareFa_Assortment);

  const week = {
    isMonday: t('common.shortDays.mon'),
    isTuesday: t('common.shortDays.tue'),
    isWednesday: t('common.shortDays.wed'),
    isThursday: t('common.shortDays.thu'),
    isFriday: t('common.shortDays.fri'),
    isSaturday: t('common.shortDays.sat'),
    isSunday: t('common.shortDays.sun')
  }
  const infoDays = (info, isCustomWeekDaySetting, customInfo) =>{
    let textDays = '';
    if(isCustomWeekDaySetting){
      for(const key in week){
      if(customInfo[key]){
        textDays+=week[key]+', '
      }
    }
    }
    else{
      for(const key in week){
      if(info[key]){
        textDays+=week[key]+', '
      }
    }
    }
    return(textDays.slice(0,-2).length>32?t('common.days.allDays'):textDays.slice(0,-2))
  }

  const [info, setInfo] = useState(null);
  const [id, setId] = useState(null);
  const [index, setIndex] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const [MUTATION_updateFA_DeliveryPeriods] = useMutation(updateFA_ProductDeliveryPeriod);
  const [MUTATION_createFA_DeliveryPeriods] = useMutation(createFA_ProductDeliveryPeriod);

  const tableData = [{
    name: t('common.days.days'),
    dataBase: 'id',
    relativeWidth: 0.2,
    specialComponent: (index, columnIndex, value, data) => {
      return (
        <div>
          {infoDays(props.data, data[index].isCustomWeekDaySetting, data[index])}
        </div>
      );
    },
  },
  {
    name: t('common.fromDate'),
    dataBase: 'fromDate',
    relativeWidth: 0.15,
    dataType: 'date',
    isRequired: true,
  },
  {
    name: t('common.toDate'),
    dataBase: 'toDate',
    relativeWidth: 0.15,
    dataType: 'date',
    isRequired: true,
  },
  {
    name: t('common.note'),
    dataBase: 'name',
    relativeWidth: 0.15,
  },
  {
    name: t('customer.customWeekDays'),
    dataBase: 'isCustomWeekDaySetting',
    relativeWidth: 0.15,
  },
  {
    name: t('customer.repeatYearly'),
    dataBase: 'isRepeatYearly',
    relativeWidth: 0.2,
  }];
  const addNewItem = () =>{
    setOpen(true);
  };

  const editItem = (index, data) => {
    setOpen(true);
    setInfo(data[index]);
    setId(data[index].id);
    setIndex(index);
  };

  const onDelete = (a, b, item) =>{
    console.log(item);
  };

  const onClose = () => {
    setOpen(false);
  }

  const save = (formikDays, formikInfo, id) =>{
    const validationCustomWeekDay = () =>{
      if(
        formikDays.isMonday != props.data.isMonday ||
        formikDays.isTuesday != props.data.isTuesday ||
        formikDays.isWednesday != props.data.isWednesday ||
        formikDays.isThursday != props.data.isThursday ||
        formikDays.isFriday != props.data.isFriday ||
        formikDays.isSaturday != props.data.isSaturday ||
        formikDays.isSunday != props.data.isSunday
      ) {
        return true;
      } else{
        return false;
      }
    };

    const newItem = {
      id: id,
      isMonday: formikDays.isMonday,
      isTuesday: formikDays.isTuesday,
      isWednesday: formikDays.isWednesday,
      isThursday: formikDays.isThursday,
      isFriday: formikDays.isFriday,
      isSaturday: formikDays.isSaturday,
      isSunday: formikDays.isSunday,
      productLink: +props.id,
      name: formikInfo.name,
      fromDate: formikInfo.fromDate,
      toDate: formikInfo.toDate,
      isCustomWeekDaySetting: validationCustomWeekDay(),
      isRepeatYearly: formikInfo.isRepeatYearly,
      isVacation: false,
      __typename: 'FA_ProductDeliveryPeriod',
    };
    const cache = props.client.readQuery({
      query: getFA_ProductDeliveryPeriod,
      variables: {
        productLink: +props.id,
        isVacation: false
      },
    }).fakturaAssist.getFA_ProductDeliveryPeriod;
    if(id) {
      //edit
      const copyCache = cloneDeep(cache);

      copyCache.splice(index, 1, newItem);

      props.client.writeQuery({
        query: getFA_ProductDeliveryPeriod,
        variables: {
            productLink: +props.id,
          isVacation: false,
        },
        data: {
          fakturaAssist:
                              {
                                getFA_ProductDeliveryPeriod: copyCache,
                                __typename: 'FA_Query',
                              },

        },
      });

      MUTATION_updateFA_DeliveryPeriods({ variables: {
        id: id,
        fields: {
          isMonday: formikDays.isMonday,
          isTuesday: formikDays.isTuesday,
          isWednesday: formikDays.isWednesday,
          isThursday: formikDays.isThursday,
          isFriday: formikDays.isFriday,
          isSaturday: formikDays.isSaturday,
          isSunday: formikDays.isSunday,
          productLink: +props.id,
          name: formikInfo.name,
          fromDate: formikInfo.fromDate,
          toDate: formikInfo.toDate,
          isCustomWeekDaySetting: validationCustomWeekDay(),
          isVacation: false,
          isRepeatYearly: formikInfo.isRepeatYearly,
        },
      } });
    } else{
      //create


      MUTATION_createFA_DeliveryPeriods({ variables: {
        fields: {
          isMonday: formikDays.isMonday,
          isTuesday: formikDays.isTuesday,
          isWednesday: formikDays.isWednesday,
          isThursday: formikDays.isThursday,
          isFriday: formikDays.isFriday,
          isSaturday: formikDays.isSaturday,
          isSunday: formikDays.isSunday,
          productLink: +props.id,
          name: formikInfo.name,
          fromDate: formikInfo.fromDate,
          toDate: formikInfo.toDate,
          isCustomWeekDaySetting: validationCustomWeekDay(),
          isVacation: false,
          isRepeatYearly: formikInfo.isRepeatYearly,
        },
      } }).then((db)=>{
        const cloneCacheData = cloneDeep(cache);

      cloneCacheData.unshift({
            id: db.data.fakturaAssist.createFA_ProductDeliveryPeriod.id,
            isMonday: formikDays.isMonday,
            isTuesday: formikDays.isTuesday,
            isWednesday: formikDays.isWednesday,
            isThursday: formikDays.isThursday,
            isFriday: formikDays.isFriday,
            isSaturday: formikDays.isSaturday,
            isSunday: formikDays.isSunday,
            productLink: +props.id,
            name: formikInfo.name,
            fromDate: formikInfo.fromDate,
            toDate: formikInfo.toDate,
            isCustomWeekDaySetting: validationCustomWeekDay(),
            isRepeatYearly: formikInfo.isRepeatYearly,
            isVacation: false,
            __typename: 'FA_ProductDeliveryPeriod',

          });

        props.client.writeQuery({
          query: getFA_ProductDeliveryPeriod,
          variables: {
            productLink: +props.id,
            isVacation: false
          },
          data: {
            fakturaAssist:
              {
                getFA_ProductDeliveryPeriod: cloneCacheData,
                __typename: 'FA_Query',
              },
          },
        });
      }).catch((err)=>{
        console.log(err);
      });
    }
    setInfo(null);
    setId(null);
    setIndex(null);
    setOpen(false);
  };

  const defInfo = {
    productLink: +props.id,
    name: '',
    fromDate: null,
    toDate: null,
    isCustomWeekDaySetting: true,
    isRepeatYearly: false,
    isMonday: props.data.deliveryMonday,
    isTuesday: props.data.deliveryTuesday,
    isWednesday: props.data.deliveryWednesday,
    isThursday: props.data.deliveryThursday,
    isFriday: props.data.deliveryFriday,
    isSaturday: props.data.deliverySaturday,
    isSunday: props.data.deliverySunday,
  };

  return (
    <div>
      <Modal
        headerText={t('common.editPeriod')}
        width={'550px'}
        height={'490px'}
        isOpen={isOpen}
        closeModal={onClose}
        Component={<ModalOfPeriod {...props} setInfo={setInfo} setId={setId} setIndex={setIndex} id={id} productLink={props.id} save={save} info={info === null ? defInfo : info} setOpen={setOpen} tableData={tableData} />}
      />
      <TableGrid
        withSearch
        itemDeleted={deleteFA_ProductDeliveryPeriod}
        graphqlParams={{
          query: getFA_ProductDeliveryPeriod,
          variables: { productLink: +props.id,
            isVacation: false },
        }}
        addNewItem = {addNewItem}
        editItem={editItem}
        //onDelete = {onDelete}
        //searchValue={this.state.searchValue}
        //onSearch={this.onSearch}
        //getSortField={this.getSortField}
        height={700}
        tableData={tableData}
        dataPolicy='local'
        initialSortValue='productLink'
        //getTableIndex={this.indexOnClickTable}
      />
    </div>
  );
};


