import gql from 'graphql-tag';

const GET_FA_ORDER_FILTER = gql`
  query getFA_OrderFilter {
      fakturaAssist {
          getFA_Config {
              orderGroup
          }
      }
  }
`;

export default GET_FA_ORDER_FILTER;
