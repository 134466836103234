
import { Input } from 'components/elements';
import React from 'react';

export default (props) =>{
  const { values, index, columnIndex, type, name, value, onChange, color, days } = props;
  const onKeyDown = (e) =>{
    if(e.keyCode === 13) {
      document.getElementById(`${index + 1}-${columnIndex}-${values[index + 1].id}`) && document.getElementById(`${index + 1}-${columnIndex}-${values[index + 1].id}`).focus();
    }
  };

  const validations = (e) =>{
    if(e.target.value.length > 1 && e.target.value[e.target.value.length - 1] === '%' && ((e.target.value.indexOf('%') > 0) && (e.target.value.slice(e.target.value.indexOf('%') + 1, e.target.value.length).indexOf('%') < 0))) {
      onChange(e.target.name, e.target.value || null);
      //%
    } else if(e.target.value === 'r') {
      onChange(e.target.name, 'r');
    } else if(
      ((Number(e.target.value) || Number(e.target.value) === 0) && e.target.value[e.target.value.length - 1] !== 'e')
      || (!/[a-z|A-Z]/.test(e.target.value)
        && (e.target.value.indexOf(',') >= 0)
        && (e.target.value.slice(e.target.value.indexOf(',') + 1, e.target.value.length).indexOf(',') < 0)
      )
    ) {
      onChange(e.target.name, e.target.value === 0 ? '0' : e.target.value);
      
      //,
    } else if(e.target.value.length > 1) {
      onChange(e.target.name, e.target.value.slice(0, -1) || null);
    } else{
      onChange(e.target.name, '');
    }
  };
  return (
    <div>
      <Input

        textAlignRight='right'
        key={values[index] && `${index}-${columnIndex}-${values[index].id}`}
        onKeyDown = {(event)=>{
          onKeyDown(event);
        }}
        id = {values[index] && `${index}-${columnIndex}-${values[index].id}`}
        style={{ marginTop: '-5px' }}
        color= {color}
        onBlur={(e)=>{
          if(index === 0 && values[2][days[columnIndex - 1]] && (values[2][days[columnIndex - 1]] < e.target.value)) {
            //max
            onChange(e.target.name, values[2][days[(columnIndex - 1)]] || null);
          } else if(index === 0 && e.target.value < values[1][days[columnIndex - 1]] && (e.target.value < values[1][days[columnIndex - 1]])) {
            //min
            onChange(e.target.name, values[1][days[(columnIndex - 1)]] || null);
          }
        }}
        noBorder
        withOpacity
        width='100%'
        type={type}
        withoutFormik={true}
        onChange={
          (e)=>{
            validations(e);
          }}
        value={value}
        name={name}/>
    </div>
  );
};


