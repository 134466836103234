import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import Formik from '../../../../../components/formik';
//import TableGrid from '../../../../../components/container/tableGrid/TableGrid.js';

import listFA_ConfigCustomerPriceCategories from '../../qql/query/listFA_ConfigCustomerPriceCategories';
import listFA_CustomerOrderReportID from '../../qql/query/listFA_CustomerOrderReportID';

//import createFA_Discount from './qql/mutation/createFA_Discount';

import { Input, Dropdown, Textarea, ReportSystemInput } from '../../../../../components/elements';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  // const Component = (props)=>{
  //   const { handleChange, values } = props;
  //   return(
  //     <div>
  //       <Input
  //         width='100%'
  //         withoutFormik={true}
  //         type='text'
  //         onChange={handleChange}
  //         value={values.name}
  //         name={'name'}
  //         title={'Name'}
  //       />
  //     </div>
      
  //   );
  // };

  return (
    <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <HeadBlock>
            <Row>
              <Col width='330px'>
                <ReportSystemInput
                  title={t('common.form')}
                  //avaliableReportForms={[17,52]}
                  defaultValueField = {values.orderReportID}
                  graphqlParams={{
                    query: listFA_CustomerOrderReportID,
                    variables: {
                      languageId: +localStorage.getItem('lngId') || 1,
                    },
                  }}
                  withoutIdAvaliableReportDefault={[45, 46]}
                  avaliableReportGroup={[5]}
                  field='orderReportID'
                  typeName={'FA_CustomerInvoiceSummaryReportID'}
                />
              </Col>
              <Col lg={2}>
                <Input small value={values.deliveryNoteCopies} field='deliveryNoteCopies' type='number' title={t('common.copies')}/>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Dropdown
                  
                  graphqlParams={{
                    query: listFA_ConfigCustomerPriceCategories }}
                  title={t('common.groupDeliveryNote')}
                  valueField = 'id'
                  labelField = 'name'
                  field = 'linkLSGroupe'
                  defaultValueField = {values.linkLSGroupe}
                />
              </Col>
            </Row>
            <Row>
              <Col width='100%'>
                <Textarea
                  title={t('customer.delivery.individualComments')}
                  width='95%'
                  height='300px'
                  value={values.memoDeliveryNote}
                  onBlur={handleSubmit}
                  label={t('customer.delivery.individualComments')}
                  field='memoDeliveryNote'
                />
              </Col>
            </Row>
            <Row>
              <Col width='100%'>
                <Textarea
                  title={t('customer.delivery.additionalComments')}
                  value={values.memoDeliveryNote2}
                  onBlur={handleSubmit}
                  label={t('customer.delivery.additionalComments')}
                  width='95%'
                  height='300px'
                  field='memoDeliveryNote2'
                />
              </Col>
            </Row>
            
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  height: 900px;
  padding: 0px 20px 20px 20px;
`;
