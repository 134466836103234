
import React from 'react';
import styled from 'styled-components';

import TableGrid from 'components/tableGrid/TableGrid.js';
import { TextAreaStandart } from 'components/elements';

import { tableDataCRM } from './params';

import deleteFA_CustomerCRMNotes from './qql/mutation/deleteFA_CustomerCRMNotes';
import createFA_CustomerCRMNotes from './qql/mutation/createFA_CustomerCRMNotes';
import updateFA_CustomerCRMNotes from './qql/mutation/updateFA_CustomerCRMNotes';

import listFA_CustomerCRMNotes from './qql/query/listFA_CustomerCRMNotes';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const Component = (props)=>{
    return(
      <div>
        <TextAreaStandart
          withoutFormik={true}
          type='text'
          onChange={props.handleChange}
          value={props.values.note}
          name={'note'}
          title={t('common.note')}
          width='100%'
        /></div>
    );
  };
  return (
    <HeadBlock>
      <TableGrid
        graphqlParams={{
          query: listFA_CustomerCRMNotes,
          variables: { id: +props.linkId } }}
        //searchValue={this.state.searchValue}
        //onSearch={this.onSearch}
        //getSortField={this.getSortField}
        Component={Component}
        height={700}
        headerText={'CRM'}
        modalWidth={440}
        modalHeight={275}
        //editItem={this.openModal}
        tableData={tableDataCRM(t)}
        //getTableIndex={this.indexOnClickTable}
        itemDeleted={deleteFA_CustomerCRMNotes}
        defaultValuesForCache={['addedOn']}
        defaultValuesForMutation = {{ customerLink: +props.linkId }}
        typeName={'FA_CustomerCRMNotes'}
        qqlMutationCreate={createFA_CustomerCRMNotes}
        qqlMutationUpdate={updateFA_CustomerCRMNotes}
        withModalMutations
      />
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  padding: 20px 20px 20px 20px;
  background: #f4f4f4;
`;
