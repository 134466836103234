import company from './company/config';

//Not needed in this moment
//import forms from './forms/config';

import configurations from './configurations/config';
import connections from './connections/config';
import printService from './printService/config';
import importConfig from './import/config';
import exportConfig from './export/config';
import automation from './automation/config';
import history from './history/config';
import securityManager from './securityManager/config';
import settings from './settings/config';

export default [
  company,
  configurations,
  connections,
  importConfig,
  exportConfig,
  automation,
  history,
  securityManager,
  settings,
  printService,
];
