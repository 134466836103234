import gql from 'graphql-tag';

export default gql`
query listFA_CustomerAddressKinds($languageId: Int){
    fakturaAssist{
    listFA_CustomerAddressKinds(languageId: $languageId){
      id
      name
      __typename
    }
  }
}`;
