import gql from 'graphql-tag';

export default gql`
mutation checkFA_FarmyApiToken($args: FA_FarmyApiTokenInputs!){
    fakturaAssist{
		checkFA_FarmyApiToken(args: $args){
            success
	        failed
    }
    
  }
}`;
