import gql from 'graphql-tag';

export default gql`
mutation deleteFA_CustomerListsDefault($id: ID!){
    fakturaAssist{
    deleteFA_CustomerListsDefault(id: $id){
		name
    }
    
  }
}`;
