import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { find } from 'lodash';

import translationEN from './locales/en.json';
import translationDE from './locales/de.json';
import translationFR from './locales/fr.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('lng') ? localStorage.getItem('lng') : 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const avaliableLanguages = [
  {
    id: 'en',
    languageId: 1,
    name: 'English',
  },
  {
    id: 'de',
    languageId: 2,
    name: 'German',
  },
  {
    id: 'fr',
    languageId: 3,
    name: 'French',
  },
];

export const changeLanguage = (newLng) => {
  localStorage.setItem('lng', newLng);
  const newLngId = find(avaliableLanguages, ({ id }) => id === newLng)?.languageId || 1;
  localStorage.setItem('lngId', newLngId);
  i18n.changeLanguage(newLng);
};