import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const stdNumber = (value, min, max) => new Intl.NumberFormat('en-US', { minimumFractionDigits: min, maximumFractionDigits: max }).format(Math.round(value * Math.pow(10, max)) / Math.pow(10, max));

export default (props) => {
  const {
    tableData,
    getColumnWidth,
    width,
    background,
    color,
    tableMainColumn,
    data,
    withOuterTotal,
  } = props;

  const [t] = useTranslation();
  let outerTotalValue = null;
  const totalRender = tableData.map(
    (props, index) => {
      const column = data && data[0] && props.dataBase === tableMainColumn && (data.indexOf(data[index]) !== -1 ?? data.indexOf(data[index]));

      const getTotal = (minimumFractionDigits, maximumFractionDigits) => {
        const totalType = typeof (column);
        switch(totalType) {
          case 'number':
            return stdNumber(column, minimumFractionDigits, maximumFractionDigits);
          case 'string':
            return column;
          case 'boolean':
            const totalSum = data.reduce((acc, val)=> {
              return parseFloat(acc) + (parseFloat(val[props.dataBase]) || 0);
            }, 0);
            return totalSum ? stdNumber(totalSum, minimumFractionDigits, maximumFractionDigits) : 0;
          default:
            return '';
        }
      };

      const totalValue = getTotal(2, 2);

      const renderTotal = (props) => {
        switch (props.dataBase) {
          case 'quantity':
            return `${props?.total?.replace(/(^\d+)(.+$)/i, '$1')} / ${getTotal(0, 3)}`;
          case 'total':
            return totalValue ? stdNumber(totalValue.replace(/,/g, ''), 2, 2) : totalValue;
          default:
            return totalValue ? totalValue : '';
        }
      };

      if (tableMainColumn === 'total') {
        outerTotalValue = totalValue;
      }

      return (
        <NameBlock
          key={index}
          width={getColumnWidth(width, { index: index }) + 'px'}
        >
          <span>{props.withoutTotal ? '' : (props.customTotalCalc ? props.customTotalCalc() : renderTotal(props))}</span>
        </NameBlock>
      );
    }
  );
  return !withOuterTotal
    ?
    <MainTotalBlock color={color} background={background}> {totalRender} </MainTotalBlock>
    : (
      <OuterTotalBlock color={color} background={background}>
        <span>{t('common.total')}</span>
        <span>{outerTotalValue || ''}</span>
      </OuterTotalBlock>
    );
};

const NameBlock = styled.div`
  width: ${(props) => props.width};
  height: 36px;
  float: right;
  position: center;
  text-align: right;
  padding: 10px 10px 0px 7px;
`;

const MainTotalBlock = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ background })=> background || '#3cb950'};
  color: ${({ color })=> color || '#fff'};
`;

const OuterTotalBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  position: absolute;
  height: 40px;
  align-items: center;
  bottom: -60px;
  left: 0;
  background-color: ${({ background })=> background || '#FAFAFA'};
  border: 1px solid #CDCDCD;
  box-sizing: border-box;
  border-radius: 50px;
  & span {
    &:last-child {
      color: ${({ color })=> color || '#62B55D'};
    }
  }
`;

