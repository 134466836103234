import gql from 'graphql-tag';

export default gql`
query listFA_ConfigCustomerPriceCategories{
    fakturaAssist{
      listFA_ConfigCustomerPriceCategories{
		id
		name
    }
    
  }
}`;
