import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { Dropdown, Input } from 'components/elements';
import Formik from 'components/formik';

export default (props) => {
  const { t } = props;
  return (
    <Formik initialValues={props.data} enableReinitialize={true} onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo} >
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <HeadBlock>
            <MainBlock>
              <Row>
                <Col lg={4}>
                  <Dropdown
                    title={t('common.form')}
                    valueField = 'id'
                    labelField = 'group'
                    field = 'linkGroup'
                    defaultValueField = {values.orderReportID}
                    data={[]}
                  />
                </Col>
                <Col lg={2}>
                  <Input value={values.orderCopies} addition field='orderCopies' title={t('common.copies')} type='number' small/>
                </Col>
              </Row>
            </MainBlock>
        
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;
const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  padding: 0px 20px 20px 20px;
  min-height: 70vh;
`;

