import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query listFA_preiskategorie{
    fakturaAssist{
    	listFA_preiskategorie{
	    	ResID
        	ResName
	 	}
 	}
}`;

