import gql from 'graphql-tag';

export default gql`
mutation ($args: NoOrderInputs!){
    fakturaAssist{
    	noOrder(args: $args){
	    	errorCode
	 	}
 	}
}`;
