import React, { useState, useEffect } from 'react';
import { get } from 'lodash';

//When query params need in main componentns
const initialSortOpts = {
  'products': { column: 'productNumber', order: 'ASC', historyFormTypeID: 3 },
  'customers': { column: 'customerNr', order: 'ASC', historyFormTypeID: 2 },
  'order': { orderEdit: { column: 'orderNr', order: 'ASC', historyFormTypeID: 5 }, orderCreate: { column: 'customerNr', order: 'ASC', historyFormTypeID: 5 } },
  'production/edit': { column: 'name', order: 'ASC', historyFormTypeID: 4 },
  'offer': { column: 'customerNr', order: 'ASC', historyFormTypeID: 9 },
  'procurement/suppliers': { column: 'id', order: 'ASC', historyFormTypeID: null },
  'invoice': { column: 'productNr', order: 'ASC', historyFormTypeID: null },
};

const withParams = (WrappedComponent) => (props) => {
  // console.log('withParams', props, props.sectionInfo.sortParams.field)
  const [searchString, changeSearchString] = useState('');
  const [sortObject, setSortObject] = useState(
    {
      column: get(props, 'sectionInfo.sortParams.field', 'id'),
      order: get(props, 'sectionInfo.sortParams.order', 'DESC'),
    }
  );
  // console.log('sortObject', sortObject)

  useEffect(() => {
    let sortOpts = null;
    if (props.mainsection === 'order') {
      sortOpts = initialSortOpts[props.mainsection][props.sectionInfo.name];
    } else if (props.mainsection === 'offer') {
      const filterState = localStorage.getItem(props.mainsection);
      if (filterState) {
        const offerFilterState = JSON.parse(filterState) || [];
        if (offerFilterState.some(({ column }) => ['openOffers', 'acceptedOffers', 'declinedOffers'].includes(column))) {
          sortOpts = {
            ...initialSortOpts[props.mainsection],
            column: 'offerNr',
            order: 'ASC',
          };
        } else {
          sortOpts = initialSortOpts[props.mainsection];
        }
      } else {
        sortOpts = initialSortOpts[props.mainsection];
      }
    } else {
      sortOpts = initialSortOpts[props.mainsection];
    }
    setSortObject({
      column: sortOpts ? sortOpts.column : sortObject.column,
      order: sortOpts ? sortOpts.order : sortObject.order,
    });
  }, [props.mainsection, props.sectionInfo.name]);

  const [offset, setOffset] = useState(0);

  const onSort = (columnName, oldChangeSortParams, order) => {
    if (order) {
      return setSortObject({ column: columnName, order });
    }
    if (sortObject.column === columnName) {
      if (sortObject.order === 'ASC') {
        setSortObject({ column: columnName, order: 'DESC' });
        if (oldChangeSortParams) {
          oldChangeSortParams({ field: columnName, order: 'DESC' });
        }
      } else {
        setSortObject({ column: null, order: null });
        if (oldChangeSortParams) {
          oldChangeSortParams({ field: 'id', order: 'DESC' });
        }
      }
    } else {
      setSortObject({ column: columnName, order: 'ASC' });
      if (oldChangeSortParams) {
        oldChangeSortParams({ field: columnName, order: 'ASC' });
      }
    }
  };

  let params = {
    offset: offset,
    limit: props.limit || Infinity,
  };
  if (sortObject.column) {
    params = {
      ...params,
      sort: {
        field: sortObject.column,
        order: sortObject.order,
      },
    };
  }
  if (searchString) {
    params = {
      ...params,
      search: {
        string: searchString,
        columns: props.sectionInfo ? props.sectionInfo.searchColumns : ['name'],
      },
    };
  }

  const withDefaultParams = props.sectionInfo.withDefaultParams ? { params: params } : {};

  // console.log({ sortObject })

  return (
    <WrappedComponent
      {...props}
      client={props.client}
      searchValue={searchString}
      changeSearchString={changeSearchString}
      onSort={onSort}
      params={params}
      offset={offset}
      setOffset={setOffset}
      sortObject={sortObject}
      initialParams={{ ...props.defaultFilterParams, ...withDefaultParams }}
      historyFormTypeID={initialSortOpts[props.mainsection]?.historyFormTypeID}
    />
  );
};

export default withParams;

