import gql from 'graphql-tag';

export default gql`
query listFA_ProductionGroup{
    fakturaAssist{
    listFA_ProductionGroup{
		id
		name
    }
    
  }
}`;
