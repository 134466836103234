
import gql from 'graphql-tag';

export default gql`
mutation changeCompareFa_Assortment($arrId: [Int], $customerId: Int){
    fakturaAssist{
    changeCompareFa_Assortment(arrId: $arrId, customerId: $customerId){
		name
    }
    
  }
}`;
