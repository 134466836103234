
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

import TableGrid from '../tableGrid/TableGrid';
import { FilterButton } from '../elements/index';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import { branch, compose } from 'recompose';
import withMutationCreate from '../section/hoc/withMutationCreate';

const MainList = (props) => {
  const [t] = useTranslation();
  const {
    data,
    ComponentForCreating,
    minHeight,
    FilterComponent,
    isfilterButton,
    changeFilter,
    changeLink,
    linkId,
    contex,
    mainsection,
    height,
    specialTableIndex,
    fetchMore,
    handleItemCreate,
    operationAvailability,
    openDeleteModal,
  } = props;

  const [width, setWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [recordCounter, setRecordCounter] = useState(0);

  useWindowDimensions();

  useEffect(() => {
    if(!contex.params?.search && data) {
      setRecordCounter(data.length);
    }
  }, [data]);

  useEffect(() => {
    setWidth(document.getElementById('Main_List') && document.getElementById('Main_List').offsetWidth);
    console.log(props);
  }, [document.getElementById('Main_List')]);

  const cutHeight = props.cutHeight || 258;
  const clientHeight = height || document.documentElement.clientHeight - cutHeight;
  // const resetFilter = () => {
  //   writeContex(defaultFilterParams);
  // };

  const transformFilterText = (val, field) => {
    if(mainsection === 'production/edit' && field === 'LinkGruppe') {
      return t('common.filter.printListGroups');
    }

    switch (val) {
      case '0':
        if(mainsection === 'products') {
          return t('common.filter.inactiveProducts');
        } else if(mainsection === 'production/edit') {
          return t('common.filter.inactivePrintList');
        } else {
          return t('common.filter.inactiveCustomers');
        }
      case '1':
        if(mainsection === 'products') {
          return t('common.filter.activeProducts');
        } else if(mainsection === 'production/edit') {
          return t('common.filter.activePrintList');
        } else {
          return t('common.filter.activeCustomers');
        }
      default:
        return t('common.allPrintList');
    }
  };

  return (
    <div style={{ position: 'fixed', width: width + 'px' }}>
      {ComponentForCreating && <ComponentForCreating isOpen={isOpen} setIsOpen={setIsOpen} {...props} />}
      {/*<Row>
         <Col lg={10}>
          <Text
            text={name}
            marginBottom='4px'
            fontSize='18px'
            fontWeight={500}
            color='#434343'
          />
        </Col>
      </Row>*/}

      <Row style={{ padding: 0 }}>
        <FilterInfoBlock>
          {FilterComponent && props.filterData && contex ? (
            <Col lg={2}>
              <FlexBox onClick={changeFilter}>
                <FilterButton
                  onClick={() => {
                    changeFilter();
                    // resetFilter(); - optional
                  }}
                  text={t('common.filter.filter')}
                />
              </FlexBox>
            </Col>
          ) : null}
          <Col>
            <StyledSpan>{transformFilterText(contex?.filter?.[0]?.value, contex?.filter?.[0]?.column,)} ({recordCounter})</StyledSpan>
          </Col>
        </FilterInfoBlock>
      </Row>
      {!isfilterButton ? (
        <div>
          <ShadowBlock>
            <TableGrid
              {...props}
              dataPolicy= {'local'}
              SpecialComponentForAdding={props.SpecialComponentForAdding}
              data={props.data}
              multiSelect
              rowActiveColor={'#434343'}
              minHeight={minHeight ? minHeight : clientHeight}
              height={clientHeight}
              getTableIndex={changeLink}
              selectById
              linkId={+linkId}
              specialTableIndex={specialTableIndex}
              fetchMore={fetchMore}
              role='mainList'
              infiniteLoader
              disableHeightChange
              operationAvailability={operationAvailability}
              operations={operationAvailability && openDeleteModal ? (payload) => {
                return (
                  {
                    delete: () => openDeleteModal(),
                  }
                );
              } : null}
              addNewItem={() => {
                return handleItemCreate ? handleItemCreate() : setIsOpen(true);
              }}
            />
          </ShadowBlock>
        </div>
      ) :
        (
          <FilterBlock height={clientHeight - 10}>{FilterComponent} </FilterBlock>
        )}
    </div>
  );
};

const ShadowBlock = styled.div`
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);

`;

const StyledSpan = styled.span`
  font-weight: 300;
  font-style: italic;
`;

const FilterBlock = styled.div`
  height: ${(props) => props.height + 90}px;
  box-shadow: 0 1px 0 0 rgba(76, 76, 76, 0.08);
  background-color: #eeeeee;
  padding: 15px;
`;

const FilterInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 0px;
`;

export default compose(
  branch(
    (({ createItem }) => !!createItem),
    withMutationCreate
  )
)(MainList);
