import gql from 'graphql-tag';
//Used in information block on top
export default gql`
mutation doFA_ImportOrder($args: FA_OrderImportInputs!){
    fakturaAssist{
    	doFA_ImportOrder(args: $args){
	    	id
            customer
            date
            orderId
			customerId
            productTable{
    			id
     			productLink
     			productLinkLookNr
     			productLinkLookName
     			quantity
     			labelRecipeLink
     			virtualPositionLink
     			orderPositionID
				taxCode
				price
				total
				discount
				discountSpec
				weight
				productNr
				productName
				addition
				fax
				email
				internet
				additive
				indTextProduction
				indTextDeliveryNote
				min
				max
			}
	 	}
 	}
}`;
