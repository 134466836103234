import React, { Fragment, useState } from 'react';
import TableGrid from 'components/tableGrid/TableGrid';
import { cloneDeep } from 'lodash';

import { Input, Checkbox, Calendar, Dropdown, TextAreaStandart } from 'components/elements';
import { useTranslation } from 'react-i18next';
//import copy from 'style/img/copy.png';
import { Row, Col, Table } from 'style/index';

import {
  listFA_CustomerSpecialPricesAndDiscountsProduct,
  deleteFA_SpecialPrices,
  listFA_CustomerSpecialPricesTypes,
  FA_ProductCustomerPriceCustomers,
  updateFA_CustomerSpecialPricesAndDiscountsProduct,
  createFA_SpecialPricesProduct,
} from '../gql/CustomerPrice';
import { TotalCustomerPrice } from '../gql/index';

// import { CustomerPriceParamsEN } from '../helpers/CONST';

const CustomerPrice = (props) => {
  const [t] = useTranslation();

  const [list, setList] = useState([
    {
      name: t('common.fromDate'),
      dataBase: 'fromDate',
      isMutation: true,
      dataType: 'date',
    },
    {
      name: t('common.toDate'),
      dataBase: 'toDate',
      isMutation: true,
      dataType: 'date',
    },
    {
      name: t('common.customerNo'),
      dataBase: 'customerNm',
      dataType: 'drop',
      isRequired: true,
    },
    {
      name: t('common.name'),
      dataBase: 'actualName',
    },
    {
      name: t('common.price'),
      dataBase: 'price',
      isMutation: true,
      dataType: 'float',
      isRequired: true,
    },
    {
      name: t('common.discount'),
      dataBase: 'discount',
      isMutation: true,
      dataType: 'percent',
      isRequired: true,
    },
    {
      name: t('customer.noDiscount'),
      dataBase: 'isDiscountsDisabled',
      isMutation: true,
      type: 'boolean',

    },
    {
      name: t('customer.fromAmount'),
      dataBase: 'fromAmount',
      isMutation: true,
      isRequired: true,
    },
    {
      name: t('common.note'),
      dataBase: 'description',
      isMutation: true,
    },
  ]);

  // const [list, setList] = useState([
  //   {
  //     name: t('common.fromDate'),
  //     dataBase: 'fromDate',
  //     isMutation: true,
  //     dataType: 'date',
  //   },
  //   {
  //     name: t('common.toDate'),
  //     dataBase: 'toDate',
  //     isMutation: true,
  //     dataType: 'date',
  //   },
  //   {
  //     name: t('customer.number'),
  //     dataBase: 'customerNm',
  //     dataType: 'drop',
  //     isRequired: true,
  //   },
  //   {
  //     name: t('common.description'),
  //     dataBase: 'actualName',
  //   },
  //   {
  //     name: t('common.price'),
  //     dataBase: 'price',
  //     isMutation: true,
  //     dataType: 'float',
  //     isRequired: true,
  //   },
  //   {
  //     name: t('common.discount'),
  //     dataBase: 'discount',
  //     isMutation: true,
  //     dataType: 'percent',
  //     isRequired: true,
  //   },
  //   {
  //     name: t('customer.noDiscount'),
  //     dataBase: 'isDiscountsDisabled',
  //     isMutation: true,
  //     type: 'boolean',

  //   },
  //   {
  //     name: t('customer.fromAmount'),
  //     dataBase: 'fromAmount',
  //     isMutation: true,
  //     isRequired: true,
  //   },
  //   {
  //     name: t('common.note'),
  //     dataBase: 'description',
  //     isMutation: true,
  //   },
  // ]);

  const refetchCount = (value) => {
    const data = props.client.readQuery({
      query: TotalCustomerPrice,
      variables: {
        id: +props.id,
      },
    });

    const count = data?.fakturaAssist?.listFA_CustomerSpecialPricesAndDiscountsProductConnection?.total || 0;

    props.client.writeQuery({
      query: TotalCustomerPrice,
      variables: {
        id: +props.id,
      },
      data: {
        fakturaAssist: {
          listFA_CustomerSpecialPricesAndDiscountsProductConnection: {
            total: count + +value,
            __typename: 'FA_CustomerSpecialPricesAndDiscountsConnection',
          },
          __typename: 'FA_Query',
        },
      },
    });
  };

  // const CustomerPriceParams = [
  //   {
  //     name: t('common.fromDate'),
  //     dataBase: 'fromDate',
  //     isMutation: true,
  //     relativeWidth: 0.1,
  //     dataType: 'date',
  //   },
  //   {
  //     name: t('common.toDate'),
  //     dataBase: 'toDate',
  //     isMutation: true,
  //     relativeWidth: 0.1,
  //     dataType: 'date',
  //   },
  //   {
  //     name: t('common.number'),
  //     dataBase: 'customerNm',
  //     isMutation: true,
  //   },
  //   {
  //     name: t('common.name'),
  //     dataBase: 'actualName',
  //     isMutation: true,
  //     relativeWidth: 0.2,
  //   },
  //   {
  //     name: t('common.price'),
  //     dataBase: 'price',
  //     isMutation: true,
  //     dataType: 'float',
  //   },
  //   {
  //     name: t('customer.fromAmount'),
  //     dataBase: 'fromAmount',
  //     isMutation: true,
  //     dataType: 'float',
  //   },
  //   {
  //     name: t('common.discount'),
  //     dataBase: 'discount',
  //     isMutation: true,
  //     dataType: 'percent',
  //   },
  //   {
  //     name: t('common.noDiscountPosible'),
  //     dataBase: 'isDiscountsDisabled',
  //     isMutation: true,
  //     relativeWidth: 0.15,
  //     specialComponent: (index)=> {
  //       const value = get(data, 'fakturaAssist.listFA_CustomerSpecialPricesAndDiscountsProduct', false);
  //       return (
  //         <StyledDiv>
  //           <StyledCheckbox margin={'0px'} value={value[index]?.isDiscountsDisabled || false} inActive/>
  //         </StyledDiv>
  //       );
  //     },
  //   },
  //   {
  //     name: t('common.note'),
  //     dataBase: 'description',
  //     isMutation: true,
  //     relativeWidth: 0.15,
  //   },
  //   // {
  //   //   dataBase: 'specialPriceTypeId',
  //   //   isMutation: true,
  //   // },
  // ];

  // const ModalInfo = (props) => {
  //   const { values, setFieldValue, dataRow } = props;
  //   useEffect(() => {
  //     setFieldValue('specialPriceTypeId', dataRow?.specialPriceTypeId);
  //     setFieldValue('customerId', dataRow?.customerId);
  //     setFieldValue('productId', +id);
  //   }, []);
  //   return (
  //     <Fragment>
  //       <Row>
  //         <Col width='300'>
  //           <Dropdown
  //             width={'300px'}
  //             withoutFormik={true}
  //             title='Type'
  //             // setFieldValue={(name, value) => change(name, value)}
  //             setFieldValue={setFieldValue}
  //             valueField = 'id'
  //             labelField = 'name'
  //             name = 'specialPriceTypeId'
  //             defaultValueField={values.specialPriceTypeId}
  //             graphqlParams={{ query: listFA_CustomerSpecialPricesTypes, variables: { languageId: +localStorage.getItem('lngId') || 1 } }}
  //           />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col lg={2}>
  //           <Calendar
  //             withoutFormik={true}
  //             dateFormat
  //             name='fromDate'
  //             title={t('debitor.invoicesFrom')}
  //             value={values.fromDate}
  //             setFieldValue={props.setFieldValue}
  //           />
  //         </Col>
  //         <Col lg={2}>
  //           <Calendar
  //             withoutFormik={true}
  //             dateFormat
  //             name='toDate'
  //             title={t('debitor.invoicesTill')}
  //             value={values.toDate}
  //             setFieldValue={props.setFieldValue}
  //           />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col width='300'>
  //           <Dropdown
  //             width={'300px'}
  //             withoutFormik={true}
  //             title={t('customer.customer')}
  //             setFieldValue={setFieldValue}
  //             valueField = 'customerNr'
  //             labelField = 'concatenatedName'
  //             name = 'customerNm'
  //             defaultValueField = {values.customerNm}
  //             graphqlParams={{ query: FA_ProductCustomerPriceCustomers }}
  //           />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col lg={2}>
  //           <Input
  //             withoutFormik={true}
  //             onChange={props.handleChange}
  //             value={values.price}
  //             type='number'
  //             name={'price'}
  //             title={t('common.price')}
  //             decimal
  //             small
  //           />
  //           <Input
  //             withoutFormik={true}
  //             onChange={props.handleChange}
  //             value={values.discount}
  //             type='number'
  //             name={'discount'}
  //             title={t('common.discount')}
  //             decimal
  //             small
  //           />
  //           <Checkbox
  //             withoutFormik={true}
  //             onChange={setFieldValue}
  //             name={'isDiscountsDisabled'}
  //             value={values.isDiscountsDisabled}
  //             field='isDiscountsDisabled'
  //             title={t('common.discountDisable')}
  //             margin={'45px 0 0 10px'}/>
  //         </Col>
  //         <Col lg={2}>
  //           <Input
  //             withoutFormik={true}
  //             title={t('customer.fromAmount')}
  //             onChange={props.handleChange}
  //             value={values.fromAmount}
  //             name={'fromAmount'}
  //             decimal
  //             type='number'
  //             small
  //           />
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col width='400px'>
  //           <TextAreaStandart
  //             withoutFormik={true}
  //             onChange={props.handleChange}
  //             value={values.description}
  //             name={'description'}
  //             title={t('common.description')}
  //             margin={'10px'}
  //             width='100%'
  //           />
  //         </Col>
  //       </Row>
  //     </Fragment>
  //   );
  // };

  const Component = (props) => {
    const { handleChange, values, setFieldValue, handleBlur, setFieldTouched, touched } = props;
    const newList = cloneDeep(list);
    if ([1, 4].includes(values.specialPriceTypeId) && (!newList[4].isRequired || newList[5].isRequired)) {
      newList[4].isRequired = true;
      newList[5].isRequired = false;
    } else if ([2, 5].includes(values.specialPriceTypeId) && ((!newList[5].isRequired && !values.isDiscountsDisabled) || newList[4].isRequired)) {
      newList[5].isRequired = true;
      newList[4].isRequired = false;
    } else if ([3, 6].includes(values.specialPriceTypeId) && (!newList[4].isRequired || (!newList[5].isRequired && !values.isDiscountsDisabled))) {
      newList[5].isRequired = true;
      newList[4].isRequired = true;
    }
    if ([1, 2, 3].includes(values.specialPriceTypeId) && newList[7].isRequired) {
      newList[7].isRequired = false;
    } else if ([4, 5, 6].includes(values.specialPriceTypeId) && !newList[7].isRequired) {
      newList[7].isRequired = true;
    }

    if (values.isDiscountsDisabled && newList[5].isRequired) {
      newList[5].isRequired = false;
    }

    if (JSON.stringify(newList) !== JSON.stringify(list)) {
      setList(newList);
    }

    return (
      <div>
        <Row>
          <Col width='320'>
            <Dropdown
              width={'300px'}
              withoutFormik={true}
              title={t('common.type')}
              onChange={(value) => {
                setFieldValue('specialPriceTypeId', value.value);
              }}
              valueField='id'
              labelField='name'
              name='specialPriceTypeId'
              defaultValueField={values.specialPriceTypeId}
              graphqlParams={{ query: listFA_CustomerSpecialPricesTypes,
                variables: {
                  languageId: +localStorage.getItem('lngId') || 1,
                },
              }}
              handleBlur={handleBlur}
              touched={touched}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={2}>
            <Calendar withoutFormik={true} title={t('common.fromDate')} setFieldValue={setFieldValue} name='fromDate' value={values.fromDate} />
          </Col>
          <Col lg={2}>
            <Calendar withoutFormik={true} title={t('common.toDate')} setFieldValue={setFieldValue} name='toDate' value={values.toDate} />
          </Col>
        </Row>
        <Row>
          <Col width={'320px'}>
            <Dropdown
              width={'300px'}
              withoutFormik={true}
              title={t('customer.customer')}
              onChange={(value) => {
                setFieldValue('customerId', value.value); setFieldValue('customerNm', value.data.customerNr); setFieldValue('actualName', value.data.actualName);
              }}
              valueField='id'
              labelField='actualName'
              err={props.errors}
              name='customerId'
              nameForValid='customerNm'
              defaultValueField={values.customerId}
              graphqlParams={{ query: FA_ProductCustomerPriceCustomers }}
              setFieldTouched={setFieldTouched}
              touched={touched}
              withVirtualized
            />
          </Col>
        </Row>
        <Row style={{ minHeight: '81px' }}>
          <Col lg={2}>
            {[1, 3, 4, 6].includes(values.specialPriceTypeId) &&
              <Input
                small
                withoutFormik={true}
                type='number'
                onChange={handleChange}
                value={values.price}
                decimal
                name={'price'}
                title={t('common.price')}
                err={props.errors}
                handleBlur={handleBlur}
                touched={touched}
              />
            }
          </Col>
          {[4, 5, 6].includes(values.specialPriceTypeId) &&
            <Col lg={2}>
              <Input
                small
                title={t('customer.fromAmount')}
                withoutFormik={true}
                type='number'
                onChange={handleChange}
                value={values.fromAmount}
                name={'fromAmount'}
                handleBlur={handleBlur}
                touched={touched}
                err={props.errors}
              />
            </Col>
          }
        </Row>

        <Row style={{ minHeight: '81px' }}>
          {[2, 3, 5, 6].includes(values.specialPriceTypeId) &&
            <Col lg={2}>
              <Input
                small
                withoutFormik={true}
                type='number'
                onChange={handleChange}
                value={values.discount}
                err={props.errors}
                name={'discount'}
                title={t('common.discount')}
                handleBlur={handleBlur}
                touched={touched}
              />
            </Col>
          }
          {[2, 3, 5, 6].includes(values.specialPriceTypeId) &&
            <Col lg={2}>
              <Checkbox
                withoutFormik={true}
                onChange={setFieldValue}
                value={values.isDiscountsDisabled}
                name={'isDiscountsDisabled'}
                title={t('common.noDiscountPosible')}
              />
            </Col>
          }
        </Row>
        <Row>
          <Col width={'320px'}>
            <TextAreaStandart
              width={'300px'}
              withoutFormik={true}
              type='number'
              onChange={handleChange}
              value={values.description}
              name={'description'}
              title={t('common.note')}
            />
          </Col>
        </Row>
      </div>

    );
  };

  return (
    <Fragment>
      {/* <Row>
        <StyledColInput width={'260px'}>
          <Input value={'Discount groups'} field='discGroup' width={'250px'}/>
        </StyledColInput>
        <StyledColImg lg={2}>
          <StyledImg src={copy}/>
        </StyledColImg>
      </Row> */}
      <Table>
        <TableGrid
          graphqlParams={{
            query: listFA_CustomerSpecialPricesAndDiscountsProduct,
            variables: { id: +props.id },
          }}
          tableData={list}
          specialWidth={320}
          height={500}

          Component={Component}
          modalWidth={440}
          modalHeight={680}
          headerText={t('product.customerPrices')}
          addFuncCreate={() => refetchCount('1')}
          addFuncDelete={() => refetchCount('-1')}

          defaultValuesForFormik={['specialPriceTypeId', 'customerId', 'customerNm', 'actualName']}
          defaultValuesForCache={['specialPriceTypeId', 'actualName']}
          itemDeleted={deleteFA_SpecialPrices}
          defaultValuesForMutation={{ productId: +props.id }}
          typeName={'listFA_CustomerSpecialPricesAndDiscountsProduct'}
          qqlMutationCreate={createFA_SpecialPricesProduct}
          qqlMutationUpdate={updateFA_CustomerSpecialPricesAndDiscountsProduct}
          isRefetchAfterMutation={true}
          withModalMutations
          withSearch
        />
      </Table>
    </Fragment>
  );
};

export default CustomerPrice;

// qqlMutationUpdate={updateDiscount}
// qqlMutationCreate={createProductSpecialPrice}
// withModalMutations

// const StyledColInput = styled(Col)`
//   margin: 0px 0px 10px 0;
// `;
// const StyledColImg = styled(Col)`
//   width: 36px;
//   height: 36px;
//   border-radius: 4px;
//   box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
//   background-color: var(--white);
// `;

// const StyledImg = styled.img`
//   margin: 10px 12.4px;
//   width: 12px;
//   height: 15.6px;
//   object-fit: contain;
// `;


