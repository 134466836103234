import gql from 'graphql-tag';

export const DAYS = gql`
query getProduct($id: ID!){
	fakturaAssist{
		getProduct(id: $id){
			id
			deliveryMonday
			deliveryTuesday
			deliveryWednesday
			deliveryThursday
			deliveryFriday
			deliverySaturday
			deliverySunday
		}
	}
}
`;

export const CREATE_DAYS = gql`
mutation updateProduct($id: ID!, $fields: ProductField!) {
  fakturaAssist {
    updateProduct(id: $id, fields: $fields){
      id
    }
  }
} `;
