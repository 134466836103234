
import gql from 'graphql-tag';

export default gql`
query listFA_ProductionFermentation($date: String){
    fakturaAssist{
    listFA_ProductionFermentation(date: $date){
		linkArticle
		articleNo
		description
		quantity
		inStock
		ordered
		reStock
    }
    
  }
}`;
