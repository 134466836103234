import gql from 'graphql-tag';

export default gql`
query listFA_Invoice($filter: [FA_GeneralFilter]!,  $isDelete: Boolean){
    fakturaAssist {
    	listFA_Invoice(filter: $filter, withDeleted: $isDelete){
    		id
	        date
	        invoiceNr
	        customerNr
            total
            customerLink
            isDelete
            nameRes
            paidByAutoBankDebit
			invoiceId
			sammelfakturaAufKunde
 		}
 	}
}`;
