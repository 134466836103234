import React from 'react';

import Reduction from './table/reduction';
import Reseller from './table/reseller';
import Article from './table/article';
import Payment from './table/payment';
import Refresh from './table/refresh';

import { Transition } from '../../../components/elements';
import { useTranslation } from 'react-i18next';
//import Formik from '../../../components/formik';

export default (props) => {
  const [t] = useTranslation();
  return (
    <React.Fragment>
      <Transition index={0} text={t('customer.conditions.reduction')} isWhite>
        <Reduction {...props}/>
      </Transition>
      <Transition index={1} text={t('customer.conditions.articlePrices')} isWhite>
        <Article {...props}/>
      </Transition>
      <Transition index={2} text={t('customer.conditions.resellerPrices')} isWhite>
        <Reseller {...props}/>
      </Transition>
      <Transition index={3}text={t('customer.conditions.payment')} isWhite>
        <Payment {...props}/>
      </Transition>
      <Transition index={6} text={t('customer.conditions.refresh')} isWhite>
        <Refresh {...props} />
      </Transition>
    </React.Fragment>
  );
};


