import React from 'react';
import { components } from 'react-select';

const defaultStyle = { color: '#565656', paddingRight: 10 };

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.isFocused && <i className='fas fa-search' style={defaultStyle} />}
      <i className='fas fa-caret-down' />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
