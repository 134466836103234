import gql from 'graphql-tag';

const GET_FA_PRODUCT_FILTER = gql`
    query getFA_CustomerFilter {
        fakturaAssist {
            listFA_ProductLists {
                id
                name
                relatedArticles
            }
            listFA_ProductGroup1 {
                productGroup
            }
            listFA_ProductGroup2 {
                id
                name
            }
            listFA_ProductGroup3 {
                id
                name
            }
            listFA_Assortment {
                id
                name
            }
        }
    }
`;

export default GET_FA_PRODUCT_FILTER;
