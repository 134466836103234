import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col } from 'style';

import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid';

import Query from 'components/graphql/Query';

import editImg from 'style/img/invoice/edit.svg';

import { TableWithOrders } from './';
import { getFA_GetOrderPositions, getFA_GetDeletedOrderPositions } from '../../qql/query';
import { tableProductsParams } from '../params';

const NewInvoice = (props) => {
  const { t, getOrderEditPage, orderId, changeChooseOrder, linkId, isDelete, sammelfakturaAufKunde } = props;
  return (
    <Formik initialValues={{ textareaValue: '', invoiceDate: moment(new Date()).add(1, 'days'), textID: 2 }}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        return (
          <MainBlock>
            <Row>
              <Col width='100%'>
                <InvoiceBlock>
                  <OptionBlock>
                    <EditImg alt='Edit image' src={editImg} onClick={getOrderEditPage} />
                  </OptionBlock>
                </InvoiceBlock>
              </Col>
            </Row>
            <Row>
              <Col width='30%'>
                <OrderSection>
                  <TableWithOrders
                    getFirstValueOfMount={(item) => changeChooseOrder(item)}
                    linkId={linkId}
                    isDelete={isDelete}
                    sammelfakturaAufKunde={sammelfakturaAufKunde}
                    changeChooseOrder={changeChooseOrder}
                    getOrderEditPage={getOrderEditPage}
                    t={t}
                  />
                </OrderSection>
              </Col>
              <Query query={isDelete ? getFA_GetDeletedOrderPositions : getFA_GetOrderPositions} variables={{ id: orderId }} skip={!orderId}>
                {({ data, ...prev }) => {
                  return (
                    <Col width='70%'>
                      <TableGrid
                        tableData={tableProductsParams(t)}
                        data={data.productTable || []}
                        height={300}
                        disableHeightChange
                        withTotal
                        disableRow
                      />
                    </Col>
                  );
                }}
              </Query>
            </Row>
          </MainBlock>);
      }}
    </Formik>
  );
};

export default NewInvoice;

const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 90px;
`;

const InvoiceBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const OrderSection = styled.div`
  margin-right: 10px;
`;

const OptionBlock = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
  background-color: #3cb950;
  margin: 13px 0 10px 0;
  cursor: pointer;
`;

const EditImg = styled.img`
  height: 22px;
  width: 13px;
  margin: 0 0 0 5px;
  display: block;
`;
