import React from 'react';
import styled from 'styled-components';
import Formik from 'components/formik';
import { Row, Col } from 'style/index';
import { Dropdown } from 'components/elements';

import {
  listFA_ProductCashAssist,
  listFA_view_fkCashAssistKonfigurationWG,
  listFA_view_fkCAVisibilityStatuses,
} from './graphql/cashAssist';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
      linkId={props.linkId}
      sectionInfo={props.sectionInfo}
    >
      {({ values }) => {
        const { caLinkTempalte, caVisibilityStatusID, salesGroupId } = values;
        return (
          <HeadBlock>
            <MainBlock>
              <Row>
                <Col lg={4}>
                  <Dropdown
                    title={t('product.template')}
                    labelField='concatenatedField'
                    valueField='id'
                    field='caLinkTempalte'
                    defaultValueField={caLinkTempalte}
                    graphqlParams={{ query: listFA_ProductCashAssist }}
                  />
                  <Dropdown
                    title={t('product.salesGroup')}
                    labelField='resultName'
                    valueField='id'
                    field='salesGroupId'
                    defaultValueField={salesGroupId}
                    graphqlParams={{ query: listFA_view_fkCashAssistKonfigurationWG, variables: { languageID: 2 } }}
                  />
                  <Dropdown
                    title={t('product.visibility')}
                    labelField='CAVisibilityStatusName'
                    valueField='id'
                    field='caVisibilityStatusID'
                    defaultValueField={caVisibilityStatusID}
                    graphqlParams={{ query: listFA_view_fkCAVisibilityStatuses, variables: { languageID: 2 } }}
                  />
                </Col>
              </Row>
            </MainBlock>
          </HeadBlock>
        );
      }}
    </Formik>
  );
};

const HeadBlock = styled(Row)`
  padding: 0px 0px 0px 0px;
  position: relative;
  height: 700px;
  z-index:0;
`;

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
  height: 260px;
`;
