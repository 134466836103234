import React, { useState } from 'react';
import styled from 'styled-components';
import QueryComponent from 'components/graphql/Query';
import MutationHoc from 'components/graphql/MutationHoc';
import { LoadingSpinner } from 'components/Loading/StyledLoading';
import { Row, Col } from 'style';
import DateModal from './components/DateModal';
import { Dropdown, Input, Checkbox, Transition } from 'components/elements';
import { vacationsTable, periodsTableData } from './data';
import Line from 'components/elements/Line/Line';
import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid';
import listFA_Discount from '../../../../customer/conditions/table/reduction/qql/listFA_Discount';
import { useTranslation } from 'react-i18next';

import {
  listFA_CustomerDefaultProfiles,
  listFA_CustomerView_fkDebitorStatuses,
  updateFA_Config,
  listFA_CustomerDefaultProfileDeliveryPeriods,
  listFA_CustomerView_fkInvoiceIntervals,
  updateFA_DefaultCustomerProfile,
  createFA_CustomerDefaultDeliveryPeriods,
  createFA_DefaultCustomerProfile,
  deleteFA_DefaultCustomerProfile,
  deleteFA_CustomerDefaultDeliveryPeriods,
  updateFA_CustomerDefaultDeliveryPeriods,
  getCustomerNoFromConfig,
  listFA_TaxInclusionKinds,
  listFA_CustomerInvoiceForms,
  listFA_CustomerPaymentSlipReportID,
  listFA_CustomerInvoiceSummaryForms,
  getFA_CustomerDefaultProfile,
  listFA_CustomerDeliveryNoteForms,
  listFA_DiscountDropdown,
  listFA_ConfigCustomerPriceCategories,
} from './gql';
import { ReportSystemInput } from '../../../../../components/elements';
import { useQuery } from '@apollo/react-hooks';
import { getFA_Config } from '../../../qql/query';

const Customer = (props) => {
  const [profile, setPropfile] = useState(null);
  const { data, loading } = useQuery(getFA_Config);
  const [t] = useTranslation();

  const ProfileModal = (props) => {
    const { values } = props;
    return (
      <Row>
        <Input withoutFormik onChange={props.handleChange('name')} field={'name'} value={values.name} title={t('common.name')} />
      </Row>
    );
  };

  const removeTypeName = (data) => {
    if (data.__typename) delete data.__typename;
    return data;
  };

  const renderDiscountInputs = (code, values) => {
    if (loading || !data) return null;
    const groups = data.fakturaAssist.getFA_Config.productGroup;
    if (code === 1) {
      return null;
    } else if (code === 2) {
      return (
        <StyledInput small value={values.discountInPercent} field={'discountInPercent'} title={'Discount'} type={'number'}/>
      );
    } else if (code === 3 || code === 4) {
      return new Array(20).fill(null).map((item, index) => {
        return groups[index] ? <StyledInput type={'number'} small value={values['discountInPercent' + (index + 1).toString()]} field={'discountInPercent' + (index + 1).toString()} title={groups[index]}/> : null;
      });
    } else if (code === 5 || code === 6) {
      return (
        <Dropdown graphqlParams={{ query: listFA_Discount }} title={'Quantity discount'} defaultValueField={values.linkQuantitiesDiscount} field={'linkQuantitiesDiscount'}/>
      );
    }
  };

  return (
    <Main>
      <ClientList>
        <TableGrid
          height={650}
          modalHeight={200}
          disableHeightChange
          withModalMutations
          defaultValuesForFormik={['id', 'name']}
          defaultValuesForCache={['id', 'name']}
          Component={ProfileModal}
          headerText={t('options.configurations.editProfile')}
          itemDeleted={deleteFA_DefaultCustomerProfile}
          typeName={'FA_CustomerDefaultProfiles'}
          qqlMutationCreate={createFA_DefaultCustomerProfile}
          qqlMutationUpdate={updateFA_DefaultCustomerProfile}
          linkId={profile}
          selectById
          getFirstValueOfMount={({ id }) => setPropfile(id)}
          setTableIndex={profile}
          changeSelectId={(profile) => setPropfile(profile.id)}
          graphqlParams={{ query: listFA_CustomerDefaultProfiles, variables: {} }}
          withSearch
          tableData={[{ name: t('common.profile'), dataBase: 'name' }]}

        />
      </ClientList>
      <Content>
        <QueryComponent variables={{ id: +profile }} skip={!profile || profile < 0} fetchPolicy={'cache-and-network'} query={getFA_CustomerDefaultProfile}>
          {({ data, loading, error, networkStatus, queryField, refetch, fetchMore, ...restQuery }) => {
            if ((!profile || profile < 0 || loading) && !props.loading) {
              return (<Transition text={t('options.configurations.defaultSettings')} isOpen><LoadingSpinner width='100%'
                height='100px'
                transparent/></Transition>);
            }
            return (
              <MutationHoc mutation={updateFA_DefaultCustomerProfile}>
                {({ mutation }) => (
                  <Formik
                    initialValues={data}
                    // enableReinitialize={true}
                    onSubmit={(v) => mutation({ variables: { id: +profile, fields: removeTypeName(v) } })}
                    linkId={profile}>
                    {({ handleSubmit, handleChange, values, setFieldValue }) => {
                      return (
                        <>
                          <Transition text={t('options.configurations.defaultSettings')} isOpen withoutMargin>
                            <SecondarySpoiler text={t('options.configurations.profileDiscount')} isWhite isOpen>
                              <StyledInput title={t('options.configurations.profileName')} name='name' field='name' value={values.name}/>
                              <FlexBox>
                                <StyledInput title={t('customer.payment.cashDiscount')} field='discountInPercent' type='number' decimal value={values.discountInPercent}
                                  width='140px'/>
                                <StyledInput title={t('common.days.days')} type='number' field='discountDays' value={values.discountDays} width='140px'/>
                              </FlexBox>
                              <Line marginTop={'20px'} marginBottom={'20px'} marginRight={'-10px'} marginLeft={'-10px'} backColor={'rgba(195, 195, 195, 0.2)'}/>
                              <Dropdown
                                graphqlParams={{
                                  query: listFA_DiscountDropdown,
                                  variables: {
                                    languageId: +localStorage.getItem('lngId') || 1,
                                  },
                                }}
                                title={t('customer.quantityDiscountOnOrderingAmount')}
                                valueField='index'
                                labelField='name'
                                name='discountCode'
                                onChangeFormik={handleChange}
                                withIndex
                                field='discountCode'
                                defaultValueField={values.discountCode}
                              />
                              <FlexBox>
                                {
                                  renderDiscountInputs(values.discountCode, values)
                                }
                              </FlexBox>
                            </SecondarySpoiler>
                            <SecondarySpoiler text={t('customer.payment.termOfPaymentInDays')} isWhite isOpen>
                              <StyledInput title={t('customer.payment.termOfPaymentInDays')} value={values.payment} field={'payment'} type='number'/>
                            </SecondarySpoiler>
                            <SecondarySpoiler isWhite isOpen text={t('product.priceCategories')}>
                              <Dropdown

                                graphqlParams={{
                                  query: listFA_ConfigCustomerPriceCategories,
                                }}
                                title={t('customer.priceCategory')}
                                valueField='id'
                                labelField='resName'
                                field='articlePriceGroup'
                                small
                                defaultValueField={values.articlePriceGroup}
                              />
                              <Dropdown

                                graphqlParams={{
                                  query: listFA_TaxInclusionKinds,
                                  variables: {
                                    languageId: +localStorage.getItem('lngId') || 1,
                                  },
                                }}
                                title={t('customer.priceCategory')}
                                valueField='id'
                                labelField='name'
                                field='MWStSystem'
                                small
                                defaultValueField={values.MWStSystem}
                              />
                            </SecondarySpoiler>
                            <SecondarySpoiler isWhite isOpen text={t('common.deliveryNote')}>
                              <ReportSystemInput graphqlParams={{
                                query: listFA_CustomerDeliveryNoteForms,
                                variables: {
                                  languageId: +localStorage.getItem('lngId') || 1,
                                },
                              }} onChangeFormik={setFieldValue} name='orderReportID' title='Group' field='orderReportID' defaultValueField={values.orderReportID}/>
                            </SecondarySpoiler>
                            <SecondarySpoiler isWhite isOpen text={t('invoice.invoice')}>
                              <FlexBox>

                                <StyledReportSystemInput graphqlParams={{
                                  query: listFA_CustomerInvoiceForms,
                                  variables: {
                                    languageId: +localStorage.getItem('lngId') || 1,
                                  },
                                }} title='Form' onChangeFormik={setFieldValue} field='invoiceReportID' defaultValueField={values.invoiceReportID}/>
                                <Dropdown

                                  graphqlParams={{
                                    query: listFA_CustomerView_fkInvoiceIntervals,
                                    variables: {
                                      languageId: +localStorage.getItem('lngId') || 1,
                                    },
                                  }}
                                  title={t('customer.invoice.accounting')}
                                  valueField='id'
                                  labelField='name'
                                  field='fakturaAccounting'
                                  small
                                  defaultValueField={values.fakturaAccounting}
                                />
                              </FlexBox>
                              <FlexBox>
                                <StyledReportSystemInput graphqlParams={{
                                  query: listFA_CustomerInvoiceSummaryForms,
                                  variables: {
                                    languageId: +localStorage.getItem('lngId') || 1,
                                  },
                                }} title='Invoice summary form' onChangeFormik={setFieldValue} field='invoiceSummaryReportID' defaultValueField={values.invoiceSummaryReportID}/>
                                <Dropdown

                                  graphqlParams={{
                                    query: listFA_CustomerView_fkDebitorStatuses,
                                    variables: {
                                      languageId: +localStorage.getItem('lngId') || 1,
                                    },
                                  }}
                                  title={t('debitor.debitor')}
                                  valueField='id'
                                  labelField='name'
                                  field='debitorStatus'
                                  small
                                  defaultValueField={values.debitorStatus}
                                />
                              </FlexBox>
                              <ReportSystemInput graphqlParams={{
                                query: listFA_CustomerPaymentSlipReportID,
                                variables: {
                                  languageId: +localStorage.getItem('lngId') || 1,
                                },
                              }} title='Payment slip form ' onChangeFormik={setFieldValue} field='invoicePaymentSlipReportID'
                              defaultValueField={values.invoicePaymentSlipReportID}/>
                            </SecondarySpoiler>
                          </Transition>
                          <Transition text={t('common.deliveryPeriods')} isOpen>
                            <SecondarySpoiler isWhite isOpen text={t('common.chooseDays')}>
                              <div>
                                <Row>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isMonday}
                                      field='isMonday'
                                      title={t('common.days.monday')}
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isWednesday}
                                      field='isWednesday'
                                      title={t('common.days.wednesday')}
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isFriday}
                                      field='isFriday'
                                      title={t('common.days.friday')}
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isSunday}
                                      field='isSunday'
                                      title={t('common.days.sunday')}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isTuesday}
                                      field='isTuesday'
                                      title={t('common.days.tuesday')}
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isThursday}
                                      field='isThursday'
                                      title={t('common.days.thursday')}
                                    />
                                  </Col>
                                  <Col lg={2}>
                                    <Checkbox
                                      margin='24px 0 0 0'
                                      width='100%'
                                      value={values.isSaturday}
                                      field='isSaturday'
                                      title={t('common.days.saturday')}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </SecondarySpoiler>
                            <SecondarySpoiler isWhite isOpen text={t('common.period')}>
                              <TableGrid

                                // withSearch
                                graphqlParams={{
                                  query: listFA_CustomerDefaultProfileDeliveryPeriods,
                                  variables: {
                                    id: +profile,
                                    isVacation: false,
                                  },
                                }}
                                // Component={ModalInfo}
                                // qqlMutationUpdate={updateFA_CustomerDefaultProfileDeliveryPeriods}
                                // defaultValuesForFormik = {['id', 'name', 'isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday', 'isSunday']}
                                // typeName={'FA_ProductDeliveryPeriod'}
                                // withModalMutations
                                // tableData={periodsTableData(data)}

                                // graphqlParams={{
                                //   query: getFA_ProductDeliveryPeriod,
                                //   variables: { productLink: +profile },
                                // }}
                                tableData={periodsTableData(data, t)}
                                specialWidth={320}
                                height={260}
                                defaultValuesForMutation={{ linkCustomerDefaultProfile: +profile }}

                                Component={DateModal(t)}
                                headerText={t('common.editPeriod')}
                                modalWidth={550}
                                modalHeight={550}
                                itemDeleted={deleteFA_CustomerDefaultDeliveryPeriods}
                                defaultValuesForFormik={['id', 'isMonday', 'description', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday', 'isSunday', 'isCustomWeekDaySetting']}
                                defaultValuesForCache={['id', 'linkCustomerDefaultProfile', 'fromDate', 'days', 'description', 'toDate', 'isMonday', 'isTuesday', 'isWednesday', 'isThursday', 'isFriday', 'isSaturday', 'isSunday', 'isRepeatYearly', 'isCustomWeekDaySetting']}
                                typeName={'FA_DeliveryPeriods'}
                                qqlMutationCreate={createFA_CustomerDefaultDeliveryPeriods}
                                qqlMutationUpdate={updateFA_CustomerDefaultDeliveryPeriods}
                                withModalMutations
                                withSearch
                              />
                            </SecondarySpoiler>
                            <SecondarySpoiler isWhite isOpen text={t('common.vacation')}>
                              <TableGrid

                                withSearch
                                // itemDeleted={deleteFA_DeliveryPeriods}
                                graphqlParams={{
                                  query: listFA_CustomerDefaultProfileDeliveryPeriods,
                                  variables: {
                                    id: +profile,
                                    isVacation: true,
                                  },
                                }}
                                // addNewItem = {addNewItem}
                                // editItem={editItem}
                                //onDelete = {onDelete}
                                //searchValue={this.state.searchValue}
                                //onSearch={this.onSearch}
                                //getSortField={this.getSortField}
                                tableData={vacationsTable(t)}
                                specialWidth={320}
                                height={300}
                                defaultValuesForMutation={{ linkCustomerDefaultProfile: +profile, isVacation: true }}

                                Component={DateModal(t, true)}
                                headerText={t('common.editPeriod')}
                                modalWidth={550}
                                modalHeight={370}
                                itemDeleted={deleteFA_CustomerDefaultDeliveryPeriods}
                                defaultValuesForFormik={['id', 'description', 'fromDate', 'toDate']}
                                defaultValuesForCache={['id', 'linkCustomerDefaultProfile', 'fromDate', 'description', 'toDate', 'isVacation']}
                                typeName={'FA_DeliveryPeriods'}
                                qqlMutationCreate={createFA_CustomerDefaultDeliveryPeriods}
                                qqlMutationUpdate={updateFA_CustomerDefaultDeliveryPeriods}
                                withModalMutations
                                //getTableIndex={this.indexOnClickTable}
                              />
                            </SecondarySpoiler>
                          </Transition>
                        </>
                      );
                    }
                    }
                  </Formik>
                )
                }
              </MutationHoc>
            );
          }
          }
        </QueryComponent>
        <Transition text={t('common.customerNo')} isOpen>
          <QueryComponent fetchPolicy={'cache-and-network'} query={getCustomerNoFromConfig}>
            {({ data, loading, error, networkStatus, queryField, refetch, fetchMore, ...restQuery }) => {
              if (loading && !props.loading) return <Transition text={t('options.configurations.defaultSettings')} isOpen><LoadingSpinner width='100%' height='100px' transparent/></Transition>;
              return (
                <MutationHoc mutation={updateFA_Config}>
                  {({ mutation }) => (
                    <Formik initialValues={data} onSubmit={(v) => mutation({ variables: {fields: removeTypeName(v) } })}>
                      {({ values }) => (
                        <>
                          <Checkbox
                            margin='24px 0 0 0'
                            width='100%'
                            value={values.flagAutoKundenNr}
                            field='flagAutoKundenNr'
                            title={t('options.configurations.customer.assignAutomatically')}
                          />
                          <FlexBox>
                            <Input small field='autoKundenNrFix' value={values.autoKundenNrFix} title={t('options.configurations.customer.partOfTheCustomer')}
                              style={{ marginRight: '20px' }}/>
                            <Input small field='autoKundenNrVariabel' value={values.autoKundenNrVariabel} type='number' title={t('options.configurations.customer.ongoingValue')}/>
                          </FlexBox>
                        </>
                      )
                      }
                    </Formik>
                  )
                  }
                </MutationHoc>
              );
            }
            }
          </QueryComponent>
        </Transition>
      </Content>
    </Main>
  );
};

export default Customer;

export const Main = styled.div`
width: 100%;
height: 95%;
position: relative;
background: #f8fdff;
margin: auto;
padding: 20px 0 0 0;
overflow-y: auto;

`;

const StyledReportSystemInput = styled(ReportSystemInput)`
  width: auto;
  margin-right: 20px;
`;

const SecondarySpoiler = styled(Transition)`
    background-color: #fff !important
`;

const StyledInput = styled(Input)`
    background: #f4f4f4;
    border: none;
    margin-right: 20px;
    margin-bottom: 10px;
`;

const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Content = styled.div`
overflow-x: hidden;
width: ${({ withoutItems }) => withoutItems ? '99.388889%' : '78.9%'};
display: block;
float: left;
padding: 0px 20px 0 20px;
`;

const ClientList = styled.div`
width: 20.1388889%;
min-height: 1px;
display: block;
float: left;
padding-left: 20px
`;
