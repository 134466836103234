/* eslint-disable */
import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { chain } from 'lodash'
import { Row, Col, Table } from 'style/index';

import TableGrid from 'components/tableGrid/TableGrid';
import redistribute from 'style/img/redistribute.svg';
import { Input, TextAreaStandart, Calendar } from 'components/elements';

import { SpecialPrice, updateSpecialPrice, deleteProductSpecialPrice, createProductSpecialPrice } from '../gql/Price';
import { TotalCategories } from '../gql/index';
import { PRICE_CATEGORIES } from '../helpers/CONST';

const PriceCategories = (props) => {
  const [t] = useTranslation();
  const { categoryList = [] } = props

  const ModalInfo = (props) => {
    const { values, tableData, errors } = props;
    const titles = chain(tableData).keyBy('dataBase').mapValues('name').value();

    const handleChange = (e) => {
      e.persist();
      const { value, name } = e.target;
      props.setFieldValue(name, value !== '' ? parseFloat(value) : null);
    }

    return (
      <Fragment>
        <Row>
          <Col lg={2}>
            <Calendar
              withoutFormik={true}
              dateFormat
              name='fromDate'
              title={t('common.fromDate')}
              value={values.fromDate}
              setFieldValue={props.setFieldValue}
              err={errors}
            // startDate={values.fromDate}
            // endDate={values.toDate}
            // selectsStart
            />
            {values.price1 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              value={values.price1 !== null ? values.price1 : null}
              type='number'
              decimal
              name={'price1'}
              title={titles.price1 || t('common.number')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price2 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price2 !== null ? values.price2 : null}
              name={'price2'}
              title={titles.price2 || t('common.delivery')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price3 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price3 !== null ? values.price3 : null}
              name={'price3'}
              title={titles.price3 || t('common.netto')}
              small
              manualErrors={errors}
              touched={errors}
            />}
          </Col>
          <Col lg={2}>
            <Calendar
              withoutFormik={true}
              dateFormat
              name='toDate'
              title={t('common.toDate')}
              value={values.toDate}
              setFieldValue={props.setFieldValue}
              err={errors}
            // startDate={values.fromDate}
            // endDate={values.toDate}
            // minDate={values.fromDate}
            // selectsEnd
            />
            {values.price4 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price4 !== null ? values.price4 : null}
              name={'price4'}
              title={titles.price4 || t('common.inTheHouse')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price5 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price5 !== null ? values.price5 : null}
              name={'price5'}
              title={titles.price5 || t('common.gross')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price6 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price6 !== null ? values.price6 : null}
              name={'price6'}
              title={titles.price6 || t('common.gross')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price7 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price7 !== null ? values.price7 : null}
              name={'price7'}
              title={titles.price7 || t('common.gross')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price8 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price8 !== null ? values.price8 : null}
              name={'price8'}
              title={titles.price8 || t('common.gross')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price9 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price9 !== null ? values.price9 : null}
              name={'price9'}
              title={titles.price9 || t('common.gross')}
              small
              manualErrors={errors}
              touched={errors}
            />}
            {values.price10 !== undefined && <Input
              withoutFormik={true}
              onChange={handleChange}
              type='number'
              decimal
              value={values.price10 !== null ? values.price10 : null}
              name={'price10'}
              title={titles.price10 || t('common.gross')}
              small
              manualErrors={errors}
              touched={errors}
            />}
          </Col>
        </Row>
        <Row>
          <Col width='400px'>
            <TextAreaStandart
              withoutFormik={true}
              onChange={props.handleChange}
              value={values.description}
              name={'description'}
              title={t('common.description')}
              margin={'10px'}
              width='100%'
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  const refetchCount = (value) => {
    const data = props.client.readQuery({
      query: TotalCategories,
      variables: {
        linkArticle: +props.id,
      }
    });

    const count = data?.fakturaAssist?.listFA_ProductSpecialPriceConnection?.total || 0;

    props.client.writeQuery({
      query: TotalCategories,
      variables: {
        linkArticle: +props.id,
      },
      data: {
        fakturaAssist: {
          listFA_ProductSpecialPriceConnection: {
            total: count + +value,
            __typename: 'FA_ProductSpecialPriceConnection'
          },
          __typename: 'FA_Query',
        }
      }
    });
  };

  const modalFormValidator = (values) => {
    const { fromDate, toDate, description: unused, ...prices } = values;
    if (!fromDate && !toDate) {
      const commonMsg = t('common.validation.PCDate');
      return {
        ok: false,
        messages: {
          fromDate: commonMsg,
          toDate: commonMsg,
        },
      };
    }
    const allPricesInvalid = Object.keys(prices).every((key) => {
      const currentValue = prices[key];
      return currentValue === null || currentValue < 0;
    })
    if (allPricesInvalid) {
      return {
        ok: false,
        messages: Object.keys(prices).reduce((acc, key) => {
          return { ...acc, [key]: t('common.validation.isRequiredInput') };
        }, {}),
      }
    }
    return { ok: true };
  }

  return (
    <React.Fragment>
      {/* <Row>
        <div>
          <StyledImgGreen src={redistribute}/>
          <StyledText>{t('product.changeRegularPrice')}</StyledText>
        </div>
      </Row> */}
      <Table>
        <TableGrid
          graphqlParams={{
            query: SpecialPrice,
            variables: { linkArticle: +props.id },
          }}
          tableData={PRICE_CATEGORIES(t, categoryList)}
          height={500}

          modalFormValidator={modalFormValidator}

          Component={ModalInfo}
          modalWidth={440}
          modalHeight={600}
          headerText={t('customer.priceCategory')}
          addFuncCreate={() => refetchCount('1')}
          addFuncDelete={() => refetchCount('-1')}

          itemDeleted={deleteProductSpecialPrice}
          defaultValuesForMutation={{ linkArticle: +props.id }}
          typeName={'FA_ProductSpecialPrice'}
          qqlMutationCreate={createProductSpecialPrice}
          qqlMutationUpdate={updateSpecialPrice}
          customHeaderOptions={{ key: 'articlePriceCategory', offset: 0 }}
          withModalMutations
          withSearch
        />
      </Table>
    </React.Fragment>
  );
};

export default PriceCategories;

const StyledText = styled.span`
    margin: 0 0 14px 7px;
    font-family: Roboto-Regular;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #3cb950;
`;

const StyledImgGreen = styled.img`
    color: #3cb950;
    transform: rotate(90deg);
    margin: 0 0 10px 0;
`;
