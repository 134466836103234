import React, { useState } from 'react';
// import { Row, Col } from 'react-bootstrap';
import { useMutation } from 'components/graphql';

import { Modal } from 'components/elements';
import styled from 'styled-components';

// import Formik from 'components/formik';

import createFA_UserForms from '../qql/mutation/createFA_UserForms';
import deleteFA_UserForms from '../qql/mutation/deleteFA_UserForms';
import updateFA_DefaultForms from '../qql/mutation/updateFA_DefaultForms';
import updateFA_UserForms from '../qql/mutation/updateFA_UserForms';

import listFA_Forms from '../qql/listFA_Forms';
import listFA_DefaultForms from '../qql/listFA_DefaultForms';
import listFA_UserForms from '../qql/listFA_UserForms';

import ModalCreate from './ModalCreate';
import Footer from './Footer';
import TableGrid from 'components/tableGrid/TableGrid.js';
import { cloneDeep } from 'lodash';

export default (props) => {
  const {
    setActualPdfParams,
    data,
    defaultValueField,
    avaliableReportForms,
    avaliableReportDefault,
    withoutIdAvaliableReportDefault,
    avaliableReportGroup,
    withoutInput,
    graphqlParams,
    allForms,
    onSelect,
    setOpenMain,
    setId,
    id,
    onSendPDFPrint,
    withPrinting,
    onShowPDFPrint,
    printingServiceAvailable,
    onShowPDFPrintServerless,
  } = props;
  const [open, setOpen] = useState(null);
  const [item, setItem] = useState(null);

  const [Mutation_createFA_UserForms] = useMutation(createFA_UserForms);
  const [Mutation_deleteFA_UserForms] = useMutation(deleteFA_UserForms);
  const [Mutation_updateFA_DefaultForms] = useMutation(updateFA_DefaultForms);
  const [Mutation_updateFA_UserForms] = useMutation(updateFA_UserForms);

  let actualData = [];
  if (!graphqlParams) {
    actualData =
      allForms.data && avaliableReportForms
        ? allForms.data.filter(({ formIdDefault }) =>
          avaliableReportForms.includes(formIdDefault)
        )
        : allForms.data || [];
    actualData =
      actualData[0] && avaliableReportGroup
        ? actualData.filter(({ reportGroupID }) =>
          avaliableReportGroup.includes(reportGroupID)
        )
        : actualData;
  } else {
    actualData =
      data && avaliableReportForms
        ? data.filter(({ formIdDefault }) =>
          avaliableReportForms.includes(formIdDefault)
        )
        : data || [];
    // reportGroupID not exist in data payload, it's gonna useful in future
    //console.log('actualData', data, props)
    // actualData =
    //   actualData[0] && avaliableReportGroup
    //     ? actualData.filter(({ reportGroupID }) =>
    //       avaliableReportGroup.includes(reportGroupID)
    //     )
    //     : actualData;
  }

  const tableData = [
    {
      name: 'Name',
      dataBase: 'form',
    },
    {
      name: 'Type',
      dataBase: 'formId',
      specialComponent: (index, columnIndex, value) => {
        return <div>{value ? '' : 'default'}</div>;
      },
    },
  ];

  const withoutIdForDelete = [];
  if (actualData) {
    for (const item of actualData) {
      if (Number.isInteger(item.id / 1000000)) {
        withoutIdForDelete.push(item.id);
      }
    }
  }

  const onEdit = (index, data) => {
    setItem(data[index]);
    setOpen(true);
  };
  const onDelete = (index, item) => {
    //customCache
    const cacheUserForms = allForms.client.readQuery({
      query: listFA_UserForms,
    }).fakturaAssist.listFA_UserForms;
    const copyMUserForms = cloneDeep(cacheUserForms);

    const muatationedUserForms = copyMUserForms.filter(
      (elem) => elem.id !== item.formId
    );

    allForms.client.writeQuery({
      query: listFA_UserForms,
      data: {
        fakturaAssist: {
          listFA_UserForms: muatationedUserForms,
          __typename: 'FA_Query',
        },
      },
    });
    if (withoutInput || !graphqlParams) {
      //mainCache
      const cacheMainData = allForms.client.readQuery({
        query: listFA_Forms,
        variables: {
          languageId: +localStorage.getItem('lngId') || 1,
        },
      }).fakturaAssist.listFA_Forms;
      const copyMMainData = cloneDeep(cacheMainData);
      const muatationedMainData = copyMMainData.filter(
        (elem) => elem.id !== item.id
      );
      allForms.client.writeQuery({
        query: listFA_Forms,
        variables: {
          languageId: +localStorage.getItem('lngId') || 1,
        },
        data: {
          fakturaAssist: {
            listFA_Forms: muatationedMainData,
            __typename: 'FA_Query',
          },
        },
      });
    } else {
      const cacheMainData = allForms.client.readQuery({
        query: graphqlParams.query,
        variables: graphqlParams.variables,
      }).fakturaAssist[graphqlParams.query.definitions[0].name.value];
      const copyMMainData = cloneDeep(cacheMainData);
      const muatationedMainData = copyMMainData.filter(
        (elem) => elem.id !== item.id
      );
      allForms.client.writeQuery({
        query: graphqlParams.query,
        variables: graphqlParams.variables,
        data: {
          fakturaAssist: {
            [graphqlParams.query.definitions[0].name
              .value]: muatationedMainData,
            __typename: 'FA_Query',
          },
        },
      });
    }
    Mutation_deleteFA_UserForms({
      variables: {
        id: item.formId,
      },
    });
  };

  const addNewItem = () => {
    setItem(null);
    setOpen(true);
  };

  const onSave = (values) => {
    const getName = (name) => {
      return `rg${values.reportGroupID}_` + name.replace(/ /g, '');
    };
    setOpen(false);
    if (item) {
      //update
      //////
      if (withoutInput || !graphqlParams) {
        //mainCache
        const cacheMainData = allForms.client.readQuery({
          query: listFA_Forms,
          variables: {
            languageId: +localStorage.getItem('lngId') || 1,
          },
        }).fakturaAssist.listFA_Forms;
        const copyMMainData = cloneDeep(cacheMainData);
        for (const elem of copyMMainData) {
          if (elem.id === item.id) {
            elem.formIdDefault = values.formIdDefault || item.formIdDefault;
            elem.name =
              values.form === undefined ? item.name : getName(values.form);
            elem.form = values.form || item.form;
          }
        }
        allForms.client.writeQuery({
          query: listFA_Forms,
          variables: {
            languageId: +localStorage.getItem('lngId') || 1,
          },
          data: {
            fakturaAssist: {
              listFA_Forms: copyMMainData,
              __typename: 'FA_Query',
            },
          },
        });
      } else {
        const cacheMainData1 = allForms.client.readQuery({
          query: graphqlParams.query,
          variables: graphqlParams.variables,
        }).fakturaAssist[graphqlParams.query.definitions[0].name.value];
        const copyCacheMainData1 = cloneDeep(cacheMainData1);
        for (const elem of copyCacheMainData1) {
          if (elem.id === item.id) {
            elem.formIdDefault = values.formIdDefault || item.formIdDefault;
            elem.name =
              values.form === undefined ? item.name : getName(values.form);
            elem.form = values.form || item.form;
          }
        }

        allForms.client.writeQuery({
          query: graphqlParams.query,
          variables: graphqlParams.variables,
          data: {
            fakturaAssist: {
              [graphqlParams.query.definitions[0].name
                .value]: copyCacheMainData1,
              __typename: 'FA_Query',
            },
          },
        });
      }
      //////
      if (item.formId > 0) {
        ////
        const cacheUserForms = allForms.client.readQuery({
          query: listFA_UserForms,
        }).fakturaAssist.listFA_UserForms;
        const copyMUserForms = cloneDeep(cacheUserForms);
        for (const elem of copyMUserForms) {
          if (elem.id === item.formId) {
            elem.copies = values.copies || 0;
            elem.special = values.special || '';
            elem.printer = values.printer || '';
            elem.printerTray = values.printerTray || 0;
            elem.defaultFormID = values.formIdDefault || item.formIdDefault;
            elem.reportGroupID = values.reportGroupID;
            elem.name =
              values.form === undefined ? item.name : getName(values.form);
            elem.form = values.form === undefined ? item.form : values.form;
          }
        }
        allForms.client.writeQuery({
          query: listFA_UserForms,
          data: {
            fakturaAssist: {
              listFA_UserForms: copyMUserForms,
              __typename: 'FA_Query',
            },
          },
        });

        ////
        //customer
        const objForMutation = {
          form: values.form === undefined ? item.form : values.form,
          copies: values.copies,
          special: values.special,
          printer: values.printer,
          printerTray: values.printerTray,
          defaultFormID: values.formIdDefault || item.formIdDefault,
          reportGroupID: values.reportGroupID,
          name: values.form === undefined ? item.name : getName(values.form),
        };
        //mutation
        Mutation_updateFA_UserForms({
          variables: {
            fields: objForMutation,
            id: values.id,
          },
        });
      } else {
        ////////////////
        const cacheDefForms = allForms.client.readQuery({
          query: listFA_DefaultForms,
        }).fakturaAssist.listFA_DefaultForms;
        const copyDefForms = cloneDeep(cacheDefForms);
        for (const elem of copyDefForms) {
          if (elem.id === item.formIdDefault) {
            elem.copies = values.copies;
            elem.special = values.special;
            elem.printer = values.printer;
            elem.printerTray = values.printerTray;
            elem.reportGroupID = values.reportGroupID;
          }
        }
        allForms.client.writeQuery({
          query: listFA_DefaultForms,
          data: {
            fakturaAssist: {
              listFA_DefaultForms: copyDefForms,
              __typename: 'FA_Query',
            },
          },
        });
        //default
        const objForMutation = {
          copies: values.copies,
          special: values.special,
          printer: values.printer,
          printerTray: values.printerTray,
          reportGroupID: values.reportGroupID,
        };

        //mutation
        Mutation_updateFA_DefaultForms({
          variables: {
            id: values.id,
            fields: objForMutation,
          },
        });
      }
    } else {
      const objForMutation = {
        form: values.form || '',
        copies: values.copies || 0,
        special: values.special || '',
        printer: values.printer || '',
        printerTray: values.printerTray || 0,
        defaultFormID: values.formIdDefault,
        reportGroupID: values.reportGroupID,
        name: values.form ? getName(values.form) : '',
      };
      //mutation
      ///////////////
      Mutation_createFA_UserForms({
        variables: {
          fields: objForMutation,
        },
      })
        .then((db) => {
          //////////
          //create (only for customer)

          //edit cache
          //cache Users
          const objForCacheUser = {
            id: db.data.fakturaAssist.createFA_UserForms.id,
            copies: values.copies || 0,
            special: values.special || '',
            printer: values.printer || '',
            printerTray: values.printerTray || 0,
            defaultFormID: values.formIdDefault,
            reportGroupID: values.reportGroupID,
            name: values.form ? getName(values.form) : '',
            __typename: 'FA_UserForms',
          };
          const cacheUserForms = allForms.client.readQuery({
            query: listFA_UserForms,
          }).fakturaAssist.listFA_UserForms;
          const copyMUserForms = cloneDeep(cacheUserForms);
          const muatationedUserForms = copyMUserForms.unshift(objForCacheUser);
          allForms.client.writeQuery({
            query: listFA_UserForms,
            data: {
              fakturaAssist: {
                listFA_UserForms: muatationedUserForms,
                __typename: 'FA_Query',
              },
            },
          });
          //Cache Main
          const newObjForMainCache = {
            id:
              values.formIdDefault * 1000000 +
              db.data.fakturaAssist.createFA_UserForms.id, //id Customer
            form: values.form,
            name: getName(values.form),
            formId: db.data.fakturaAssist.createFA_UserForms.id, //id Customer
            formIdDefault: values.formIdDefault,
            reportGroupID: values.reportGroupID,
            __typename: props.typeName || 'FA_Forms',
          };
          if (withoutInput || !graphqlParams) {
            //mainCache
            const cacheMainData = allForms.client.readQuery({
              query: listFA_Forms,
              variables: {
                languageId: +localStorage.getItem('lngId') || 1,
              },
            }).fakturaAssist.listFA_Forms;
            const copyMMainData = cloneDeep(cacheMainData);
            copyMMainData.unshift(newObjForMainCache);
            allForms.client.writeQuery({
              query: listFA_Forms,
              variables: {
                languageId: +localStorage.getItem('lngId') || 1,
              },
              data: {
                fakturaAssist: {
                  listFA_Forms: copyMMainData,
                  __typename: 'FA_Query',
                },
              },
            });
          } else {
            const cacheMainData1 = allForms.client.readQuery({
              query: graphqlParams.query,
              variables: graphqlParams.variables,
            }).fakturaAssist[graphqlParams.query.definitions[0].name.value];
            const copyCacheMainData1 = cloneDeep(cacheMainData1);
            copyCacheMainData1.unshift(newObjForMainCache);

            allForms.client.writeQuery({
              query: graphqlParams.query,
              variables: graphqlParams.variables,
              data: {
                fakturaAssist: {
                  [graphqlParams.query.definitions[0].name
                    .value]: copyCacheMainData1,
                  __typename: 'FA_Query',
                },
              },
            });
          }

          //mutation
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // console.log('Actual Data', actualData)
  return (
    <Main>
      <Modal
        headerText={'Change Report Form'}
        width={'670px'}
        height={'450px'}
        isOpen={open}
        closeModal={() => setOpen(false)}
        Component={
          <ModalCreate
            avaliableReportForms={avaliableReportForms}
            data={item || { formId: 1 }}
            onSave={onSave}
            open={open}
            setOpen={setOpen}
            avaliableReportDefault={avaliableReportDefault}
            withoutIdAvaliableReportDefault={withoutIdAvaliableReportDefault}
            avaliableReportGroup={avaliableReportGroup}
          />
        }
      />
      <TableGrid
        data={actualData}
        withoutIdForDelete={withoutIdForDelete}
        //searchValue={this.state.searchValue}
        //onSearch={this.onSearch}
        //getSortField={this.getSortField}
        //Component={Component}

        name='Choose Report'
        height={200}
        //name='Quantity discount'
        //headerText={'Edit Quantity discount'}
        //modalWidth={440}
        //modalHeight={205}
        //editItem={editItem}
        addNewItem={addNewItem}
        tableData={tableData}
        withoutIdForUpdate={[-1000000]}
        editItem={onEdit}
        onDelete={onDelete}
        //getTableIndex={this.indexOnClickTable}
        changeSelectId={(item) => {
          setId(item.id);
          if(item.formIdDefault === 36) {
            setActualPdfParams && setActualPdfParams({
              ...props.pdfParams,
              reportId: `${item.formIdDefault}`,
              AUserFormularID: `${item.formId}`,
              ACustomerID: 0,
              APriceCategory: '0' });
          } else {
            setActualPdfParams && setActualPdfParams({ ...props.pdfParams, reportId: `${item.formIdDefault}`, AUserFormularID: `${item.formId}` });
          }
        }}
        //itemDeleted={deleteFA_Discount}
        linkId={id || defaultValueField}
        selectById
        dataPolicy='local'
        initialSortValue='id'
        //withModalMutations
        //typeName={'FA_Discount'}
        //qqlMutationCreate={createFA_Discount}
      />
      <FooterStyle withPrinting={withPrinting}>
        <Footer
          onShowPDFPrint={onShowPDFPrint}
          onSendPDFPrint={onSendPDFPrint}
          withPrinting={withPrinting}
          onShowPDFPrintServerless={onShowPDFPrintServerless}
          printingServiceAvailable={printingServiceAvailable}
          handleClose={() => {
            setOpenMain(false);
          }}
          onSelect={() => onSelect(id)}
        />
      </FooterStyle>
    </Main>
  );
};

const Main = styled.div`
  padding: 20px 20px 20px 20px;
`;

const FooterStyle = styled.div`
  right: auto;
  position: absolute;
  left: ${(props) => (props.withPrinting ? '0px' : '130px')};
  bottom: 20px;
`;
