import React, { Fragment, useEffect } from 'react';
import TableGrid from 'components/tableGrid/TableGrid';
import { useTranslation } from 'react-i18next';
import { Input, TextAreaStandart, Dropdown } from 'components/elements';
import { Row, Col, Table } from 'style/index';

import { FA_ProductCustomerPriceCustomers } from '../gql/CustomerPrice';

import {
  listFA_CustomerResellerPricesProduct,
  createFA_CustomerResellerPrices,
  updateFA_CustomerResellerPrices,
  deleteFA_CustomerResellerPrices,
  listFA_CustomerResellerPricesConnection,
} from '../gql/ResselerPrice';

export const tableOrdersParams = (t) => [
  {
    name: t('common.number'),
    dataBase: 'customerId',
    isMutation: true,
    relativeWidth: 0.2,
    isRequired: true,
  },
  {
    name: t('common.name'),
    dataBase: 'actualName',
    isMutation: true,
    relativeWidth: 0.4,
  },
  {
    name: t('common.price'),
    dataBase: 'price',
    isMutation: true,
    relativeWidth: 0.2,
    dataType: 'float',
    isRequired: true,
  },
  {
    name: t('common.note'),
    dataBase: 'note',
    isMutation: true,
    relativeWidth: 0.2,
  },
];

const ResellerPrice = (props) => {
  const { id } = props;
  const [t] = useTranslation();

  const ModalInfo = (props) => {
    const { values, setFieldValue, touched, handleBlur } = props;
    console.log(props);
    useEffect(() => {
      setFieldValue('productId', +id);
      // setFieldValue('customerid', dataRow?.customerId);
    }, []);
    return (
      <Fragment>
        <Row>
          <Col width='300'>
            <Dropdown
              width={'300px'}
              withoutFormik={true}
              title={t('customer.customer')}
              setFieldValue={setFieldValue}
              name='customerId'
              defaultValueField={values.customerId}
              valueField='id'
              labelField='concatenatedName'
              graphqlParams={{ query: FA_ProductCustomerPriceCustomers }}
              touched={touched}
              // setFieldTouched={setFieldTouched}
              err={props.errors}
              // handleBlur={handleBlur}
              // customErrText={'Is require'}
              // withoutName={true}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <Input
              withoutFormik={true}
              onChange={props.handleChange}
              type='number'
              decimal
              value={values.price}
              name={'price'}
              title={t('common.price')}
              small
              err={props.errors}
              handleBlur={handleBlur}
              touched={touched}
            />
          </Col>
        </Row>
        <Row>
          <Col width='400px'>
            <TextAreaStandart
              withoutFormik={true}
              onChange={props.handleChange}
              value={values.note}
              name={'note'}
              title={t('common.note')}
              margin={'10px'}
              width='100%'
              handleBlur={handleBlur}
              touched={touched}
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  const refetchCount = (value) => {
    const data = props.client.readQuery({
      query: listFA_CustomerResellerPricesConnection,
      variables: {
        id: +props.id,
      },
    });

    console.log(data);

    const count = data?.fakturaAssist?.listFA_CustomerResellerPricesConnection?.total || 0;
    console.log(count);

    props.client.writeQuery({
      query: listFA_CustomerResellerPricesConnection,
      variables: {
        id: +props.id,
      },
      data: {
        fakturaAssist: {
          listFA_CustomerResellerPricesConnection: {
            total: count + +value,
            __typename: 'FA_CustomerResellerPricesConnection',
          },
          __typename: 'FA_Query',
        },
      },
    });
  };

  return (
    <Table>
      <TableGrid
        graphqlParams={{
          query: listFA_CustomerResellerPricesProduct,
          variables: { id: +id },
        }}
        tableData={tableOrdersParams(t)}
        specialWidth={320}
        height={500}

        Component={ModalInfo}
        modalWidth={440}
        modalHeight={455}
        headerText={t('customer.conditions.resellerPrices')}
        addFuncCreate={() => refetchCount('1')}
        addFuncDelete={() => refetchCount('-1')}

        itemDeleted={deleteFA_CustomerResellerPrices}
        defaultValuesForMutation={{ id: +id }}
        typeName={'FA_CustomerResellerPrices'}
        qqlMutationCreate={createFA_CustomerResellerPrices}
        qqlMutationUpdate={updateFA_CustomerResellerPrices}
        isRefetchAfterMutation={true}
        withModalMutations
        withSearch
      />
    </Table>
  );
};

export default ResellerPrice;
