import React from 'react';
import { Row } from 'style';
import styled from 'styled-components';

import { Button } from 'components/elements';
const OrderCreatingModal = ({ closeModal, t }) => (
  <ModalBody>
    <p>
      {t('order.modals.orderWarningModalText')}} <br />
    </p>
    <ButtonBlock lg={5}>
      <Button value={t('common.ok')} onClick={closeModal} />
    </ButtonBlock>
  </ModalBody>
);

const ModalBody = styled.div`
  padding: 20px 20px 0 20px;
  text-align: center;
  font-size: 15px;
`;

const ButtonBlock = styled(Row)`
  margin-top: 30px;
  justify-content: space-around;
`;

export default OrderCreatingModal;
