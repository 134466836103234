import React from 'react';
import { compose, branch } from 'recompose';
import styled from 'styled-components';

import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
import 'moment/locale/en-gb';
import PropTypes from 'prop-types';

import de from 'date-fns/locale/de';
import { default as en } from 'date-fns/locale/en-GB';
import { registerLocale } from 'react-datepicker';

import { withFieldSubmit } from '../../formik';
import withName from '../hoc/withName';
import icon_calendar from './icon.svg';

import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

registerLocale('de', de);
registerLocale('en', en);

const Calendar = (props) => {
  const { submit, field, onChangeFormik, value, withoutFormik, onChange, setFieldValue, name, noActive, dateFormat = false, withTime = false, err } = props;
  const { i18n } = useTranslation();
  const convertToMoment = (val) => {
    if(!val || !moment(val)._isValid) {
      return null;
    } else {
      val = moment(val).format('DD.MM.YYYY, HH:mm');
      const y = val.slice(6, 10);
      const m = val.slice(3, 5);
      const d = val.slice(0, 2);
      const date = moment(y + '-' + m + '-' + d);
      return new Date(moment(date).format());
    }
  };
  
  return (
    <>
      {noActive
        ? <CalendarInputMain {...props} value={moment(props.value).format('DD.MM.YYYY')}/>
        : withTime
          ? <DatePicker
            name={field}
            showTimeSelect
            timeIntervals={1}
            timeCaption='time'
            locale={i18n.language}
            selected={value}
            onChange= {!withoutFormik ? (date)=> {
              onChangeFormik(moment(date).format('YYYY-MM-DD, HH:mm'), name); submit();
            } : (date)=> {
              onChange ? onChange(moment(date).format('YYYY-MM-DD, HH:mm')) :
                dateFormat
                  ? setFieldValue(name, moment(date).format('YYYY-MM-DD, HH:mm'))
                  : setFieldValue([name], moment(date).format('YYYY-MM-DD, HH:mm'));
            }}
            dateFormat='MMMM d, yyyy HH:mm'
            customInput={<CalendarInputMain {...props}/>}
            autoComplete='off'
          />
          : <><DatePicker
            name={field}
            locale={i18n.language}
            selected={convertToMoment(value)}
            onChange= {!withoutFormik ? (date)=> {
              onChangeFormik(moment(date).format('YYYY-MM-DD'), name); submit();
            } : (date)=> {
              onChange ? onChange(moment(date).format('YYYY-MM-DD')) :
                dateFormat
                  ? setFieldValue(name, moment(date).format('YYYY-MM-DD'))
                  : setFieldValue([name], moment(date).format('YYYY-MM-DD'));
            }}
            dateFormat='dd.MM.yyyy'
            customInput={<CalendarInputMain {...props}/>}
            onCalendarClose={props.onCalendarClose}
            autoComplete='off'
            // startDate={props.startDate}
            // endDate={props.endDate}
            // minDate={props.minDate}
            // maxDate={props.maxDate}
            // selectsStart={props.selectsStartt}
            // selectsEnd={props.selectsEnd}
          />{err && err[name] && <small style={{ color: 'red', display: 'block', maxWidth: 140 }}>{err[name]}</small>}</>
      }
    </>
  );
};

export default compose(withName,
  branch(
    ({ withoutFormik }) => !withoutFormik,
    withFieldSubmit
  ))(Calendar);

const CalendarInputMain = styled.input`
  width: ${(props) => props.width ? props.width : '140px'};
  height: 36px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
  color: ${(props) => props.noActive ? 'initial' : 'initial'};
  text-align: left;
  padding-top: ${(props) => props.paddingTop ? props.paddingTop : '10px'};
  background: url(${icon_calendar}) no-repeat 3px 1px;
  background-size: 20px;
  background-position: 95%;
  background-color: ${(props) => props.noActive ? '#cdcdcd' : '#ffffff'};
  padding: 10px 0px 10px 10px;
  border: none;
  &:hover {
    cursor: ${({ noActive }) => noActive && 'not-allowed'};
}
`;

Calendar.propTypes = {
  value: PropTypes.string.isRequired, //Currend date
  title: PropTypes.string,
  field: PropTypes.string, // Formik field
  onChange: PropTypes.func, // (date) => '2018-10-10'. Choose date
  dateFormat: PropTypes.bool,
};

/*  Example
	<Calendar
    field='jornalDate'
    title='Create jornal'
    value={filter.date}
  />
*/
