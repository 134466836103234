import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/elements';

import { daysArray } from './CONST';

export default (props) => {
  const { weekArray, setWeekArray, t } = props;
  const setAllWeek = () => {
    if(weekArray.length === 7) {
      setWeekArray([]);
    }else{
      setWeekArray([...daysArray(t).map((e) => e.name)]);
    }
  };
  const mounthsCheckbox = daysArray(t).map(({ id, name }, index) => {
    return (<MonthCheckboxBlock width={'80px'} key={index}>
      <Checkbox
        value = {weekArray.includes(name)}
        title={name}
        onClick={() => {
          const arrCopy = weekArray;
          if(arrCopy.includes(name)) {
            arrCopy.splice(arrCopy.indexOf(name), 1);
            console.log(arrCopy);
          }else{
            arrCopy.push(name);
          }
          setWeekArray([...arrCopy]);
        } }
        margin={'10px 0 0 0 '}
      />
    </MonthCheckboxBlock>);
  });
  return(
    <>
     
      <MainCheckboxBlock>
        <AllMonthCheckboxBlock><Checkbox value = {weekArray.length === 7} title='All days' onClick={setAllWeek}/></AllMonthCheckboxBlock>
        {mounthsCheckbox}
      </MainCheckboxBlock>
    </>
  );
};

const MonthCheckboxBlock = styled.div`
    width: 140px;
    display: inline-block;
`;

const AllMonthCheckboxBlock = styled.div`
    width: 140px;
    margin: 0 0 0 15px;
`;

const MainCheckboxBlock = styled.div`
    padding: 0 20px 80px 0;
`;
