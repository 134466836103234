
import { Dropdown } from 'components/elements';
import React from 'react';
import { debounce } from 'lodash';

export default (props) => {
  const { index, columnIndex, id, setFieldValue, withoutFormik, defaultValueField, additionalFunc, name, isExpertMode } = props;

  const onFocusDropDown = (id) =>{
    document.getElementById(id).className && document.getElementById(id).firstChild.firstChild.childNodes[1].firstChild && document.getElementById(id).firstChild.firstChild.childNodes[1].firstChild.firstChild.focus();
  };
  const debouncedDropDown = debounce(onFocusDropDown, 1);

  const onKeyDownDrop = (e) =>{
    console.log(e);
    if(e.shiftKey) {
      document.getElementById(`${index}-${columnIndex - 1}`) && document.getElementById(`${index}-${columnIndex - 1}`).focus();
    } else if(e.keyCode === 9) {
      if(!isExpertMode && (columnIndex === 5)) {
        document.getElementById(`${index + 1}-${1}`) && document.getElementById(`${index + 1}-${1}`).focus();
      } else{
        document.getElementById(`${index}-${columnIndex + 1}`) && document.getElementById(`${index}-${columnIndex + 1}`).focus();
      }
    }else if(e.keyCode === 13) {
      document.getElementById(`${index + 1}-${columnIndex}`) && document.getElementById(`${index + 1}-${columnIndex}`).focus();
    }
    // else if(e.shiftKey && e.keyCode === 13) {
    //   document.getElementById(`${index - 1}-${columnIndex}`) && document.getElementById(`${index - 1}-${columnIndex}`).focus();
    // }
  };

  return (
    <div>
      <Dropdown
        key={id}
        id={id}
        valueField='id'
        labelField='name'
        onBlur={()=>{
          debouncedDropDown.cancel();
        }}
        withoutFormik={withoutFormik}
        onKeyDown = {(event)=>{
          onKeyDownDrop(event);
        }}
        defaultValueField = {defaultValueField}
        width={props.width || '220px'}
        setFieldValue={setFieldValue}
        name = {name}
        {...props}
        additionalFunc={()=>{
          additionalFunc && additionalFunc();
        }}/>
    </div>
  );
};

