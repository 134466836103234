import gql from 'graphql-tag';

export const listFA_fkArticleLists = gql`
query listFA_fkArticleLists($linkArticle: Int!) {
  fakturaAssist {
    listFA_fkArticleLists(linkArticle: $linkArticle) {
        id
        name
        checked
    }
  }
}`;

export const updateList = gql`
mutation updateList($id: ID!, $fields: fkArticleLists) {
  fakturaAssist {
    updateFA_fkArticleLists(id: $id, fields: $fields) {
      id
      name
    }
  }
}`;

export const createFA_fkArticleLists = gql`
mutation createFA_fkArticleLists($fields: fkArticleLists) {
  fakturaAssist {
    createFA_fkArticleLists(fields: $fields) {
      id
      name
      checked
    }
  }
}`;

export const deleteItem = gql`
mutation deleteItem($id: ID!) {
fakturaAssist {
    deleteFA_fkArticleLists(id: $id) {
        id
        name
      }
    }
}`;

export const createRealations = gql`
mutation createRealations($fields: fkArticleListsRelations!) {
  fakturaAssist {
    createFA_fkArticleListsRelations(fields: $fields) {
        id
        linkArticle
        linkArticleList
      }
    }
}`;

export const deleteRealations = gql`
mutation deleteRealations($fields: fkArticleListsRelations!) {
  fakturaAssist {
    deleteFA_fkArticleListsRelations(fields: $fields) {
        id
        linkArticle
        linkArticleList
      }
    }
}`;

