import React, { Fragment, useState } from 'react';
import { compose } from 'recompose';

import TableGrid from '../tableGrid/TableGrid';
import { tableData } from './tableParams';
import TableRow from './TableRow';
import { listFA_ActualTaxs } from './qql/query';

import { Modal } from 'components/elements';

import { useQuery } from 'components/graphql';

import withOperations from './hoc/withOperations';

import MinMaxModal from './fragments/modals/MinMaxModal';
import AdditionalModal from './fragments/modals/AdditionalModal';
import { orderBy } from 'lodash';

const TableWithProductOnDate = (props) =>{
  const { totalParams, deliveryInfo, date, minMaxInfo, setMinMaxInfo, height, t, localSortKey, notMutable } = props;
  const [isOpenAdditionalModal, changeOpenAdditionalModal] = useState(false);

  const vatInfo = useQuery(listFA_ActualTaxs, {
    variables: { args: { date: date } },
    fetchPolicy: 'cache-first',
  });
  const tableWidth = height || window.innerHeight - 520;

  const cellRenderer = ({ columnIndex, rowIndex, style, key, parent }, newData = []) => {
    return (
      <TableRow
        {...props}
        data={newData}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        key={newData[rowIndex] ? key + newData[rowIndex].productNr : key}
        style={style}
        topDrop={parent._styleCache[`${rowIndex - 4}-0`]}
        changeOpenAdditionalModal={changeOpenAdditionalModal}
        tableData={tableData({ t: props.t })}
        vatInfo ={vatInfo}
      />

    );
  };
  return (
    <Fragment>
      <Modal closeModal = {() => setMinMaxInfo({})} isOpen={!!Object.keys(minMaxInfo).length} headerText='Information' width='350px' height='220px' Component = { <MinMaxModal closeModal = {() => setMinMaxInfo({})}minMaxInfo={minMaxInfo}/> }/>
      <Modal closeModal = {() => changeOpenAdditionalModal(false)} isOpen={isOpenAdditionalModal} headerText={t('common.addition')} width='450px' height='680px' Component = { <AdditionalModal {...props} t={t} item={localSortKey
        ? orderBy(props.data || [], [localSortKey], ['asc'])[props.tableIndex]
        : props.data[props.tableIndex] || {}} closeModal = {() => changeOpenAdditionalModal(false)} /> }/>

      <TableGrid
        {...props}
        tableData={tableData({ deliveryInfo: deliveryInfo, t: props.t, totalParams: totalParams })}
        cellRenderer={cellRenderer}
        height={tableWidth}
        searchColumns={false}
        dataPolicy='local'
        addNewItem= {() => {!notMutable && props.onAdd();}}
        withUnderLine={props.isDelivery}
        overscanRowCount={10}
        withTotal
        disableHeightChange
        disableRows={props.disableRows}
        initScrollIndex={0}
      />
    </Fragment>
  );
};

export default compose(withOperations)(TableWithProductOnDate);
