import React from 'react';
import { Col } from 'style';

import { Checkbox } from 'components/elements';
import Formik from 'components/formik';

export default ({ t, configData, onSubmit }) => {
  return (
    <Formik initialValues={configData} enableReinitialize onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <Col lg ={4}>
            <Checkbox
              margin='20px 0 0 0'
              value={values.webOrderAutoPrint}
              field='webOrderAutoPrint'
              title={t('options.configurations.deliveryNoteAutomaticallyPrint')}
            />
          </Col>
        );
      }}
    </Formik>
  );
};
