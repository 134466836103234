
import styled from 'styled-components';
import React from 'react';
import { Row, Col } from 'style';
import { Button, Text } from 'components/elements';
import { useTranslation } from 'react-i18next';

export default (props) =>{
  const { onCloseModal, mutation } = props;
  const [t] = useTranslation();

  return (
    <HeadBlock>
      <Text text='Are u sure?'/>
      <Footer>
        <Col width='50%'>
          <div style={{ marginRight: '10px' }}>
            <Button
              float='right'
              className={'grey-btn'}
              width={'190px'}
              onClick={()=>onCloseModal(false)}
              value={t('common.cancel')}/>
          </div>
        </Col>
        <Col width='50%'>
                        
          <div style={{ marginLeft: '10px' }}>
            <Button
              float='left'
              className={'black'}
              width={'190px'}
              onClick={()=>{
                onCloseModal(mutation);
              }}
              value={t('common.save')}/>
          </div>
        </Col>
      </Footer>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  padding: 20px 20px 20px 20px;
  background: #f4f4f4;
`;
const Footer = styled(Row)`
  margin-top: 40px;
`;


