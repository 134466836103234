import gql from 'graphql-tag';

export const UPDATE_PRODUCT = gql`
mutation ($id: ID!, $fields: ProductField!){
    fakturaAssist{
    	updateProduct(id: $id, fields: $fields){
            id
            name
            productNumber
            designation
            isTitle
            isNetto
            isActivated
            linkGroup
            linkGroup2
            linkGroup3
            amount
            caLinkTempalte
            caVisibilityStatusID
            salesGroupId
            memo
            priceUnit
			taxChild
            EANNr
	 	}
 	}
}`;
