/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { Calendar, Input, Dropdown } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';
import ActionsList from 'components/actionsList/ActionsList';

import { CUSTOMER, PAYMENT, VIEW_FROM_PAYMENTS } from '../graphql/debitor';
import { PAYMENT_FIELD } from '../CONST';
import { useTranslation } from 'react-i18next';

const settingPayment = (t) => [
  { id: 1, name: t('common.filter.currentMonth'), label: 'currentMonth' },
  { id: 2, name: t('common.filter.lastMonth'), label: 'lastMonth' },
  { id: 3, name: t('common.filter.dateRange'), label: 'dateRange' },
  { id: 4, name: t('common.filter.invoiceDateRange'), label: 'invoiceDateRange' },
  { id: 5, name: t('common.filter.invoiceNumberRange'), label: 'number' },
  { id: 6, name: t('customer.customer'), label: 'customerId' },
];

export default (props) => {
  const { sendPDFIntoPrinter, showPDFPreview, sendPDFIntoEmail } = props;
  const [t] = useTranslation();
  const [date, setDate] = useState({
    dateStart: new Date(),
    dateEnd: new Date(),
  }); // ouput date calendat
  const [payment, setPayment] = useState(1); // UI
  const [range, setRange] = useState({
    numberRangeStart: 0,
    numberRangeEnd: 100,
  }); // UI
  const [type, setType] = useState({
    dateStart: null,
    dateEnd: null,
    numberRangeStart: null,
    numberRangeEnd: null,
    customerId: null,
    type: 'currentMonth',
  });
  const [idCustomer, setIdCustomer] = useState(); // UI
  const [pdfParams, setPDFParams] = useState({
    AInvoicesList: [], // 35
    APaymentsList: [], // 24
    AUserFormularID: '0',
    reportId: '24',
  }); // for report
  const [defaultValue, setDefaultValue] = useState({ form: 1, reminderText: 2 });

  // const { data } = useQuery(CUSTOMER); // work -> list customer // not use now
  // const { data: payments } = useQuery(PAYMENT, {
  //   variables: {
  //     args: type,
  //   },
  // });

  const getAllId = (ids) => {
    // get first value with Grid in moment mounting
    setPDFParams({ ...pdfParams, AInvoicesList: ids, APaymentsList: ids });
  };

  const setFieldValue = (name, value) => {
    const i = value - 1;
    setPayment(value); // ui
    setType({ ...type, type: settingPayment(t)[i].label });
  };

  const setFieldCustomer = (name, id) => {
    setIdCustomer(id);
    setType({ ...type, customerId: id });
  };

  const setFieldDate = (name, value) => {
    setDate({ ...date, [name]: value });
    setType({ ...type, [name]: value });
  };

  const setFieldRange = (name, value) => {
    setRange({ ...range, [name]: value });
  };

  const settingRender = () => {
    switch(payment) {
      case 1:
        return null;
      case 2:
        return null;
      case 3:
        return (<>
          <TextCalender>{t('common.selectionOfTimePeriod')}</TextCalender>
          <StyledCalendar withoutFormik={true} dateFormat value={date.dateStart} setFieldValue={setFieldDate} name='dateStart'/>
          <Calendar withoutFormik={true} dateFormat value={date.dateEnd} setFieldValue={setFieldDate} name='dateEnd'/>
        </>);
      case 4:
        return (<>
          <TextCalender>{t('common.selectionOfTimePeriod')}</TextCalender>
          <StyledCalendar withoutFormik={true} dateFormat value={date.dateStart} setFieldValue={setFieldDate} name='dateStart'/>
          <Calendar withoutFormik={true} dateFormat value={date.dateEnd} setFieldValue={setFieldDate} name='dateEnd'/>
        </>);
      case 5:
        return (<>
          <TextCalender>{t('common.filter.numberRange')}</TextCalender>
          <StyledInput withoutFormik={true} onChange={(e) => setFieldRange(e.target.name, e.target.value)} type='number' name='numberRangeStart' value={range.numberRangeStart}/>
          <Input withoutFormik={true} onChange={(e) => setFieldRange(e.target.name, e.target.value)} type='number' name='numberRangeEnd' value={range.numberRangeEnd}/>
        </>);
      case 6:
        return (
          <Dropdown
            width={'300px'}
            title={t('customer.customer')}
            withoutFormik={true}
            setFieldValue={setFieldCustomer}
            valueField = 'id'
            labelField = 'nameCustomer'
            name = 'customer'
            defaultValueField = {idCustomer}
            graphqlParams={{ query: CUSTOMER }}
          />);
      default:
        return null;
    }
  };

  return (
    <StDiv>
      <Row>
        <Title lg={2}>
				  {t('debitor.payments')}
        </Title>
        <ActionBlock lg={1}>
          <ActionsList
            t={t}
            text={t('common.actions')}
            pdfParams={pdfParams}
            customActions={true}
            actions={{
              printReport: () => {
                sendPDFIntoPrinter(pdfParams);
              },
              preview: (actualPdfParams) => {
                showPDFPreview(actualPdfParams || pdfParams);
              },
              email: () => {
                sendPDFIntoEmail(pdfParams);
              },
            }}
          />
        </ActionBlock>
      </Row>
      <StyledHeader>
        <StyledHeaderCol lg={2}>
          <Dropdown
            width={'300px'}
            withoutFormik={true}
            title={t('common.type')}
            setFieldValue={setFieldValue}
            valueField = 'id'
            labelField = 'name'
            name = 'settingPayment'
            defaultValueField = {payment}
            data={settingPayment(t)}
          />
        </StyledHeaderCol>
        <StyledHeaderCol lg={2}>
          <Dropdown
            title={t('common.openReportForm')}
            labelField='form'
            valueField='reportId'
            defaultValueField={defaultValue.form}
            isFirstValue={true}
            graphqlParams={{ query: VIEW_FROM_PAYMENTS, variables: {
              languageId: +localStorage.getItem('lngId') || 1,
            } }}
            onChange={(value) => {
              setDefaultValue({ ...defaultValue, form: value.value });
              setPDFParams({ ...pdfParams, reportId: `${value.value / 1000000}` });
            }}
          />
        </StyledHeaderCol>
        <StyledHeaderCol lg={3}>
          {settingRender()}
        </StyledHeaderCol>
      </StyledHeader>
      <StyledRowContent>
        <Col>
          <Title>{t('common.result')}</Title>
          <TableGrid
            graphqlParams={{
              query: PAYMENT,
              variables: { args: type },
            }}
            tableData={PAYMENT_FIELD(t)}
            height={500}

            getAllId={getAllId}

            withSearch
            withTotal
          />
        </Col>
      </StyledRowContent>
    </StDiv>
  );
};

const ActionBlock = styled(Col)`
  float: right;
  margin-top: 8px;
`;

const StDiv = styled.div`
  margin: 0 20px;
`;

const StyledHeader = styled(Row)`
	border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  border-top: 1px solid #3e4c57;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
`;

const StyledHeaderCol = styled(Col)`
	padding: 10px 0;
  margin-right: 20px;
`;

const Title = styled(Col)`
  margin: 12px 0 2px 0;
  /* font-family: Roboto; */
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
`;

const TextCalender = styled.p`
margin: 10px 0 0 0;
	/* font-family: Roboto; */
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #686868;
`;

const StyledRowContent = styled(Row)`
margin-top: 10px;
padding-bottom: 20px;
border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;

const StyledCalendar = styled(Calendar)`
  margin: 10px 0px;
`;

const StyledInput = styled(Input)`
  margin: 10px 0;
`;

// import React, {Component} from 'react';
// import {Col, Row} from 'react-bootstrap';
// import {Query} from 'react-apollo';
// import styled from 'styled-components';
// import {format, addMonths, lastDayOfMonth, startOfMonth} from 'date-fns';

// import {REPORT, REPORT_DEFAULT, KEYS, TABLE} from './CONST';
// import {DataTable, PaymentsCustomer} from '../graphql/Payments';

// import Line from '../../../components/simple/Line';
// import Input from '../../../components/joint/Input.js';
// import Dropdown from '../../../components/simple/dropdown/Dropdown';
// import Calendar from '../../../components/container/calendar/Calendar';
// import Button from '../../../components/simple/Button';
// import TableGrid from '../../../components/container/tableGrid/TableGrid';

// function DateRange(props) {
// 	return (
// 		<React.Fragment>
// 			<BlockCalendar lg={3}>
// 				<Calendar
// 					text='Invoices from'
// 					getDate={props.onChangeDateFrom}
// 					width='200px'
// 				/>
// 			</BlockCalendar>
// 			<BlockCalendar lg={3}>
// 				<Calendar
// 					text='Invoices till'
// 					getDate={props.onChangeDateTill}
// 					width='200px'
// 				/>
// 			</BlockCalendar>
// 		</React.Fragment>
// 	);
// }

// function Invoice(props) {
// 	return (
// 		<StyleColInvoice lg={6}>
// 			<Col lg={3}><Input text="From" width="140px" name="from" marginTop={'3px'} topLoading={'35px'} value={props.min} parseInt onChangeValue={props.onChangeValue.bind(this, 'min')}/></Col>
// 			<Col lg={3}><Input text="to" width="140px" name="to" marginTop={'3px'} topLoading={'35px'} value={props.max} parseInt onChangeValue={props.onChangeValue.bind(this, 'max')}/></Col>
// 			<Col lg={4}><StyleButton value="SEARCH" width="250px" onClick={props.onConfirm}/></Col>
// 		</StyleColInvoice>
// 	);
// }

// export default class Payment extends Component {
// 	state = {};

// 	handleChange = (e, {value}) => this.setState({value});
// 	resetThenSet = (id) => {
// 		const start = format(startOfMonth(new Date()), 'YYYY-MM-DD');
// 		const final = format(lastDayOfMonth(new Date()), 'YYYY-MM-DD');
// 		const startLastMonth = addMonths(start, -1);
// 		const finalLastMonth = addMonths(final, -1);
// 		const i = id -1;
// 		if (id === 1) {
// 			this.setState({
// 				start,
// 				final,
// 			});
// 		} else if (id === 2) {
// 			this.setState({
// 				startLastMonth,
// 				finalLastMonth,
// 			});
// 		}
// 		this.setState({
// 			id,
// 			activeName: REPORT[i].title,
// 		});
// 	};
// 	resetThenSetCustomer = (idCustomer) => {
// 		this.setState({idCustomer});
// 		console.log(idCustomer);
// 	};
// 	onChangeDateFrom = (date) => {
// 		this.setState({start: date});
// 	};
// 	onChangeDateTill = (date) => {
// 		this.setState({final: date});
// 	};
// 	getTableIndex = (id) => console.log(id);
// 	onChangeValue = (name, value) => {
// 		this.setState({[name]: value});
// 		console.log(this.state);
// 	};
// 	ConfirmRange = () => this.setState({minValue: this.state.min, maxValue: this.state.max});

// 	render() {
// 		const {id, activeName, start, final, startLastMonth, finalLastMonth, min, max, minValue, maxValue, idCustomer} = this.state;
// 		console.log(this.state);
// 		return (
// 			<Row>
// 				<Row>
// 					<Col lg={3}>
// 						<Dropdown
// 							list={REPORT}
// 							defaultValue={activeName}
// 							title='Open report Form'
// 							activeId={this.resetThenSet}
// 							nameKey={KEYS}
// 							marginLeft='20px'
// 							marginTop='7px'
// 							width={'200px'}
// 						/>
// 					</Col>
// 					<Col lg={3}>
// 						<Dropdown
// 							title='Chose Report'
// 							activeId={this.resetThenSet}
// 							marginTop='7px'
// 							marginLeft={'-70px'}
// 							width={'200px'}
// 						/>
// 					</Col>
// 					{id === null
// 						? null
// 						: id === 1
// 							? null
// 							: id === 2
// 								? null
// 								: id === 3
// 									? <DateRange
// 										onChangeDateFrom={this.onChangeDateFrom}
// 										onChangeDateTill={this.onChangeDateTill}
// 										dateFrom={start}
// 										dateTill={final}/>
// 									: id === 4
// 										? <DateRange
// 											onChangeDateFrom={this.onChangeDateFrom}
// 											onChangeDateTill={this.onChangeDateTill}
// 											dateFrom={start}
// 											dateTill={final}/>
// 										: id === 5
// 											? <Invoice
// 												onChangeValue={this.onChangeValue}
// 												onConfirm={this.ConfirmRange}
// 												min={min} max={max}/>
// 											: id === 6
// 												? <Query
// 													query={PaymentsCustomer}
// 													fetchPolicy = 'network-only'
// 													onError = {() => console.log('ups..error in debitor/payment/customer')}
// 													displayName = {'payment'}>
// 													{({loading, error, networkStatus, data}) => {
// 														if (networkStatus === 4) return 'Refetching...';
// 														if (error) return `ErrorQuery: ${error}`;
// 														console.log(data);
// 														return (
// 															<Col lg={3}>
// 																<Dropdown
// 																	list={data.allDebitorPaymentsCustomer}
// 																	title='Selection customer'
// 																	marginLeft='-160px'
// 																	marginTop='7px'
// 																	width={'300px'}
// 																	nameKey={['id', 'name']}
// 																	activeId={this.resetThenSetCustomer}
// 																/>
// 															</Col>
// 														);
// 													}}
// 												</Query>
// 												: null}
// 				</Row>
// 				<Line/>
// 				<Query
// 					query={DataTable}
// 					variables={{
// 						option: id,
// 						start,
// 						final,
// 						minValue,
// 						maxValue,
// 						idCustomer,
// 					}}
// 					fetchPolicy = 'network-only'>
// 					{({error, data, networkStatus, loading, refetch}) => {
// 						if (networkStatus === 4) return 'Refetching';
// 						if (error) return 'Errors';
// 						if (loading) return 'loading';
// 						console.log(data);
// 						return (
// 							<TableGrid
// 								data={data.allDebitorPaymentsData}
// 								tableData={TABLE}
// 								getTableIndex={this.getTableIndex} />
// 						);
// 					}}
// 				</Query>
// 			</Row>
// 		);
// 	}
// }

// const StyleButton = styled(Button)`
// 	margin-top: 20px;
// `;

// const StyleColInvoice = styled(Col)`
// 	margin-left: -160px;
// `;

// const BlockCalendar = styled(Col)`
// 	margin-left: -40px;
// `;

// const BlockSettingsIco = styled.img`
// 	float: right;
// 	margin-top: 35px;
// 	margin-right: 20px;
// 	width: 34px;
// 	height: 34px;
// 	box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.2);
// 	background-color: #f5f5f5;
// `;
