import gql from 'graphql-tag';
//Used in information block on top
export default gql`
mutation ($params: FA_ReportSystemInput!){
    fakturaAssist{
    	callFA_ReportSystem(params: $params){
	    	error
		    _taskids
		    link
	 	}
 	}
}`;
