import gql from 'graphql-tag';

export const updateFilterArticle = gql`
mutation updateFilterArticle($id: ID!, $arr: String){
    fakturaAssist{
    updateFilterArticle(id: $id, arr: $arr){
		isPrint
    }
    
  }
}`;

export const updateFilterCustomer = gql`
mutation updateFilterCustomer($id: ID!, $arr: String){
    fakturaAssist{
    updateFilterCustomer(id: $id, arr: $arr){
		isPrint
    }
    
  }
}`;

export const updateFilterOrder = gql`
mutation updateFilterOrder($id: ID!, $arr: String){
    fakturaAssist{
    updateFilterOrder(id: $id, arr: $arr){
		isPrint
    }
    
  }
}`;
