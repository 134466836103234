import gql from 'graphql-tag';

export default gql`
query {
    fakturaAssist{
    	getFA_Config{
    		id
    		customerNr
			orderGroup
 		}
 	}
}`;
