import gql from 'graphql-tag';

export default gql`
mutation exportDAT_FILES_Toolbox($date: String, $isAuto: Boolean){
    fakturaAssist{
    exportDAT_FILES_Toolbox(date: $date, isAuto:$isAuto){
		file1
		file2
		file3
		file4
		file5
		file6
		file9
    }
    
  }
}`;