
import gql from 'graphql-tag';

export default gql`
query listFA_ProductionArticles{
    fakturaAssist{
    listFA_ProductionArticles{
		id
		articleNo
		description
    }
    
  }
}`;
