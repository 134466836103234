import gql from 'graphql-tag';

export default gql`
query listFA_PostSectors{
    fakturaAssist{
    listFA_PostSectors{
		id
		name
    }
    
  }
}`;
