import React from 'react';

import useQuerySpecial from '../../../graphql/useQuerySpecial';

export default (WrappedComponent) => (props) =>{
  const { getQuery, defaultValueField } = props;
  const getQueryProps = useQuerySpecial(getQuery, { variables: props.singleQueryVariables || { id: defaultValueField, skip: !getQuery } });
  return <WrappedComponent {...props} getQueryData={getQueryProps.data} getQueryLoading={getQueryProps.loading}/>;
};

