import gql from 'graphql-tag';

export default gql`
query getCountOrderForPre_Production($onDate: String!){
    fakturaAssist{
	    getCountOrderForPre_Production(onDate: $onDate){
			count
		}
  	}
}`;
