
import gql from 'graphql-tag';

export default gql`
mutation updateFA_CustomerListsDefault($id: ID!, $fields: CustomerListsDefaultFields!){
    fakturaAssist{
    updateFA_CustomerListsDefault(id: $id, fields: $fields){
		id
		name
		isValue
		customerListsRelationID
		linkCustomer
    }
    
  }
}`;
