import gql from 'graphql-tag';

export default gql`
query listFA_TaxInclusionKinds($languageId: Int){
    fakturaAssist{
    listFA_TaxInclusionKinds(sample: [1,2,3], languageId: $languageId){
      id
      name
    }  
  }
}`;
