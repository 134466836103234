import { useLazyQuery as useLazyQueryOriginal } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { fetchPolicy as globalFetchPolicy } from 'config/graphql';
import { transformData } from '../utils';

const useQuery = (query, options) => {
  const noQuery = !query;
  const [callQuery, { data, loading, ...rest }] = useLazyQueryOriginal(noQuery ? gql`query{_}` : query, {
    skip: noQuery,
    fetchPolicy: globalFetchPolicy,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    ...options,
  });
  const { data: transformedData, queryField, ...dataInfo } = transformData(data);

  if(noQuery) {
    return [callQuery, {}];
  }

  return [callQuery, {
    ...rest,
    ...dataInfo,
    data: loading ? [] : transformedData[queryField],
    hasData: !!Object.keys(transformedData).length,
  }];
};

export default useQuery;
