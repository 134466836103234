export const tableData = (t) => [
  {
    name: t('common.articleNo'),
    dataBase: 'productNm',
    isRequired: true,
  },
  {
    name: t('common.description'),
    dataBase: 'description',
  },
  {
    name: t('common.price'),
    dataBase: 'price',
    dataType: 'float',
    isMutation: true,
    isRequired: true,

  },
  {
    name: t('common.note'),
    dataBase: 'note',
    isMutation: true,
  },
];
