import React, { Fragment, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Dropdown, Calendar, Input } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';
import Formik from 'components/formik';
import useQuerySpecial from 'components/graphql/useQuerySpecial';

import { taxTableParams } from './params';

import { listFA_TaxInclusionKinds, listFA_TaxList, listFA_TaxOnDate } from '../../qql/query';

const Tax = (props) => {
  const { t, configData: { countryId } } = props;
  const [fromDate, setFromDate] = useState(new Date());
  const taxInfo = useQuerySpecial(listFA_TaxOnDate, {
    variables: {
      args: {
        date: fromDate,
        countryID: +countryId,
      },
    },
  });
  return (
    <Formik initialValues={taxInfo.data} enableReinitialize onSubmit={() =>{}}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <Fragment>
            <Row>
              <Col lg={3}>
                <TableContent>
                  <TableGrid
                    tableData={taxTableParams(t)}
                    graphqlParams={{ query: listFA_TaxList }}
                    height={720}
                    disableHeightChange
                    data={[{ test: 'test' }, { test: 'test' }, { test: 'test' }]}
                    getTableIndex = {(_, item) => setFromDate(item.fromDate)}
                  />
                </TableContent>
              </Col>
              <Col lg={9}>
                <Content>
                  <Dropdown
                    title={t('options.configurations.tax.taxSystem')}
                    field = 'roundingTotalPriceMethodID'
                    defaultValueField = {1}
                    graphqlParams={{ query: listFA_TaxInclusionKinds }}
                    
                  />
                  <Row>
                    <Calendar title={t('common.fromDate')} value={new Date(fromDate)}/>
                  </Row>
                  <Row>
                    <Input title={t('options.configurations.tax.taxNormalRate')} field='invoiceNumber' value={values.taxNormal}/>
                  </Row>
                  <Row>
                    <Input title={t('options.configurations.tax.taxReducedRate')} field='invoiceNumber' value={values.taxReduced}/>
                  </Row>
                  <Row>
                    <Input title={t('options.configurations.tax.taxSpecial') + ' 1'} field='invoiceNumber' value={values.taxSpecial1}/>
                  </Row>
                  <Row>
                    <Input title={t('options.configurations.tax.taxSpecial') + ' 2'} field='invoiceNumber' value={values.taxSpecial2}/>
                  </Row>
                  <Row>
                    <Input title={t('options.configurations.tax.tax') + ' 0%'} field='invoiceNumber' value={values.taxSpecial0}/>
                  </Row>
                </Content>
              </Col>
        
            </Row>
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default Tax;

const Content = styled.div`
  padding: 10px 20px 20px 20px; 
  background: #f4f4f4;
  margin: 20px;
  min-height: 755px;
`;

const TableContent = styled.div`
  margin-top: 20px
`;
