/* eslint-disable */
import React, { Component, useMemo, useState } from 'react';
import styled from 'styled-components';
import { cloneDeep, orderBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import MutationHoc from '../graphql/MutationHoc';
import icon_edit from '../../style/edit.svg';
import IMG_delete from '../../style/img/delete.svg';
import DELETE_HOVER from '../../style/img/actions/delete_hover.svg';
import DELETE_STANBY from '../../style/img/actions/delete_stanby.svg';
import EDIT_HOVER from '../../style/img/actions/edit_hover.svg';
import EDIT_STANBY from '../../style/img/actions/edit_stanby.svg';

import OperationIcons from './components/operationIcons/OperationIcons.js';

import Radio from './components/TableCheckbox.js';
import { Modal, Button, Checkbox } from 'components/elements';
import { Row, Col } from 'style';
import currencies from '../../config/currencies'

const TableRow = (props) => {

  const [isOpenConfirmModal, setConfirmModal] = useState(false);
  const [t] = useTranslation();

  const renderSwitch = (props, specialTableIndex) => {
    const {
      columnIndex,
      data,
      rowIndex,
      tableData,
      rowColor,
      tableIndex,
      operations,
      withoutBorderColumn,
      isBorderTopRow,
      addItemToSelected,
      isSpacePressed,
      clearSelectedItems,
      onClick,
      leftGridColors,
      leftGridScrollMode,
      client,
      contex,
    } = props;

    const item = data[rowIndex];

    const filterColumn = contex?.filter?.[0]?.column;

    let specialColor = useMemo(() => {
      if (leftGridColors) {
        const gridKeys = Object.keys(leftGridColors);
        for (let i = 0; i < gridKeys.length; i++) {
          let isExist = item[gridKeys[i]];
          if (typeof leftGridColors[gridKeys[i]] === 'function') {
            isExist = leftGridColors[gridKeys[i]]({ ...item, filterColumn });
            if (isExist) {
              return isExist;
            }
          }
          if (isExist) {
            return leftGridColors[gridKeys[i]];
          }
        }
      }
      return null;
    }, [item]);


    const { dataBase, substring, specialComponent, isDate, dataType, textAlign, isShortText } = tableData[columnIndex];

    const checkDataType = (value) => {
      switch (dataType) {
        case 'date':
          return value ? moment(value).format('DD.MM.YYYY') : '-'
        case 'dateTime':
          return value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : '-'
        case 'float':
        case 'money':
          return typeof value === 'number' ? Number(value).toFixed(2) : '-'
        case 'percent':
          return value ? typeof value === 'number' ? value + '%' : value : '-'
        // case 'money':
        //   return value > -1 && item.currencyId ? `${Number(value).toFixed(2)} ${currencies[item.currencyId]}`  : value
        default:
          return value
      }
    }

    const textPlagination = useMemo(() => {
      const getItemValue = () => {
        const dataBaseArray = dataBase.split('.');
        if (item) {
          if (dataBaseArray.length === 1) {
            return checkDataType(item[dataBase]);
          } else {
            return checkDataType(item[dataBaseArray[0]][dataBaseArray[1]]);
          }
        } else {
          return null;
        }
      };
      if (dataBase) {
        const currentValue = getItemValue();
        // console.log('CURRENT ITEM', item[dataBase])
        if (currentValue !== null && currentValue !== undefined) {
          if (item.dataType === 'date') {
            return moment(item[dataBase]).format('DD.MM.YYYY')
          } else if (typeof item[dataBase] === 'boolean') {
            return <Checkbox value={item[dataBase]} style={{ margin: 0 }} />
          } else if (substring) {
            return item[dataBase] && item[dataBase].substring(0, substring);
          } else {
            return getItemValue();
          }
        } else {
          //console.log('NULL ITEM', item)
          return '-';
        }
      } else {
        return 'Add database';
      }
    }, [data]);

    // console.log(leftGridColors, tableData)
    // /document.getElementById(`${props.rowIndex}-ux`).offsetParent.style.top
    // console.log({ leftGridColors })
    return (
      <TableBlock
        id={`${props.rowIndex}-ux`}
        rowIndex={props.rowIndex}
        textAlign={textAlign ? textAlign : dataType === 'float' || dataType === 'number' || dataType === 'percent' || dataType === 'int' ? 'right' : 'left'}
        heightRow={props.heightRow}
        colorBlock={specialColor ? specialColor : props.isSelected ? '#e8f5ff' : tableData[columnIndex].colorBlock || (tableData[columnIndex].colorBlockFunc && tableData[columnIndex].colorBlockFunc(data, rowIndex))}
        isFirst={columnIndex === 0}
        rowColor={specialColor || rowColor}
        isBorderTopRow={isBorderTopRow}
        onClick={() => {
          // console.log('WAS CLICKED', rowIndex, data[rowIndex])
          onClick && onClick(rowIndex, data[rowIndex])
          if (!isSpacePressed || !addItemToSelected) {
            clearSelectedItems && clearSelectedItems();
            props.changeTableIndex(rowIndex, data[rowIndex]);
          }
          else addItemToSelected(data[rowIndex].id);
        }}
        isActive={
          specialTableIndex || specialTableIndex === 0
            ? specialTableIndex === rowIndex
            : rowIndex === tableIndex
        }
        isShortText={isShortText}
      >
        <TextPlagination
          isFirst={columnIndex === 0}
          withoutBorderColumn={withoutBorderColumn}
          colorBlock={tableData[columnIndex].colorBlock || (tableData[columnIndex].colorBlockFunc && tableData[columnIndex].colorBlockFunc(data, rowIndex))}
          title={textPlagination}
          isShortText={isShortText}
          leftGridScrollMode={leftGridScrollMode}
        >
          {specialComponent ? (
            specialComponent(rowIndex, columnIndex, textPlagination != '-' && textPlagination, data, data[rowIndex], props.sortObject, client)
          ) : (
              textPlagination
            )}
        </TextPlagination>
      </TableBlock>
    );
  };

  const checkAdditionOptionsStatus = (lenghtRows) => {
    const additionOption = <OperationIcons {...props} />;
    return additionOption;
  }

  const {
    changeTableIndex,
    style,
    editItem,
    rowIndex,
    columnIndex,
    tableIndex,
    data,
    getBlockColor,
    itemDeleted,
    rowActiveColor,
    selectById,
    linkId,
    withCheckbox,
    checkboxList,
    changeCheckboxList,
    tableData,
    changeIsUpdate,
    borderStyle,
    setTableUpdate,
    preventDeletionForId,
    withoutLeftTableBlock,
    operations,
    specialSelectField,
    onDelete,
    getCheckboxList,
    withoutIdForDelete,
    withoutIdForUpdate,
    actionsToLeft,
    confirmDelete = true,
    sortObject
  } = props;
  const lenghtRows = 0;
  let specialTableIndex = props.tablePosition;

  const isActive = specialTableIndex || specialTableIndex === 0
    ? specialTableIndex === rowIndex
    : rowIndex === tableIndex;

  const ConfirmModal = ({ closeModal, onConfirm }) => (
    <ModalBody>
      <p>
        {t('common.confirmDelete')}
      </p>
      <BottonBlock>
        <Col lg={5}>
          <Button value={t('common.no')} className='black' upperCase onClick={closeModal} />
        </Col>
        <Col lg={5}>
          <Button value={t('common.ok')} className='grey' upperCase onClick={onConfirm} />
        </Col>
      </BottonBlock>
    </ModalBody>
  );
  return (
    <div>
      <div
        style={style}
      >
        {columnIndex === 0 && !withoutLeftTableBlock && (
          <LeftTableBlock
            heightRow={props.heightRow}
            borderStyle={borderStyle}
            rowActiveColor={rowActiveColor}
            rowIndex={rowIndex}
            getBlockColor={
              getBlockColor && getBlockColor.bind(null, data[rowIndex])
            }
            isSelected={props.isSelected}
            isActive={
              specialTableIndex || specialTableIndex === 0
                ? specialTableIndex === rowIndex
                : rowIndex === tableIndex
            }
          />
        )}
        {withCheckbox && columnIndex === 0 && <CheckboxPos> <Radio checked={checkboxList[rowIndex]} onClick={(() => {
          const checkboxListCopy = cloneDeep(checkboxList);
          checkboxListCopy[rowIndex] = !checkboxListCopy[rowIndex];
          changeCheckboxList(checkboxListCopy);
          getCheckboxList(checkboxListCopy);
        })} submit={(() => { })} /> </CheckboxPos>}
        {itemDeleted && columnIndex === 0 && actionsToLeft
          ? (
            <React.Fragment>
              <MutationHoc mutation={props.itemDeleted}>
                {({ mutation, result, status, client }) => {
                  const { query, variables } = props.graphqlParams;
                  const queryInfo = {
                    query,
                    variables,
                  };
                  const queryValue = query.definitions[0].name.value;
                  const cacheChange = () => {
                    try {
                      const data = client.readQuery({
                        ...queryInfo,
                      });
                      let cacheData = cloneDeep(data);
                      if (sortObject && sortObject.column && sortObject.order) {
                        cacheData.fakturaAssist[queryValue] = orderBy(cacheData.fakturaAssist[queryValue], [sortObject.column], [sortObject.order])
                      } else {
                        cacheData.fakturaAssist[queryValue] = cloneDeep(props.data)
                      }
                      // console.log(cacheData[rowIndex], rowIndex)
                      cacheData.fakturaAssist[queryValue].splice(rowIndex, 1);
                      client.writeQuery({
                        ...queryInfo,
                        data: {
                          fakturaAssist: {
                            [queryValue]: cacheData.fakturaAssist[queryValue],
                            __typename: 'FA_Query',
                          },
                        },
                      });
                    } catch (err) {
                      console.log(err);
                    }
                  };
                  const onDelete = async () => {
                    try {
                      // console.log('DELETED ITEM', rowIndex, props.data[rowIndex])
                      await mutation({ variables: { id: props.data[rowIndex].id } });
                      cacheChange();
                      props.addFuncDelete && props.addFuncDelete();
                      // closeModal && closeModal();
                      setConfirmModal(false);
                    } catch (err) {
                      console.log(err);
                    }
                  };
                  return (
                    <React.Fragment>
                      <Modal
                        isOpen={isOpenConfirmModal}
                        closeModal={() => setConfirmModal(false)}
                        headerText={t('common.confirm')}
                        width='450px' height='220px'
                        Component={<ConfirmModal closeModal={() => setConfirmModal(false)} onConfirm={onDelete} />}
                      />

                      {(!preventDeletionForId || (preventDeletionForId !== data[rowIndex].id && data[rowIndex].id)) && <DeletedAction onClick={confirmDelete ? () => setConfirmModal(true) : onDelete} />}
                      {
                        withoutIdForUpdate == 'all'
                          ?
                          <div />
                          :
                          <UpdatedAction onClick={() => {
                            editItem(rowIndex, data); setTableUpdate && setTableUpdate(true);
                          }} />
                      }

                    </React.Fragment>
                  );
                }}

              </MutationHoc>


            </React.Fragment>

          )
          //: null}
          //{isActive && actions && checkAdditionOptionsStatus(lenghtRows)}

          : itemDeleted && columnIndex === (tableData.length - 1) && !actionsToLeft
            ? (
              <React.Fragment>
                <MutationHoc mutation={props.itemDeleted}>
                  {({ mutation, result, status, client }) => {
                    const { query, variables } = props.graphqlParams;
                    const queryInfo = {
                      query,
                      variables,
                    };
                    const queryValue = query.definitions[0].name.value;
                    const cacheChange = () => {
                      try {
                        const data = client.readQuery({
                          ...queryInfo,
                        });
                        let cacheData = cloneDeep(data);
                        if (sortObject && sortObject.column && sortObject.order) {
                          cacheData.fakturaAssist[queryValue] = orderBy(cacheData.fakturaAssist[queryValue], [sortObject.column], [sortObject.order?.toLowerCase()])
                        } else {
                          cacheData.fakturaAssist[queryValue] = cloneDeep(props.data)
                        }
                        // console.log(cacheData[rowIndex], rowIndex)
                        cacheData.fakturaAssist[queryValue].splice(rowIndex, 1);
                        client.writeQuery({
                          ...queryInfo,
                          data: {
                            fakturaAssist: {
                              [queryValue]: cacheData.fakturaAssist[queryValue],
                              __typename: 'FA_Query',
                            },
                          },
                        });
                      } catch (err) {
                        console.log(err);
                      }
                    };
                    const onDelete = async () => {
                      try {
                        // console.log('DELETED ITEM', rowIndex, props.data[rowIndex])
                        await mutation({ variables: { id: props.data[rowIndex].id } });
                        cacheChange();
                        props.addFuncDelete && props.addFuncDelete();
                        // closeModal && closeModal();
                        setConfirmModal(false);
                      } catch (err) {
                        console.log(err);
                      }
                    };
                    return (
                      <React.Fragment>
                        <Modal
                          isOpen={isOpenConfirmModal}
                          closeModal={() => setConfirmModal(false)}
                          headerText={t('common.confirm')}
                          width='450px' height='220px'
                          Component={<ConfirmModal closeModal={() => setConfirmModal(false)} onConfirm={onDelete} />}
                        />

                        {(!preventDeletionForId || (preventDeletionForId !== data[rowIndex].id && data[rowIndex].id)) && <DeletedAction onClick={confirmDelete ? () => setConfirmModal(true) : onDelete} />}
                        {
                          withoutIdForUpdate == 'all'
                            ?
                            <div />
                            :
                            <UpdatedAction onClick={() => {
                              editItem(rowIndex, data); setTableUpdate && setTableUpdate(true);
                            }} />
                        }

                      </React.Fragment>
                    );
                  }}

                </MutationHoc>


              </React.Fragment>

            )
            //: null}
            //{isActive && actions && checkAdditionOptionsStatus(lenghtRows)}
            :
            (onDelete && columnIndex === tableData.length - 1 && !actionsToLeft) ?
              <React.Fragment>
                {
                  withoutIdForDelete
                    ?
                    (withoutIdForDelete.includes(data[rowIndex].id) || withoutIdForDelete == 'all')
                      ?
                      <div />
                      : (
                        <>
                          <Modal
                            isOpen={isOpenConfirmModal}
                            closeModal={() => setConfirmModal(false)}
                            headerText={t('common.confirm')}
                            width='450px' height='220px'
                            Component={<ConfirmModal closeModal={() => setConfirmModal(false)} onConfirm={() => onDelete(rowIndex, data[rowIndex])} />}
                          />
                          <DeletedAction onClick={() => setConfirmModal(true)} />
                        </>
                      )
                    :
                    <DeletedAction onClick={() => onDelete(rowIndex, data[rowIndex])} />}
                {
                  withoutIdForUpdate
                    ?
                    (withoutIdForUpdate.includes(data[rowIndex].id) || withoutIdForUpdate == 'all')
                      ?
                      <div />
                      :
                      <UpdatedAction onClick={() => {
                        editItem(rowIndex, data);
                      }} />
                    :
                    <UpdatedAction onClick={() => {
                      editItem(rowIndex, data);
                    }} />
                }
              </React.Fragment> : (onDelete && columnIndex === 0 && actionsToLeft) &&
              <React.Fragment>
                {
                  withoutIdForDelete
                    ?
                    (withoutIdForDelete.includes(data[rowIndex].id) || withoutIdForDelete == 'all')
                      ?
                      <div />
                      : (
                        <>
                          <Modal
                            isOpen={isOpenConfirmModal}
                            closeModal={() => setConfirmModal(false)}
                            headerText={t('common.confirm')}
                            width='450px' height='220px'
                            Component={<ConfirmModal closeModal={() => setConfirmModal(false)} onConfirm={() => onDelete(rowIndex, data[rowIndex])} />}
                          />
                          <DeletedActionLeft onClick={() => setConfirmModal(true)} />
                        </>
                      )
                    :
                    <DeletedActionLeft onClick={() => onDelete(rowIndex, data[rowIndex])} />}
                {
                  withoutIdForUpdate
                    ?
                    (withoutIdForUpdate.includes(data[rowIndex].id) || withoutIdForUpdate == 'all')
                      ?
                      <div />
                      :
                      <UpdatedActionLeft onClick={() => {
                        editItem(rowIndex, data);
                      }} />
                    :
                    <UpdatedActionLeft onClick={() => {
                      editItem(rowIndex, data);
                    }} />
                }
              </React.Fragment>}
        {isActive && columnIndex === tableData.length - 1 && operations && checkAdditionOptionsStatus(lenghtRows)}
        {renderSwitch(props, specialTableIndex)}
      </div>
      <TotalBlock />
    </div>
  )
}

export default TableRow;

const getBlockColor = ({
  isActive,
  getBlockColor,
  rowActiveColor = '#3c445a',
  isSelected,
  rowIndex
}) => {
  const checkBlockColor = getBlockColor ? getBlockColor() : null;
  if (isSelected) return '#e8f5ff';
  if (isActive) {
    if (getBlockColor && checkBlockColor) {
      return checkBlockColor;
    } else {
      return rowActiveColor;
    }
  } else if (getBlockColor && checkBlockColor) {
    return checkBlockColor;
  } else {
    if (rowIndex % 2) {
      return '#E6E6E6';
    }
    return '#cdcdcd';
  }
};

export const LeftTableBlock = styled.div`
  width: 5px;
  height: ${(props) => (props.heightRow ? (props.heightRow - 1) + 'px' : '39px')};
  background-color: ${(props) => getBlockColor(props)};
  z-index: 1000;
  position: absolute;
  ${({ borderStyle }) => {
    if (borderStyle === 'circle') {
      return (`
      width: 4px;
      height: 4px;
      border-radius: 2.5px;
      margin: 16px 0 0 8px;
      display: inherit;`);
    }
  }}
`;

export const UpdatedAction = styled.div`
  margin-top: 7px;
  text-align: center;
  width: 25px;
  height: 25px;
  z-index: 1000;
  position: absolute;
  right: 40px;
  cursor: pointer;
  background-image: url(${EDIT_STANBY});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${EDIT_HOVER});
  }
`;

export const UpdatedActionLeft = styled.div`
  margin-top: 7px;
  text-align: center;
  width: 25px;
  height: 25px;
  z-index: 1000;
  position: absolute;
  left: 10px;
  cursor: pointer;
  background-image: url(${EDIT_STANBY});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${EDIT_HOVER});
  }
`;

export const DeletedAction = styled.div`
  margin-top: 7px;
  width: 25px;
  height: 25px;
  z-index: 1000;
  position: absolute;
  right: 10px;
  cursor: pointer;
  background-image: url(${DELETE_STANBY});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${DELETE_HOVER});
  }
`;

export const DeletedActionLeft = styled.div`
  margin-top: 7px;
  width: 25px;
  height: 25px;
  z-index: 1000;
  position: absolute;
  left: 10px;
  cursor: pointer;
  background-image: url(${DELETE_STANBY});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    background-image: url(${DELETE_HOVER});
  }
`;

const TableBlock = styled.div`
  width: 100%;
  height: ${(props) => (props.heightRow ? props.heightRow + 'px' : '40px')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  padding-top: ${(props) => (props.heightRow ? (props.heightRow / 2 - 7) + 'px' : '10px')};

  font-weight: 300;
  cursor: pointer;
  border-top: ${(props) => (!props.isBorderTopRow ? 'none' : 'solid 1px #f5f5f5')};
  background-color: ${(props) => (props.colorBlock ? props.colorBlock : props.rowIndex % 2 ? props.isActive ? '#E6E6E6' : '#FAFAFA' : props.isActive ? '#E6E6E6' : '#fff')};
  border-bottom: 2px solid #E6E6E6;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
`;

const TextPlagination = styled.div`
  padding-left: ${(props) => (props.isFirst ? '18px' : '10px')};
  border-left: ${(props) => (props.isFirst || props.withoutBorderColumn || props.colorBlock || props.colorBlock ? 'none' : 'solid 1px #E6E6E6')};
  height: 20px;
  padding-right: ${(props) => props.isShortText ? '65px' : '10px'};
  ${({leftGridScrollMode = true}) => leftGridScrollMode ? `overflow: hidden;text-overflow: ellipsis;white-space: nowrap` : ''};
  `;

const CheckboxPos = styled.div`
   margin: 10px 10px 10px 20px;
   float: left;
`;

const TotalBlock = styled.div`
  height: 36px;
  background-color: #3cb950;
  position: absolute;
`;

const ModalBody = styled.div`
  padding: 20px 20px 0 20px;
  text-align: center;
  font-size: 15px;
`;

const BottonBlock = styled(Row)`
    margin-top: 40px;
    justify-content: center;
`;
