import React from 'react';
import styled from 'styled-components';

export default ({ children, onClick, width }) => <IconWrapper onClick={onClick} width={width}>{children}</IconWrapper>;

const IconWrapper = styled.button`
    position: relative;
    height: 100%;
    background: transparent;
    border: none;
    width: ${(props) => props.width && props.width};
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    /* font-family: Roboto; */
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    color: #fff;

    // :hover {
    //    svg {
    //        path {
    //            stroke: #b6b6b6;
    //        }
    //    } 
    // }

    & p {
        margin-top: 7px;
    }

    & svg {
        transition: .1.4s ease;
        width: 40px;
        height: 40px;
        :hover {
            .download {
                stroke: #4382CB;
            }
            .delete {
                stroke: #C60008;
            }
            .delete-path {
                fill: #C60008;
            }
            .add {
                stroke: #B0B0B0;
            }
            .add-path {
                fill: #B0B0B0;
            }
            .replace {
                stroke: #3EC554;
            }
        }    
    }
`;
