/*eslint-disable */

import 'rc-tree-select/assets/index.css';
import TreeSelect from 'rc-tree-select';
import { Storage } from 'aws-amplify';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Calendar, Button, Checkbox } from 'components/elements';
import Folder_close from 'style/img/options/Vectorfolder_close.svg';
import { Row, Col } from 'style';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';

import { debounce } from 'lodash';
import moment from 'moment';

import exportDAT_FILES_Toolbox from './qql/exportDAT_FILES_Toolbox.js';

import getFA_Config from './qql/getFA_Config.js';
import getPathQ from './qql/getPathQ.js';
import getPathM from './qql/getPathM.js';

import updateFA_Config from './qql/updateFA_Config.js';

const nextDay = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');

export default (props)=> {
  const [date, setDate] = useState(nextDay);
  const [url, setUrl] = useState(null);
  const [isAuto, setIsAuto] = useState(false);

  const [valueOfTree, setValueOfTree] = useState({ value: '', label: '' });
  const [data1, setData] = useState([]);
  const [treeDataSimpleMode, setTreeDataSimpleMode] = useState({ treeDataSimpleMode: {
    id: 'id',
    rootPId: 0,
  } });



  const onChange = (value, _, obj) =>{
    console.log(obj)
    setValueOfTree({value: obj.triggerValue, label: obj.triggerValue})
  };

  const [mutationGetPathM] = useMutation(getPathM);

  const onLoadData = (treeNode) => {
    console.log(treeNode.props);
    const id = treeNode.props.id;
    const indexLoad = data1.findIndex((x) => x.id === id);
    console.log(indexLoad);

    if(!data1[indexLoad].loaded) {
      return new Promise((resolve) => {
        const data1N = data1;
        data1N[indexLoad].loaded = true;
        mutationGetPathM({ variables: { path: id } }).then((data)=>{
          console.log(data);

          setData([...data1N, ...JSON.parse(data.data.fakturaAssist.getPathM.body)]);
          resolve();
        }).catch((err)=>console.log(err));
      });
    } else{
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1);
      });
    }
  };






  const dataPathFA = useQuery(getPathQ, {
    fetchPolicy: 'cache-first',
    variables: {
      path: '*C:\\/Users/Default',
    },
    onCompleted: (data)=>{
    //   setData({
    // name: 'file system',
    // toggled: true,
    // children: JSON.parse(data.fakturaAssist.getPathQ.body.replace(/([\\])/g, '\\$1'))})
      setData(JSON.parse(data.fakturaAssist.getPathQ.body.replace(/([\\])/g, '\\$1')));

      setUrl(data.fakturaAssist.getPathQ.path);
    },
  });

  //////

  const dataFA_Config = useQuery(getFA_Config, {
    fetchPolicy: 'cache-first',
    onCompleted: (data)=>{
      setIsAuto(data.fakturaAssist.getFA_Config.usePrintingServiceForToolboxImport);
      setValueOfTree({ value: data.fakturaAssist.getFA_Config.toolboxImportPathForPrintingService, label: data.fakturaAssist.getFA_Config.toolboxImportPathForPrintingService } )
    },
  });

  const [mutationFA_Config] = useMutation(updateFA_Config);
  const [exportDAT_FILES, { data, loading }] = useMutation(exportDAT_FILES_Toolbox);

  const onMutation = (isAuto, valueOfTree) =>{
    mutationFA_Config({ variables: { fields: { usePrintingServiceForToolboxImport: isAuto, toolboxImportPathForPrintingService: valueOfTree.value } } }).then((data)=>{
      console.log(data);
    }).catch((err)=>console.log(err));
  };

  const onChangeCheckbox = () =>{
    setIsAuto(!isAuto);
  };

  const debouncedMutation = useCallback(debounce(onMutation, 2000, {
    leading: false,
    trailing: true,

  }), []);

  const saveFiles = () =>{
    exportDAT_FILES({ variables: { date: date, isAuto: isAuto } }).then((data)=>{
      if(!isAuto) {
        for(const item in data.data.fakturaAssist.exportDAT_FILES_Toolbox) {
          if(item !== '__typename') {
            Storage.get(data.data.fakturaAssist.exportDAT_FILES_Toolbox[item]).then((result) => { // <----- CHANGE IS HERE
              window.open(result);
              console.log(result);
            }).catch((err)=>{
              console.log(err);
            });
          }
        }
      }
    }).catch((err)=>console.log(err));
  };

  return (
    <HeadBlock>
      <MainBlock>
        <Row>
          <Col width='170px'>
            <Calendar title='Date' withoutFormik={true} onChange={(d)=>{
              setDate(d);
            }} value={date}/>
          </Col>
        </Row>

        <Row>
          <Col width='100%'>
            <Checkbox
              margin={'20px 0px'}
              zIndex={100}
              onClick={()=>{
                onChangeCheckbox(); debouncedMutation(!isAuto, valueOfTree);
              }}
              value={isAuto}
              title='Auto download to user Local Computer'
            />
          </Col>
        </Row>
      {isAuto &&
        <Row>
          <Col width='400px'>
            <TreeSelect
              treeDataSimpleMode={treeDataSimpleMode}
              style={{ width: '400px', height: '36px', bordreRadius: '3px' }}
              labelInValue
              //value={this.state.value}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder='Please select'
              onChange={onChange}
              loadData={onLoadData}
              value={valueOfTree}
              //loadData={this.onLoadData}
              showSearch={false}
              treeData={data1}
            />
            </Col>
            <Col width='400px'>
              <Button
                margin='0 0 0 20px '
                className={'black'}
                width={'190px'}
                onClick={()=>onMutation(isAuto, valueOfTree)}
                value={'Select'} />
            </Col>
        </Row>
      }
        <Row>
          <Col width='200px'>
            {loading !== true ?
              <Button
                className={'black'}
                width={'190px'}
                onClick={saveFiles}
                value={'Export Files'} />
              :
              <Button
                className={'grey-btn'}
                width={'190px'}
                value={isAuto ? 'Send files to storage...' : 'Download files...'} />}
          </Col>
        </Row>
      </MainBlock>
    </HeadBlock>
  );
};

const defaultData = {
  getFinanceAccount:
                        [
                          { ToolboxArticlesFilter: '1' },
                        ],
};

const HeadBlock = styled.div`
  height: 70vh;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;

