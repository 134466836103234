import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';
import { TextAreaStandart, Input, Transition } from 'components/elements';
import Formik from 'components/formik';

import EAN from './components/EAN';
import Declaration from './components/Declaration';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize
      linkId={props.linkId}
      sectionInfo={props.sectionInfo}
      updateWithoutId
    >
      {({ handleSubmit, values }) => {
        return (
          <>
            <MainBlock>
              <Row>
                <Col lg={4}>
                  <TextAreaStandart
                    value={values.label2}
                    onBlur={handleSubmit}
                    title={t('product.alternativeDescription')}
                    height='50px'
                    field='label2'
                    notResize
                  />
                </Col>
                <Col lg={4}>
                  <Input value={values.origin} field='origin' title={t('product.origin')}/>
                </Col>
              </Row>
            </MainBlock>
            <Transition index={0} text={t('product.ean')} isWhite>
              <EAN data={values} mutations={props.mutations}/>
            </Transition>
            <Transition index={1} text={t('product.declaration')} isWhite>
              <Declaration data={values} mutations={props.mutations}/>
            </Transition>
          </>
        );
      }}
    </Formik>
  );
};

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
