import React, { Fragment } from 'react';
import styled from 'styled-components';
import EDIT_HOVER from 'style/img/actions/edit_hover.svg';
import EDIT_STANBY from 'style/img/actions/edit_stanby.svg';
import DELETE_HOVER from 'style/img/actions/delete_hover.svg';
import DELETE_STANBY from 'style/img/actions/delete_stanby.svg';

import { listFA_AvaliableProductsListOnDay } from './qql/query';
//Dropdown, listFA_ActualTaxs
import { Input, SmartDropdown } from './elements';

const renderSwitch = (props) => {
  const item = props.data[props.rowIndex] || {};
  const row = props.rowIndex;
  const { t, date, vatInfo, notMutable, linkId, mutable } = props;
  const findTaxName = () => {
    const findItem = vatInfo?.data?.find((it) => item.taxCode === it.id);
    return findItem ? findItem.name : '-';
  };

  //When in default order canChangeInOrders will be true, order item closed!;
  const checkIsNotMutable = () => {
    //disable all table mutation
    if (notMutable) {
      return true;
    }
    if (mutable) {
      return false;
    }
    if (item.hasOwnProperty('canChangeInOrders')) {
      return !item.canChangeInOrders;
    }
    return false;
  };

  const noEdit = checkIsNotMutable();
  const indTextDeliveryNote = item.indTextDeliveryNote?.replace(/<[^>]+>/g, '');
  const indTextProduction = item.indTextProduction?.replace(/<[^>]+>/g, '');

  switch (props.columnIndex) {
    case 0:
      return (
        <SmartDropdown
          {...props}
          item={item}
          paddingLeft='19px'
          name='productNr'
          labelField={['productNr', 'name']}
          row={row}
          graphqlParams={{
            query: listFA_AvaliableProductsListOnDay,
            variables: {
              args: { date: date, customerId: linkId },
              params: {
                limit: 100,
                sort: [{ field: 'productNr', order: 'ASC' }],
                search: { columns: ['Bezeichnung'] },
              },
            },
          }}
          valueOfDropdown={item.productNr}
          sortBy='productNr'
          topDrop={props.topDrop}
          width={props.style.width}
          placeholder={t('common.article_number')}
          noEdit={noEdit}
          defaultValueField={item.productLink}
        />

      );
    case 1:
      return (
        <Input
          {...props}
          placeholder='Quantity'
          value={item.quantity !== 0 ? item.quantity : ''}
          name='quantity'
          type='number'
          textAlign='right'
          noEdit={noEdit}

        />
      );
    case 2:
      return (
        <SmartDropdown
          {...props}
          item={item}
          paddingLeft='19px'
          name='name'
          labelField={['name', 'productNr']}
          row={row}
          graphqlParams={{
            query: listFA_AvaliableProductsListOnDay,
            variables: {
              args: { date: date, customerId: linkId },
              params: {
                limit: 100,
                sort: [{ field: 'name', order: 'ASC' }],
                search: { columns: ['Bezeichnung'] },
              },
            },
          }}
          background={(indTextDeliveryNote && indTextDeliveryNote !== item.productName) || (indTextProduction && indTextProduction !== item.productName) ? '#FDBE9E' : undefined}
          valueOfDropdown={indTextDeliveryNote || item.productName}
          sortBy='name'
          topDrop={props.topDrop}
          width={props.style.width}
          value={{ value: 111, label: item.productNr }}
          placeholder={t('common.article_number')}
          noEdit={noEdit}
          defaultValueField={item.productLink}
        />
      );
    case 3:
      return (
        <AdditionBlock rowIndex={row} noEdit={noEdit}>
          <UpdatedAction noEdit={noEdit} onClick={(() => !noEdit && props.changeOpenAdditionalModal(true))} />
        </AdditionBlock>
      );
    case 4:
      return (
        <Input
          placeholder='Price'
          name='price'
          value={Number(item.price).toFixed(2)}
          onBlur={props.onChangeInput}
          background={Number(item.price) ? 'rgba(60,185,79,0.2)' : 'rgba(255,204,0,0.2)'}
          opacity={0.2}
          type='number'
          textAlign='right'
          noEdit={noEdit}
          {...props}
        />
      );
    case 5:
      return (
        <TotalBlockStyle
          noEdit={noEdit}
          background={Math.sign(item.total) === -1 ? '#c13b44' : Number(item.total) ? 'rgba(60,185,79,0.2)' : 'rgba(255,204,0,0.2)'}>
          {typeof item.total === 'number' ? item.total.toFixed(2) : 0.00}
        </TotalBlockStyle>
      );
    case 6:
      return (
        <Input
          placeholder='Rabatt'
          name='discount'
          value={item.discount}
          onBlur={props.onChangeInput}
          type='number'
          textAlign='right'
          noEdit={noEdit}
          {...props}
        />
      );
    case 7:
      return (
        <SmartDropdown
          {...props}
          item={item}
          labelField='name'
          name='tax'
          row={row}
          dropdownData={vatInfo.data}
          valueOfDropdown={findTaxName()}
          topDrop={props.topDrop}
          width={props.style.width}
          placeholder={t('common.article_number')}
          noEdit={noEdit}
        />

      );
    case 8:
      return (
        <DeletedBlockStyle noEdit={noEdit}>
          <DeletedAction noEdit = {noEdit} onClick={() => !noEdit && props.onDelete(props.rowIndex)} />
        </DeletedBlockStyle>
      );
    default:
      return <span>error</span>;
  }
};

const TableRow = (props) => {
  const { style, rowIndex, changeTableIndex, disableRows } = props;

  return (
    <Fragment >
      <TableBlock style={style} onClick={changeTableIndex.bind(null, rowIndex)} rowIndex={rowIndex} disableRows={disableRows}>
        {renderSwitch(props)}
      </TableBlock>
    </Fragment>
  );
};

export default TableRow;

const TableBlock = styled.div`
  width: 100%;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #ffffff;
  border-bottom: solid 1px #f5f5f5;
  border-left: solid 1px #f5f5f5;
  background-color: ${(props) => (props.rowIndex % 2 ? '#eeeeee' : '#fff')};
  opacity: ${({ disableRows }) => disableRows ? '.3' : '1'};
  pointer-events: ${({ disableRows }) => disableRows ? 'none' : 'all'};
`;

export const UpdatedAction = styled.div`
  text-align: center;
  width: 25px;
  height: 25px;
  z-index: 1000;
  position: absolute;
  cursor: pointer;
  background-image: url(${EDIT_HOVER}); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${({ noEdit }) => noEdit && `
    background-image: url(${EDIT_STANBY});
	cursor: not-allowed;
`}    
`;

export const DeletedAction = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  right: 12px;
  cursor: pointer;
  background-image: url(${DELETE_HOVER}); 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  ${({ noEdit }) => noEdit && `
    background-image: url(${DELETE_STANBY});
	cursor: not-allowed;
`}  
`;

const AdditionBlock = styled.div`
  width: 100%;
  height: 40px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    display: block;
    margin: 0 auto;
    height: 22px;
    width: 22px;
  }
  ${({ noEdit }) => noEdit && `
  color: #afafaf;
  cursor: not-allowed;
`}
`;

const DeletedBlockStyle = styled.div`
  font-weight: 500;
  width: 100%;
  padding: 10px 10px 10px 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ noEdit }) => noEdit && `
  color: #afafaf;
  cursor: not-allowed;
`}
`;

const TotalBlockStyle = styled.div`
  /* font-family: Roboto-Medium; */
  font-weight: 500;
  width: 100%;
  padding: 10px 10px 10px 10px;
  height: 40px;
  border: none;
  background: ${(props) => (props.background ? props.background : 'inherit')};
  text-align: right;
  ${({ noEdit }) => noEdit && `
  color: #afafaf;
  cursor: not-allowed;
`}
`;
/*<div>
    <div style={{'width':'10%', float: "left", padding: "6px 0 0 9px" }} >
    { this.state.index === index ?
    <InputWithDropdown
      changeValue={this.props.changeInputWithDropdownValue}
      onClickInputWithDropdown={this.props.onClickInputWithDropdown}
      inputValue = {item.ArtikelNr}
      text="ArtikelNr"
      style={{zIndex: 99999 - index}}
      list={this.props.list}
      gruppeId={1}
      onChange={this.props.changeInputWithDropdownValue.bind(this, index)}
      deleteDropDown={null}
      addDropDown={null}
      updeteDrop={null}
      ref = {this.textInput}
      placeholder='Article'
      closeInput={this.closeInput}
    />
    :
    <Input
      type="text"
      value={item.ArtikelNr}
      width = '100%'
      placeholder='ArtikelNr'
      onClick={this.getRowIndex.bind(null, index)}
    />
  }
    </div>
    <div style={{'width':'10%', float: "left", padding: "6px 0 0 9px" }} >
    <Input
      type="number"
      value={item.Menge}
      name="Menge"
      onBlur={this.changeInputWithoutDroponBlur.bind(this, 'quantityIndex')}
      onChange={this.props.changeTableByInput.bind(this, index)}
      width = '100%'
      step={1}
      placeholder='Quantity'
      autoFocus = {index==0? true:null}
      onKeyPress={onPressEnter}
      getRef={(input) => {mergeInput[index] = input}}
    />
    </div>
    <div style={{'width':'20%', float: "left", padding: "6px 0 0 9px" }} >
    {
      false?
      <InputWithDropdown
        testFunctionToInput= {this.props.testFunctionToInput}
        onClickInputWithDropdown={this.onClickInputWithDropdown}
        changeValue={this.props.changeInputWithDropdownValue.bind(this)}
        inputValue = {item.Bezeichnung.substring(0, 25)}
        text="Group"
        style={{zIndex: 99999 - index}}
        list={this.props.list}
        gruppeId={1}
        onBlur={this.changeInputWithoutDroponBlur.bind(this, 'desIndex')}
        deleteDropDown={null}
        addDropDown={null}
        updeteDrop={null}
        ref = {this.textInput}
        placeholder='Bezeichnung'
      /> :
      <div>{item.Bezeichnung} </div>
    }
      </div>
  <div style={{'width':'10%', float: "left", padding: "6px 0 0 9px" }} >
  <img style={{width:'26px', height: "25px"}} src={edit} onClick={this.props.deleteTable}/>
  </div>
  <div style={{'width':'10%', float: "left", padding: "6px 0 0 9px" }} >
  <Input
      type="text"
      value={item.PreisLieferung}
      name="PreisLieferung"
      onChange={this.props.changeTableByInput.bind(this, index)}
      width = '100%'
      placeholder='Price'
    />
    </div>
    <div style={{'width':'10%', float: "left", padding: "6px 0 0 9px" }} >
  <div style ={{color: item.Menge <0 ? "#d0021b" : null, width: '100%'}}> {item.Total ? item.Total: 0}</div>
  </div>
  <div style={{'width':'10%', float: "left", padding: "6px 0 0 9px" }} >
  <Input
      type="text"
      value={item.RabattP ? item.RabattP : ''}
      name="RabattP"
      onChange={this.props.changeTableByInput.bind(this, index)}
      width = '100%'

      placeholder='Discount'
    />
    </div>
    <div> </div>
    </div>*/
