import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getYears } from '../../../helpers/getYears';
import { Calendar, Modal, Dropdown, Radio, Button, MonthChooser } from '..';
import { periods } from './CONST';
import icon_calendar from '../Calendar/icon.svg';
import { useTranslation } from 'react-i18next';
import { useTranslation as useLanguage } from 'react-i18next';

import arrow from 'style/blackArrowDown.svg';

import 'moment/locale/fr';
import 'moment/locale/de';

const DateRangeInModal = (props) => {
  const [t] = useTranslation();
  const { i18n } = useLanguage();
  moment.locale(i18n.language);
  //Date or period radio button
  //Cache date range. When user close modal without accept, cacheDates get as default
  const [cacheFirstDate, setCacheFirstDate] = useState(null);
  const [cacheDefaultMounth, setCacheDefaultMounth] = useState(null);
  const [cacheSecondDate, setCacheSecondDate] = useState(null);
  const [firstDate, setFirstDate] = useState(props.firstDate ? moment(props.firstDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'));
  const [secondDate, setSecondDate] = useState(props.secondDate ? moment(props.secondDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'));
  const [defaultMounth, setMounth] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    year: moment().year(),
    period: '',
  });

  const onMountsClick = (id, mounth) => {
    setSelectedValues({ ...selectedValues, period: '' });
    const startOfTheMonth = moment().year(selectedValues.year).month(id - 1).startOf('month').format('YYYY-MM-DD');
    const endOfTheMonth = moment().year(selectedValues.year).month(id - 1).endOf('month').format('YYYY-MM-DD');
    setMounth(mounth);
    setFirstDate(startOfTheMonth);
    setSecondDate(endOfTheMonth);
  };

  const onYearsClick = ({ value }) => {
    const startOfTheYear = moment().year(value).startOf('year').format('YYYY-MM-DD');
    const endOfTheYear = moment().year(value).endOf('year').format('YYYY-MM-DD');
    setSelectedValues({ ...selectedValues, year: value, period: '' });
    setMounth('');
    setSecondDate(endOfTheYear);
    setFirstDate(startOfTheYear);
  };

  const onPeriodClick = ({ value }) => {
    setSelectedValues({ ...selectedValues, period: value });
    setMounth('');
    const startOfThePeriod = moment().year(selectedValues.year).startOf('year').format('YYYY-MM-DD');
    const startMiddleOfThePeriod = moment().year(selectedValues.year).month(5).endOf('month').format('YYYY-MM-DD');
    const endMiddleOfThePeriod = moment().year(selectedValues.year).month(6).startOf('month').format('YYYY-MM-DD');
    const endOfThePeriod = moment().year(selectedValues.year).endOf('year').format('YYYY-MM-DD');

    if(value === 1) {
      setFirstDate(startOfThePeriod);
      setSecondDate(startMiddleOfThePeriod);
    }else{
      setFirstDate(endMiddleOfThePeriod);
      setSecondDate(endOfThePeriod);
    }
  };

  const onChangeCalendar = (calendarNumber, date) => {
    if(calendarNumber === 1) {
      setSelectedValues({ ...selectedValues, year: moment(date).year(), period: '' });
      setFirstDate(date);
      setSecondDate('');
    } else if (calendarNumber === 2) {
      setSelectedValues({ ...selectedValues, period: '' });
      setSecondDate(date);
    }
  };

  const dateRangeInText = () => {
    if(secondDate && firstDate !== secondDate) {
      const first = moment(firstDate).format('DD.MM.YYYY');
      const second = secondDate ? moment(secondDate).format('DD.MM.YYYY') : '';
      return `${first} ${second ? '-' + second : ''}`;
    }
    return moment(firstDate).format('dddd, DD.MM.YYYY');
  };

  const onAccept = () => {
    //Callback func from props
    props.onAccept && props.onAccept(firstDate, secondDate ? secondDate : null);
    setOpen(false);
  };
  //Cache date range. When user close modal without accept, data going to default value
  const cacheInfo = () => {
    setCacheFirstDate(firstDate);
    setCacheSecondDate(secondDate);
    setCacheDefaultMounth(defaultMounth);
  };

  const onClose = () => {
    setOpen(false);
    setFirstDate(cacheFirstDate);
    setSecondDate(cacheSecondDate);
    setMounth(cacheDefaultMounth);
    setSelectedValues({ ...selectedValues, period: '', year: moment().year() });
  };

  const actionsButtons = () => {
    return (
      <div>
        <Col lg={4}>
          <Button upperCase value = {t('common.save')} className='black' onClick={onAccept}/>
        </Col>
      </div>
    );
  };
  return (
    <Main>
      <Modal isOpen={isOpen} isOutsideClick={true} closeModal={onClose} headerText={t('common.selectionOfTimePeriod')} width='40%' height='auto' Component={
        <PeriodModal
          firstDate={firstDate}
          secondDate={secondDate}
          setSecondDate={setSecondDate}
          setFirstDate={setFirstDate}
          defaultMounth={defaultMounth}
          onMountsClick={onMountsClick}
          onYearsClick={onYearsClick}
          onPeriodClick={onPeriodClick}
          onChangeCalendar={onChangeCalendar}
          actionsButtons={actionsButtons}
          selectedValues={selectedValues}
        />
      }
      onAfterOpen={cacheInfo}
      />
      <div>
        {!props.withoutTitle && <DateRangeText>{t('common.filter.dateRange')}</DateRangeText>}
        <CalendarInputMain width={props.width} margin={props.margin} onClick={() => setOpen(true)} value={dateRangeInText()}/>
      </div>
    </Main>);
};

const PeriodModal = (props) => {
  const [t] = useTranslation();
  const [sampValue, setSampValue] = useState(props.firstDate === props.secondDate || !!!props.secondDate);
  return (
    <ModalContent>
      <PeriodWrapper>
        <RadioWrapper>
          <Radio title={t('common.day')} checked={sampValue} onClick={() => {
            setSampValue(true);
            props.setSecondDate(props.firstDate);
          }}/>
        </RadioWrapper>
        <RadioWrapper>
          <Radio title={t('common.period')} checked={!sampValue} onClick={() => setSampValue(false)}/>
        </RadioWrapper>
      </PeriodWrapper>
      {sampValue ? <DayChoose {...props}/> : <PeriodChoose {...props}/>}
    </ModalContent>

  );
};

const PeriodChoose = (props) => {
  const [t] = useTranslation();
  const { defaultMounth, onMountsClick, firstDate, secondDate, onYearsClick, onPeriodClick, onChangeCalendar, actionsButtons, selectedValues } = props;

  return (
    <>
      <Row>
        <CalendarsSection>
          <CalendarWrapper>
            <Calendar widthTitle='auto' withoutFormik colorTitle='#686868' title={t('common.period')} value={firstDate} onChange={(date) => {
              onChangeCalendar(1, date);
            }}/>
          </CalendarWrapper>
          <CalendarWrapper>
            <Calendar widthTitle='auto' withoutFormik colorTitle='#686868' title={t('common.toDate')} value={secondDate} onChange={(date) => {
              onChangeCalendar(2, date);
            }}/>
          </CalendarWrapper>
        </CalendarsSection>
      </Row>
      <Separator/>
      <Row>
        <Dropdown
          data={getYears(5)}
          colorTitle='#686868'
          title={t('common.year')}
          onChange={onYearsClick}
          defaultValueField={selectedValues.year || moment().year()}
        />
      </Row>
      <Row>
        <Dropdown
          bold
          data={periods(t)}
          colorTitle='#686868'
          title={t('common.period')}
          onChange={onPeriodClick}
          defaultValueField={selectedValues.period || ''}
        />
      </Row>
      <Row>
        <MonthChooser
          defaultMonth={defaultMounth}
          onClick={onMountsClick}
        />
      </Row>
      <Row>
        <ButtonsWrapper>
          {actionsButtons()}
        </ButtonsWrapper>
      </Row>
    </>
  );
};

const DayChoose = ({ firstDate, setFirstDate, setSecondDate, onChangeCalendar, actionsButtons }) => {
  const [t] = useTranslation();

  const onPreviousDay = () => {
    setFirstDate(moment(firstDate).add(-1, 'days').format('YYYY-MM-DD'));
    setSecondDate('');
  };
  const onNextDay = () => {
    setFirstDate(moment(firstDate).add(1, 'days').format('YYYY-MM-DD'));
    setSecondDate('');
  };

  return (
    <Row>
      <CalendarWrapper>
        <ImgBlock onClick={onPreviousDay}>
          <img src={arrow}/>
        </ImgBlock>
        <Calendar title={t('common.day')} withoutFormik value={firstDate} onChange={(date) => {
          onChangeCalendar(1, date);
        }}/>
        <ImgBlock second onClick={onNextDay}>
          <img src={arrow}/>
        </ImgBlock>
      </CalendarWrapper>
      <ButtonsWrapper>
        {actionsButtons()}
      </ButtonsWrapper>
    </Row>
  );
};

export default DateRangeInModal;

const Main = styled.div`
    display: flex;
    width: 100%;
`;

const CalendarWrapper = styled.div`
  display: block;
  margin-right: 20px;
  position: relative;
`;

const DateRangeText = styled.p`
  margin-top: 20px;
  margin-bottom: 6px;
  font-size: 14px;
  color: #686868;
`;

const ImgBlock = styled.div`
  height: 22px;
  width: 20px;
  display: inline-block;
  position: absolute;
  left: ${({ second }) => second ? '140px' : '-20px'};
  bottom: ${({ second }) => second ? '6px' : '6px'};
  text-align: center;
  transform: ${({ second }) => second ? 'rotate(90deg);' : 'rotate(-90deg);'}; 
  cursor: pointer;
  img {
    width: 1rem;
  }
`;

const CalendarsSection = styled.div`
  display: flex;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #fff;
  margin-top: 23px;
`;

const RadioWrapper = styled.div`
  flex-basis: 135px;
`;

const ModalContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const PeriodWrapper = styled.div`
  display: flex;
`;

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`;

DateRangeInModal.propTypes = {
  onAccept: PropTypes.func.isRequired, //When user press accept
  firstDate: PropTypes.instanceOf(Date), //First date. new Date() as default
  secondDate: PropTypes.instanceOf(Date), // Second date

};

/*
  Example
    <DateRangeInModal
      firstDate = {new Date()}
      secondDate = {new Date()}
      onAccept = { (first ,second) => {}}

    />
*/

const CalendarInputMain = styled.input`
  width: ${(props) => props.width ? props.width : '100%'};
  height: 36px;
  border-radius: 3px;
  color: ${(props) => props.noActive ? '#ffffff' : 'initial'};
  text-align: left;
  padding-top: 10px;
  cursor: pointer;
  background: url(${icon_calendar}) no-repeat 3px 1px;
  background-size: 20px;
  background-position: 95%;
  background-color: ${(props) => props.noActive ? '#cdcdcd' : '#ffffff'};
  padding: 10px 0px 10px 13px;
  border: none;
  margin: ${(props) => props.margin ? props.margin : '0'}
`;


