
import gql from 'graphql-tag';

export default gql`
query listFA_DeliveryPeriods($id: Int!, $isVacation: Boolean){
    fakturaAssist{
    listFA_DeliveryPeriods(id: $id, isVacation: $isVacation){
		id
		linkCustomer
		description
		fromDate
		toDate
		isCustomWeekDaySetting
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		isVacation
		isRepeatYearly
    }
    
  }
}`;
