
import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'components/elements';
import Formik from '../../../components/formik';
import { Row, Col } from 'style';

import listFA_PostSectors from './qql/listFA_PostSectors';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  return (
  	<Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <HeadBlock>
            <MainBlock>
              <Col lg={4}>
                <Dropdown
                  margin={'19px 0 0 0'}
                  title={t('customer.post.postSector')}
                  valueField = 'id'
                  labelField = 'name'
                  field = 'linkPostSector'
                  defaultValueField = {values.linkPostSector}
                  graphqlParams={{ query: listFA_PostSectors }}
                />
              </Col>
            </MainBlock>
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled(Row)`
  height: 100%;
  min-height: 300px;
  padding: 0px 0px 0px 0px;
`;

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
  max-height: 100px;
`;
