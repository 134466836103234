export const tableDataCRM = (t) => [
  {
    name: t('common.dateTime'),
    dataBase: 'addedOn',
    isDefaultChanged: '2018-11-11',
    dataType: 'date',
  },
  {
    name: t('common.user'),
    dataBase: 'id',
  },
  {
    name: t('common.note'),
    dataBase: 'note',
    isMutation: true,
  },
];
