import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Input, Button, Modal } from 'components/elements';
import { useLazyQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';

import styled from 'styled-components';
import { Row, Col } from 'style';
import { useTranslation } from 'react-i18next';
import allArticles from './gql/allArticles';

const CREATE_PRODUCT = gql`
mutation createProduct($name: String, $productNumber: String){
    fakturaAssist{
    	createProduct(name: $name, productNumber: $productNumber){
    		id
        name
        productNumber
 		}
 	}
}`;

const ComponentForCreating = ({ linkId, isOpen, setIsOpen, contex, history, client, sortObject }) => {
  const [t] = useTranslation();
  const [data, setData] = useState({ productNumber: '', name: '' });
  const [getProduct, { data: productData, loading: productLoading }] = useLazyQuery(allArticles);
  const [productExisted, setProductExisted] = useState(false);

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT);
  //const [id, setId] = useState(linkId);
  const onCreate = async () => {
    getProduct({
      variables: {
        params: {
          limit: 100, offset: 0, sort: { field: 'productNumber', order: 'ASC' },
          search: {
            string: data.productNumber, columns: ['ArtikelNr'],
          },
        },
      },
    });
  };

  useEffect(() => {
    if (!productLoading && productData) {
      createProduct({
        variables: {
          ...data,
        },
        refetchQueries: [{ query: allArticles, variables: contex }],
      }).then((_data) => {
        try {
          history.push(`/products/article/${_data.data.fakturaAssist.createProduct.id}`);
          setIsOpen(false);
          setProductExisted(false);
          setData({ productNumber: '', name: '' });
        } catch (e) {
          console.error(e);
        }
      }).catch((err) => {
        if(err?.graphQLErrors) {
          if (err.graphQLErrors[0]?.message.includes('Product number already exists')) {
            setProductExisted(true);
          }
        }
      });
    }
  }, [productLoading, productData]) // eslint-disable-line

  const renderModal = () => {
    return (
      <HeadBlock>
        <>
          <Input
            value={data.productNumber}
            field='productNumber'
            title={t('common.articleNo')}
            onChange={(e) => {
              if (productExisted) {
                setProductExisted(false);
              }
              setData({ ...data, productNumber: e.target.value });
            }}
          />
          {productExisted && <small style={{ color: '#C60008' }}>{t('article_already_exist', { productNumber: data.productNumber })}</small>}
          <Input value={data.name} field='name' title={t('common.description')} onChange={(e) => setData({ ...data, name: e.target.value })} />
        </>

        <Footer>
          <Col width='50%'>
            <div style={{ marginRight: '10px' }}>
              <Button
                float='right'
                className={'grey-btn'}
                width={'100%'}
                upperCase
                onClick={() => {
                  setData({ productNumber: '', name: '' });
                  setIsOpen(false);
                }}
                height={'42px'}
                style={{ fontSize: 16 }}
                value={t('common.cancel')} />
            </div>
          </Col>
          <Col width='50%'>
            <div style={{ marginLeft: '10px' }}>
              {loading || productLoading ? <i className='fas fa-spinner fa-pulse' /> : <Button
                disabled={!data.productNumber}
                float='left'
                className={'green-btn'}
                width={'100%'}
                upperCase
                height={'42px'}
                style={{ fontSize: 16 }}
                onClick={onCreate}
                value={t('common.save')} />}
            </div>
          </Col>
        </Footer>
      </HeadBlock>

    );
  };

  return (
    <Modal
      headerText={t('product.createProduct')}
      width={'480px'}
      height={'315px'}
      isOpen={isOpen}
      closeModal={() => {
        setData({ productNumber: '', name: '' });
        setIsOpen(false);
      }}
      Component={renderModal()}
    />
  );
};

export default ComponentForCreating;

const HeadBlock = styled.div`
  min-height: 200px;
  padding: 0px 20px 20px 20px;
  background: #f4f4f4;
`;
const Footer = styled(Row)`
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
`;
