import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { tableProductsParams, tableOrdersParams, tableOrdersParamsWithCustomerInfo } from '../params';

import TableGrid from 'components/tableGrid/TableGrid';
import Query from 'components/graphql/Query';

import editImg from 'style/img/invoice/edit.svg';
import { getFA_GetOrderPositions, listFA_InvoiceOrders } from '../../qql/query';

const ProductsTable = ({ orderId, t, getOrderEditPage, ...props }) => {
  const {
    checkBoxList,
    orderSelectingMode,
    isLeftGridEmpty,
    setCheckBoxList,
    linkId,
    orderVariables,
  } = props;

  const sammelfakturaAufKunde = props.data?.find((item) => +item?.id === linkId)?.sammelfakturaAufKunde;

  return(
    <>
      <Row>
        <UnderTableHeaderBlock>
          <OptionBlock>
            <EditImg alt='' src={editImg} onClick={getOrderEditPage}/>
          </OptionBlock>
        </UnderTableHeaderBlock>
      </Row>
      <Row>
        {/* don't show right part when left grid is empty */
          (!isLeftGridEmpty) && (
            <Col width='30%'>
              <OrderBlock>
                {true && (
                  <TableGrid
                    height={300}
                    tableData={sammelfakturaAufKunde ? tableOrdersParamsWithCustomerInfo(t) : tableOrdersParams(t)}
                    graphqlParams={{
                      query: listFA_InvoiceOrders,
                      variables: orderVariables,
                      skip: !linkId,
                      fetchPolicy: 'network-only',
                    }}
                    withCheckbox={!orderSelectingMode}
                    getCheckboxList={(list) => setCheckBoxList(list)}
                    checkBoxList={checkBoxList}
                    disableHeightChange
                    startedIndex={0}
                    t={t}
                    withTotal
                  />
                )}
              </OrderBlock>
            </Col>
          )
        }
        <Query query={getFA_GetOrderPositions} variables={ { id: orderId } } skip={!orderId}>
  				{({ data, ...prev })=> {
  					return (
              // Remain problem withTotal and selecting
              <Col width='70%' >
                <TableGrid
                  tableData={tableProductsParams(t)}
                  data={data.productTable || []}
                  height={300}
                  withTotal
                  startedIndex={0}
                  disableHeightChange
                />
              </Col>
            );
  				}}
  			</Query>
      </Row>
    </>
  );
};

export default ProductsTable;

const OptionBlock = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.12);
  background-color: #3cb950;
  margin: 13px 0 0 0;
  cursor:pointer;
`;

const EditImg = styled.img`
  height: 22px;
  width: 13px;
  margin: 0 0 0 5px;
  display: block;
`;

const UnderTableHeaderBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;

const OrderBlock = styled.div`
  margin-right: 10px;
`;
