import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Text } from '../elements/index';

const Menu = (props) =>{
  const { menuObj, text } = props;
  const menuLink = menuObj.map(({ name, col, link }, index) => {
    return (
      <MenuBlock menuObj={menuObj} key={name} col={col ? col : 12 / menuObj.length}>
        <ActiveLink key={name} to={link}>
          <LinkButton disabled={menuObj.length === 1}>{name}</LinkButton>
        </ActiveLink>
        <Border/>
      </MenuBlock>
    );
  });
  return (
    <div>
      {text ? (
        <div>
          <Text
            text={text}
            marginBottom='-8px'
            fontSize='24px'
            color='#3c445a'
          />
        </div>
      ) : null}
      <MenuWrapper>{menuLink}</MenuWrapper>
    </div>
  );
};

Menu.propTypes = {
  isaddition: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
};

Menu.defaultProps = {
  menuObj: [
    {
      name: 'name',
      link: 'link',
    },
  ],
};

export default Menu;

const Border = styled.div`
  border: 1px solid white;
  height: 90%;
`;

const ActiveLink = styled(NavLink)`
  color: #ffffff;
  &.active {
    opacity: 1;
    font-weight: 400;
  }
`;

const MenuWrapper = styled.span`
  display: flex;
  border-radius: 3px 3px 0 0;
  overflow: hidden;
`;

const LinkButton = styled.button`
  background-color: #434343;
  width: 100%;
  height: 36px;
  text-align: center;
  font-size: 14px;
  border: none;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 5px;
		right: 0;
		width: 2px;
		height: 25px;
		background-color: #565656;
  }
  ${ActiveLink}.active & {
    background-color: #565656;
    border-bottom: 2px solid #FD2724;
  }
`;

const MenuBlock = styled.div`
  width: ${({ col, menuObj }) => {
    if(col) {
      return (100 * col) / 12;
    }else{
      return 100;
    }
  } }%;
  float: left;
`;

// const DropdownMainBlock = styled(Select)`
//     width: ${(props)=>props.width || '300px'};
//     border: none;
//     &.Select.is-open > .Select-control .Select-arrow {
//       border-color: transparent transparent red;
//     }
// `;
