import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LoadingWrap } from 'style/default';

import Menu from 'components/Menu/Menu';
import ActionsList, { ActionsListBlock } from 'components/actionsList/ActionsList';
import { Text } from '../elements/index';

//import SadSmile from 'style/img/list/sadSmile.svg';

const Additional = (props) => {
  const {
    customActions,
    reportForms,
    pdfParams,
    t,
    actions,
    menuObj,
    children,
    text,
    loading,
    paddingTop,
    isShow = true,
    historyFormTypeID,
    linkId,
    menuVisible = true,
  } = props;
  const [width, setWidth] = useState(0);

  useEffect(()=>{
    setWidth(document.getElementById('addition_block') && document.getElementById('addition_block').offsetWidth);
  }, [document.getElementById('addition_block')]);

  //const cutHeight = props.cutHeight || 230;
  //const clientHeight = height || window.innerHeight - cutHeight;
  // console.log(actions);
  const render = () => {
    const mainComponent = (<>
      <HeaderMenu width={width - 19 + 'px'} paddingTop={paddingTop} menuVisible={menuVisible}>
        <InfoUnderMenu><Text
          text={text || '-'}
          marginBottom='4px'
          fontSize='20px'
          color='#3c445a'
        />
        {actions && <StyledActions>
          <ActionsList
            t={t}
            actions={actions}
            reportForms={reportForms}
            pdfParams={pdfParams}
            customActions={customActions}
            historyFormTypeID={historyFormTypeID}
            linkId={linkId}
          />
        </StyledActions>}
        </InfoUnderMenu>
        {menuVisible && <Menu menuObj={menuObj}/>}
      </HeaderMenu>
      <LoadingWrap loading={loading}>
        {children}
      </LoadingWrap>
    </>);
    // const noShowComponent = (
    //   <NoShowBlock clientHeight={clientHeight}>
    //     {noShowText && <NoShowSmile>
    //       <img src={SadSmile} alt=''/>
    //       <NoShowBlockText>{noShowText || ''}
    //       </NoShowBlockText>
    //     </NoShowSmile>}
    //   </NoShowBlock>);
    if(loading) {
      return mainComponent;
    }else if(!loading && isShow) {
      return mainComponent;
    }else{
      return null;
    }
  };
  return (
    <div>
      <Content id='addition_block'>
        <MainBlock menuVisible={menuVisible}>
          {render()}
        </MainBlock>
      </Content>
    </div>
  );
};

export default Additional;

const MainBlock = styled.div`
  margin : ${({ menuVisible }) => !menuVisible ? '0 0 0 20px' : '28px 0px 0px 20px'};
`;

const HeaderMenu = styled('div')`
    position: ${({ menuVisible }) => !menuVisible ? 'static' : 'fixed'};
    top: 60px;
    background: #f7fdff;
    padding: ${(props)=> props.paddingTop !== undefined ? props.paddingTop : 45}px 0 0 0;
    height: ${({ menuVisible }) => !menuVisible ? '38px' : '80px'};
    width: ${(props)=> props.width};
    z-index: 3;
    margin-left: -1px;
`;

const InfoUnderMenu = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    padding: 0px 0px 0px 0px;
`;

const Content = styled.div`
  overflow-x: hidden;

`;

const StyledActions = styled(ActionsListBlock)`
  margin-right: 0px;
  margin-top: 30px;
`;

// const NoShowBlock = styled.div`
//   background: #f5f5f5;
//   width: 100%;
//   height: 75vh;
//   height: ${({ clientHeight })=> clientHeight + 'px'};
//   display: flex;
//     align-items: center;
//     align-content: center;
//     justify-content: center;
// `;

// const NoShowBlockText = styled.span`
//   color: #a8a8a8;
//   font-size: 24px;
//   display: block;
//   margin: 0 auto;
//   margin-top: 20px;
// `;

// const NoShowSmile = styled.div`
// display: flex;
// flex-direction: column;
// `;
