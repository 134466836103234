import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { useMutation } from 'components/graphql';
import { cloneDeep, isEqual } from 'lodash';
import { readQuery, writeQuery } from 'helpers/queryOperations';

import Items from 'components/List/Items';
import Addition from 'components/List/Additional';

import { listFA_InvoiceListNew } from '../qql/query';
import withPDFActions from 'components/viewPDF/withPDFActions';

import ArrowWhite from 'style/arrow-white.svg';
import ArrowWhiteDown from 'style/arrow-white-down.svg';

import Filter from 'components/elements/Filter/Filter';

import { Main } from './pages';

import { LoadingWrap } from 'style/default';

// import { callFA_CreateInvoice } from '../qql/mutation';
import { listFA_InvoiceOrders } from '../qql/query';

import { tableDeliveryDate } from './params';

const NewInvoice = (props) => {
  const [isOpenFilter, setOpenFilter] = useState(false);
  // const [createInvoiceMutation, createInvoiceMutationProps] = useMutation(
  //   callFA_CreateInvoice
  // );
  const {
    contex,
    client,
    writeContex,
    // sendPDFIntoPrinter,
    // showPDFPreview,
    // sendPDFIntoEmail
  } = props;
  const { filterData, filterQuery } = props.subSectionInfo;
  const [t] = useTranslation();
  const [confirmedFilterInfo, setConfirmedFilterInfo] = useState(JSON.parse(localStorage.getItem('invoice/new')) || []);
  const [isOpenTable, changeOpenTable] = useState(false);
  const [orderSelectingMode, setOrderSelectiongMode] = useState(true);
  const { linkId } = props;
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [localLinkId, setLocalLinkId] = useState(linkId);
  //ID from Items component!

  useEffect(() => {
    setLocalLinkId(linkId);
  }, [linkId]);

  const some = (id) => {
    props.history.replace({
      pathname: `/invoice/new/${id}`,
    });
  };
  const setFilterInfo = (filter) => {
    writeContex({ ...contex, filter: [...filter] });
  };

  const setDateInfo = (date) => {
    if (date) {
      writeContex({ ...contex, date: date });
    }
  };

  const orderVariables = {
    args: {
      date: contex.date?.date,
      secondDate: contex.date?.secondDate,
      id: +linkId,
    },
  };

  const readQueryOrderParams = {
    client,
    variables: orderVariables,
    query: listFA_InvoiceOrders,
  };
  const writeQueryOrderParams = {
    client,
    variables: orderVariables,
    query: listFA_InvoiceOrders,
    queryValue: 'listFA_InvoiceOrders',
  };

  //Puh 1 id of customer in link

  const [chooseOrder, changeChooseOrder] = useState({});
  //Change link on order/edit where customer can change order.
  //Date of order write in main section as defaultFilterParams and changes after history replace

  const getOrderEditPage = () => {
    if (chooseOrder.id) {
      const { pageCache, setPageCache } = props;
      const cacheCopy = cloneDeep(pageCache);
      const cacheIndexPage = cacheCopy.findIndex((item) => item.name === 'orderEdit');
      const orderCacheFilterObject = {
        name: 'orderEdit',
        filter: {
          filter: [
            { column: 'folderName', value: 'date' },
            { column: 'date', value: chooseOrder.date },
          ],
        },
      };
      if (cacheIndexPage === -1) {
        cacheCopy.push(orderCacheFilterObject);
        setPageCache(cacheCopy);
      } else {
        cacheCopy.splice(cacheIndexPage, 1, orderCacheFilterObject);
        setPageCache(cacheCopy);
      }
      props.history.replace({
        pathname: `/order/edit/${chooseOrder.id}`,
      });
    }
  };
  const pdfParams = {
    reportId: '8',
    AOrderID: `${chooseOrder.id}`,
    AUserFormularID: '0',
  };

  const createInvoice = async (values) => {
    const data = cloneDeep(readQuery(readQueryOrderParams));
    const idList = data.reduce((val, { id }, index) => {
      if (checkBoxList[index]) {
        return val === '' ? val + `${id}` : val + `,${id}`;
      } else {
        return val;
      }
    }, '');
    if (checkBoxList.length) {
      setCheckBoxList(checkBoxList.filter((item) => !item));
    }

    // const mutationData = await createInvoiceMutation({
    //   variables: {
    //     args: {
    //       idList: idList,
    //       id: linkId,
    //       fromDate: contex.date.date,
    //       toDate: contex.date.secondDate,
    //       facturaDate: values.invoiceDate,
    //       textDropdownId: null,
    //       textarea: '1',
    //       userId: 1,

    //     },
    //   },
    // });

    // await CreateInvoice
    //Invoiced all orders. Change on nex customer
    const finalData = data.filter((item, index) => !checkBoxList[index]);
    if (idList === '' || !finalData.length) {
      const customerArray = {
        ...client.cache.readQuery({
          query: listFA_InvoiceListNew,
          variables: contex,
        }),
      };
      const queryName = listFA_InvoiceListNew.definitions[0].name.value;
      const deepArray = cloneDeep(customerArray);
      const index = deepArray.fakturaAssist[queryName].findIndex((x) => +x.id === +linkId);
      deepArray.fakturaAssist[queryName].splice(index, 1);
      const changedData = deepArray.fakturaAssist[queryName];
      try {
        client.cache.writeQuery({
          query: listFA_InvoiceListNew,
          variables: contex,
          data: {
            fakturaAssist: {
              [queryName]: changedData,
              __typename: 'FA_Query',
            },
          },
        });

        //Change customer position
      } catch (err) {
        //console.log(err)
      }
      if (!changedData.length) {
        props.history.replace({
          pathname: '/invoice/new',
        });
      } else if (index === changedData.length) {
        props.history.replace({
          pathname: `/invoice/new/${changedData[index - 1].id}`,
        });
      } else {
        props.history.replace({
          pathname: `/invoice/new/${changedData[index].id}`,
        });
      }
    } else {
      writeQuery({ ...writeQueryOrderParams, changedData: finalData });
    }

    //Change customer position
  };

  const [localContex, setLocalContex] = useState(null);

  useEffect(() => {
    setLocalContex(contex);
  }, [JSON.stringify(contex)]);

  const [isLeftGridEmpty, setIsLeftGridEmpty] = useState(true);
  const onDetermineIsGridEmpty = (value) => {
    setIsLeftGridEmpty(value);
  };

  return (
    <div>
      <Items
        {...props}
        getValueOnStartById={(index, item) =>{
          some(item.id);
          changeChooseOrder(item);
        }}
        onDetermineIsGridEmpty={onDetermineIsGridEmpty}
        graphqlParams={{
          query: listFA_InvoiceListNew,
          variables: contex,
          fetchPolicy: isEqual(contex, localContex) ? 'cache-first' : 'cache-and-network',
        }}
        tableData={tableDeliveryDate(t)}
        data={props.customersData}
        getBlockColor={() => {}}
        getTableIndex={(id, data) => {
          some(data.id);
          setCheckBoxList([]);
          changeChooseOrder(data);
        }}
        leftGridData={props.data}
        withSearch={true}
        searchColumns={['productNr', 'nameRes']}
        withVirtualized
        selectById
        linkId={+linkId}
        FilterComponent={
          <Filter
            t={t}
            mainsection={props.mainsection}
            setFilterInfo={setFilterInfo}
            filterData={filterData}
            // dateInfo={contex && contex.date}
            setDateInfo={setDateInfo}
            graphqlParams={filterQuery}
            filterInfo={contex.filter}
            isOpenFilter={isOpenFilter}
            setOpenFilter={setOpenFilter}
            confirmedFilterInfo={confirmedFilterInfo}
            setConfirmedFilterInfo={setConfirmedFilterInfo}
            subsection={props.subsection}
          />
        }
        isOpenFilter={isOpenFilter}
        setOpenFilter={setOpenFilter}
        initialSortValue='productNr'
        dataPolicy='databaseOnly'
        leftGridSort='local'
        cutHeight={262}
      />
      {false && (
        <BlackCircle
          onClick={() => {
            changeOpenTable(!isOpenTable);
          }}
        >
          <Arrow src={isOpenTable ? ArrowWhiteDown : ArrowWhite} alt='' />
        </BlackCircle>
      )}

      {
        /* don't show right part when left grid is empty */
        !isLeftGridEmpty && (
          <Addition
            t={t}
            pdfParams={pdfParams}
            text={chooseOrder.nameRes}
            menuObj={[{ link: '/invoice/new', name: t('invoice.newInvoice') }]}
            noShowText='No invoice on this filter'
            isShow={linkId}
          >
            {/* <LoadingWrap loading={createInvoiceMutationProps.loading}> */}
            <LoadingWrap>
              <Main
                {...props}
                checkBoxList={checkBoxList}
                localLinkId={localLinkId}
                localContex={localContex}
                orderVariables={orderVariables}
                isLeftGridEmpty={isLeftGridEmpty}
                changeChooseOrder={changeChooseOrder}
                setCheckBoxList={setCheckBoxList}
                orderSelectingMode={orderSelectingMode}
                setOrderSelectiongMode={setOrderSelectiongMode}
                getOrderEditPage={getOrderEditPage}
                linkId={+linkId}
                orderId={chooseOrder.id}
                date={contex.date?.date}
                secondDate={contex.date?.secondDate}
                createInvoice={createInvoice}
              />
            </LoadingWrap>
          </Addition>
        )
      }
    </div>
  );
};

export default withPDFActions(NewInvoice);

const BlackCircle = styled.div`
  height: 32px;
  width: 32px;
  box-shadow: 0 4px 1px 0 rgba(207, 207, 207, 0.32);
  background-color: #3e4c57;
  border-radius: 16px;
  position: absolute;
  bottom: -15px;
  left: 96%;
`;

const Arrow = styled.img`
  display: block;
  margin: auto;
  top: 50%;
  margin-top: 45%;
`;
