import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import search from 'style/img/search.svg';
import { useTranslation } from 'react-i18next';

//const ENTER_KEY = 13; // press Enter key
const InputWithSearch = (props) => {
  const [t] = useTranslation();
  const inputRef = useRef(null);

  
  useEffect(()=> {
    props.onSearch('');
    inputRef.current.value = '';
  }, [props.mainsection]);
  
  useEffect(()=> {
    props.onSearch(inputRef.current.value);
  }, [props.data[0]]);
  
  const acceptChange = () => {
    props.onSearch(inputRef.current.value);
  };

  const onChange = () => {
    acceptChange();
  };

  // const onKeyDown = (e) => {
  //   if (e.keyCode === ENTER_KEY) {
  //     acceptChange();
  //   }
  // };

  return (
    <Col lg={12}>
      <Inputs
        innerRef = {inputRef}
        onChange={debounce(onChange, props.debounceDelay)}
        // onKeyDown={onKeyDown}
        disableRadius={props.disableRadius}
        marginBottom={props.marginBottom}
        placeholder={t('common.search') + '...'}
        type='text'
      />
      <IconSearch onClick={acceptChange}>
        <img style={{ height: '15px', width: '15px' }} src={search}/>
      </IconSearch>
    </Col>
  );
};

InputWithSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  debounceDelay: PropTypes.number,
};

InputWithSearch.defaultProps = {
  onSearch: () => {
    console.warn('');
  },
  debounceDelay: 1000,
};

export default InputWithSearch;

const Inputs = styled.input`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0px')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0px')};
  width: 100%;
  padding-left: 20px;
  height: 39px;
  border: 1px solid #CDCDCD;
  border-radius: ${(props) => (props.disableRadius ? 0 : '20px')};

  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #afafaf;
  &::placeholder {
    font-family: 'Montserrat', sans-serif;
  }
`;

const IconSearch = styled.div`
  cursor: pointer;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 20px;
  top: 9px;
  &:hover {
    > img {
      height: 17px !important;
      width: 17px !important;
    }
  }
`;


