import gql from 'graphql-tag';

export default gql`
mutation updateFA_ProductionEdit($fields: InputFA_ProductionEditDefault!, $id: ID!){
    fakturaAssist{
    updateFA_ProductionEdit(fields: $fields, id: $id){
		showSemiProductsDetails
    }
    
  }
}`;
