import React, { useEffect, useState } from 'react';
import Formik from 'components/formik';
import styled from 'styled-components';
import bwipjs from 'bwip-js';

import { Row, Col } from 'style/index';

import { Input, Checkbox, Button } from 'components/elements';
import { useTranslation } from 'react-i18next';

const EAN = (props) => {
  const [t] = useTranslation();
  const [error, setError] = useState({ text: '', isErr: false });
  const [isVisible, setIsVisible] = useState(false);

  const checkEAN = (value) => {
    // console.log({ value}, props.data.EANNr)
    if(value) {
      try {
        bwipjs.toCanvas('mycanvas', {
          bcid: 'ean13', // Barcode type
          text: value, // Text to encode 5449000027757 4820000430012
          scale: 3, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          guardwhitespace: true,
          textxalign: 'center', // Always good to set this
        });
        setError({ text: '', isErr: false });
        setIsVisible(true);
      } catch (e) {
        const err = e.toString();
        setIsVisible(false);
        // ean13badCheckDigit
        // ean13badLength
        if(err.match(/ean13badCheckDigit/)) {
          setError({ isErr: true, text: t('product.eanIncorrect') });
        } else if(err.match(/ean13badLength/)) {
          setError({ isErr: true, text: t('product.eanMustBe12or13') });
        } else {
          setError({ isErr: true, text: err });
        }
      }
    } else {
      setError(false);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    setError({ isErr: false, text: '' });
    setIsVisible(false);
  }, [props.data?.EANNr]);

  useEffect(() => {
    checkEAN(props.data.EANNr);
  }, [props]);

  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize
    >
      {({ values, handleChange }) => {
        return (
          <>
            <Row>
              <Col width='310px'>
                <Input
                  withoutFormik={true}
                  onChange={handleChange}
                  value={values.EANNr}
                  field='EANNr'
                  name={'EANNr'}
                  maxlength={13}
                  readonly={values.isEANmanagedByCA}
                  title={t('product.eanNumber')}/>
              </Col>
              <Col lg={4}>
                <ButtonStyled onClick={() => checkEAN(values.EANNr)} value={`${t('common.test')}/${t('product.completeEan')}`} className='black' upperCase/>

              </Col>
            </Row>
            <Row>
              <CheckboxStyled value={values.isEANmanagedByCA} field='isEANmanagedByCA' title={`${t('product.eanManagedBy')} CashAssist`} margin='10px 0'/>
            </Row>
            <Row>
              {error.isErr && <spanError style={{ color: 'red' }}>{error.text}</spanError>}
              <StyledCanvas id='mycanvas' isVisible={isVisible}/>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};

export default EAN;

const StyledCanvas = styled.canvas`
  visibility: ${(props) => props.isVisible ? 'visible' : 'hidden'};
  ${(props) => !props.isVisible ? 'height: 0;' : ''}
`;

const ButtonStyled = styled(Button)`
    margin-top: 36px;
`;

const CheckboxStyled = styled(Checkbox)`
  width: 400px;
`;

const spanError = styled.span`

`;
