//import email from 'style/img/actions/email.svg';
import reports from 'style/img/actions/reports.svg';
import deleteImg from 'style/img/actions/delete.svg';
import info from 'style/img/list/info.svg';

export const allOperations = (t) =>([
 
  {
    action: 'info',
    icon: info,
  },
  {
    action: 'delete',
    icon: deleteImg,
  },
  {
    action: 'copy',
    icon: reports,
  },
  
]);

