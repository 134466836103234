import gql from 'graphql-tag';

export default gql`
query listFA_CustomerListsDefault{
    fakturaAssist{
    listFA_CustomerListsDefault{
		id
		name
		
    }
    
  }
}`;
