import React, { Fragment } from 'react';
// import styled from 'styled-components';

import Block from './components/Block';

export default (props) => {

  return (
    <Fragment>
      <Block id={props?.data?.id} props={props}/>
      {/* <Block id={props?.data?.id} props={values} handleChange={handleChange} /> */}
    </Fragment>
  );
};
