import Invoice from './pages/invoice';
import Language from './pages/language';
import Order from './pages/order';
import WebOrder from './pages/webOrder';
import Production from './pages/production';
import Tax from './pages/tax';
import Post from './pages/post';

import Customer from './pages/customer';
import Product from './pages/product';
import SpecialDays from './pages/specialDays';
import CashAssit from './pages/cashAssist';

import { updateFA_Config } from '../qql/mutation';

export default (t) => ({
  link: 'configurations',
  subsections: [
    {
      name: t('customer.customer'),
      link: 'customer',
      Component: Customer,
      updateMutation: updateFA_Config,
      fields: [
        'invoiceNumber',
        'roundingTotalPriceMethodID', 'isAddSummaryToInvoiceReportPDF',
        'isAddSummaryToInvoiceReportPDF',
        'isUseAnotherCurrencies', 'euroRate', 'usdRate',
      ],
    },
    {
      name: t('product.product'),
      link: 'product',
      Component: Product,
      updateMutation: updateFA_Config,
      fields: [
        'articlePriceCategory', 'isSameEANForMultipleArticles',
        'productGroup',
      ],
    },
    {
      name: t('invoice.invoice'),
      link: 'invoice',
      Component: Invoice,
      updateMutation: updateFA_Config,
      fields: [
        'invoiceNumber',
        'roundingTotalPriceMethodID', 'isAddSummaryToInvoiceReportPDF',
        'isAddSummaryToInvoiceReportPDF',
        'isUseAnotherCurrencies', 'euroRate', 'usdRate',
      ],
    },
    {
      name: t('common.language'),
      link: 'language',
      Component: Language,
      updateMutation: updateFA_Config,
      fields: [
        'invoiceNumber',
        'roundingTotalPriceMethodID', 'isAddSummaryToInvoiceReportPDF',
        'isAddSummaryToInvoiceReportPDF',
        'isUseAnotherCurrencies', 'euroRate', 'usdRate',
      ],
    },
    {
      name: t('order.order'),
      link: 'order',
      Component: Order,
      updateMutation: updateFA_Config,
      fields: [
        'invoiceNumber', 'isDeliveryInMonday', 'isDeliveryInTuesday',
        'isDeliveryInWednesday', 'isDeliveryInThursday', 'isDeliveryInFriday',
        'isDeliveryInSaturday', 'isDeliveryInSunday', 'isAutomaticDeliveryDay',
        'isOrderPricePrior', 'roundingArticlePriceMethodID',
        'importOrdersPositionSorting', 'isShippingNote', 'isDelivery',
      ],
    },
    {
      name: t('order.modals.webOrder'),
      link: 'webOrder',
      Component: WebOrder,
      updateMutation: updateFA_Config,
      fields: [
        'webOrderAutoPrint',
      ],
    },
    {
      name: t('common.tax'),
      link: 'tax',
      Component: Tax,
      updateMutation: updateFA_Config,
      fields: [
        'countryId',
        'invoiceNumber',
        'roundingTotalPriceMethodID', 'isAddSummaryToInvoiceReportPDF',
        'isAddSummaryToInvoiceReportPDF',
        'isUseAnotherCurrencies', 'euroRate', 'usdRate',
      ],
    },
    {
      name: t('common.post'),
      link: 'post',
      Component: Post,
      updateMutation: updateFA_Config,
      fields: [
        'invoiceNumber',
        'roundingTotalPriceMethodID', 'isAddSummaryToInvoiceReportPDF',
        'isAddSummaryToInvoiceReportPDF',
        'isUseAnotherCurrencies', 'euroRate', 'usdRate',
      ],
    },
    {
      name: t('production.production'),
      link: 'production',
      Component: Production,
      updateMutation: updateFA_Config,
      fields: [
        'orderTypesInProductionReports',
      ],
    },
    {
      name: t('configurations.specialDays'),
      link: 'specialDays',
      Component: SpecialDays,
      updateMutation: updateFA_Config,
      fields: [
        'invoiceNumber',
        'roundingTotalPriceMethodID', 'isAddSummaryToInvoiceReportPDF',
        'isAddSummaryToInvoiceReportPDF',
        'isUseAnotherCurrencies', 'euroRate', 'usdRate',
      ],
    },
    {
      name: t('common.cashAssist'),
      link: 'cashAssist',
      Component: CashAssit,
      updateMutation: updateFA_Config,
      fields: [
        'isCAPrintFilial', 'isCAPrintRetour',
        'isCAPrintCustomer', 'amountRepresentationKindId', 'isCAOrderExportActive',
      ],
    },
  ],
  
});
