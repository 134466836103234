import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query{
    fakturaAssist{
    	listFA_Forms{
	    	id
            form
            name
            formId
            formIdDefault
	 	}
 	}
}`;

