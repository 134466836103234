import React, { useState, useEffect } from 'react';

const withQuery = (WrappedComponent) => (props) =>{
  console.log(props);
  //console.log(props.data.fill(true) || [])
  const [checkBoxList, changeCheckboxList] = useState([]);
  useEffect(()=>{
    if(props.checkBoxList && props.checkBoxList.length) {
      changeCheckboxList(props.checkBoxList);
    }else{
      changeCheckboxList(new Array(props.data.length).fill(true));
    }
  }, [props.data]);
    
  console.log(checkBoxList);
  return <WrappedComponent {...props} checkboxList={checkBoxList} changeCheckboxList={changeCheckboxList}/>;
};
export default withQuery;
