export const additionalMenuObj = (t) => ([
  {
    name: t('order.ordersOverview'),
    link: '/order/overview/order',
  },
  {
    name: t('order.articleOverview'),
    link: '/order/overview/articles',
  },
  {
    name: t('order.customerWithoutOrders'),
    link: '/order/overview/withoutOrders',
  },
  {
    name: 'Dough overview',
    link: '/order/overview/dough',
  },
]);
