import gql from 'graphql-tag';

export default gql`
query ($id: ID!){
    fakturaAssist{
    	getFA_GetOrderPositions(id: $id){
				id
				productTable{
					id
					productLink
					productLinkLookNr
					productLinkLookName
					quantity
					labelRecipeLink
					virtualPositionLink
					orderPositionID
					taxCode
					price
					total
					discount
					discountSpec
					weight
					productNr
					productName
					addition
					fax
					email
					internet
					additive
					indTextProduction
					indTextDeliveryNote
				}
			}
 	}
}`;
