import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'style';

import DefaultFilter from 'components/filter/DefaultFilter';

import { RadioGroup, Calendar, Dropdown, Input } from 'components/elements';
import { filterFolder } from '../params';

const FilterOrder = (props) => {
  const { t } = props;
  const [localFilterInfo, setLocalFilterInfo] = useState(props.filterInfo);
  const onConfirm = () => {
    props.setFilterInfo({ ...localFilterInfo, folderName: localFilterInfo.folderName });
    //Close filter after accept
    props.setOpenFilter(false);
  };

  const changeFolderName = (e) => {
    setLocalFilterInfo({ ...localFilterInfo, folderName: e.target.value });
  };

  const changeDate = (date) => {
    setLocalFilterInfo({ ...localFilterInfo, date: date });
  };

  const renderComponentUnderRadio = (value) =>{
    const renderDataRange = () => {
      return (
        <DateRangBlock>
          <Col lg={2}> <Calendar withoutFormik onChange = {changeDate} value={localFilterInfo && localFilterInfo.date} title={t('common.firstDate')}/></Col>
          <Col lg={2}> <Calendar withoutFormik onChange = {changeDate} value={localFilterInfo && localFilterInfo.date} title={t('common.secondDate')}/></Col>
        </DateRangBlock>
      );
    };

    const renderNotesRange = () => {
      return (
        <DateRangBlock>
          <Col lg={2}> <Input title={t('common.firstRange')} small/></Col>
          <Col lg={2}> <Input title={t('common.secondRange')} small/></Col>
        </DateRangBlock>
      );
    };
    switch(value) {
      case 'date':
        return <RadioBlock><Calendar withoutFormik onChange = {changeDate} value={localFilterInfo && localFilterInfo.date} title={t('common.date')}/></RadioBlock>;
      case 'dateRange':
        return renderDataRange();
      case 'customer':
        return <RadioBlock><Dropdown title={t('common.customer')} data={[]}/></RadioBlock>;
      case 'deliveryNotesRange':
        return renderNotesRange();
      default:
        return null;
    }
  };

  return (
    <DefaultFilter
      onClose={() => props.setOpenFilter(false)}
      onConfirm={onConfirm}
      t={t}
    >
      <RadioGroup
        field='folderName'
        listItems={filterFolder(t)}
        values={{ folderName: localFilterInfo.folderName }}
        onClick={changeFolderName}
        renderComponentUnderRadio = {renderComponentUnderRadio}
      />
    </DefaultFilter>
  );
};

export default FilterOrder;

const RadioBlock = styled.div`
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #fff;
    height: 100%;  
`;

const DateRangBlock = styled(Row)`
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
`;
