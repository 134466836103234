import gql from 'graphql-tag';

export default gql`
mutation MutationFA_CustomerDiscountChange($mutationCreate: [InputFA_CustomerDiscountChangeData], $mutationUpdate: [InputFA_CustomerDiscountChangeData], $arrIdDelete: [Int], $discountsNames: [String], $idDiscount: Int){
    fakturaAssist{
    MutationFA_CustomerDiscountChange(mutationCreate: $mutationCreate, mutationUpdate: $mutationUpdate, arrIdDelete: $arrIdDelete, discountsNames: $discountsNames, idDiscount: $idDiscount){
    names
    }
    
  }
}`;
