import gql from 'graphql-tag';

export default gql`
query getFA_Config {
  fakturaAssist{
    getFA_Config {
		toolboxImportPathForPrintingService
		usePrintingServiceForToolboxImport
    }
  }
}`;
