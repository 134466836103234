import gql from 'graphql-tag';

export default gql`
mutation updateFA_Discount($id: ID!, $fields: FA_DiscountField!){
    fakturaAssist{
    updateFA_Discount(id: $id, fields: $fields){
		id
		name
    }
    
  }
}`;
