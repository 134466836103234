import gql from 'graphql-tag';

export default gql`
query listFA_ProductionSorting($valueGroup: Int!, $languageId: Int){
    fakturaAssist{
    listFA_ProductionSorting(valueGroup: $valueGroup, languageId: $languageId){
		id
		name
    }
    
  }
}`;
