import gql from 'graphql-tag';

export default gql`
query getProduct($id: ID!) {
  fakturaAssist {
    getProduct(id: $id){
      id
      name
      productImage
      productNumber
      designation
      isTitle
      isNetto
      isActivated
      linkGroup
      linkGroup2
      linkGroup3
      amount
      caLinkTempalte
      caVisibilityStatusID
      salesGroupId
      memo
      priceUnit
			taxChild
      willReplacedWithProductionCalc
      isBruchTitel
      bruchTitelAnzahlTeile
      isBruchRound
      bruchSummierenAuf
      bruchVerwendeteTeile
      linkRecipe
      isDough
      isFermentationInterrupt
      linkDough
      isBakingSheet
      numberPerSheet
      numberOfSheetsPerCat
      doughInsert
      factorLtKg
      roundUp
      isBatterSplitting
      batterSplitting1
      batterSplitting2
      descriptionAnotherLng
      weight
      orderPeriodInDays

      deliveryMonday
      deliveryTuesday
      deliveryWednesday
      deliveryThursday
      deliveryFriday
      deliverySaturday
      deliverySunday
      
      label2
      EANNr
      storageCustomerText
      infoCustomerText
      isEANmanagedByCA
      linkLabelRecipe
      origin
      declaration
      consumeDays
      salesWeight
      forSaleDays
      helfLifeDays
      mustBeKeptCool
      storage
    }
  }
}`;
