
import gql from 'graphql-tag';

export default gql`
query listFA_ProductionFreezer($date:String){
    fakturaAssist{
    listFA_ProductionFreezer(date: $date){
		LinkSourceArticle
		SourceArticleNo
		SourceArticleName
		AvailableQuantity
		RequiredByNormalCustomers
		RequiredByFlexibleCustomers
		LinkKunde
		KundenNr
		Name
		OrderedArticleNo
		OrderedArticleName
		LieferscheinNr
		LieferscheinPositionIntern
		LieferscheinIntern
		Menge
		Multiplier
    }
    
  }
}`;
