// eslint
import gql from 'graphql-tag';

export default gql`
mutation createFA_ProductionEdit($args: FA_ProductionEditCreate){
    fakturaAssist{
    createFA_ProductionEdit(args: $args){
		  id
    }
  }
}`;
