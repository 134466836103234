import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query getFA_CustomerCreatedOrder ($id: ID!){
    fakturaAssist{
    	getFA_CustomerCreatedOrder(id: $id){
	    	id
            customerLink
            comment
            caCashierId
            caRezeptrNr
            caDeliveryTime
            caDeliveryType
            caUser
	 	}
 	}
}`;
