import gql from 'graphql-tag';

export default gql`
query listFA_Assortment{
    fakturaAssist{
    	listFA_Assortment{
    		id
		    name
 		}
 	}
}`;
