import gql from 'graphql-tag';

export default gql`
mutation callFA_EditOffer($args: FA_EditOfferInputs!){
    fakturaAssist{
    	callFA_EditOffer(args: $args){
    		errorCode
        errorText
        offerID
        offerNr
 		}
 	}
}`;
