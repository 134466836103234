import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'components/elements';

import TableGrid from 'components/tableGrid/TableGrid';
import listFA_Forms from '../qql/listFA_Forms';
import useQuerySpecial from 'components/graphql/useQuerySpecial';

const tableData = [

  {
    name: 'Form',
    dataBase: 'form',
  },
];

const MultiOrdersModal = ({ closeModal, data, multiOrderModalOnAccept, avaliableReportForms, cachePDFParams, showPDFPreview }) => {
  const [cachePDFParamsState, setCachePDFParamsState] = useState(cachePDFParams);
  const allForms = useQuerySpecial(listFA_Forms, { fetchPolicy: 'cache-first', variables: {
    languageId: +localStorage.getItem('lngId') || 1,
  } });
  const actualData = allForms.data ? allForms.data.filter(({ formIdDefault })=>avaliableReportForms.includes(formIdDefault)) : [];
  const onTableClick = (index, item) => {
    setCachePDFParamsState({ ...cachePDFParamsState, reportId: item.formIdDefault.toString() });
  };
  return (
    <ModalBody>
      <TableGrid
        {...allForms}
        data = {actualData }
        tableData={ tableData }
        height={160}
        getTableIndex={onTableClick}

      />
      <BottomBlock>
        <Col lg={3} lgOffset={1}>
          <Button value = 'Cancel' className='black' upperCase onClick={closeModal}/>
        </Col>
        <Col lg={3} lgOffset={1}>
          <Button value = 'Print' className='transparent' upperCase onClick={closeModal}/>
        </Col>
        <Col lg={3} lgOffset={1}>
          <Button value = 'Show preview' className='grey' upperCase onClick={() => {
            showPDFPreview(cachePDFParamsState);
            closeModal();
          }} />
        </Col>
      </BottomBlock>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 15px;
`;
const BottomBlock = styled(Row)`
    margin-top: 40px;
    margin-bottom: 20px;
    margin-right: 20px;
`;

export default MultiOrdersModal;


