import React from 'react';
import { Col, Row } from '../../../../../../style';
import { Calendar, Checkbox, TextAreaStandart } from '../../../../../../components/elements';
import styled from 'styled-components';

const DateModal = (t, isSmall) => (props) => {
  const { values } = props;
  return (
    <>
      <Row>
        <Col lg={1}>
          <Calendar
            withoutFormik={true}
            dateFormat
            name='fromDate'
            title={t('common.fromDate')}
            value={values.fromDate}
            setFieldValue={props.setFieldValue}
          />
        </Col>
        <Col lg={1}>
          <Calendar
            withoutFormik={true}
            dateFormat
            name='toDate'
            title={t('common.toDate')}
            value={values.toDate}
            setFieldValue={props.setFieldValue}
          />
        </Col>
      </Row>
      {!isSmall && (
        <>
          <Row>
            <Choose>{t('common.chooseDays')}</Choose>
          </Row>
          <Row>
            <Col width={'130px'}>
              <Checkbox withoutFormik onChange={(name, value) => {
                props.setFieldValue('isMonday', value);
                props.setFieldValue('isTuesday', value);
                props.setFieldValue('isWednesday', value);
                props.setFieldValue('isFriday', value);
                props.setFieldValue('isSunday', value);
                props.setFieldValue('isSaturday', value);
                props.setFieldValue('isThursday', value);
              }} value={values.isMonday && values.isTuesday && values.isThursday && values.isWednesday && values.isFriday && values.isSaturday && values.isSunday} field='allDays'
              title={t('common.days.allDays')} margin={'12px 0 0 0'}/>
            </Col>
          </Row>
          <Row>
            <Col width={'130px'}>
              <Checkbox
                withoutFormik={true}
                onClick={props.handleChange('isMonday')}
                value={values.isMonday}
                name='isMonday'
                title={t('common.days.monday')}
                margin={'9px 0 0 0'}/>

              <Checkbox withoutFormik={true} onClick={props.handleChange('isTuesday')} value={values.isTuesday} name='isTuesday' title={t('common.days.tuesday')}
                margin={'9px 0 0 0'}/>
            </Col>
            <Col width={'130px'}>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isWednesday')} value={values.isWednesday} name='isWednesday' title={t('common.days.wednesday')}
                margin={'9px 0 0 0'}/>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isThursday')} value={values.isThursday} name='isThursday' title={t('common.days.thursday')}
                margin={'9px 0 0 0'}/>
            </Col>
            <Col width={'130px'}>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isFriday')} value={values.isFriday} name='isFriday' title={t('common.days.friday')}
                margin={'9px 0 0 0'}/>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isSaturday')} value={values.isSaturday} name='isSaturday' title={t('common.days.saturday')}
                margin={'9px 0 0 0'}/>
            </Col>
            <Col width={'130px'}>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isSunday')} value={values.isSunday} name='isSunday' title={t('common.days.sunday')}
                margin={'9px 0 0 0'}/>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col width='510px'>
          <TextAreaStandart
            withoutFormik={true}
            onChange={props.handleChange}
            value={values.description}
            name={'description'}
            title={t('common.note')}
            margin={'17px'}
            width='100%'
          />
        </Col>
      </Row>
      {!isSmall && (
        <>
          <Row>
            <Col width={'130px'}>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isRepeatYearly')} value={values.isRepeatYearly} name='isRepeatYearly' title={t('customer.repeatYearly')}
                margin={'15px 0 0 0'}/>
            </Col>
          </Row>
          <Row>
            <Col width={'160px'}>
              <Checkbox withoutFormik={true} onClick={props.handleChange('isCustomWeekDaySetting')} value={values.isCustomWeekDaySetting} name='isCustomWeekDaySetting'
                title={t('customer.customWeekDays')} margin={'15px 0 0 0'}/>
            </Col>
          </Row>
        </>
      )
      }

    </>
  );
};

const Choose = styled.p`
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
  margin: 18px 0 0 0;
`;

export default DateModal;
