import React from 'react';

import Query from '../graphql/Query';
import PageNotFound from '../PageNotFound';

const ap = ({ itemQuery, linkId, children, withCacheAddSection }) => {
  const id = parseInt(linkId);
  return (
    itemQuery ? <Query query={itemQuery} variables={{ id: id }} skip={!id} fetchPolicy={'cache-and-network'}>
      {({ data, loading, error, fetchMore })=>{
        if(data ? Object.keys(data).length !== 0 && withCacheAddSection : false) {
          return children({ data, fetchMore });
        }
        if(loading) {
          return children({ data: data, loading: loading });
        }
        if(error) {
          return <PageNotFound type='NETWORK_ERROR'/>;
        }
        if(!data && !loading) {
          return <PageNotFound type='NO_ITEM'/>;
        } else{
          return children({ data, loading, fetchMore });
        }
      }}
    </Query> : children({ data: [] })
  );
};

export default ap;
