import React from 'react';
import { LoadingWrap } from 'style/default';

const SubSection = (props) =>(
  <LoadingWrap {...props} loading={props.loading}>

    {props.sectionInfo.subSection.map((item, index) =>{
      const { Component } = item;
      return props.subsection === props.sectionInfo.subSection[index].link && <Component {...props} key={index} subSectionInfo={item}/>;
    })}
  </LoadingWrap>
);

export default SubSection;


