import React, { useState, useMemo, useEffect } from 'react';

import DefaultFilter from 'components/filter/DefaultFilter';

import { RadioGroup } from 'components/elements';
import { Dots } from 'components/Loading/index';
import DateRange from 'components/elements/dateRange/DateRange';
import { branch, compose } from 'recompose';
import withQuery from '../hoc/withQuery';
import styled from 'styled-components';
import { cloneDeep, uniqBy } from 'lodash';

const getFieldsWithLevels = (data, res = {}, level = 0) => {
  if (!data) return res;
  if (data.field) res[data.field] = level;

  if (data.subFields) data.subFields.forEach((item) => res[item] = level);

  if (Array.isArray(data)) {
    data.forEach((item) => {
      res = { ...res, ...getFieldsWithLevels(item, res, level) };
    });
    return res;
  }
  return getFieldsWithLevels(data.subItems || data.additionalInputs, res, level + 1);
};

const Filter = (props) => {
  const {
      t,
      filterData,
      restContexData,
      data,
      loading,
      dateInfo,
      setDateInfo,
      setFilterInfo,
      filterInfo,
      restContexInfo,
      filterRaw,
      getSecondDateVal,
      mainsection,
      setConfirmedFilterInfo,
      confirmedFilterInfo,
      parentHandler,
      subsection,
    } = props,
    [localFilterInfo, setLocalFilterInfo] = useState(props.filterInfo),
    [localDateInfo, setLocalDateInfo] = useState(props.dateInfo),
    [localRestContex, setLocalRestContex] = useState(restContexInfo || []);
    
  const closeFilter = () => props.setOpenFilter(false);
    
  useEffect(() => {
    setLocalFilterInfo(filterInfo);
  }, [filterInfo]);

  useEffect(() => {
    return () => {
      closeFilter();
    };
  }, []);

  useEffect(() => {
    return () => {
      closeFilter();
    };
  }, [mainsection]);

  const defaultFilterInfo = filterInfo.map((item, index) => {
    const returnVal = { ...item };

    if(index === 0) {
      returnVal.column = filterData[0]?.field;
      returnVal.value = filterData[0]?.value?.toString();
    }
    return returnVal;
  });

  const key = typeof confirmedFilterInfo === 'object' ? Object.keys(confirmedFilterInfo).find((name) => name === mainsection) : '';

  const levels = useMemo(() => getFieldsWithLevels(filterData), [filterData]);
  const onConfirm = () => {
    setFilterInfo(localFilterInfo, localRestContex);
    if (setDateInfo) setDateInfo(localDateInfo);

    if(setConfirmedFilterInfo) {
      if(parentHandler === 'Main_List') {
        setConfirmedFilterInfo({ ...confirmedFilterInfo, [key]: localFilterInfo });
      } else {
        setConfirmedFilterInfo(localFilterInfo);
      }
    }
    let newLocalFilterInfo = cloneDeep(localFilterInfo);
    if (mainsection === 'offer') {
      const currentFolder = localFilterInfo?.find((item) => !(['date', 'secondDate'].includes(item.column)));
      newLocalFilterInfo = [currentFolder, ...localFilterInfo?.filter((item) => item.column !== currentFolder.column)];
    }
    // Set to localStorage confirmed filter
    localStorage.setItem(subsection ? `${mainsection}/${subsection}` : mainsection, JSON.stringify(newLocalFilterInfo));

    // Set to localStorage deleted records
    if(restContexData) {
      localStorage.setItem(`${mainsection}/${subsection}/${localRestContex[0].column}`, JSON.stringify(localRestContex));
    }

    //Close filter after accept
    closeFilter();
  };

  const changeFilter = (field, value, keepItemsOnLevel = false) => {
    if ((!value && value !== 0 && value !== '') || !field) return;
    // Problem with query filter params here
    const fieldLevel = levels[field];
    const idx = localFilterInfo.findIndex((item) => item.column === field);
    let filterCopy = [...localFilterInfo]; // localFilterInfo not sync with filterInfo prop
    if (idx > -1) {
      filterCopy[idx].value = value;
      if (!keepItemsOnLevel) {
        filterCopy = filterCopy.filter((item) => levels[item.column] < fieldLevel || (levels[item.column] === fieldLevel && field === item.column));
      } else {
        filterCopy = filterCopy.filter((item) => levels[item.column] <= fieldLevel);
      }
    } else {
      const fieldOnSameLevelIdx = filterCopy.findIndex((item) => levels[item.column] === fieldLevel);
      if (fieldOnSameLevelIdx > -1 && !keepItemsOnLevel) {
        filterCopy.splice(fieldOnSameLevelIdx, 1);
      }
      filterCopy.push({ column: field, value: value });
    }
    // temp solution that fix date range button behaviour
    if (filterCopy[0] && filterCopy[0].value === 'dateRange' && filterCopy[0].column === 'folderName') {
      filterCopy.push(filterInfo[1]);
      filterCopy = uniqBy(filterCopy, 'column');
    }
    setLocalFilterInfo(filterCopy);
  };

  const values = Object.fromEntries((localFilterInfo || []).map((item) => [item.column, item.value]));

  const hasEmptyFields = (fieldValue) => {
    if (!values.folderName) return false;
    const topLevelItem = filterData.find((item) => item.field === 'folderName' && item.value === fieldValue);
    if (!topLevelItem || !topLevelItem.additionalInputs) return false;
    for (const item of topLevelItem.additionalInputs) {
      if (item.field) {
        if (!values[item.field]) {
          return true;
        }
      } else if (item.subFields) {
        for (const field of item.subFields) {
          if (!values[field]) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const changeDate = (field, value) => {
    setLocalDateInfo({ ...localDateInfo, [field]: value });
  };

  const resetFilter = () => {
    setLocalFilterInfo([]);
  };

  const handleRestContexChange = (index, value) => {
    console.log({ index, value, restContexInfo });
    const clonedRestContexInfo = cloneDeep(restContexInfo);
    clonedRestContexInfo[index].value = value;
    setLocalRestContex(clonedRestContexInfo);
  };

  const isDateRangeInvalid = () => {
    const { date, secondDate } = values;
    if (date && secondDate && date < secondDate) {
      return false;
    }
    return true;
  };

  if (loading) return <LoadingWrapper><Dots radius='25px'/></LoadingWrapper>;
  const isButtonDisabled = values.folderName && values.folderName === 'dateRange' ? isDateRangeInvalid() : values.folderName && hasEmptyFields(values.folderName);
  return (
    <DefaultFilter
      onClose={() => {
        if(parentHandler === 'Main_List') {
          setFilterInfo(!confirmedFilterInfo[key].length ? defaultFilterInfo : confirmedFilterInfo[key]);
        } else {
          setFilterInfo(((confirmedFilterInfo && !confirmedFilterInfo.length) ? defaultFilterInfo : confirmedFilterInfo), [{
            column: 'isDelete',
            value: restContexInfo?.[0]?.value || false,
          }]);
        }
        closeFilter();
      }}
      onConfirm={onConfirm}
      t={t}
      isButtonDisabled={(values.folderName === 'customerGroup' && values.hasOwnProperty('groupId')) ? false : isButtonDisabled} // temporary
    >
      <FlexBox>
        {dateInfo && <DateRange values={localDateInfo} t={t} startField={'date'} endField={'secondDate'} onChange={changeDate} withoutFormik/>}
      </FlexBox>
      {
        restContexData && (
          <div style={{ width: 250, margin: '5px 0' }} >
            {restContexData.map(({ title, field, Component }) => {
              const idx = localRestContex.findIndex(({ column }) => field === column);
              const contexData = localRestContex[idx];
              return (<Component
                key={field}
                withoutFormik
                onClick={(value) => handleRestContexChange(idx, value)}
                open={contexData.value}
                title={title}
                value={contexData.value}
              />);
            })}
          </div>
        )
      }
      <RadioGroup
        field='folderName'
        data={data}
        values={values}
        onFilterChange={changeFilter}
        filterData={filterData || []}
        filterInfo={filterInfo}
        resetFilter={resetFilter}
        filterRaw={filterRaw}
        getSecondDateVal={getSecondDateVal}
      />
    </DefaultFilter>
  );
};

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default compose(branch((props) => props.graphqlParams, withQuery))(Filter);

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

// [{column: 'test', value: 1}]
// {test: 1}
