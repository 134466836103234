import { IS_LOCALHOST, isDeployedToDevTestEndpoint } from 'config/consts';

export const GRAPHQL_API_URI = process.env.REACT_APP_DEV ? (
  'http://localhost:4000/dev' // if you wanna get data from local server run 'npm run dev' command
) : (
  (IS_LOCALHOST || isDeployedToDevTestEndpoint) ? 'https://devapi.hssoft.com/graphql' : 'https://api.hssoft.com/graphql'
);

// dev:
//   web graphql endpoint POST - https://devapi.hssoft.com/graphql
//   android graphql endpoint POST - https://devapi.hssoft.com/graphql/device
//   subscriptions endpoint - wss://devws.hssoft.com/subscribe
//   x-api-key: 3pW75OOrT76A0GI0Hp9wx8nC1grQe4x89xDi5vwE
//   introspection POST - https://devapi.hssoft.com/graphql/introspection
//   x-api-key: OmZ0uEpHA58tHCdFS4GWOaAvWZu9jHB19Or3m7s2
//   credentials endpoint POST - https://devapi.hssoft.com/client/credentials
//   reportcrash endpoint POST - https ://devapi.hssoft.com/client/reportcrash
// prod:
//   web graphql endpoint POST - https://api.hssoft.com/graphql
//   android graphql endpoint POST - https://api.hssoft.com/graphql/device
//   subscriptions endpoint - wss://ws.hssoft.com/subscribe
//   x-api-key: W7fDeHIMHeHp9BMFWpBLprSgoZVZP3aAGMAeBW80
//   introspection POST - https://api.hssoft.com/graphql/introspection
//   x-api-key: qFreo7dgz25pXhPs35VKleSZZBe7ymgasSVISHCj
//   credentials endpoint POST - https://api.hssoft.com/client/credentials
//   reportcrash endpoint POST - https://api.hssoft.com/client/reportcrash
