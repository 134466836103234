export const mounths = (t) => [
  {
    id: 1,
    name: t('common.months.jan'),
  },
  {
    id: 2,
    name: t('common.months.feb'),
  },
  {
    id: 3,
    name: t('common.months.mar'),
  },
  {
    id: 4,
    name: t('common.months.apr'),
  },
  {
    id: 5,
    name: t('common.months.may'),
  },
  {
    id: 6,
    name: t('common.months.jun'),
  },
  {
    id: 7,
    name: t('common.months.jul'),
  },
  {
    id: 8,
    name: t('common.months.aug'),
  },
  {
    id: 9,
    name: t('common.months.sep'),
  },
  {
    id: 10,
    name: t('common.months.oct'),
  },
  {
    id: 11,
    name: t('common.months.nov'),
  },
  {
    id: 12,
    name: t('common.months.dec'),
  },

];

export const daysArray = (t) => ([
  {
    id: 1,
    name: t('common.days.monday'),
  },
  {
    id: 2,
    name: t('common.days.wednesday'),
  },
  {
    id: 3,
    name: t('common.days.friday'),
  },
  {
    id: 4,
    name: t('common.days.sunday'),
  },
  {
    id: 5,
    name: t('common.days.tuesday'),
  },
  {
    id: 6,
    name: t('common.days.thursday'),
  },
  {
    id: 7,
    name: t('common.days.saturday'),
  },
    
]);
  
