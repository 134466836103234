import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { useMutation } from 'components/graphql';

import Addition from 'components/List/Additional';
import Calendar from 'components/elements/Calendar/Calendar';
import { Button, Dropdown, Checkbox } from 'components/elements';

import listFA_CreatedOrderForCustomer from '../../order/qql/query/listFA_CreatedOrderForCustomer';
import RUN_EXPORT from './gql/runExport';

import { OptionsContent, Row } from 'style';
import { LoadingWrap } from 'style/default';

const Export = (props) => {
  const { t } = props;
  const [date, setDate] = useState(Date.now());
  const [customerId, setCustomerId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [s3export, setS3export] = useState(true);
  const [runExportMutation, { loading }] = useMutation(RUN_EXPORT);

  const getType = (typeId) => {
    switch (typeId) {
      case 1:
        return null;
      case 2:
        return 'WA';
      case 3:
        return 'RET';
      case 4:
        return 'INV';
      default:
        return null;
    }
  };

  const runExport = async () => {
    const shouldDownload = !s3export;
    const variables = {};
    const type = getType(typeId);
    if (type) variables.type = type;
    else variables.exportAll = true;
    variables.s3export = s3export;
    variables.filter = [
      { column: 'folderName', value: 'date' },
      { column: 'date', value: Number.isInteger(date) ? moment(date).format('YYYY-MM-DD') : date },
      { column: 'customerId', value: customerId.toString() },
    ];
    const data = await runExportMutation({ variables });
    if (!data || !data.data || !shouldDownload) return;
    if(!data.data.fakturaAssist) return;
    if (!data.data.fakturaAssist.exportSpecialOrder || data.data.fakturaAssist.exportSpecialOrder.length === 0) return;
    for (const item of data.data.fakturaAssist.exportSpecialOrder) {
      if (item.isAlreadyExported){
        if (!window.confirm(t('common.this_data_has_already_been_processed', { order: item.name }))) {
          continue;
        }
      }

      const encodedData = item.data;
      const blob = new Blob([encodedData], { type: 'application/octet-stream' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = item.name;
      a.click();
    }
  };

  useEffect(() => {
  }, [date]);
  return (
    <Addition
      {...props}
      text = {t('common.export')}
      menuObj={[{ name: t('common.export'), link: '/options/export' }]}
      paddingTop={10}
    >
      <LoadingWrap loading={props.loading}>
        <Main>

          <OptionsContent>
            <Row>
              <Dropdown
                withoutFormik
                onChange={(e) => setCustomerId(e.value)}
                defaultValueField={customerId}
                title={t('customer.customer')}
                graphqlParams={{
                  query: listFA_CreatedOrderForCustomer,
                  variables: {
                    // skip: !date,
                    filter: [
                      { column: 'folderName', value: 'date' },
                      { column: 'date', value: Number.isInteger(date) ? moment(date).format('YYYY-MM-DD') : date }],
                  },
                }}
                valueField='customerLink'
                labelField='actualName'
              />
            </Row>
            <Row>
              <Calendar title={t('common.date')} withoutFormik value={date} onChange={setDate}/>
            </Row>
            <Dropdown
              title={t('order.orderType')}
              data={[{ id: 1, name: t('common.all') }, { id: 2, name: 'CashAssist Bestellung' }, { id: 3, name: 'CashAssist Retour' }, { id: 4, name: 'CashAssist Inventur' }]}
              withoutFormik
              onChange={(e) => setTypeId(e.value)}
              defaultValueField={typeId}
            />
            <Checkbox title={t('common.downloadFiles')} specialFunc margin='20px 0' withoutFormik value={!s3export} onChange={(e, v) => {
              setS3export(!v);
            }}/>
            <Row>
              <Button value={loading ? t('common.loading') : `${t('common.export')}`} onClick={runExport} disabled={!date || !customerId || !typeId || loading} className='black'/>
            </Row>
          </OptionsContent>
        </Main>
      </LoadingWrap>
          
    </Addition>
  );
};

export default Export;

const Main = styled.div`
  padding-top: 20px;
  min-height: 20vh;
`;
