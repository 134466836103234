import React, { useState } from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';
import useQuerySpecial from 'components/graphql/useQuerySpecial';

import { Input, Checkbox, Text, Calendar } from 'components/elements';
import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid.js';

import { updateFA_Suppliers } from '../../qql/mutation';
import { listFA_Goods, getFA_Goods } from '../../qql/query';

export default (props) => {
  const { t } = props;

  const [isShowOnlyAssigned, setIsShowOnlyAssigned] = useState(true);

  const { data, ...prevQuery } = useQuerySpecial(getFA_Goods, {
    variables: { id: +props.linkId, isShowOnlyAssigned },
    skip: !props.linkId,
  });

  const tableData = [
    {
      name: t('Supp. No.'),
      dataBase: 'number',
    },
    {
      name: t('Name'),
      dataBase: 'name',
    },
    {
      name: t('Supp. prod'),
      dataBase: 'externalNumber',
    },
    {
      name: t('Product name by supplier'),
      dataBase: 'externalName',
    },
    {
      name: t('Price'),
      dataBase: 'price',
    },
    {
      name: t('Last sale'),
      dataBase: 'lastSale',
    },
    {
      name: t('Aviability'),
      dataBase: 'isAvailable',
      specialComponent: (index, value)=><Checkbox margin={'0px'} zIndex={100} onClick={()=>{}} value={value} field='outOfStock'/>,
    },
  ];

  const tableDataModal = [
    {
      name: t('Supp. No.'),
      dataBase: 'isValue',
    },
    {
      name: t('Name'),
      dataBase: 'isValue',
    },
    {
      name: t('Supp. prod'),
      dataBase: 'isValue',
    },
  ];

  const EditSuppliersPriceModal = (props) => {
    const { handleChange, values } = props;
    return(
      <>
        <Row>
          <Col lg={2}>
            <Calendar
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('Date')}
              small
            />
          </Col>
          <Col lg={2}>
            <Input
              withoutFormik={true}
              type='number'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('Price')}
              small
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Input
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('Description')}
            />
          </Col>
          
        </Row>
      </>
    );
  };

  const Component = (props)=>{
    const { handleChange, values } = props;
    return(
      <>
        <Row>
          <Col lg={2}>
            <Input
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'Supplier'}
              title={t('Supplier')}
            />
          </Col>
          <Col lg={4}>
            <Input
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('Product')}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <Input
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('common.name')}
              small
            />
          </Col>
          <Col lg={2}>
            <Input
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('Supp. prod. No.')}
              small
            />
          </Col>
          <Col lg={2}>
            <Input
              withoutFormik={true}
              type='text'
              onChange={handleChange}
              value={values.name}
              name={'name'}
              title={t('Product name by supplier')}
              small
            />
          </Col>
          <Col lg={3}>
            <Checkbox title={t('Aviability')} field='deliveryTime' value={values.deliveryTime}/>
          </Col>
        </Row>
        <Row>
          <Col width='100%'>
            <TableGrid
              withSearch
              graphqlParams={{
                query: listFA_Goods,
                variables: { id: +props.linkId } }}
              
              //searchValue={this.state.searchValue}
              //onSearch={this.onSearch}
              //getSortField={this.getSortField}
              Component={EditSuppliersPriceModal}
              height={100}
              headerText={t('common.edit')}
              modalWidth={400}
              modalHeight={200}
              //editItem={this.openModal}
              tableData={tableDataModal}
              //withModalMutations
              //getTableIndex={this.indexOnClickTable}
              itemDeleted={updateFA_Suppliers}
              defaultValuesForCache={['isValue', 'customerListsRelationID', 'linkCustomer']}
              defaultValuesForMutation = {{ linkCustomer: +props.linkId }}
              typeName={'FA_CustomerLists'}
              qqlMutationUpdate={updateFA_Suppliers}
              getBlockColor={() => 'red'}

            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Formik initialValues={{}} enableReinitialize={true}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <Section>
            <HeadBlock>
              <Row>
                <Col lg={2}>
                  <Input title={t('Prod Nr')} field='orderRef' value={data.articleNr} small notEdit/>
                </Col>
                <Col lg={4}>
                  <Input title={t('Product description')} field='orderRef' value={data.name} notEdit/>
                </Col>
                <Col lg={3}>
                  <Checkbox title={t('isActive')} field='deliveryTime' value={data.isActive}/>
                </Col>
              </Row>
            </HeadBlock>

            <OrderSection>
              <TableOptions>
                <div>
                  <Text text={t('Information from suppliers')} color='black' size='18px' fontWeight={'500'} marginTop='17px' marginBottom='5px'/>
                </div>

              </TableOptions>
              <TableWithProductOnDateBlock>
                <Checkbox
                  title={t('show unassigned')}
                  value={isShowOnlyAssigned}
                  onChange={() => setIsShowOnlyAssigned(!isShowOnlyAssigned)}
                  withoutFormik
                />
                <TableGrid
                  {...prevQuery}
                  withSearch
                  data={data.goodsItems ? data.goodsItems : []}
                  
                  //searchValue={this.state.searchValue}
                  //onSearch={this.onSearch}
                  //getSortField={this.getSortField}
                  //Component={Component}
                  height={700}
                  headerText={t('Information')}
                  modalWidth={840}
                  modalHeight={405}
                  searchColumns={['number', 'name']}
                  //editItem={this.openModal}
                  tableData={tableData}
                  //withModalMutations
                  //getTableIndex={this.indexOnClickTable}
                  //itemDeleted={updateFA_Suppliers}
                  // defaultValuesForCache={['isValue', 'customerListsRelationID', 'linkCustomer']}
                  //defaultValuesForMutation = {{ linkCustomer: +props.linkId }}
                  //typeName={'FA_CustomerLists'}
                  //qqlMutationUpdate={updateFA_Suppliers}
                  getBlockColor={() => 'red'}

                />
              </TableWithProductOnDateBlock>
            </OrderSection>
          </Section>
        );
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  padding: 40px 10px 18px 15px;

`;

const Section = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;

const OrderSection = styled.div`
  margin-top: 40px
  padding: 0px 20px 0px 20px;
  background-color: #f4f4f4;
  min-height: 56vh;
`;

const TableOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableWithProductOnDateBlock = styled.div`
  padding-bottom: 20px;
`;
