import gql from 'graphql-tag';

export default gql`
query ($args: FA_OrdersInCreateInvoiceInputs!){
    fakturaAssist{
    	listFA_OrdersInCreateInvoice(args: $args){
    		id
			date
			customerNr
			name
            orderNr
            total
 		}
 	}
}`;
