import { readQuery, writeQuery } from 'helpers/queryOperations';
import { cloneDeep } from 'lodash';

const useOrderTypes = ({ contex, writeContex, readQueryProductsParams, writeQueryProductsParams, withoutCache, productTable }) => {
  const returnOrderType = () =>{
    const { orderType } = contex.filter;
    try{
      const some = withoutCache ? { productTable } : readQuery(readQueryProductsParams, true).fakturaAssist.getFA_GetDefaultOrderForCustomerOnDate;
      
      const cacheData = cloneDeep(some);
      const checkNegativeNumber = (number) => Math.sign(number) === 1 ? -number : number;
      const checkPositiveNumber = (number) => Math.sign(number) === 1 ? number : -number;
      if(orderType === 4) {
        const negative = cacheData.productTable.map((item, index)=> {
          return { ...item, quantity: checkNegativeNumber(item.quantity), total: checkNegativeNumber(item.total) };
        });
        const finalData = { ...cacheData, productTable: negative };
        if(!withoutCache) {
          writeQuery({ ...writeQueryProductsParams, changedData: finalData });
        } else{
          return negative;
        }
      }else if(orderType === 6) {
        const finalData = { ...cacheData, productTable: cloneDeep([]) };
        if(!withoutCache) {
          writeQuery({ ...writeQueryProductsParams, changedData: cloneDeep(finalData) });
        } else{
          return cloneDeep([]);
        }
      }else{
        const positive = cacheData.productTable.map((item, index)=> {
          return { ...item, quantity: checkPositiveNumber(item.quantity), total: checkPositiveNumber(item.total) };
        });
        const finalData = { ...cacheData, productTable: positive };
        if(!withoutCache) {
          writeQuery({ ...writeQueryProductsParams, changedData: cloneDeep(finalData) });
        } else{
          return positive;
        }
      }
    }catch(err) {
		  //console.log(err);
    }
	  };
  
  return {
    returnOrderType,
  };
};
  
export default useOrderTypes;
  

