import gql from 'graphql-tag';

export default gql`
mutation checkFA_ProductInfoFromOrderDropdown($args: FA_ProductInfoFromOrderDropdownInputs!){
    fakturaAssist{
    	checkFA_ProductInfoFromOrderDropdown(args: $args){
    		productNr
            name
            deliveryPrice
            discount
            taxCode
            price
            weight
 	    }
    }
}`;


