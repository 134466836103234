
import gql from 'graphql-tag';

export default gql`
query listFA_CustomerPaymentSlipReportID($languageId: Int){
    fakturaAssist{
    listFA_CustomerPaymentSlipReportID(languageId: $languageId){
		id
        form
        name
        formId
        formIdDefault
    }
    
  }
}`;
