import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { MonthChooser, Radio, Dropdown, Calendar, DateRange, Input } from '../';

const RadioGroup = (props) => {
  const { values, resetFilter, filterData, data, onFilterChange, filterRaw, getSecondDateVal, filterInfo, mainsection } = props;
  // const radioElements = listItems.map((props, index)=> {
  //   const { value } = props;
  //Recurse function. Need to create deeply folder trees
  //   const getSubfolderElements = (obj) => {
  //     if(obj.subfolder) {
  //       const getFolder = (o) => {
  //         return o.map((item, index) => {
  //           const subFold = item.subfolder ? item.value === values[item.field] && getFolder(item.subfolder) : null;
  //           return (
  //             <Fragment>
  //               <SubFolderElements>
  //                 <Radio {...item} checked = {item.value === values[item.field]} onClick={onClick}/>
  //                 <SubFolderElements>
  //                   {subFold}
  //                 </SubFolderElements>
  //               </SubFolderElements>
  //             </Fragment>);
  //         });
  //       };
  //       return getFolder(obj.subfolder);
  //     }
  //   };
  //   return (
  //     <RadioBlock>
  //       <Radio checked = {value === values[field]} {...props} onClick={onClick}/>
  //       {value === values[field] && getSubfolderElements(listItems[index])}
  //       {renderComponentUnderRadio && value === values[field] && renderComponentUnderRadio(value) }
  //     </RadioBlock>
  //   );
  // });
  return (
    <Main>
      {filterData.map((item, index) =>
        (<RadioWithSubGroup
          {...item}
          mainsection={mainsection}
          key={index}
          filterRaw={filterRaw}
          filterInfo={filterInfo}
          resetFilter={resetFilter}
          data={data}
          values={values}
          onFilterChange={onFilterChange}
          getSecondDateVal={getSecondDateVal}
          isActive={values[item.field] && item.value && values[item.field] === item.value}
        />))}
    </Main>
  );
};

const RadioWithSubGroup = (props) => {
  const [t] = useTranslation();
  const {
    values,
    field,
    title,
    subItems,
    queryField,
    valueField,
    titleField,
    data,
    onFilterChange,
    isActive,
    indexAsValue,
    resetFilter,
    cleanFilter,
    filterRaw,
    additionalInputs,
    getSecondDateVal,
    sortOrder,
    radio,
  } = props;

  const value = filterRaw ? (Number.isInteger(props.value) ? JSON.stringify(props.value) : props.value) : JSON.stringify(props.value);
  const interval = {
    date: values?.date ?? '',
    secondDate: values?.secondDate ?? '',
  };

  const [isOpen, setOpen] = useState(false);
  const [dateInterval, setDateInterval] = useState(interval);
  const [dateState, setDateState] = useState({
    year: '',
    month: '',
    period: '',
  });

  const dataFromQuery = data && queryField ? get(data, queryField, []) : (queryField || titleField || valueField ? data : null);

  useEffect(() => {
    if (!isActive) {
      setOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    // sync local state with props date state
    if (values[field] !== dateInterval[field]) {
      setDateInterval({ [field]: values[field] });
      if(radio) setOpen(false);
    }
    if ((value || value === 0)) {
      if ((values[field] || values[field] === 0) && (!!value || value === 0) && values[field] === value) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    } else if (!value && values[field]) setOpen(true);
  }, [values]);

  useEffect(() => {
    getSecondDateVal && getSecondDateVal(dateInterval);
  }, [dateInterval]);

  const handleClick = () => {
    // localStorage.setItem(mainsection, (field && value) ? JSON.stringify([{ ...filterInfo[0], column: field, value }]) : JSON.stringify([]));
    if ((!isOpen && field && (value || value === 0)) || radio) {
      onFilterChange(field, value);
    }

    if(!isOpen && radio && field === 'LinkGruppe') {
      // Set default filter value for printListGroups
      onFilterChange(field, '6');
    }

    if (cleanFilter) resetFilter();
    setOpen((state) => !state);
  };

  const renderAddon = (addon, keepValues) => {
    if (!addon) return null;
    const { type } = addon;
    const {
      graphqlParams,
      labelField,
      valueField,
      field,
      subFields,
      title,
      isSmall,
      keepItemsOnLevel,
      data,
      dropdownType,
      sortKey,
    } = addon;

    const handleChange = (field, value, keepItemsOnLevel = keepValues) => {
      onFilterChange(field, filterRaw ? Number.isInteger(value) ? JSON.stringify(value) : value : JSON.stringify(value), keepItemsOnLevel);
    };

    const handleMonthChooserChange = (id, name) => {
      const startOfTheMonth = moment().month(id - 1).startOf('month').format('YYYY-MM-DD');
      const endOfTheMonth = moment().month(id - 1).endOf('month').format('YYYY-MM-DD');

      setDateState({ ...dateState, period: '', year: '', month: name });
      setDateInterval({
        ...dateInterval,
        date: startOfTheMonth,
        secondDate: endOfTheMonth,
      });
      handleChange('date', startOfTheMonth);
    };

    const handleDropDownChange = async (e) => {
      switch (dropdownType) {
        case 'year':
          const startOfTheYear = moment().year(e.value).startOf('year').format('YYYY-MM-DD');
          const endOfTheYear = moment().year(e.value).endOf('year').format('YYYY-MM-DD');
          setDateInterval({
            ...dateInterval,
            date: startOfTheYear,
            secondDate: endOfTheYear,
          });
          setDateState({ ...dateState, month: '', period: '' });
          // onFilterChange('secondDate', endOfTheYear) - trigger secondDate filter variable
          return handleChange(field, e.value);
        case 'period':
          const startOfThePeriod = moment().startOf('year').format('YYYY-MM-DD');
          const startMiddleOfThePeriod = moment().month(5).endOf('month').format('YYYY-MM-DD');
          const endMiddleOfThePeriod = moment().month(6).startOf('month').format('YYYY-MM-DD');
          const endOfThePeriod = moment().endOf('year').format('YYYY-MM-DD');
          setDateInterval({
            ...dateInterval,
            date: e.value === 1 ? startOfThePeriod : startMiddleOfThePeriod,
            secondDate: e.value === 1 ? endMiddleOfThePeriod : endOfThePeriod,
          });
          setDateState({ ...dateState, month: '', period: e.value });
          return handleChange(field, e.value === 1 ? startOfThePeriod : startMiddleOfThePeriod);
        default:
          return handleChange(field, e.value);
      }
    };

    switch (type) {
      case 'dropdown':
        return (
          <Dropdown
            {...{
              ...(data && { data }),
              ...(labelField && { labelField }),
              ...(valueField && { valueField }),
              ...(graphqlParams && { graphqlParams }),
              ...(sortKey && { sortKey }),
            }}
            title={title}
            defaultValueField={dropdownType === 'period' ? dateState.period : parseInt(values[field])}
            onChange={handleDropDownChange}
          />
        );
      case 'monthChooser':
        return (
          <MonthChooser
            defaultMonth={dateState.month}
            onClick={handleMonthChooserChange}
          />
        );
      case 'calendar':
        return (
          <Calendar
            withoutFormik
            value={values[field]}
            name={field}
            title={title}
            onChange={(date) => {
              setDateInterval({
                ...dateInterval,
                date: date,
                secondDate: '',
              });
              setDateState({
                ...dateState,
                month: '',
                period: '',
                year: '',
              });
              handleChange(field, date);
            }}
          />
        );
      case 'dateRange':
        if (!subFields || subFields.length !== 2) throw new Error('You must provide an array of 2 subfields for \'dateRange\' type');
        return (
          <Flexbox>
            <DateRange
              withoutFormik
              values={{ ...values, ...dateInterval }}
              startField={subFields[0]}
              endField={subFields[1]}
              title={{
                from: t('common.fromDate'),
                to: t('common.toDate'),
              }}
              t={t}
              onChange={(field, date) => {
                setDateInterval({
                  ...dateInterval,
                  [field]: date,
                });
                onFilterChange(field, date, true);
              }}
              withoutMargin
            />
          </Flexbox>
        );
      case 'input':
        return <Input withoutFormik value={values[field]} title={title} small={isSmall} onChange={(e) => onFilterChange(field, e.target.value, keepItemsOnLevel)}/>;
      default:
        return null;
    }
  };

  return (
    <div>
      {
        value || value === 0 || cleanFilter || radio ?
          <Radio checked={cleanFilter ? !Object.keys(values).length : isOpen} onClick={handleClick} title={title}/>
          :
          <FakeButton active={isOpen} onClick={() => setOpen((state) => !state)}>{isOpen ? '-' : '+'} {title}</FakeButton>
      }
      {isOpen &&
      <>
        <SubFolderElements>
          {subItems && subItems.map((item, index) => {
            return (<RadioWithSubGroup
              {...item}
              key={index}
              filterRaw={filterRaw}
              openParent={() => setOpen(true)}
              values={values}
              resetFilter={resetFilter}
              data={data}
              isActive={isActive}
              onFilterChange={onFilterChange}
            />);
          })}
          {
            dataFromQuery &&
            <MarginBlock>
              <Dropdown
                defaultValueField={values[field]}
                data={
                  dataFromQuery.map(
                    (item, idx) => indexAsValue
                      ? ({ id: JSON.stringify(idx + 1), name: item })
                      :
                      {
                        ...item, [valueField]: filterRaw
                          ? Number.isInteger(item[valueField])
                            ? JSON.stringify(item[valueField])
                            : item[valueField]
                          : JSON.stringify(item[valueField]),
                      },
                  ).filter((item) => item[titleField || 'name'])
                }
                withoutFormik
                labelField={titleField || 'name'}
                valueField={valueField || 'id'}
                {...(sortOrder && { sortOrder })}
                onChange={(e) => onFilterChange(field, e.value)}
              />
            </MarginBlock>
          }
          {additionalInputs && additionalInputs.map((item) => <MarginBlock>{renderAddon(item, true)}</MarginBlock>)}
        </SubFolderElements>

      </>
      }
    </div>
  );
};

export default RadioGroup;

const Main = styled.div`
  display: inline;
  margin-left: 20px;
`;

const FakeButton = styled.span`
  display: block;
  cursor: pointer;
  padding-left: 10px;
  margin-bottom: 8px;
  font-weight: ${({ active }) => active ? 'bold' : 'normal'}
`;

const MarginBlock = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
  float: left;
`;

const SubFolderElements = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 10px;
`;

const Flexbox = styled.div`
  display: flex;
`;

RadioGroup.propTypes = {
  listItems: PropTypes.array.isRequired, //Array format on bottom
  field: PropTypes.string.isRequired,
  formikValues: PropTypes.object,
};

/*
  Example
    <RadioGroup field='folderName' listItems={listItemsRadioNew()} formikValues={props.values}/>
    const listItemsRadioNew = [
      {
        title: 'Daily customer',
        value: 'dailyCustomer',
        field: 'folderName',
        subfolder: [],
      }
    ];
*/

