import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { menuObj } from './params';

const Header = (props) => {
  const [selectedIndex, changeIndex] = useState(0);
  const [hoverIndex, changeHoverIndex] = useState(null);
  const onClickHeader = (index) => {
    changeHoverIndex(null);
    changeIndex(index);
  };
  const menuTranslated = menuObj(props.t);
  const paramsRender = menuTranslated.map((item, index) => {
    const { link, icon, text, subLink, name } = item;
    let actualLink = link;
    const some = Object.keys(props.sectionsPaths);
    if (some.includes(name)) {
      actualLink = props.sectionsPaths[name];
    }
    const renderSubLinks = () => {
      const renderLinks = subLink.map((item, index) => {
        const { link, text } = item;
        return (
          <NavLink to={link} key={index}>
            <SubLinkText>{text}</SubLinkText>
          </NavLink>
        );
      });
      return (

        <SubLinkBlock> {renderLinks}</SubLinkBlock>
      );
    };
    return (
      <ActiveLink to={actualLink} key={index}>
        <HeaderBlock selectedIndex={selectedIndex} key={index} onClick={props.onClick}>
          <div key={index} onMouseEnter={() => changeHoverIndex(index)} key={index} to={actualLink} onClick={() => onClickHeader(index)}>
            <HeaderIcon src={icon} alt={text} />
            <LinkText>{text}</LinkText>
          </div>

        </HeaderBlock>

        {hoverIndex === index && subLink && <div>{renderSubLinks()}</div>}
      </ActiveLink>
    );
  });
  return <MainHeaderBlock onMouseLeave={() => changeHoverIndex(null)}>{paramsRender} </MainHeaderBlock>;
};

export default Header;

const ActiveLink = styled(NavLink)`
  color: #ffffff;
  display: inline-block;
  display: inline-block;
  height: 60px;
  background: ${(props) => (isNaN(props.selectedIndex) ? '#565656' : '#434343')};
  text-align: center;
  width: ${100 / menuObj.length}%;
  position: relative;
  &.active {
    opacity: 1;
    font-weight: 400;
    border-bottom: 2px solid #FD2724;
    box-shadow: 0px 0px 10px 5px rgba(253, 39, 36, 0.25);
  }
`;

//All icons here
const MainHeaderBlock = styled.div`
    display: flex;
    z-index: 9;
  flex-direction: row;
  height: 60px;
  top: 0px;
    background-color: #434343;
    display: flex;
  overflow: hidden;
  position: fixed;
  width: 101%;
  margin-left:-3px;
  max-width: 1930px;
  min-width: 1050px;
  & a:nth-child(10) {
    & > div:after {
      display:none;
    }
  }
`;

const SubLinkBlock = styled.div`
    height: auto;
  background: #434343;
  position: fixed;
  z-index: 3;
  width: 12%;
  max-width: 220px;
`;

const HeaderBlock = styled.div`
  display: inline-block;
  height: 60px;
  background: ${(props) => (isNaN(props.selectedIndex) ? '#565656' : '#434343')};
  opacity: 0.9;
  text-align: center;
  width: ${100 / menuObj.length}%;
  position: relative;
  z-index: 999;
  & span {
    color: white !important;
  }
  &:after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    width: 2px;
    height: 34px;
    background-color: #565656;
  }
  ${ActiveLink}.active & {
    opacity: 1;
    background-color: #565656;
    border-bottom: 2px solid #FD2724;
    }
`;

const HeaderIcon = styled.img`
  text-align: center;
  padding-top: 6px;
  display: block;
  margin: auto;
  margin-top: 0;
  height: 29px;
  width: 23px;
`;

const SubLinkText = styled.p`
  padding: 10px;
  display: block;
  height: 26px;
`;

const LinkText = styled.span`
  display: block;
  margin-top: 3px;
  position: center;
`;

