
export const tablePrice = (t) => [
  {
    name: t('common.fromDate'),
    relativeWidth: 0.2,
    dataBase: 'FromDate',
    dataType: 'date',
  },
  {
    name: t('common.toDate'),
    relativeWidth: 0.2,
    dataBase: 'ToDate',
    dataType: 'date',
  },

  {
    name: t('common.load'),
    relativeWidth: 0.07,
    dataBase: 'Price1',
    dataType: 'float',
  },

  {
    name: t('common.delivery'),
    relativeWidth: 0.07,
    dataBase: 'Price2',
    dataType: 'float',
  },

  {
    name: t('common.net'),
    relativeWidth: 0.07,
    dataBase: 'Price3',
    dataType: 'float',
  },
  {
    name: `${t('common.price')} 4`,
    relativeWidth: 0.07,
    dataBase: 'Price4',
    dataType: 'float',
  },
  {
    name: t('common.gross'),
    relativeWidth: 0.07,
    dataBase: 'Price5',
    dataType: 'float',
  },
  {
    name: t('common.description'),
    relativeWidth: 0.25,
    dataBase: 'Description',
    isShortText: true,
  },
];

export const tableCustomer = (t) => [
  {
    name: t('common.fromDate'),
    relativeWidth: 0.1,
    dataBase: 'fromDate',
  },

  {
    name: t('common.toDate'),
    relativeWidth: 0.1,
    dataBase: 'toDate',
  },

  {
    name: t('customer.No'),
    relativeWidth: 0.1,
    dataBase: 'customerNo',
  },

  {
    name: t('common.name'),
    relativeWidth: 0.1,
    dataBase: 'actNameIntern',
  },
  {
    name: t('common.price'),
    relativeWidth: 0.1,
    dataBase: 'price',
  },
  {
    name: t('cutomer.fromAmount'),
    relativeWidth: 0.1,
    dataBase: 'fromAmount',
  },
  {
    name: t('common.discount'),
    relativeWidth: 0.1,
    dataBase: 'discount',
  },
  {
    name: t('product.statusDiscount'),
    relativeWidth: 0.1,
    dataBase: 'discountsDisabled',
  },
  {
    name: t('common.description'),
    relativeWidth: 0.2,
    dataBase: 'description',
    isShortText: true,
  },
];

export const tableResseler = [
  {
    name: 'Customer No',
    relativeWidth: 0.2,
    dataBase: 'customerNo',
  },
  {
    name: 'Name',
    relativeWidth: 0.2,
    dataBase: 'actNameIntern',
  },
  {
    name: 'Price',
    relativeWidth: 0.2,
    dataBase: 'price',
  },
  {
    name: 'Description',
    relativeWidth: 0.4,
    dataBase: 'description',
    isShortText: true,
  },
];

export const MENU = [
  {
    name: 'Price categories',
    link: '/products/article/categoriesPrice',
    col: '4',
  },
  {
    name: 'Customer price',
    link: '/products/article/customerPrice',
    col: '4',
  },
  {
    name: 'Resseler price',
    link: '/products/article/resselerPrice',
    col: '4',
  },
];

export const KEYS = ['id', 'designation'];

export const PRICE_CATEGORIES = (t, categoryList) => [
  {
    name: t('common.fromDate'),
    dataBase: 'fromDate',
    isMutation: true,
    dataType: 'date',
    relativeWidth: 0.1,
  },
  {
    name: t('common.toDate'),
    dataBase: 'toDate',
    isMutation: true,
    dataType: 'date',
    relativeWidth: 0.1,

  },
  {
    name: categoryList[0],
    dataBase: 'price1',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[1],
    dataBase: 'price2',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[2],
    dataBase: 'price3',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[3],
    dataBase: 'price4',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[4],
    dataBase: 'price5',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[5],
    dataBase: 'price6',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[6],
    dataBase: 'price7',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[7],
    dataBase: 'price8',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[8],
    dataBase: 'price9',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: categoryList[9],
    dataBase: 'price10',
    isMutation: true,
    dataType: 'float',
    relativeWidth: 0.1,
  },
  {
    name: t('common.description'),
    dataBase: 'description',
    isMutation: true,
    isShortText: true,
  },
].filter((item) => item.name);

export const CustomerPriceParamsEN = [
  {
    name: 'From date',
    dataBase: 'fromDate',
    dataType: 'date',
    isMutation: true,
    relativeWidth: 0.1,
  },
  {
    name: 'To date',
    dataBase: 'toDate',
    isMutation: true,
    relativeWidth: 0.1,
  },
  {
    name: 'Number',
    dataBase: 'customerNm',
    isMutation: true,
  },
  {
    name: 'Name',
    dataBase: 'actualName',
    isMutation: true,
    relativeWidth: 0.2,
  },
  {
    name: 'Price',
    dataBase: 'price',
    isMutation: true,
    type: 'float',
  },
  {
    name: 'From amount',
    dataBase: 'fromAmount',
    isMutation: true,
    type: 'float',
  },
  {
    name: 'Discount',
    dataBase: 'discount',
    isMutation: true,
    type: 'percent',
  },
  {
    name: 'No discount posible',
    dataBase: 'isDiscountsDisabled',
    isMutation: true,
    relativeWidth: 0.2,
  },
  {
    name: 'Note',
    dataBase: 'description',
    isMutation: true,
    relativeWidth: 0.2,
    isShortText: true,
  },
];
