
const useFilterInfo = ({ contex, writeContex }) => {
  const setOrderDateContex = (d) => {
    let payload = { ...contex, date: { ...contex.date, date: d } };
    if (contex?.filter?.some((item) => ['acceptedOffers', 'declinedOffers'].includes(item.column))) {
      payload = { ...payload, date: { date: d, secondDate: d } };
    }
    writeContex(payload);
  };

  const setOrderType = (number) => {
    writeContex({ ...contex, orderType: number });
  };
  const setFilterInfo = (filter) => {
    writeContex({ ...contex, filter: [...filter] });
  };

  return {
    setOrderDateContex,
    setOrderType,
    setFilterInfo,
  };
};

export default useFilterInfo;
