import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from 'components/elements';

export const Inputs = styled.input`
  /* font-family: Roboto-Medium; */
  font-weight: 500;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  border: none;
  background: ${(props) => (props.background ? props.background : 'inherit')};
  color: ${(props) => (props.notEdit ? '#ffffff' : 'inherit')};
  float:left;
`;

export const HidenDiv = styled.div`
/* font-family: Roboto-Medium; */
font-weight: 500;
width: 100%;
padding: 10px 10px 10px 10px;
height: 40px;
border: none;
background: ${(props) => (props.background ? props.background : 'inherit')};
text-align: ${({ textAlign }) => textAlign || 'left'};
${({ noEdit }) => noEdit && `
  color: #afafaf;
  cursor: not-allowed
`}
`;

const SpecInput = (props) =>{
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const { columnIndex, rowIndex, name, data, changeTableIndex, type, textAlign, noEdit, background } = props;
  const l = data.length;
  useEffect(
    () => {
      setValue(props.value);
    },
    [props.value],
  );
  const onKeyDown = async (e) =>{
    if(e.keyCode === 13) {
      props.onChangeInput(e, setValue);
      if(columnIndex !== 0) {
        document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`) && document.getElementById(`${columnIndex}-${l - rowIndex - 1}-${name}`).focus();
      }
    } else if(e.keyCode === 13) {
      if(columnIndex) {
        await props.onAdd(() => {
          document.getElementById(`${columnIndex}-${l - rowIndex}-${name}`) && document.getElementById(`${columnIndex}-${l - rowIndex}-${name}`).focus();
        });
        document.getElementById(`${columnIndex}-${rowIndex}-${name}`) && document.getElementById(`${columnIndex}-${rowIndex}-${name}`).focus();
      }
    }
  };
  return (
    <div>
      {open ?
        <Input
          key={columnIndex && `${columnIndex}-${l - rowIndex}-${name}`}
          noBorder
          withOpacity
          onKeyDown = {(event)=>{
            onKeyDown(event);
          }}
          value={value}
          width='100%'
          withoutFormik={true}
          id = {columnIndex && `${columnIndex}-${l - rowIndex}-${name}-input`}
          onBlur={(e)=>{
            props.onChangeInput(e, setValue);
            setOpen(false);
          }}
          autoFocus
          onChange = {(e) =>setValue(e.target.value)}
          name = {name}
          autoComplete='off'
          onFocus={(e)=> document.getElementById(`${columnIndex}-${l - rowIndex}-${name}-input`).select()}
          type={type}
        />
        :
        <HidenDiv
          textAlign={textAlign}
          noEdit={noEdit}
          background={background}
          tabIndex='0'
          open={props.open}
          onFocus={(e)=>{
            if(!noEdit) {
              setOpen(true);
              changeTableIndex(rowIndex);
            }
          }}
          id = {columnIndex && `${columnIndex}-${l - rowIndex}-${name}`}
          key={columnIndex && `${columnIndex}-${l - rowIndex}-${name}`}
          type={'text'}
        > {name === 'discount' ? value ? value + '%' : '-' : value }</HidenDiv>
      }
    </div>);
};

export default SpecInput;

export const Loading = styled.div`
  width: 20px;
  position: absolute;
  right: 5px;
  top: 45px;
`;
