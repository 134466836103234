import gql from 'graphql-tag';

export default gql`
query getPathQ($path: String){
  fakturaAssist{
    getPathQ(path: $path){
		body
		path
    }
  }
}`;
