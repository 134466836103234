import React from 'react';
import { compact } from 'lodash';

import QueryComponent from '../../graphql/Query';

const withQuery = (WrappedComponent) => (props) => {
  const { graphqlParams, fieldQuery, withVirtualized } = props;

  if (!graphqlParams) {
    return <WrappedComponent {...props} />;
  } else {
    let variables = { ...(typeof graphqlParams.variables === 'function' ? graphqlParams.variables() : graphqlParams.variables) };
    const prevParams = variables.params || {};
    // if(props.withSearch) {
    //   variables = {
    //     ...variables,
    //     params: {
    //       ...prevParams,
    //       search: {
    //         string: props.searchValue,
    //         columns: props.searchColumns || props.labelField,
    //       },
    //       limit: 200,
    //     },
    //   };
    // }
    variables = {
      ...variables,
      params: {
        ...prevParams,
        limit: withVirtualized ? 9999 : graphqlParams.variables?.params?.limit || 1000,
      },
    };
    if (props.withSearch && !withVirtualized) {
      variables = {
        ...variables,
        params: {
          ...prevParams,
          search: {
            string: props.searchValue,
            columns: props.searchColumns || props.labelField,
          },
        },

      };
    }
    return (
      <QueryComponent
        variables={variables}
        query={graphqlParams.query}
        fetchPolicy={graphqlParams.fetchPolicy || 'cache-first'}
      >
        {({ data, loading, error, networkStatus, queryField, fetchMore, ...restQuery }) => {
          try {
            return (<WrappedComponent
              {...props}
              loading={loading}
              fetchMore={fetchMore}
              data={props.data ? props.data : Array.isArray(data) ? fieldQuery ? compact(data[fieldQuery]) : compact(data) : fieldQuery ? data[fieldQuery] : data} />);
          } catch (err) {
            return <div> Error in data</div>;
          }
        }}
      </QueryComponent>
    );
  }
};
export default withQuery;


