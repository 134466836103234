/* eslint-disable no-use-before-define */
import React from 'react';
import Formik from 'components/formik';
import styled from 'styled-components';

import { Row, Col } from 'style/index';
import { LIST_RECIPES } from '../gql/index';

import { Input, Checkbox, Dropdown, Textarea } from 'components/elements';
import { useTranslation } from 'react-i18next';

const Declaration = (props) => {
  const [t] = useTranslation();
  // const { data } = useQuery(Recept, {
  //   variables: {
  //     id: +props.data.linkLabelRecipe,
  //   },
  //   fetchPolicy: 'cache-and-network',
  // });
  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const { declaration, forSaleDays, salesWeight, mustBeKeptCool, consumeDays, helfLifeDays } = values;

        const handleChange = (e) => {
          e.persist();
          const { value, name } = e.target;
          setFieldValue(name, value !== '' ? parseFloat(value) : null);
        };

        return (
          <>
            <Row>
              <Col lg={4}>
                <Dropdown
                  title={`${t('product.connectionTo')} RezeptAssist`}
                  setFieldValue={setFieldValue}
                  name='linkLabelRecipe'
                  field='linkLabelRecipe'
                  valueField='id'
                  labelField='concatField'
                  defaultValueField={values.linkLabelRecipe}
                  graphqlParams={{ query: LIST_RECIPES, variables: { params: { limit: Infinity } } }}
                  sortOrder='none' />
              </Col>
              <Col width={'200px'}>
                <Input width={'140px'} name='forSaleDays' type='number' hideTrailingZero value={forSaleDays} onChange={handleChange} field='forSaleDays' title={t('product.sellBy')} />
              </Col>
              {/* <Col width={'150px'}>
                <Input width={'140px'} readonly value={get(data, 'fakturaAssist.getFA_ReceptAssistRecepts.CA_WeightSale', '')} title={t('product.itemSaleWeight')}/>
              </Col> */}
              <Col>
                <CheckboxStyled value={mustBeKeptCool} field='mustBeKeptCool' title={t('product.articleMustBeCool')} margin='45px 0 0' />
              </Col>
            </Row>

            <RowStyled>
              <Col lg={4}>
                <Textarea
                  value={declaration}
                  label={t('product.declaration')}
                  width='300px'
                  height='300px'
                  field='declaration'
                  bold
                />
              </Col>
              <Col width={'200px'}>
                <Input name={mustBeKeptCool ? 'consumeDays' : 'helfLifeDays'} width={'140px'} type='number' onChange={handleChange} hideTrailingZero value={mustBeKeptCool ? consumeDays : helfLifeDays} field={mustBeKeptCool ? 'consumeDays' : 'helfLifeDays'} title={mustBeKeptCool ? t('product.consumeBy') : t('product.shelfLifeInDays')} />
              </Col>
              <Col width={'180px'}>
                <Input name='salesWeight' width={'140px'} type='number' onChange={handleChange} hideTrailingZero widthTitle={'160px'} value={salesWeight} field='salesWeight' title={`${t('product.alternativeSaleWeight')} (g)`} />
              </Col>
            </RowStyled>
          </>
        );
      }}
    </Formik>
  );
};

export default Declaration;

const CheckboxStyled = styled(Checkbox)`
  width: 400px;
`;

const RowStyled = styled(Row)`
  margin-top:20px;
`;
