//import React from 'react';
//import periodImg from 'style/img/actions/calendar-time.svg';

// use TablesDebitor
export const PARAMS = ({ total, balance, totalInTime, total15, total30, totalMore, openModal, t }) => ([
  {
    name: t('common.date'),
    dataBase: 'date',
    dataType: 'date',
  },
  {
    name: t('debitor.invoiceNo'),
    dataBase: 'fakturaNr',
    dataType: 'int',
    textAlign: 'right',
  },
  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
    textAlign: 'right',
  },
  {
    name: t('common.name'),
    dataBase: 'actNameId',
  },
  {
    name: t('common.total'),
    dataBase: 'totalBilling',
    total: total,
    dataType: 'float',
  },
  {
    name: t('debitor.remainingAmount'),
    dataBase: 'balance',
    total: balance,
    dataType: 'float',
  },
  {
    name: t('debitor.termOfPayment'),
    dataBase: 'payment',
    dataType: 'date',
  },
  {
    name: t('debitor.paymentDate'),
    dataBase: 'dateOfPayment',
    dataType: 'date',
  },
  {
    name: t('debitor.paymentDelay'),
    dataBase: 'delay',
    dataType: 'int',
  },
  {
    name: t('debitor.unexpiredDebt'),
    dataBase: 'debtInTime',
    total: totalInTime,
    dataType: 'float',
  },
  {
    name: t('debitor.overdueFifteen'),
    dataBase: 'debt1_15Days',
    total: total15,
    colorBlock: '#fff5bd',
    dataType: 'float',
  },
  {
    name: t('debitor.overdueThirty'),
    dataBase: 'debt16_30Days',
    total: total30,
    colorBlock: '#ffdfd7',
    dataType: 'float',
  },
  {
    name: t('debitor.overdueMore'),
    dataBase: 'debtMore30Days',
    total: totalMore,
    colorBlock: '#fcdffa',
    dataType: 'float',
  },
  {
    name: t('debitor.reminders'),
    dataBase: 'remindersCount',
    dataType: 'int',
  },
  // {
  //   name: '',
  //   specialComponent: (a, b, c, d, data) => {
  //     return (
  //       <img src={periodImg} width={'20px'} style={{ 'z-index': '2' }} onClick={(e) => openModal(e, data)}/>
  //     );
  //   },
  // },
]);

export const PARAMS_PAYMENT = (t) => ([
  {
    name: t('common.date'),
    dataBase: 'date',
    dataType: 'date',
  },
  {
    name: t('common.accountNo'),
    dataBase: 'accountNm',
    dataType: 'int',
  },
  {
    name: t('common.receptNo'),
    dataBase: 'voucherNm',
  },
  {
    name: t('common.description'),
    dataBase: 'description',
  },
  {
    name: t('common.amount'),
    dataBase: 'amount',
    dataType: 'float',
  },
]);

export const REPORT = (t) => [
  {
    id: 1,
    title: t('common.filter.currentMonth'),
  },
  {
    id: 2,
    title: t('common.filter.lastMonth'),
  },
  {
    id: 3,
    title: t('common.filter.dateRange'),
  },
  {
    id: 4,
    title: t('common.filter.numberRange'),
  },
  {
    id: 5,
    title: t('common.filter.invoiceNumberRange'),
  },
  {
    id: 6,
    title: t('customer.customer'),
  },
];

export const REPORT_DEFAULT = '';

export const KEYS = ['id', 'title'];

export const TABLE = (t) => [
  {
    name: t('common.data'),
    relativeWidth: 0.1,
    dataBase: 'date',
  },
  {
    name: t('debitor.invoiceNo'),
    relativeWidth: 0.1,
    dataBase: 'DatbillingNoum',
  },
  {
    name: t('common.customerNo'),
    relativeWidth: 0.1,
    dataBase: 'customerNo',
  },
  {
    name: t('common.name'),
    relativeWidth: 0.2,
    dataBase: 'actNameId',
  },
  {
    name: t('common.amount'),
    relativeWidth: 0.1,
    dataBase: 'amount',
  },
  {
    name: t('common.description'),
    relativeWidth: 0.2,
    dataBase: 'designation',
  }, {
    name: t('common.accountNo'),
    relativeWidth: 0.1,
    dataBase: 'accountNo',
  },
  {
    name: t('common.receiptNo'),
    relativeWidth: 0.1,
    dataBase: 'documentNo',
  },
];

export const TABLEEDIT = (t) => [
  {
    name: t('common.data'),
    relativeWidth: 0.2,
    dataBase: 'Data',
  },
  {
    name: t('common.accountNo'),
    relativeWidth: 0.2,
    dataBase: 'AccountNo',
  },
  {
    name: t('common.receiptNo'),
    relativeWidth: 0.2,
    dataBase: 'ReceiptNo',
  },
  {
    name: t('debitor.subscription'),
    relativeWidth: 0.2,
    dataBase: 'Subscription',
  },
  {
    name: t('common.amount'),
    relativeWidth: 0.2,
    dataBase: 'Amount',
  },
];

export const FILTER = (t) =>[
  {
    title: t('debitor.allCustomer'),
    value: 'allCustomer',
    field: 'radioGroup',
    subfolder: [],
  },
  {
    title: t('debitor.selectedInvoiceAllCustomer'),
    value: 'allInvoicesSelectedCustomer',
    field: 'radioGroup',
    subfolder: [],
  },
  {
    title: t('debitor.onlyInvoice'),
    value: 'opnlySelectedInvoice ',
    field: 'radioGroup',
    subfolder: [],
  },
];

export const PAYMENT_FIELD = (t) => [
  {
    name: t('common.date'),
    dataBase: 'date',
    dataType: 'date',
  },
  {
    name: t('debitor.invoiceNo'),
    dataBase: 'facturaNm',
  },
  {
    name: t('common.customerNo'),
    dataBase: 'customerNm',
  },
  {
    name: t('common.name'),
    dataBase: 'actName',
  },
  {
    name: t('common.amount'),
    dataBase: 'amount',
    total: true,
    dataType: 'float',
  },
  {
    name: t('common.description'),
    dataBase: 'description',
  },
  {
    name: t('common.accountNo'),
    dataBase: 'accountNm',
  },
  {
    name: t('debitor.receiptNo'),
    dataBase: 'dateOfPayment',
    dataType: 'date',
  },
];
