import gql from 'graphql-tag';

export default gql`
query listFA_ProductionCustomerSortingKinds{
    fakturaAssist{
        listFA_ProductionCustomerSortingKinds{
          id
		      name
    }
    
  }
}`;
