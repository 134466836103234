import gql from 'graphql-tag';

export const listFA_ProductionFilterOrder = gql`
query listFA_ProductionFilterOrder($id: ID!){
    fakturaAssist{
    listFA_ProductionFilterOrder(id: $id){
		id
		name
		checked
    }
    
  }
}`;

export const listFA_ProductionFilterCustomer = gql`
query listFA_ProductionFilterCustomer($id: ID!){
    fakturaAssist{
    listFA_ProductionFilterCustomer(id: $id){
		id
		name
		checked
    }
    
  }
}`;

export const listFA_ProductionFilterArticle = gql`
query listFA_ProductionFilterArticle($id: ID!){
    fakturaAssist{
    listFA_ProductionFilterArticle(id: $id){
		id
		name
		checked
    }
    
  }
}`;


