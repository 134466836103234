/*eslint-disable*/
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Formik } from 'formik';
import { withFieldSubmit } from 'components/formik';
import { compose } from 'recompose';

import TableDefOrder from './tabs/tableDefOrder';
import AssortmentGroups from './tabs/AssortmentGroups';
import Period from './tabs/Period';
import BreakOff from './tabs/BreakOff';
import IMG_printer from '../../../style/img/print.svg';
import IMG_reports from '../../../style/img/reports.svg';

//import ActionsList, { ActionsListBlock } from 'components/actionsList/ActionsList';
import withPDFActions from 'components/viewPDF/withPDFActions';

import { Row, Col } from 'style';
import { Dropdown, Calendar, Transition, Checkbox } from 'components/elements';

import listFA_CustomerView_fkCustomerDefaultOrderKinds from './qql/listFA_CustomerView_fkCustomerDefaultOrderKinds';
import listFA_Customer from '../qql/listFA_Customer.js';
import getFA_Customer from '../qql/getFA_Customer.js';

import { useTranslation } from 'react-i18next';
import { ColumnSizer } from 'react-virtualized';


const DefaultOrder = (props) => {
  const [t] = useTranslation();
  const { showPDFPreview, showFormsType, setFieldValue, values,linkId, b, pdfParams } = props;
  const getNextDate = (date) =>{
    if(date.includes('-')){
      date = moment(date).format('DD.MM.YYYY');
    }
    const y = date.slice(6, 10);
    const m = date.slice(3, 5);
    const d = date.slice(0, 2);
    const standartDate = moment(y + '-' + m + '-' + d).add(6, 'day');
    return standartDate;
  }

  return (
    <React.Fragment>
     <Formik
          initialValues={
            {
              fromDate: b,
            }
          }
          linkId={props.linkId}
          sectionInfo={props.sectionInfo}
          enableReinitialize
          render ={(calendarInfo) =>{
          const pdfParams = {
            reportId: '7',
            ACustomerID: +linkId,
            APeriodStart: moment(calendarInfo.values.fromDate).format('DD.MM.YYYY'),
            ADoIncludeAllArticles: '0',
            AUserFormularID: '0',
          };
            return (
              <div>
      <HeadBlock>
        <MainBlock>
          <Row>
            <Col lg = {4}>
              <Dropdown
                title={t('customer.preset')}
                valueField = 'id'
                labelField = 'name'
                field = 'defaultDeliveryId'
                defaultValueField = {values.defaultDeliveryId}
                graphqlParams={{
                  query: listFA_CustomerView_fkCustomerDefaultOrderKinds,
                  variables: {
                    languageId: +localStorage.getItem('lngId') || 1,
                  },
                }}
              />
            </Col>
            <Col lg = {4}>
              <Dropdown
                graphqlParams={{
                  query: listFA_Customer,
                  variables: {params: {limit: 100}}
                }}
                getQuery = {getFA_Customer}
                title={t('customer.defaultOrderFromCustomer')}
                valueField = 'id'
                labelField = 'nameCustomerDisp'
                searchColumns = {['Name', 'NameIntern']}
                field = 'linkStandardOrder'
                defaultValueField = {values.linkStandardOrder}
                withSearch
                isClearable
              />
            </Col>
          </Row>

          <Row>
            <Col lg = {4}>
              <Checkbox
                margin='24px 0 0 0'
                width='100%'
                value={values.vorgabeAuto}
                field='vorgabeAuto'
                title={t('customer.createAuto')}
              />
            </Col>
          </Row>
        </MainBlock>
      </HeadBlock>
      <Transition index={0} text={t('common.deliveryPeriods')} isWhite>
        <Transition index={1} text={t('common.chooseDays')}>
          <div>
            <Row>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isMonday}
                  field='isMonday'
                  title={t('common.days.monday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isWednesday}
                  field='isWednesday'
                  title={t('common.days.wednesday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isFriday}
                  field='isFriday'
                  title={t('common.days.friday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isSunday}
                  field='isSunday'
                  title={t('common.days.sunday')}
                />
              </Col>
            </Row>
            <Row>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isTuesday}
                  field='isTuesday'
                  title={t('common.days.tuesday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isThursday}
                  field='isThursday'
                  title={t('common.days.thursday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.isSaturday}
                  field='isSaturday'
                  title={t('common.days.saturday')}
                />
              </Col>
            </Row>
          </div>
        </Transition>
        <Transition index={2} text={t('common.period')}>
          <Period {...props}/>
        </Transition>
        <Transition index={4} text={t('customer.breakOff')}>
          <BreakOff {...props}/>
        </Transition>
      </Transition>
      <Transition index={5} text={t('common.assortmentGroups')} isWhite>
        <AssortmentGroups {...props}/>
      </Transition>
      <Transition index={6} text={t('common.delivery')} isWhite>
        <DatesBlock>
          <Row>
            <Col lg = {2}>
              <Calendar
                margin='24px 0 0 0'
                withoutFormik={true}
                title={t('common.fromDate')}
                dateFormat
                setFieldValue={calendarInfo.setFieldValue}
                name='fromDate'
                value={calendarInfo.values.fromDate}
              />
            </Col>

            <Col width='170px'>
              <Calendar
                margin='24px 0 0 0'
                withoutFormik={true}
                title={t('common.toDate')}
                noActive={true}
                name='toDate'
                value={getNextDate(calendarInfo.values.fromDate)}
              />
            </Col>

            <Col width='47px'>
              <IMG onClick={()=>showFormsType(pdfParams, [7, 28, 33, 47])}>
                <img src={IMG_printer}/>
              </IMG>
            </Col>

            <Col width='50px'>
              <IMG onClick={()=>showPDFPreview(pdfParams)}>
                <img src={IMG_reports}/>
              </IMG>
            </Col>
          </Row>
        </DatesBlock>

        <TableDefOrder {...props} date = {calendarInfo.values.fromDate} onChangeFormik={props.onChangeFormik} setFieldValue={setFieldValue} submit={props.submit}/>
      </Transition>
      </div>);
          }}/>
    </React.Fragment>
  );
};

export default compose(
  withFieldSubmit,
  withPDFActions)(DefaultOrder);

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;
const MainBlock = styled(Col)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
const DatesBlock = styled(Col)`
  background-color: #fff;
  border: 0px;
  margin-top: 0px;
  margin-bottom: 25px;
`;
const IMG = styled.div`
  margin: 35px 0 0 0px;
  float: left;
  background:white;
  height: 37px;
  width: 37px;
  padding: 9px;
`;


