/*
    Our params in localStorage
    lng: 'English' use by i18.
    autoPrint: 'use by ViewPDF, auto send on printer after create order'
*/
//
export const changeIsAutomaticPrintingBoolean = (value) => {
  localStorage.setItem('autoPrint', !!value);
};

// change dates for static header in Productions
export const changeDatesSHProductions = (name, date) => {
  localStorage.setItem(name, date);
};
