import gql from 'graphql-tag';

export default gql`
 query listFA_Suppliers($params: ListQueryArgs, $filter: [FA_GeneralFilter]){
    fakturaAssist {
        listFA_Suppliers(params: $params, filter: $filter) {
            id
            suppliersNumber
            nameSupplier
        }
    }
 }
`;
