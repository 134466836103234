import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = (props) => (
  <MainText
    {...props}>
    {props.text}
  </MainText>
);

Text.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  padding: PropTypes.string,
};

Text.defaultProps = {
  text: '',
};

export default Text;

const MainText = styled.span`
  float: left;
  /* font-family: Roboto; */
  font-size: ${(props) => props.fontSize ? props.fontSize : '16px'};
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : '400'};
  margin-bottom: ${(props) => props.marginBottom ? props.marginBottom : '0px'};
  margin-top: ${(props) => props.marginTop ? props.marginTop : '0px'};
  padding: ${(props) => props.padding ? props.padding : '0px'};
  opacity: ${(props) => props.opacity ? props.opacity : '1'};
  font-style: normal;
  display: inline-block;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.color ? props.color : '#3c445a'};
`;

// <Text text="value" color="string" fontSize ="300px" fontWeight="500" marginBottom='20px'/>
