import Company from './Company';
import { getFA_Config } from '../qql/query';
import { updateFA_Config } from '../qql/mutation';


export default () => ({
  link: 'company',
  subsections: [],
  itemQuery: getFA_Config,
  updateMutation: updateFA_Config,
  Component: Company,
  fields: [
    'company', 'street', 'fax', 'taxNumber', 'name',
    'postCode', 'city', 'email', 'additive', 'phone', 'internet',
    'bankName', 'creditorId', 'bin', 'accountNr', 'iban', 'bic',
  ],
});

