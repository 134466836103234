import { useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';

import reduce from 'helpers/reduceGraphqlData';

import { useMutation } from 'components/graphql';
import { temporaryOrder } from '../../qql/mutation';

import { getFA_GetDefaultOrderForCustomerOnDate } from '../../qql/query';

const useTemporaryOrder = (props) => {
  const orderDate = props.contex?.date?.date;
  const [memoryProductData, setMemoryProductData] = useState(null);
  const {
    listFA_CustomersListForCreatingOrdersOnDate,
    isDelivery,
    deliveryInfo,
    contex, client,
    prevVariables,
    prevCustomerInfo } = props;
  const [temporaryOrderMutation] = useMutation(temporaryOrder);
  const changeTemporaryIdForCustomer = (temporaryId) =>{
    try{
      const customersFromCache = { ...client.cache.readQuery({
        query: listFA_CustomersListForCreatingOrdersOnDate,
        variables: contex,
      }) };
      const deepArray = cloneDeep(customersFromCache);
      const actualCustomer = cloneDeep(prevCustomerInfo.info);
      actualCustomer.temporaryId = temporaryId;
      deepArray.fakturaAssist.listFA_CustomersListForCreatingOrdersOnDate[prevCustomerInfo.index] = actualCustomer;
      const changedData = deepArray.fakturaAssist.listFA_CustomersListForCreatingOrdersOnDate;
      try{
        // client.cache.writeQuery({
        //   query: listFA_CustomersListForCreatingOrdersOnDate,
        //   variables: { ...contex, params: { limit: null,
        //     offset: 0,
        //     sort: { field: 'customerNr', order: 'ASC' } } },
            
        //   data: {
        //     fakturaAssist: {
        //       listFA_CustomersListForCreatingOrdersOnDate: [],
        //       __typename: 'FA_Query',
        //     },
        //   },
        // });
        // client.cache.writeQuery({
        //   query: listFA_CustomersListForCreatingOrdersOnDate,
        //   variables: { ...contex, params: { limit: null,
        //     offset: 0,
        //     sort: { field: 'customerNr', order: 'DESC' } } },
            
        //   data: {
        //     fakturaAssist: {
        //       listFA_CustomersListForCreatingOrdersOnDate: [],
        //       __typename: 'FA_Query',
        //     },
        //   },
        // });
        // client.cache.writeQuery({
        //   query: listFA_CustomersListForCreatingOrdersOnDate,
        //   variables: { ...contex, params: { limit: null,
        //     offset: 0,
        //     sort: { field: 'id', order: 'DESC' } } },
            
        //   data: {
        //     fakturaAssist: {
        //       listFA_CustomersListForCreatingOrdersOnDate: [],
        //       __typename: 'FA_Query',
        //     },
        //   },
        // });
        // client.cache.writeQuery({
        //   query: listFA_CustomersListForCreatingOrdersOnDate,
        //   variables: { ...contex, params: {
        //     limit: null,
        //     offset: 0,
        //     search: { columns: ['id'], string: '' },
        //     sort: { field: 'customerNr', order: 'ASC' } } },
        //   data: {
        //     fakturaAssist: {
        //       listFA_CustomersListForCreatingOrdersOnDate: changedData,
        //       __typename: 'FA_Query',
        //     },
        //   },
        // });
        
        // client.cache.writeQuery({
        //   query: listFA_CustomersListForCreatingOrdersOnDate,
        //   variables: { ...contex, params: { limit: null,
        //     offset: 0,
        //     search: { columns: ['id'], string: '' },
        //     sort: { field: 'customerNr', order: 'DESC' } } },
        //   data: {
        //     fakturaAssist: {
        //       listFA_CustomersListForCreatingOrdersOnDate: [],
        //       __typename: 'FA_Query',
        //     },
        //   },
        // });

        client.cache.writeQuery({
          query: listFA_CustomersListForCreatingOrdersOnDate,
          variables: contex,
          data: {
            fakturaAssist: {
              listFA_CustomersListForCreatingOrdersOnDate: changedData,
              __typename: 'FA_Query',
            },
          },
        });

        //Change customer position
      }catch(err) {
      //console.log(err)
      }
    }catch(err) {
      //console.log(err);
    }
  };

  const checkTemporaryOrder = async (variables, memoryProductDataFromOrderHandler, { orderRef, deliveryTime }, tempDataCopy) => {
    const checkTempDataCopy = () => {
      if(!tempDataCopy?.orderRef && !tempDataCopy?.deliveryTime && (orderRef || deliveryTime)) {
        return true;
      } else if(orderRef !== tempDataCopy?.orderRef || deliveryTime !== tempDataCopy?.deliveryTime) {
        return true;
      }
      return false;
    };
    
    //Check old and new data
    try{
      const cache = client.readQuery({
        query: getFA_GetDefaultOrderForCustomerOnDate,
        variables: variables,
      }).fakturaAssist.getFA_GetDefaultOrderForCustomerOnDate;

      const data = cache.productTable
        .map((order) => ({ ...order,
          quantity: order.quantity === -0 ? '' : `${order.quantity}`,
          discount: order.discount === null ? '' : `${order.discount}`,
          price: (order.price === 0 || order.price === '0.00') ? '' : `${order.price}`,
        }))
        .sort((a, b) => +a.id - +b.id);
      if(data.length || orderRef || deliveryTime) {
        const isCreateTemporaryOrder = !isEqual(data, cloneDeep(memoryProductDataFromOrderHandler)) || checkTempDataCopy();
        if(isCreateTemporaryOrder) {
          const productsForOrder = cache.productTable.map((item)=>{
            const {
              virtualPositionLink,
              productLink,
              quantity,
              price,
              discount,
              taxCode,
              indTextProduction,
              indTextDeliveryNote,
              total,
            } = item;
            return {
              virtualPositionLink: +virtualPositionLink || 0,
              productLink: productLink,
              quantity: +quantity || 0,
              price: price ? Number(price) : 0,
              total: total ? Number(total) : 0,
              discount: +discount || 0,
              taxCode: +taxCode || 0,
              indTextProduction: indTextProduction || '',
              indTextDeliveryNote: indTextDeliveryNote || '',
            };
          });
          if(isDelivery) {
            const delivery = {
              virtualPositionLink: 2,
              productLink: null,
              quantity: 1,
              total: deliveryInfo.total,
              discount: null,
              taxCode: 2,
            };
            productsForOrder.push(delivery);
          }
          const dataForTemporaryOrder = {
            args: {
              date: orderDate,
              customerId: prevVariables.args.id,
              temporaryId: prevVariables.args.temporaryId,
              productsList: productsForOrder,
              deliveryTime,
              orderRef,
            },
          };
          try{
            const data = await temporaryOrderMutation({ variables: dataForTemporaryOrder });
            const newTemporaryId = await reduce(data.data).id;
            await changeTemporaryIdForCustomer(newTemporaryId);
          }catch(err) {
          //console.log(err);
          }
        }
      }
    } catch(err) {
    // console.log(err);
    }
    return null;
  };

  return {
    checkTemporaryOrder,
    setMemoryProductData,
    memoryProductData,
  };
};

export default useTemporaryOrder;

