import gql from 'graphql-tag';

export const CALL_ADD_INVOICE_PAYMENT = gql`
query callPR_AddInvoicePayment($args: FieldsInoivePayment) {
	fakturaAssist {
	  callPR_AddInvoicePayment(args: $args) {
		id
	  }
	}
  }
`;

export const ENTER_PAYMENT_INFO = gql`
query listFA_InvoiceForDebitorEnterPayment($id: Int) {
	fakturaAssist {
	  listFA_InvoiceForDebitorEnterPayment(id: $id) {
		total
		balance
		customerNr
		invoiceNr
		date
		actName
	  }
	}
  }
`;

export const ENTER_PAYMENT = gql`
query listFA_OpenDebtsPayment($id: Int) {
	fakturaAssist {
	  listFA_OpenDebtsPayment(id: $id) {
		id
		date
		accountNm
		voucherNm
		amount
		description
	  }
	}
  }
`;

export const DATE_REMINDER = gql`
query listFA_Invoice($id: Int) {
	fakturaAssist {
	  listFA_InvoiceDate(id: $id) {
		id
		dateDebtReminder1
		dateDebtReminder2
		dateDebtReminder3
	  }
	}
  }
`;

export const UPDATE_DATE_REMINDER = gql`
mutation UPDATE_DATE_REMINDER($id: Int!, $fields: FA_InvoiceReminder!) {
	fakturaAssist {
	  updateDebtReminder(id: $id, fields: $fields) {
		id
		dateDebtReminder1
		dateDebtReminder2
		dateDebtReminder3
	  }
	}
  }
`;

export const DEBITOR = gql`
query callTFN_OpenDebtsListByInvoicesOnPeriod($args: ChangeDate){
	fakturaAssist{
		callTFN_OpenDebtsListByInvoicesOnPeriod(args: $args){
			total
			balance
			total15
			total30
			totalMore
			totalInTime
			invoicesList
			items {
				id
				date
				fakturaNr
				customerNr
				actNameId
				totalBilling
				balance
				payment
				dateOfPayment
				customerId
				delay
				debtInTime
				debt1_15Days
				debt16_30Days
				debtMore30Days
				remindersCount
			}
		}
	}
}
`;

export const VIEW_FROM = gql`
query listFA_view_fkAllFormular($languageId: Int) {
  fakturaAssist{
    listFA_view_fkAllFormular(languageId: $languageId){
      reportId
      form
    }
  }
}
`;

export const VIEW_FROM_PAYMENTS = gql`
query listFA_view_fkAllFormularPayments($languageId: Int) {
  fakturaAssist{
    listFA_view_fkAllFormularPayments(languageId: $languageId){
      reportId
      form
    }
  }
}
`;

// haven`t language
export const REMINDER_TEXT = gql`
query listFA_FakturaAssistMahnungDefinieren {
  fakturaAssist {
    listFA_FakturaAssistMahnungDefinieren{
      id
      name
      title
    }
  }
}
`;

export const CUSTOMER = gql`
query listFA_Customer {
  fakturaAssist {
    listFA_Customer(params: {}){
      id
      nameCustomerDisp
    }
  }
}
`;

export const PAYMENT = gql`
query listFA_FakturaAssistDebitor($args: FA_FakturaAssistDebitorSettings) {
	fakturaAssist {
		listFA_FakturaAssistDebitor(args: $args) {
			id
			facturaNm
			date
			amount
			debit
			accountNm
			linkCustomer
			description
			voucherNm
			isExportFibu
			linkTakenFrom
			customerNm
			dateOfPayment
			actName
		}
	}
}
`;

export const IMPORT = gql`
	query listFA_ImportISO($input: [FA_ImportFile]) {
		fakturaAssist {
			listFA_ImportISO(input: $input) {
				id
				isOK
				errorMessage
				date
				linkCustomer
				actualName
				customerInfo
				discountAmount
				amountPosted
				FIBUAccount
				flagBookAfter
		}
	}
}
`;
