import gql from 'graphql-tag';

export default gql`
mutation updateFA_DeliveryPeriods($id: Int!, $fields:fieldsFA_DeliveryPeriods){
    fakturaAssist{
    updateFA_DeliveryPeriods(id: $id, fields: $fields){
		id
    }
    
  }
}`;
