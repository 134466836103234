import React from 'react';
import { Row, Col } from 'style/index';
import Formik from 'components/formik';

import { Checkbox } from 'components/elements';

import { useTranslation } from 'react-i18next';

export default (props) => {
  const { data, mutations, linkId, sectionInfo } = props;
  console.log(data);
  const [t] = useTranslation();
  return(
    <Formik initialValues={data} enableReinitialize={true} onSubmit={mutations} linkId={linkId} sectionInfo={sectionInfo} >
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        return (
          <div>
            <Row>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliveryMonday}
                  field='deliveryMonday'
                  title={t('common.days.monday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliveryWednesday}
                  field='deliveryWednesday'
                  title={t('common.days.wednesday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliveryFriday}
                  field='deliveryFriday'
                  title={t('common.days.friday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliverySunday}
                  field='deliverySunday'
                  title={t('common.days.sunday')}
                />
              </Col>
            </Row>
            <Row>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliveryTuesday}
                  field='deliveryTuesday'
                  title={t('common.days.tuesday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliveryThursday}
                  field='deliveryThursday'
                  title={t('common.days.thursday')}
                />
              </Col>
              <Col lg = {2}>
                <Checkbox
                  margin='24px 0 0 0'
                  width='100%'
                  value={values.deliverySaturday}
                  field='deliverySaturday'
                  title={t('common.days.saturday')}
                />
              </Col>
            </Row>
          </div>);
      }}
    </Formik>);
};
