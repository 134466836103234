import gql from 'graphql-tag';

export const listFA_CustomerDefaultProfiles = gql`
    query listFA_CustomerDefaultProfiles {
        fakturaAssist {
            listFA_CustomerDefaultProfiles {
                id
                name
            }
        }
    }
`;

export const listFA_DiscountDropdown = gql`
query listFA_DiscountDropdown($languageId: Int){
    fakturaAssist{
    listFA_DiscountDropdown(languageId: $languageId){
		id
		name
    }

  }
}`;
export const listFA_CustomerView_fkInvoiceIntervals = gql`
query listFA_CustomerView_fkInvoiceIntervals($languageId: Int){
    fakturaAssist{
      listFA_CustomerView_fkInvoiceIntervals(languageId: $languageId){
		id
		name
    }

  }
}`;

export const updateFA_Config = gql`
mutation updateFA_Config($fields: FA_ConfigFields!){
    fakturaAssist{
		updateFA_Config(fields: $fields){
		id

    }

  }
}`;

export const listFA_CustomerView_fkDebitorStatuses = gql`
  query listFA_CustomerView_fkDebitorStatuses($params: ListQueryArgs, $languageId: Int) {
    fakturaAssist {
      listFA_CustomerView_fkDebitorStatuses(params: $params, languageId: $languageId) {
        id
        name
      }
    }
  }
`;

export const getCustomerNoFromConfig = gql`
    query getCustomerNoFromConfig {
        fakturaAssist {
            getFA_Config {
              flagAutoKundenNr
                autoKundenNrFix
                autoKundenNrVariabel
            }
        }
    }
`;

export const listFA_CustomerInvoiceSummaryForms = gql`
    query listFA_CustomerInvoiceSummaryForms($languageId: Int) {
  fakturaAssist {
    listFA_CustomerInvoiceSummaryForms(languageId: $languageId) {
      id
      form: formular
      reportGroupID
      formIdDefault: defaultFormularID
      name
    }
    }
  }

`;

export const listFA_CustomerPaymentSlipReportID = gql`
    query listFA_CustomerPaymentSlipReportID($languageId: Int) {
  fakturaAssist {
    listFA_CustomerPaymentSlipReportID(languageId: $languageId) {
      id
      name
      form
      formIdDefault
    }
    }
  }
`;

export const listFA_CustomerDefaultProfileDeliveryPeriods = gql`
query listFA_CustomerDefaultProfileDeliveryPeriods($id: Int!, $isVacation: Boolean!){
    fakturaAssist{
        listFA_CustomerDefaultProfileDeliveryPeriods(id: $id, isVacation: $isVacation){
		id
		linkCustomer
		description
		fromDate
		toDate
		isCustomWeekDaySetting
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		isVacation
		isRepeatYearly
    }
  }
}`;

export const updateFA_CustomerDefaultDeliveryPeriods = gql`
mutation updateFA_CustomerDefaultDeliveryPeriods($id: Int!, $fields: fieldsFA_DeliveryPeriods!){
    fakturaAssist{
      updateFA_CustomerDefaultDeliveryPeriods(id: $id, fields: $fields){
		id
		linkCustomer
		description
		fromDate
		toDate
		isCustomWeekDaySetting
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		isVacation
		isRepeatYearly
    }
  }
}`;

export const createFA_CustomerDefaultDeliveryPeriods = gql`
mutation createFA_CustomerDefaultDeliveryPeriods($fields: fieldsFA_DeliveryPeriods!){
    fakturaAssist{
      createFA_CustomerDefaultDeliveryPeriods(fields: $fields){
		id
		description
    linkCustomerDefaultProfile
    linkCustomer
    isVacation
		fromDate
		toDate
		isCustomWeekDaySetting
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		isVacation
		isRepeatYearly
    }
  }
}`;

export const deleteFA_CustomerDefaultDeliveryPeriods = gql`
mutation deleteFA_CustomerDefaultDeliveryPeriods($id: Int!){
    fakturaAssist{
      deleteFA_CustomerDefaultDeliveryPeriods(id: $id){
		id
		linkCustomer
		description
		fromDate
		toDate
		isCustomWeekDaySetting
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		isVacation
		isRepeatYearly
    }
  }
}`;

export const listFA_CustomerInvoiceForms = gql`
    query listFA_CustomerInvoiceForms($languageId: Int) {
  fakturaAssist {
    listFA_CustomerInvoiceForms(languageId: $languageId) {
      id
      form: formular
      formIdDefault: defaultFormularID
      name
    }
    }
  }

`;

export const listFA_CustomerDeliveryNoteForms = gql`
    query listFA_CustomerDeliveryNoteForms($languageId: Int) {
  fakturaAssist {
    listFA_CustomerDeliveryNoteForms(languageId: $languageId) {
      id
      form: formular
      reportGroupID
      formIdDefault: defaultFormularID
      name
    }
    }
  }
`;

export const listFA_TaxInclusionKinds = gql`
    query listFA_TaxInclusionKinds($languageId: Int) {
  fakturaAssist {
    listFA_TaxInclusionKinds(languageId: $languageId) {
        id
			name
    }
    }
  }
`;

export const listFA_ConfigCustomerPriceCategories = gql`
query listFA_ConfigCustomerPriceCategories{
    fakturaAssist{
    listFA_ConfigCustomerPriceCategories{
		id
		resName
    }
  }
}`;

export const getFA_CustomerDefaultProfile = gql`
    query getFA_CustomerDefaultProfile($id: Int!) {
        fakturaAssist {
            getFA_CustomerDefaultProfile(id: $id) {
                id
                name
                MWStSystem
                articlePriceGroup
                discountDays
                linkBank
                discountCode
                linkQuantitiesDiscount
                debitorStatus
                invoiceReportID
                fakturaAccounting
                invoiceSummaryReportID
                invoicePaymentSlipReportID
                payment
                orderReportID
                discountInPercent
                discountInPercent1
                discountInPercent2
                discountInPercent3
                discountInPercent4
                discountInPercent5
                discountInPercent6
                discountInPercent7
                discountInPercent8
                discountInPercent9
                discountInPercent10
                discountInPercent11
                discountInPercent12
                discountInPercent13
                discountInPercent14
                discountInPercent15
                discountInPercent16
                discountInPercent17
                discountInPercent18
                discountInPercent19
                discountInPercent20
                isMonday
                isTuesday
                isWednesday
                isThursday
                isFriday
                isSaturday
                isSunday
            }
        }
    }
`;

export const createFA_DefaultCustomerProfile = gql` mutation createFA_DefaultCustomerProfile($fields:CustomerProfileInput!) {
    fakturaAssist {
        createFA_DefaultCustomerProfile(fields: $fields) {
            id
            name
        }
    }
}
`;

export const deleteFA_DefaultCustomerProfile = gql` mutation deleteFA_DefaultCustomerProfile($id: Int!) {
    fakturaAssist {
        deleteFA_DefaultCustomerProfile(id: $id) {
            id
            name
        }
    }
}
`;

export const updateFA_DefaultCustomerProfile = gql`
mutation updateFA_DefaultCustomerProfile($id: Int!, $fields: CustomerProfileInput!) {
        fakturaAssist {
          updateFA_DefaultCustomerProfile(id: $id, fields: $fields) {
                id
                name
                MWStSystem
                articlePriceGroup
                discountDays
                linkBank
                discountCode
                linkQuantitiesDiscount
                debitorStatus
                invoiceReportID
                fakturaAccounting
                invoiceSummaryReportID
                invoicePaymentSlipReportID
                payment
                orderReportID
                discountInPercent
                discountInPercent1
                discountInPercent2
                discountInPercent3
                discountInPercent4
                discountInPercent5
                discountInPercent6
                discountInPercent7
                discountInPercent8
                discountInPercent9
                discountInPercent10
                discountInPercent11
                discountInPercent12
                discountInPercent13
                discountInPercent14
                discountInPercent15
                discountInPercent16
                discountInPercent17
                discountInPercent18
                discountInPercent19
                discountInPercent20
                isMonday
                isTuesday
                isWednesday
                isThursday
                isFriday
                isSaturday
                isSunday
            }
        }
    }
`;
