
import gql from 'graphql-tag';

export default gql`
query getStatusCAdirect($onDate: String!){
    fakturaAssist{
	    getStatusCAdirect(onDate: $onDate){
			status
		}
  	}
}`;
