
import gql from 'graphql-tag';

export default gql`
mutation copyArticlePrice($idCustomer: Int!, $idCopyOfCustomer: Int!){
    fakturaAssist{
    copyArticlePrice(idCustomer: $idCustomer, idCopyOfCustomer: $idCopyOfCustomer){
		note
		
    }
    
  }
}`;
