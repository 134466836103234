import gql from 'graphql-tag';

const GET_FA_CUSTOMER_FILTER = gql`
  query getFA_CustomerFilter {
      fakturaAssist {
          listFA_CustomerListsDefault {
              id
              name
              relatedCustomers
          }
          listFA_CustomerGroup {
              id
              group
          }
          listFA_CustomerTourplan {
              id
              name
          }
      }
  }
`;

export default GET_FA_CUSTOMER_FILTER;
