import gql from 'graphql-tag';

export default gql`
mutation deleteFA_CustomerResellerPrices($id: ID!){
    fakturaAssist{
    deleteFA_CustomerResellerPrices(id: $id){
		customerId
    }
    
  }
}`;
