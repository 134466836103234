
export const tableData = (t) => ([
  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
    relativeWidth: 0.3,
    //headerTextAlign: 'right',
    //contentTextAlign: 'right'
  },

  {
    name: t('common.name'),
    dataBase: 'nameRes',
  },
]);

export const tableDataExtended = (t) => ([
  {
    name: t('common.date'),
    dataBase: 'offerDate',
    relativeWidth: 0.2,
    dataType: 'date',
  },
  {
    name: 'Nr.',
    dataBase: 'offerNr',
    relativeWidth: 0.2,
  },
  {
    name: t('common.customerNo'),
    dataBase: 'customerNr',
    relativeWidth: 0.2,
  },
  {
    name: t('common.name'),
    dataBase: 'nameRes',
    relativeWidth: 0.2,
  },
  {
    name: t('common.total'),
    dataBase: 'total',
    relativeWidth: 0.2,
    dataType: 'float',
  },
]);
