/*eslint-disable */
import App from './App';
import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { withClientState } from 'apollo-link-state';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { RetryLink } from 'apollo-link-retry';
import { version } from '../package.json';

import { GRAPHQL_API_URI } from 'config/api';

import registerServiceWorker from './registerServiceWorker'

import './translation/i18next';
import { withAuth } from './auth';

const defaultOptions = {
  query: {
    errorPolice: 'all',
    fetchPolicy: 'network-only',
  },
  mutate: {
    errorPolice: 'all',
  },
};

const retryLink = new RetryLink({
  delay: { initial: 1000 },
  attempts: { max: 1 },
});

const httpLink = retryLink.concat(new BatchHttpLink({ uri: GRAPHQL_API_URI }));

const errorLink = onError(({ graphQLErrors }) => {
  // if (graphQLErrors) {
  //   graphQLErrors.map(({ message }) => (console.log(message), confirm('Sorry, an error occurred while sending the request \n' + message + '\nReload page? ') ? location.reload() : null )
  //   );
  // }
});

const cache = new InMemoryCache({
  freezeResults: false,
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'listDefaultOrder': return `listDefaultOrder:${responseObject.id}`;
      case 'FA_OrderProducts': return `FA_OrderProducts:${responseObject.orderPositionID}${responseObject.id}`;
      case 'FA_OrderPosition': return `FA_OrderPosition:${responseObject.orderPositionID}${responseObject.id}`;
      case 'FA_OfferCustomersList': return `FA_OfferCustomersList:${responseObject.id}${responseObject.temporaryId}${responseObject.offerID}`;
      case 'FA_GetDefaultOrderForCustomerOnDate': return `FA_GetDefaultOrderForCustomerOnDate:${responseObject.id}${responseObject.temporaryId}${responseObject.offerID}`;
      case 'FA_CustomersListForCreatingOrdersOnDate': return `FA_CustomersListForCreatingOrdersOnDate:${responseObject.id}${responseObject.temporaryId}${responseObject.offerId}`;
      
      default: return defaultDataIdFromObject(responseObject);
      // default: return  `${responseObject.__typename}:${responseObject.id}`
    }
  }
}
  );

const stateLink = withClientState({
  cache,
});

const link = ApolloLink.from([stateLink, errorLink, withAuth, httpLink]);

const client = new ApolloClient({
  link,
  cache,
  version,
  name: `wawiassist-web${process.env.NODE_ENV === '-production' ? '' : '-dev'}`,
  connectToDevTools: true,
  defaultOptions,
  assumeImmutableResults: true,
});

client.initQueryManager();

const ApolloApp = () => (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App client={client}/>
      </BrowserRouter>
    </ApolloProvider>
);

render(<ApolloApp/>, document.getElementById('root'));
registerServiceWorker();
