import gql from 'graphql-tag';

export default gql`
query callFA_RecalculateOrders($id: Int,$fromDate: String,$toDate: String,$onlyCAOrders: Boolean,$refreshPrices: Boolean,$refreshTaxAndDiscounts: Boolean){
	fakturaAssist{
		callFA_RecalculateOrders(
			args: {id: $id,fromDate: $fromDate,toDate: $toDate,onlyCAOrders: $onlyCAOrders,refreshPrices: $refreshPrices,refreshTaxAndDiscounts: $refreshTaxAndDiscounts}){
			id
		}
	}
}`;

