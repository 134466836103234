import gql from 'graphql-tag';

export default gql`
mutation createFA_CustomerListsRelations($fields: CustomerListsRelationsFields!){
    fakturaAssist{
    createFA_CustomerListsRelations(fields: $fields){
		id
    }
    
  }
}`;
