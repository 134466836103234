import React from 'react';
import styled from 'styled-components';

import listFA_ProductionArticles from './qql/listFA_ProductionArticles';
import DxDataTable from 'components/dxTableGrid';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const tableData = (t) => [
    {
      name: t('common.articleNo'),
      dataBase: 'articleNo',
      relativeWidth: .1,
    },
    {
      name: t('common.description'),
      dataBase: 'description' || t('common.description'),
    },
  ];
  return (
    <HeadBlock>
      <MainBlock>
        <DxDataTable
          // {...props}
          label={t('production.assigment_warning')}
          labelColor='red'
          height={700}
          graphqlParams={{ query: listFA_ProductionArticles }}
          tableData={tableData(t)}
        />
      </MainBlock>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  height: 800px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
`;
