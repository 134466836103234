import gql from 'graphql-tag';

export default gql`
query listFA_CustomerView_fkCurrencies{
    fakturaAssist{
    listFA_CustomerView_fkCurrencies{
		id
		name
    }
    
  }
}`;
