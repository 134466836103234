import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'style';
import { Button } from 'components/elements';

import TableGrid from 'components/tableGrid/TableGrid';

const tableData = (t) => ([
  
  {
    name: t('common.deliveryNr'),
    dataBase: 'orderNr',
  },
  {
    name: t('common.user'),
    dataBase: 'userFullName',
  },
  {
    name: t('common.date'),
    dataBase: 'dOperation',
  },
]);
  
const MultiOrdersModal = ({ closeModal, data, multiOrderModalOnAccept, t, customerNr }) => (
  <ModalBody>
    <TitleText> {t('order.modals.multiOrdersMessage', { ordersCount: data.length })}</TitleText>
    <TableGrid
      data = {data }
      tableData={ tableData(t) }
      height={160}
      t={t}
      disableHeightChange
    />
    <BottomBlock>
    		<Col lg={5}>
    			<Button value ={t('common.no')} className='black' upperCase onClick={closeModal}/>
    		</Col>
    		<Col lg={3}>
    			<Button value ={t('common.ok')} className='grey' upperCase onClick={multiOrderModalOnAccept} />
    		</Col>
    	</BottomBlock>
  </ModalBody>
);

const ModalBody = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 15px;
`;
const BottomBlock = styled(Row)`
    margin-top: 40px;
    justify-content: center;
`;

const TitleText = styled.span`
  font-size: 18px
  font-weight: 600;
  color: #3c445a;
  display: inline-block
  padding: 0 0 16px 0;
`;
export default MultiOrdersModal;


