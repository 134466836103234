import gql from 'graphql-tag';

export default gql`
query ($filter: FA_CreatedInvoiceFilterInputs!){
    fakturaAssist{
    	listFA_Invoice(filter: $filter){
    		id
	        date
	        invoiceNr
	        customerNr
            total
            customerLink
            isDelete
            nameRes
            paidByAutoBankDebit
			sammelfakturaAufKunde
 		}
 	}
}`;
