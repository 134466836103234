import React from 'react';
import styled, { keyframes } from 'styled-components';
import loading from './loading.svg';

export const LoadingWrap = (Component) => styled(Component)`
	${(props) => props.loading ? `
	position: relative;
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: url(${loading}) no-repeat center, rgba(255, 255, 255, 0.86);
			background-size: 60px auto;
			z-index: 15;
		}` : ''}
`;
const progressAnimation = keyframes`
	0% {width: 5%;}
  10% {width: 65%;}
  40% {width: 85%;}
  100% {width: 97%; }
`;
export const LoadingBar = styled.div`
	height: 3px;
	background: #FD2724;
	box-shadow: 0 1px 1px 0 rgba(90, 90, 90, 0.2);
	animation-name: ${progressAnimation};
	animation-duration: 10s;
	:before {
		content: '';
		height: 3px;
		position: absolute;

  }
`;

export const useLoading = ({ isLoading }) => isLoading ? `
	position: relative;
	z-index: 10;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: url(${loading}) no-repeat center, rgba(255, 255, 255, 0.86);
		background-size: 60px auto;
		z-index: 100;
	}
` : '';

export const LoadingSpinner = styled.div`
	position: relative;
	width: ${(props) => props.children ? '100%' : props.width || 'unset'};
	height: ${(props) => props.children ? '100vh' : props.height || 'unset'};
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: url(${loading}) no-repeat center ${(props) => props.transparent ? '' : ',rgba(255, 255, 255, 0.86)'};
		background-size: 60px auto;
		z-index: 100;
	}
`;

export const Dots = (props) => <StyledDotContainer margin={props.margin}><Dot {...props} delay='0ms'/>  <Dot {...props} delay='160ms'/>  <Dot {...props} delay='320ms'/> </StyledDotContainer>;

const keyFrameExampleOne = keyframes`
0%, 80%, 100% {
    opacity: 0;
}
40% {
    opacity: 1;
}
`;

const StyledDotContainer = styled.div`
	margin: ${({ margin }) => margin};
`;

const Dot = styled.span`
animation-duration: 1s;
animation-delay: ${(props) => props.delay};
animation-iteration-count: infinite;
animation-name: ${keyFrameExampleOne};
animation-timing-function: ease-in-out;
background-color: hsl(0,0%,80%);
border-radius: ${(props) => props.radius ? props.radius : '5px'};
display: inline-block;
height: ${(props) => props.radius ? props.radius : '5px'};
vertical-align: top;
width:  ${(props) => props.radius ? props.radius : '5px'};
margin-right: 10px;
`;

