import listFA_Customer from '../customer/qql/listFA_Customer';
import listFA_CustomerGroup from '../customer/adresse/qql/query/listFA_CustomerGroup';
import listFA_CustomerListsDefault from '../production/edit/table/crossTable/qql/listFA_CustomerListsDefault';
// import { getGroupDeliveryItem } from '../order/info'

export const newInvoicefilterData = (t) => [{
  title: t('offer.filter.activeCustomer'),
  field: 'IsAktiviert',
  value: 1,
  subItems: [
    {
      title: t('common.groupDeliveryNote'),
      field: 'LinkLSGruppe',
      queryField: 'getFA_Config.orderGroup',
      indexAsValue: true,
    },
    {
      title: t('customer.invoice.accounting'),
      field: 'FakturaAbrechnung',
      queryField: 'listFA_CustomerFormulareFacturaAdchnung',
      titleField: 'name',
      valueField: 'id',
      sortOrder: 'none',
    },
  ],
}, {
  title: t('common.filter.inactiveCustomers'),
  field: 'IsAktiviert',
  value: 0,
  subItems: [
    {
      title: t('common.groupDeliveryNote'),
      field: 'LinkLSGruppe',
      queryField: 'getFA_Config.orderGroup',
      indexAsValue: true,
    },
    {
      title: t('customer.invoice.accounting'),
      field: 'FakturaAbrechnung',
      queryField: 'listFA_CustomerFormulareFacturaAdchnung',
      titleField: 'name',
      valueField: 'id',
      sortOrder: 'none',
    },
  ],
}];

export const editInvoiceFilterData = (t) => [
  {
    title: t('common.filter.currentMonth'),
    field: 'folderName',
    value: 'currentMonth',
  },
  {
    title: t('common.filter.lastMonth'),
    field: 'folderName',
    value: 'lastMonth',
  },
  {
    title: t('common.filter.invoiceDateRange'),
    field: 'folderName',
    value: 'invoiceDateRange',
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  },
  {
    title: t('common.filter.invoiceNumberRange'),
    field: 'folderName',
    value: 'invoiceNumbersRange',
    additionalInputs: [
      {
        type: 'input',
        field: 'firstRange',
        inputType: 'number',
        isSmall: true,
        keepItemsOnLevel: true,
        title: t('common.filter.from'),
      },
      {
        type: 'input',
        field: 'secondRange',
        inputType: 'number',
        isSmall: true,
        title: t('common.filter.to'),
        keepItemsOnLevel: true,
      },
    ],
  },
  {
    title: t('common.filter.journalNumbersRange'),
    field: 'folderName',
    value: 'journalNumbersRange',
    additionalInputs: [
      {
        type: 'input',
        field: 'firstRange',
        inputType: 'number',
        isSmall: true,
        keepItemsOnLevel: true,
        title: t('common.filter.from'),
      },
      {
        type: 'input',
        field: 'secondRange',
        inputType: 'number',
        isSmall: true,
        title: t('common.filter.to'),
        keepItemsOnLevel: true,
      },
    ],
  },
  {
    title: t('customer.customer'),
    field: 'folderName',
    value: 'customer',
    additionalInputs: [
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_Customer,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'customerId',
        labelField: 'nameCustomerDisp',
        valueField: 'id',
      },
    ],
  },
  {
    title: t('order.customerGroup'),
    field: 'folderName',
    value: 'customerGroup',
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_CustomerGroup,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'groupId',
        labelField: 'group',
        valueField: 'id',
        sortKey: 'label',
      },
    ],
  },
  {
    title: t('common.filter.customerList'),
    field: 'folderName',
    value: 'customerList',
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_CustomerListsDefault,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'listId',
        labelField: 'name',
        valueField: 'id',
      },
    ],
  },
  {
    title: t('common.filter.oneTimeCustomers'),
    field: 'folderName',
    value: 'oneTimeCustomers',
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  },
  {
    title: t('common.filter.openInvoicesTillDate'),
    field: 'folderName',
    value: 'openInvoices',
    additionalInputs: [
      {
        type: 'calendar',
        field: 'date',
      },
    ],
  },
];
