import React from 'react';
import { Row } from 'style';
import styled from 'styled-components';

import { Calendar } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';

import { historyTableParams } from './params';

export default (props) => {
  const { t } = props;
  return (
    <Row>
      <TableBlock>
        <CalendarBlock>
          <Calendar title={t('history.dateFilter')}/>
        </CalendarBlock>
        <TableGrid
          tableData={historyTableParams(t)}
          height={600}
          disableHeightChange
        />
      </TableBlock>
    </Row>
  );
};

const TableBlock = styled.div`
  margin: 20px 0px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  width: 100%;
`;

const CalendarBlock = styled.div`
  padding-bottom: 20px;
`;

