import gql from 'graphql-tag';

export const listFA_CustomerResellerPricesProduct = gql`
query listFA_CustomerResellerPricesProduct($id: ID!) {
  fakturaAssist {
    listFA_CustomerResellerPricesProduct(id: $id) {
      id
      price
      productId
      productNm
      description
      customerId
      note
      customerNm 
      actualName
    }
  }
}`;

export const updateFA_CustomerResellerPrices = gql`
mutation updateFA_CustomerResellerPrices($id: ID!, $fields: CustomerResellerPricesFields!) {
  fakturaAssist {
    updateFA_CustomerResellerPrices(id: $id, fields: $fields){
      id
      price
      productId
      productNm
      description
      customerId
      note
      customerNm 
      actualName
    }
  }
}`;

export const createFA_CustomerResellerPrices = gql`
mutation createFA_CustomerResellerPricesForProduct($fields: CustomerResellerPricesFields) {
  fakturaAssist {
    createFA_CustomerResellerPricesForProduct(fields: $fields){
      id
      price
      productId
      productNm
      description
      customerId
      note
      customerNm 
      actualName
    }
  }
}`;

export const deleteFA_CustomerResellerPrices = gql `
mutation deleteFA_CustomerResellerPrices($id: ID!) {
  fakturaAssist {
    deleteFA_CustomerResellerPrices(id: $id){
      id
    }
  }
}`;

export const listFA_CustomerResellerPricesConnection = gql`
query listFA_CustomerResellerPricesConnection($id: Int) {
  fakturaAssist {
    listFA_CustomerResellerPricesConnection(id: $id) {
      total
    }
  }
}
`;
