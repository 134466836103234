import React, { useEffect, useState } from 'react';

import { tableOrdersParams, tableOrdersParamsWithCustomerInfo } from '../params';
import { listFA_OrdersInCreateInvoice, listFA_OrderByCreatedInvoiceDeleted } from '../../qql/query';
import TableGrid from 'components/tableGrid/TableGrid';

const TableOrders = ({ t, linkId, changeChooseOrder, getFirstValueOfMount, isDelete, sammelfakturaAufKunde }) =>{
  const height = window.innerHeight / 2 - 140;
  const [localLinkId, setLocalLinkId] = useState(linkId);

  useEffect(() => {
    setLocalLinkId(linkId);
  }, [linkId]);

  return (
    <TableGrid
      height={300 || height}
      disableHeightChange
      tableData={sammelfakturaAufKunde ? tableOrdersParamsWithCustomerInfo(t) : tableOrdersParams(t) }
      getTableIndex={((_, data)=> {
        changeChooseOrder(data);
      })}
      graphqlParams={{
        query: isDelete ? listFA_OrderByCreatedInvoiceDeleted : listFA_OrdersInCreateInvoice,
        variables: { args: { invoiceID: linkId } }, skip: !linkId,
        fetchPolicy: localLinkId === linkId ? 'cache-first' : 'cache-and-network',
      }}
      getFirstValueOfMount={getFirstValueOfMount}
      startedIndex={0}
      withTotal
      t={t}
    />
  );
};

export default TableOrders;

