import gql from 'graphql-tag';

export default gql`
mutation updateFA_CustomerCRMNotes($id: ID!, $fields: CRMFields!){
    fakturaAssist{
    updateFA_CustomerCRMNotes(id: $id, fields: $fields){
		id
		addedOn
		
    }
    
  }
}`;
