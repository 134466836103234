export const years = (t) => [
  {
    id: 2019,
    name: '2019',
  },
  {
    id: 2018,
    name: '2018',
  },
  {
    id: 2017,
    name: '2017',
  },
  {
    id: 2016,
    name: '2016',
  },
  {
    id: 2015,
    name: '2015',
  },
];
export const periods = (t) => [
  {
    id: 1,
    name: t('common.periods.firstHalf'),
  },
  {
    id: 2,
    name: t('common.periods.secondHalf'),
  },
];

export const mounths = (t) =>[
  {
    id: 1,
    name: t('common.months.jan'),
  },
  {
    id: 2,
    name: t('common.months.feb'),
  },
  {
    id: 3,
    name: t('common.months.mar'),
  },
  {
    id: 4,
    name: t('common.months.apr'),
  },
  {
    id: 5,
    name: t('common.months.may'),
  },
  {
    id: 6,
    name: t('common.months.jun'),
  },
  {
    id: 7,
    name: t('common.months.jul'),
  },
  {
    id: 8,
    name: t('common.months.aug'),
  },
  {
    id: 9,
    name: t('common.months.sep'),
  },
  {
    id: 10,
    name: t('common.months.oct'),
  },
  {
    id: 11,
    name: t('common.months.nov'),
  },
  {
    id: 12,
    name: t('common.months.dec'),
  },

];
