import gql from 'graphql-tag';

export const listFA_ProductBreak = gql`
query listFA_ProductBreak {
  fakturaAssist {
    listFA_ProductBreak{
        id
        articleNm
        description
        concatenatedField
    }
  }
}`;
