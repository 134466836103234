import gql from 'graphql-tag';

export const listFA_ProductCashAssist = gql`
query listFA_ProductCashAssist {
  fakturaAssist {
    listFA_ProductCashAssist {
      id
      pageName
      pageIndex
      concatenatedField
      isVisible
    }
  }
}`;

export const listFA_view_fkCashAssistKonfigurationWG = gql`
query listFA_view_fkCashAssistKonfigurationWG($languageID: Int!) {
  fakturaAssist {
    listFA_view_fkCashAssistKonfigurationWG(languageID: $languageID){
      id
      languageID
      resultName
    }
  }
}`;

export const listFA_view_fkCAVisibilityStatuses = gql`
query listFA_view_fkCAVisibilityStatuses($languageID: Int!) {
  fakturaAssist{
    listFA_view_fkCAVisibilityStatuses(languageID: $languageID){
      id
      languageID
      CAVisibilityStatusName
    }
  }
}`;
