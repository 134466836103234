import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';

import SelectionRow from './SelectionRow';
import TableHeader from '../tableGrid/TableHeader';
import TableTotal from '../tableGrid/TableTotal';

const SelectionTable = (props) =>{
  const { data, tableData } = props;
  const sumRelativeWidth = 0;
  const relativeWidthCount = 0;
  const maxWidth = 1;
  const dataLength = tableData.length;
  const getColumnWidth = (width, { index }) => {
    if(tableData[index]) {
      const relativeWidth = tableData[index].relativeWidth;
      if (relativeWidth) {
        return relativeWidth * width;
      } else {
        return (
          ((maxWidth - sumRelativeWidth) / (dataLength - relativeWidthCount)) * width - 1);
      }
    }else{
      return 10;
    }
  };
  useEffect(()=> {
    console.log(document.getElementById('main_block_in_section_table'));
  },
  [document.getElementById('main_block_in_section_table')]);

  const widths = window.innerWidth;
  
  const render = data ? data.map((item, index) => <MainBlock > <SelectionRow {...props} getColumnWidth ={getColumnWidth} rowItem={item} rowIndex={index}/> </MainBlock>) : <LoadingBlock> Loading</LoadingBlock>;
  
  return (
    <Fragment>
      <TableHeader {...props} getColumnWidth={getColumnWidth} width={widths - 70}/>
      <MainBlock>
        {render}
      </MainBlock>
      <TableTotal getColumnWidth={getColumnWidth} tableData={tableData}/>
    </Fragment>
    
  );
};

/*const TableWithProductOnDate = (props) =>{
  const { data, rerenderValue, getTableRef, tableData } = props;
  const cellRenderer = ({ columnIndex, rowIndex, style, key, parent }) => {
    return (
    
      <SelectionRow
        styleProps={style}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        keyProps={key}
        {...props}
      />);
  };
  return (
    <TableGrid
      data={data}
      tableData={tableData}
      cellRenderer={cellRenderer}
      rerender = {rerenderValue}
      ref={getTableRef}
      columnCount={tableData.length}
      {...props}
    />
  );
};*/

export default SelectionTable;

const MainBlock = styled.div`
    max-height: 615px;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 300;
    color: #3c445a;
    width: 100%;
`;

const LoadingBlock = styled.div`
    min-height: 615px;
    overflow-y: auto;
    font-size: 14px;
    font-weight: 300;
    color: #3c445a;
`;


