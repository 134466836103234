import moment from 'moment';

export const CustomizeText = (col, localFormat) => {
  if(!col.customizeText) {
    switch(localFormat?.type) {
      case 'date': col.customizeText = ({ value }) => value ? moment(value).format('DD.MM.YYYY') : '-';
        break;
      case 'float': col.customizeText = ({ value }) => Number(value).toFixed(2);
        break;
      default:
    }
  }
  return col;
};
