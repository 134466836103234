import gql from 'graphql-tag';

export default gql`
query listFA_History($id: ID!, $languageId: Int, $params: ListQueryArgs){
  fakturaAssist{
    listFA_History(id: $id, languageId: $languageId, params: $params){
		id
		recordID
		newValueID
		dOperation
		operationName
		userFullName
		fieldName
		oldValue
		newValue
		content
		formTypeName
		dbFieldName
		dbFieldType
    }
  }
}`;
