import React from 'react';
import styled from 'styled-components';

import './ViewPDF.css';

import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    overflow: 'none',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    padding: '-10px 0 0 0',
    background: '#24313b',
    opacity: '0.9',
    border: '0px',
    margin: '-1px 0 0 0',
    zIndex: 107,
  },
};

const ViewPDF = (props) => {
  const { link } = props;
  const getActualPdfLink = () => {
    if(link.includes('/hstemp/')) {
      //Generate new PDF file
      return link;
    }
    else if(link.includes('wawiassist') || link.includes('upload') || link.includes('pdfs') || link.includes('files')){
      return link;
    } else{
      //PDF File from DB storage
      return `https://s3-eu-west-1.amazonaws.com/fakturaassist.terminal.printouts/${link}`;
    }
  };
  return(
    <Modal
      isOpen={!!props.link}
      onAfterOpen={props.afterOpenModal}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <Close onClick={props.cleanLink}> x </Close>
      <iframe onBlur={props.cleanLink} style={{ width: '100%', height: '100%' }} src={getActualPdfLink() + '#zoom=70'} title='pdf'/>
    </Modal>
  );
};

export default ViewPDF;

const Close = styled.div`
z-index: 11;
color: #737373f5;
position: absolute;
right: -40px;
top: -40px;
font-size: 40px;
cursor: pointer;
`;

