import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FooterIcon = ({ text, icon, onClick }) => (
  <IconBlock onClick={onClick}>
    <Icon src={icon} alt='' /> <IconText> {text}</IconText>
  </IconBlock>
);

FooterIcon.propTypes = {
  text: PropTypes.string,
};

FooterIcon.defaultProps = {
  text: 'text',
};

export default FooterIcon;

const IconBlock = styled.div`
  padding-right: 20px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const IconText = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  margin: 10px 10px 7px 10px;
  color: #ffffff;
`;
