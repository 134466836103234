import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorHandler from '../components/ErrorHandler';

import PageNotFound from '../components/PageNotFound';
import Section from '../components/section';
import sections from './sections';
import StartPage from './StartPage';
import { useTranslation } from 'react-i18next';

export default (childProps) => {
  const [t] = useTranslation();
  const getSection = sections(t).map(({ link }) => link).join('|');
  return(
    <ErrorHandler client={childProps.client} {...childProps} t={t}>
      <Switch>
        <Route exact path='/' component={StartPage}/>

        <Route path={`/:mainsection(${getSection})/:subsection?/:id?`} render={(props) =>
          <Section {...childProps} {...props} sectionInfo={sections(t)}/>
        }/>

        <Route component={PageNotFound} props={childProps} />
      </Switch>
    </ErrorHandler>);
};
