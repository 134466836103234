import gql from 'graphql-tag';

export const GET_ALL_PRODUCTS_IDS = gql`
    query{
        fakturaAssist {
            listProducts(params: {limit: 1000000000, sort: [{field: "id", order: ASC}]}) {
                productNumber
            }
        }
    } 
`;

export const CHECK_DUBLICATES = gql`
mutation checkDublicates($data: ImportInput!) {
    fakturaAssist {
        checkDublicates(data: $data)
    }
}
`;

export const GET_ALL_CUSTOMERS_IDS = gql`
query {
 fakturaAssist {
    listFA_Customer(params: {limit: 1000000000}){
        customerNr
    }
	}
  
}   
`;

export const IMPORT_TABLE = gql`
  mutation importTable($data: ImportInput!) {
    fakturaAssist {
        importTable(data: $data)
	}
  }
`;
