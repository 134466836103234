import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { sortByParamName } from '../../../helpers/sortByParamName';

const withQuery = (WrappedComponent) => (props) => {
  const {
    graphqlParams,
    setGraphQlParams,
    searchColumns,
    leftGridSort,
    dataPolicy,
    initialSortValue,
    contex,
    specialSortKey,
  } = props;
  const [data, setData] = useState(props.data || []);
  const [searchValue, setSearchValue] = useState('');
  const [sortObject, setSortObject] = useState({
    column: specialSortKey || initialSortValue,
    order: contex?.params?.order || 'ASC',
  });

  useEffect(() => {
    if (specialSortKey) {
      props.onSort(specialSortKey, null, 'ASC');
    }
  }, [JSON.stringify(contex?.filter)]);

  useEffect(() => {
    const sorted = sortByParamName(props.data, sortObject.column, sortObject.order);
    setData(sorted);
    setSearchValue('');
  }, [props.data]);

  useEffect(() => {
    setSearchValue('');
  }, [props.graphqlParams]);

  const onSort = onSortingFn(
    leftGridSort,
    dataPolicy,
    sortObject,
    setSortObject,
    graphqlParams,
    setGraphQlParams,
    props.onSort,
    data,
    setData
  );

  const onSearch = (value) => {
    if (props.onSearch) {
      props.onSearch(value);
    } else if (searchColumns) {
      const localSearch = () => {
        const newArray = [...(props.data || [])];
        const filterArray = filter(newArray, (item) => {
          for (const column of searchColumns) {
            if (item[column] && typeof item[column] === 'string') {
              if (item[column].toLowerCase().toString().indexOf(value.toLowerCase()) >= 0) {
                return true;
              }
            }
          }
        });
        setData(filterArray);
      };
      const dataBaseSearch = () => {
        setGraphQlParams({
          ...graphqlParams,
          variables: {
            ...graphqlParams.variables,
            params: {
              ...(graphqlParams.variables ? graphqlParams.variables.params : {}),
              search: {
                string: value,
                columns: searchColumns,
              },
            },
          },
        });
      };
      if (graphqlParams) {
        if (dataPolicy === 'local') {
          localSearch();
        } else if (dataPolicy === 'databaseOnly') {
          dataBaseSearch();
        } else {
          localSearch();
        }
      } else {
        localSearch();
      }
    }
  };

  return (
    <WrappedComponent
      {...props}
      raphqlParams={graphqlParams}
      data={data}
      sortObject={leftGridSort === 'local' ? sortObject : props.sortObject}
      onSort={onSort}
      onSearch={onSearch || searchColumns ? onSearch : null}
      searchValue={searchValue}
    />
  );
};
export default withQuery;
/**
 *
 * @param {'local' | undefined} leftGridSort local sorting for left grid
 * @param {'local' | 'databaseOnly'} dataPolicy by default `databaseOnly`
 * @param {object} sortObject column name and sort order
 * @param {useState} setSortObject
 * @param {object} graphqlParams
 * @param {useState} setGraphQlParams
 * @param {function} onSortFn
 * @param {array} data
 * @param {useState} setData
 * @returns return onSort function depends on `leftGridSort` and `dataPolicy`
 */
function onSortingFn(
  leftGridSort,
  dataPolicy = 'databaseOnly',
  sortObject,
  setSortObject,
  graphqlParams,
  setGraphQlParams,
  onSortFn,
  data,
  setData
) {
  const { column, order } = sortObject;
  if (leftGridSort === 'local') {
    return (columnName) => {
      const sortOrder = order.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
      const sorted = sortByParamName(data, columnName, sortOrder);
      setSortObject({ column: columnName, order: sortOrder });
      setData([...sorted]);
    };
  }
  if (typeof onSortFn === 'function') {
    return onSortFn;
  } else {
    return (columnName) => {
      if (column === columnName) {
        if (order === 'ASC') {
          setSortObject({ column: columnName, order: 'DESC' });
          queryParams(graphqlParams, setGraphQlParams, columnName, 'DESC');
        } else {
          setSortObject({ column: null, order: null });
          queryParams(graphqlParams, setGraphQlParams, columnName, null);
        }
      } else {
        setSortObject({ column: columnName, order: 'ASC' });
        queryParams(graphqlParams, setGraphQlParams, columnName, 'ASC');
      }
    };
  }
}
function queryParams(graphqlParams, setGraphQlParams, columnName, order) {
  setGraphQlParams({
    ...graphqlParams,
    variables: {
      ...graphqlParams.variables,
      params: {
        ...(graphqlParams.variables ? graphqlParams.variables.params : {}),
        sort:
          order === 'DESC'
            ? {
                field: columnName,
                order: order.toUpperCase(),
              }
            : null,
      },
    },
  });
}
