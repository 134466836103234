import gql from 'graphql-tag';

export default gql`
query listFA_OrderTypesView{
    fakturaAssist{
    	listFA_OrderTypesView{
    		id
	        orderTypeName
 		}
 	}
}`;
