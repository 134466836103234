import React from 'react';
import styled from 'styled-components';

import { Button, Dropdown } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';

import { timePeriodTableParams, customersTableParams, articlesTableParams, orderTypeTableParams } from './params';
import { useTranslation } from 'react-i18next';

const Settings = (props) => {
  const [t] = useTranslation();
  return (
    
    <MainBlock>
      <div>
        <Dropdown title={t('statistic.groupBy')} data={[]}/>
        <Dropdown title={t('common.profile')} data={[]}/>
        <ButtonBlock>
          <Button
            value={t('common.calculate')}
            className='black'
            width='180px'
            height='42px'
            upperCase
          />
        </ButtonBlock>
      </div>
      <TableGridBlock>
        <TableGrid
          tableData={timePeriodTableParams(t)}
          data={[{ time: '22.05.11' }, { time: '23.05.11' }]}
          height='200'
          disableHeightChange
        />
      </TableGridBlock>
      <TableGridBlock>
        <TableGrid
          tableData={customersTableParams(t)}
          data={[{ time: '22.05.11' }, { time: '23.05.11' }]}
          height='200'
          disableHeightChange
        />
      </TableGridBlock>
      <TableGridBlock>
        <TableGrid
          tableData={articlesTableParams(t)}
          data={[{ time: '22.05.11' }, { time: '23.05.11' }]}
          height='200'
          disableHeightChange
        />
      </TableGridBlock>
      <TableGridBlock>
        <TableGrid
          tableData={orderTypeTableParams(t)}
          data={[{ time: '22.05.11' }, { time: '23.05.11' }]}
          height='200'
          disableHeightChange
        />
      </TableGridBlock>
    </MainBlock>

  );
};

export default Settings;

const MainBlock = styled.div`
   display: flex;
   justify-content: space-between;
`;

const TableGridBlock = styled.div`
    width: 250px;
`;

const ButtonBlock = styled.div`
    margin: 100px 0 20px 0;
`;

