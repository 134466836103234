export const tableData = (t) => ([
  {
    caption: t('common.date'),
    dataField: 'date',
    localFormat: { type: 'date' },
  },
  {
    caption: t('common.customerNo'),
    dataField: 'clientInfo.customerNr',
  },
  {
    caption: t('common.name'),
    dataField: 'nameRes',
  },
]);
