import React from 'react';
import styled from 'styled-components';
import Freezer from './Freezer';

export default (props) => {
  return (
    <HeadBlock>
      <MainBlock>
        <Freezer date={props.values.fromDate}/>
      </MainBlock>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  height: 800px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
`;
