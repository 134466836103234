
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import TableGrid from '../../../components/tableGrid/TableGrid.js';
import { TextAreaStandart } from '../../../components/elements';

import { tableDataCRM } from './params';
import { Col } from 'style';

import deleteFA_CustomerCRMNotes from './qql/mutation/deleteFA_CustomerCRMNotes';
import createFA_CustomerCRMNotes from './qql/mutation/createFA_CustomerCRMNotes';
import updateFA_CustomerCRMNotes from './qql/mutation/updateFA_CustomerCRMNotes';

import listFA_CustomerCRMNotes from './qql/query/listFA_CustomerCRMNotes';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const Component = (props)=>{
    return(
      <div>
        <TextAreaStandart
          handleBlur={props.handleBlur}
          withoutFormik={true}
          type='text'
          onChange={props.handleChange}
          value={props.values.note}
          name={'note'}
          title={t('common.note')}
          width='100%'
          isRefetchAfterMutation
          setFieldTouched={props.setFieldTouched}
          touched={props.touched}
          err={props.errors}
        /></div>
    );
  };
  return (
    <HeadBlock>
      <MainBlock>
        <TableGrid
          graphqlParams={{
            query: listFA_CustomerCRMNotes,
            variables: { id: +props.linkId },
            fetchPolicy: 'network-only',
          }}
          //searchValue={this.state.searchValue}
          //onSearch={this.onSearch}
          //getSortField={this.getSortField}ы
          Component={Component}
          isRefetchAfterMutation
          height={700}
          headerText={'CRM'}
          modalWidth={440}
          modalHeight={295}
          //editItem={this.openModal}
          tableData={tableDataCRM(t)}
          //getTableIndex={this.indexOnClickTable}
          itemDeleted={deleteFA_CustomerCRMNotes}
          defaultValuesForCache={['addedOn']}
          defaultValuesForMutation = {{ customerLink: +props.linkId, addedOn: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss') }}
          typeName={'FA_CustomerCRMNotes'}
          qqlMutationCreate={createFA_CustomerCRMNotes}
          qqlMutationUpdate={updateFA_CustomerCRMNotes}
          withModalMutations
          dataPolicy='local'
          initialSortValue='id'
        />
      </MainBlock>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;

const MainBlock = styled(Col)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
`;

