import gql from 'graphql-tag';

export default gql`
mutation updateFA_Config($fields: FA_ConfigFields!){
    fakturaAssist{
    updateFA_Config(fields: $fields){
    	id
    }

  }
}`;