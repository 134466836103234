import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'style';

import { Button, Calendar, OptionsButton } from 'components/elements';
import { Dots } from 'components/Loading';

const Selects = ({ offerID, accepted, declined, callOfferActions, lastValidDate, offerForDate, t, setOfferForDate, setLastValidDate, openDublicateModal, createNewOffer, editFA_Offer, editOfferLoading }) => {
  const renderButton = () => {
    if (offerID) {
      return (
        <AdditionButtonBlock>
          <Row>
            <Col lg={2}>
              <NumberBlock> Nr. {offerID}</NumberBlock>
            </Col>
            <Col lg={2}>
              <CalendarBlock>
                <Calendar
                  text='Date'
                  value={offerForDate}
                  title={t('common.date')}
                  withoutFormik
                  onChange={(d) => setOfferForDate(d)}
                  noActive={(accepted || declined)}
                />
              </CalendarBlock>
            </Col>
            <Col lg={2}>
              <CalendarBlock>
                <Calendar
                  value={lastValidDate}
                  text='Valid until'
                  title={t('offer.validUntil')}
                  withoutFormik
                  onChange={(d) => setLastValidDate(d)}
                  noActive={(accepted || declined)}
                />
              </CalendarBlock>
            </Col>
          </Row>

          <Row justifyContent='flex-end'>
            <ButtonBlock lg={5}>
              <Button
                upperCase
                value={t('offer.offerDeclined')}
                className={declined ? 'no-active-btn' : 'red-btn'}
                onClick={() => {
                  callOfferActions('decline');
                }}
                disabled={(declined)}
              />
            </ButtonBlock>
            <ButtonBlock lg={5}>
              <Button
                upperCase
                value={t('offer.offerAccepted')}
                className={declined || accepted ? 'no-active-btn' : null}
                onClick={() => {
                  callOfferActions('accept');
                }}
                disabled={(accepted || declined)}
              />
            </ButtonBlock>
            <ButtonBlock lg={5}>
              <Button
                upperCase
                value={t('offer.offerEdit')}
                className={editOfferLoading ? 'no-active-btn' : 'green-btn'}
                onClick={editFA_Offer}
                disabled={(accepted || declined)}
              >
                {editOfferLoading && <Dots margin='12px 0 0 0' />}
              </Button>
            </ButtonBlock>
            {false && (
              <ButtonBlock lg={5}>
                <Button upperCase value={t('offer.offerDublicate')} className='transparent' onClick={openDublicateModal} />
              </ButtonBlock>
            )}
          </Row>
        </AdditionButtonBlock>
      );
    } else {
      return (
        <AdditionButtonBlock>
          <Row justifyContent='flex-end'>
            <Col lg={5}>
              <ButtonBlock>
                <OptionsButton
                  value={t('offer.offerСreate')}
                  className='green-btn'
                  onClick={createNewOffer}
                  automaticPrintOption
                  upperCase
                />
              </ButtonBlock>
            </Col>
          </Row>
        </AdditionButtonBlock>
      );
    }
  };
  return renderButton();
};
export default Selects;

const ButtonBlock = styled(Col)`
  margin: 30px 0 0 20px;
`;

const CalendarBlock = styled.div`
  margin: 0 0 0 20px;
`;

const NumberBlock = styled.div`
  margin: 30px 0 0 20px;
  font-size: 30px;
`;

const AdditionButtonBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;
