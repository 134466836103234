import gql from 'graphql-tag';

export default gql`
query listFA_ProductionEdit($params: ListQueryArgs, $filter: [FA_GeneralFilter]){
  fakturaAssist{
    listFA_ProductionEdit(params: $params, filter: $filter){
      id
      name
    }
  }
}`;
