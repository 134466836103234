import { useState, useEffect } from 'react';
import { readQuery } from 'helpers/queryOperations';
import { useQuery } from '@apollo/react-hooks';

import { getFA_CustomerDeliveryPrices } from '../qql/query';

const useDelivery = (props) => {
  const [isDelivery, setDelivery] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState({ mass: 0, total: 0 });
  const { queryProductsParams } = props;

  const deliveryPricesQuery = useQuery(getFA_CustomerDeliveryPrices, {
    variables: { args: { customerID: props.linkId } },
    skip: !props.linkId,
  });

  useEffect(()=>setDelivery(false), [props.linkId]);

  const calculateDeliveryInfo = () => {
    try{
      const cacheData = readQuery(queryProductsParams);
      const totalMassInGramm = cacheData.productTable.reduce((acc, { weight, quantity })=> weight ? acc += weight * quantity : acc, 0);
      let actualPrice = 0;
      const actualDeliveryPrices = deliveryPricesQuery.data.fakturaAssist.getFA_CustomerDeliveryPrices;
      for (const el of actualDeliveryPrices) {
        if(el.weight >= totalMassInGramm) {
          actualPrice = el.weightPrice;
          break;
        }
      }
      setDeliveryInfo({ total: actualPrice, weight: totalMassInGramm / 1000 });
    }catch(err) {
      //console.log(err);
    }
  };
  const onDeliveryClick = async () => {
    setDelivery(!isDelivery);
    calculateDeliveryInfo();
  };

  return { isDelivery, setDelivery, deliveryInfo, calculateDeliveryInfo, onDeliveryClick };
};

export default useDelivery;

