import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/elements';

import { mounths } from './CONST';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const { monthsArray, setMonthsArray } = props;
  const setAllMounths = () => {
    if(monthsArray.length === 12) {
      setMonthsArray([]);
    }else{
      setMonthsArray([...mounths(t).map((e) => e.name)]);
    }
  };
  const mounthsCheckbox = mounths(t).map(({ id, name }, index) => {
    return (<MonthCheckboxBlock width={'80px'} key={index}>
      <Checkbox
        value = {monthsArray.includes(name)}
        title={name}
        onClick={() => {
          const arrCopy = monthsArray;
          if(arrCopy.includes(name)) {
            arrCopy.splice(arrCopy.indexOf(name), 1);
            console.log(arrCopy);
          }else{
            arrCopy.push(name);
          }
          setMonthsArray([...arrCopy]);
        } }
        margin={'10px 0 0 0 '}
      />
    </MonthCheckboxBlock>);
  });
  return(
    <>
     
      <MainCheckboxBlock>
        <AllMonthCheckboxBlock><Checkbox value = {monthsArray.length === 12} title='All mounts' onClick={setAllMounths}/></AllMonthCheckboxBlock>
        {mounthsCheckbox}
      </MainCheckboxBlock>
    </>
  );
};

const MonthCheckboxBlock = styled.div`
    width: 140px;
    display: inline-block;
`;

const AllMonthCheckboxBlock = styled.div`
    width: 140px;
    margin: 0 0 0 15px;
`;

const MainCheckboxBlock = styled.div`
    padding: 0 20px 80px 0;
`;
