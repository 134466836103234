import React, { useState } from 'react';

import Items from 'components/List/Items';
import Addition from 'components/List/Additional';
import { Modal } from 'components/elements';

import { Main } from './pages';
 
import { itemsData } from './params';
import { SpecialDateModal } from './modals';

export default (props) => {
  const { t } = props;
  const [isOpenSpecialDateModal, setSpecialDateModal] = useState(false);
  return (
    <>
      <Modal isOpen={isOpenSpecialDateModal} closeModal = {() => setSpecialDateModal(false)} Component = { <SpecialDateModal setSpecialDateModal ={ setSpecialDateModal} t={t} /> } width='510px' height='423px' headerText='Edit Period'/>
      <Items tableData={itemsData(t)} name={t('options.automation.automation')}/>
      <Addition
        text = {t('options.automation.printOutOfBakingSlip') }
        menuObj={[{ link: 'order/edit', name: t('options.automation.automation') }]}
        noShowText={t('options.automation.noOrders') }

      >
        <Main {...props} setSpecialDateModal={setSpecialDateModal}/>
      </Addition>
    </>
  );
};
