import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { Input, Checkbox, Transition } from 'components/elements';
import Formik from 'components/formik';

export default (props) => {
  const { t } = props;
  return (
    <Formik initialValues={props.data} enableReinitialize={true} onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo} >
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <HeadBlock>
            <Transition index={0} text={'Discount'} isOpen>
              <Row>
                <Col lg={4}>
                  <Input value={values.discountInPercent} field='discountInPercent' title={t('discountInPercent')} type='float'/>
                </Col>
                <Col lg={4}>
                  <Input value={values.bonusInPercent} field='bonusInPercent' title={t('bonusInPercent')} type='float'/>
                </Col>
              </Row>
            </Transition>

            <Transition index={0} text={'Cash discount'} isOpen>
              <Row>
                <Col lg={2}>
                  <Input value={values.accountDiscountInPercent} field='accountDiscountInPercent' title={t('accountDiscountInPercent')} type='float' small/>
                </Col>
                <Col lg={2}>
                  <Input value={values.accountDiscountDays} field='accountDiscountDays' title={t('accountDiscountDays')} type='number' small/>
                </Col>
              </Row>
            </Transition>

            <Transition index={0} text={'Delivery periods'} isOpen>
              <Row>
                <Col lg={4}>
                  <Input value={values.ourCustomerNo} field='ourCustomerNo' title={t('ourCustomerNo')}/>
                </Col>
                <Col lg={2}>
                  <Input value={values.deliveryTimeFrom} field='deliveryTimeFrom' title={t('deliveryTimeFrom')} small/>
                </Col>
                <Col lg={2}>
                  <Input value={values.deliveryTimeTo} field='deliveryTimeTo' title={t('deliveryTimeTo')} small/>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Row><Col lg = {2}>
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isMonday}
                      field='isMonday'
                      title={t('common.days.monday')}
                    />
                  </Col>
                  <Col lg = {2}>
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isWednesday}
                      field='isWednesday'
                      title={t('common.days.wednesday')}
                    />
                  </Col>
                  <Col lg = {2}>
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isFriday}
                      field='isFriday'
                      title={t('common.days.friday')}
                    />
                  </Col>
                  <Col lg = {2}>
                    <Checkbox
                      margin='24px 0 0 0'
                      value={values.isSunday}
                      field='isSunday'
                      title={t('common.days.sunday')}
                    />
                  </Col>
                  </Row>
                  <Row>
                    <Col lg = {2}>
                      <Checkbox
                        margin='24px 0 0 0'
                        value={values.isTuesday}
                        field='isTuesday'
                        title={t('common.days.tuesday')}
                      />
                    </Col>
                    <Col lg = {2}>
                      <Checkbox
                        margin='24px 0 0 0'
                        value={values.isThursday}
                        field='isThursday'
                        title={t('common.days.thursday')}
                      />
                    </Col>
                    <Col lg = {2}>
                      <Checkbox
                        margin='24px 0 0 0'
                        value={values.isSaturday}
                        field='isSaturday'
                        title={t('common.days.saturday')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Transition>
        
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;