export const tableData = ({ deliveryInfo, t, totalParams }) => ([
  {
    name: t('common.articleNo'),
    // dataBase: 'productNr',
    relativeWidth: 0.16,
    disableSort: true,
  },

  {
    name: t('common.quantity'),
    dataBase: 'quantity',
    total: totalParams ? totalParams.quantityCount : 0,
    underLine: 1,
    relativeWidth: 0.10,
    headerTextAlign: 'right',
    disableSort: true,
  },

  {
    name: t('common.description'),
    // dataBase: 'productName',
    relativeWidth: 0.20,
    underLine: `${t('common.deliveryCost')} ${deliveryInfo && deliveryInfo.weight} kg`,
    disableSort: true,
  },

  {
    name: t('common.addition'),
    // dataBase: 'total',
    relativeWidth: 0.07,
    disableSort: true,
  },

  {
    name: t('common.price'),
    // dataBase: 'price',
    underLine: deliveryInfo && deliveryInfo.total,
    relativeWidth: 0.10,
    headerTextAlign: 'right',
    disableSort: true,
  },

  {
    name: t('common.total'),
    dataBase: 'total',
    // total: totalParams ? totalParams.total : '0',
    underLine: deliveryInfo && deliveryInfo.total,
    relativeWidth: 0.09,
    headerTextAlign: 'right',
    disableSort: true,

  },

  {
    name: t('common.discount'),
    dataBase: 'discount',
    relativeWidth: 0.12,
    headerTextAlign: 'right',
    disableSort: true,
    withoutTotal: true,
  },

  {
    name: t('common.vat'),
    // dataBase: 'discountSpec',
    relativeWidth: 0.12,
    disableSort: true,
  },
  {
    relativeWidth: 0.04,
    disableSort: true,
  },
]);
