import gql from 'graphql-tag';

export default gql`
 query listFA_OverviewDough($args: FA_OverviewOrdersInputs!){
    fakturaAssist {
    listFA_OverviewDough(args: $args) {
        id
        productNumber
        customerNr
        amount
        description
        name
        deliveryNote
        productionList
        date
        deliveryNr
        total
        totalNetto
        price
        discountPercent
        dough
    }
    }
 }
`;
