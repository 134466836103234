import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'style';
import moment from 'moment';

import { tableData } from './tableData';

import DateRange from 'components/elements/dateRange/DateRange';
import { Dropdown } from 'components/elements';
import Query from 'components/graphql/Query';
import { Formik } from 'formik';
import DXTableGrid from 'components/dxTableGrid';

import { getFA_OrderGroupFromConfig, listFA_OverviewOrders } from '../../qql/query';

export default ({ t }) =>(
  <Formik initialValues={{ date: moment(), secondDate: moment(new Date()).add(1, 'days'), group: 0 }}>
    {({ handleSubmit, handleChange, values, setFieldValue }) =>{
      return (
        <div>
          <ArgsBlock>
            <Row>
              <DateRange startField='date' endField='secondDate' values={values} t={t} withoutFormik onChange={setFieldValue}/>
              <Col lg={4}>
                <Query
                  query={getFA_OrderGroupFromConfig}
                >
                  {({ data }) => {
                    const dataForDropdown = data.orderGroup ? data.orderGroup.filter((item) => item).map((item, index) => {
                      return { id: index + 1, name: item };
                    }) : [];
                    return (
                      <Dropdown
                        data={dataForDropdown}
                        onChange={({ value })=>setFieldValue('group', value)}
                        title={t('common.groupDeliveryNote')}
                        defaultValueField={values.group}
                        labelField = 'name'
                        valueField = 'id'
                        field='group'
                      />

                    );
                  }}
                </Query>

              </Col>

            </Row>
          </ArgsBlock>
          <Row>
            <TableBlock>

              <Query
                query={listFA_OverviewOrders}
                variables={{ args: { date: values.date, secondDate: values.secondDate, group: values.group } }}
              >
                {({ data, loading, fetchMore }) => {
                  console.log(data.items);
                  return (
                    <>
                      <DXTableGrid
                        data={data.items}
                        loading={loading}
                        columns={tableData({ t })}
                        withoutQuery
                        height={508}
                        manualGroup/>
                    </>
                  );
                }}
              </Query>
            </TableBlock>
          </Row>
        </div>);
    }}
  </Formik>
);

const ArgsBlock = styled.div`
  background: #f4f4f4;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  padding: 0 20px;
`;

const TableBlock = styled.div`
  margin: 20px 0px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  width: 100%;
`;
