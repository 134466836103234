import gql from 'graphql-tag';

export default gql`
query listFA_CustomerTourplan{
  fakturaAssist{
    listFA_CustomerTourplan{
      id
      name
    }
  }
}`;
