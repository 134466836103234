import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Text, Toggle, DateRangeInModal } from 'components/elements';
import { Row, Col } from 'style';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import { get } from 'lodash';

import changeStatusBakery2bProduction from '../qql/changeStatusBakery2bProduction.js';
import changeStatusCAdirectProduction from '../qql/changeStatusCAdirectProduction.js';

import getCountOrders from '../qql/getCountOrders.js';
import getCountOrderForPre_Production from '../qql/getCountOrderForPre_Production.js';
import getStatusBakery2b from '../qql/getStatusBakery2b.js';
import getStatusCAdirect from '../qql/getStatusCAdirect.js';
import { useTranslation } from 'react-i18next';
import { CONFIG } from './qql/configValues.js';

import { changeDatesSHProductions } from 'config/localStorage';

export default (props) => {
  const [t] = useTranslation();
  const { values, setFieldValue } = props;
  const сountOrders = useQuery(getCountOrders, { variables: { fromDate: values.fromDate, toDate: (values.toDate === null || values.toDate === 'null') ? values.fromDate : values.toDate }, onCompleted: (data) => {
    setFieldValue('order', get(data, 'fakturaAssist.getCountOrders.RecordCount'));
  } });
  const сountOrdersForPre = useQuery(getCountOrderForPre_Production, { variables: { onDate: values.fromDate }, onCompleted: (data)=>{
    setFieldValue('preOrder', get(data, 'fakturaAssist.getCountOrderForPre_Production.count'));
  } });
  const getStatusBakery2b_Query = useQuery(getStatusBakery2b, { variables: { onDate: values.fromDate } });
  const getStatusCAdirect_Query = useQuery(getStatusCAdirect, { variables: { onDate: values.fromDate } });
  const getConfigValues_Query = useQuery(CONFIG);
  const [Mutation_changeStatusBakery2b, Mutation_Info_changeStatusBakery2b] = useMutation(changeStatusBakery2bProduction);
  const [Mutation_changeStatusCAdirectProduction, Mutation_Info_changeStatusCAdirectProduction] = useMutation(changeStatusCAdirectProduction);
  let statusBakery2b = false;
  let statusCAdirect = false;
  if(!getStatusBakery2b_Query.loading) {
    statusBakery2b = getStatusBakery2b_Query.data.fakturaAssist.getStatusBakery2b.status;
  }
  if(!getStatusCAdirect_Query.loading) {
    statusCAdirect = getStatusCAdirect_Query.data.fakturaAssist.getStatusCAdirect.status;
  }
  let isForbidCA = false;
  let isShopOnline = false;
  if(!getConfigValues_Query.loading) {
    isForbidCA = getConfigValues_Query.data.fakturaAssist.getFA_Config.isForbidCA;
    isShopOnline = getConfigValues_Query.data.fakturaAssist.getFA_Config.isShopOnline;
  }
  const changeStatusBakery2b = () =>{
    Mutation_changeStatusBakery2b(
      {
        variables:
                    {
                      onDate: values.fromDate,
                      status: statusBakery2b,
                    },
      }
    ).then((db)=>{
      getStatusBakery2b_Query.client.writeQuery({
        query: getStatusBakery2b,
        variables: { onDate: values.fromDate },
        data: {
          fakturaAssist: {
            getStatusBakery2b: { status: !statusBakery2b, __typename: 'StatusBakery2b' },
            __typename: 'FA_Query',
          },
        },
      });
    }).catch((err)=>console.log(err));
  };

  const changeStatusCAdirect = () =>{
    Mutation_changeStatusCAdirectProduction(
      {
        variables:
                    {
                      onDate: values.fromDate,
                      status: statusCAdirect,
                    },
      }
    ).then((db)=>{
      getStatusBakery2b_Query.client.writeQuery({
        query: getStatusCAdirect,
        variables: { onDate: values.fromDate },
        data: {
          fakturaAssist: {
            getStatusCAdirect: { status: !statusCAdirect, __typename: 'StatusCAdirect' },
            __typename: 'FA_Query',
          },
        },
      });
    }).catch((err)=>console.log(err));
  };

  useEffect(() => {
    if(values.fromDate !== values.toDate) {
      setFieldValue('preOrder', 0);
    }
  }, [сountOrdersForPre?.data, values.fromDate, values.toDate]);

  return(
    <HeadBlock>
      <MainBlock>
        <Row>
          <Col width='370px'>
            <Row>
              <BlockOfText bottom='5px'>
                <Text fontSize='14px' text={t('common.selectionOfTimePeriod')}/>
              </BlockOfText>
            </Row>
            <Row>
              <Col>
                <DateRangeInModal
                  firstDate={values.fromDate}
                  secondDate={(values.toDate === null || values.toDate === 'null') ? values.fromDate : values.toDate}
                  width='210px'
                  withoutTitle
                  onAccept={(fromDate, toDate) => {
                    setFieldValue('fromDate', fromDate);
                    setFieldValue('toDate', toDate || fromDate);
                    changeDatesSHProductions('fromDateSHProductions', fromDate);
                    changeDatesSHProductions('toDateSHProductions', toDate || fromDate);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col width='400px'>
            <Row>
              <BlockOfText top='25px'>
                <Text fontSize='14px' text={`${t('common.orders')}: ${сountOrders.loading ? ' ' : get(сountOrders, 'data.fakturaAssist.getCountOrders.RecordCount', '')}`}/>
                {сountOrders.loading ? <i style={{ marginLeft: '5px' }} className='fas fa-spinner fa-pulse' /> : null}
              </BlockOfText>
            </Row>
            <Row>
              {values.fromDate === values.toDate &&
                <BlockOfText>
                  <Text fontSize='14px' text={`${t('production.ordersForPreProduction')}: ${сountOrdersForPre.loading ? ' ' : get(сountOrdersForPre, 'data.fakturaAssist.getCountOrderForPre_Production.count', '')}`}/>
                  {сountOrdersForPre.loading ? <i style={{ marginLeft: '5px' }} className='fas fa-spinner fa-pulse' /> : null}
                </BlockOfText>
              }
            </Row>
          </Col>
          {isShopOnline &&
            <Col width='290px'>
              <Row>
                <BlockOfText bottom='5px'>
                  <Text fontSize='14px' text={t('production.ordersThroughBakery')}/>
                </BlockOfText>
              </Row>
              <Row>
                <BlockOfText disabled={values.fromDate !== values.toDate}>
                  {getStatusBakery2b_Query.loading || Mutation_Info_changeStatusBakery2b.loading ?
                    <i className='fas fa-spinner fa-pulse fa-2x' /> :
                    <Toggle
                      withoutFormik
                      onClick={changeStatusBakery2b}
                      value={statusBakery2b}
                      textOn={t('common.allowed')}
                      textOff={t('common.closed')}
                    />
                  }
                  {values.fromDate !== values.toDate && <small style={{ color: 'red' }}>{t('common.validation.selectSingleDay')}</small>}
                </BlockOfText>
              </Row>
            </Col>
          }
          {isForbidCA &&
            <Col width='380px'>
              <Row>
                <BlockOfText bottom='5px'>
                  <Text fontSize='14px' text={t('production.ordersViaCashAssist')}/>
                </BlockOfText>
              </Row>
              <Row>
                <BlockOfText disabled={values.fromDate !== values.toDate}>
                  {getStatusCAdirect_Query.loading || Mutation_Info_changeStatusCAdirectProduction.loading ?
                    <i className='fas fa-spinner fa-pulse fa-2x' /> :
                    <Toggle
                      withoutFormik
                      onClick={changeStatusCAdirect}
                      value={statusCAdirect}
                      textOn={t('common.allowed')}
                      textOff={t('common.closed')}
                    />
                  }
                  {values.fromDate !== values.toDate && <small style={{ color: 'red' }}>{t('common.validation.selectSingleDay')}</small>}
                </BlockOfText>
              </Row>
            </Col>
          }
        </Row>
      </MainBlock>
    </HeadBlock>);
};

const HeadBlock = styled.div`
  height: 100px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
const BlockOfText = styled.div`
  padding-top: ${(props) => props.top ? props.top : '5px'};
  padding-bottom: ${(props) => props.bottom ? props.bottom : '0'};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  opacity: ${(props) => props.disabled ? '0.5' : '1'};
`;
