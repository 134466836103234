import Automation from './Automation';
import { getFA_Config } from '../qql/query';
import { updateFA_Config } from '../qql/mutation';

export default () => ({
  link: 'automation',
  subsections: [],
  itemQuery: getFA_Config,
  updateMutation: updateFA_Config,
  Component: Automation,
});

