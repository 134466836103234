import gql from 'graphql-tag';

export default gql`
query listFA_TaxOnDate($args: FA_TaxOnDateInputs!) {
    fakturaAssist{
    	listFA_TaxOnDate(args: $args){
    		id
            taxNormal
            taxReduced
            taxSpecial0
            taxSpecial1
            taxSpecial2

 		}
 	}
}`;
