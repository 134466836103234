import React, { useState, useEffect } from 'react';
import { ErrorModal } from '../elements';
import styled from 'styled-components';
import { useMutation } from 'components/graphql';

import Success from '../../style/check.svg';
import Loading from '../../style/loading.svg';
import ErrorIndicator from '../../style/attention.svg';
import NoNetworkIndicator from '../../style/nonetwork.svg';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('status-root');

const MutationStatusPortal = ({ children }) => {
  const el = document.createElement('div');

  useEffect(
    () => {
      modalRoot.appendChild(el);
      return () => {
        modalRoot.removeChild(el);
      };
    },
    [el]
  );
  return ReactDOM.createPortal(children, el);
};

export const MutationStatus = ({ loading, error, networkError }) =>{
  if(loading) {
    return <MutationStatusPortal><MutationStatusBox src={Loading || Success} background='#ffffff' /></MutationStatusPortal>;
  }
  if(networkError) {
    return <MutationStatusPortal><MutationStatusBox src={NoNetworkIndicator} background='#c13c44' /></MutationStatusPortal>;
  }
  if(error) {
    return <MutationStatusPortal><MutationStatusBox src={ErrorIndicator} background='#c13c44' /></MutationStatusPortal>;
  } else{
    return <MutationStatusPortal><MutationStatusBox loading={loading} src={Success} background='#ffffff' /></MutationStatusPortal>;
  }
};

const MutationHook = (props) => {
  const [status, changeStatus] = useState(false);
  const [mutation, { result, error, client, loading }] = useMutation(props.mutation, {
    ...props,
  });
  setTimeout(() => changeStatus(false), 3000);
  return(
    <React.Fragment>
      {loading && <MutationStatus loading={loading}/>} {error && <MutationStatusPortal><MutationStatus error={error}/></MutationStatusPortal>}
      <ErrorModal
        errorText = {error && error.toString()}
        isOpen={Boolean(error)}/>
      {props.children({
        loading: loading,
        mutation: mutation,
        result: result,
        status: status,
        client: client })}
    </React.Fragment>
  );
};

// const MutationHOC = (props) =>{
//   const [status, changeStatus] = useState(false);
//   return (
//     <Mutation
//       {...props}
//     >
//       {(mutation, result)=>{
//         console.log(mutation);
//         console.log(props);
//          const { error, loading } = result;
//          if(loading) {
//           changeStatus(true);
//            setTimeout(() => changeStatus(false), 3000);
//       }
//     return (
//           <div>
//             {status && <MutationStatus {...result}/>}
//             <ErrorModal errorText = {error && error.toString()} isOpen={Boolean(error)}/>
//             {props.children(mutation, result, status)} </div>
//         );
//       }}
//     </Mutation>);
// };

const MutationStatusBox = styled.div`
	  width: 70px;
    height: 70px;
    background: #ffffff;
    position: absolute;
    z-index: 99;
    opacity: 0.6;
    right: 5%;
    bottom: 5%;
    border-radius: 50%;
    background: url(${(props) => props.src}) no-repeat;
    background-size: 30px;
    background-color: ${(props) => props.background};
    background-position: center;
`;

// export default MutationHOC;
export default MutationHook;
