import React from 'react';
import styled, { keyframes } from 'styled-components';
import sandTime from 'style/img/sandTime.png';

const OrderCreatingModal = ({ closeModal, t }) => (
  <ModalBody>
    <Rotate src={sandTime} />
    <ModalText> {t('order.modals.orderCreatingPleaseWait')} </ModalText>
  </ModalBody>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

// Here we create a component that will rotate everything we pass in over two seconds
const Rotate = styled.img`
  display: inline-block;
  animation: ${rotate} 3s linear infinite;
  padding: 2rem 1rem;
  font-size: 1.2rem;
  height: 110px !important;
  display: block;
  margin: 0 auto;
`;

const ModalText = styled.p`
  font-size: 16px;
  text-align: center;
`;
const ModalBody = styled.div`
  padding-top: 20px;
  text-align: center;
  font-size: 15px;
`;

export default OrderCreatingModal;
