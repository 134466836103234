import gql from 'graphql-tag';

export default gql`
query listFA_OrderByCreatedInvoiceDeleted($args: FA_OrdersInCreateInvoiceInputs!){
    fakturaAssist{
    	listFA_OrderByCreatedInvoiceDeleted(args: $args){
    		id
			date
			customerNr
			name
			orderNr
			total
 		}
 	}
}`;
