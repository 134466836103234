
import styled from 'styled-components';
import React from 'react';
import { Row, Col } from 'style';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { Checkbox, Calendar, TextAreaStandart } from 'components/elements';
import ValidationPeriod from './validationPeriod';

export default (props) => {
  const [t] = useTranslation();
  const { save, info, data, id, setInfo, setId, setIndex, tableData } = props;
  let objectSchema = {};

  for(const item of tableData) {
    if(item.isRequired) {
      objectSchema = {
        ...objectSchema,
        [item.dataBase]: (item.dataType === 'float' || item.dataType === 'percent')
          ?
          Yup.number(t('common.validation.isRequiredInput')).positive(t('common.validation.isRequiredInput')).required(t('common.validation.isRequiredInput')).nullable(t('common.validation.isRequiredInput'))
          :
          (item.dataType === 'date'
            ?
            Yup.string().required(t('common.validation.isRequiredInput')).nullable(t('common.validation.isRequiredInput')).matches(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/, t('common.validation.isRequiredInput'))
            :
            Yup.string().required(t('common.validation.isRequiredInput')).nullable(t('common.validation.isRequiredInput'))
          ),
      };
    }
  }
  return (
    <HeadBlock>
      <Formik
        initialValues={info}
        validationSchema={Yup.object().shape(objectSchema)}
        enableReinitialize
        render={({ handleSubmit, handleChange, values, setFieldValue, setTouched, setFieldError, errors, touched }) => {
          const validationValue = () => {
            return (!!!values.toDate && values.isRepeatYearly) || (!!!values.fromDate && !values.isRepeatYearly) ||
            (Date.parse(values.toDate) < Date.parse(values.fromDate)) || (/Invalid date/.test(values.fromDate) || /Invalid date/.test(values.toDate));
          };
          return (
            <div>
              <Row>
                <Col lg={3}>
                  <Calendar
                    withoutFormik={true}
                    title={t('common.fromDate')}
                    setFieldValue={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    name='fromDate'
                    value={values.fromDate}
                  />
                  {
                    (errors?.fromDate && touched?.noActiveBtnClick) &&
                    <p style={{ color: 'red' }}>{errors?.fromDate}</p>
                  }
                </Col>
                <Col lg={3}>
                  <Calendar
                    withoutFormik={true}
                    title={t('common.toDate')}
                    setFieldValue={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    name='toDate'
                    value={values.toDate}
                  />
                  {
                    ((errors?.toDate && touched?.noActiveBtnClick) || (Date.parse(values.toDate) < Date.parse(values.fromDate)) ||
                    (values.isRepeatYearly && values.toDate === null)) &&
                    <p style={{ color: 'red' }}>{errors?.toDate}</p>
                  }
                </Col>
              </Row>
              <Formik initialValues={info.isCustomWeekDaySetting ? info : data} enableReinitialize render={(formikDays) => {

                return (
                  <div>
                    <Row>
                      <Col lg = {2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isMonday}
                          name='isMonday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.monday')}
                        />
                      </Col>
                      <Col lg = {2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isWednesday}
                          name='isWednesday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.wednesday')}
                        />
                      </Col>
                      <Col lg = {2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isFriday}
                          name='isFriday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.friday')}
                        />
                      </Col>
                      <Col lg = {2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isSunday}
                          name='isSunday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.sunday')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isTuesday}
                          name='isTuesday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.tuesday')}
                        />
                      </Col>
                      <Col lg={2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isThursday}
                          name='isThursday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.thursday')}
                        />
                      </Col>
                      <Col lg={2}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={formikDays.values.isSaturday}
                          name='isSaturday'
                          onChange={formikDays.setFieldValue}
                          title={t('common.days.saturday')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col width='100%'>
                        <TextAreaStandart
                          withoutFormik={true}
                          width='100%'
                          onChange={handleChange}
                          value={values.description}
                          name={'description'}
                          title={t('common.note')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg = {4}>
                        <Checkbox
                          withoutFormik={true}
                          margin='24px 0 0 0'
                          width='100%'
                          value={values.isRepeatYearly}
                          name='isRepeatYearly'
                          onChange={setFieldValue}
                          title={t('customer.repeatYearly')}
                        />
                      </Col>
                    </Row>
                    {errors?.period && <p style={{ color: 'red', marginTop: '5px' }}>{errors?.period}</p>}
                    <ValidationPeriod
                      {...props}
                      setInfo={setInfo}
                      validationValue={validationValue}
                      setFieldValue={setFieldValue}
                      setTouched={setTouched}
                      setFieldError={setFieldError}
                      setId={setId}
                      setIndex={setIndex}
                      save={save}
                      id={id}
                      values={values}
                      formikDays={formikDays}
                    />
                  </div>
                );
              }} />
            </div>
          );
        }}
      />
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  padding: 20px 20px 20px 20px;
`;


