import gql from 'graphql-tag';

export default gql`
query listFA_KgAmountRepresentationKinds{
    fakturaAssist{
    	listFA_KgAmountRepresentationKinds{
    		id
			name
 		}
 	}
}`;
