import React, { useState } from 'react';
import { Row, Col } from 'style';
import { Input, Button } from 'components/elements';
import styled from 'styled-components';

import AdditionaTextarea from '../../elements/AdditionalTextarea';

const alias = {
  indTextProduction: 'IndTextProduction',
  indTextDeliveryNote: 'IndTextDeliveryNote',
};

export default (props) => {
  const { item, t, closeModal, onChangeInput } = props;

  const [formState, setFormState] = useState({
    IndTextProduction: { target: { value: item.indTextProduction || item.productName }, name: 'indTextProduction' },
    IndTextDeliveryNote: { target: { value: item.indTextDeliveryNote || item.productName }, name: 'indTextDeliveryNote' },
  });
  const { IndTextDeliveryNote: { target: indTextDeliveryNote }, IndTextProduction: { target: indTextProduction } } = formState;

  const submitForm = (e) => {
    e.preventDefault();
    Object.keys(formState).forEach((key) => {
      onChangeInput({ target: { value: formState[key].target.value, name: formState[key].name } });
    });
    closeModal();
  };

  const handleClose = (e) => {
    e.preventDefault();
    closeModal();
  };

  const handleChange = ({ target: { name, value } }) => {
    const currentKey = alias[name];
    setFormState((prevState) => ({
      ...prevState,
      [currentKey]: {
        ...prevState[currentKey],
        target: {
          value: value === '<p><br></p>' ? item.productName : value,
        },
      },
    }));
  };

  const onAdditionTextareaArrowClick = (field) => {
    if (field === 'indTextProduction') {
      handleChange({ target: { name: 'indTextDeliveryNote', value: formState.IndTextProduction.target.value } });
    } else {
      handleChange({ target: { name: 'indTextProduction', value: formState.IndTextDeliveryNote.target.value } });
    }
  };

  return (
    <BodyModal>
      <form>
        <Input
          title={t('order.modals.articleDescription')}
          width='410px'
          name='IndTextProduction'
          value={item.productName || ''}
          notEdit
        />
        <AdditionaTextarea
          title={t('order.modals.textOnProductionList')}
          name='indTextProduction'
          value={indTextProduction.value || item.productName}
          handleChange={handleChange}
          onAdditionTextareaArrowClick={onAdditionTextareaArrowClick}
          productName={item.productName}
        />
        <AdditionaTextarea
          title={t('order.modals.textOnDeliveryNote')}
          name='indTextDeliveryNote'
          value={indTextDeliveryNote.value || item.productName}
          handleChange={handleChange}
          onAdditionTextareaArrowClick={onAdditionTextareaArrowClick}
          arrowUp
          productName={item.productName}
        />
        <RowBlock>
          <Col width='220px'>
            <Button value={t('common.cancel')} className='grey-btn' upperCase width='190px' onClick={handleClose} />
          </Col>
          <Col>
            <Button value={t('common.save')} className='black' upperCase width='190px' onClick={submitForm} />
          </Col>
        </RowBlock>
      </form>
    </BodyModal>
  );
};

const BodyModal = styled.div`
  width: 445px;
  height: 300px;
  padding: 20px;
`;

const RowBlock = styled(Row)`
  margin-top: 20px;
`;


