
import gql from 'graphql-tag';

export default gql`
query getStatusBakery2b($onDate: String!){
    fakturaAssist{
	    getStatusBakery2b(onDate: $onDate){
			status
		}
  	}
}`;
