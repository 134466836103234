/**
 * @description Sorting object collection by parameter name
 * @param {array} collection object collection
 * @param {string} paramName object paramenter name.
 * @param {ASC | DESC} sortOrder by default `ASC`
 * @example sortByParamName([{userName: 'Jon'}...],'userName', 'DESC' )
 * sortByParamName([{id: 123}...],'id', 'DESC' )
 */
export const sortByParamName = (collection, paramName, sortOrder = 'ASC') => {
  if (!Array.isArray(collection)) {
    return [];
  }

  const sorted = collection.sort((firstValue, secondValue) => {
    let fv = firstValue[paramName];
    let sv = secondValue[paramName];

    if (fv === undefined || sv === undefined) {
      return 0;
    }

    if (Array.isArray(fv) || Array.isArray(sv)) {
      return 0;
    }
    if (typeof fv === 'object' || typeof sv === 'object') {
      return 0;
    }

    const fvDigits = parseFloat(fv);
    const svDigits = parseFloat(sv);

    if (!Number.isNaN(fvDigits) && !Number.isNaN(svDigits)) {
      return fvDigits > svDigits ? 1 : -1;
    }

    if (typeof fv === 'string' && typeof sv === 'string') {
      return fv.toUpperCase() > sv.toUpperCase() ? 1 : -1;
    }
    return 0;
  });

  if (sortOrder === 'DESC') {
    return sorted.reverse();
  }
  return sorted;
};
