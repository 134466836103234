import gql from 'graphql-tag';

export const getFA_ProductDeliveryPeriod = gql`
query getFA_ProductDeliveryPeriod($productLink: Int!, $isVacation: Boolean){
	fakturaAssist{
		getFA_ProductDeliveryPeriod(productLink: $productLink, isVacation: $isVacation){
			id
			isMonday
			isTuesday
			isWednesday
			isThursday
			isFriday
			isSaturday
			isSunday
			toDate
			fromDate
			name
			isVacation
			isRepeatYearly
			isCustomWeekDaySetting
			days
		}
	}
}
`;

export const updateFA_ProductDeliveryPeriod = gql`
mutation updateFA_ProductDeliveryPeriod($id: Int!, $fields: FA_ProductDeliveryPeriodInput) {
	fakturaAssist {
		updateFA_ProductDeliveryPeriod(id: $id, fields: $fields) {
			id
			isMonday
			isTuesday
			isWednesday
			isThursday
			isFriday
			isSaturday
			isSunday
			toDate
			fromDate
			name
			isVacation
			isRepeatYearly
			isCustomWeekDaySetting
			days
		}
	}
}
`;

export const deleteFA_ProductDeliveryPeriod = gql`
mutation deleteFA_ProductDeliveryPeriod($id: Int!) {
	fakturaAssist {
		deleteFA_ProductDeliveryPeriod(id: $id) {
			id
		}
	}
}
`;

export const infoValidationDates = gql`
query infoValidationDates($id: Int, $customerId: Int!, $fromDate: String, $toDate: String){
    fakturaAssist{
    infoValidationDates(id: $id, customerId: $customerId, fromDate: $fromDate, toDate: $toDate){
		fromDate
		toDate
		description

    }

  }
}`;

export const createFA_ProductDeliveryPeriod = gql`
mutation createFA_ProductDeliveryPeriod($fields: FA_ProductDeliveryPeriodInput) {
	fakturaAssist {
		createFA_ProductDeliveryPeriod(fields: $fields){
			id
			isMonday
			isTuesday
			isWednesday
			isThursday
			isFriday
			isSaturday
			isSunday
			toDate
			fromDate
			name
			isVacation
			isRepeatYearly
			isCustomWeekDaySetting
			days
		}
	}
}
`;
