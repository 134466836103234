export const assortmentGroupsTableParams = (t) =>([
  {
    name: t('common.name'),
    dataBase: 'name',
  },
]);

export const postTableParams = (t) =>([
  {
    name: t('common.name'),
    dataBase: 'name',
  },
]);
  
  
