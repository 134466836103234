import gql from 'graphql-tag';
//Used in information block on top
export default gql`
query listFA_Forms($languageId: Int){
    fakturaAssist{
    	listFA_Forms(languageId: $languageId){
	    	id
            form
            name
            formId
            formIdDefault
            reportGroupID
	 	}
 	}
}`;

