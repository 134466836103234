import { listFA_OfferCustomersList } from './qql/query';
import Offer from './Offer';
import moment from 'moment';

const nextDay = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
const filterTypeName = JSON.parse(localStorage.getItem('offer'));

const filterData = (t) => [
  {
    title: t('common.filter.activeCustomers'),
    field: 'activeCustomer',
    value: 1,
  }, {
    title: t('common.filter.inactiveCustomers'),
    field: 'inactiveCustomer',
    value: 1,
  }, {
    title: t('offer.filter.additionalOffer'),
    field: 'additionalOffer',
    value: 1,
  }, {
    title: t('offer.filter.openOffers'),
    field: 'openOffers',
    value: 1,
  }, {
    title: t('offer.filter.acceptedOffers'),
    field: 'acceptedOffers',
    value: 1,
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  }, {
    title: t('offer.filter.declinedOffers'),
    field: 'declinedOffers',
    value: 1,
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  }];

const extendedColors = {
  acceptedOffers: '#85bb65',
  declinedOffers: '#FF0000',
};

export default (t) => ({
  name: t('offer.offer'),
  link: 'offer',
  sortField: 'customerNr',
  sortOrder: 'ASC',
  leftGridColors: {
    declined: '#FF0000',
    temporaryId: '#008080',
    accepted: ({ accepted, declined, offerID, offerNr, filterColumn }) => typeof accepted === 'boolean'
      ? accepted && !declined ? '#85bb65' : !accepted && !declined ? '#FFFF00' : '#FF0000'
      : extendedColors[filterColumn] ? extendedColors[filterColumn] : accepted === null && declined === null && offerNr && offerID ? '#FFFF00' : null,
  },
  separateSection: [
    {
      sortParams: {
        field: 'customerNr',
        order: 'ASC',
      },
      name: 'offer',
      link: 'create',
      listQuery: listFA_OfferCustomersList,
      mainItem: 'id',
      additionPrimeKeys: ['offerID'],
      Handler: Offer,
      disableSearch: false,
      searchColumns: ['customerNr', 'nameRes'],
      filterData: filterData(t),
      defaultFilterParams: {
        filter: filterTypeName || [{
          column: 'activeCustomer',
          value: '1',
        }],
        date: {
          date: nextDay,
          secondDate: nextDay,
        },
      },
      withParams: true,
      withoutMutation: true,
      historyFormTypeID: 9,
    },
  ],
});

