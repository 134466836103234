import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import { Calendar } from 'components/elements';
  
import arrow from 'style/blackArrowDown.svg';

const CalendarButton = ({ calendarDate, setCalendarDate, setCheck }) => {
  const [localCalendarDate, setLocalCalendarDate] = useState('');

  useEffect(() => {
    setLocalCalendarDate(calendarDate);
  }, JSON.stringify(calendarDate));

  const onPreviousDay = () => {
    setCalendarDate(moment(calendarDate).add(-1, 'days').format('YYYY-MM-DD'));
    setCheck();
  };
  const onNextDay = () => {
    setCalendarDate(moment(calendarDate).add(1, 'days').format('YYYY-MM-DD'));
    setCheck();
  };

  return (
    <Main>
      <ImgBlock onClick={onPreviousDay}>
        <img src={arrow}/>
      </ImgBlock>
      <Calendar
        withoutFormik={true}
        setFieldValue={(_, date) => {
          setLocalCalendarDate(date);
        }}
        onCalendarClose={() => {
          setCalendarDate(localCalendarDate);
          setCheck();
        }}
        value={calendarDate}
      />
      <ImgBlock second onClick={onNextDay}>
        <img src={arrow}/>
      </ImgBlock>
    </Main>
  );
};

CalendarButton.propTypes = {
  onClick: PropTypes.func,
};

CalendarButton.defaultProps = {
  text: 'Filter',
};

export default CalendarButton;

const Main = styled.div`
  width: 190px;
  position: relative;
  margin-left: 8%;
`;

const ImgBlock = styled.div`
  height: 22px;
  width: 20px;
  display: inline-block;
  position: absolute;
  left: ${({ second }) => second ? '140px' : '-20px'};
  bottom: ${({ second }) => second ? '6px' : '6px'};
  text-align: center;
  transform: ${({ second }) => second ? 'rotate(90deg);' : 'rotate(-90deg);'}; 
  cursor: pointer;
  img {
    width: 1rem;
  }
`;
