import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { phoneInputs, addressInputs, lands } from './params';
import { Dropdown, Input, Checkbox, Transition } from 'components/elements';
import Formik from 'components/formik';

export default (props) => {
  const { t } = props;
  return (
    <Formik initialValues={props.data} enableReinitialize={true} onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo} >
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <HeadBlock>
            <MainBlock>
              <Col lg={4}>
                <Row>
                  <Col width='150px'>
                    <Input value={values.suppliersNumber} small field='suppliersNumber' title={t('procurement.suppliers.supplierNr')}/>
                  </Col>
                  <Col lg={2}>
                    <Checkbox value={values.isActivated} field='isActivated' title={t('procurement.suppliers.supplierIsActive')}/>
                  </Col>
                </Row>

              </Col>
            </MainBlock>

            <Transition index={0} text={'Addresse'} isOpen isWhite>
              <Row>
                <Col lg={4}>
                  <Input value={values.additive} addition field='additive' title={t('customer.addition')}/>
                </Col>
                <Col lg={4}>
                  <Dropdown
                    title={t('customer.land')}
                    labelField = 'name'
                    valueField = 'id'
                    field = 'country'
                    defaultValueField = {values.country}
                    data={lands}/>
                </Col>
              </Row>
              <Row>
                <Col lg={4}><Input {...addressInputs(t)[0]} value={values[addressInputs(t)[0].field]}/></Col>
                <Col lg={4}><Input {...addressInputs(t)[1]} value={values[addressInputs(t)[1].field]}/></Col>
              </Row>
              <Row>
                <Col lg={4}><Input {...addressInputs(t)[3]} value={values[addressInputs(t)[3].field]}/></Col>
                <Col lg={4}><Input {...addressInputs(t)[4]} value={values[addressInputs(t)[4].field]}/></Col>
              </Row>
              <Row>
                <Col lg={4}>
                  {values.customerAddressKindID === 3 && <Input value={values.surName} field='surName' title={t('customer.surname')}/>}
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col lg={1}>
                      <Input value={values.postCode} width='80px' field='postCode' title={t('common.zip')}/>
                    </Col>
                    <Col lg={3}>
                      <Input value={values.city} field='city' width='100%' title={t('common.place')}/>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Transition>
            {values.customerAddressKindID === 2 && <Transition index={1} text={t('customer.deliveryAdress')} >
              <Row>
                <Col lg={4}>
                  <Input value={values.deliveryNoteSalutation} field='deliveryNoteSalutation' title={t('customer.salutation')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.deliveryNoteRoad} field='deliveryNoteRoad' title={t('common.street')}/>
                </Col>
                <Col lg={4}>
                  <Dropdown
                    title={t('customer.land')}
                    labelField = 'name'
                    valueField = 'id'
                    field = 'deliveryCountry'
                    defaultValueField = {values.deliveryCountry}
                    data={lands}/>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Input value={values.deliveryNotesName} field='deliveryNotesName' title={t('common.name')}/>
                </Col>
                <Col lg={4}>
                  <Input value={values.deliveryNoteMailbox} field='deliveryNoteMailbox' title={t('customer.postBox')}/>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Input value={values.deliveryNoteAddition} field='deliveryNoteAddition' title={t('customer.additive')}/>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col lg={2}>
                      <Input small value={values.deliveryNotePLZ} field='deliveryNotePLZ' title={t('common.zip')}/>
                    </Col>
                    <Col lg={2}>
                      <Input value={values.deliveryNoteOrt} field='deliveryNoteOrt' title={t('common.place')} small/>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Transition>}
            <Transition index={2} text={t('common.phone')} isOpen isWhite>
              <Row>
                <Col lg={4}><Input {...phoneInputs(t)[0]} value={values[phoneInputs(t)[0].field]}/></Col>
                <Col lg={4}><Input {...phoneInputs(t)[1]} value={values[phoneInputs(t)[1].field]}/></Col>
                <Col lg={4}><Input {...phoneInputs(t)[2]} value={values[phoneInputs(t)[2].field]}/></Col>
              </Row>
              <Row>
                <Col lg={4}><Input {...phoneInputs(t)[3]} value={values[[phoneInputs(t)[3]].field]}/></Col>
                <Col lg={4}><Input {...phoneInputs(t)[4]} value={values[phoneInputs(t)[4].field]}/></Col>
                <Col lg={4}><Input {...phoneInputs(t)[5]} value={values[phoneInputs(t)[5].field]}/></Col>
              </Row>
              <Row>
                <Col lg={4}><Input {...phoneInputs(t)[6]} value={values[phoneInputs(t)[6].field]}/></Col>
                <Col lg={4}><Input {...phoneInputs(t)[7]} value={values[phoneInputs(t)[7].field]}/></Col>
                <Col lg={4}><Input {...phoneInputs(t)[8]} value={values[phoneInputs(t)[8].field]}/></Col>
              </Row>

            </Transition>
          </HeadBlock>);
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  padding: 0px 0px 0px 0px;
`;
const MainBlock = styled(Row)`
  height: 96px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  padding: 0px 20px 20px 20px;
`;

