import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Row } from 'style';
import { Dropdown } from 'components/elements';
import Formik from 'components/formik';
import QueryLanguages from './languages';

export default (props) => {
  const [t] = useTranslation();
  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
      linkId={props.linkId}
      sectionInfo={props.sectionInfo}
    >
      {({ values }) => {
        return (
          <MainBlock>
            <Dropdown
              title={t('common.language')}
              field='descriptionAnotherLng'
              defaultValueField={values.descriptionAnotherLng}
              graphqlParams={{ query: QueryLanguages }}
              valueField='name'
              labelField='name'
            />
          </MainBlock>
        );
      }}
    </Formik>
  );
};

const MainBlock = styled(Row)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 20px;
`;
