import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withFieldSubmit } from '../../formik';
import checkedIcon from 'style/path.svg';

const Radio = (props) => {
  const { onChangeFormik, title, field, onClick, checked } = props;
  return (
    <Main checked={checked} width={props.width}>
      <Label >
      	<RadioStyle
          {...props}
          type ='radio'
          disabled={checked}
          onClick={(e) => {
            const { value } = e.target;
            if(onClick) {
              onClick(e);
            } else if(+value) {
              onChangeFormik(parseInt(value));
            } else {
              onChangeFormik(e.target.value);
            }
          }}
          field={field}
          
        />
        <RadioCircle/>
      	<span>{title}</span>
      </Label>
    </Main>
  );
};

export default withFieldSubmit(Radio);

const RadioCircle = styled.span`
  border-radius: 50%;
  background-size: 60%;
  content: '';
  display: inline-block;
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
  background-color: #fff;
  margin-right: 8px;
  border: 0.5px solid #cdcdcd;
  /* -webkit-transition: background-color 0.3s, border 0.3s; */
  transition: background-color 0.3s, border 0.3s;
`;

const Main = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  /* font-family: ${({ checked }) => checked ? 'Roboto-Medium' : 'Roboto-Light'}; */
  font-weight: ${({ checked }) => checked ? '500' : '300'};
  font-size: 14px;
`;

const Label = styled.label`
    float: left;
    cursor: pointer;
`;

const RadioStyle = styled.input`
  display: none;
  &:checked {
    & ~ ${RadioCircle} {
      background-image: url(${checkedIcon});
      background-repeat: no-repeat;
      background-position: center;
      border-color: #3cb950;
      transition: width 0.2s ease-out, height 0.2s ease-out;
      &::before {
        opacity: 0.5;
        transition: opacity 1s ease;
      }
    };
    & ~ ${Main} {
      font-weight: 500 !important;
    }
  }

`;

Radio.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.string,
};

/*
  Example
    <Radio field='radio'/>
*/

