import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Input, Checkbox, Transition } from 'components/elements';
import Formik from 'components/formik';
import TableGrid from 'components/tableGrid/TableGrid';

import { listFA_Assortment } from '../../qql/query';
import { createFA_Assortment, updateFA_Assortment, deleteFA_Assortment } from '../../qql/mutation';

import { assortmentGroupsTableParams, postTableParams } from './params';

export default ({ t, configData, onSubmit }) => {
  //Fill all needed data. All inputs must show.
  const articlePriceCategory = configData.articlePriceCategory || new Array(10).fill('');
  const productGroup = configData.productGroup || new Array(20).fill('');

  const AssortmentGroupsModal = (props)=>{
    const { handleBlur, handleChange, values } = props;
    return(
      <div>
        <Input
          handleBlur={handleBlur}
          withoutFormik
          type='text'
          onChange={handleChange}
          value={values.name}
          name='name'
          title={t('common.name')}
        />
      </div>
    );
  };

  return (
    <Formik initialValues={{ ...configData, productGroup, articlePriceCategory }} enableReinitialize onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        const categoryRender = values.articlePriceCategory && values.articlePriceCategory.map((item, index) => {
          if(index === 0) {
            return (
              <Input title={t('options.configurations.product.category')} field={`articlePriceCategory[${index}]`} value={item} />
            );
          }else{
            return(
              <OrderGroupSingleInput> <Input field={`articlePriceCategory[${index}]`} value={item} /></OrderGroupSingleInput>
            );
          }
        });
        const productGrouprender = values.productGroup && values.productGroup.map((item, index) => {
          if(index === 0) {
            return (
              <Input title={t('options.configurations.product.category')} field={`productGroup[${index}]`} value={item} />
            );
          }else{
            return(
              <OrderGroupSingleInput> <Input field={`productGroup[${index}]`} value={item} /></OrderGroupSingleInput>
            );
          }
        });
        return (

          <Row>
            <Transition text={t('options.configurations.product.category')} setTransitions={null}>
              <Row>
                {categoryRender}
              </Row>
              <Row>
                <Checkbox
                  margin='24px 0 12px 0'
                  value={values.isSameEANForMultipleArticles}
                  field='isSameEANForMultipleArticles'
                  title={t('options.configurations.product.theSameCode')}
                />
              </Row>
            </Transition>
            <Transition text={t('product.discountGroups')} setTransitions={null}>
              <Row>

                <ProductGroupBlock>{productGrouprender}</ProductGroupBlock>

              </Row>
            </Transition>
            <Transition text={t('common.assortmentGroups')} setTransitions={null} isOpen>
              <Row>
                <TableGrid
                  tableData={assortmentGroupsTableParams(t)}
                  graphqlParams={{ query: listFA_Assortment, fetchPolicy: 'cache-first' }}
                  height={720}
                  width={300}
                  Component={AssortmentGroupsModal}

                  defaultValuesForFormik={['name']}
                  defaultValuesForCache={['name']}
                  typeName={'listFA_Assortment'}
                  qqlMutationCreate={createFA_Assortment}
                  qqlMutationUpdate={updateFA_Assortment}
                  itemDeleted={deleteFA_Assortment}
                  modalHeight={160}
                  withModalMutations
                  disableHeightChange
                  //getTableIndex = {(_, item) => setFromDate(item.fromDate)}
                />
              </Row>
            </Transition>

            <Transition text={t('options.configurations.product.post')} minHeight='500px' isWhite>
              <Row>
                <Col lg={4}>
                  <Row>
                    <TableGrid
                      tableData={postTableParams(t)}
                      graphqlParams={{ query: listFA_Assortment, fetchPolicy: 'cache-first' }}
                      height={720}
                      width={300}
                      Component={AssortmentGroupsModal}

                      defaultValuesForFormik={['name']}
                      defaultValuesForCache={['name']}
                      typeName={'listFA_Assortment'}
                      qqlMutationCreate={createFA_Assortment}
                      qqlMutationUpdate={updateFA_Assortment}
                      itemDeleted={deleteFA_Assortment}
                      modalHeight={160}
                      withModalMutations
                      disableHeightChange
                      //getTableIndex = {(_, item) => setFromDate(item.fromDate)}
                    />
                  </Row>

                </Col>

              </Row>
            </Transition>
          </Row>
        );
      }}
    </Formik>
  );
};

const OrderGroupSingleInput = styled.div`
    padding-top: 10px;
    width: 300px;
    display: block;
`;

const ProductGroupBlock = styled.div`


`;
