import moment from 'moment';

import { listFA_CustomersListForCreatingOrdersOnDate/*, listFA_CreatedOrderForCustomer */ } from './qql/query';
import CreateOrder from './createOrder';
import { EditOrder } from './editOrder';
import Overview from './overview/index';
import GET_FA_ORDER_FILTER from './qql/query/getFA_OrderFilter';
import listFA_Customer from '../customer/qql/listFA_Customer';
import listFA_CustomerGroup from '../customer/adresse/qql/query/listFA_CustomerGroup';
import listFA_CustomerListsDefault from '../production/edit/table/crossTable/qql/listFA_CustomerListsDefault';
import { Checkbox } from 'components/elements';

const nextDay = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');

const filterTypeNameCreate = JSON.parse(localStorage.getItem('order/create'));
const filterTypeNameEdit = JSON.parse(localStorage.getItem('order/edit'));
const filterTypeNameEditDeleted = JSON.parse(localStorage.getItem('order/edit/isDelete'));

const filterCreate = {
  filter: filterTypeNameCreate || [{
    column: 'folderName',
    value: 'dailyCustomer',
  }],
  date: {
    date: nextDay,
  },
  orderType: 1,
};

const filterQuery = {
  query: GET_FA_ORDER_FILTER,
};

export const getGroupDeliveryItem = (t) => {
  return {
    title: t('common.groupDeliveryNote'),
    field: 'group',
    queryField: 'orderGroup',
    indexAsValue: true,
  };
};

const orderCreateFilterData = (t) => [
  {
    title: t('common.filter.dailyCustomers'),
    field: 'folderName',
    value: 'dailyCustomer',
    subItems: [getGroupDeliveryItem(t)],
  },
  {
    title: t('common.filter.customerWithEmptyOrders'),
    field: 'folderName',
    value: 'customerWithEmptyOrders',
    subItems: [getGroupDeliveryItem(t)],
  },
  {
    title: t('common.filter.acceptedOffers'),
    field: 'folderName',
    value: 'acceptedOffers',
  },
  {
    title: t('common.filter.activeCustomers'),
    field: 'folderName',
    value: 'activeCustomer',
    subItems: [getGroupDeliveryItem(t)],
  },
  {
    title: t('common.filter.inactiveCustomers'),
    field: 'folderName',
    value: 'inactiveCustomers',
    subItems: [getGroupDeliveryItem(t)],
  },
  {
    title: t('customer.customer'),
    field: 'folderName',
    value: 'customer',
    additionalInputs: [
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_Customer,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'customerId',
        labelField: 'nameCustomerDisp',
        valueField: 'id',
      },
    ],
  },
];

const orderEditFilterData = (t) => [
  {
    title: t('common.date'),
    field: 'folderName',
    value: 'date',
    additionalInputs: [
      {
        type: 'calendar',
        field: 'date',
      },
    ],
  },
  {
    title: t('common.filter.dateRange'),
    field: 'folderName',
    value: 'dateRange',
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  },
  {
    title: t('common.filter.deliveryNotesRange'),
    field: 'folderName',
    value: 'deliveryNotesRange',
    additionalInputs: [
      {
        type: 'input',
        field: 'firstRange',
        inputType: 'number',
        isSmall: true,
        keepItemsOnLevel: true,
        title: t('common.filter.from'),
      },
      {
        type: 'input',
        field: 'secondRange',
        inputType: 'number',
        isSmall: true,
        title: t('common.filter.to'),
        keepItemsOnLevel: true,
      },
    ],
  },
  {
    title: t('customer.customer'),
    field: 'folderName',
    value: 'customer',
    additionalInputs: [
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_Customer,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'customerId',
        labelField: 'nameCustomerDisp',
        valueField: 'id',
      },
    ],
  },
  {
    title: t('order.customerGroup'),
    field: 'folderName',
    value: 'customerGroup',
    additionalInputs: [
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_CustomerGroup,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'groupId',
        labelField: 'group',
        valueField: 'id',
        sortKey: 'label',
      },
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  },
  {
    title: t('common.filter.customerList'),
    field: 'folderName',
    value: 'customerList',
    additionalInputs: [
      {
        type: 'dropdown',
        graphqlParams: {
          query: listFA_CustomerListsDefault,
          variables: {
            params: {
              limit: 100,
            },
          },
        },
        field: 'linkCustomerList',
        labelField: 'name',
        valueField: 'id',
      },
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  },
  {
    title: t('common.filter.oneTimeCustomers'),
    field: 'folderName',
    value: 'oneTimeCustomers',
    additionalInputs: [
      {
        type: 'dateRange',
        subFields: ['date', 'secondDate'],
      },
    ],
  },
];

const filterEdit = {
  filter: filterTypeNameEdit || [{
    column: 'folderName',
    value: 'date',
  },
  {
    column: 'date',
    value: nextDay,
  }],
  restContex: filterTypeNameEditDeleted || [{
    column: 'isDelete',
    value: false,
  }],
};

const restContexData = (t) => [
  {
    title: t('common.show_deleted'),
    Component: Checkbox,
    field: 'isDelete',
  },
];

export const leftGridColors = {
  temporaryId: '#1A8081', // teal
  offerId: ({ offerId, isEmptyOrder }) => {
    // If offerId truthy value this is accepted order
    if(offerId) {
      return isEmptyOrder ? '#CFCFCF' : '#BFDDBF'; // '#BFDDBF' - money green
    }
    return null;
  },
  isEmptyOrder: '#CFCFCF', // light gray
};

export const editLeftGridColors = {
  invoiceNr: '#FDB790',
  isDelete: '#FF0000',
  orderType: ({ orderType }) => {
    if ([11, 12, 13, 14, 15, 16, 20, 21, 22].includes(+orderType)) {
      return '#EDC002';
    }
    if ([7, 8].includes(+orderType)) {
      return '#B5483B';
    }
  },
};

export default (t) => ([
  {
    link: 'order',
    separateSection: [
      {
        name: 'orderCreate',
        link: 'create',
        listQuery: listFA_CustomersListForCreatingOrdersOnDate,
        mainItem: 'id',
        filterData: orderCreateFilterData(t),
        filterRaw: true,
        filterQuery,
        Handler: CreateOrder,
        disableSearch: false,
        dataPolicy: 'local',
        withSearch: true,
        searchColumns: ['KundenNr', 'AktNameIntern'],
        defaultFilterParams: filterCreate,
        withParams: true,
        withoutMutation: true,
      },
      {
        name: 'orderEdit',
        link: 'edit',
        // listQuery: listFA_CreatedOrderForCustomer,
        mainItem: 'id',
        filterData: orderEditFilterData(t),
        restContexData: restContexData(t),
        filterRaw: true,
        Handler: EditOrder,
        defaultFilterParams: filterEdit,
        withParams: true,
        withoutMutation: true,
        disableSearch: false,
        searchColumns: ['orderNr', 'customerNr', 'actualName'],
        historyFormTypeID: 5,
      },
      {
        link: 'overview',
        Handler: Overview,

      },

    ],
  },
]);

