
import React from 'react';
import styled from 'styled-components';
import { tableData as params } from './params';

import TableGrid from '../../../../../components/tableGrid/TableGrid.js';

import { Dropdown, Input, TextAreaStandart } from '../../../../../components/elements';

import createFA_CustomerResellerPrices from './qql/mutation/createFA_CustomerResellerPrices';
import deleteFA_CustomerResellerPrices from './qql/mutation/deleteFA_CustomerResellerPrices';
import updateFA_CustomerResellerPrices from './qql/mutation/updateFA_CustomerResellerPrices';

import listFA_CustomerResellerPrices from './qql/listFA_CustomerResellerPrices';
import listProducts from '../../../qql/listProducts';
import { useTranslation } from 'react-i18next';

//import { Dropdown, Input, Text, Checkbox } from '../../../components/elements';

export default (props) => {
  const [t] = useTranslation();
  const Component = (props)=>{
    const { handleChange, values, setFieldValue, setFieldTouched, touched } = props;
    return(
      <div>
        <Dropdown
          withoutFormik={true}
          title={t('customer.article')}
          width='100%'
          onChange = {(value)=>{
            setFieldValue('productId', value.value); setFieldValue('productNm', value.data.productNumber);
          }}
          nameForValid = 'productNm'
          valueField = 'id'
          labelField = 'name'
          name = 'productId'
          defaultValueField = {values.productId}
          graphqlParams={{ query: listProducts, variables: { params: { limit: 100 } } }}
          setFieldTouched={setFieldTouched}
          touched={touched}
          err={props.errors}
          withPagination
        />

        <Input
          withoutFormik={true}
          type='number'
          decimal
          width='140px'
          onChange={handleChange}
          value={values.price}
          name={'price'}
          title={t('common.price')}
          setFieldTouched={setFieldTouched}
          touched={touched}
          err={props.errors}
        />

        <TextAreaStandart
          withoutFormik={true}
          width='100%'
          type='number'
          onChange={handleChange}
          value={values.note}
          name={'note'}
          title={t('common.note')}
        />
      </div>

    );
  };

  return (
    <div>
      <HeadBlock>
        <TableGrid
          graphqlParams={{
            query: listFA_CustomerResellerPrices,
            variables: { id: props.linkId } }}
          //searchValue={this.state.searchValue}
          //onSearch={this.onSearch}
          //getSortField={this.getSortField}
          Component={Component}
          height={700}
          name='Reseller prices'
          headerText={t('customer.conditions.resellerPrices')}
          modalWidth={440}
          modalHeight={450}
          //editItem={this.openModal}
          tableData={params(t)}
          withModalMutations
          //getTableIndex={this.indexOnClickTable}
          itemDeleted={deleteFA_CustomerResellerPrices}
          defaultValuesForFormik = {['productId', 'productNm']}
          defaultValuesForCache={['productNm', 'description']}
          defaultValuesForMutation = {{ customerId: +props.linkId }}
          typeName={'FA_CustomerResellerPrices'}
          qqlMutationCreate={createFA_CustomerResellerPrices}
          qqlMutationUpdate={updateFA_CustomerResellerPrices}
          dataPolicy='local'
          initialSortValue='id'
        />
      </HeadBlock>
    </div>
  );
};

const HeadBlock = styled.div`
  padding: 20px 20px 20px 20px;
`;
