import gql from 'graphql-tag';

export default gql`
query getListPrinters{
  fakturaAssist{
   	getListPrinters{
      status
      message
      printers{
        PrinterID
        PrinterName
        DefaultTray
        Trays{
          TrayID
          TrayName
        }
      }
    }
  }
}`;
