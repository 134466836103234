import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'style';
import { Formik } from 'formik';
import { useMutation } from 'components/graphql';
import { useQuery } from '@apollo/react-hooks';
import { cloneDeep, findIndex } from 'lodash';
import { LoadingWrap } from 'style/default';

import { Button, Input, Modal } from '../../../../../components/elements';
import TableGrid from 'components/tableGrid/TableGrid.js';

import getFA_CustomerDiscountChange from './qql/getFA_CustomerDiscountChange';
import listFA_Discount from './qql/listFA_Discount';

import updateFA_Discount from './qql/mutation/updateFA_Discount';
import MutationFA_CustomerDiscountChange from './qql/mutation/MutationFA_CustomerDiscountChange';
import { useTranslation } from 'react-i18next';

export default (props) =>{
  const [t] = useTranslation();
  const { setOpen, open, id, index, nameDiscount, setNameDiscount } = props;
  const [MUTATION_updateDiscountName] = useMutation(updateFA_Discount);
  const [MUTATION_updateDiscounts] = useMutation(MutationFA_CustomerDiscountChange);
  const [defaultData, setDefaultData] = useState([]);
  const [discountsNames, setDiscountsNames] = useState([]);
  const [names, setNames] = useState([]);
  const { data, loading, client } = useQuery(getFA_CustomerDiscountChange, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id,
    },
    onCompleted: (data)=>{
      setDefaultData(data.fakturaAssist.getFA_CustomerDiscountChange.data);
      setDiscountsNames(data.fakturaAssist.getFA_CustomerDiscountChange.discountsNames);
      setNames(data.fakturaAssist.getFA_CustomerDiscountChange.names);
    },
  });

  const handleClose = () =>{
    const cache = client.readQuery({
      query: getFA_CustomerDiscountChange,
      variables: {
        id: id,
      },
    }).fakturaAssist.getFA_CustomerDiscountChange.data;
    const cloneCache = cloneDeep(cache);
    setDefaultData(cloneCache);
    setOpen(false);
  };

  const handleSave = (elem, resetForm) =>{
    const values = cloneDeep(elem);
    const newValues = cloneDeep(elem);
    for(const item of values) {
      delete item.__typename;
    }

    const DATA = data.fakturaAssist.getFA_CustomerDiscountChange.data;
    const arrIndexCreate = [];
    const arrIndexUpdate = [];
    const arrIdDelete = [];
    values.forEach((item, index)=>{
      if(DATA.length === 0 && values.length > 0) {
        arrIndexCreate.push(index);
      }
      for(const elem of DATA) {
        if(elem.id === item.id) {
          arrIndexUpdate.push(index);
          return;
        }
        if(elem.id === DATA[DATA.length - 1].id) {
          arrIndexCreate.push(index);
          return;
        }
      }
    });

    DATA.forEach((item, index)=>{
      console.log(123);
      console.log();
      if(findIndex(values, { id: item.id }) === -1) {
        arrIdDelete.push(item.id);
      }
    });

    const mutationCreate = [];
    const mutationUpdate = [];
    for(const index of arrIndexCreate) {
      mutationCreate.push(values[index]);
    }

    for(const index of arrIndexUpdate) {
      mutationUpdate.push(values[index]);
    }

    MUTATION_updateDiscounts({
      variables: {
        discountsNames: discountsNames,
        mutationCreate: mutationCreate,
        mutationUpdate: mutationUpdate,
        arrIdDelete: arrIdDelete,
        idDiscount: +id,
      },
    });

    const cacheListDiscount = cloneDeep(client.readQuery({
      query: listFA_Discount,
    }).fakturaAssist.listFA_Discount);

    cacheListDiscount[index].name = nameDiscount;

    client.writeQuery({
      query: listFA_Discount,
      data: {
        fakturaAssist: {
          listFA_Discount: cacheListDiscount,
          __typename: 'FA_Query',
        },
      },
    });

    MUTATION_updateDiscountName({
      variables: {
        id: props.id,
        fields: {
          name: nameDiscount,
        },
      },
    });

    ///update cache
    const data12 = client.cache.readQuery({
      query: getFA_CustomerDiscountChange,
      variables: { id: id },
    }).fakturaAssist.getFA_CustomerDiscountChange;
    const data12Copy = cloneDeep(data12);
    data12Copy.data = newValues;
    const cacheData = cloneDeep(data12Copy);
    client.writeQuery({
      query: getFA_CustomerDiscountChange,
      variables: {
        id: id,
      },
      data: {
        fakturaAssist:
                          {
                            getFA_CustomerDiscountChange: cacheData,
                            __typename: 'FA_Query',
                          },

      },
    });
    setDefaultData(cacheData.data);
    handleClose();
    // id
    // amount
    // discountTotal
    // discounts
  };

  const createDiscount = (values, resetForm) =>{
    const newValues = cloneDeep(values);
    newValues.push({
      id: (newValues.length + 1) * 1000,
      amount: 0,
      discountTotal: 0,
      discounts: [],
      __typename: 'FA_CustomerDiscountChangeData',
    });
    names.forEach((item, index)=>{
      newValues[newValues.length - 1].discounts.push(null);
    });
    resetForm(newValues);
    setDefaultData(newValues);
  };

  const deleteDiscount = (values, resetForm, index) =>{
    const newValues = cloneDeep(values);
    newValues.splice(index, 1);
    resetForm([]);
    setDefaultData(newValues);
  };

  const onBlur = (values) =>{
    setNameDiscount(values.nameDiscount);
  };

  const Component = ()=>{
    return(
      <div>
        <Formik
          enableReinitialize
          initialValues={defaultData || []}
          linkId={props.linkId}
          sectionInfo={props.sectionInfo}
          render={
            ({ values, handleChange, resetForm, setValues }) => {
              const DATA = data.fakturaAssist.getFA_CustomerDiscountChange;
              const tableData = [
                {
                  name: t('common.quantity'),
                  dataBase: 'amount',
                  relativeWidth: 0.08,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <Row>
                        <Input
                          onWheel={ (event) => event.currentTarget.blur() }
                          style={{ marginTop: '-5px' }}
                          noBorder
                          withOpacity
                          width='100%'
                          withoutFormik={true}
                          onChange={handleChange}
                          type='number'
                          value={values[index] && values[index].amount}
                          name={`[${index}]amount`}/>
                      </Row>
                    );
                  },
                },
                {
                  name: t('common.discount'),
                  dataBase: 'discountTotal',
                  relativeWidth: 0.08,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <Row>
                        <Input
                          onWheel={ (event) => event.currentTarget.blur() }
                          style={{ marginTop: '-5px' }}
                          noBorder
                          withOpacity
                          width='100%'
                          withoutFormik={true}
                          onChange={handleChange}
                          type='number'
                          value={values[index] && values[index].discountTotal}
                          name={`[${index}]discountTotal`}/>
                      </Row>
                    );
                  },
                },
              ];

              DATA.names.forEach((item, index)=>{
                const relativeWidth = DATA.names.length > 10 ? (item.length + 8) / 200 : 1 / DATA.names.lenght - 2;
                tableData.push(
                  {
                    name: DATA.names[index],
                    dataBase: DATA.discountsNames[index],
                    relativeWidth: relativeWidth,
                    specialComponent: (index, columnIndex)=>{
                      return (
                        <Row >
                          <Input
                            onWheel={ (event) => event.currentTarget.blur() }
                            key={values[index] && values[index].id}
                            style={{ marginTop: '-5px' }}
                            noBorder
                            withOpacity
                            width='100%'
                            withoutFormik={true}
                            onChange={handleChange}
                            type='number'
                            value={values[index] && values[index].discounts[columnIndex]}
                            name={`[${index}]discounts[${columnIndex}]`}/>
                        </Row>
                      );
                    },
                  }
                );
              });
              return(
                <Main>
                  {
                    <Formik
                      enableReinitialize
                      initialValues={{ nameDiscount: nameDiscount }}
                      render={
                        ({ values, handleChange }) => {
                          return(
                            <Row>
                              <Col lg={4}>
                                <Input
                                  title = {t('common.description')}
                                  width='300px'
                                  withoutFormik={true}
                                  onChange={handleChange}
                                  onBlur = {()=>onBlur(values)}
                                  type='string'
                                  value={values.nameDiscount}
                                  name={'nameDiscount'}/>
                              </Col>
                            </Row>

                          );
                        }}
                    />
                  }
                  <Block>
                    <TableGrid
                      //searchValue={this.state.searchValue}
                      //onSearch={this.onSearch}
                      //getSortField={this.getSortField}
                      actionsToLeft
                      onDelete = {(index)=>{
                        deleteDiscount(values, resetForm, index);
                      }}

                      withSearch
                      withoutIdForUpdate='all'
                      overscanColumnCount={15}
                      addNewItem={()=>createDiscount(values, resetForm)}
                      withScroll
                      height={600}
                      name={t('customer.quantityDiscount')}
                      tableData={tableData}
                      //getTableIndex={this.indexOnClickTable}
                      data={defaultData || []}
                    />
                  </Block>
                  <Footer>
                    <ColOfButtonLeft width='50%'>
                      <Button
                        float='right'
                        className={'grey-btn'}
                        width={'190px'}
                        onClick={handleClose}
                        value={t('common.cancel')}/>
                    </ColOfButtonLeft>
                    <ColOfButtonRight width='50%'>
                      <Button
                        float='left'
                        className={'black'}
                        width={'190px'}
                        onClick={()=>handleSave(values)}
                        value={t('common.save')}/>
                    </ColOfButtonRight>
                  </Footer>

                </Main>
              );
            }}
        />

      </div>);
  };

  return(
    <div>{<Modal
      headerText={t('customer.editQuantityDiscount')}
      width={'1400px'}
      height={'860px'}
      isOpen={open}
      Component={
        <LoadingWrap loading={loading}>
          {loading ? <div /> : Component()}
        </LoadingWrap>
      }/>}</div>
  	);
};

const Main = styled.div`
  padding: 10px 20px 20px 20px;
  background: #f4f4f4;
`;

const Block = styled.div`
  margin-top: 20px;
`;

const Footer = styled(Row)`
  margin-top: 20px;
`;

const ColOfButtonLeft = styled(Col)`
  margin-right: 15px;
`;

const ColOfButtonRight = styled(Col)`
  margin-left: 15px;
`;
