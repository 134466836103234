import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { mounths } from '../dateRange/CONST';
import { Radio } from '../';

const MonthChooser = ({ defaultMonth, onClick }) => {
  const [t] = useTranslation();

  const mounthsCheckbox = mounths(t).map(({ id, name }, index) => {
    return (
      <Col lg={4} key={index}>
        <Radio
          checked={defaultMonth === name}
          title={name}
          onClick={() => onClick(id, name)}
        />
      </Col>
    );
  });

  return (
    <MonthChooserContainer>
      <DayChooserText>{t('common.month')}</DayChooserText>
      {mounthsCheckbox}
    </MonthChooserContainer>
  );
};

export default MonthChooser;

const DayChooserText = styled.p`
  margin: 0;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
`;

const MonthChooserContainer = styled.div`
  margin-top: 20px;
`;
