import React from 'react';
import { Row, Col } from 'style';

import { Input, Checkbox, Dropdown } from 'components/elements';
import Formik from 'components/formik';

import { listFA_PriceRoundingMethods } from '../../qql/query';

export default ({ t, onSubmit, configData }) => {

  return (
    <Formik initialValues={configData} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) =>{
        return (
          <>
            <Row>
              <Col lg={4}>
                <Input title={t('options.configurations.invoice.invoiceNr')} field='invoiceNumber' value={values.invoiceNumber} type='number' small/>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Dropdown
                  title={t('options.configurations.invoice.totalsPriceRounding')}
                  field = 'roundingTotalPriceMethodID'
                  defaultValueField = {values.roundingTotalPriceMethodID}
                  graphqlParams={{ query: listFA_PriceRoundingMethods }}
                />
              </Col>
            </Row>
            <Row>
              <Col width='100%'>
                <Checkbox margin = '10px 0 0 0' value={values.isAddSummaryToInvoiceReportPDF} field='isAddSummaryToInvoiceReportPDF' title={t('options.configurations.invoice.addPaymentSlipToInvoice')}/>
                <Checkbox margin = '10px 0 0 0' value={values.isAddSummaryToInvoiceReportPDF} field='isAddSummaryToInvoiceReportPDF' title={t('options.configurations.invoice.addSummaryToInvoice')}/>
              </Col>
            </Row>
            <Row>
              <Col width='100%'>
                <Checkbox margin = '30px 0 0 0'value={values.isUseAnotherCurrencies} field='isUseAnotherCurrencies' title={t('options.configurations.invoice.useAnotherCurrencies')}/>
              </Col>
            </Row>
            {values.isUseAnotherCurrencies && <Row>
              <Col lg={2}>
                <Input title={t('options.configurations.invoice.euroRate')} field='euroRate' value={values.euroRate} small type='float'/>
              </Col>
              <Col lg={2}>
                <Input title={t('options.configurations.invoice.usdRate')} field='usdRate' value={values.usdRate} small type='float'/>
              </Col>
            </Row>}
    
            <Row />
          </>
        );
      }}
    </Formik>
  );
};
