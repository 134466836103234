import React from 'react';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import WarningValid from 'style/img/warningValid.svg';
import { withFieldSubmit } from '../../formik';
import withName from '../hoc/withName';

const TextAreaStandart = (props) =>{
  const { submit, key, onChangeFormik, onBlur, withoutFormik, onChange, err = {}, name, handleBlur, touched, customErrText } = props;
  return (
    <>
      <Inputs
        key={key}
        onBlur={!withoutFormik ? (e) => {
          submit(e); handleBlur && handleBlur(e);
          onBlur && onBlur(e.target.value);
        } : (e) =>{
          handleBlur && handleBlur(e);
        }
          
        }
        onChange={!withoutFormik ? (e) =>{
          onChangeFormik(e.target.value);
        } : (e) => onChange(e)
        }
        {...props}
      
      />
      {((err && err[name]) && (touched && touched[name])) &&
        <Error>
          <Img src={WarningValid}/> {customErrText ? customErrText : err[name]}
        </Error>
      }
    </>);
};

export default compose(
  withFieldSubmit, withName)(TextAreaStandart);

const Inputs = styled.textarea`
  /* font-family: Roboto-Medium; */
  font-size: 14px;
  font-weight: 500;
  width: ${(props) => props.width ? props.width : '300px'};
  margin-top: ${(props) => props.margin ? props.margin : ''};
  padding-left: 10px;
  padding-top: 10px;
  height: ${(props) => props.height || '100px'};
  border-radius: 3px;
  border: ${(props) => (props.err && props.err[props.name]) && (props.touched && props.touched[props.name]) ? '1px solid #c13c44' : (props.noBorder ? 'none' : 'solid 1px #ffffff')} ;
  box-shadow: ${(props) => (props.noBorder ? 'none' : '0 1px 2px 0 rgba(90, 90, 90, 0.12)')}  ;
  background: ${(props) => (props.notEdit ? '#c3c3c3' : '#ffffff')};
  color: ${(props) => (props.notEdit ? '#ffffff' : 'inherit')};
  resize: ${(props) => props.notResize && 'none' };
`;
const Error = styled.div`
  color: #c13c44;
  /* font-family: Roboto-Light; */
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  margin-top: 0px;
`;

const Img = styled.img`
  margin-top: -6px;
`;

TextAreaStandart.propTypes = {
  value: PropTypes.string.isRequired, //Array format on bottomd,
};

TextAreaStandart.defaultProps = {
  value: '',
};

/*
  Example
    <TextAreaStandart value='Some'/>
*/

