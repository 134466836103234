import { cloneDeep } from 'lodash';
import { writeQuery } from 'helpers/queryOperations';
import reduce from 'helpers/reduceGraphqlData';

const deleteOperations = (props) => {
  const { client, contex, chooseCustomerIndex, changeChooseCustomerInfo, changeChooseCustomerIndex, query, link } = props;
  const deleteCustomerFromList = (refetch) => {
    try {
      // console.table({ contex, chooseCustomerIndex, changeChooseCustomerInfo, query })
      const cacheData = client.cache.readQuery({
        query: query,
        variables: contex,
      });
      const clientsList = cloneDeep(reduce(cacheData));
      // console.log({clientsList})
      // console.log({ refetch, chooseCustomerIndex, clientsList })
      clientsList.splice(chooseCustomerIndex, 1);
      writeQuery({
        client,
        query: query,
        variables: contex,
        changedData: cloneDeep(clientsList),
      });
      refetch();
      // console.log(productsFromCache)
      // const queryName = query.definitions[0].name.value;
      // const deepArray = cloneDeep(productsFromCache);
      // deepArray.fakturaAssist[queryName].splice(chooseCustomerIndex, 1);
      // const changedData = deepArray.fakturaAssist[queryName];
      // console.log(changedData)

      try {
        // client.cache.writeQuery({
        //   query: query,
        //   variables: contex,
        //   data: {
        //     fakturaAssist: {
        //       [queryName]: changedData,
        //       __typename: 'FA_Query',
        //     },
        //   },
        // });

        //Change customer position
      } catch (err) {
        //  console.log(err)
      }
      const currentFilterState = contex?.filter[0]?.value;
      if (!(['dailyCustomer', 'customerWithEmptyOrders'].includes(currentFilterState))) {
        return;
      }
      if (!clientsList.length) {
        props.history.replace({
          pathname: `/${link}`,
        });
      } else if (clientsList.length === chooseCustomerIndex) {
        changeChooseCustomerInfo(clientsList[chooseCustomerIndex - 1]);
        changeChooseCustomerIndex(chooseCustomerIndex - 1);
        props.history.replace({
          pathname: `/${link}/${clientsList[chooseCustomerIndex - 1].id}`,
        });
      } else {
        changeChooseCustomerIndex(chooseCustomerIndex);
        changeChooseCustomerInfo(clientsList[chooseCustomerIndex]);
        props.history.replace({
          pathname: `/${link}/${clientsList[chooseCustomerIndex].id}`,
        });
      }
      //console.log(productsFromCache);
    } catch (err) {
      console.log(err);
    }
  };
  return {
    deleteCustomerFromList,
  };
};

export default deleteOperations;

