/*eslint-disable*/
import styled from 'styled-components';
import { Button } from 'components/elements';
import { Formik } from 'formik';
import { Row, Col } from 'style';
import { useMutation } from 'components/graphql';
import React, { useState, useEffect } from 'react';
import { cloneDeep, findIndex } from 'lodash';

import Add from '../../../../style/img/add.svg';
import DeleteImg from '../../../../style/img/delete.svg';

import listDefaultOrder from '../qql/listDefaultOrder';
import changeDefaultOrderModal from '../qql/changeDefaultOrderModal';

import SpecialInputForModal from './SpecialInputForModal.js';
import TableGrid from 'components/tableGrid/TableGrid.js';
import { useTranslation } from 'react-i18next';

const Index = (props) => {
  const [t] = useTranslation();
  const filterData = (data = {}) =>{
    const additionalData = [];
    for(let i = 1; i <= 13; i++) {
      if(i === 1) {
        additionalData.push(
          {
            title: 'Delivery:',
            Mon: data.quantityMon,
            Tue: data.quantityTue,
            Wed: data.quantityWed,
            Thu: data.quantityThu,
            Fri: data.quantityFri,
            Sat: data.quantitySat,
            Sun: data.quantitySun,
            id: data.id,
          }
        );
      } else if(i === 2) {
        additionalData.push(
          {
            title: 'Min:',
            Mon: data.MonMin,
            Tue: data.TueMin,
            Wed: data.WedMin,
            Thu: data.ThuMin,
            Fri: data.FriMin,
            Sat: data.SatMin,
            Sun: data.SunMin,
            id: data.id,
          }
        );
      } else if(i === 3) {
        additionalData.push(
          {
            title: 'Max:',
            Mon: data.MonMax,
            Tue: data.TueMax,
            Wed: data.WedMax,
            Thu: data.ThuMax,
            Fri: data.FriMax,
            Sat: data.SatMax,
            Sun: data.SunMax,
            id: data.id,
          }
        );
      } else{
        additionalData.push(
          {
            title: `Part ${i - 3}`,
            Mon: data[`Mon${i - 3}`],
            Tue: data[`Tue${i - 3}`],
            Wed: data[`Wed${i - 3}`],
            Thu: data[`Thu${i - 3}`],
            Fri: data[`Fri${i - 3}`],
            Sat: data[`Sat${i - 3}`],
            Sun: data[`Sun${i - 3}`],
            id: data.id,
          }
        );
      }
    }
    const returnData = cloneDeep(additionalData);
    return returnData;
  };
  const {index, setIndex, id, setId, loadingChanges} = props;
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const [arrForMutation, setArrForMutation] = useState([]);
  const [dataForRefresh, setDataForRefresh] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [dataForTable, setDataForTable] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [defaultDataForTable, setDefaultDataForTable] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
  const [defaultDataDB, setDefaultDataDB] = useState([]);
  const [count, setCount] = useState(3);
  const [textError, setTextError] = useState('');

  useEffect(() => {
    props.dataDef[index] && setDataForTable(filterData(props.dataDef[index]));
    props.dataDef[index] && setDefaultDataForTable(filterData(props.dataDef[index]));
    setDefaultDataDB(props.dataDef);
  }, [id, props.dataDef]);

  useEffect(() => {
    setCount((props.values.deliverySplittingParts == 1 || !props.values.enabledDeliverySplitting) ? 0 : props.values.deliverySplittingParts);

  }, [props.dataDef]);

    useEffect(

    () => {
    setDataForRefresh(props.dataDef);
  }, []);

  const [Mutation_ChangeDefaultOrderModa] = useMutation(changeDefaultOrderModal);
  const checkChangesInTable = (values) =>{
    let boolean = true;
    const arrDaysPartFormik = [];
    for(const item of values) {
      for(let i = 0; i < 7; i++) {
        arrDaysPartFormik.push(item[days[i]]);
      }
    }

    ///
    const arrDaysPartOrigin = [];
    for(const item of defaultDataForTable) {
      for(let i = 0; i < 7; i++) {
        arrDaysPartOrigin.push(item[days[i]]);
      }
    }

    arrDaysPartFormik.forEach((item, index)=>{
      if(item === arrDaysPartOrigin[index]) {
      } else if(!item && !arrDaysPartOrigin[index]) {
      } else{
        boolean = false;
      }
    });
    return boolean;
  };

  const changeCacheBeforeMutation = (values, bool) =>{
    let trueIndex = 0;
    const arrDaysPart = [[], [], [], [], [], [], []];
    const arrDaysQuantity = [[], [], [], [], [], [], []];



    //create arr for mutations
    for(const item of values.slice(3)) {
      for(let i = 0; i < 7; i++) {
        arrDaysPart[i].push(item[days[i]]);
      }
    }
    for(const item of values.slice(0, 3)) {
      for(let i = 0; i < 7; i++) {
        arrDaysQuantity[i].push((item[days[i]]||item[days[i]] === 0) ? +item[days[i]]  : null);
      }
    }
    //update state
    setDefaultDataForTable(values);

    //push cache
    const cacheData = props.client.readQuery({
      query: listDefaultOrder,
      variables: {
        id: +props.linkId,
        date: props.date,
      },
    }).fakturaAssist.listDefaultOrder;

    const cloneCacheDataForChange = cloneDeep(cacheData);

    //change cache
    cloneCacheDataForChange.forEach((item, index)=>{
      if(item.id === id) {
        trueIndex = index;
      }
    });
    arrDaysPart.forEach((item, index)=>{
      for(let i = 1; i <= 10; i++) {
        cloneCacheDataForChange[trueIndex][days[index] + i] = item[i - 1];
      }
    });

    arrDaysQuantity.forEach((item, index)=>{
      cloneCacheDataForChange[trueIndex]['quantity' + days[index]] = item[0];

      cloneCacheDataForChange[trueIndex][days[index] + 'Min'] = item[1];

      cloneCacheDataForChange[trueIndex][days[index] + 'Max'] = item[2];
    });

    //save cache
    props.client.writeQuery({
      query: listDefaultOrder,
      variables: {
        id: +props.linkId,
        date: props.date,
      },
      data: {
        fakturaAssist:
                          {
                            listDefaultOrder: cloneCacheDataForChange,
                            __typename: 'FA_Query',
                          },

      },
    });


    let cloneArrForMutation = cloneDeep(arrForMutation);

     if(findIndex(cloneArrForMutation, ['id', id])>=0){

       cloneArrForMutation.splice(findIndex(cloneArrForMutation, ['id', id]), 1, {
        id: id,
        idProduct: +cacheData[trueIndex].productId,
        idCustomer: +props.linkId,
        arrDaysPart: arrDaysPart,
        arrDaysQuantity: arrDaysQuantity,
      })

       setArrForMutation(cloneArrForMutation)

     }
     else{
       cloneArrForMutation = [...cloneArrForMutation, {
        id: id,
        idProduct: +cacheData[trueIndex].productId,
        idCustomer: +props.linkId,
        arrDaysPart: arrDaysPart,
        arrDaysQuantity: arrDaysQuantity,
      }]
       setArrForMutation(cloneArrForMutation)
     }


        // //mutation

     if(bool){
           //update count  mutations/cache
    try{
      props.onChangeFormik(count || 1 ,"deliverySplittingParts");
      props.onChangeFormik((count && count !==1) ? true : false,"enabledDeliverySplitting");
      props.submit();
    }
    catch(err){
      console.log('ERR');
      console.log(err);
    }
       Mutation_ChangeDefaultOrderModa({
      variables: {
        fields: cloneArrForMutation
      },
    });
     }



    setTextError('');
  };

  const changeSelectId = (item, values, index) =>{
      setIndex(index)
      setId(item.id);
      setDataForTable(filterData(item));
      setDefaultDataForTable(filterData(item));
      setTextError('');


    changeCacheBeforeMutation(values);

  };
  const onClickAdd = (values, setValues)=>{
    if(!count){
      setCount(2)
    }
    else{
      count < 10 && setCount(count + 1);}
  };
  const onClickDelete = (values, setValues)=>{
    if(count === 2){
      setCount(0)
    }
    else{
      count > 1 && setCount(count - 1);
    }
  };



  // const onSave = (values) =>{
  //   let trueIndex = 0;
  //   const arrDaysPart = [[], [], [], [], [], [], []];
  //   const arrDaysQuantity = [[], [], [], [], [], [], []];

  //   //update count  mutations/cache
  //   try{
  //     props.onChangeFormik( count, 'deliverySplittingParts' );
  //           props.submit(count, 'deliverySplittingParts' );
  //   }
  //   catch(err){
  //     console.log('ERR');
  //     console.log(err);
  //   }

  //   //create arr for mutations
  //   for(const item of values.slice(3)) {
  //     for(let i = 0; i < 7; i++) {
  //       arrDaysPart[i].push(item[days[i]]);
  //     }
  //   }
  //   for(const item of values.slice(0, 3)) {
  //     for(let i = 0; i < 7; i++) {
  //       arrDaysQuantity[i].push(item[days[i]] ? +item[days[i]] : null);
  //     }
  //   }

  //   //update state
  //   setDefaultDataForTable(values);

  //   //push cache
  //   const cacheData = props.client.readQuery({
  //     query: listDefaultOrder,
  //     variables: {
  //       id: +props.linkId,
  //       date: props.date,
  //     },
  //   }).fakturaAssist.listDefaultOrder;

  //   const cloneCacheDataForChange = cloneDeep(cacheData);

  //   //change cache
  //   cloneCacheDataForChange.forEach((item, index)=>{
  //     if(item.id === id) {
  //       trueIndex = index;
  //     }
  //   });
  //   arrDaysPart.forEach((item, index)=>{
  //     for(let i = 1; i <= 10; i++) {
  //       cloneCacheDataForChange[trueIndex][days[index] + i] = item[i - 1];
  //     }
  //   });

  //   arrDaysQuantity.forEach((item, index)=>{
  //     cloneCacheDataForChange[trueIndex]['quantity' + days[index]] = item[0];

  //     cloneCacheDataForChange[trueIndex][days[index] + 'Min'] = item[1];

  //     cloneCacheDataForChange[trueIndex][days[index] + 'Max'] = item[2];
  //   });

  //   //save cache
  //   props.client.writeQuery({
  //     query: listDefaultOrder,
  //     variables: {
  //       id: +props.linkId,
  //       date: props.date,
  //     },
  //     data: {
  //       fakturaAssist:
  //                         {
  //                           listDefaultOrder: cloneCacheDataForChange,
  //                           __typename: 'FA_Query',
  //                         },

  //     },
  //   });

  //   //mutation
  //   Mutation_ChangeDefaultOrderModa({
  //     variables: {
  //       id: id,
  //       idProduct: +cacheData[trueIndex].productId,
  //       idCustomer: +props.linkId,
  //       arrDaysPart: arrDaysPart,
  //       arrDaysQuantity: arrDaysQuantity,
  //     },
  //   });
  //   const cloneArrForMutation = cloneDeep(arrForMutation);

  //    if(findIndex(cloneArrForMutation, ['id', id])>=0){

  //      cloneArrForMutation.splice(findIndex(cloneArrForMutation, ['id', id]), 1, {
  //       id: id,
  //       idProduct: +cacheData[trueIndex].productId,
  //       idCustomer: +props.linkId,
  //       arrDaysPart: arrDaysPart,
  //       arrDaysQuantity: arrDaysQuantity,
  //     })

  //      setArrForMutation(cloneArrForMutation)

  //    }
  //    else{
  //      setArrForMutation([...arrForMutation, {
  //       id: id,
  //       idProduct: +cacheData[trueIndex].productId,
  //       idCustomer: +props.linkId,
  //       arrDaysPart: arrDaysPart,
  //       arrDaysQuantity: arrDaysQuantity,
  //     }])
  //    }



  //   setTextError('');
  // };

  const onSave = async (values) =>{
    changeCacheBeforeMutation(values, true);
    props.setOpen(false);
  }

  const onRefresh = (resetForm) =>{

    const cloneDefaultDataDB = cloneDeep(defaultDataDB);

    cloneDefaultDataDB.splice(index,1, dataForRefresh[index]);

    changeCacheBeforeMutation(filterData(dataForRefresh[index]));
    resetForm({
             values: filterData(dataForRefresh[index])})


    props.client.writeQuery({
      query: listDefaultOrder,
      variables: {
        id: +props.linkId,
        date: props.date,
      },
      data: {
        fakturaAssist:
                          {
                            listDefaultOrder: cloneDefaultDataDB,
                            __typename: 'FA_Query',
                          },

      },
    })



  };

  const onClose = () =>{
    props.setOpen(false);
    props.client.writeQuery({
      query: listDefaultOrder,
      variables: {
        id: +props.linkId,
        date: props.date,
      },
      data: {
        fakturaAssist:
                          {
                            listDefaultOrder: dataForRefresh,
                            __typename: 'FA_Query',
                          },

      },
    })
  }




  return (

    <div>
      {
        !props.loading ? <Formik
          enableReinitialize
          initialValues={dataForTable || [{}, {}, {}, {}, {}]}
          onSubmit={props.mutations}
          >
          {
            ({ values, setFieldValue, handleSubmit, resetForm, setValues, handleChange }) => {
              const dataForCheckValues = filterData(dataForRefresh[index]);
              const tableData = [
                {
                  name: '',
                  colorBlock: '#fff',
                  dataBase: 'title',
                  relativeWidth: 0.146,
                  specialComponent: (index, columnIndex, value)=>{
                    return (
                      <div>
                        {index === (count + 2) ? <div >{value}<img style={{ 'margin-left': '10px', 'cursor': 'pointer' }} src={Add} onClick={()=>onClickAdd(values, resetForm)}/> <img style={{ 'margin-left': '10px', 'cursor': 'pointer' }} src={DeleteImg} onClick={()=>onClickDelete(values, resetForm)}/></div> : value}
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.monday'),
                  dataBase: 'title',
                  colorBlock: '#eeeeee',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Mon === dataForCheckValues[index].Mon ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Mon}
                          name={`[${index}]Mon`}/>
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.tuesday'),
                  dataBase: 'title',
                  colorBlock: '#fff',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Tue === dataForCheckValues[index].Tue ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Tue}
                          name={`[${index}]Tue`}/>
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.wednesday'),
                  dataBase: 'title',
                  colorBlock: '#eeeeee',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Wed === dataForCheckValues[index].Wed ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Wed}
                          name={`[${index}]Wed`}
                        />
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.thursday'),
                  dataBase: 'title',
                  colorBlock: '#fff',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Thu === dataForCheckValues[index].Thu ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Thu}
                          name={`[${index}]Thu`}

                        />
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.friday'),
                  dataBase: 'title',
                  colorBlock: '#eeeeee',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Fri === dataForCheckValues[index].Fri ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Fri}
                          name={`[${index}]Fri`}

                        />
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.saturday'),
                  colorBlock: '#fff',
                  dataBase: 'title',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Sat === dataForCheckValues[index].Sat ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Sat}
                          name={`[${index}]Sat`}
                        />
                      </div>
                    );
                  },
                },
                {
                  name: t('common.days.sunday'),
                  dataBase: 'title',
                  colorBlock: '#eeeeee',
                  relativeWidth: 0.122,
                  specialComponent: (index, columnIndex)=>{
                    return (
                      <div>
                        <SpecialInputForModal
                          days = {days}
                          index={index}
                          columnIndex={columnIndex}
                          color= {values[index].Sun === dataForCheckValues[index].Sun ? '' : '#3cb950'}
                          noBorder
                          withOpacity
                          width='100%'
                          type={(index === 0 || index === 1 || index === 2) ? 'number' : 'text'}
                          withoutFormik={true}
                          onChange={setFieldValue}
                          values = {values}
                          value={values[index].Sun}
                          name={`[${index}]Sun`}
                        />
                      </div>
                    );
                  },
                },
              ];
              return(
                <div>

                  <Row>
                    <Col width = '440px'>
                      <div style={{ margin: '35px 20px 10px 20px' }}>
                        <TableGrid
                          withSearch
                          //searchValue={this.state.searchValue}
                          //onSearch={this.onSearch}
                          //getSortField={this.getSortField}
                          height={690}
                          tableData={[
                            {
                              name: t('common.number'),
                              dataBase: 'productNr',
                              relativeWidth: 0.2,
                            },
                            {
                              name: t('common.description'),
                              dataBase: 'description',
                            },
                          ]}
                          data={defaultDataDB || []}

                          changeSelectId={(item, index)=>changeSelectId(item, values, index)}
                          linkId={id}
                          selectById
                        />
                      </div>
                    </Col>
                    <Col width='880px' tabindex='30'>
                      <div style={{ margin: '17px 20px 20px 0px' }}>
                        <Row>
                          <Col lg={4}>
                            <TextOfUnderline onClick={()=>{
                              onRefresh(resetForm);
                            }}>
                              RESET TO DEFAULT
                            </TextOfUnderline>
                          </Col>
                        </Row>

                        <Row>
                          <Col width='100%'>
                            <TableGrid
                              withoutBorderColumn= {true}
                              isBorderTopRow = {true}
                              //searchValue={this.state.searchValue}
                              //onSearch={this.onSearch}
                              //getSortField={this.getSortField}
                              withScroll
                              withoutLeftTableBlock
                              height={540}
                              name={t('common.quantityDiscount')}
                              tableData={tableData}
                              //getTableIndex={this.indexOnClickTable}
                              rowCount={count + 3}
                              data={dataForTable || []}
                            />
                          </Col>
                        </Row>
                      </div>

                    </Col>

                  </Row>
                  <Footer>
                    <Col width='900px'/>
                    <Col width='420px'>
                      <Row>
                        <Col width='190px'>
                          <div>
                            <Button
                              float='right'
                              className={'grey-btn'}
                              width={'190px'}
                              onClick={()=> onClose()}
                              value={t('common.cancel')}/>
                          </div>
                        </Col>
                        <Col width='230px'>
                          <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <Button
                              errText={textError}
                              float='right'
                              className={'black'}
                              width={'190px'}
                              onClick={()=>{
                                onSave(values);
                              }}
                              value={t('common.save')}/>
                          </div>
                        </Col>
                      </Row>

                    </Col>
                  </Footer>
                </div>

              )}}
</Formik>: <div/>
      }
    </div>
  );
};

export default Index;

const Footer = styled(Row)`
  margin-top: 20px;
  bottom: 0;
`;

const TextOfUnderline = styled.div`
  /* font-family: Roboto-Medium; */
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3c445a;
  text-decoration: underline;
  padding-bottom: 5px;

`;
