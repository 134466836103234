import gql from 'graphql-tag';

export default gql`
mutation updateFA_AusnahmeTag($id: Int, $fields: inputFA_AusnahmeTag!){
    fakturaAssist{
		updateFA_AusnahmeTag(id: $id, fields: $fields){
		id
    }
    
  }
}`;
