import React from 'react';
import styled from 'styled-components';

const LoginInput = (props) =>{
  const { errMessage, errField } = props.err;
  return (
    <Body>
      <Input errField = {errField} {...props}/>
      <ErrText> {errField === props.name ? errMessage : ''}</ErrText>
    </Body>

  );
};
export default LoginInput;

const Input = styled.input`
width: 300px;
display: flex;
border: none;
height: 40px;
font-size: 16px;
color: #434343 !important;
background-color: #ffffff !important;
border-bottom: ${(props) => (props.errFiled === props.name ? '1px solid #dd5860' : 'solid 1px #ffffff')} ;
margin: 0px auto;
margin-bottom: 24px;
border: 1px solid #E6E6E6;
box-sizing: border-box;
border-radius: 5px;
padding-left: 20px;
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
	    transition: background-color 5000s ease-in-out 0s;
	}
  &::placeholder {
    font-family: 'Montserrat', sans-serif;
  }
`;

const ErrText = styled.span`
	visibility: visible;
    border: 0;
    color: #dd5860;
    background: unset;
    width: 100%;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    top: 8px;
    left: 100%;
    text-align: inherit !important;
    @media (max-width: 1024px) {
    	position: relative;
    	top: -12px;
    	left: 0%
    }
`;

const Body = styled.div`
	position: relative;
`;
