import React, { useState, useEffect } from 'react';
import { compose, branch } from 'recompose';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Filter from '../elements/Filter/';

import withLink from './hoc/withLink';
import withFilter from './hoc/withFilter';
import withMutation from './hoc/withMutation';
import withMainLoading from './hoc/withMainLoading';
import withParams from './hoc/withParams';
import withCache from './hoc/withCache';

import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import MainList from '../mainList';
import AdditionalSection from './AdditionalSection';
import AdditionalStaticHeader from './AdditionalStaticHeader';
import ActionsList, { ActionsListBlock } from 'components/actionsList/ActionsList';
import withPDFActions from 'components/viewPDF/withPDFActions';

import SubSection from './SubSection';

import Menu from '../Menu/Menu';
import { Text } from '../elements/index';

const MainSection = (props) => {
  const { link, Handler, StaticHeader, valuesForHeader, name } = props.sectionInfo;
  const { sectionInfo, subSectionInfo, linkId, contex, writeContex } = props;

  const [t] = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmedFilterInfo, setConfirmedFilterInfo] = useState({
    'customers': JSON.parse(localStorage.getItem('customers')) || [],
    'products': JSON.parse(localStorage.getItem('products')) || [],
    'procurement/suppliers': JSON.parse(localStorage.getItem('procurement/suppliers')) || [],
    'procurement/goods': JSON.parse(localStorage.getItem('procurement/goods')) || [],
    'production/edit': JSON.parse(localStorage.getItem('production/edit')) || [],
  });

  const addItemToSelected = (item) => {
    if (selectedItems.indexOf(item) === -1) setSelectedItems([...selectedItems, item]);
    else {
      const splicedItems = [...selectedItems];
      splicedItems.splice(splicedItems.findIndex((i) => i === item), 1);
      setSelectedItems(splicedItems);
    }
  };

  const setFilterInfo = (filter) => {
    writeContex({ ...contex, filter: [...filter] });
  };

  const clearSelectedItems = () => setSelectedItems([]);

  const [width, setWidth] = useState(0);

  useEffect(()=>{
    setWidth(document.getElementById('addition_section') && document.getElementById('addition_section').offsetWidth);
  }, [document.getElementById('addition_section'), link]);

  useEffect(() => {
    const currentFilterKey = Object.keys(localStorage).find((key) => (key === props.mainsection) || (key === `${props.mainsection}/${props.subsection}`));

    if (currentFilterKey) {
      setFilterInfo(JSON.parse(localStorage.getItem(currentFilterKey)));
    }
  }, [name]);

  let menuObj;

  //Special section render
  if(Handler) {
    return (
      <Main>
        <Header t={props.t} sectionsPaths={props.sectionsPaths} mainsection={props.mainsection}/>
        <HandlerBlock>
          <Handler {...props} t={t} linkId={+linkId} contex={contex} writeContex={writeContex}/>
        </HandlerBlock>
        <Footer signOut={props.signOut}/>
      </Main>
    );
  } else{
    menuObj = (data)=> {
      const arr = [];
      for(const item of sectionInfo.subSection) {
        if(item.skip && (typeof data === 'object') && !item.skip(data, props)) {
          continue;
        }else{
          arr.push({
            link: `/${link}/${item.link}${linkId ? ('/' + linkId) : ''}`,
            name: item.name,
            col: item.col,

          });
        }
      }

      return arr;
    };
    //Check on special menu in subsection info
    if(subSectionInfo.specialMenuObj) {
      menuObj = (data) => (subSectionInfo.specialMenuObj.map((item, index)=>{
        return {
          link: `/${link}/${item.link}${linkId ? ('/' + linkId) : ''}`,
          name: item.name,
          col: item.col,
        };
      }));
    }

    const ActionRender = () => {
      const actionsInfo = sectionInfo.actions && sectionInfo.actions.reduce((val, item) => {
        const object = { ...val, [item]: () => console.log(1) };
        return object;
      }, {});
      return actionsInfo;
    };

    return (
      <Main>
        <Header t={props.t} sectionsPaths={props.sectionsPaths} mainsection={props.mainsection}/>
        <ClientList id='Main_List'>
          {!subSectionInfo.withoutItems &&
                      <MainList
                        {...props.sectionInfo}
                        {...props}
                        filterData={props.sectionInfo.filterData}
                        FilterComponent={
                          <StyledFilter
                            t={t}
                            sectionInfo={props.sectionInfo}
                            mainsection={props.mainsection}
                            filterData={props.sectionInfo.filterData}
                            setFilterInfo={setFilterInfo}
                            filterInfo={contex && contex.filter}
                            graphqlParams={props.sectionInfo && props.sectionInfo.filterQuery}
                            isOpenFilter={props.isfilterButton}
                            setOpenFilter={props.changeFilter}
                            confirmedFilterInfo={confirmedFilterInfo}
                            setConfirmedFilterInfo={setConfirmedFilterInfo}
                            parentHandler='Main_List'
                          />}
                        clearSelectedItems={clearSelectedItems}
                        selectedItems={selectedItems}
                        addItemToSelected={props.sectionInfo.multipleSelect && addItemToSelected}
                        contex={contex}
                        writeContex={writeContex}
                        onSearch={props.changeSearchString}
                      />}
        </ClientList>
        <Content withoutItems = {subSectionInfo.withoutItems}>
          <AdditionMain id='addition_section'>
            {/* don't show right part when left grid is empty */
              ((!props.data) || (props.data.length > 0)) && <AdditionalSection {...props} {...sectionInfo}>
                {({ data, loading })=>{
                  return (
                    <>

                      <HeaderMenu width={(width + 2) + 'px'} >{sectionInfo.defaultPDFParams && <StyledActions>
                        <ActionsList
                          actions={ActionRender()}
                          pdfParams={sectionInfo.defaultPDFParams && sectionInfo.defaultPDFParams((selectedItems.length > 0 && selectedItems) || linkId)}
                          pdfLink=''
                          selectedItems={selectedItems}
                          reportForms = {sectionInfo.reportForms}
                          t={t}
                          {...props}
                        />
                      </StyledActions>}
                      <Row>
                        <Text
                          text={props.sectionInfo.textHead ? (data[props.sectionInfo.textHead] || '\u00A0') : '\u00A0'}
                          marginBottom='4px'
                          marginTop='10px'
                          fontSize='20px'
                          color='#3c445a'
                        />
                      </Row>
                      <div>
                        <Menu menuObj={menuObj(data)}/>
                      </div>
                      </HeaderMenu>
                      <Row><AdditionalStaticHeader values={valuesForHeader || {}} data={data}>
                        {(info)=>{
                          return(
                            <Row>
                              {StaticHeader && <StaticHeader loading={loading} {...info}/>}

                              <SubSection {...props} {...info} loading={loading} data={data} contex={contex} writeContex={writeContex}/>

                            </Row>
                          );
                        }
                        }
                      </AdditionalStaticHeader>
                      </Row>

                    </>
                  );
                }}
              </AdditionalSection>
            }
          </AdditionMain>
        </Content>
        <Footer signOut={props.signOut}/>
      </Main>
    );
  }
};

export default compose(
  withLink,
  withParams,
  withCache,
  withFilter,
  branch(
    (props) => !props.sectionInfo.withoutMutation,
    withMutation,
  ),
  withMainLoading,
  withPDFActions,
)(MainSection);

const AdditionMain = styled.div`
width: 100%;
  margin: 65px 20px 0 20px;
`;

const StyledActions = styled(ActionsListBlock)`
  margin-right: 0px;
  margin-top: 30px;
`;

const StyledFilter = styled(Filter)`
  margin-top: -4px !important;
`;

const HeaderMenu = styled('div')`
  position: fixed;
  top: 60px;
  background: #f7fdff;
  height: 80px;
  margin-left: -2px;
  width: ${(props)=> props.width};
  z-index: 3;
  padding: 35px 0 0 0;
`;

export const Main = styled.div`
width: 100%;
height: 100vh;
position: relative;
background: #f8fdff;
margin: auto;
padding: 105px 0 80px 0;
overflow-y: auto;

`;
//fixed overwlow-x on visible (old - hidden) - please testing
const Content = styled.div`
overflow-x: hidden;
width: ${({ withoutItems })=> withoutItems ? '99.388889%' : '78.9%'};

display: block;
float: left;
padding: 0px 20px 0 20px;
`;

const ClientList = styled.div`
width: 20.1388889%;
min-height: 1px;
display: block;
float: left;
padding-left: 20px;
`;

const HandlerBlock = styled.div`
display: block;
float: left;
padding: 0px 20px 0 20px;
width: 100%;
`;
