import React, { useState } from 'react';
import { useMutation } from 'components/graphql';
import orderBy from 'lodash/orderBy';
import { cloneDeep } from 'lodash';
import { ConfirmModal } from '../../../main/order/editOrder/modals';
import { Modal } from '../../elements';

const withMutationCreate = (WrappedComponent) => (props) => {
  const { defaultCreationArgs, listQuery, createItem, deleteItem, deleteModalText } = props.sectionInfo || {};
  const [createItemMutation] = useMutation(createItem);
  const [deleteItemMutation] = useMutation(deleteItem);
  const { client, contex, sortObject, mainsection, subsection, history, t, linkId } = props;
  const [isConfirmModalDeleteItem, setConfirmModalDeleteItem] = useState(false);

  // console.log({ props })

  const updateCache = (createdItem) => {
    const queryName = listQuery.definitions[0].name.value;
    const defaultList = cloneDeep(client.readQuery({
      query: listQuery,
      variables: contex,
    }).fakturaAssist[queryName]);
    // console.log({ defaultCreationArgs})
    const updatedList = orderBy([...defaultList, { ...createdItem, ...defaultCreationArgs }], [sortObject.column], [sortObject.order?.toLowerCase()]);
    // console.log(updatedList);
    client.writeQuery({
      query: listQuery,
      variables: contex,
      data: {
        fakturaAssist: {
          [queryName]: updatedList,
          __typename: 'FA_Query',
        },
      },
    });
  };

  const updateCacheAfterDelete = (deletedItem) => {
    const queryName = listQuery.definitions[0].name.value;
    const defaultList = cloneDeep(client.readQuery({
      query: listQuery,
      variables: contex,
    }).fakturaAssist[queryName]);
    // console.log({ defaultCreationArgs})
    const itemIdx = defaultList.findIndex((item) => item.id === deletedItem.id);
    const nextItem = defaultList[itemIdx + 1] || defaultList[itemIdx - 1];
    defaultList.splice(itemIdx, 1);
    const updatedList = orderBy(defaultList, [sortObject.column], [sortObject.order?.toLowerCase()]);
    // console.log(updatedList)
    client.writeQuery({
      query: listQuery,
      variables: contex,
      data: {
        fakturaAssist: {
          [queryName]: updatedList,
          __typename: 'FA_Query',
        },
      },
    });
    return nextItem;
  };

  const handleItemCreate = async (variables = { args: defaultCreationArgs }) => {
    try {
      const queryName = createItem.definitions[0].name.value;
      const result = await createItemMutation({ variables });
      const data = result.data.fakturaAssist[queryName];
      const id = data.id;
      updateCache(data);
      history.push(subsection ? `/${mainsection}/${subsection}/${id}` : `/${mainsection}/${id}`);
    } catch (e) {
      console.log(e.message);
    }
  };

  const openDeleteModal = () => {
    setConfirmModalDeleteItem(true);
  };

  const onConfirmDeleteItem = async () => {
    try {
      const queryName = createItem.definitions[0].name.value;
      const result = await deleteItemMutation({ variables: { args: { id: +linkId } } });
      const data = result.data.fakturaAssist[queryName];
      const nextItem = updateCacheAfterDelete(data);
      setConfirmModalDeleteItem(false);
      if (nextItem) {
        history.push(subsection ? `/${mainsection}/${subsection}/${nextItem.id}` : `/${mainsection}/${nextItem.id}`);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <>
      {deleteItem && (
        <Modal
          isOpen={isConfirmModalDeleteItem}
          closeModal={() => setConfirmModalDeleteItem(false)}
          headerText={t('common.confirm')}
          width='450px'
          height='220px'
          Component={
            <ConfirmModal
              t={t}
              closeModal={() => setConfirmModalDeleteItem(false)}
              onConfirm={onConfirmDeleteItem}
              text={t(deleteModalText)}
            />
          }
        />
      )}
      <WrappedComponent {...props} handleItemCreate={handleItemCreate} openDeleteModal={deleteItem && openDeleteModal}/>;
    </>
  );
};

export default withMutationCreate;
