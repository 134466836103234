import React from 'react';
import styled from 'styled-components';

import closeWindowImg from 'style/img/filter/closeWindow.svg';

import { Button, Text } from 'components/elements';

const FilterOrder = (props) => {
  const { children, onConfirm, onClose, buttonWidth, buttonJustifyContext, t, isButtonDisabled } = props;
  return (
    <Main>
      <Filters>
        <TitleBlock>
          <Text text={t('common.individualFilter')} fontSize='18px' fontWeight={500}/>
          <CloseImg src={closeWindowImg} alt='close' onClick={onClose}/>
        </TitleBlock>
        <ContentBlock>
          {children}
        </ContentBlock>
      </Filters>
      <ButtonsBlock buttonJustifyContext={buttonJustifyContext}>
        <ButtonStyle disabled={isButtonDisabled || false} width={buttonWidth || '100%'} value={t('common.confirm')} upperCase className='black' onClick={onConfirm}/>
        <ButtonStyle width={buttonWidth || '100%'} value={t('common.clear')} upperCase onClick={onClose} className='transparent'/>
      </ButtonsBlock>
    </Main>
  );
};

export default FilterOrder;

const ButtonsBlock = styled.div`
width: 100%;
${({ buttonJustifyContext }) =>{
    if(buttonJustifyContext) {
      return (
        `display: flex;
        justify-content: ${buttonJustifyContext};
    `
      );
    }
  }}


`;

const ButtonStyle = styled(Button)`
    margin-top: 20px;
    display: -webkit-inline-box;
    opacity: ${({ disabled }) => disabled ? '.3' : 1};
`;

const Main = styled.div`
    height: 95%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Filters = styled.div`
  height: 100%;
  overflow: scroll;
  position: relative;
  padding-bottom: 50px;
`;

const TitleBlock = styled.div`
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    height: 50px;
    border-bottom: 1px solid #fff;
`;
const ContentBlock = styled.div`
    margin-top: 5px;
`;

const CloseImg = styled.img`
    margin-bottom: 25px;
    cursor: pointer;
`;


