
import gql from 'graphql-tag';

export default gql`
mutation createFA_Discount($fields: FA_DiscountField!){
    fakturaAssist{
    createFA_Discount(fields: $fields){
		id
		name
    }
    
  }
}`;
