import gql from 'graphql-tag';

export default gql`
query getFA_Customer($id: ID! ){
    fakturaAssist{
    getFA_Customer(id: $id){
		id
	    descriptionFaktura
		customerNr
		salutation
		nameId
		country
		deliveryNoteSalutation
		deliveryNoteRoad
		deliveryNotesName
		deliveryNoteMailbox
		deliveryNotePLZ
		deliveryNoteOrt
		deliveryNoteAddition
		collectingInvoice
		surName
		street
		postCode
		city
		phoneG
		memoLieferschein
		phoneP
		phoneG2
		fax
		email
		emailForInvoice
		internet
		additive
		nameCustomer
		nameCustomerDisp
		contactPerson
		postOfficeBox
		memoDeliveryNote
		memoDeliveryNote2
		deliveryNote
		debit
		returnLimit


		natel
		sAPOperationNr
		customPaymentText
		setupStation
		setupStationSa
		setupStationSu
		tourPlanDescription
		deliveryCountry

		tourPlanOrder
		tourPlanOrderSa
		tourPlanOrderSu
		linkTourPlan
		linkTourPlanSa
		linkTourPlanSu
		linkPostSector
		yieldAccount
		debitorAccount
		discountDays
	    discountCode
	    linkQuantitiesDiscount

		currencyID
		MWStSystem
		articlePriceGroup
	    linkBank
		invoiceReportID
	    invoiceSummaryReportID
	    invoicePaymentSlipReportID
	    fakturaAccounting
	    debitorStatus
	    invoiceCopies
		linkGroup
		correspondenceLanguage
		defaultDeliveryId
		deliveryNoteCopies
		customerAddressKindID
		payment
		changeNo
		orderReportID
		linkLSGroupe
		deliverySplittingParts
		cashAssistPrice
		timePlan
		timePlanSu
		timePlanSa
		discountInPercent
	    discountInPercent1
	    discountInPercent2
	    discountInPercent3
	    discountInPercent4
	    discountInPercent5
	    discountInPercent6
	    discountInPercent7
	    discountInPercent8
	    discountInPercent9
	    discountInPercent10
	    discountInPercent11
	    discountInPercent12
	    discountInPercent13
	    discountInPercent14
	    discountInPercent15
	    discountInPercent16
	    discountInPercent17
	    discountInPercent18
	    discountInPercent19
	    discountInPercent20

	    specialCADiscount
	    descriptionFaktura2
	    alwaysTourSchedule
		isActivated
		isMonday
		isTuesday
		isWednesday
		isThursday
		isFriday
		isSaturday
		isSunday
		isCashAssistKredit
		vorgabeAuto
		flexibleOrders
		enabledDeliverySplitting
		useAnotherLngDescriptions

		bankName1
		bankIBAN1
		mandatNumber1
		bankBLZ1
		bankKonto1
		bankBIZ1
		mandatSignatureDate1
		bankName2
		bankIBAN2
		mandatNumber2
		bankBLZ2
		bankKonto2
		bankBIZ2
		mandatSignatureDate2

		toolboxDeliveryNo1
		toolboxDeliveryNo2
		toolboxDeliveryNo3
		toolboxDeliveryNo4
		toolboxDeliveryNo5
		toolboxDeliveryNo6
		toolboxDeliveryNo7
		toolboxDeliveryNo8
		toolboxDeliveryNo9
		toolboxDeliveryNo10
		toolBoxUseOwnNrInSplitting
		isSpecialCustomer
		linkStandardOrder
		__typename
    }

  }
}`;
