import React, { useState } from 'react';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import reduce from 'helpers/reduceGraphqlData';
import { useMutation } from 'components/graphql';

import callFA_ReportSystem from './qql/callFA_ReportSystem';
import checkFA_ReportStatus from './qql/checkFA_ReportStatus';
import getReport from './qql/getReport';

import ViewPDF from './ViewPDF';
import { encodeToBase64 } from 'helpers/encryption.js';

import { Modal } from 'components/elements';
import sendPDFToPrint from 'components/elements/ReportSystemInput/qql/mutation/sendPDFToPrint/';
import PrinterModal from './modals/PrinterModal';
import EmailModal from './modals/EmailModal';
import ReportFormModal from './modals/ReportFormModal';
import LoadingModal from 'components/Loading/LoadingModal';
// import useQuerySpecial from 'components/graphql/useQuerySpecial';

const withPDFActions = (WrappedComponent) => (props) =>{
  //Full link in pdf
  const [callReportSystem, { loading, error }] = useMutation(callFA_ReportSystem);
  const [M_getReport] = useMutation(getReport);
  const [callCheckFA_ReportStatus] = useMutation(checkFA_ReportStatus);
  const [pdfLoading, setPDFLoading] = useState(false);
  const [link, setPDFLink] = useState('');
  const [email, setEmail] = useState('nechyporchuk@ukr.net');
  const [status, setStatus] = useState('Sending');
  const [isOpenPrinterModal, setOpenPrinterModal] = useState(false);
  const [isOpenEmailModal, setOpenEmailModal] = useState(false);
  const [cachePDFParams, setCachePDFParams] = useState({});
  const [avaliableReportForms, setAvaliableReportForms] = useState([]);
  const [isOpenReportForms, setOpenReportForms] = useState(false);
  // const [id, setId] = useState(null);
  
  // const allFormsDefault = useQuerySpecial(listFA_DefaultForms, { fetchPolicy: 'cache-first' });
  // const allFormsUser = useQuerySpecial(listFA_UserForms, { fetchPolicy: 'cache-first' });
  const [Mutation_sendPDFToPrint] = useMutation(sendPDFToPrint);
  // const allForms = useQuerySpecial(listFA_Forms, { fetchPolicy: 'cache-first',
  //   variables: {
  //     languageId: +localStorage.getItem('lngId') || 1,
  //   } });

  // const getSelectPrinterInfo = () =>{
  //   const selectForm = allForms.data.filter((elem)=>{
  //     if(id) {
  //       return elem.id === id;
  //     } else{
  //       return elem.id === defaultValueField;
  //     }
  //   });

  //   if(selectForm[0]) {
  //     if(selectForm[0].formId) {
  //       return(allFormsUser.data.filter((elem)=>
  //         elem.id === selectForm[0].formId
  //       )[0]);
  //     } else{
  //       return(allFormsDefault.data.filter((elem)=>
  //         elem.id === selectForm[0].formIdDefault
  //       )[0]);
  //     }
  //   }
  // };

  const toStandartDates = (obj) =>{
    const APeriodEnd = moment(obj.APeriodEnd).format('DD.MM.YYYY');
    const APeriodStart = moment(obj.APeriodStart).format('DD.MM.YYYY');
    return { APeriodEnd: APeriodEnd, APeriodStart: APeriodStart };
  };

  const checkReportStatus = async (id) => {
    const reportInfo = await callCheckFA_ReportStatus({ variables: { taskId: +id } });
    const reportInfoData = reportInfo.data.fakturaAssist.checkFA_ReportStatus;
    return reportInfoData;
  };

  const delay = async (ms) => {
    return await new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  };
  
  const showPDFPreview = async (pdfParams, pdfLink) => {
    //Static pdf link from db
    pdfParams = { ...pdfParams,
      //languageId: 2,
      ...toStandartDates({ APeriodEnd: pdfParams.APeriodEnd, APeriodStart: pdfParams.APeriodStart }) };
    if(pdfLink) {
      setPDFLink(pdfLink.toLowerCase());
    } else {
      const params = {
        pdfParams: pdfParams,
      };

      setStatus('Generate PDF');
      //Future loading screen
      setPDFLoading(true);
      // const idsForNewReports = [
      //   // 'orderPreview',
      //   // 'offerPreview',
      //   // 'invoicePreview',
      //   // '1',
      //   // '3',
      //   // //'4',≈
      //   // '5',
      //   // '6',
      //   // //'8',
      //   // //'9',
      //   // '14',
      //   // '15',
      //   // //'16',
      //   // //'17',
      //   // //'19',
      //   // '20',
      //   // //'21',
      //   // '25',
      //   // '26',
      //   // '27',
      //   // //'29',
      //   // //'30',
      //   // //'31',
      //   // '32',
      //   // '34',
      //   // '36',
      //   // //'37',
      //   // //'38',
      //   // //'39',
      //   // //'40',
      //   // '41',
      //   // '42',
      //   // //'43',
      //   // //'44',
      //   // // '45',
      //   // //'48',
      //   // '50',
      //   // //'52',
      //   // //'55',
      //   // '57',
      //   // //'62'
      // ];
      //52, 39,21,17
      if(params.pdfParams.isServerlessReport) {
        const newParams = {
          ...params.pdfParams,
          reportId: params.pdfParams.reportId.split(' ').join(''),
        };

        delete newParams.isServerlessReport;

        const data = await M_getReport({ variables: { params: newParams } }).catch((err)=>{
          // console.log(err);
          setPDFLoading(false);
          setStatus(err.toString());
        });
        // console.log(data);
        // console.log(Storage);
        Storage.get(data?.data?.reports.getReport.body).then((result) => { // <----- CHANGE IS HERE
          // console.log(`public/${data?.data?.reports.getReport.body}` );
          setPDFLoading(false);
          setPDFLink(result);
        }).catch((err)=>{
          setPDFLoading(false);
          setStatus(err.toString());
        });
        // setPDFLink(reduceData.link);
      } else{
        const data = await callReportSystem({ variables: { params: params } });
        setStatus('Check PDF status');
        const reduceData = reduce(data.data);

        const taskId = reduceData._taskids[0];
        if(error) {
          setPDFLoading(false);
          setStatus(error.toString());
        }
        const checkStatus = async (taskId) => {
          const reportInfoData = await checkReportStatus(taskId);
          if(reportInfoData.state.id === 2) {
          // ready
            setPDFLoading(false);
            setPDFLink(reduceData.link);
          } else if(reportInfoData.state.id === 5) {
          // n progress
            setStatus(reportInfoData.state.name);
            checkStatus(taskId);
          } else {
          // error
            setStatus(reportInfoData.state.name);
            await delay(2000);
            setPDFLoading(false);
          }
        };
        checkStatus(taskId);
      }
      // //Async timeout
      // setStatus('Loading');
      // await delay(3000);
      // setPDFLoading(false);
      // setPDFLink(reduceData.link);
    }
  };

  // const sendPDFIntoPrinter = async (pdfParams, pdfLink) => {
  //   const params = {
  //     pdfParams: pdfParams,
  //     isAutoPrint: true,
  //   };
  //   setOpenPrinterModal(true);
  //   await callReportSystem({ variables: { params: params } });
  //   setOpenPrinterModal(false);
  // };

  const sendPDFIntoPrinter = async (pdfParams, printerInfo) =>{
    pdfParams = { ...pdfParams, ...toStandartDates({ APeriodEnd: pdfParams.APeriodEnd, APeriodStart: pdfParams.APeriodStart }) };
    setPDFLoading(true);
    setStatus('loading');
    const info = printerInfo;
    const actualPdfParams = pdfParams;
    // actualPdfParams.reportId = info?.defaultFormID ? `${info.defaultFormID}` : `${info?.id}`;
    actualPdfParams.reportId = `${actualPdfParams.reportId}`;
    const params = {
      pdfParams: actualPdfParams,
    };
    setStatus('Upload PDF');
    await callReportSystem({ variables: { params: params } }).then((db)=> {
      const reduceData = db.data.fakturaAssist.callFA_ReportSystem;
      //
      setStatus('Check PDF status');

      const taskId = reduceData._taskids[0];
      const checkStatus = async (taskId) => {
        const reportInfoData = await checkReportStatus(taskId);
        if(reportInfoData.state.id === 2) {
        // ready
          setStatus(`Send to print ${info.printer}`);
          Mutation_sendPDFToPrint({
            variables: {
              PrinterName: info.printer,
              TrayID: `${info.printerTray}`,
              Downloadlink: `${db.data.fakturaAssist.callFA_ReportSystem.link}`,
              Copies: `${info.copies}`,
            },
          }).then((db)=> {
            if(db.data.fakturaAssist.sendPDFToPrint && db.data.fakturaAssist.sendPDFToPrint.State) {
              if(db.data.fakturaAssist.sendPDFToPrint.State === 'Ready') {
                setStatus('Print completed successfully');
                setTimeout(() => setPDFLoading(false), 3000);
              } else{
                setStatus('Print error 1');
                setTimeout(() => setPDFLoading(false), 3000);
              }
            } else{
              setStatus('Print error 2');
              setTimeout(() => setPDFLoading(false), 3000);
            }
          }).catch((err)=>{
            console.log(err);
            setStatus('Print error 3');
            setTimeout(() => setPDFLoading(false), 3000);
          });
        } else if(reportInfoData.state.id === 5) {
        // n progress
          setStatus(reportInfoData.state.name);
          checkStatus(taskId);
        } else {
        // error
          setStatus(reportInfoData.state.name);
          await delay(3000);
          setPDFLoading(false);
        }
      };
      checkStatus(taskId);
      ///
    }).catch((err)=>{
      setStatus('Error upload PDF');
      setTimeout(() => setPDFLoading(false), 3000);
    });
  };

  const sendPDFIntoEmail = async (pdfParams, defaultEmail) => {
    pdfParams = { ...pdfParams, ...toStandartDates({ APeriodEnd: pdfParams.APeriodEnd, APeriodStart: pdfParams.APeriodStart }) };
    setCachePDFParams(pdfParams);
    if(defaultEmail) {
      setEmail(defaultEmail);
    }
    setOpenEmailModal(true);
    
    // const params = {
    //   pdfParams: pdfParams,
    //   email: email,
    // };
    // await callReportSystem({ variables: { params: params } });
  };

  //When user click OK in Send PDF email modal
  const acceptSendPDFIntoEmail = async ({ subject, email, message }) =>{
    const copyParams = cachePDFParams;
    if(email) {
      copyParams.ASubject = encodeToBase64(subject);
      copyParams.AMessage = encodeToBase64(message);
      // copyParams.AMessage = subject;
      // copyParams.ASubject = message;
    }
    const params = {
      pdfParams: copyParams,
      email: email,
    };
    await callReportSystem({ variables: { params: params } });
    setOpenEmailModal(false);
    //Clear all cache
    setEmail('');
    setCachePDFParams({});
  };

  const showFormsType = (pdfParams, avaliableReportFormsArray) => {
    pdfParams = { ...pdfParams, ...toStandartDates({ APeriodEnd: pdfParams.APeriodEnd, APeriodStart: pdfParams.APeriodStart }) };
    setCachePDFParams(pdfParams);
    setAvaliableReportForms(avaliableReportFormsArray);
    setOpenReportForms(true);
  };

  return (
    <div>
      <ViewPDF link ={link} cleanLink = {() =>setPDFLink('')} />
      <Modal isOpen={isOpenEmailModal } closeModal = {() =>setOpenEmailModal(false)} headerText='Email' width='670px' height='550px' Component = { <EmailModal {...props} onChange= {(e)=> {
        setEmail(e.target.value)
        ;
      }} email = {email} closeModal = {() =>setOpenEmailModal(false)} acceptSendPDFIntoEmail={acceptSendPDFIntoEmail}/> }/>
      <Modal background='none' width='350px' height='220px' isOpen ={ loading || pdfLoading} Component = {<LoadingModal status={status}/>}/>
      <Modal isOpen={isOpenPrinterModal} closeModal = {() =>setOpenPrinterModal(false)} headerText='Print sending...' width='350px' height='220px' Component = { <PrinterModal closeModal = {() =>setOpenPrinterModal(false)}/> }/>
      
      <Modal isOpen={isOpenReportForms} closeModal = {() =>setOpenPrinterModal(false)} headerText='Peport type' width='680px' height='250px' Component = { <ReportFormModal zIndex={10} closeModal = {() =>setOpenReportForms(false)} avaliableReportForms={avaliableReportForms} cachePDFParams={cachePDFParams} showPDFPreview={showPDFPreview}/> }/>
      <WrappedComponent {...props} link={link} setPDFLink={setPDFLink} showPDFPreview={showPDFPreview} sendPDFIntoPrinter={sendPDFIntoPrinter} sendPDFIntoEmail={sendPDFIntoEmail} showFormsType={showFormsType}/>
    </div>
  );
};
export default withPDFActions;

