import gql from 'graphql-tag';

export const LIST_ASSORTMENT = gql`
query listCompareFa_AssortmentProduct($id: Int){
    fakturaAssist{
        listCompareFa_AssortmentProduct(id: $id){
		id
		name
		open
    }
    
  }
}`;

export const CHANGE_ASSORTMENT = gql`
mutation changeCompareFa_AssortmentProduct($arrId: [Int], $productId: Int){
    fakturaAssist{
        changeCompareFa_AssortmentProduct(arrId: $arrId, productId: $productId){
		name
    }
    
  }
}`;
