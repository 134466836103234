import React from 'react';
import styled from 'styled-components';

import TableGrid from 'components/tableGrid/TableGrid.js';
import listFA_ProductionFermentation from './qql/listFA_ProductionFermentation';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const tableData = [
    {
      name: t('common.articleNo'),
      dataBase: 'articleNo',
      relativeWidth: 0.1,
    },

    {
      name: t('common.description'),
      relativeWidth: 0.5,
      dataBase: 'description',
    },
    {
      name: t('production.inStock'),
      dataBase: 'inStock',
      colorBlock: '#FEF3C5',
    },
    {
      name: t('production.ordered'),
      dataBase: 'ordered',
      colorBlock: '#FADED9',
    },
    {
      name: t('production.restock'),
      dataBase: 'reStock',
      colorBlock: '#F5DBF9',
    },
    {
      name: t('production.newInStock'),
      dataBase: 'quantity',
    },
  ];
  return (
    <HeadBlock>
      <MainBlock>
        <TableGrid
          withSearch
          graphqlParams={{
            query: listFA_ProductionFermentation,
            variables: { date: props.values.fromDate } }}
          height={700}
          tableData={tableData}
        />
      </MainBlock>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  height: 800px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 10px;
`;
