import Email from './pages/email';
import PayNet from './pages/paynet';
import FarmySH from './pages/farmysh';

import { updateFA_Config } from '../qql/mutation';

export default (t) => ({
  link: 'connections',
  subsections: [
    {
      name:  t('common.email'),
      link: 'email',
      Component: Email,
      updateMutation: updateFA_Config,
    },
    {
      name: 'PayNet',
      link: 'paynet',
      Component: PayNet,
      updateMutation: updateFA_Config,
    },
    {
      name: 'FarmySH',
      link: 'farmysh',
      Component: FarmySH,
      updateMutation: updateFA_Config,
      fields: [
        'farmyApiToken', 'farmyApiLinkCustomer',
      ],
    },
  ],
  
});
