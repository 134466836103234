import gql from 'graphql-tag';

export default gql`
 query listFA_Goods($params: ListQueryArgs, $filter: [FA_GeneralFilter]){
    fakturaAssist {
        listFA_Goods(params: $params, filter: $filter) {
            id
            articleNr
            name
            outOfStock
        }
    }
 }
`;
