import styled from 'styled-components';
import React from 'react';
import { Checkbox } from '../../../../../components/elements';

const week = (t) => ({
  isMonday: t('common.shortDays.mon'),
  isTuesday: t('common.shortDays.tue'),
  isWednesday: t('common.shortDays.wed'),
  isThursday: t('common.shortDays.thu'),
  isFriday: t('common.shortDays.fri'),
  isSaturday: t('common.shortDays.sat'),
  isSunday: t('common.shortDays.sun'),
});

const infoDays = (info, isCustomWeekDaySetting, customInfo, t) => {
  let textDays = '';
  if (isCustomWeekDaySetting) {
    for (const key in week(t)) {
      if (customInfo[key]) {
        textDays += week(t)[key] + ', ';
      }
    }
  } else {
    for (const key in week(t)) {
      if (info[key]) {
        textDays += week(t)[key] + ', ';
      }
    }
  }
  return (textDays.slice(0, -2).length > 32 ? t('common.days.allDays') : textDays.slice(0, -2));
};

export const vacationsTable = (t) => [
  {
    name: t('common.fromDate'),
    dataBase: 'fromDate',
    relativeWidth: 0.15,
    dataType: 'date',
    isMutation: true,
  },
  {
    name: t('common.toDate'),
    dataBase: 'toDate',
    relativeWidth: 0.15,
    dataType: 'date',
    isMutation: true,
  },
  {
    name: t('common.note'),
    dataBase: 'description',
    relativeWidth: 0.7,
    isMutation: true,
  },
];

export const periodsTableData = (info, t) => [{
  name: 'Days',
  dataBase: 'id',
  relativeWidth: 0.2,
  isMutation: true,
  specialComponent: (index, columnIndex, value, data) => {
    return (
      <div>
        {infoDays(info, data[index].isCustomWeekDaySetting, data[index], t)}
      </div>
    );
  },
},
{
  name: t('common.fromDate'),
  dataBase: 'fromDate',
  relativeWidth: 0.15,
  dataType: 'date',
  isMutation: true,
},
{
  name: t('common.toDate'),
  dataBase: 'toDate',
  relativeWidth: 0.15,
  dataType: 'date',
  isMutation: true,
},
{
  name: t('common.note'),
  dataBase: 'description',
  relativeWidth: 0.15,
  // isHidden: true,
  isMutation: true,
},
{
  name: t('customer.customWeekDays'),
  dataBase: 'isCustomWeekDaySetting',
  type: 'boolean',
  isMutation: true,
  relativeWidth: 0.15,
  specialComponent: (index, columnIndex, value, data) => (
    <StyledDiv>
      <StyledCheckbox margin={'0px'} inActive value={data[index].isCustomWeekDaySetting}/>
    </StyledDiv>
  ),
},
{
  name: t('customer.repeatYearly'),
  dataBase: 'isRepeatYearly',
  type: 'boolean',
  isMutation: true,
  relativeWidth: 0.2,
  specialComponent: (index, columnIndex, value, data) => (
    <StyledDiv>
      <StyledCheckbox margin={'0px'} inActive value={data[index].isRepeatYearly}/>
    </StyledDiv>
  ),
},
{
  dataBase: 'isMonday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0.1,
}, {
  dataBase: 'isTuesday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0,
}, {
  dataBase: 'isWednesday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0,
}, {
  dataBase: 'isThursday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0,
}, {
  dataBase: 'isFriday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0,
}, {
  dataBase: 'isSaturday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0,
}, {
  dataBase: 'isSunday',
  type: 'boolean',
  isMutation: true,
  isHidden: true,
  relativeWidth: 0,
}];

const StyledDiv = styled.div`
    z-index: 10;
    padding-left: '30%';
    margin-top: '-15px';
`;

const StyledCheckbox = styled(Checkbox)`
    z-index: 10;
`;
