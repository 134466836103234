import React from 'react';
import { Checkbox } from 'components/elements';

export const tableDataSuppliers = (t) => [
  {
    name: 'Number',
    dataBase: 'suppliersNumber',
  },
  {
    name: 'Name',
    dataBase: 'nameSupplier',
  },
];
  
//SOme
  
export const tableDataGoods = (t) => [
  {
    name: 'Number',
    dataBase: 'articleNr',
  },
  {
    name: 'Description',
    dataBase: 'name',
  },
  
  {
    name: 'Stock',
    dataBase: 'outOfStock',
    relativeWidth: 0.23,
    specialComponent: (index, value)=><Checkbox margin={'0px'} zIndex={100} onClick={()=>{}} value={value} field='outOfStock'/>,
  },
];
