
import { tableData as params } from './paramss';
import moment from 'moment';
import StaticHeaderPrint from './components/StaticHeaderPrint';
import StaticHeaderEdit from './components/StaticHeaderEdit';

import listFA_ProductionEdit from './qql/listFA_ProductionEdit.js';
import getFA_ProductionEdit from './qql/getFA_ProductionEdit.js';
import getFA_ProductionEditCrossTable from './edit/table/crossTable/qql/getFA_ProductionEditCrossTable.js';
import updateFA_ProductionEditCrossTable from './edit/table/crossTable/qql/updateFA_ProductionEditCrossTable.js';

import updateFA_ProductionEdit from './qql/updateFA_ProductionEdit.js';
///print
import Production from './print/table/production';
import Delivery from './print/table/print';
import Redistribution from './print/table/redistribution';
import Fermentation from './print/table/fermentation';
import Articles from './print/table/articles';
///edit
import General from './edit/table/general';
import Filter from './edit/table/filter';
import CrossTable from './edit/table/crossTable';
import Toolbox from './toolbox';
import listFA_ProductionGroup from './edit/table/general/qql/listFA_ProductionGroup';
import createFA_ProductionEdit from './qql/createFA_ProductionEdit';

localStorage.getItem('fromDateSHProductions') ||
localStorage.setItem('fromDateSHProductions', moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
localStorage.getItem('toDateSHProductions') ||
localStorage.setItem('toDateSHProductions', moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));//*update localStorage dates to default

// const defaultPDFParams = (linkId) => {
//   return ({
//     reportId: '51',
//     AUserFormularID: '0',
//   });
// };

const filterTypeName = JSON.parse(localStorage.getItem('production/edit'));

const filterQuery = {
  query: listFA_ProductionGroup,
  variables: () => ({ languageId: +localStorage.getItem('lngId') || 1 }),
};
const valuesForHeader = {
  fromDate: localStorage.getItem('fromDateSHProductions'),
  toDate: localStorage.getItem('toDateSHProductions'),
};

const filterData = (t) => [{
  title: t('common.allPrintList'),
  cleanFilter: true,
}, {
  title: t('common.filter.activePrintList'),
  field: 'IsPrint',
  value: 1,
}, {
  title: t('common.filter.inactivePrintList'),
  field: 'IsPrint',
  value: 0,
}, {
  title: t('common.filter.printListGroups'),
  field: 'LinkGruppe',
  titleField: 'name',
  valueField: 'id',
  radio: true,
}];

export default (t) => ([{
  name: 'ProductionPrint',
  link: 'production/print',
  mainItem: 'id', //Items in top of menu
  StaticHeader: StaticHeaderPrint,
  defaultFilterParams: [],
  valuesForHeader: valuesForHeader,
  separateSection: [
    { name: t('production.production'),
      link: 'production',
      Component: Production,
      withoutItems: true,
      col: 2,
      listQuery: '',
      itemQuery: '',
    },
    { name: t('production.printDeliveryNotes'),
      link: 'delivery',
      Component: Delivery,
      listQuery: '',
      col: 2,
      withoutItems: true,
      itemQuery: '',

    },
    { name: t('production.articlesWithoutAssigntment'),
      link: 'articles',
      Component: Articles,
      listQuery: '',
      col: 3,
      withoutItems: true,
      itemQuery: '',

    },
    { name: t('production.preparationForFermentation'),
      link: 'fermentation',
      Component: Fermentation,
      listQuery: '',
      col: 3,
      withoutItems: true,
      itemQuery: '',

    },
    { name: t('production.redistributionForForwarding'),
      link: 'redistribution',
      Component: Redistribution,
      listQuery: '',
      col: 2,
      withoutItems: true,
      itemQuery: '',

    },
  ],
},
{
  name: 'ProductionEdit',
  link: 'production/edit',
  StaticHeader: StaticHeaderEdit,
  listQuery: listFA_ProductionEdit,
  itemQuery: getFA_ProductionEdit,
  tableData: params(t),
  updateItem: updateFA_ProductionEdit,
  createItem: createFA_ProductionEdit,
  deleteItem: createFA_ProductionEdit,
  deleteModalText: 'order.modals.deleteThisProduction',
  defaultCreationArgs: { name: '-' },
  operationAvailability: {
    delete: { field: 'id', check: (value) => value },
  },
  defaultFilterParams: { filter: filterTypeName || [] },
  filterData: filterData(t),
  filterQuery,
  mainItem: 'id',
  textHead: 'name',
  searchColumns: ['name'],
  sortParams: { field: 'name', order: 'ASC' },
  subSection: [
    { name: t('common.general'),
      link: 'general',
      Component: General,

    },
    { name: t('production.filters'),
      link: 'filters',
      Component: Filter,

    },
    { name: t('production.crossTableReport'),
      link: 'cross-table',
      Component: CrossTable,
      itemQuery: getFA_ProductionEditCrossTable,
      updateItem: updateFA_ProductionEditCrossTable,
      skip: (data, props)=>{
        if([11, 65].includes((Math.round(data.reportID / 1000000))) || props.location.pathname.includes('cross-table')) {
          return(true);
        } else{
          return(false);
        }
      },
    },
  ],
},
{
  name: 'ProductionToolbox',
  link: 'production/toolbox',
  mainItem: 'id', //Items in top of menu
  separateSection: [
    { name: t('production.productionToolbox'),
      link: 'toolbox',
      Component: Toolbox,
      withoutItems: true,
      itemQuery: '',
    },
  ],
}]);