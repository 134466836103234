import React from 'react';
import styled from 'styled-components';

export default (props) => {
  const { daysArray, setDaysArray, isAllDays, monthDays } = props;
  const allDays = () => {
    if(daysArray.length === monthDays) {
      setDaysArray([]);
    }else{
      setDaysArray([...Array(monthDays).keys()]);
    }
  };
  const daysList = Array(monthDays).fill(0).map((e, index)=> {
    return (
      <DayBox
        active={isAllDays || daysArray.includes(index)}
        onClick={() => {
          console.log(daysArray.includes(index));
          const arrCopy = daysArray;
          if(arrCopy.includes(index)) {
            arrCopy.splice(arrCopy.indexOf(index), 1);
            console.log(arrCopy);
          }else{
            arrCopy.push(index);
          }
          console.log(arrCopy);
          setDaysArray([...arrCopy]);
        }}>
        {index + 1}
    
      </DayBox>);
  });
  return (<div> <div onClick={allDays}> All days</div> {daysList} </div>);
};
const DayBox = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 1px;
    color: ${({ active }) => active ? '#3e4c57' : '#c3c3c3'};
    border: solid 1px ${({ active }) => active ? '#3e4c57' : '#c3c3c3'};
    display: inline-block;
    margin: 15px 15px 0px 0px;
    font-size: 12px;
    padding-top: 2px;
    font-weight: ${({ active }) => active ? 500 : 200};
    cursor: pointer;
`;
