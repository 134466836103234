import gql from 'graphql-tag';

export const GROUPS = gql`
query listFA_ProductAvailabilityGroups($linkArticle: Int) {
    fakturaAssist{
    	listFA_ProductAvailabilityGroups(linkArticle: $linkArticle){
    		id
        name
        checked
			  idChecked
 		}
 	}
}`;

export const DELETE = gql`
mutation deleteFA_ProductAvailabilityGroups($id: Int!) {
  fakturaAssist {
    deleteFA_ProductAvailabilityGroups(id: $id){
      id
    }
  }
} `;

export const CREATE = gql`
mutation createFA_ProductAvailabilityGroups($fields: FA_AvailabilityAssortmentInput!) {
  fakturaAssist {
    createFA_ProductAvailabilityGroups(fields: $fields){
      id
    }
  }
} `;

