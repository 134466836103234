import React from 'react';
import { ReactComponent as LogoSignin } from 'style/img/logo-faktura-assist.svg';
import styled from 'styled-components';

const AuthContainer = ({ children, onSubmit }) => (
  <MainAuth>
	  <form onSubmit={onSubmit}>
      <BlockAuth>
        <LogoSignin/>
        <SolidLine/>
        {children}
      </BlockAuth>
    </form>
  </MainAuth>
);

export default AuthContainer;

const MainAuth = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	background: #FAFAFA;
`;

const BlockAuth = styled.div`
	color: #fff;
	font-size: 16px;
	height: 560px;
	display: flex;
	flex-direction: column;
	align-items: center;
	& > svg {
		min-height: 191px;
	}
	@media screen and (max-width: 767px) {
		height: 600px;
	}
	span{
		display: block;
		/* font-family: 'Roboto-Light'; */
		font-size: 16px;
		font-weight: 300;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		padding-bottom: 30px;
	}
	button{
		margin-top: 30px
	}
`;

const SolidLine = styled.div`
	background: transparent;
	width: 250px;
	height: 1px;
	margin: 28px 0px;
`;

export const LinkButton = styled.div`
	cursor: pointer;
	padding: 5px;
	text-decoration: underline;
	font-weight: 300;
	font-size: 14px;
	text-align: center;
	color: #434343;
`;
