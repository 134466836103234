import ExportComponent from './Export';
import { getFA_Config } from '../qql/query';
import { updateFA_Config } from '../qql/mutation';

export default () => ({
  link: 'export',
  subsections: [],
  itemQuery: getFA_Config,
  updateMutation: updateFA_Config,
  Component: ExportComponent,
});

