import gql from 'graphql-tag';

export default gql`
query listFA_CustomersListForCreatingOrdersOnDate($filter: [FA_GeneralFilter], $date: FA_FilterDate!, $orderType: Int, $params: ListQueryArgs){
    fakturaAssist{
    	listFA_CustomersListForCreatingOrdersOnDate(params: $params, filter: $filter, orderType: $orderType, date: $date){
    		id
    		name
			customerNr
    		temporaryId
			offerId
			isAutoSpecific
			isEmptyOrder
			isHasLSOnDate
			isDelivered
 		}
 	}
}`;
