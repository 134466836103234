export const tableData = ({ totalSum, totalNetto, t }) => ([
  {
    caption: t('common.date'),
    dataField: 'date',
    localFormat: { type: 'date' },
  },

  {
    caption: t('common.customerNo'),
    dataField: 'customerNr',
  },

  {
    caption: t('common.name'),
    dataField: 'name',

  },

  {
    caption: t('common.deliveryNr'),
    dataField: 'orderNr',
  },

  {
    caption: t('common.brutto'),
    dataField: 'total',
    summary: {
      summaryType: 'sum',
    },
    localFormat: { type: 'float' },
  },

  {
    caption: t('common.netto'),
    dataField: 'totalNetto',
    summary: {
      summaryType: 'sum',
    },
    localFormat: { type: 'float' },
  },
  {
    caption: t('order.orderType'),
    dataField: 'orderTypeName',
  },
  {
    caption: t('common.user'),
    dataField: 'userFullName',
  },
  {
    caption: t('common.groupDeliveryNote'),
    dataField: 'resName',
  },
  {
    caption: t('order.customerGroup'),
    dataField: 'gruppe',
  },
]);


