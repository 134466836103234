import React, { useState } from 'react';
import { useMutation } from 'components/graphql';

import { OptionsContent } from 'style';

import { Input, Dropdown } from 'components/elements';
import Formik from 'components/formik';

import listFA_Customer from 'main/customer/qql/listFA_Customer.js';
import getFA_Customer from 'main/customer/qql/getFA_Customer.js';

import { checkFA_FarmyApiToken } from '../../qql/mutation';

export default ({ configData, onSubmit }) => {
  const [error, setError] = useState('');
  const [checkFarmyApiTokenMutation] = useMutation(checkFA_FarmyApiToken);

  const onSubmitLocal = async (values) => {
    if(!values.farmyApiToken) {
      setError('');
      onSubmit(values);
    }
    const tokenStatus = await checkFarmyApiTokenMutation({ variables: { args: { farmyApiToken: values.farmyApiToken } } });
    const { failed } = tokenStatus.data.fakturaAssist.checkFA_FarmyApiToken;
    if(failed) {
      setError('Token not valid');
      return null;
    }
    setError('');
    onSubmit(values);
  };
  return (
    <Formik
      initialValues={configData}
      enableReinitialize
      onSubmit={onSubmitLocal}
    >
      {({ handleSubmit, handleChange, values, setFieldValue, errors, touched }) =>{
        return (
          <OptionsContent>
            <>
              <Input className={error && 'error__input'}title ='FarmySH token' value={values.farmyApiToken} field={'farmyApiToken'}/>
              {error && <div style={{ color: '#c13c44', fontSize: '12px' }}><span>{error}</span></div>}
    
              <Dropdown
                graphqlParams={{
                  query: listFA_Customer,
                  variables: {} }}
                getQuery = {getFA_Customer}
                title='Customer'
                valueField = 'id'
                labelField = 'nameId'
                field = 'farmyApiLinkCustomer'
                defaultValueField = {values.farmyApiLinkCustomer}
                withSearch
              />
            </>
          </OptionsContent >
        );
      }}
    </Formik>
  );
};
