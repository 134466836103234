import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'components/elements';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import { cloneDeep, debounce } from 'lodash';

import { LIST_ASSORTMENT, CHANGE_ASSORTMENT } from '../../gql/index';

export default (props) => {
  const { id } = props;
  const { data, loading, client } = useQuery(LIST_ASSORTMENT, { variables: { id: +id }, fetchPolicy: 'cache-and-network' });
  const [MUTATION_changeCompareFa_Assortment] = useMutation(CHANGE_ASSORTMENT);

  const mutationCheckbox = () =>{
    const values = client.readQuery({
      query: LIST_ASSORTMENT,
      variables: {
        id: +id,
      },
    }).fakturaAssist.listCompareFa_AssortmentProduct;

    const arrId = [];

    for(const item of values) {
      item.open && arrId.push(item.id);
    }

    MUTATION_changeCompareFa_Assortment({ variables: {
      productId: +id, arrId: arrId,
    } });
  };

  const debouncedMutationCheckbox = useCallback(debounce(mutationCheckbox, 2000, {
    leading: false,
    trailing: true,
  }), [id]);

  const set = (index, value) =>{
    const info = client.readQuery({
      query: LIST_ASSORTMENT,
      variables: {
        id: +id,
      },
    }).fakturaAssist.listCompareFa_AssortmentProduct;

    const clone = cloneDeep(info);
    // debouncedMutationCheckbox.cancel();
    clone[index].open = value;

    client.writeQuery({
      query: LIST_ASSORTMENT,
      variables: {
        id: +id,
      },
      data: {
        fakturaAssist: {
          listCompareFa_AssortmentProduct: clone,
          __typename: 'FA_Query',
        },
      },
    });
  };

  return (
    <Main>
      {!loading && data?.fakturaAssist?.listCompareFa_AssortmentProduct?.map((item, index) => {
        return(
          <Element onClick = {()=>{
            set(index, !data.fakturaAssist.listCompareFa_AssortmentProduct[index].open);
            //debouncedMutationCheckbox.cancel();
            debouncedMutationCheckbox();
          }}>
            <Checkbox
              withoutFormik
              key={data.fakturaAssist.listCompareFa_AssortmentProduct[index].id}
              width='100%'
              margin='20px 0 0 0'
              onChange={() => {}}
              value={data?.fakturaAssist?.listCompareFa_AssortmentProduct.every((i) => !!!i.open) ? true : data.fakturaAssist.listCompareFa_AssortmentProduct[index].open}
              name={`[${index}]open`}
              title={data.fakturaAssist.listCompareFa_AssortmentProduct[index].name}
            />
          </Element>
        );
      })}
    </Main>
  );
};

const Main = styled.div`
  width: 900px;
  display: block;
 height: auto;
`;

const Element = styled.div`
  padding-top: -20px;
  width: 33.3%;
  float: left;
  height: 40px;
`;
