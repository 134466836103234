import gql from 'graphql-tag';

export default gql`
mutation updateDefOrder($fields:[CustomerDefOrder]){
    fakturaAssist{
    updateDefOrder(fields: $fields){
		note
    }
    
  }
}`;
