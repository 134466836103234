const { default: gql } = require('graphql-tag');

export const DELETE_PRODUCT_IMG = gql`
mutation deleteFA_ProductImg($id: ID!) {
    fakturaAssist {
      deleteFA_ProductImg(id: $id)
    }
  }
`;

export const UPLOAD_PRODUCT_IMG = gql`
mutation uploadFA_ProductImgBase64($file: String!, $id: ID!) {
    fakturaAssist {
      uploadFA_ProductImgBase64(file: $file, id: $id){
        id
      }
    }
  }
`;
