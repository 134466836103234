import React from 'react';
import Formik from 'components/formik';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
// import { get } from 'lodash';
import styled from 'styled-components';

import { Row, Col } from 'style/index';

import { CONFIG, Sale, Tax } from '../gql/index';
import { Dropdown, Input } from 'components/elements';

const ActualPrice = (props) => {
  const [t] = useTranslation();
  const { loading } = useQuery(CONFIG);

  // const category = get(dataConfig, 'fakturaAssist.getFA_Config.articlePriceCategory', []);

  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize
      linkId={props.linkId}
      sectionInfo={props.sectionInfo}
    >
      {({ values: { amount = [], priceUnit, taxChild } }) => {
        return (
          <React.Fragment>
            <LoadingRow loading={loading}>
              <Col lg={4}>
                <Dropdown
                  title={t('product.discountGroups')}
                  field='priceUnit'
                  valueField='id'
                  labelField='name'
                  defaultValueField={priceUnit}
                  graphqlParams={{ query: Sale }}
                />
              </Col>
              <Col lg={4}>
                <Dropdown
                  title={t('product.vatReduced')}
                  field='taxChild'
                  defaultValueField={taxChild}
                  graphqlParams={{ query: Tax }}
                />
              </Col>
            </LoadingRow>
            <Row>
              <Col width={'160px'}>
                <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[0]} field={'amount[0]'} title={props.category[0] || ''} />
              </Col>
              {
                props.category[1] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[1]} field={'amount[1]'} title={props.category[1] || ''}/>
                </Col>
              }
              {
                props.category[2] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[2]} field={'amount[2]'} title={props.category[2] || ''}/>
                </Col>
              }
              {
                props.category[3] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'}value={+amount[3]} field={'amount[3]'} title={props.category[3] || ''}/>
                </Col>
              }
              {
                props.category[4] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[4]} field={'amount[4]'} title={props.category[4] || ''}/>
                </Col>
              }
              {
                props.category[5] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[5]} field={'amount[5]'} title={props.category[5] || ''}/>
                </Col>
              }
              {
                props.category[6] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[6]} field={'amount[6]'} title={props.category[6] || ''}/>
                </Col>
              }
              {
                props.category[7] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[7]} field={'amount[7]'} title={props.category[7] || ''}/>
                </Col>
              }
              {
                props.category[8] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[8]} field={'amount[8]'} title={props.category[8] || ''}/>
                </Col>
              }
              {
                props.category[9] &&
                <Col width={'160px'}>
                  <Input placheholder={'Enter...'} paddingRight={'8px'} small type='number' floatFormat={'ffFixed'} value={+amount[9]} field={'amount[9]'} title={props.category[9] || ''}/>
                </Col>
              }
            </Row>
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

export default ActualPrice;

const LoadingRow = styled(Row)`
  margin: ${(props) => (props.loading ? '0 0 36px 0px' : null)}
`;
