import React from 'react';
import { Row, Col } from 'style';
import styled from 'styled-components';

import { Button } from 'components/elements';
const ConfirmModal = ({ closeModal, onConfirm, text, t }) => (
  <ModalBody>
    <p>
      {text}
    </p>
    <BottonBlock>
    		<Col lg={5}>
    			<Button value ={t('common.no')} className='black' upperCase onClick={closeModal}/>
    		</Col>
    		<Col lg={5}>
    			<Button value ={t('common.ok')} className='grey' upperCase onClick={onConfirm} />
    		</Col>
    	</BottonBlock>
  </ModalBody>
);

const ModalBody = styled.div`
  padding: 20px 20px 0 20px;
  text-align: center;
  font-size: 15px;
`;

const BottonBlock = styled(Row)`
    margin-top: 40px;
    justify-content: center;
`;

export default ConfirmModal;
