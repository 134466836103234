import gql from 'graphql-tag';

export default gql`
query listFA_EmptyOrders($args: FA_EmptyOrdersInputs!, $params: ListQueryArgs){
    fakturaAssist{
    	listFA_EmptyOrders(args: $args, params: $params){
    		date
	        id
	        nameRes
	        clientInfo {
				customerNr
			}
 		}
 	}
}`;
// clientInfo {
// customerNr
// }
