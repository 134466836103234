import gql from 'graphql-tag';
//Used in information block on top
export default gql`
mutation doFA_DeleteOrder($args: FA_DeleteOrderInputs!){
    fakturaAssist{
    	doFA_DeleteOrder(args: $args){
	    	id
	 	}
 	}
}`;
