import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FilterButton = ({ onClick, value, isfilterButton }) => {
  return (
    <BtnWrapper onClick={onClick}>
      <div className='icon'>
        <i className='icon-arrow' />
      </div>
      <ReactButton isfilterButton={isfilterButton}>{value}</ReactButton>
    </BtnWrapper>
  );
}
;

FilterButton.propTypes = {
  onClick: PropTypes.func,
};

FilterButton.defaultProps = {
  value: 'Filter',
};

export default FilterButton;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  & .icon {
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    &-arrow {
      border: solid #CDCDCD;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 1.6px;
      transform: rotate(45deg);
      &.opened {
        margin-top: 1px;
        transform: rotate(-135deg);
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

const ReactButton = styled.div`
  display: flex;
  z-index: 99;
    font-weight: 300;
    /* width: 100%; */
    position: relative;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    height: 22px;
    font-size: 14px;
    background-color: transparent;
    color: #434343;
    border-width: 0;
    border-style: none;
    border-color: initial;
    border-image: initial;
    transition: all 0.3s ease 0s;
    margin: 0px;
    padding: 0 0 0 5px;
    border-radius: 3px;
    margin-left: auto;
`;

