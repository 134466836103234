import gql from 'graphql-tag';

export default gql`
query listFA_Languages{
    fakturaAssist{
    listFA_Languages{
      id
      name
      __typename
    }
  }
}`;
