import gql from 'graphql-tag';

export default gql`
query listFA_CustomerGroup{
    fakturaAssist{
    listFA_CustomerGroup{
      id
      group
      __typename
    }
  }
}`;
