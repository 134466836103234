import React from 'react';
import { Col } from 'style';

import { Input } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';

import { timeListTableParams } from '../params';

export default (props) => {
  const { t, time, setTime } = props;
  const timeList = Array(48).fill(0).map((e, index)=> {
    const roundingNumber = index % 2 ? `${Math.floor(index / 2)}:30` : `${Math.floor(index / 2)}:00`;
    return({
      time: roundingNumber,
    });
  });
  return(
    <>
      <Col>
        <Input title ='Time value' value={time} onChange={setTime} withoutFormik/>
      </Col>
      <Col width={'260px'}>
        <TableGrid
          getTableIndex = {(_, item) => setTime(item.time)}
          tableData={timeListTableParams(t)}
          data={timeList}
          height={200}
          disableHeightChange
        />
      </Col>
    </>
  );
};
