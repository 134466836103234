import React from 'react';
import styled from 'styled-components';
import Formik from 'components/formik';

import ProductsTable from './ProductsTable';
import CalendarSection from './CalendarSection';

const NewInvoice = (props) =>(
  <Formik initialValues={{ textareaValue: '', textID: 2 }}>
    {({ handleSubmit, handleChange, values, setFieldValue }) =>{
      return (
        <MainBlock>
          <CalendarSection values={values} {...props} />
          <ProductsTable {...props}/>
        </MainBlock>);
    }}
  </Formik>);

export default NewInvoice;

const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 0 20px 20px 20px;
  margin-top: 90px;
`;
