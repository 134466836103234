import gql from 'graphql-tag';

export default gql`
query getFA_TemporaryOrder($args: FA_GetTempOrderInputs!) {
  fakturaAssist {
    getFA_TemporaryOrder(args: $args) {
      id
      customerId
      orderRef
      deliveryTime
      date
      selectedIndex
    }
  }
}`;
