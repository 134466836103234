import React from 'react';
import styled from 'styled-components';

import TableGrid from 'components/tableGrid/TableGrid';

const tableData = (t) => ([
  
  {
    name: t('common.deliveryNr'),
    dataBase: 'deliveryNr',
  },
  {
    name: t('common.date'),
    dataBase: 'date',
  },
  {
    name: t('common.total'),
    dataBase: 'total',
  },
]);
  
const PreviousOrdersModal = ({ closeModal, data, setDeliveryNr, t }) => (
  <ModalBody>
    <TableGrid
      data = {data }
      tableData={ tableData(t) }
      gg={console.log(data)}
      height={160}
      getTableIndex={async (index, item)=> {
        setDeliveryNr(item.id);
      }}
      t={t}
      disableHeightChange
    />
  </ModalBody>
);

const ModalBody = styled.div`
  padding: 20px;
  text-align: center;
  font-size: 15px;
`;

export default PreviousOrdersModal;
