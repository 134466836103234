import gql from 'graphql-tag';

export default gql`
query listFA_OverviewOrders($args: FA_OverviewOrdersInputs!, $params: ListQueryArgs){
    fakturaAssist{
    	listFA_OverviewOrders(args: $args, params: $params){
    		items{
		        id
		        date
		        resName
		        gruppe
		        name
		        orderNr
		        customerNr
		        userFullName
		        orderTypeName
		        total
		        totalNetto
		      }
	      totalSum
	      totalNetto
 		}
 	}
}`;
