import SecurityManager from './SecurityManager';
import { getFA_Config } from '../qql/query';
import { updateFA_Config } from '../qql/mutation';

export default () => ({
  link: 'securityManager',
  subsections: [],
  itemQuery: getFA_Config,
  updateMutation: updateFA_Config,
  Component: SecurityManager,
});

