import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row } from 'react-bootstrap';

import Menu from '../../../components/Menu/Menu';
import { additionalMenuObj } from './params';

import Articles from './articles/Articles';
import Dough from './dough/Dough';
import Orders from './orders/Orders';
import WithoutOrders from './withoutOrders/WithoutOrders';

export default (props) =>{
  return (
    <div>
      <Row><Menu menuObj={additionalMenuObj(props.t)} isaddition/></Row>
      <Switch>
        <Route path='/order/overview/order' component={() => <Orders {...props}/>}/>
        <Route path='/order/overview/articles' component={() => <Articles {...props}/>}/>
        <Route path='/order/overview/withoutOrders' component={() => <WithoutOrders {...props}/>}/>
        <Route path='/order/overview/dough' component={() => <Dough {...props}/>}/>
      </Switch>
    </div>
  );
};


