import gql from 'graphql-tag';

export default gql`
mutation deleteFA_CustomerListsRelations($id: ID!){
    fakturaAssist{
    deleteFA_CustomerListsRelations(id: $id){
		linkCustomer
    }
    
  }
}`;
