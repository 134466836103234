import React, { useState } from 'react';
import { Row, Col } from 'style';
import copy from 'style/img/copy.svg';
import styled from 'styled-components';
import Formik from 'components/formik';

import MutationHoc from 'components/graphql/MutationHoc';
import { Dropdown, Input, Checkbox, Calendar, TextAreaStandart, Modal } from 'components/elements';

import TableGrid from '../../../../../components/tableGrid/TableGrid.js';

import AskOfModal from './AskOfModal';

import deleteFA_SpecialPrices from './qql/mutation/deleteFA_SpecialPrices';
import createFA_SpecialPrices from './qql/mutation/createFA_SpecialPrices';
import updateFA_SpecialPrices from './qql/mutation/updateFA_SpecialPrices';

import copyArticlePrice from './qql/mutation/copyArticlePrice';

import listFA_CustomerSpecialPricesTypes from './qql/listFA_CustomerSpecialPricesTypes';
import listProducts from '../../../qql/listProducts';
import listFA_CustomerSpecialPricesAndDiscounts from './qql/listFA_CustomerSpecialPricesAndDiscounts';
import listFA_ConfigCustomerPriceCategories from './qql/listFA_ConfigCustomerPriceCategories';
import listFA_TaxInclusionKinds from './qql/listFA_TaxInclusionKinds';
import listFA_CustomerForSpecialPrice from './qql/listFA_CustomerForSpecialPrice';
import { useTranslation } from 'react-i18next';

import { cloneDeep } from 'lodash';

export default (props) => {
  const [t] = useTranslation();
  const [idCopyOfCustomer, setIdCopyOfCustomer] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState([
    {
      name: t('common.fromDate'),
      dataBase: 'fromDate',
      isMutation: true,
      dataType: 'date',
    },
    {
      name: t('common.toDate'),
      dataBase: 'toDate',
      isMutation: true,
      dataType: 'date',
    },
    {
      name: t('common.articleNo'),
      dataBase: 'productNr',
      dataType: 'drop',
      isRequired: true,
    },
    {
      name: t('common.description'),
      dataBase: 'productName',
    },
    {
      name: t('customer.stdPrice'),
      dataBase: 'currentPrice',
      dataType: 'float',
    },
    {
      name: t('common.price'),
      dataBase: 'price',
      isMutation: true,
      dataType: 'float',
      isRequired: true,
    },
    {
      name: t('common.discount'),
      dataBase: 'discount',
      isMutation: true,
      dataType: 'percent',
      isRequired: true,
    },
    {
      name: t('customer.noDiscount'),
      dataBase: 'isDiscountsDisabled',
      isMutation: true,
      type: 'boolean',

    },
    {
      name: t('customer.fromAmount'),
      dataBase: 'fromAmount',
      isMutation: true,
      isRequired: true,
    },
    {
      name: t('common.note'),
      dataBase: 'description',
      isMutation: true,
    },
  ]);

  const onCopy = () => {
    setIsOpen(true);
  };

  const onCloseModal = (mutation) => {
    if (mutation) {
      mutation({
        variables: {
          idCustomer: +props.linkId,
          idCopyOfCustomer: +idCopyOfCustomer,
        },
        refetchQueries:
          [{ query: listFA_CustomerSpecialPricesAndDiscounts, variables: { id: props.linkId } }],
      }).then((db) => {
        console.log('ok');
      }).catch((err) => {
        console.log(err);
      });
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  // const list = [
  //   {
  //     name: t('common.fromDate'),
  //     dataBase: 'fromDate',
  //     isMutation: true,
  //     dataType: 'date',
  //   },
  //   {
  //     name: t('common.fromDate'),
  //     dataBase: 'toDate',
  //     isMutation: true,
  //     dataType: 'date',
  //   },
  //   {
  //     name: t('common.articleNo'),
  //     dataBase: 'productNr',
  //     dataType: 'drop',
  //     isRequired: true,
  //   },
  //   {
  //     name: t('common.description'),
  //     dataBase: 'productName',
  //   },
  //   {
  //     name: t('customer.stdPrice'),
  //     dataBase: 'currentPrice',
  //     dataType: 'float',
  //   },
  //   {
  //     name: t('common.price'),
  //     dataBase: 'price',
  //     isMutation: true,
  //     dataType: 'float',
  //     isRequired: true,
  //   },
  //   {
  //     name: t('common.discount'),
  //     dataBase: 'discount',
  //     isMutation: true,
  //     dataType: 'percent',
  //     isRequired: true,
  //   },
  //   {
  //     name: t('customer.noDiscount'),
  //     dataBase: 'isDiscountsDisabled',
  //     isMutation: true,
  //     type: 'boolean',

  //   },
  //   {
  //     name: t('customer.fromAmount'),
  //     dataBase: 'fromAmount',
  //     isMutation: true,
  //   },
  //   {
  //     name: t('common.description'),
  //     dataBase: 'description',
  //     isMutation: true,
  //   }
  // ];

  const Component = (props) => {
    const { handleChange, values, setFieldValue, handleBlur, setFieldTouched, touched } = props;
    const newList = cloneDeep(list);
    if ([1, 4].includes(values.specialPriceTypeId) && (!newList[5].isRequired || newList[6].isRequired)) {
      newList[5].isRequired = true;
      newList[6].isRequired = false;
    } else if ([2, 5].includes(values.specialPriceTypeId) && ((!newList[6].isRequired && !values.isDiscountsDisabled) || newList[5].isRequired)) {
      newList[6].isRequired = true;
      newList[5].isRequired = false;
    } else if ([3, 6].includes(values.specialPriceTypeId) && (!newList[5].isRequired || (!newList[6].isRequired && !values.isDiscountsDisabled))) {
      newList[6].isRequired = true;
      newList[5].isRequired = true;
    }
    if ([1, 2, 3].includes(values.specialPriceTypeId) && newList[8].isRequired) {
      newList[8].isRequired = false;
    } else if ([4, 5, 6].includes(values.specialPriceTypeId) && !newList[8].isRequired) {
      newList[8].isRequired = true;
    }

    if (values.isDiscountsDisabled && newList[6].isRequired) {
      newList[6].isRequired = false;
    }

    if (JSON.stringify(newList) !== JSON.stringify(list)) {
      setList(newList);
    }

    return (
      <div>
        <Row>
          <Col width='320'>
            <Dropdown
              width={'300px'}
              withoutFormik={true}
              title={t('common.type')}
              onChange={(value) => {
                setFieldValue('specialPriceTypeId', value.value);
              }}
              valueField='id'
              labelField='name'
              name='specialPriceTypeId'
              defaultValueField={values.specialPriceTypeId}
              graphqlParams={{ query: listFA_CustomerSpecialPricesTypes,
                variables: {
                  languageId: +localStorage.getItem('lngId') || 1,
                },
              }}
              handleBlur={handleBlur}
              touched={touched}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={2}>
            <Calendar withoutFormik={true} title={t('common.fromDate')} setFieldValue={setFieldValue} name='fromDate' value={values.fromDate} />
          </Col>
          <Col lg={2}>
            <Calendar withoutFormik={true} title={t('common.toDate')} setFieldValue={setFieldValue} name='toDate' value={values.toDate} />
          </Col>
        </Row>
        <Row>
          <Col width={'320px'}>
            <Dropdown
              width={'300px'}
              withoutFormik={true}
              title={t('customer.article')}
              onChange={(value) => {
                setFieldValue('productId', value.value); setFieldValue('productNr', value.data.productNumber); setFieldValue('productName', value.data.name);
              }}
              valueField='id'
              labelField='name'
              err={props.errors}
              name='productId'
              nameForValid='productNr'
              defaultValueField={values.productId}
              graphqlParams={{ query: listProducts }}
              setFieldTouched={setFieldTouched}
              touched={touched}
              withVirtualized
            />
          </Col>
        </Row>
        <Row style={{ minHeight: '81px' }}>
          <Col lg={2}>
            {[1, 3, 4, 6].includes(values.specialPriceTypeId) &&
              <Input
                small
                withoutFormik={true}
                type='number'
                onChange={handleChange}
                value={values.price}
                decimal
                name={'price'}
                title={t('common.price')}
                err={props.errors}
                handleBlur={handleBlur}
                touched={touched}
              />
            }
          </Col>
          {[4, 5, 6].includes(values.specialPriceTypeId) &&
            <Col lg={2}>
              <Input
                small
                title={t('customer.fromAmount')}
                withoutFormik={true}
                type='number'
                onChange={handleChange}
                value={values.fromAmount}
                name={'fromAmount'}
                handleBlur={handleBlur}
                touched={touched}
                err={props.errors}
              />
            </Col>
          }
        </Row>

        <Row style={{ minHeight: '81px' }}>
          {[2, 3, 5, 6].includes(values.specialPriceTypeId) &&
            <Col lg={2}>
              <Input
                small
                withoutFormik={true}
                type='number'
                onChange={handleChange}
                value={values.discount}
                err={props.errors}
                name={'discount'}
                title={t('common.discount')}
                handleBlur={handleBlur}
                touched={touched}
              />
            </Col>
          }
          {[2, 3, 5, 6].includes(values.specialPriceTypeId) &&
            <Col lg={2}>
              <Checkbox
                withoutFormik={true}
                onChange={setFieldValue}
                value={values.isDiscountsDisabled}
                name={'isDiscountsDisabled'}
                title={t('common.noDiscountPosible')}
              />
            </Col>
          }
        </Row>
        <Row>
          <Col width={'320px'}>
            <TextAreaStandart
              width={'300px'}
              withoutFormik={true}
              type='number'
              onChange={handleChange}
              value={values.description}
              name={'description'}
              title={t('common.note')}
            />
          </Col>
        </Row>
      </div>

    );
  };
  return (
    <HeadBlock>
      <MutationHoc mutation={copyArticlePrice}>
        {({ mutation, result, status, loading }) => {
          return (<Modal
            headerText={t('customer.windowInfo')}
            width={'480px'}
            height={'170px'}
            isOpen={isOpen}
            Component={<AskOfModal mutation={mutation} onCloseModal={onCloseModal} />}
          />);
        }
        }
      </MutationHoc>
      <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={props.linkId} sectionInfo={props.sectionInfo}>
        {({ handleSubmit, handleChange, values, setFieldValue }) => {
          return (
            <Row>
              <Col width='340px' >
                <Dropdown
                  width='300px'
                  title={t('customer.priceCategory')}
                  valueField='id'
                  labelField='resName'
                  field='articlePriceGroup'
                  defaultValueField={values.articlePriceGroup}
                  graphqlParams={{ query: listFA_ConfigCustomerPriceCategories }}
                />
              </Col>
              <Col width='340px' >
                <Dropdown
                  width='300px'
                  title={t('common.vat')}
                  valueField='id'
                  labelField='name'
                  field='MWStSystem'
                  defaultValueField={values.MWStSystem}
                  graphqlParams={{ query: listFA_TaxInclusionKinds, variables: { languageId: +localStorage.getItem('lngId') || 1 } }}
                />
              </Col>
              <Col width='310px' >
                <Dropdown
                  width='300px'
                  graphqlParams={{ query: listFA_CustomerForSpecialPrice }}
                  valueField='id'
                  labelField='aktNameId'
                  withoutFormik={true}
                  setFieldValue={(name, value) => {
                    setIdCopyOfCustomer(value);
                  }}
                  title={t('customer.copyFrom')}
                  defaultValueField={idCopyOfCustomer}
                  withVirtualized

                />
              </Col>
              <Col width='50px'>
                <Img onClick={idCopyOfCustomer && onCopy}><img src={copy} /></Img>
              </Col>
            </Row>
          );
        }}
      </Formik>
      <Table>
        <TableGrid
          graphqlParams={{
            query: listFA_CustomerSpecialPricesAndDiscounts,
            variables: { id: props.linkId },
          }}
          //searchValue={this.state.searchValue}
          //onSearch={this.onSearch}
          //getSortField={this.getSortField}
          Component={Component}
          isRefetchAfterMutation
          height={700}
          name={t('common.price')}
          headerText={t('customer.conditions.articlePrices')}
          modalWidth={384}
          modalHeight={690}
          withModalMutations
          withSearch
          //editItem={this.openModal}
          tableData={list}
          //getTableIndex={this.indexOnClickTable}
          itemDeleted={deleteFA_SpecialPrices}
          defaultValuesForFormik={['specialPriceTypeId', 'productId', 'productNr', 'productName']}
          defaultValuesForCache={['specialPriceTypeId', 'currentPrice', 'productName']}
          defaultValuesForMutation={{ customerId: +props.linkId }}
          typeName={'FA_SpecialPrices'}
          qqlMutationCreate={createFA_SpecialPrices}
          qqlMutationUpdate={updateFA_SpecialPrices}
          dataPolicy='local'
          initialSortValue='id'
        />
      </Table>
    </HeadBlock>
  );
};

const HeadBlock = styled.div`
  padding: 0px 20px 20px 20px;
`;

const Table = styled.div`
  padding: 20px 0 0 0;
`;

const Img = styled.div`
  margin-top: 34px;
  padding: 9px 13px 10px 13px;
  height: 38px;
  width: 38px;
  border-radius: 3px;

`;


