import { readQuery } from 'helpers/queryOperations';
import { useState } from 'react';
import { useMutation } from 'components/graphql';
import { useResettableMutation } from 'apollo-hooks-extended';
import { createOrder, deleteFA_TemporaryOrder, checkOrdersOnThisDate } from '../../qql/mutation';

const useNewOrder = (props) => {
  const [checkOrdersOnThisDateMutation] = useMutation(checkOrdersOnThisDate);
  const [createOrderMutation, { reset, called }] = useResettableMutation(createOrder, {
    client: props.client,
  });
  const { readQueryProductsParams, linkId, isDelivery, deliveryInfo, orderType, initialOrderInfo, contex, deleteCustomerFromList, variables: { args } } = props;
  const [isWarningModalStatus, changeWarningModalStatus] = useState(false);
  const [isLoadingModalStatus, changeLoadingModalStatus] = useState(false);
  const [ordersArray, setOrderArray] = useState([]);
  const orderDate = props.contex?.date?.date;
  const [deleteTempOrderMutation] = useMutation(deleteFA_TemporaryOrder, {
    variables: { id: args?.temporaryId },
  });

  //With positive price and quantity
  const allOrderedProducts = () => {
    const productsFromCache = readQuery(readQueryProductsParams);
    const productsForOrder = productsFromCache.productTable.filter(({ quantity }) => quantity).map((item) => {
      const {
        virtualPositionLink,
        productLink,
        quantity,
        price,
        discount,
        taxCode,
        indTextProduction,
        indTextDeliveryNote,
      } = item;
      return {
        virtualPositionLink: virtualPositionLink,
        productLink: productLink,
        quantity: +quantity,
        price: +price,
        discount: +discount,
        taxCode: +taxCode,
        indTextProduction: indTextProduction,
        indTextDeliveryNote: indTextDeliveryNote,
      };
    });
    return productsForOrder;
  };

  const sendOrderInDatabase = async (values) => {
    const productsForOrder = allOrderedProducts();
    //When customer add delivery note
    if (isDelivery) {
      const delivery = {
        virtualPositionLink: 2,
        quantity: 1,
        price: deliveryInfo.total,
        discount: null,
        taxCode: 1,
      };
      productsForOrder.push(delivery);
    }
    const dataForOrder = {
      userId: 774,
      orderType: orderType,
      date: orderDate,
      deliveryTime: values.deliveryTime,
      orderRef: values.orderRef,
      customerId: linkId,
      productList: productsForOrder,
      textarea: initialOrderInfo.textareaValue,
    };
    // console.log({ dataForOrder })
    await createOrderMutation({ variables: { args: dataForOrder } });
    if (props.refetch) {
      props.refetch();
    }
    contex.filter[0].value === 'dailyCustomer' && deleteCustomerFromList();
    // const { errorCode, errorText } = orderData.data.fakturaAssist.callFA_CreateOrder;
    // changeLoadingModalStatus(false);
    // if(errorCode || errorText) {
    //   //alert(errorText);
    // }else{
    //   contex.filter[0].value === 'dailyCustomer' && deleteCustomerFromList();
    // }
  };

  const createNewOrder = async (values) => {
    //Local variables of query
    try {
      const productsForOrder = allOrderedProducts();
      //If order havent quantity or total of all fields
      if (!productsForOrder.length) {
        changeWarningModalStatus(true);
      } else {
        //changeLoadingModalStatus(true);
        const dataForCheckOrderOnDate = { date: orderDate, customerId: linkId };
        const ordersOnDate = await checkOrdersOnThisDateMutation({ variables: { args: dataForCheckOrderOnDate } });
        const reduceData = ordersOnDate.data.fakturaAssist.checkOrdersOnThisDate;
        //const reduceData = [];

        if (reduceData.length >= 1) {
          setOrderArray(reduceData);
          //changeLoadingModalStatus(false);
        } else {
          sendOrderInDatabase(values);
          if(args?.temporaryId) {
            deleteTempOrderMutation();
          }
        }
      }

    } catch (err) {
      console.log(err);
    }
  };

  const multiOrderModalOnAccept = async () => {
    changeLoadingModalStatus(true);
    setOrderArray([]);
    await sendOrderInDatabase({});
    changeLoadingModalStatus(false);
  };

  const multiOrderModalOnDisable = () => {
    setOrderArray([]);
  };
  return {
    createNewOrder,
    multiOrderModalOnDisable,
    multiOrderModalOnAccept,
    isLoadingModalStatus,
    isWarningModalStatus,
    changeWarningModalStatus,
    ordersArray,
    called,
    reset,
  };
};

export default useNewOrder;

