import React from 'react';
import styled from 'styled-components';
import Formik from 'components/formik';
import { Textarea } from 'components/elements';

import { Col } from 'style';

export default (props) => {
  return (
    <Formik
      initialValues={props.data}
      onSubmit={props.mutations}
      enableReinitialize={true}
      linkId={props.linkId}
      sectionInfo={props.sectionInfo}
    >
      {({ handleSubmit, values }) => {
        return(
          <MainBlock>
            <Textarea
              value={values.memo}
              label='Note'
              width='80%'
              height='400px'
              field='memo'
            />
          </MainBlock>
        );
      }}
    </Formik>
  );
};

const MainBlock = styled(Col)`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 20px 20px 20px;
  margin-top: 20px;
`;
