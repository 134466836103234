/*eslint-disable */
import styled from 'styled-components';
import { Dropdown, Input, Button, Modal } from 'components/elements';
import { Formik } from 'formik';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';

import { writeQuery, readQuery } from 'helpers/queryOperations';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { debounce, differenceWith, isEqual, cloneDeep, includes, set, findIndex, last } from 'lodash';
import { useTranslation } from "react-i18next";

import { generateNewItem } from 'components/tableWithProductOnDate/hoc/const';
import { checkFA_ProductInfoFromOrderDropdown } from 'components/tableWithProductOnDate/qql/mutation';
import { Checkbox } from 'components/elements';

import listProducts from '../../qql/listProducts';
import checkWeekAvailableForProduct from '../qql/checkWeekAvailableForProduct';

import listDefaultOrder from '../qql/listDefaultOrder';
import updateDefOrder from '../qql/updateDefOrder';
import createDefOrder from '../qql/createDefOrder';
import deleteDefOrder from '../qql/deleteDefOrder';

import TableGrid from 'components/tableGrid/TableGrid.js';

import InfoOfDefOrderItem from '../infoOfDefOrderItem.js';
import SpecialDropDown from '../components/SpecialDropDown.js';
import SpecialInput from '../components/SpecialInput.js';
import ModalDefOrder from '../components/ModalDefOrder.js';
import CreateOfModal from '../components/createOfModal.js';

export default (props) => {
  const [t] = useTranslation();
  const { linkId, date, orderGraphQLParams } = props;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [maxSortPos, setMaxSortPos] = useState(0)
  const [index, setIndex] = useState(0);
  const [firstProduction, setFirstProduction] = useState({});

  const [Mutation_updateDefOrder, { loading: loadingChanges }] = useMutation(updateDefOrder);
  const [Mutation_checkWeekAvailableForProduct] = useMutation(checkWeekAvailableForProduct);
  const [Mutation_createDefOrder, { loading: mutationLoading }] = useMutation(createDefOrder);
  const [Mutation_deleteDefOrder] = useMutation(deleteDefOrder);
  const [getProductInfoFromDropdown] = useMutation(checkFA_ProductInfoFromOrderDropdown);
  const ListProducts = useQuery(listProducts);
  const { data, loading, client, refetch } = useQuery(listDefaultOrder, {
    variables: {
      id: +linkId,
      date: date,
    },
    onCompleted: (data) => {
      //console.log('useQuery', data.fakturaAssist.listDefaultOrder)
      data.fakturaAssist.listDefaultOrder[0] && setId(data.fakturaAssist.listDefaultOrder[0].id);
      data.fakturaAssist.listDefaultOrder[data.fakturaAssist.listDefaultOrder.length - 1]
      ? setMaxSortPos(data.fakturaAssist.listDefaultOrder[data.fakturaAssist.listDefaultOrder.length - 1].ItemSortPos)
      : setMaxSortPos(0)
    },
  });

  // console.log('PROPS', props)
  const idRef = useRef({})

  useEffect(() => {
    if (props.linkId !== idRef?.current?.linkId) {
      idRef.current.linkId = props.linkId
    }
  }, [props.linkId])

  const DATA = () => {
    try {

      const a = props.client.readQuery({
        query: listDefaultOrder,
        variables: {
          id: +idRef.current.linkId,
          date: date,
        },
      }).fakturaAssist.listDefaultOrder;
      // console.log({ a })
      return a;

    } catch (err) {
      return []
    }

  };


  const returnChanges = (values) => {
    const d = DATA()
    return differenceWith(values, d, isEqual);
  };

  const changeSelectId = (item, _, index) => {
    setIndex(index);
    setId(item.id);
  };

  const onClose = () => {
    setOpen(false);
  }

  //This function need in Create Order - Default Order. Its change info in defaultOrderOnDay
  const getOrderInfoAccordingToDate = (actualItem) => {
    const actDate = props.orderDate;
    const dayNumber = new Date(actDate).getDay();
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const actualDay = days[dayNumber];
    const finalObj = {
      quantity: actualItem[`quantity${actualDay}`],
      min: actualItem[`${actualDay}Min`],
      max: actualItem[`${actualDay}Max`],
    };
    return finalObj;
  };

  const addNewItemInCacheInDefaultOrder = async (productId, mutationData) => {
    const cacheData = cloneDeep(readQuery({ ...orderGraphQLParams }, true).fakturaAssist.getFA_GetDefaultOrderForCustomerOnDate);
    const activeProduct = { ...generateNewItem(productId) };
    const concatTotal = (value) => Math.round(value * 100) / 100;
    const productArgs = { customerId: props.linkId, date: props.orderDate, productId: productId };
    const productInfo = await getProductInfoFromDropdown({ variables: { args: productArgs } });
    const { productNr, name, discount, taxCode, price, weight } = productInfo.data.fakturaAssist.checkFA_ProductInfoFromOrderDropdown;
    activeProduct.productName = name;
    activeProduct.taxCode = taxCode;
    activeProduct.price = price;

    activeProduct.productNr = productNr;
    activeProduct.discount = discount;
    activeProduct.weight = weight;
    activeProduct.id = +1;
    activeProduct.productLink = +productId;
    activeProduct.productLinkLookName = +productId;
    activeProduct.productLinkLookNr = +productId;
    activeProduct.quantity = mutationData.quantity;
    activeProduct.total = concatTotal(mutationData.quantity * price || 0);
    cacheData.productTable.splice(0, 0, { ...activeProduct });
    const test = cloneDeep(cacheData)
    writeQuery({ ...orderGraphQLParams, changedData: test });
  };

  const changeCacheInDefaultOrderOnDayOnBlur = (mutationData) => {
    const actualItem = mutationData[index];
    const cacheData = cloneDeep(readQuery({ ...orderGraphQLParams }, true).fakturaAssist.getFA_GetDefaultOrderForCustomerOnDate);
    const orderInfoOnDate = getOrderInfoAccordingToDate(actualItem);
    const actualOrderIndex = cacheData.productTable.findIndex((e) => e.productLink === +actualItem.productId);
    //Create new item in cache list
    if (actualOrderIndex === -1 && orderInfoOnDate.quantity) {
      addNewItemInCacheInDefaultOrder(+actualItem.productId, mutationData);
    } else {
      cacheData.productTable[actualOrderIndex].quantity = orderInfoOnDate.quantity;
      writeQuery({ ...orderGraphQLParams, changedData: cacheData });
    }
  };

  const changeCacheInDefaultOrderOnDayOnDelete = (productId) => {
    const cacheData = cloneDeep(readQuery({ ...orderGraphQLParams }, true).fakturaAssist.getFA_GetDefaultOrderForCustomerOnDate);
    const spliceIndex = cacheData.productTable.findIndex((e) => e.productLink === +productId);
    cacheData.productTable.splice(spliceIndex, 1);
    writeQuery({ ...orderGraphQLParams, changedData: cacheData });
  };

  const onBlurInput = async (values, needUpdate) => {
    const mutationData = returnChanges(values);
    const mutationFields = [];
    // const findedIndex = [];
    try {
      // console.log('onBlurInput', mutationData, values)
      // console.log(!isEqual(mutationData, []), mutationData, values, mutationData[0]?.customerId, values[0]?.customerId, +idRef.current.linkId)
      if ((!isEqual(mutationData, []) && values && ((mutationData[0].customerId === values[0].customerId ? mutationData[0].customerId : false) === +idRef.current.linkId)) || needUpdate) {

        const prevData = cloneDeep(DATA());

        let recordsForCreate = [], recordsForUpdate = [];

        // console.log({mutationData})

        mutationData.forEach((item) => {
          const currentItem = prevData.find((prevItem) => prevItem.id === item.id);
          if (item.productId) {
            if (!currentItem.productId) {
              recordsForCreate.push(item);
            } else {
              recordsForUpdate.push(item);
            }
          }
        })

        if (recordsForCreate.length) {
          await Promise.all(recordsForCreate.map((item) => {
            return createItem(item.productId);
          }));
          setMaxSortPos(maxSortPos+1);
        }

        if (!recordsForUpdate.length) {
          return refetch();
        }

        if (recordsForUpdate.length) {
          for (const item of recordsForUpdate) {
            mutationFields.push({
              quantityMon: item.quantityMon,
              quantityTue: item.quantityTue,
              quantityWed: item.quantityWed,
              quantityThu: item.quantityThu,
              quantityFri: item.quantityFri,
              quantitySat: item.quantitySat,
              quantitySun: item.quantitySun,
              productId: item.productId,
              id: item.id,
              canChangeInOrders: item.canChangeInOrders
            });
          }
          orderGraphQLParams ? changeCacheInDefaultOrderOnDayOnBlur(mutationFields) : null;
          Mutation_updateDefOrder({
            variables: {
              fields: mutationFields,
            },
          })
          .then(res => {
            // console.log({ res })
            ///change Cache
          // console.log('CACHE',linkId, date, values)
            props.client.writeQuery({
              query: listDefaultOrder,
              variables: {
                id: +idRef.current.linkId,
                date: date,
              },
              data: {
                fakturaAssist:
                {
                  listDefaultOrder: values,
                  __typename: 'FA_Query',
                },
              },
            });
          })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              // console.log('finally');
              refetch();
            });
        
        }
      }
    } catch (err) {
      refetch();
      console.log(err);
    }
  };
  const debouncedInput = useCallback(debounce(onBlurInput, 2000, {
    leading: false,
    trailing: true,

  }), []);

  const onEdit = (index, data) => {
    changeSelectId(data[index], '_', index);
    debouncedInput.cancel();
    onBlurInput(data)

    setOpen(true);
  };

  const updateCacheAfterChangeProduct = (values, info, indexT) => {

    const cacheData = props.client.readQuery({
      query: listDefaultOrder,
      variables: {
        id: +linkId,
        date: date,
      },
    }).fakturaAssist.listDefaultOrder;

    Mutation_checkWeekAvailableForProduct({
      variables: {
        id: info.data.id,
        customerId: values[indexT].customerId,
        dates: {
          DateMon: values[indexT].DateMon,
          DateTue: values[indexT].DateTue,
          DateWed: values[indexT].DateWed,
          DateThu: values[indexT].DateThu,
          DateFri: values[indexT].DateFri,
          DateSat: values[indexT].DateSat,
          DateSun: values[indexT].DateSun
        },
      },
    }).then((db) => {
      const cloneCacheData = cloneDeep(cacheData);
      const index = findIndex(cloneCacheData, ['id', values[indexT].id]);
      cloneCacheData.splice(index, 1,
        {
          ...cloneCacheData[index],
          productId: info.data.id,
          description: info.data.name,
          productNr: info.data.productNumber,
          IsMonAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsMonAvailable,
          IsTueAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsTueAvailable,
          IsWedAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsWedAvailable,
          IsThuAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsThuAvailable,
          IsFriAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsFriAvailable,
          IsSatAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsSatAvailable,
          IsSunAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsSunAvailable,
          IsGroupAvailable: db.data.fakturaAssist.checkWeekAvailableForProduct.IsGroupAvailable,
          __typename: 'FA_CustomerDefaultOrder',
        }
      );

      debouncedInput.cancel();
      onBlurInput(cloneCacheData, true);

      props.client.writeQuery({
        query: listDefaultOrder,
        variables: {
          id: +idRef.current.linkId,
          date: date,
        },
        data: {
          fakturaAssist:
          {
            listDefaultOrder: cloneCacheData,
            __typename: 'FA_Query',
          },
        },
      });


      // refetch()
    }).catch((err) => {
      console.log(err);
      refetch()
    });
  }

  const addEmptyRecord = () => {
    const cacheData = props.client.readQuery({
      query: listDefaultOrder,
      variables: {
        id: +linkId,
        date: date,
      },
    }).fakturaAssist.listDefaultOrder;
    const cloneCacheData = cloneDeep(cacheData);
    const lastRecord = cloneCacheData[cloneCacheData.length - 1];
    cloneCacheData.push(
      {
        productId: null,
        customerId: +linkId,
        id: lastRecord ? new Date().getTime() : 1,
        description: null,
        productNr: null,
        ...InfoOfDefOrderItem(),
        __typename: 'FA_CustomerDefaultOrder',
      }
    );
    props.client.writeQuery({
      query: listDefaultOrder,
      variables: {
        id: +idRef.current.linkId,
        date: date,
      },
      data: {
        fakturaAssist:
        {
          listDefaultOrder: cloneCacheData,
          __typename: 'FA_Query',
        },
      },
    });
  }

  const createItem = (id) => {
    // const cacheData = props.client.readQuery({
    //   query: listDefaultOrder,
    //   variables: {
    //     id: +linkId,
    //     date: date,
    //   },
    // }).fakturaAssist.listDefaultOrder;

    return Mutation_createDefOrder({
      variables: {
        productId: +id,
        customerId: +linkId,
        itemSortPos: +maxSortPos+1,
      },
    })
    // .then((db) => {
    // const cloneCacheData = cloneDeep(cacheData);

    // cloneCacheData.unshift(
    //   {
    //     productId: +id,
    //     customerId: +linkId,
    //     id: db.data.fakturaAssist.createDefOrder.id,
    //     description: description,
    //     productNr: productNr,
    //     ...InfoOfDefOrderItem(),
    //     __typename: 'FA_CustomerDefaultOrder',

    //   }
    // );
    // props.client.writeQuery({
    //   query: listDefaultOrder,
    //   variables: {
    //     id: +idRef.current.linkId,
    //     date: date,
    //   },
    //   data: {
    //     fakturaAssist:
    //     {
    //       listDefaultOrder: cloneCacheData,
    //       __typename: 'FA_Query',
    //     },

    //   },
    // });
    //refetch()
  };

  const onDelete = (index, item) => {
    const cacheData = props.client.readQuery({
      query: listDefaultOrder,
      variables: {
        id: +linkId,
        date: date,
      },
    }).fakturaAssist.listDefaultOrder;
    const cloneCacheData = cloneDeep(cacheData);
    orderGraphQLParams ? changeCacheInDefaultOrderOnDayOnDelete(+item.productId) : null
    cloneCacheData.splice(index, 1);
    props.client.writeQuery({
      query: listDefaultOrder,
      variables: {
        id: +idRef.current.linkId,
        date: date,
      },
      data: {
        fakturaAssist:
        {
          listDefaultOrder: cloneCacheData,
          __typename: 'FA_Query',
        },

      },
    });
    Mutation_deleteDefOrder({
      variables: {
        id: item.id,
      },
    });
  };

  const checkAdditionalInfo = (index, day) => {
    const item = DATA()[index];
    let boolean = false;
    if (item) {
      for (let i = 1; i <= 10; i++) {
        if (
          item[`${day}${i}`] ||
          item[`${day}Min`] ||
          item[`${day}Max`]
        ) {
          boolean = true;
          break;
        }
      }
    }

    return boolean;
  };

  return (
    <div>
      {<div tabIndex='33'>
        <Formik
          enableReinitialize
          initialValues={!loading ? DATA() : []}
          render={
            ({ values, handleChange, resetForm, setFieldValue, setValues }) => {

              useEffect(() => {
                onBlurInput(values)
              }, [linkId])
              const filteredListProducs = () => {
                const arrID = [];
                const arrProductions = [];
                values.forEach((item, index) => {
                  arrID.push(item.productId);
                });

                ListProducts.data.fakturaAssist.listProducts.forEach((item, index) => {
                  if (!includes(arrID, item.id)) {
                    arrProductions.push(item);
                  }
                });
                setFirstProduction(arrProductions[0]);
                return arrProductions;
              };
              const Products = !loading ? filteredListProducs() : [];
              return (
                <div>
                  {/* <Modal
                    headerText={t('customer.editQuantityDiscount')}
                    width={'480px'}
                    height={'900px'}
                    isOpen={openCreateOfModal}
                    closeModal={onCloseCreateOfModal}
                    Component={<CreateOfModal mutationLoading={mutationLoading} setOpenCreateOfModal={setOpenCreateOfModal} createItem={createItem} data={Products} dataLoading={ListProducts.loading} />}
                  /> */}
                  <Modal
                    headerText={t('customer.defaultOrder')}
                    width={'1320px'}
                    height={'750px'}
                    isOpen={open}
                    closeModal={onClose}
                    Component={<ModalDefOrder loadingChanges={loadingChanges} setIndex={setIndex} index={index} id={id} setId={setId} setOpen={setOpen} loading={loading} dataDef={!loading ? values : []} client={client} id={id} {...props} />}
                  />
                  <TableGrid
                    //searchValue={this.state.searchValue}
                    //onSearch={this.onSearch}
                    //getSortField={this.getSortField}
                    loading={loading}
                    //addNewItem={() => createItem(firstProduction?.id)}
                    className='table-grid'
                    addNewItem={addEmptyRecord}
                    height={360}
                    onDelete={onDelete}
                    editItem={(index, data) => { onEdit(index, values); }}
                    withSearch
                    dataPolicy='local'
                    leftGridScrollMode={false}
                    // disableHeightChange
                    tableData={[
                      {
                        name: t('common.number'),
                        dataBase: 'productNr',
                        relativeWidth: 0.08,
                        specialComponent: (index, columnIndex, value, data) => {
                          return (
                            <div>
                              <SpecialDropDown
                                updateCacheAfterChangeProduct={updateCacheAfterChangeProduct}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                data={!loading && ListProducts.data.fakturaAssist.listProducts}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                key={`${columnIndex}${index}${value}`}
                                id={id}
                                setId={setId}
                                refetch={refetch}
                                sortKey='data.productNumber'
                                selectedLabel='productNumber'
                                concatFields={['productNumber', 'name']}
                                debouncedInput={debouncedInput} />
                            </div>
                          );
                        }
                      },
                      {
                        name: t('common.description'),
                        dataBase: 'description',
                        relativeWidth: 0.16,
                        specialComponent: (index, columnIndex, value, data) => {

                          useEffect(() => {
                            if (!isEqual(data, values)) {
                              setValues(data)
                            }
                          }, [data])

                          return (
                            <div>
                              <SpecialDropDown
                                updateCacheAfterChangeProduct={updateCacheAfterChangeProduct}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                data={!loading && ListProducts.data.fakturaAssist.listProducts}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                key={`${columnIndex}${index}${value}`}
                                id={id}
                                setId={setId}
                                refetch={refetch}
                                selectedLabel='name'
                                concatFields={['name', 'productNumber']}
                                debouncedInput={debouncedInput} />
                            </div>
                          );
                        },
                      },
                      {
                        name: t('common.shortDays.mon'),
                        dataBase: 'quantityMon',
                        specialComponent: (index, columnIndex, value) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Mon')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantityMon`}
                                open={open}
                                value={values[index] && values[index].quantityMon} />
                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsMonAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('common.shortDays.tue'),
                        dataBase: 'quantityTue',
                        specialComponent: (index, columnIndex) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Tue')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantityTue`}
                                value={values[index] && values[index].quantityTue} />
                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsTueAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('common.shortDays.wed'),
                        dataBase: 'quantityWed',
                        specialComponent: (index, columnIndex) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Wed')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantityWed`}
                                value={values[index] && values[index].quantityWed} />
                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsWedAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('common.shortDays.thu'),
                        dataBase: 'quantityThu',
                        specialComponent: (index, columnIndex) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Thu')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantityThu`}
                                value={values[index] && values[index].quantityThu} />

                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsThuAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('common.shortDays.fri'),
                        dataBase: 'quantityFri',
                        specialComponent: (index, columnIndex) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Fri')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantityFri`}
                                value={values[index] && values[index].quantityFri} />

                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsFriAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('common.shortDays.sat'),
                        dataBase: 'quantitySat',
                        specialComponent: (index, columnIndex) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Sat')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantitySat`}
                                value={values[index] && values[index].quantitySat} />

                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsSatAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('common.shortDays.sun'),
                        dataBase: 'quantitySun',
                        specialComponent: (index, columnIndex) => {
                          return (
                            <div>
                              <SpecialInput
                                t={t}
                                values={values}
                                index={index}
                                columnIndex={columnIndex}
                                id={id}
                                setId={setId}
                                debouncedInput={debouncedInput}

                                open={open}
                                type={'number'}
                                checkAdditionalInfo={checkAdditionalInfo(index, 'Sun')}
                                setFieldValue={setFieldValue}
                                name={`[${index}]quantitySun`}
                                value={values[index] && values[index].quantitySun} />

                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                        colorBlockFunc: (data, index) => { if (data[index].IsSunAvailable) { return ('#D3F4DC') } else { return ('#FFDFD7') } }
                      },
                      {
                        name: t('customer.changesAllowed'),
                        dataBase: 'canChangeInOrders',
                        specialComponent: (index, value) => {
                          return (
                            <div style={{ padding: '0 40%' }}
                              key={Math.random()}>
                              <Checkbox
                                key={Math.random()}
                                tabIndex='0'
                                value={values[index] && values[index].canChangeInOrders}
                                name={`[${index}]canChangeInOrders`}
                                onChange={async (name, checkboxValue) => {
                                  // let valuesCopy = values.slice();
                                  // let valuesCopyItem = {...valuesCopy[index]};
                                  // valuesCopyItem.canChangeInOrders = checkboxValue;
                                  // valuesCopy[index] = valuesCopyItem;
                                  //  (() => {
                                  //    setFieldValue(name, checkboxValue);
                                  //  });
                                  //console.log(values[index].canChangeInOrders)
                                  await setFieldValue(name, checkboxValue);
                                  //console.log(values[index].canChangeInOrders)
                                  debouncedInput.cancel();


                                }}
                                onBlur={() => debouncedInput(values)}
                                withoutFormik
                                margin={'0px'}
                                zIndex={100}
                              />

                            </div>
                          );
                        },
                        relativeWidth: 0.07,
                      },

                      {
                        name: t('order.modals.information'),
                        dataBase: 'description',
                        relativeWidth: 0.19,
                        specialComponent: (index, columnIndex, _, data) => {
                          let information = "";

                          if (!data[index].IsGroupAvailable) {
                            information = "Gruppe nicht verfügbar"
                          }
                          else if (!data[index].isActivated) {
                            information = "Inaktiv"
                          }
                          else if (
                            data[index] &&
                            (data[index].quantityMon === false ||
                              data[index].quantityTue === false ||
                              data[index].quantityWed === false ||
                              data[index].quantityThu === false ||
                              data[index].quantityFri === false ||
                              data[index].quantitySat === false ||
                              data[index].quantitySun === false)) {
                            information = "Artikel Menge = 0"
                          }
                          else if (
                            data[index] &&
                            (data[index].IsMonAvailable === true &&
                              data[index].IsTueAvailable === true &&
                              data[index].IsWedAvailable === true &&
                              data[index].IsThuAvailable === true &&
                              data[index].IsFriAvailable === true &&
                              data[index].IsSatAvailable === true &&
                              data[index].IsSunAvailable === true)) {
                            information = "Lieferbar"
                          }
                          else if (
                            data[index] &&
                            (data[index].IsMonAvailable === true ||
                              data[index].IsTueAvailable === true ||
                              data[index].IsWedAvailable === true ||
                              data[index].IsThuAvailable === true ||
                              data[index].IsFriAvailable === true ||
                              data[index].IsSatAvailable === true ||
                              data[index].IsSunAvailable === true)) {
                            information = "Manchmal lieferbar "
                          }
                          else if (
                            data[index] &&
                            (data[index].IsMonAvailable === false ||
                              data[index].IsTueAvailable === false ||
                              data[index].IsWedAvailable === false ||
                              data[index].IsThuAvailable === false ||
                              data[index].IsFriAvailable === false ||
                              data[index].IsSatAvailable === false ||
                              data[index].IsSunAvailable === false)) {
                            information = "Nicht verfügbar"
                          }
                          return (
                            <div>
                              {information}
                            </div>
                          );
                        },
                      },
                    ]}
                    data={!loading ? DATA() : []}
                    isDefaultOrder
                    changeSelectId={(item, index) => changeSelectId(item, values, index)}
                    linkId={id}
                    minHeightTable="400px"
                    selectById
                    setIdAfterCreation={setId}
                  />
                </div>
              );
            }}
        />

      </div>}</div>
  );
};

const HeadBlock = styled.div`
  height: 800px;
  padding: 20px 20px 20px 20px;
  background: #f4f4f4;
`;

const Footer = styled.div`
  margin-top: 20px;
`;
