import gql from 'graphql-tag';

export const listFA_ProductProductionDough = gql`
query listFA_ProductProductionDough {
  fakturaAssist {
    listFA_ProductProductionDough {
      id
      articleNm
      description
      concatenatedField
    }
  }
}`;

export const listFA_ProductProductionGeneralRecipe = gql`
query listFA_ProductProductionGeneralRecipe {
  fakturaAssist {
    listFA_ProductProductionGeneralRecipe {
      id
      description
      nummer
      concatenatedField
      
      connectedArticleID
      connectedArticleName
    }
  }
}`;
