import gql from 'graphql-tag';

export default gql`
mutation checkWeekAvailableForProduct(
		$id: ID!, 
		$dates: daysForWeekAvailableForProduct, 
		$customerId: ID!){
    fakturaAssist{
        checkWeekAvailableForProduct(id: $id, dates: $dates, customerId: $customerId){
            IsMonAvailable
            IsTueAvailable
            IsWedAvailable
            IsThuAvailable
            IsFriAvailable
            IsSatAvailable
            IsSunAvailable
            IsGroupAvailable
    }
  }
}`;
