import gql from 'graphql-tag';

export default gql`
query listFA_Statistic($args: FA_StatisticInputs!) {
    fakturaAssist{
    	listFA_Statistic(args: $args){
    		id
			productLink
			orderType
			name
			productNr
			discountGroup
			marketingGroup
			productionGroup
			quantity
			weight
 		}
 	}
}`;
