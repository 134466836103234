import gql from 'graphql-tag';

export default gql`
 query listFA_OrderTypesView($params: ListQueryArgs, $languageId: Int){
    fakturaAssist {
        listFA_OrderTypesView(params: $params, languageId: $languageId) {
            id
            orderTypeName
    }
    }
 }
`;
