import gql from 'graphql-tag';

export default gql`
query listFA_CreatedOrderForCustomer($filter: [FA_GeneralFilter],  $params: ListQueryArgs, $isDelete: Boolean){
    fakturaAssist{
    	listFA_CreatedOrderForCustomer(filter: $filter, params: $params, withDeleted: $isDelete){
    		id
			date
			orderNr
			customerNr
			actualName
			orderType
			total
			customerLink
			isDelete
			orderTypeName
			pdfLink
			invoiceNr
			currencyId
 		}
 	}
}`;
