
import gql from 'graphql-tag';

export default gql`
query listCompareFa_Assortment($id: Int){
    fakturaAssist{
    listCompareFa_Assortment(id: $id){
		id
		name
		open
    }
    
  }
}`;
