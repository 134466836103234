
import { useQuery } from '@apollo/react-hooks';

import reduce from 'helpers/reduceGraphqlData.js';

const QueryComponent = ({ query, queryRef, fetchPolicy, children, variables, onCompleted, typename, skip, ...props }) => {
  const { data, error, loading, client, fetchMore, refetch, networkStatus } = useQuery(query, {
    ...props,
    ref: queryRef,
    skip: skip,
    fetchPolicy: fetchPolicy || 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    __typename: typename,
    variables,
    onCompleted,

  });

  const concatData = !loading && !error && data ? reduce(data) : [];
  try {
    const cache = client.cache.readQuery({
      query: query,
      variables: variables,
      __typename: typename,
    });
    
    return children({
      ...props,
      networkStatus,
      error,
      data: reduce(cache),
      loading,
      onCompleted,
      fetchMore,
      client: client,
      refetch,
    });
  }catch(err) {
    return children({
      ...props,
      networkStatus,
      error,
      loading,
      fetchMore,
      data: concatData,
      client: client,
      refetch,
    });
  }
};

export default QueryComponent;

// const QueryComponent = ({ query, skip, queryRef, fetchPolicy, children, ...props }) => {
//   if(skip) return children({ data: [] });
//   return(
//     <Query
//       ref={queryRef}
//       query={query}
//       fetchPolicy={fetchPolicy || 'cache-and-network'}
//       errorPolicy='all'
//       {...props}
//     >
//       {(props) => {
//         const { data, loading, error } = props;
//         const concatData = !loading && !error && data ? reduce(data) : [];
//         return children({
//           ...props,
//           data: concatData,
//         });
//       }}
//     </Query>
//   );
// };

// export default QueryComponent;
