import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useMutation } from 'components/graphql';
import styled from 'styled-components';
import { Checkbox, TextAreaStandart } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid.js';
import Formik from 'components/formik';
import { Row, Col } from 'style';

import { cloneDeep } from 'lodash';

import { listFA_ProductionFilterOrder, listFA_ProductionFilterCustomer, listFA_ProductionFilterArticle } from './qql/listFA_ProductionFilter';

import { updateFilterArticle, updateFilterCustomer, updateFilterOrder } from './qql/updateFA_ProductionFilter';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const [t] = useTranslation();
  const { linkId } = props;
  const [isExpertMode, setIsExpertMode] = useState(false);
  const [isAllArticle, setIsAllArticle] = useState(false);
  const [isAllCustomer, setIsAllCustomer] = useState(false);
  const [isAllOrder, setIsAllOrder] = useState(false);
  // const [listCustomersFilter, setListCustomersFilter] = useState([]);
  const { client } = useQuery(listFA_ProductionFilterCustomer);
  const [mutationFilterArticle] = useMutation(updateFilterArticle);
  const [mutationFilterCustomer] = useMutation(updateFilterCustomer);
  const [mutationFilterOrder] = useMutation(updateFilterOrder);

  const valueGroup = props.data && (props.values?.reportGroupID || props.data.reportGroupID);
  const getCheckIsAll = (data) => {
    let isAll = true;
    for (const item of data) {
      if (!item.checked) {
        isAll = false;
        break;
      }
    }

    return (isAll);
  };

  useQuery(listFA_ProductionFilterArticle, {
    variables: { id: +linkId },
    onCompleted: (data) => {
      setIsAllArticle(getCheckIsAll(data.fakturaAssist.listFA_ProductionFilterArticle));
    },
  });

  useQuery(listFA_ProductionFilterCustomer, {
    variables: { id: +linkId },
    onCompleted: (data) => {
      // const newData = data.fakturaAssist.listFA_ProductionFilterCustomer;
      // const newObj = {id: 9999, name: "0", checked: false, __typename: "FA_ProductionFilterCustomer"};
      // newData.splice(1,0,newObj)
      // setListCustomersFilter(data.fakturaAssist.listFA_ProductionFilterCustomer);
      setIsAllCustomer(getCheckIsAll(data.fakturaAssist.listFA_ProductionFilterCustomer));
    },
  });

  useQuery(listFA_ProductionFilterOrder, {
    variables: { id: +linkId },
    onCompleted: (data) => {
      setIsAllOrder(getCheckIsAll(data.fakturaAssist.listFA_ProductionFilterOrder));
    },
  });

  const onChangeIsAll = (type, state, setState) => {
    const data = client.cache.readQuery({
      query: type,
      variables: { id: +linkId },
    }).fakturaAssist[type.definitions[0].name.value];

    const cacheData = cloneDeep(data);

    for (const item of cacheData) {
      item.checked = !state;
    }

    client.writeQuery({
      query: type,
      variables: { id: +linkId },
      data: {
        fakturaAssist: {
          [type.definitions[0].name.value]: cacheData,
          __typename: 'FA_Query',
        },
      },
    });
    setState(!state);
  };

  const onChange = (type, index, value, setState) => {
    const data = client.cache.readQuery({
      query: type,
      variables: { id: +linkId },
    }).fakturaAssist[type.definitions[0].name.value];

    const cacheData = cloneDeep(data);

    cacheData[index].checked = !value;

    client.writeQuery({
      query: type,
      variables: { id: +linkId },
      data: {
        fakturaAssist: {
          [type.definitions[0].name.value]: cacheData,
          __typename: 'FA_Query',
        },
      },
    });

    setState(getCheckIsAll(cacheData));
  };

  const onBlur = (type, mutation, isAll, additionalWords) => {
    const data = client.cache.readQuery({
      query: type,
      variables: { id: +linkId },
    }).fakturaAssist[type.definitions[0].name.value];
    let arr = [];
    if (isAll) {
      arr = null;
    } else {
      for (const item of data) {
        if (item.checked) {
          arr.push(item.id);
        }
      }
      if (additionalWords) {
        arr = additionalWords + '(' + (arr.join() || 0) + ')';
      } else {
        (arr = arr.join() || '0');
      }
    }
    mutation({
      variables: { arr: arr, id: linkId },
    });
  };

  const tableDataArticle = [
    {
      name:
        <div style={{ marginTop: '-47px' }}>
          <Checkbox isWhiteStyle={true} onClick={onChangeIsAll.bind(this, listFA_ProductionFilterArticle, isAllArticle, setIsAllArticle)} withoutFormik value={isAllArticle} />
        </div>,
      dataBase: 'checked',
      relativeWidth: 0.1,
      specialComponent: (index, _, value) => {
        return (
          <div style={{ marginTop: '-44px', marginLeft: '-8px' }}>

            <Checkbox withoutFormik onClick={onChange.bind(this, listFA_ProductionFilterArticle, index, value.props.value, setIsAllArticle)} value={value.props.value} />

          </div>
        );
      },
      disableSort: true,
    },

    {
      name: t('customer.article'),
      dataBase: 'name',
    },
  ];

  const tableDataCustomer = [
    {
      name:
        <div style={{ marginTop: '-47px' }}>
          <Checkbox isWhiteStyle={true} withoutFormik onClick={onChangeIsAll.bind(this, listFA_ProductionFilterCustomer, isAllCustomer, setIsAllCustomer)} value={isAllCustomer} />
        </div>,
      dataBase: 'checked',
      relativeWidth: 0.1,
      specialComponent: (index, _, value) => {
        return (
          <div style={{ marginTop: '-44px', marginLeft: '-8px' }}>

            <Checkbox onClick={onChange.bind(this, listFA_ProductionFilterCustomer, index, value.props.value, setIsAllCustomer)} withoutFormik value={value.props.value} />

          </div>
        );
      },
      disableSort: true,
    },

    {
      name: t('customer.customer'),
      dataBase: 'name',
    },
  ];

  const tableDataOrder = [
    {
      name:
        <div style={{ marginTop: '-47px' }}>
          <Checkbox isWhiteStyle={true} onClick={onChangeIsAll.bind(this, listFA_ProductionFilterOrder, isAllOrder, setIsAllOrder)} withoutFormik value={isAllOrder} />
        </div>,
      dataBase: 'checked',
      relativeWidth: 0.1,
      specialComponent: (index, _, value) => {
        return (
          <div style={{ marginTop: '-44px', marginLeft: '-8px' }}>

            <Checkbox withoutFormik onClick={onChange.bind(this, listFA_ProductionFilterOrder, index, value.props.value, setIsAllOrder)} value={value.props.value} />

          </div>
        );
      },
      disableSort: true,
    },

    {
      name: t('order.orderType'),
      dataBase: 'name',
    },
  ];
  return (
    <Formik initialValues={props.data} enableReinitialize onSubmit={props.mutations} linkId={linkId} sectionInfo={props.sectionInfo}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        return (
          <div>
            <HeadBlock>
              <MainBlock>
                <Row>
                  <Col
                    width='33%'
                    tabindex='11'
                    onBlur={
                      onBlur.bind(
                        this,
                        listFA_ProductionFilterArticle,
                        mutationFilterArticle,
                        isAllArticle,
                        'LinkArticleList in '
                      )
                    }>
                    <div style={{ paddingRight: '20px' }}>
                      <Checkbox margin={'0 0 20px 0'} onClick={() => setIsExpertMode(!isExpertMode)} title={t('production.expertMode')} withoutFormik value={isExpertMode} />

                      <TableGrid
                        withSearch
                        withoutLeftTableBlock
                        graphqlParams={{
                          query: listFA_ProductionFilterArticle,
                          variables: { id: +linkId },
                          typename: 'FA_ProductionFilterArticle',
                        }}
                        height={670}
                        headerText={'All print lists'}
                        tableData={tableDataArticle}
                      />
                    </div>
                  </Col>
                  {isExpertMode && <>
                    <Col
                      width='33%'
                      tabindex='12'
                      onBlur={
                        onBlur.bind(
                          this,
                          listFA_ProductionFilterCustomer,
                          mutationFilterCustomer,
                          isAllCustomer,
                          'LinkCustomerList in '
                        )
                      }>
                      <div style={{ paddingRight: '20px' }}>
                        <Checkbox margin='10px 0 10px 0' value={values.isIncludeOneTimeCustomers} field='isIncludeOneTimeCustomers' title={t('production.oneTimeCustomers')} />
                        <TableGrid
                          withSearch
                          withoutLeftTableBlock
                          // data={listCustomersFilter}
                          graphqlParams={{
                            query: listFA_ProductionFilterCustomer,
                            variables: { id: +linkId },
                            typename: 'FA_ProductionFilterCustomer',
                          }}
                          height={670}
                          headerText={'All print lists'}
                          tableData={tableDataCustomer}
                        />
                      </div>
                    </Col>
                    <Col
                      width='33%'
                      tabindex='13'
                      onBlur={
                        onBlur.bind(
                          this,
                          listFA_ProductionFilterOrder,
                          mutationFilterOrder,
                          isAllOrder,
                          ''
                        )
                      }>
                      <div style={{ paddingRight: '20px', marginTop: '37px' }}>
                        <TableGrid
                          withSearch
                          withoutLeftTableBlock
                          graphqlParams={{
                            query: listFA_ProductionFilterOrder,
                            variables: { id: +linkId },
                            typename: 'FA_ProductionFilterOrder',
                          }}
                          height={670}
                          headerText={'All print lists'}
                          tableData={tableDataOrder}
                        />
                      </div>
                    </Col>
                  </>}

                </Row>
              </MainBlock>
            </HeadBlock>
            {isExpertMode && <>
              <FooterBlock>
                <MainBlock>
                  <Row>
                    <Col width='50%'>
                      <TextAreaStandart
                        key={`filterDefSql${linkId}`}
                        width='95%'
                        value={values.filterDefSql}
                        field='filterDefSql'
                        title={t('production.articleSQLFilter')}
                      />
                    </Col>
                    <Col width='50%'>
                      <TextAreaStandart
                        key={`filterDefCustomerSql${linkId}`}
                        width='95%'
                        value={values.filterDefCustomerSql}
                        field='filterDefCustomerSql'
                        title={t('production.customerSQLFilter')}
                      />
                    </Col>
                  </Row>
                  {
                    ([1, 11, 14, 15, 20, 25, 26, 27, 32, 34, 41, 42, 51, 57, 65, 66].includes(Math.round(props.data.reportID / 1000000)) || [11, 3].includes(valueGroup)) && <Checkbox margin='10px 0 10px 0' value={values.isShowIndividalTexts} field='isShowIndividalTexts' title={t('production.showOnlyIndividualText')} />
                  }
                  {
                    ([1, 11, 14, 15, 20, 25, 26, 27, 32, 34, 41, 42, 51, 57, 65, 66].includes(Math.round(props.data.reportID / 1000000)) || [11, 3].includes(valueGroup)) && <Checkbox margin='10px 0 10px 0' value={values.orderPositionsOnlyIndividualText} field='orderPositionsOnlyIndividualText' title={t('production.considerOnlyIndividualText')} />

                  }
                  {
                    [1, 34, 41, 14, 25, 27, 26].includes(Math.round(props.data.reportID / 1000000)) && <Checkbox margin='10px 0 10px 0' value={values.highlightArticlesFromProductionCalculation} field='highlightArticlesFromProductionCalculation' title={t('production.highlightArticlesFromProductionCalculation')} />

                  }
                  {
                    [1, 34, 41, 14, 25, 27, 26].includes(Math.round(props.data.reportID / 1000000)) && <Checkbox margin='10px 0 10px 0' value={values.isSplitDoughBeforePrint} field='isSplitDoughBeforePrint' title={t('production.splitDoughBeforePrint')} />

                  }
                  {
                    [1, 34, 41, 14, 25, 27, 26].includes(Math.round(props.data.reportID / 1000000)) && <Checkbox margin='10px 0 10px 0' value={values.isShowDeliverySplitting} field='isShowDeliverySplitting' title={t('production.showDeliverySplitting')} />

                  }
                </MainBlock>
              </FooterBlock>
            </>}
          </div>
        );
      }}
    </Formik>
  );
};

const HeadBlock = styled.div`
  height: 800px;
`;
const FooterBlock = styled.div`
  height: 300px;
`;
const MainBlock = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 20px 0px 20px 20px;
  margin-top: 20px;
`;
