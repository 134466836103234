import React from 'react';
import styled from 'styled-components';

export default (WrappedComponent) => (props) =>{
  const { small, width, widthTitle, marginTitle, colorTitle, title } = props;
  const widthInput = widthTitle ? widthTitle : width ? width : small ? 'auto' : '300px';
  //const widthInput = widthTitle ? widthTitle : width ? width : small ? '140px' : '300px';
  if(!title) return <WrappedComponent {...props}/>;
  return (<div style={{ width: widthInput }}>
    {title ? <Text widthInput={widthInput} marginTitle={marginTitle} colorTitle={colorTitle}>{title || '‎'}</Text> : <div/>}
    <WrappedComponent {...props}/>
  </div>);
};

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${(props) => props.colorTitle ? props.colorTitle : '#3c445a'};
  margin: ${(props) => props.marginTitle ? props.marginTitle : '15px 0 6px 0'} ;
  width: ${(props) => props.widthInput};
`;
