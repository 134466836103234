import customer from 'style/header/customer.svg';
import debitor from 'style/header/debitor.svg';
//import procurement from 'style/header/procurement.svg';
import offer from 'style/header/offer.svg';
//import statistic from 'style/header/statistic.svg';
import order from 'style/header/order.svg';
import product from 'style/header/article.svg';
import production from 'style/header/production.svg';
import invoice from 'style/header/invoice.svg';
import options from 'style/header/options.svg';

export const menuObj = (t)=> ([
  {
    link: '/customers/adresse',
    icon: customer,
    text: t('customer.customer'),
    name: 'customers',
  },
  {
    link: '/products/article',
    icon: product,
    text: t('product.product'),
    name: 'products',
  },
  // {
  //   link: '/procurement/suppliers/address',
  //   icon: procurement,
  //   text: t('procurement.procurement'),
  //   name: 'procurement',
  //   subLink: [
  //     {
  //       link: '/procurement/suppliers/address',
  //       text: t('procurement.suppliers.suppliers'),
  //     },
  //     {
  //       link: '/procurement/goods/general',
  //       text: t('procurement.goods.goods'),
  //     },
  //     {
  //       link: '/order/procurement',
  //       text: t('procurement.procurement'),
  //     },
  //   ],
  // },
  {
    link: '/offer/create',
    icon: offer,
    text: t('offer.offer'),
    name: 'offer',
  },
  {
    link: '/order/create',
    icon: order,
    text: t('order.order'),
    subLink: [
      {
        link: '/order/create',
        text: t('order.orderCreate'),
      },
      {
        link: '/order/edit',
        text: t('order.orderEdit'),
      },
      {
        link: '/order/overview/order',
        text: t('order.orderOverview'),
      },
    ],
    name: 'order',
  },
  {
    link: '/production/print/production',
    icon: production,
    text: t('production.production'),
    subLink: [
      {
        link: '/production/print/production',
        text: t('production.productionPrint'),
      },
      {
        link: '/production/edit/general',
        text: t('production.productionEdit'),
      },
      {
        link: '/production/toolbox/toolbox',
        text: t('production.productionToolbox'),
      },
    ],
  },
  {
    link: '/invoice/new',
    icon: invoice,
    text: t('invoice.invoice'),
    subLink: [
      {
        link: '/invoice/new',
        text: t('invoice.newInvoice'),
      },
      {
        link: '/invoice/created',
        text: t('invoice.createdInvoices'),
      },
    ],
    name: 'invoice',
  },
  {
    link: '/debitor/open',
    icon: debitor,
    text: t('debitor.debitor'),
    subLink: [
      {
        link: '/debitor/open',
        text: t('debitor.openDebts'),
      },
      {
        link: '/debitor/payments',
        text: t('debitor.payments'),
      },
      {
        link: '/debitor/import',
        text: t('debitor.importISO'),
      },
    ],
  },
  // {
  //   link: '/statistic/',
  //   icon: statistic,
  //   text: t('statistic.statistic'),
  // },
  {
    link: '/options/company',
    icon: options,
    text: t('options.options'),
    subLink: [
      {
        link: '/options/company',
        text: t('options.company.company'),
      },
      {
        link: '/options/configurations/language',
        text: t('options.configurations.configurations'),
      },
      {
        link: '/options/connections/email',
        text: t('options.connections.connections'),
      },
      {
        link: '/options/import',
        text: t('options.import.import'),
      },
      {
        link: '/options/export',
        text: t('common.export'),
      },
      {
        link: '/options/automation',
        text: t('options.automation.automation'),
      },
      {
        link: '/options/printService',
        // text: t('options.automation.automation'),
        text: 'printService',
      },
      {
        link: '/options/history',
        text: t('history.history'),
      },
      {
        link: '/options/securityManager',
        text: t('options.securityManager.securityManager'),
      },
      {
        link: '/options/settings',
        text: t('common.settings'),
      },
    ],
  },

]);
