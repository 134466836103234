import React from 'react';
import styled from 'styled-components';
import { Row } from 'style';
import moment from 'moment';

import { tableData } from './tableData';

import DateRange from 'components/elements/dateRange/DateRange';
import Query from 'components/graphql/Query';
import { Formik } from 'formik';
import SelectionTable from 'components/selectionTable/SelectionTable';

import { listFA_OverviewProductAllProductNumbers, listFA_OverviewProductGetProductByNumber } from '../../qql/query';

export default ({ t }) =>(
  <Formik initialValues={{ date: moment(), secondDate: moment(new Date()).add(1, 'days') }}>
    {({ handleSubmit, handleChange, values, setFieldValue }) =>{
      return (
        <>
          <ArgsBlock>
            <Row>
              <DateRange startField='date' endField='secondDate' values={values} t={t} withoutFormik onChange={setFieldValue}/>
            </Row>
          </ArgsBlock>
          <TableBlock>
            <Query
              query={listFA_OverviewProductAllProductNumbers}
              variables={{ args: { date: values.date, secondDate: values.secondDate } }}
            >
              {({ data, loading, error }) => {
                return (
                  <SelectionTable
                    error = {error}
                    data={data.items}
                    tableData={tableData({ totalSum: data.totalSum, t })}
                    loading={loading}
                    prevText='Kunder Nr:'
                    elementQuery={listFA_OverviewProductGetProductByNumber}
                    values={values}
                    t={t}
                    withTotal
                  />);
                  
              }}
            </Query>
          </TableBlock>
         
        </>);
    }}
  </Formik>
);

const ArgsBlock = styled.div`
  background: #f4f4f4;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
  padding: 0 20px;
`;

const TableBlock = styled.div`
  margin: 20px 0px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(90, 90, 90, 0.2);
  background-color: #f4f4f4;
`;
