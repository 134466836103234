import gql from 'graphql-tag';

export default gql`
query listFA_ReductionsAndPricesTakeFromKinds($languageId: Int){
    fakturaAssist{
    listFA_ReductionsAndPricesTakeFromKinds(languageId: $languageId){
		id
		name
    }
    
  }
}`;
