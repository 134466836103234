import products from './gql/allArticles';
import product from './gql/getArticles';
import { UPDATE_PRODUCT } from './article/gql/index';

import Article from './article/index';
import Note from './note/index';
import List from './list/index';
import Availability from './availability/index';
import Production from './production/index';
import CashAssist from './cashAssist/index';
// eslint-disable-next-line no-unused-vars
import Sprache from './sprache/index';
import Post from './post/index';
import Label from './label/index';
import GET_FA_PRODUCT_FILTER from './gql/getFA_ProductFilter';

import createProduct from './createProduct';

export const params = (t) => [
  {
    name: t('common.number'),
    dataBase: 'productNumber',
    relativeWidth: 0.3,
  },
  {
    name: t('common.description'),
    dataBase: 'name',
  },
];

const defaultPDFParams = (item) => {
  return ({
    reportId: '36',
    AArticlesList: Array.isArray(item) ? item.join(', ') : `${item}`,
    APriceDate: null,
    ACustomerID: null,
    APriceCategory: null,
    AUserFormularID: '0',
  });
};

const filterQuery = {
  query: GET_FA_PRODUCT_FILTER,
};

const filterData = (t) => [{
  title: t('common.filter.activeProducts'),
  field: 'IsAktiviert',
  value: 1,
  subItems: [
    {
      title: t('common.list'),
      field: 'Intern',
      queryField: 'listFA_ProductLists',
      titleField: 'name',
      valueField: 'relatedArticles',
      sortOrder: 'asc',
    },
    {
      title: t('product.discountGroups'),
      field: 'LinkGruppe',
      queryField: 'listFA_ProductGroup1[0].productGroup',
      indexAsValue: true,
      sortOrder: 'none',
    },
    {
      title: t('product.productionGroups'),
      field: 'LinkGruppe2',
      queryField: 'listFA_ProductGroup2',
      titleField: 'name',
      valueField: 'id',
    },
    {
      title: t('product.marketingGroups'),
      field: 'LinkGruppe3',
      queryField: 'listFA_ProductGroup3',
      titleField: 'name',
      valueField: 'id',
    },
  ],
}, {
  title: t('common.filter.inactiveProducts'),
  field: 'IsAktiviert',
  value: 0,
  subItems: [
    {
      title: t('common.list'),
      field: 'Intern',
      queryField: 'listFA_ProductLists',
      titleField: 'name',
      valueField: 'relatedArticles',
      sortOrder: 'asc',
    },
    {
      title: t('product.discountGroups'),
      field: 'LinkGruppe',
      queryField: 'listFA_ProductGroup1[0].productGroup',
      indexAsValue: true,
      sortOrder: 'none',
    },
    {
      title: t('product.productionGroups'),
      field: 'LinkGruppe2',
      queryField: 'listFA_ProductGroup2',
      titleField: 'name',
      valueField: 'id',
    },
    {
      title: t('product.marketingGroups'),
      field: 'LinkGruppe3',
      queryField: 'listFA_ProductGroup3',
      titleField: 'name',
      valueField: 'id',
    },
  ],
}];

const filterTypeName = JSON.parse(localStorage.getItem('products'));

export default (t) => ({
  name: t('customer.article'),
  link: 'products',
  leftGridColors: {
    isTitle: ({ isDough, isTitle, isFermentationInterrupt }) => isDough && isTitle && isFermentationInterrupt ? '#CBC3E3' : isDough && isTitle ? '#1EA1A1' : isTitle && !isDough ? '#D0E78B' : null, // light-lime
    isBruchTitel: '#FFFFE0', // light-yellow
    isDough: '#1EA1A1', // light-teal
    isFermentationInterrupt: '#CBC3E3', // light-purple
  },
  multipleSelect: true,
  listQuery: products,
  itemQuery: product,
  tableData: params(t),
  updateItem: UPDATE_PRODUCT,
  filterData: filterData(t),
  filterQuery,
  defaultFilterParams: { filter: filterTypeName || [{ column: 'IsAktiviert', value: '1' }] },
  mainItem: 'id',
  textHead: 'name',
  disableSearch: false,
  searchColumns: ['ArtikelNr', 'Bezeichnung'],
  defaultPDFParams: defaultPDFParams,
  reportForms: [6, 36, 50],
  actions: ['printReport', 'preview', 'reportForms', 'history'],
  historyFormTypeID: 3,
  withDefaultFunction: true,
  withCacheAddSection: true,
  withDefaultParams: true,
  ComponentForCreating: createProduct,
  subSection: [
    {
      name: t('customer.article'),
      link: 'article',
      Component: Article,
      transitions: [false, false, false, false],
    },
    {
      name: t('common.note'),
      link: 'note',
      Component: Note,
    },
    {
      name: t('common.list'),
      link: 'list',
      Component: List,
    },
    {
      name: t('product.availability'),
      link: 'availability',
      Component: Availability,
      transitions: [false, false],
    },
    {
      name: t('production.production'),
      link: 'production',
      Component: Production,
      transitions: [false, false, false, false, false],
    },
    {
      name: 'CashAssist',
      link: 'cashAssist',
      Component: CashAssist,
    },
    {
      name: t('product.label'),
      link: 'label',
      Component: Label,
    },
    // {
    //   name: t('common.language'),
    //   link: 'sprache',
    //   Component: Sprache,
    // },
    {
      name: t('customer.post.post'),
      link: 'post',
      Component: Post,
    },
  ],
});
