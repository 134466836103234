
import gql from 'graphql-tag';

export default gql`
mutation createFA_CustomerResellerPrices($fields: CustomerResellerPricesFields!){
    fakturaAssist{
    createFA_CustomerResellerPrices(fields: $fields){
		customerId
		productId
		price
		note
		id
		productNm
		description
		
    }
    
  }
}`;
