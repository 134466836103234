import gql from 'graphql-tag';

export default gql`
query getCountOrders($fromDate: String!, $toDate: String!){
    fakturaAssist{
	    getCountOrders(fromDate: $fromDate, toDate: $toDate){
			RecordCount
		}
  	}
}`;
