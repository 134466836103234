import gql from 'graphql-tag';

export default gql`
query listFA_OrdersInCreateInvoice($args: FA_OrdersInCreateInvoiceInputs!){
    fakturaAssist{
    	listFA_OrdersInCreateInvoice(args: $args){
    		id
			date
			customerNr
			name
        	orderNr
      		total
 		}
 	}
}`;
