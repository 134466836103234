import React from 'react';
import styled from 'styled-components';

import FooterIcon from './FooterIcon.js';

import LogoutIcon from 'style/img/footer/exit.svg';
import footerLogo from 'style/img/footer/logo-faktura-assist-footer.svg';

import { version } from '../../../package.json';

const Footer = (props) => (
  <Body>
    <LogoBlock>
      <StyleImg alt='' src={footerLogo}/>
      <span>{version}</span>
    </LogoBlock>
    <div>
      <ActionBlock><FooterIcon text={'Beenden'} icon={LogoutIcon} onClick={props.signOut}/></ActionBlock>
    </div>
  </Body>
);

export default Footer;

const StyleImg = styled.img`
  width: 166px;
  height: 26px;
`;

const Body = styled.div`
  height: 48px;
  width: 100%;
  background: #434343;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  margin-top: 40px;
  z-index: 200;
  position: fixed;
  bottom: 0px;
  width: 101%;
	margin-left:-3px;
	max-width: 1930px;
	min-width: 1050px;
`;

const ActionBlock = styled.div`
  display: inline-block;
`;

const LogoBlock = styled.div`
  color: #ffffff;
  margin-top: -3px;
  span{
    display: inline-block;
    position: absolute;
    padding: 4px 10px 0 10px;
  }
`;
