import gql from 'graphql-tag';

export default gql`
mutation deleteFA_SpecialPrices($id: ID!){
    fakturaAssist{
    deleteFA_SpecialPrices(id: $id){
		customerId
    }
    
  }
}`;
