import { Auth } from 'aws-amplify';
import { setContext } from 'apollo-link-context';

export const withAuth = setContext(async (_, { headers, clientAwareness = {} }) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      ...headers,
      'apollographql-client-name': 'FakturaAssist',
      'apollographql-client-version': '1.0',
      'Authorization': token,
      //'Authorization': 'eyJraWQiOiJWaDZjeE1kWkwxWWpRczlLSDR6WEZhSEhlMno0V1BhT2VuOWJxVHZzZWpVPSIsImFsZyI6IlJTMjU2In0.eyJjdXN0b206YWNjZXNzX2N1c3RvbV9yaWdodHMiOiJ7fSIsInN1YiI6IjJkMWU3N2U5LTllNDgtNDA0ZS04MWQ5LTM3YTZhMDZjZWM0NCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJjdXN0b206aW5zdGFuY2UiOiIxMjU3YTcxYWU4OWM3Y2E3YzVhMjIzNGZhMGZlYTIzODNiOTEzZjI4YWFkMjc0YjM0ZjZlY2FiY2Y3YWU1MGE1IiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfWlFBS3VmVVZaIiwiY29nbml0bzp1c2VybmFtZSI6Im5sdWthc2hldl9oc3NvZnQuY29tIiwiY3VzdG9tOmNvbXBhbnkiOiI2YTQwN2QyMy1iMGRmLTQyOWUtYWJjOS1jNTBjZDYyNGUzZTEiLCJjdXN0b206YWNjZXNzX3JpZ2h0cyI6IntcImNoY19zYVwiOlwidHJ1c3RlZFwifSIsImF1ZCI6IjF0MmozNWk4NHVqOGVrdnIyOW5wMDJuOWs3IiwiZXZlbnRfaWQiOiI4YjBmOGNiNi00ZjdlLTQwMTUtOGI5ZC0wMDg4ODIxZjI4Y2MiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyNTU3ODg4OCwibmFtZSI6Ik5pa2l0YSBMdWthc2hldiIsImV4cCI6MTYyNzkwMjcxNywiY3VzdG9tOnJvbGUiOiJhZG1pbiIsImlhdCI6MTYyNzg5OTExNywiZW1haWwiOiJubHVrYXNoZXZAaHNzb2Z0LmNvbSJ9.f5XAHitG0iBqlBkRM-xLpx2s4IeT25cxpqHlexOHUgbAVJQ9NSH75CgVvnaFMgT-PvGGGikIEHrX7Y3_9uJfNo8d24w--chXsGIiTRpAqnmQX7vAxKF4MkYPcO6EebMcn0TJCA94_MGGFNLLucXodoP0JflhTr4_62pF5QdizpZTIcSqrkpGAce8zaRXpnGFn_1f4fuRzNSr4RopdloEp-dEn7mVOrk7VPrp78tHyAb2mn_DPXxwHrknnhWOvsCEwwcwiZ2Ct3vS8Kw2JpnvnFUVcsgZFFttZhQD-FOzxLOFGBvTA7wHfolct4CahJlQ5NzIxHIHNW0_Roe17KuyEw',
    },
  };
});

export const getSubscriptionParams = async () => {
  return {
    authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  };
};

