import React from 'react';
import QueryComponent from 'components/graphql/Query';
import PageNotFound from '../../PageNotFound';
import { cloneDeep } from 'lodash';

import { Main } from '../MainSection';

const withQuery = (WrappedComponent) => (props) => {
  const { contex, withVirtualized = true } = props;
  const currentContex = cloneDeep({ ...contex, params: { ...contex.params, ...(withVirtualized ? { limit: Infinity } : {}) } });
  // fix wrong dropdown behaviour at product filter section
  if (props.mainsection === 'products') {
    const internIdx = currentContex.filter?.findIndex((f) => f.column === 'Intern');
    if (internIdx > 0) {
      const newVal = currentContex.filter[internIdx].value?.split('-')?.[1];
      currentContex.filter[internIdx].value = newVal !== '[]' ? newVal : null;
    }
  }
  return props.sectionInfo.listQuery ? (
    <QueryComponent
      variables={currentContex}
      fetchPolicy={'network-only'}
      query={props.sectionInfo.listQuery}
    >
      {({ data, loading, error, networkStatus, queryField, fetchMore, ...restQuery }) => {
        if (error) {
          if (error.toString().includes('Network')) {
            return <Main><PageNotFound type='NETWORK_ERROR' /></Main>;
          } else {
            return <div> Error. {error.toString()}</div>;
          }
        } else if (!props.linkId && !loading) {
          props.makeRedirect();
        }

        return <WrappedComponent {...props} queryName={props.sectionInfo.listQuery.definitions[0].name.value} fetchMore={withVirtualized ? null : fetchMore} data={props.data ? props.data : data} loading={loading} error={error} />;
      }}
    </QueryComponent>
  ) : <WrappedComponent {...props} />;
};
export default withQuery;
