import gql from 'graphql-tag';

export default gql`
query listFA_GetOfferProducts($args: FA_GetOfferProductsInputs!){
    fakturaAssist{
    	listFA_GetOfferProducts(args: $args){
    		id
    		productTable{
    			id
     			productLink
     			productLinkLookNr
     			productLinkLookName
     			quantity
     			labelRecipeLink
     			virtualPositionLink
     			orderPositionID
				taxCode
				price
				total
				discount
				discountSpec
				weight
				productNr
				productName
				addition
				fax
				email
				internet
				additive
				indTextProduction
				indTextDeliveryNote
			}
 		}
 	}
}`;
