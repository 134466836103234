import gql from 'graphql-tag';
//Used in information block on top
export default gql`
mutation ($taskId: Int!){
    fakturaAssist{
    	checkFA_ReportStatus(taskId: $taskId){
	    	error
            state{
                id
                name
            }
	 	}
 	}
}`;
