
import getArticles from '../../gql/getArticles';
import { readQuery, writeQuery } from 'helpers/queryOperations';
import { cloneDeep } from 'lodash';

const imageActions = (mutation, id, client) => {
  const variables = { id: parseInt(id) };

  const importFile = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      const result = await reader.result;
      try {
        await mutation({
          variables: {
            file: result,
            id,
          },
        });
        const productData = readQuery({ client, query: getArticles, variables }, true).fakturaAssist.getProduct;
        const cacheData = cloneDeep(productData);
        cacheData.productImage = result.split(',').pop();
        writeQuery({ client, query: getArticles, variables, changedData: cacheData });
      } catch (e) {
        console.error(e);
      }
    };
    reader.onerror = () => {
      console.log(reader.error);
    };
  };
  const deleteImage = async () => {
    try {
      await mutation({
        variables: {
          id,
        },
      });
      const productData = readQuery({ client, query: getArticles, variables }, true).fakturaAssist.getProduct;
      const cacheData = cloneDeep(productData);
      cacheData.productImage = '';
      writeQuery({ client, query: getArticles, variables, changedData: cacheData });
    } catch (e) {
      console.error(e);
    }
  };

  const uploadImage = (e) => {
    importFile(e);
  };
  return({
    deleteImage: deleteImage,
    uploadImage: uploadImage,
  });
};

export default imageActions;
