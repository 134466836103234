import React from 'react';
import styled from 'styled-components';
import Loading from 'components/Loading/LoadingModal';
import FormikComponent from 'components/formik';

import CrossTable from './CrossTable';

export default (props) => {
  const arrCustomer = [{ id: 1, name: 'Customer' }, { id: 2, name: 'Customer List' }];
  const arrDelivery =
    [
      { id: null, name: 'Alle' },
      { id: '1', name: 'Lieferschein' },
      { id: '2', name: 'Nachlieferung' },
      { id: '3', name: 'Ruestliste' },
      { id: '4', name: 'Retour' },
      { id: '5', name: 'Gutschrift' },
      { id: '6', name: 'Sonderbestellung' },
    ];
  const arrSplit =
    [
      { id: null, name: 'Alle' },
      { id: 'nothing', name: 'Keine' },
      { id: '1', name: '1' },
      { id: '2', name: '2' },
      { id: '3', name: '3' },
      { id: '4', name: '4' },
      { id: '5', name: '5' },
      { id: '6', name: '6' },
      { id: '7', name: '7' },
      { id: '8', name: '8' },
      { id: '9', name: '9' },
      { id: '10', name: '10' },
    ];
  return (
    (!props.loading && props.data.id) ? <FormikComponent enableReinitialize initialValues={props.data} onSubmit={props.mutations} timeoutSubmit={4000}>
      {({ values, setFieldValue }) =>{
        return(
          values.id && <CrossTable values = { values } setFieldValue={setFieldValue} arrSplit={arrSplit} arrDelivery={arrDelivery} arrCustomer={arrCustomer} linkId={props.linkId}/>
        );
      }}
    </FormikComponent> : <LoadingWrapper><Loading /></LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;


