import { useQuery } from '@apollo/react-hooks';

import reduce from 'helpers/reduceGraphqlData.js';

const QueryHOC = (query, { queryRef, fetchPolicy, children, variables, onCompleted, typename, ...props }) => {
  const { data, ...queryProps } = useQuery(query, {
    ref: queryRef,
    fetchPolicy: fetchPolicy || 'cache-and-network',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    __typename: typename,
    variables,
    onCompleted,
    ...props,
  });
  const concatData = !queryProps.loading && !queryProps.error && data ? reduce(data) : [];

  return {
    data: concatData,
    ...queryProps,
  };
};

export default QueryHOC;

// const QueryComponent = ({ query, skip, queryRef, fetchPolicy, children, ...props }) => {
//   if(skip) return children({ data: [] });
//   return(
//     <Query
//       ref={queryRef}
//       query={query}
//       fetchPolicy={fetchPolicy || 'cache-and-network'}
//       errorPolicy='all'
//       {...props}
//     >
//       {(props) => {
//         const { data, loading, error } = props;
//         const concatData = !loading && !error && data ? reduce(data) : [];
//         return children({
//           ...props,
//           data: concatData,
//         });
//       }}
//     </Query>
//   );
// };
 
// export default QueryComponent;
