
import styled from 'styled-components';
import { Input } from 'components/elements';
import React from 'react';

export default (props) =>{
  const { index, additionalFunc, type, value, id, columnIndex, count, isExpertMode } = props;

  const onKeyDown = (e) =>{
    if(e.shiftKey && e.keyCode === 13) {
      if(index !== 0) {
        document.getElementById(`${index - 1}-${columnIndex}`) && document.getElementById(`${index - 1}-${columnIndex}`).focus();
      }
    } else if(e.keyCode === 13) {
      if(index !== count) {
        document.getElementById(`${index + 1}-${columnIndex}`) && document.getElementById(`${index + 1}-${columnIndex}`).focus();
      }
    } else if(e.shiftKey) {
      if(columnIndex === 1 && index !== 0) {
        document.getElementById(`${index - 1}-${!isExpertMode ? 5 : 7}`) && document.getElementById(`${index - 1}-${!isExpertMode ? 5 : 7}`).focus();
      } else{
        document.getElementById(`${index}-${columnIndex - 1}`) && document.getElementById(`${index}-${columnIndex - 1}`).focus();
      }
    }
  };
  return (
    <div>
      <StyledInput
        key={id}
        type={type}
        onKeyDown = {(event)=>{
          onKeyDown(event);
        }}
        additionalFunc = {additionalFunc}
        value={value}
        {...props}
      />
    </div>
  );
};

const StyledInput = styled(Input)`
  width: ${(props) => props.width ? props.width : '90%'};
`;
