import reduce from './reduceGraphqlData';
export const writeQuery = ({ client, query, variables, changedData }) =>{
  try{
    const queryName = query.definitions[0].name.value;
    client.writeQuery({
      query: query,
      variables: variables,
      data: {
        fakturaAssist: {
          [queryName]: changedData,
          __typename: 'FA_Query',
        },
      },
    });
  }catch(err) {
    //console.log(err);
  }
};

export const readQuery = ({ client, query, variables }, fullData) =>{
  try{
    const data = client.cache.readQuery({
      query: query,
      variables: variables,
    });
    return fullData ? data : (reduce(data));
  }catch(err) {
    //console.log(err);
    return [];
  }
};

