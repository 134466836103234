import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'style';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { isObject } from 'lodash';

import { LoadingWrap } from 'style/default';
import { Toggle } from 'components/elements';
import TableGrid from 'components/tableGrid/TableGrid';
import { Modal } from 'components/elements';
import { tableData } from './tableParams';

import listFA_History from './listFA_History';
import listFA_HistoryDeleted from './listFA_HistoryDeleted';

const HistoryModal = ({ isOpen, t, closeModal, historyFormTypeID, ...prev }) => {
  return (
    <Modal
      isOpen={isOpen}
      withoutConfirmModal
      headerText={t('history.history')}
      width='85%'
      height='85%'
      closeModal={closeModal}
      Component={<HistoryModalComponent t={t} historyFormTypeID={historyFormTypeID} {...prev} />}
    />
  );
};

const HistoryModalComponent = ({ historyFormTypeID, recordId = 0 }) => {
  const [t] = useTranslation();
  const [history, setHistory] = useState('-1');
  const [isShowDeletedHistory, setIsShowDeletedHistory] = useState(false);

  const getVariables = (str) => ({
    id: historyFormTypeID,
    params: {
      limit: Infinity,
      search: { columns: ['recordID'], string: str },
      sort: [{ field: 'dOperation', order: 'DESC' }],
    },
  });
  
  const { data: historyDeletedData } = useQuery(listFA_HistoryDeleted, {
    variables: { id: historyFormTypeID, params: { sort: [{ field: 'dOperation', order: 'DESC' }] } },
    onCompleted: ({ fakturaAssist: { listFA_HistoryDeleted } }) => {
      if(listFA_HistoryDeleted.length) {
        setHistory(listFA_HistoryDeleted[0]);
      }
    },
  });

  const { data: historyData, loading, refetch } = useQuery(listFA_History, {
    variables: getVariables(`${recordId}`),
  });
 
  useEffect(() => {
    if(isObject(history) && isShowDeletedHistory) {
      refetch(getVariables(history?.recordID ? `${history?.recordID}` : '-1'));
    } else if(!isObject(history) && isShowDeletedHistory) {
      refetch(getVariables('-1'));
    } else if(!isShowDeletedHistory) {
      refetch(getVariables(`${recordId}`));
    }
  }, [history, isShowDeletedHistory]);

  return (
    <Wrapper>
      <Row>
        <BlockOfText bottom='20px'>
          <Toggle
            withoutFormik
            onClick={() => setIsShowDeletedHistory(!isShowDeletedHistory)}
            value={!isShowDeletedHistory}
            textOn={t('history.currentEntry')}
            textOff={t('history.deletedEntries')}
          />
        </BlockOfText>
      </Row>
      {isShowDeletedHistory &&
        <Row style={{ margin: '0 0 20px 0' }}>
          <Col width='100%'>
            <TableGrid
              changeSelectId={(item) => setHistory(item)}
              linkId={history?.id}
              selectById
              data={historyDeletedData?.fakturaAssist?.listFA_HistoryDeleted || []}
              tableData={tableData(t)}
              height={200}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col width='100%'>
          <LoadingWrap loading={loading}>
            <TableGrid
              data={historyData?.fakturaAssist?.listFA_History || []}
              tableData={tableData(t)}
              height={isShowDeletedHistory ? 300 : 400}
            />
          </LoadingWrap>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default HistoryModal;

HistoryModal.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  padding: 20px;
`;

const BlockOfText = styled.div`
  padding-top: ${(props) => props.top ? props.top : '5px'};
  padding-bottom: ${(props) => props.bottom ? props.bottom : '0'};
  pointer-events: ${(props) => props.disabled ? 'none' : 'auto'};
  opacity: ${(props) => props.disabled ? '0.5' : '1'};
`;

/*
  Example
    <ErrorModal errorText='Ops'/>
*/

