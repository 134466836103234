import React from 'react';
import { Row, Col } from 'style';
// import { useMutation } from 'components/graphql';
import { Button } from 'components/elements';
import { useTranslation } from 'react-i18next';

// import Formik from 'components/formik';

export default (props) => {
  const { onSelect, handleClose, onSendPDFPrint, withPrinting, onShowPDFPrint, printingServiceAvailable, onShowPDFPrintServerless } = props;
  const [t] = useTranslation();
  return(
    <Row>
      {!withPrinting
        ?
        <Row><Col width='50%'>
          <div >
            <Button
              style={{ marginRight: '15px', float: 'right' }}
              className={'grey-btn'}
              width={'190px'}
              onClick={handleClose}
              value={t('common.cancel')}/>
          </div>
        </Col>
        <Col width='50%'>
          <div >
            <Button
              style={{ marginLeft: '15px', float: 'left' }}
              className={'grey-btn'}
              width={'190px'}
              onClick={onSelect}
              value={t('common.save')}/>
          </div>
        </Col>
        </Row>
        :
        printingServiceAvailable ?
          <Row><Col width='33%'>
            <div >
              <Button
                style={{ marginLeft: '15px', float: 'left' }}
                className={'grey-btn'}
                width={'190px'}
                onClick={onSelect}
                value={t('common.close')}/>
            </div>
          </Col>
          <Col width='33%'>
            <div >
              <Button
                style={{ marginLeft: '15px', float: 'left' }}
                className={'transparent'}
                width={'190px'}
                onClick={()=>{
                  handleClose();
                  onShowPDFPrint();
                }}
                value={t('actions.generatePDF')}/>
            </div>
          </Col>
          <Col width='33%'>
            <div >
              <Button
                style={{ marginLeft: '15px', float: 'left' }}
                className={'black'}
                width={'190px'}
                onClick={onSendPDFPrint}
                value={t('actions.printReport')}/>
            </div>
          </Col>
          </Row>
          :
          <Row>
            <Col width='33%'>
              <div >
                <Button
                  style={{ marginLeft: '15px', float: 'left' }}
                  className={'grey-btn'}
                  width={'190px'}
                  onClick={onSelect}
                  value={t('common.close')}/>
              </div>
            </Col>
            <Col width='33%'>
              <div >
                <Button
                  style={{ marginLeft: '15px', float: 'left' }}
                  className={'transparent'}
                  width={'190px'}
                  onClick={()=>{
                    handleClose();
                    onShowPDFPrint();
                  }}
                  value={`${t('actions.generatePDF')} from Windows`}/>
              </div>
            </Col>

            <Col width='33%'>
              <div >
                <Button
                  style={{ marginLeft: '15px', float: 'left' }}
                  className={'transparent'}
                  width={'190px'}
                  onClick={()=>{
                    handleClose();
                    onShowPDFPrintServerless();
                  }}
                  value={`${t('actions.generatePDF')} from Serverless`}/>
              </div>
            </Col>
          </Row>
      }
    </Row>
  );
};

