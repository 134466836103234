import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash';
import { writeQuery } from 'helpers/queryOperations';
import { Row, Col } from 'style';

import { Transition, Radio, Input, Button, Checkbox } from 'components/elements';
import Formik from 'components/formik';

export default ({ t, configData, onSubmit, client, getConfigQuery }) => {
  const [radio, setRadio] = useState('ownEmailAddress');

  const standart = {
    SMTPServer: 'email-smtp.eu-west-1.amazonaws.com',
    SMTPPort: 587,
    SMTPUser: 'AKIAIYCZZAC4NHZYPM2Q',
    SMTPPassword: 'Avcmu/2fRmZ0hQ5rN2g80xuZLSoAyam9bng6paPk2vfv',
    SMTPUseSSL: true,
    SMTPSenderAddress: 'noreply@bakery2b.com',
  };

  const empty = {
    SMTPServer: '',
    SMTPPort: 587,
    SMTPUser: '',
    SMTPPassword: '',
    SMTPUseSSL: true,
    SMTPSenderAddress: 'you@domain.com',
  };

  const handleConfigChange = (config) => {
    const emailConfig = {
      SMTPServer: config.SMTPServer,
      SMTPPort: config.SMTPPort,
      SMTPUser: config.SMTPUser,
      SMTPPassword: config.SMTPPassword,
      SMTPUseSSL: config.SMTPUseSSL,
      SMTPSenderAddress: config.SMTPSenderAddress,
    };

    const standartCopy = { ...standart };
    
    if (isEqual(emailConfig, standartCopy)) {
      setRadio('standard');
    } else {
      delete emailConfig.SMTPSenderAddress;
      delete standartCopy.SMTPSenderAddress;
      if (isEqual(emailConfig, standartCopy)) {
        setRadio('ownEmailAddress');
        return;
      } else {
        setRadio('ownEmailServer');
        return;
      }
    }
  };

  useEffect(() => {
    handleConfigChange(configData);
  }, []);

  const writeToCache = (emailConfig) => {
    const newConfig = { ...configData, ...emailConfig };
    writeQuery({ client, query: getConfigQuery, variables: { id: 1 }, changedData: newConfig });
  };

  const mutate = async (values) => {
    const newEmailConfig = {
      SMTPServer: values.SMTPServer,
      SMTPPort: values.SMTPPort,
      SMTPUser: values.SMTPUser,
      SMTPPassword: values.SMTPPassword,
      SMTPUseSSL: values.SMTPUseSSL,
      SMTPSenderAddress: values.SMTPSenderAddress,
      SMTPReceiverAddress: values.SMTPReceiverAddress,
    };
    const oldConfig = { ...configData };
    try {
      writeToCache(newEmailConfig);
      onSubmit(newEmailConfig);
    } catch (e) {
      writeQuery({ client, query: getConfigQuery, variables: { id: 1 }, changedData: oldConfig });
    }
  };

  const handleRadioChange = (id) => {
    switch(id) {
      case 0:
        mutate(standart);
        break;
      case 1:
        const standartCopy = { ...standart };
        standartCopy.SMTPSenderAddress = '';
        writeToCache(standartCopy);
        break;
      case 2:
        writeToCache(empty);
        break;
      default:
        return;
    }
  };

  const renderEmailConfiguration = (radioName, values) => {
    switch(radioName) {
      case 'ownEmailAddress':
        return (
          <Row>
            <Col lg={4}>
              <Input title ={t('options.connections.email.emailSender')} value={values.SMTPSenderAddress} field='SMTPSenderAddress' placeholder='you@domain.com'/>
            </Col>
            <Col lg={1}>
              <ButtonBlock><Button value={t('options.connections.email.verify')} upperCase/></ButtonBlock>
            </Col>
          </Row>
        );
      case 'ownEmailServer':
        return (
          <Row>
            <div>
              <Input title ={t('options.connections.email.emailSender')} value={values.SMTPSenderAddress} field='SMTPSenderAddress'/>
              <Input title ={`SMTP ${t('options.connections.server')}`} value={values.SMTPServer} field='SMTPServer'/>
              <Input title ={`SMTP ${t('common.user')}`} value={values.SMTPUser} field='SMTPUser'/>
              <Input title ={`SMTP ${t('options.connections.password')}`} value={values.SMTPPassword} field='SMTPPassword'/>
              <Row>
                <Col lg={2}>
                  <Input title ={`SMTP ${t('options.connections.port')}`} type='number' small value={values.SMTPPort} field='SMTPPort'/>
                </Col>
                <Col lg={2}>
                  <Checkbox title={t('options.connections.useSsl')} value={values.SMTPUseSSL} field='SMTPUseSSL'/>
                </Col>
              </Row>
            </div>
          </Row>
        );
      default:
        return (
          <Row>
            <Col lg={5}>
              <Input title ={t('options.connections.email.emailSender')} value={values.SMTPSenderAddress} field='SMTPSenderAddress' disabled/>
            </Col>
          </Row>
        );
    }
  };
  return (
    <Formik initialValues={{ ...configData }} onSubmit={mutate}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        return (
          <>
            <Transition text={t('options.connections.email.emailConfiguration')} isOpen>
              <>
                <Row>
                  <RadioBlock> <Radio title ={t('options.connections.email.standart')} name ='standard' checked={radio === 'standard'} onClick={(e) => handleRadioChange(0) }/></RadioBlock>
                  <RadioBlock > <Radio title ={t('options.connections.email.ownEmailAdress')} name ='ownEmailAddress' checked={radio === 'ownEmailAddress'} onClick={(e) => handleRadioChange(1)}/> </RadioBlock>
                  <RadioBlock > <Radio title ={t('options.connections.email.ownEmailServer')} name ='ownEmailServer' checked={radio === 'ownEmailServer'} onClick={(e) => handleRadioChange(2)}/> </RadioBlock>
                </Row>
                {renderEmailConfiguration(radio, values)}
              </>
            </Transition>
            <Transition text={t('options.connections.email.emailTest')} isOpen>
              <Row>
                <Col lg={4}>
                  <Input title ={t('options.connections.email.emailReceiver')} value={values.SMTPReceiverAddress} field='SMTPReceiverAddress'/>
                </Col>
              </Row>
              <Row>
                <ButtonBlock lg={4} margin='20px 0 0 0'><Button value={t('options.connections.email.testEmail')} width='300px' upperCase/></ButtonBlock>
              </Row>
            </Transition>
            
          </>
        );
      }}
    </Formik>
  );
};

const ButtonBlock = styled.div`
  margin: ${({ margin }) => margin ? margin : '34px 0 0 0 '};
`;

const RadioBlock = styled(Col)`
  padding: 0 60px 0 0;
`;

