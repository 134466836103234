import ImportComponent from './PrintService';
import { getFA_Config } from '../qql/query';
import { updateFA_Config } from '../qql/mutation';

export default () => ({
  link: 'printService',
  subsections: [],
  itemQuery: getFA_Config,
  updateMutation: updateFA_Config,
  Component: ImportComponent,
});

