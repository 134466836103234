import React, { useState, useRef, useEffect, useCallback } from 'react';
import { isEqual, get, debounce } from 'lodash';
import { useFormik } from 'formik';
import { deepDiff } from './utils';
import { compose } from 'recompose';
import withHistoryMutation from './withHistoryMutation';

const FormikSubmit = React.createContext();

const Formik = (props) => {
  const { children, formikRef, onSubmit, sectionInfo, mutateHistory, timeoutSubmit, updateWithoutId, customHandleChange, customState } = props;
  const [linkId, setLinkId] = useState(null);
  const itemRef = useRef(null);

  const formikApi = useFormik({
    initialValues: {},
    onSubmit: onSubmit,
    ref: formikRef,
    enableReinitialize: true,
  });
  // useEffect(() => {

  // }, [props.initialValues]

  const { initialValues, values, setFieldValue, submitForm } = formikApi;
  useEffect(() => {
    //setInitVal(props.initialValues);
    if (updateWithoutId) {
      formikApi.setValues(props.initialValues);
    } else if(props.initialValues?.id !== itemRef?.current) {
      itemRef.current = props.initialValues.id;
      // console.log(props.initialValues)
      formikApi.setValues(props.initialValues);
    }
  }, [props.initialValues]);

  const isSubmitting = useRef(false);
  const submit = useRef();
  const valuesRef = useRef({});

  // console.log('VALUES', values)

  const valuesChanged = useCallback((field, triggerFrom) => {
    // console.log(props.initialValues[field], values[field], triggerFrom)
    const oldValue = field ? get(['dropdown', 'checkBox', 'reportSystem'].includes(triggerFrom) ? valuesRef.current : props.initialValues, field) : initialValues;
    const newValue = field ? get(values, field) : values;
    return { notEqual: !isEqual(oldValue, newValue), oldValue, newValue };
  }, [values]);

  const finalSubmit = (field, _values, initValues, triggerFrom, _linkId) => {
    if (isSubmitting.current) {
      submitForm()
        .then(() => {
          const { historyFormTypeID } = sectionInfo;
          // console.log('finalSubmit', historyFormTypeID, props.linkId , linkId, mutateHistory)
          const diffResults = deepDiff(['dropdown', 'checkBox', 'reportSystem'].includes(triggerFrom)
            ? { ..._values, ...valuesRef.current }
            : _values, initValues);
          const oldResults = {};

          Object.keys(diffResults).forEach((key) => {
            oldResults[key] = initValues[key];
          });

          // console.log({ diffResults, oldResults, lId })
          if (historyFormTypeID && _linkId && mutateHistory) {
            Object.keys(diffResults).forEach((key) => {
              mutateHistory({
                variables: {
                  payload: {
                    formTypeId: historyFormTypeID,
                    historyUserId: 1,
                    recordId: +_linkId,
                    operationId: 2,
                    fieldName: key || '',
                    dbFieldName: '', // should update in the future
                    oldValue: String(oldResults[key]) || '',
                    newValue: String(diffResults[key]) || '',
                  },
                },
              });
            });
          }
        })
        .catch(console.log);
      isSubmitting.current = false;
    }
  };

  const handleChange = (field) => (value, secondField, isReport) => {
    if (customState && customState[field] !== undefined && customHandleChange) {
      customHandleChange((prevState) => ({ ...prevState, [field]: value }));
    }
    try {
      setFieldValue(field || secondField, value);
      if (valuesRef.current instanceof Object && (['dropdown', 'checkBox'].includes(secondField) || isReport)) {
        valuesRef.current[field] = value;
      }
    } catch (err) {
      //console.log(err)
    }
  };

  if (!submit.current) {
    submit.current = debounce(finalSubmit, timeoutSubmit || 2000);
  }

  const startSubmit = (field) => (value, triggerFrom) => {
    const valueData = valuesChanged(field, triggerFrom);
  
    if (valueData.notEqual) {
      isSubmitting.current = true;
      submit.current.cancel();
      submit.current(field, values, props.initialValues, triggerFrom, props.linkId);
    }
  };

  useEffect(() => {
    if (isSubmitting.current) {
      onSubmit(values, sectionInfo, linkId);
      submit.current.cancel();
      setLinkId(props.linkId);
      isSubmitting.current = false;
    } else {
      setLinkId(props.linkId);
    }
    return () => {
      if (isSubmitting.current) {
        submitForm();
        submit.current.cancel();
      }
    };
  }, [props.linkId]);

  const context = {
    submit: startSubmit,
    onChangeFormik: handleChange,
  };
  return (
    <div>
      <FormikSubmit.Provider value={context}>
        {children({ ...formikApi })}
      </FormikSubmit.Provider>

    </div>
  );
};

export const withFieldSubmit = (WrapedComponent) => ({ ...props }) => {
  return (
    <FormikSubmit.Consumer>
      {(context) => {
        const newProps = {
          ...props,
          submit: context && context.submit(props.field),
          onChangeFormik: context && context.onChangeFormik(props.field),

        };
        return <WrapedComponent {...newProps} />;
      }
      }
    </FormikSubmit.Consumer>
  );
};

export default compose(
  withHistoryMutation
)(Formik);
