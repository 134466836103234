import React from 'react';

import Invoice from './table/invoice';
import Delivery from './table/delivery';

import { Transition } from '../../../components/elements';
import { useTranslation } from 'react-i18next';
//import Formik from '../../../components/nf/formik';

export default (props) => {
  const [t] = useTranslation();
  return (
    <React.Fragment>
      <Transition index={0} text={t('common.deliveryNote')} isWhite>
        <Delivery {...props}/>
      </Transition>
      <Transition index={1} text={t('invoice.invoice')} isWhite>
        <Invoice {...props}/>
      </Transition>
    </React.Fragment>
  );
};


